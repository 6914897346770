import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Loader, TabView } from 'venus/components'
import PSILayout from 'venus/layout/PSI'
import { DocumentList } from 'venus/pages/Documents/List'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import Items from './Items'
import { Container } from './Space.styles'
import HistoryList from 'venus/pages/History/HistoryList'
import TradespeopleList from 'venus/pages/Tradespeople'
import React from 'react'
import PSIPhotos from 'venus/common/PSIPhotos'
import { updateSpace } from 'venus/redux/features/space/space'
import api from 'venus/api'
import { isTempUrl } from 'venus/utils/url'
import useShareStatus from 'venus/hooks/useShareStatus'
import { updateProperty } from 'venus/redux/features/property/property'
import useNavigateSearch from 'venus/hooks/useNavigateSearch'

const formatData = (data, images) => {
  if (data.coverImage && isTempUrl(data.coverImage)) {
    return { ...data, images: [...images, { url: data.coverImage }] }
  }
  return data
}

const getTabsForSpace = (
  isShared: boolean,
  isShareDocument: boolean,
  isShareHistory: boolean,
  items: any | undefined,
) => {
  const tabs = []
  const isDisplayItems = !isShared || (isShared && !!items)
  const isDisplayDocument = !isShared || (isShared && isShareDocument)
  const isDisplayHistory = !isShared || (isShared && isShareHistory)
  if (isDisplayItems) tabs.push('Items')
  tabs.push('Photos')
  if (isDisplayDocument) tabs.push('Documents')
  if (isDisplayHistory) tabs.push('History')
  if (!isShared) tabs.push('Tradespeople')

  return { tabs, isDisplayItems, isDisplayDocument, isDisplayHistory }
}

const Space = () => {
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  const { propertyId, spaceId } = useParams()
  const navigate = useNavigate()
  const navigateSearch = useNavigateSearch()
  const { property } = useAppSelector((state) => state.property)
  const { space, loading } = useAppSelector((state) => state.space)
  const dispatch = useAppDispatch()

  const { share } = useShareStatus(propertyId, shareId)

  const queryParams = new URLSearchParams(window.location.search)
  const activeIndex = queryParams.get('activeIndex')

  if (loading) {
    return <Loader />
  }

  const goBack = () => {
    navigateSearch(`/property/${propertyId}`, {
      shareId,
      dreamPropertyId,
      activeIndex: 'Spaces',
    })
  }

  const onUpdateSpaceSetting = async (data) => {
    const response = await api.put(
      `/property/${propertyId}/spaces/${space.id}`,
      formatData(data, space.images),
    )
    const updatedProperty = response.data

    dispatch(updateSpace({ ...updatedProperty, propertyId }))
  }

  const isShared = !!shareId || !!dreamPropertyId

  // @ts-ignore
  const { isShareDocument, isShareHistory } = share

  const { tabs, isDisplayItems, isDisplayDocument, isDisplayHistory } = getTabsForSpace(
    isShared,
    isShareDocument,
    isShareHistory,
    space?.items,
  )

  const onDelete = async () => {
    try {
      const response = await api.delete(`/property/${propertyId}/spaces/${space.id}`)
      const updatedSpaces = response.data

      dispatch(updateProperty({ spaces: updatedSpaces }))

      navigate(`/property/${propertyId}?activeIndex=Spaces`)
    } catch (err) {}
  }

  console.log('activeIndex', window.location.search, activeIndex, tabs)

  return (
    <PSILayout
      tag='Space'
      title={space?.name}
      headerBackground={space?.coverImage}
      goBack={goBack}
      setting={{ name: space?.name, coverImage: space?.coverImage, images: space?.images }}
      onUpdateSetting={onUpdateSpaceSetting}
      onDelete={onDelete}
      isShared={isShared}
      isDreamProperty={!!dreamPropertyId}
      // sharedBy={sharedBy}
    >
      <Container>
        <TabView
          defaultActiveIndex={tabs.indexOf(activeIndex || 'Items')}
          tabs={tabs}
          style={{ minHeight: 0, overflow: 'auto' }}
        >
          {!!isDisplayItems && (
            <Items
              propertyId={propertyId}
              spaceId={spaceId}
              items={space?.items || []}
              setTabButtons={null}
            />
          )}
          <PSIPhotos
            style={{ padding: '24px 0' }}
            images={space?.images}
            updateUrl={`/property/${propertyId}/spaces/${spaceId}`}
            update={updateSpace}
            psiId={{ propertyId, spaceId, shareId, dreamPropertyId }}
            propertyWideSpaceId={property?.propertyWideSpaceId}
            setTabButtons={undefined}
            isShared={isShared}
            isPersonal={undefined}
            enableScanHome
          />
          {isDisplayDocument && (
            <DocumentList
              type='space'
              propertyId={propertyId}
              propertyWideSpaceId={property?.propertyWideSpaceId}
              spaceId={spaceId}
              shareId={shareId}
              dreamPropertyId={dreamPropertyId}
            />
          )}
          {isDisplayHistory && (
            <HistoryList
              propertyId={propertyId}
              spaceId={spaceId}
              spaceNameTag={space?.name}
            />
          )}
          {!isShared && <TradespeopleList propertyId={propertyId} spaceId={spaceId} />}
        </TabView>
      </Container>
    </PSILayout>
  )
}

export default Space
