import { useEffect, useMemo, useState } from 'react'
import { AlertBox, Button, Modal } from 'venus/components'
import ImageGrids from '../components/ImageGrids'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'

import AddSpace from 'venus/common/AddSpace'
import api from 'venus/api'
import { updateProperty } from 'venus/redux/features/property/property'
import { useAppDispatch } from 'venus/redux/hooks'
import EditSpaces from './EditSpaces'
import { DragDropContext } from '@hello-pangea/dnd'
import { useParams } from 'react-router-dom'
import { buildURLQuery } from 'venus/utils/url'
import _ from 'lodash'

type TIsDeleteOpen = {
  id?: string
  name?: string
}

const Spaces = ({ id, spaces, setTabButtons, shareId, dreamPropertyId }) => {
  const { propertyId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState<TIsDeleteOpen>({})
  const [isEditSpaceOpen, setIsEditSpaceOpen] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!shareId && !dreamPropertyId) {
      setTabButtons(
        <>
          <Button
            type='icon_button_tertiary'
            text='Edit'
            iconLeft={<EditIcon style={{ marginRight: 8 }} />}
            style={{ width: 'auto', marginRight: 8 }}
            onClick={() => setIsEditSpaceOpen(true)}
          />
          <Button
            type='icon_button'
            text='Add Space'
            iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
            style={{ width: 'auto' }}
            onClick={() => setIsOpen(true)}
          />
        </>,
      )
    }

    return () => setTabButtons(<></>)
  }, [shareId, dreamPropertyId])

  const grids = useMemo(
    () =>
      (spaces || []).map((space) => ({
        id: space.id,
        image: space.coverImage,
        url: `/property/${propertyId}/space/${space.id}?${buildURLQuery({
          shareId,
          dreamPropertyId,
        })}`,
        name: space.name,
      })),
    [spaces, shareId, dreamPropertyId],
  )

  const addSpace = async (space) => {
    const response = await api.post(`/property/${id}/spaces`, [space])
    dispatch(updateProperty({ spaces: response.data }))
    setIsOpen(false)
  }

  const editSpaces = async (spaces) => {
    const updated = await api.put(`/property/${propertyId}/spaces`, spaces)
    dispatch(updateProperty({ spaces: updated.data }))
    setIsEditSpaceOpen(false)
  }

  const openDelete = (id, name) => {
    setIsEditSpaceOpen(false)
    setIsDeleteOpen({ id, name })
  }

  const cancelDelete = () => {
    setIsDeleteOpen({})
    setIsEditSpaceOpen(true)
  }
  const closeDelete = () => {
    setIsDeleteOpen({})
  }

  const deleteSpace = async () => {
    try {
      const response = await api.delete(`/property/${propertyId}/spaces/${isDeleteOpen.id}`)
      const updatedSpaces = response.data

      dispatch(updateProperty({ spaces: updatedSpaces }))
    } catch (err) {}

    setIsDeleteOpen({})
  }

  return (
    <>
      <ImageGrids grids={grids} />
      <Modal
        height={620}
        title='Add Space'
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <AddSpace onSubmit={addSpace} isOnboarding={false} />
      </Modal>
      <Modal
        isOpen={isEditSpaceOpen}
        onClose={() => setIsEditSpaceOpen(false)}
        title='Home layout'
      >
        <EditSpaces spaces={spaces} onSubmit={editSpaces} onDelete={openDelete} />
      </Modal>
      <AlertBox
        showAlert={!_.isEmpty(isDeleteOpen)}
        cancel={cancelDelete}
        title={`Remove Space`}
        message={`Are you sure you want to remove '${isDeleteOpen.name}' from your MyHomeVault? This space will be deleted immediately. You can't undo this action.`}
        btnGroup={[
          {
            text: 'Cancel',
            type: 'secondary',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: cancelDelete,
          },
          {
            text: 'Delete',
            type: 'error',
            color: 'Primary',
            textColor: 'White',
            onClick: deleteSpace,
          },
        ]}
      />
    </>
  )
}

export default Spaces
