export const Dream_Price_URLs = {
  StackName: {
    label: 'DreamPrice',
    url: 'DreamPrice'
  },
  List: {
    label: '',
    url: 'List'
  },
  Details: {
    label: '',
    url: 'DreamPropertyDetails'
  },
  SetDreamPrice: {
    label: 'Share Dream Price',
    url: 'SetDreamPrice'
  },
  ShareDreamProperty: {
    label: 'Share Dream Price',
    url: 'ShareDreamProperty'
  }
}

export const Add_Dream_Property_Type = 'type_dreamprice'
export const Request_Ownership_Type = 'RequestOwnership'

export const Max_Price = 99999999999
