import { getExtension } from 'mime'
import { useAppDispatch } from 'venus/redux/hooks'
import { getUploadUrl, uploadDoc } from 'venus/redux/features/docs'
import { File_Types, theme } from 'venus/config'
import { Button } from 'venus/components'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { ReactComponent as UploadIcon } from 'venus/assets/img/icons/Upload.svg'
import {
  Wrapper,
  IconArea,
  FileSelectDiv,
  UploadContainer,
  FileSelectIconDiv,
} from './FileUploader.styles'
import { useState } from 'react'

interface IFileUploader {
  setUploadURL: (v: string[]) => void
  setUploadName: (v: string[]) => void
  onFinish: () => void
  label?: string
  isIcon?: boolean
}

export const FileUploader = ({
  setUploadURL,
  setUploadName,
  onFinish,
  label = 'Add document',
  isIcon = false,
}: IFileUploader) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('')

  const handlePressPlus = async (e) => {
    const { files } = e.target
    const filesArr = Array.from(files)
    const body = filesArr.map(({ type: mimeType, name }) => {
      const arr = name.split('.')
      const filename = arr.slice(0, arr.length - 1).join('.')
      const ext = getExtension(mimeType)
      return {
        name: filename,
        ext,
        type: mimeType,
      }
    })
    const getUploadUrlResponse = await dispatch(getUploadUrl(body))
    console.log('get Url uploadURL::', getUploadUrlResponse.payload)
    let completed = 0
    const names = []
    const urls = []

    if (getUploadUrlResponse.meta.requestStatus !== 'fulfilled') return

    filesArr.forEach((file, index) => {
      const { uploadURL } = getUploadUrlResponse.payload[index]
      const { type } = body[index]
      const reader = new FileReader()
      reader.readAsArrayBuffer(file as Blob)
      reader.onload = async () => {
        const content = reader.result
        const response = await dispatch(
          uploadDoc({
            uploadURL,
            content,
            type,
          })
        )
        console.log(`upload result ${index + 1}::`, response)
        names.push(body[index].name)
        const pos = uploadURL.indexOf('?')
        const url = uploadURL.slice(0, pos)
        urls.push(url)
        completed += 1
        if (completed === filesArr.length) {
          setUploadURL(urls)
          setUploadName(names)
          onFinish()
        }
      }
    })
  }

  const handleClick = () => {
    setValue('')
  }

  const InputWrapper = isIcon ? FileSelectIconDiv : FileSelectDiv

  return (
    <Wrapper>
      <IconArea>
        {isIcon && (
          <UploadContainer>
            <UploadIcon width={60} height={60} color={theme.colors.BlueGrey} />
          </UploadContainer>
        )}
        {!isIcon && (
          <Button
            type='icon_button'
            text={label}
            onClick={() => null}
            iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
            style={{ width: '165px' }}
          />
        )}
      </IconArea>
      <InputWrapper>
        <input
          type='file'
          accept={File_Types.join(',')}
          onChange={handlePressPlus}
          onClick={handleClick}
          value={value}
          style={{ cursor: 'pointer' }}
          multiple
        />
      </InputWrapper>
    </Wrapper>
  )
}
