import { ActionLayout, Modal } from 'venus/components'
import ItemForm from './ItemForm'
import styled from 'styled-components'
import ActionBar from 'venus/components/ActionBar'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { theme } from 'venus/config'
import { useRef } from 'react'
const ModalContent = styled.div`
  overflow: auto;
  padding: 0 34px;
`

const AddItem = ({ isOpen, onClose, template, onSubmit, isPersonal = false, ...props }) => {
  const { type, name } = props

  const refSubmitButtom = useRef<HTMLButtonElement>(null)
  const triggerSubmit = () => {
    refSubmitButtom?.current?.click()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Add Item'>
      <ActionLayout
        margin={34}
        rightActions={[
          {
            type: 'icon_button',
            text: 'Add',
            iconLeft: <AddSign color={theme.colors.White} />,
            onClick: triggerSubmit,
          },
        ]}
      >
        {/* <ModalContent> */}
        <ItemForm
          template={template}
          isPersonal={isPersonal}
          onSubmit={onSubmit}
          defaultValues={{ typeId: type, name, images: [] }}
          refSubmitButtom={refSubmitButtom}
          {...props}
        />
        {/* </ModalContent> */}
      </ActionLayout>

      {/* <ActionBar
        rightActions={[
          {
            type: 'icon_button',
            text: 'Add',
            iconLeft: <AddSign color={theme.colors.White} />,
            onClick: triggerSubmit,
          },
        ]}
      /> */}
    </Modal>
  )
}

export default AddItem
