import MenuItem, { IMenu } from './MenuItem'
import { MenuContainer } from './Menus.styles'

interface IMenus {
  menus: IMenu[]
}

const Menus = ({ menus }: IMenus) => {
  return (
    <MenuContainer>
      {menus.map((menu) => (
        <MenuItem key={menu.label || menu.title} {...menu} />
      ))}
    </MenuContainer>
  )
}

export default Menus
