import { Text, EmptyData } from 'venus/components'
import { theme } from 'venus/config'
import { Details } from './Details'
import {
  DocumentWrapper,
  EmptyDataWrapper,
  ListWrapper,
} from './SubList.styles'

interface ISubList {
  data: any[]
  handlePress: Function
  handlePressKebab: Function
  handlePressRename: () => void
  handlePressMove: () => void
  handlePressDelete: () => void
  isShare?: boolean
  isPersonal: boolean
  isPersonalItem: boolean
}

export const SubList = ({
  data,
  handlePress,
  handlePressKebab,
  handlePressRename,
  handlePressMove,
  handlePressDelete,
  isShare,
  isPersonal,
  isPersonalItem,
}: ISubList) => {
  if (!data || data.length === 0) {
    return (
      <EmptyDataWrapper>
        <EmptyData
          message={`Click the Add button to upload documents to your vault.`}
        />
      </EmptyDataWrapper>
    )
  }

  return (
    <ListWrapper>
      <DocumentWrapper>
        <Text
          value='Title'
          color={theme.colors.Black}
          format='h3'
          style={{ marginBottom: 3, width: 256, display: 'inline-block' }}
        />
        <Text
          value='Date'
          color={theme.colors.Black}
          style={{ width: 120, display: 'inline-block' }}
          format='h3'
        />
        <div style={{ width: 32 }} />
      </DocumentWrapper>
      {data.map(({ updatedAt, url, displayName, contentType }: any, index) => {
        return (
          <Details
            updatedAt={updatedAt}
            url={url}
            displayName={displayName}
            contentType={contentType}
            handlePress={handlePress}
            handlePressKebab={handlePressKebab}
            handlePressRename={handlePressRename}
            handlePressMove={handlePressMove}
            handlePressDelete={handlePressDelete}
            isShare={isShare}
            isPersonal={isPersonal}
            isPersonalItem={isPersonalItem}
            key={`details_${index}`}
          />
        )
      })}
    </ListWrapper>
  )
}
