import styled from 'styled-components'
import { theme } from 'venus/config'

export const SearchTradesPeopleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.colors.BlueGrey};
  border-radius: 8px;
  background-color: ${theme.colors.White};

  &:hover {
    background-color: ${theme.colors.LighterGrey};
    cursor: pointer;
  }
`
