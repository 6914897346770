import _ from 'lodash'
import {
  Address,
  Container,
  CoverImage,
  ItemContainer,
  ListContainer,
  Radio,
  TagDisplay,
} from './SelectProperty.styles'
import { IProperty } from 'venus/redux/features/property/properties'
import { PillTag, Text } from 'venus/components'
import { getFullPropertyName } from 'venus/utils'
import { useState } from 'react'
import { theme } from 'venus/config'
import { ReactComponent as Tick } from 'venus/assets/img/icons/Tick.svg'

const SelectProperty = ({ properties, number, onSelect }) => {
  const [selected, setSelected] = useState({})

  const handleSelect = (id) => () => {
    let selectedProperties = {}
    if (selected[id]) {
      selectedProperties = _.omit(selected, id)
    } else {
      if (number === Object.keys(selected).length) {
        return
      }
      selectedProperties = { ...selected, [id]: true }
    }
    setSelected(selectedProperties)
    onSelect(Object.keys(selectedProperties))
  }

  return (
    <Container>
      <TagDisplay>
        <PillTag
          background={theme.colors.Primary}
          color='White'
          label={`${Object.keys(selected).length} / ${number} selected`}
        />
      </TagDisplay>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <ListContainer>
          {properties.map((property: IProperty) => {
            const { id, coverImage, address, propertyData } = property

            return (
              <ItemContainer key={id} onClick={handleSelect(id)}>
                <CoverImage image={coverImage} />
                <Address>
                  <Text format='body'>
                    {getFullPropertyName({ address, propertyData })}
                  </Text>
                </Address>
                <Radio isSelected={selected[id]}>
                  {!!selected[id] && <Tick width={14} color={theme.colors.Secondary} />}
                </Radio>
              </ItemContainer>
            )
          })}
        </ListContainer>
      </div>
    </Container>
  )
}

export default SelectProperty
