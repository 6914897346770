import React from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { updateProfileSteps } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal/Constants'
import { Header } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/ProfileTabMenu.styles'
import { ICompanyInfo } from 'venus/redux/features/user'
import { useAppSelector } from 'venus/redux/hooks'
import { Input } from 'venus/components'
import { Text } from 'venus/components'
import { LABELS } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Company/Constants'
import { PersonalProfileContainer } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal/ProfileForm.styles'
import { PersonalProfilePanel } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal/ProfileForm.styles'
import { useAppDispatch } from 'venus/redux/hooks'
import { useEffect } from 'react'
import { updateProfile } from 'venus/redux/features/user'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'
import { theme } from 'venus/config'
import { Loader } from 'venus/components'
import { ERR_REQUIRED_FIELD } from 'venus/layout/ProfileMenu/ProfileSettings/Constants'
import { Select } from 'venus/components'
import { States } from 'venus/config'
import SettingsHeader from '../../SettingsHeader/SettingsHeader'

const CompanyProfileForm = ({
  step,
  setStep,
  setActions,
}: {
  step: number
  setStep: (n: number) => void
  setActions: (actions: any) => void
}) => {
  const { info, loadingProfile } = useAppSelector((state) => state.user)
  const profile = info.profile

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<ICompanyInfo>({
    defaultValues: {
      companyName: profile?.companyName || '',
      companyABN: profile?.companyABN || '',
      companyAddress: profile?.companyAddress || '',
      companySuburb: profile?.companySuburb || '',
      companyState: profile?.companyState || '',
      companyPhone: profile?.companyPhone || '',
      companyEmail: profile?.companyEmail || '',
      companyWebsite: profile?.companyWebsite || '',
      licenseNo: profile?.licenseNo || '',
    },
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    switch (step) {
      case updateProfileSteps.GET_FORM:
        setActions([
          {
            type: 'icon_button',
            text: 'Edit',
            onClick: () => {
              setStep(updateProfileSteps.EDIT_PROFILE)
            },
            iconLeft: (
              <EditIcon style={{ marginRight: '9px', color: theme.colors.White }} />
            ),
            style: { width: 'auto' },
          },
        ])
        break
      case updateProfileSteps.EDIT_PROFILE:
        setActions([
          {
            type: 'primary',
            isSubmit: true,
            onClick: handleSubmit(handleOnSubmitCompanyProfile),
            text: 'Update',
          },
        ])
        break
    }
  }, [step])

  const handleOnSubmitCompanyProfile = async (data) => {
    if (!data) return
    const updateData = {
      profile: {
        ...data,
      },
    }
    await dispatch(updateProfile(updateData)).finally(() => {
      setStep(updateProfileSteps.GET_FORM)
    })
  }

  if (loadingProfile) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  let bodyComponent = <></>

  switch (step) {
    case updateProfileSteps.GET_FORM:
      const defaultValues = getValues()
      bodyComponent = (
        <>
          <PersonalProfileContainer>
            <PersonalProfilePanel>
              <Text
                format={'h2'}
                value={LABELS.COMPANY_NAME}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyName}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_ABN}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyABN}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_ADDRESS}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyAddress}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_SUBURB}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companySuburb}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_STATE}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyState}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_PHONE}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyPhone}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_EMAIL}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyEmail}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.COMPANY_WEBSITE}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.companyWebsite}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'h2'}
                value={LABELS.LICENSE_NUMBER}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
              <Text
                format={'body'}
                value={defaultValues.licenseNo}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
            </PersonalProfilePanel>
          </PersonalProfileContainer>
        </>
      )
      break
    case updateProfileSteps.EDIT_PROFILE:
      bodyComponent = (
        <>
          <PersonalProfileContainer>
            <form>
              <PersonalProfilePanel>
                <Controller
                  name='companyName'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_NAME}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='companyABN'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_ABN}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='companyAddress'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_ADDRESS}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='companySuburb'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_SUBURB}
                        value={value}
                        onChange={onChange}
                        isError={!!errors.companySuburb}
                        errorMsg={ERR_REQUIRED_FIELD}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='companyState'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      title='State'
                      options={States}
                      {...field}
                      style={{
                        marginBottom: '18px',
                      }}
                      isError={!!errors.companyState}
                      errorMsg={ERR_REQUIRED_FIELD}
                    />
                  )}
                />
                <Controller
                  name='companyPhone'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_PHONE}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='companyEmail'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_EMAIL}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='companyWebsite'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.COMPANY_WEBSITE}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='licenseNo'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={LABELS.LICENSE_NUMBER}
                        value={value}
                        onChange={onChange}
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
              </PersonalProfilePanel>
            </form>
          </PersonalProfileContainer>
        </>
      )
      break
  }
  return <>{bodyComponent}</>
}

export default CompanyProfileForm
