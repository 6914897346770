import React, { Ref, useEffect, useMemo, useState } from 'react'
// import {
//   Keyboard,
//   KeyboardAvoidingView,
//   TouchableWithoutFeedback,
//   View,
// } from 'react-native'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { theme, Max_Price } from 'venus/config'
import { AutoComplete, Input, PriceInput, Text, DatePicker } from 'venus/components'
import _ from 'lodash'
import api from 'venus/api'
// import Scan from '../NewItem/Scan'
// import { IItemAutocomplete } from '../NewItem/Scan/Scan'
import ImageUploader from 'venus/common/ImageUploader'
import { IImage } from 'venus/types/Image'
import { isBefore } from 'date-fns'
import { parseDate } from 'venus/utils'
import { FormContainer, Row, Section } from './ItemForm.styles'
import { TextArea } from 'venus/components/Textarea'

interface IItemAutocomplete {
  keywords: string[]
  type?: {
    name: string
    type: string
  }
  brand: {
    name: string
  }
  model: {
    name: string
  }
}

type ItemFormData = {
  typeId?: string
  name?: string
  brandName?: string
  modelName?: string
  serialNo?: string
  value?: number
  note?: string
  spaceId: string
  warrantyDate?: string
  installedAt?: string
  images?: IImage[]
}

interface IOption {
  value: string
  label: string
}

const getOptions = (options: { name: string }[], name: string, keywords: string[] = []) => {
  const autocompleteOptions = options.map((option) => ({
    value: option.name,
    label: option.name,
  }))

  const reg = new RegExp(name, 'ig')
  const keywordsOptions = keywords
    .filter((keyword) => reg.test(keyword))
    .map((keyword) => ({ value: keyword, label: keyword }))

  return [...autocompleteOptions, ...keywordsOptions]
}

const ItemForm = ({
  template,
  defaultValues,
  onSubmit,
  isPersonal,
  refSubmitButtom,
}: {
  template?: any
  defaultValues?: any
  onSubmit: SubmitHandler<ItemFormData>
  isPersonal: boolean

  refSubmitButtom: Ref<HTMLButtonElement>
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    resetField,
  } = useForm<ItemFormData>({ defaultValues })

  const { name } = getValues()

  const handleTypeChange =
    (onChange: (...event: any[]) => void) => (type: string, typeName: string) => {
      if (!dirtyFields.name) {
        const selected = types.find(({ value }) => value == type) || {}
        setValue('name', selected.label)
      }
      onChange(type)
      handleSearchBrand('')
    }

  const handleBrandChange = (brand: string, onChange: (...event: any[]) => void) => {
    onChange(brand)
    handleSearchModel('')
  }

  const [typeOptions, setTypeOptions] = useState(template)
  const [brands, setBrands] = useState<IOption[]>([])
  const [models, setModels] = useState<IOption[]>([])

  const [autoComplete, setAutoComplete] = useState<IItemAutocomplete>()

  const type = getValues('typeId')

  useEffect(() => {
    if (autoComplete) {
      if (autoComplete.brand?.name) {
        setValue('brandName', autoComplete.brand.name)
      }

      if (autoComplete.model?.name) {
        setValue('modelName', autoComplete.model.name)
      }
    }
  }, [autoComplete])

  useEffect(() => {
    onSearchType(defaultValues.typeId)
  }, [])

  const onSearchType = async (name: string) => {
    // if (!name) {
    //   setTypeOptions(template)
    //   return
    // }

    const options = await api
      .get('/autocomplete/item/type', { params: { name } })
      .then((res) => res.data)
    setTypeOptions(options)
  }

  const handleSearchBrand = async (name: string) => {
    if (isPersonal) {
      setBrands([])
      return
    }

    const response = await api.get('/autocomplete/item/brand', {
      params: { name, type },
    })

    setBrands(getOptions(response.data, name, autoComplete?.keywords))
  }

  const handleSearchModel = async (name: string) => {
    if (isPersonal) {
      setModels([])
      return
    }

    const brand = getValues('brandName')
    const response = await api.get('/autocomplete/item/model', {
      params: { name, type, brand },
    })
    setModels(getOptions(response.data, name, autoComplete?.keywords))
  }

  const types = useMemo(
    () =>
      typeOptions?.map((item: { name: string; type: string }) => ({
        label: item.name,
        value: item.type,
      })) || [],
    [typeOptions],
  )

  const defaultTypes = useMemo(() => {
    return (
      template?.map((item: { name: string; type: string }) => ({
        label: item.name,
        value: item.type,
      })) || []
    )
  }, [template])

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {/* {!isPersonal && <Scan type={type} disabled={!isScanable} onScan={setAutoComplete} />} */}

      {!isPersonal && (
        <Controller
          control={control}
          name='typeId'
          render={({ field: { value, onChange } }) => {
            return (
              <AutoComplete
                style={{ marginBottom: 18 }}
                label='Item Type'
                value={value}
                onChange={handleTypeChange(onChange)}
                onSearch={onSearchType}
                options={types}
                defaultOptions={defaultTypes}
                isSelectedOnly
              />
            )
          }}
        />
      )}

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            style={{ marginBottom: 18 }}
            label='Name'
            value={value}
            onChange={onChange}
            isError={!!errors.name}
            errorMsg={
              errors?.name?.type === 'maxLength'
                ? 'Maximum 25 characters.'
                : !!value && value !== value?.trim()
                ? 'Please remove leading or trailing spaces.'
                : 'This is required.'
            }
          />
        )}
        rules={{
          required: true,
          maxLength: 25,
          validate: (v) => v && v === v.trim(),
        }}
        name='name'
      />
      <Controller
        control={control}
        rules={{
          maxLength: 20,
          validate: (v) => v && v === v.trim(),
        }}
        render={({ field: { onChange, value } }) => (
          <AutoComplete
            style={{ marginBottom: 18 }}
            label='Brand Name'
            value={value}
            onChange={(brand) => handleBrandChange(brand, onChange)}
            onSearch={handleSearchBrand}
            options={brands}
          />
        )}
        name='brandName'
      />
      {errors.brandName && (
        <Text
          format='body'
          value={
            errors.brandName.type === 'maxLength'
              ? 'Maximum 20 characters.'
              : 'Please remove leading or trailing spaces.'
          }
          color={theme.colors.ErrorState}
          fontSize={14}
          fontWeight='bold'
          style={{ marginTop: '-12px', marginBottom: '12px' }}
        />
      )}
      <Controller
        control={control}
        rules={{
          maxLength: 20,
          validate: (v) => v && v === v.trim(),
        }}
        render={({ field: { onChange, value } }) => (
          <AutoComplete
            style={{ marginBottom: 18 }}
            label='Model Name'
            value={value}
            onChange={onChange}
            onSearch={handleSearchModel}
            options={models}
          />
        )}
        name='modelName'
      />
      {errors.modelName && (
        <Text
          format='body'
          value={
            errors.modelName.type === 'maxLength'
              ? 'Maximum 20 characters.'
              : 'Please remove leading or trailing spaces.'
          }
          color={theme.colors.ErrorState}
          fontSize={14}
          fontWeight='bold'
          style={{ marginTop: '-12px', marginBottom: '12px' }}
        />
      )}

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            style={{ marginBottom: 18 }}
            label='Serial Number'
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          maxLength: 50,
        }}
        name='serialNo'
      />
      {errors.serialNo && (
        <Text
          format='body'
          value={'Maximum 50 characters.'}
          color={theme.colors.ErrorState}
          fontSize={14}
          fontWeight='bold'
          style={{ marginTop: -6 }}
        />
      )}
      <Row>
        <Section>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <div style={{ flex: 1, marginBottom: 30 }}>
                <DatePicker
                  label='Installed date'
                  onChange={onChange}
                  value={value}
                  disableFutureDate
                />
              </div>
            )}
            name='installedAt'
          />
        </Section>
        <div style={{ width: 16 }} />
        <Section>
          <Controller
            control={control}
            rules={{
              validate: (val) => {
                const installed = getValues('installedAt')

                if (installed && val) {
                  if (isBefore(parseDate(val), parseDate(installed))) {
                    return false
                  }
                  return true
                }
                return true
              },
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker label='Warranty date' onChange={onChange} value={value} />
            )}
            name='warrantyDate'
          />
          {errors.warrantyDate && (
            <Text
              format='body'
              value='Warranty Date must be after installation date.'
              color={theme.colors.ErrorState}
              fontSize={14}
              fontWeight='bold'
              style={{ marginTop: 40 }}
            />
          )}
        </Section>
      </Row>

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <PriceInput
            label='Value'
            value={value || 0}
            onChange={onChange}
            style={{ marginBottom: 18 }}
            isError={value > Max_Price}
            errorMsg={'Invalid value.'}
          />
        )}
        rules={{
          min: 0,
        }}
        name='value'
      />
      {errors.value && (
        <Text
          format='body'
          value='Value must not be negative.'
          color={theme.colors.ErrorState}
          fontSize={14}
          fontWeight='bold'
          style={{ marginTop: -6 }}
        />
      )}
      <Controller
        control={control}
        rules={{
          maxLength: 1024,
        }}
        render={({ field: { onChange, value } }) => (
          <TextArea
            rows={3}
            cols={50}
            style={{ marginBottom: 18 }}
            label='Notes'
            value={value}
            onChange={onChange}
            isError={!!errors.note}
            errorMsg={errors?.note?.type === 'maxLength' ? 'Maximum 1024 characters.' : ''}
          />
        )}
        name='note'
      />

      <button hidden={true} ref={refSubmitButtom} type={'submit'} />
      {/* {isPersonal && !defaultValues.images.length && (
        <Controller
          control={control}
          name='images'
          render={({ field: { onChange, value } }) => (
            <ImageUploader images={value} onChange={onChange} />
          )}
        />
      )}
      <TouchButton
        fullBlock
        isDisabled={!name || (!isPersonal && !type)}
        onPress={handleSubmit(onSubmit)}
        backgroundColor={theme.colors.Primary}
        color={theme.colors.White}
        isLoading={isLoading}
        text='Save Changes'
      /> */}
    </FormContainer>
  )
}

export default ItemForm
