import styled from 'styled-components'

export const VideosContainer = styled.div`
  margin-top: 24px;
`

export const VideoList = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  list-style: none;

  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`

export const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;

  .react-player,
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    overflow: hidden;
  }
`

export const VideoLength = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.75);
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PlayButton = styled.button`
  cursor: pointer;
  border: 0;
  background: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VideoPreviewContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 150px;

  display: flex;
  align-items: center;
`

export const CloseButton = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  position: absolute;
  top: 36px;
  left: 24px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.White};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ActionContainer = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  width: 36px;
  height: 36px;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`
