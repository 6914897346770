import React from 'react'
import { PillTag } from 'venus/components'
import { formatFullAddress } from 'venus/utils'
import { theme } from 'venus/config'
import { IProperty } from 'venus/redux/features/property/properties'
import { Item } from './PropertyCardView.styles'
import { ImageContainer } from './PropertyCardView.styles'
import { getAddressPreviewStr } from 'venus/utils'
import { Text } from 'venus/components'
import { PropertyDataWrapper } from './PropertyCardView.styles'
import { Container } from './PropertyCardView.styles'

const PropertyCardView = ({
  property,
  isSelected = false,
  setSelectedProperty,
  imageHeight,
  showPropertyData = false,
  style,
  addressFontSize = 18,
  propertyDataFontSize = 16,
}: {
  property: IProperty
  isSelected: boolean
  setSelectedProperty?: Function
  imageHeight?: number
  showPropertyData?: boolean
  style?: {}
  addressFontSize?: number,
  propertyDataFontSize?: number,
}) => {
  const handleOnClick = (e: any) => {
    e.preventDefault()
    setSelectedProperty && setSelectedProperty(property)
  }

  return (
    <Container>
      <ImageContainer isSelected={isSelected} onClick={handleOnClick} style={style}>
        <Item
          key={property?.id}
          imageHeight={imageHeight}
          coverImage={property?.coverImage}
        >
          {!showPropertyData && (
            <PillTag
              label={formatFullAddress(property?.address)}
              color={theme.colors.Primary}
              background={theme.colors.White}
              fontSize={12}
              paddingVertical={4}
            />
          )}
        </Item>
      </ImageContainer>
      {showPropertyData && (
        <PropertyDataWrapper>
          <Text
            format={'h2'}
            fontSize={addressFontSize}
            fontWeight={600}
            lineHeight={'21px'}
            color={theme.colors.Black}
            value={formatFullAddress(property?.address)}
          />
          <Text
            format={'body'}
            fontWeight={500}
            fontSize={propertyDataFontSize}
            lineHeight={'21px'}
            color={theme.colors.DarkGrey}
            value={getAddressPreviewStr(property?.propertyData)}
          />
        </PropertyDataWrapper>
      )}
    </Container>
  )
}

export default PropertyCardView
