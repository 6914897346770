import React from 'react'
import { Text } from 'venus/components'
import { Button } from 'venus/components'
import { Drawer } from 'venus/components'
import { ContactDrawView } from 'venus/components'
import { ReactComponent as DeleteIcon } from 'venus/assets/img/icons/Trash.svg'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'
import { theme } from 'venus/config'
import ActionLayout from '../../components/ActionLayout/ActionLayout'

export const ContactDrawViewSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [showDraw, setShowDraw] = React.useState(false)

  const toggleDraw = () => {
    setShowDraw(!showDraw)
  }

  const contact = {
    abn: '92648732441',
    averageRating: 2.5,
    companyAddress: '403 Terry Street',
    companyName: 'Vault Co. Ltd.',
    companyPhone: null,
    companyURL: 'https://vault.com.au',
    createdAt: '2022-08-16T18:50:33.146Z',
    createdBy: 1,
    email: 'test@myhomevault.com.au',
    firstName: 'Firstname',
    histories: [],
    id: 'test-contact-3',
    lastName: 'Surname',
    license: '268760C',
    mobile: '0449663747',
    profile: {
      avatar: 'https://i.pravatar.cc/150?img=20',
      companyName: 'Vault Co. Ltd.',
      isAllowedToShareContactInfo: true,
      isAllowedToSharePropertyInfo: true
    },
    propertyId: 'test-property-0001',
    rating: { pricing: 4, quality: 2, communication: 1, timeManagement: 3 },
    role: ['TRADESPEOPLE', 'OWNER', 'DEVELOPER'],
    tradespeopleRole: ['ELECTRICIAN', 'PAINTER', 'PLUMBER', 'CARPENTER', 'OTHER'],
    updatedAt: '2022-08-17T07:14:54.844Z'
  }

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Button
        onClick={toggleDraw}
        type='primary'
        text='Open Contact Drawer'
        style={{ width: '300px' }}
      />
      <Drawer
        isOpen={showDraw}
        onClose={toggleDraw}
      >
        <ActionLayout
          margin={36}
          children={<ContactDrawView contact={contact}/>}
          leftActions={[{
            type: 'icon_button_error',
            text: 'Delete',
            onClick: () => {},
            iconLeft: <DeleteIcon style={{ marginRight: '9px', color: theme.colors.ErrorState, }}/>
          }]}
          rightActions={[{
            type: 'icon_button_tertiary',
            text: 'Edit',
            onClick: () => {console.log('edit')},
            iconLeft: <EditIcon style={{ marginRight: '9px', color: theme.colors.Primary }}/>
          }]}
        />
      </Drawer>
    </>
  )
}
