export enum SignUpSteps {
  ENTER_GENERAL_INFO = 0,
  ENTER_COMPANY_INFO = 1,
  ENTER_REFERRAL_CODE = 2,
  ENTER_MOBILE = 3,
  ENTER_VALIDATION_CODE = 4,
}

export const CREATE_ACCOUNT_TITLE = 'Create an account'
export const COMPANY_DETAILS = 'Company Details'
export const ENTER_REFERRAL_CODE_TITLE = 'Enter referral code'
export const SKIP_REFERRAL_CODE_MSG = `Enter the referral code if you have, otherwise click "Skip"`
export const ENTER_MOBILE_NUMBER_TITLE = 'Enter your mobile number.'
export const ENTER_MOBILE_NUMBER_MSG = 'We will send you a confirmation code.'
export const ENTER_MOBILE_CODE_TITLE = 'Enter code sent to your mobile number.'
export const ENTER_MOBILE_CODE_MSG = 'We sent it to the number.'
export const RESEND_MOBILE_CODE_MSG = 'Code has resent.'
export const RESEND_EMAIL_CODE_MSG = 'Please check your email.'
export const SENT_EMAIL_CODE_MSG = 'Code has sent'
export const VERIFY_EMAIL_BUTTON_TEXT = 'Press to Verify'
export const VERIFY_MOBILE_BUTTON_TEXT = 'Click to verify'
export const VERIFIED_TEXT = 'Verified'
