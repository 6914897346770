import _ from 'lodash'
import { useEffect, useState } from 'react'
import useDebounce from 'venus/hooks/useDebounceVaule'
import Input from '../Input'
import { Option } from '../Select/Select'
import SelectOptions from '../Select/SelectOptions'
import { Container } from './AutoComplete.styles'

interface IAutoComplete {
  style?: any
  label?: string
  placeholder?: string
  value?: string
  text?: string
  isSelectedOnly?: boolean
  onChange: (val: string, label: string) => void
  onSearch: (val: string) => void
  options: Option[]
  defaultOptions?: Option[]
}

const AutoComplete = ({
  style,
  placeholder,
  label,
  onSearch,
  options,
  value,
  onChange,
  isSelectedOnly,
  defaultOptions,
}: IAutoComplete) => {
  const [text, setText] = useState(isSelectedOnly ? '' : value)
  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => setIsFocused(true)
  const onBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, 200)

    if (!isSelectedOnly) {
      onChange(text, text)
    } else {
      if (!value && !options.length) {
        setText('')
      }
    }
  }

  const onSelect = (option: Option) => {
    console.log('select')
    setText(option.label)
    onChange(option.value, option.label)
  }

  const debounceValue = useDebounce(text)

  useEffect(() => {
    console.log('debounceValue', debounceValue)
    onSearch(debounceValue ? '' + debounceValue : '')
  }, [debounceValue])

  useEffect(() => {
    const option = options.find((option) => option.value === value)

    console.log('option', options, option)
    // @ts-ignore
    // const defaultValue = '' + option?.label
    if (option?.label && !isSelectedOnly) {
      setText(option?.label)
    }
    if (isSelectedOnly && !text) {
      setText(option?.label)
    }
  }, [options])

  useEffect(() => {
    if (isSelectedOnly && isFocused && !text) {
      onChange('', '')
    }
    if (isSelectedOnly && !isFocused && text && !value) {
      setText('')
    }
  }, [isSelectedOnly, isFocused, text])

  return (
    <Container style={style}>
      <Input
        onFocus={onFocus}
        onBlur={onBlur}
        type='text'
        label={label}
        value={text}
        onChange={setText}
        placeholder={placeholder}
      />
      {isFocused && (options.length > 0 || (defaultOptions && !!defaultOptions.length)) && (
        <SelectOptions options={options} onSelect={onSelect} onClose={onBlur} />
      )}
    </Container>
  )
}

// const AutoComplete = ({
//   label,
//   placeholder,
//   value,
//   onChange,
//   onSearch,
//   options = [],
//   isSelectedOnly = false
// }: IAutoComplete) => {

//   useEffect(() => {
//     const { label: defaultValue } = options.find((option) => option.value === value) || {}
//     if (defaultValue) {
//       setText(defaultValue)
//     }
//   }, [options])

//   // console.log('defaultValue', label, options, value)

//   const [text, setText] = useState<string | number>(value || '')
//   const [isFocused, setIsFocused] = useState(false)

//   const debounceValue = useDebounce(text)

//   // const setFocus = () => setIsFocused(true)

//   useEffect(() => {
//     onSearch(`${debounceValue}`)
//   }, [debounceValue])

//   const handleFocus = () => {
//     setIsFocused(true)
//     handleTextChange(text)
//   }

//   // const option = options.find((option) => option.value === value)

//   const blur = () => {
//     console.log('blur')

//     if (!isSelectedOnly) {
//       onChange(text, text)
//     }

//   }

//   useEffect(() => {
//     if (value) {
//       const option = options.find((option) => option.value === value)
//       if (option) {
//         setText(option?.label)
//       } else {
//         setText(value)
//       }
//     }
//   }, [value])

//   const handlePress = (item) => {
//     onChange(item.value, item.label)
//     setText(item.label)
//     setIsFocused(false)
//   }

//   const renderItem = ({ item }) => {
//     const display = item.label || item

//     const regex = new RegExp(`(${text})`, 'i')
//     const remainingText = display.split(regex)

//     return (
//       <AutoCompleteOption onPress={() => handlePress(item)}>
//         {remainingText.map((t, i) => (
//           <Typography
//             key={`${t}_${i}`}
//             fontWeight={t === text ? 'semiBold' : 'regular'}
//             color="Black"
//             fontSize={12}
//             text={t}
//           />
//         ))}
//       </AutoCompleteOption>
//     )
//   }

//   const handleTextChange = (value: string | number | null) => {
//     setText(value)
//   }

//   console.log('options', options, isFocused)

//   const handleFinishChange = (value: string) => {
//     if (options.length) {
//       const item = options[0]

//       handlePress(item)
//     }

//     // const option = options.find((option) => option.value === value)
//     // console.log('value', value, options)

//     // if (option) {
//     //   setText(option?.label)
//     // } else {
//     //   setText(value)
//     // }
//   }

//   return (
//     <>
//       <AutoCompleteContainer isFocused={isFocused}>
//         <InputBox
//           placeholder={placeholder}
//           inputProps={{
//             autoCorrect: false,
//             autoCapitalize: 'none',
//             onPressIn: handleFocus,
//             onBlur: blur,
//             onSubmitEditing: ({ text: changed }) => {
//               isSelectedOnly ? blur() : handleTextChange(text)
//             }
//           }}
//           label={label}
//           text={text}
//           setText={handleTextChange}
//           margin={0}
//         />
//         {isFocused && options.length > 0 && (
//           <AutoCompleteList style={{ shadowOffset: { x: 0, y: 4 } }}>
//             <FlatList
//               keyExtractor={(item) => item.value}
//               data={options}
//               renderItem={renderItem}
//               keyboardShouldPersistTaps
//               nestedScrollEnabled
//             />
//           </AutoCompleteList>
//         )}
//       </AutoCompleteContainer>

//       {isFocused && (
//         <TouchableWithoutFeedback onPress={() => setIsFocused(false)}>
//           <View
//             style={{
//               zIndex: 1,
//               position: 'absolute',
//               top: 0,
//               left: 0,
//               right: 0,
//               bottom: 0
//             }}
//           />
//         </TouchableWithoutFeedback>
//       )}
//     </>
//   )

export default AutoComplete
