import api from 'venus/api'
import { useCallback, useEffect, useState } from 'react'

const useSpaces = (propertyId?: string) => {
  const [spaces, setSpaces] = useState([])

  const getSpaces = async (pid?: string) => {
    const id = pid || propertyId

    if (id) {
      const result = await api
        .get(`/property/${id}/spaces`, {
          params: { orderBy: 'update' },
        })
        .then(({ data }) => data)
      setSpaces(result)

      return result
    }

    return []
  }

  useEffect(() => {
    const fetchSpaces = async (pid?: string) => {
      const id = pid || propertyId

      if (id) {
        const result = await api
          .get(`/property/${id}/spaces`, {
            params: { orderBy: 'update' },
          })
          .then(({ data }) => data)
        setSpaces(result)

        return result
      }

      return []
    }

    if (propertyId) {
      fetchSpaces()
    }
  }, [propertyId])

  return { spaces, getSpaces }
}

export default useSpaces
