import styled from 'styled-components'
import { theme } from 'venus/config'

export const CardViewContainer = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${theme.colors.LighterGrey};
  border-radius: 12px;
  background-color: ${theme.colors.White};
  margin-bottom: 10px;

  &:hover {
    background-color: ${theme.colors.LighterGrey};
    cursor: pointer;
  }
`

type ICardBoxProps = {
  readonly flex: number;
}

export const CardBox = styled.div<ICardBoxProps>`
  width: 300px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 30px;
`

export const RatingBox = styled.div<ICardBoxProps>`
   flex: ${(props) => props.flex};
   display: flex;
   justify-content: flex-start;
`


export const TagBox = styled.div`
  flex: 1;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 45px;
`
