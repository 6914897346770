import { FileUploader, Text } from 'venus/components'

export const FileUploaderSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const setUploadURL = () => null
  const setUploadName = () => null

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <FileUploader
        setUploadURL={setUploadURL}
        setUploadName={setUploadName}
        onFinish={() => null}
      />
    </>
  )
}
