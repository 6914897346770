import { ReactElement } from 'react'
import { GroupWrapper, ButtonWrapper } from './ButtonGroup.styles'

export interface IButtonGroup {
  icon: ReactElement
  onClick: () => void
}

export const ButtonGroup = ({ buttons }: { buttons: IButtonGroup[] }) => {
  return (
    <GroupWrapper>
      {buttons.map(({ icon, onClick }, index) => (
        <ButtonWrapper key={`btnGroup_${index}`} onClick={onClick}>
          {icon}
        </ButtonWrapper>
      ))}
    </GroupWrapper>
  )
}
