import { useEffect, useMemo } from 'react'
import { getSharedProperties } from 'venus/redux/features/property/sharedProperties'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'

const useShareStatus = (propertyId, shareId) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (shareId) {
      dispatch(getSharedProperties())
    }
  }, [shareId])

  const { sharedScope } = useAppSelector((state) => state.sharedProperties)

  const share = useMemo(
    () => (sharedScope || []).find((scope) => scope.id === shareId),
    [sharedScope, shareId],
  )

  return {
    share: share || {},
  }
}

export default useShareStatus
