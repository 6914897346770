import styled from 'styled-components'
import { getColorsFromUrl, isColorBackground } from 'venus/utils'

import { ReactComponent as Pencil } from 'venus/assets/img/icons/Pencil.svg'
import { defaultPillsTabConfig, theme } from 'venus/config'
import { Modal, PillTabs } from 'venus/components'
import { useState } from 'react'
import BackgroundPicker from './BackgroundPicker'
import Gallery from './Gallery'
import Upload from './Upload'

type ImageContainerProps = {
  readonly image?: string
  readonly isGradient?: boolean
  readonly colors?: string[]
}
const CoverImageContainer = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  aspect-ratio: 1.9;
`

const Image = styled.div<ImageContainerProps>`
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  background-color: ${(props) => props.theme.colors.Grey};
  ${(props) =>
    props.isGradient
      ? `
    background: linear-gradient(0deg, ${props.colors[0]}, ${props.colors[1]});
  `
      : `background-image: url(${props.image});`}
`

const EditButton = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;

  position: absolute;
  bottom: 11px;
  left: 11px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.Primary};
  width: 30px;
  height: 30px;
  padding: 4.5px;
  align-items: center;
  justify-content: center;
`

const CoverImage = ({ coverImage, images, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const isGradient = isColorBackground(coverImage)

  console.log('coverImage', coverImage)

  const openModal = () => {
    setIsOpen(true)
  }
  const handleChange = (data) => {
    onChange(data)
    setIsOpen(false)
  }

  const colors = isGradient ? getColorsFromUrl(coverImage) : []

  return (
    <CoverImageContainer>
      <Image image={coverImage} isGradient={isGradient} colors={colors} />
      <EditButton type='button' onClick={openModal}>
        <Pencil width={16} color={theme.colors.White} />
      </EditButton>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title='Cover Image Picker'>
        <div style={{ margin: '0 34px' }}>
          <PillTabs
            // onChange={setActiveIndex}
            tabs={[
              { label: 'Color and Gradient', key: 'color' },

              { label: 'Gallery', key: 'gallery' },
              { label: 'Upload', key: 'upload' },
            ]}
            config={defaultPillsTabConfig}
            // style={{ marginLeft: -5, marginBottom: 20, position: 'fixed' }}
          >
            <BackgroundPicker onChange={handleChange} />
            <Gallery onChange={handleChange} images={images} />
            <Upload onChange={handleChange} />
          </PillTabs>
        </div>
      </Modal>
    </CoverImageContainer>
  )
}

export default CoverImage
