import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { roles, tradesPeopleSubRolesArr } from 'venus/config'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { ContactLayout } from 'venus/layout/Contact'
import isEmail from 'validator/lib/isEmail'
import {
  getContactList,
  updateContact,
  createContact,
  deleteContact,
  IPhoneContactFields,
  setError,
  resetError,
} from 'venus/redux/features/contact'
import {
  Input,
  Select,
  AlertBox,
  Loader,
  Avatar,
  ActionLayout,
} from 'venus/components'
import {
  RolesWrapper,
  RoleItemWrapper,
  AvatarWrapper,
} from './CreateEditContact.styles'
import _ from 'lodash'

interface ICreateEditContact {
  setShow: Function
  setShowEdit?: Function
  contactId?: string | undefined
  prefilled?: IPhoneContactFields
  propertyId: string
  isEditMode: boolean
  isCreateMode: boolean
  showModal?: boolean
}

type PropertyFormData = {
  avatar: string
  firstName: string
  lastName: string
  mobile: string
  role1: string
  role2: string
  email: string
  companyName: string
  companyAddress: string
  companyPhone: string
  companyURL: string
  abn: string
  license: string
}

export const CreateEditContact = ({
  setShow,
  setShowEdit,
  contactId,
  propertyId,
  prefilled,
  isEditMode,
  isCreateMode,
  showModal = true,
}: ICreateEditContact) => {
  const { entries, loading, error } = useAppSelector((state) => state.contact)
  const { url, progress, image } = useAppSelector((state) => state.image)
  const {
    profile: { mobile: loginMobile },
  } = useAppSelector((state) => state.profile)
  const entry = entries.filter(({ id }) => id === contactId)[0]
  const selected = prefilled || entry
  const dispatch = useAppDispatch()

  const details = {
    defaultValues: selected
      ? {
          avatar: contactId ? entry.profile.avatar : prefilled?.avatar,
          firstName: contactId ? entry.firstName : prefilled?.firstName,
          lastName: contactId ? entry.lastName : prefilled?.lastName,
          mobile: contactId ? entry.mobile : prefilled?.mobile,
          role1: contactId ? entry.role[0] : '',
          role2: contactId
            ? !!entry.tradespeopleRole
              ? entry.tradespeopleRole[0]
              : ''
            : '',
          email: contactId
            ? entry.email
            : _.get(prefilled, 'emails[0].email', ''),
          companyName: contactId
            ? entry.companyName
            : !!prefilled?.companyName
            ? prefilled?.companyName
            : '',
          companyAddress: contactId ? entry.companyAddress : '',
          companyPhone: contactId ? entry.companyPhone : '',
          companyURL: contactId ? entry.companyURL : '',
          abn: contactId ? entry.abn : '',
          license: contactId ? entry.license : '',
        }
      : {},
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    formState,
    getValues,
    setValue,
  } = useForm<PropertyFormData>(details)

  const onSubmit = async (data: any) => {
    if (loginMobile === _.trim(data.mobile)) {
      dispatch(
        setError(
          'Can not create contact with the same mobile number as login user.'
        )
      )
      return
    }
    const body: {
      firstName: string
      lastName: string
      mobile: string
      email: string
      companyName: string
      companyAddress: string
      companyPhone: string
      companyURL: string
      role: string[]
      tradespeopleRole: string[]
      abn: string
      license: string
      id?: string
      profile?: {
        avatar: string
      }
      avatar?: string
    } = {
      firstName: _.trim(data.firstName),
      lastName: _.trim(data.lastName),
      mobile: _.trim(data.mobile),
      email: _.trim(data.email),
      companyName: _.trim(data.companyName),
      companyAddress: _.trim(data.companyAddress),
      companyPhone: _.trim(data.companyPhone),
      companyURL: _.trim(data.companyURL),
      role: [data.role1.toUpperCase()],
      tradespeopleRole: data.role2 ? [data.role2.toUpperCase()] : [],
      abn: _.trim(data.abn),
      license: _.trim(data.license),
    }
    const avatar = data.avatar ? data.avatar.trim() : ''
    if (isEditMode) {
      body.id = contactId
      body.profile = { avatar }
    }
    if (isCreateMode) {
      body.avatar = avatar
    }
    const result = isEditMode
      ? await dispatch(
          updateContact({
            body,
            propertyId,
            contactId,
          })
        )
      : await dispatch(createContact({ body, propertyId }))

    if (result.meta.requestStatus === 'fulfilled') {
      setShow(false)
      if (setShowEdit) setShowEdit(false)
      await dispatch(getContactList(propertyId))
    }

    return false
  }
  const [isDisable, setIsDisable] = useState(
    getValues('role1') !== 'TRADESPEOPLE'
  )
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const handlePressDelete = () => {
    setShowConfirmDelete(true)
  }

  const handlPressAlertOK = async () => {
    const data = await dispatch(deleteContact({ propertyId, contactId }))

    setShowConfirmDelete(false)
    if (data.meta.requestStatus === 'fulfilled') {
      setShow(false)
      if (setShowEdit) setShowEdit(false)
    }
  }

  const handlPressAlertCancel = () => {
    setShowConfirmDelete(false)
  }

  const handlePressGoBack = () => {
    setShow(false)
  }

  const handleValidateEmail = (v: string) => {
    if (!v) return true
    return isEmail(v)
  }

  const handlPressCloseErrorMsg = () => {
    dispatch(resetError())
  }

  if (loading) return <Loader isFull />

  const Body = (
    <ActionLayout
      leftActions={
        isEditMode
          ? [
              {
                type: 'error_reverse',
                text: 'Delete',
                onClick: handlePressDelete,
                isLoading: loading,
              },
            ]
          : []
      }
      rightActions={[
        {
          type: 'primary',
          text: 'Save',
          onClick: handleSubmit(onSubmit),
          isLoading: loading,
        },
      ]}
    >
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <AvatarWrapper>
              <Avatar
                editable
                size={129}
                avatar={value}
                handleOnEditAvatar={onChange}
                showEditBtn={true}
              />
            </AvatarWrapper>
          )
        }}
        name='avatar'
      />
      <RolesWrapper>
        <RoleItemWrapper style={{ width: '49%' }}>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (v) => v === v.trim(),
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                label='First Name'
                value={value}
                onChange={onChange}
                isError={!!errors.firstName}
                errorMsg={
                  !!value && value !== value?.trim()
                    ? 'Please remove leading or trailing spaces.'
                    : 'This is required.'
                }
                style={{ marginBottom: 10 }}
              />
            )}
            name='firstName'
          />
        </RoleItemWrapper>
        <RoleItemWrapper style={{ width: '2%' }} />
        <RoleItemWrapper style={{ width: '49%' }}>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (v) => v === v.trim(),
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                label='Last Name'
                value={value}
                onChange={onChange}
                isError={!!errors.lastName}
                errorMsg={
                  !!value && value !== value?.trim()
                    ? 'Please remove leading or trailing spaces.'
                    : 'This is required.'
                }
              />
            )}
            name='lastName'
          />
        </RoleItemWrapper>
      </RolesWrapper>
      <Controller
        control={control}
        rules={{
          required: true,
          minLength: 10,
          maxLength: 10,
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            label='Mobile Number'
            value={value}
            onChange={onChange}
            isError={!!errors.mobile}
            errorMsg={
              errors?.mobile?.type === 'required'
                ? 'This is required.'
                : 'Mobile number should be 10 digits.'
            }
            style={{ marginBottom: 10 }}
          />
        )}
        name='mobile'
      />
      <RolesWrapper style={{ marginBottom: 10 }}>
        <RoleItemWrapper style={{ width: '49%' }}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  title='Role'
                  showTransparentBg={false}
                  value={
                    !formState.dirtyFields.role1 && selected?.role
                      ? selected?.role[0]
                      : value
                  }
                  onChange={(v) => {
                    onChange(v)
                    if (v === 'TRADESPEOPLE') {
                      setIsDisable(false)
                    } else {
                      setIsDisable(true)
                      setValue('role2', '')
                    }
                  }}
                  options={roles}
                  isError={!!errors.role1}
                  errorMsg='This is required.'
                />
              )
            }}
            name='role1'
          />
        </RoleItemWrapper>
        <RoleItemWrapper style={{ width: '2%' }} />
        <RoleItemWrapper style={{ width: '49%' }}>
          <Controller
            control={control}
            rules={{
              required: getValues('role1') === 'TRADESPEOPLE',
            }}
            render={({ field: { onChange, value } }) =>
              getValues('role1') === 'TRADESPEOPLE' ? (
                <Select
                  title={'Tradespeople Role'}
                  showTransparentBg={false}
                  value={
                    !formState.dirtyFields.role2 && selected?.tradespeopleRole
                      ? selected.tradespeopleRole[0]
                      : value
                  }
                  onChange={onChange}
                  options={tradesPeopleSubRolesArr}
                  isDisabled={isDisable}
                  isError={!!errors.role2}
                  errorMsg='This is required.'
                />
              ) : null
            }
            name='role2'
          />
        </RoleItemWrapper>
      </RolesWrapper>
      <Controller
        control={control}
        rules={{
          required: false,
          validate: (v) => handleValidateEmail(v),
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            label='E-mail'
            value={value}
            onChange={onChange}
            isError={!!errors.email}
            errorMsg={
              getValues('email') !== getValues('email')?.trim()
                ? 'Please remove leading or trailing spaces.'
                : 'This email address is invalid.'
            }
            style={{ marginBottom: 10 }}
          />
        )}
        name='email'
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label='Company Name'
            value={value}
            onChange={onChange}
            style={{ marginBottom: 10 }}
          />
        )}
        name='companyName'
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label='ABN'
            value={value}
            onChange={onChange}
            style={{ marginBottom: 10 }}
          />
        )}
        name='abn'
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label='Company Address'
            value={value}
            onChange={onChange}
            style={{ marginBottom: 10 }}
          />
        )}
        name='companyAddress'
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label='Company Phone'
            value={value}
            onChange={onChange}
            style={{ marginBottom: 10 }}
          />
        )}
        name='companyPhone'
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label='Company Website'
            value={value}
            onChange={onChange}
            style={{ marginBottom: 10 }}
          />
        )}
        name='companyURL'
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label='License'
            value={value}
            onChange={onChange}
            style={{ marginBottom: 10 }}
          />
        )}
        name='license'
      />
      <AlertBox
        title='Delete contact'
        message='Are you sure you want to remove this contact?'
        cancel={handlPressAlertCancel}
        btnGroup={[
          {
            text: 'Delete',
            onClick: handlPressAlertOK,
            color: 'ErrorState',
            type: 'error',
          },
          {
            text: 'Cancel',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: handlPressAlertCancel,
            type: 'secondary',
          },
        ]}
        showAlert={isEditMode && showConfirmDelete}
      />
      <AlertBox
        title='Contact Error'
        cancel={handlPressCloseErrorMsg}
        message={error}
        btnGroup={[
          { text: 'OK', onClick: handlPressCloseErrorMsg, type: 'error' },
        ]}
        showAlert={!!error}
      />
    </ActionLayout>
  )

  if (showModal)
    return (
      <ContactLayout
        handlePressGoBack={handlePressGoBack}
        propertyId={propertyId}
        title={isEditMode ? 'Edit Contact' : 'Create Contact'}
      >
        {Body}
      </ContactLayout>
    )

  return Body
}
