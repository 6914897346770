import styled from 'styled-components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'

const PlanContainer = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;

  position: relative;
  margin-bottom: 16px;
  height: 82px;
  padding: 16px 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.LightBlue};

  display: flex;
  flex-direction: column;
`

const PlanDisplay = ({ display, index, name, description, onSelect }: any) => {
  const handlePress = () => onSelect(index)
  if (!display) {
    return null
  }
  return (
    <PlanContainer onClick={handlePress}>
      <Text
        format='h2'
        style={{ marginBottom: 5 }}
        value={name}
        color={theme.colors.Primary}
      />
      <div>
        <Text format='body' value={description} color={theme.colors.DarkestGrey} />
      </div>
    </PlanContainer>
  )
}

export default PlanDisplay
