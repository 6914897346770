import styled from 'styled-components'
import { theme } from 'venus/config'

export const Wrapper = styled.div`
  height: 440px;
  overflow: scroll;
  padding-bottom: 24px;
`

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
  cursor: pointer;
  border-radius: 9px;
  &:hover {
    background-color: ${theme.colors.LightGrey};
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
`

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
`
