import { Modal } from 'venus/components'
import { ModalContent } from './PreviewDoc.styles'

interface IPreviewDoc {
  isOpenPreview: boolean
  handleClosePreview: () => void
  url: string
  displayName: string
}

export const PreviewDoc = ({
  isOpenPreview,
  handleClosePreview,
  url,
  displayName,
}: IPreviewDoc) => {
  return (
    <>
      <Modal
        isOpen={isOpenPreview}
        onClose={handleClosePreview}
        showCloseButton={false}
        width={800}
      >
        <ModalContent src={url} />
      </Modal>
    </>
  )
}
