import { useEffect, useState } from 'react'

const useRefDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 1024, height: 768 })
  useEffect(() => {
    const getDimensions = () => {
      const { current } = ref
      const boundingRect = current?.getBoundingClientRect()
      const { width, height } = boundingRect
      setDimensions({ width: Math.round(width), height: Math.round(height) })
    }

    const handleResize = () => {
      getDimensions()
    }

    if (ref.current) {
      getDimensions()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref])
  return dimensions
}
export default useRefDimensions
