import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useParams } from 'react-router-dom'
import { Create, Reminder } from 'venus/pages/Reminder'
import { resetError } from 'venus/redux/features/reminder'
// import MHVPremium from 'mhv/scenes/Transfer/MHVPremium'
// import { useShareStatus } from 'mhv/hooks/useShareStatus'
import useShowPremium from 'venus/hooks/useShowPremium'
import {
  getReminderList,
  setDetail,
  setStatus,
  TDetail,
} from 'venus/redux/features/reminder'
import { TabView, Loader, Text, AlertBox, PillTag, Button } from 'venus/components'
import { FREE, Reminder_URLs, States, theme } from 'venus/config'
import { SubList } from './SubList'
import { ListContainer, Row } from './List.styles'
import usePremium from 'venus/hooks/usePremium'
import usePremiumContext from 'venus/context/MHVPremium'
import { Header, IconArea, Wrapper } from 'venus/pages/Share/Shared/Shared.styles'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import MHVPremium from 'venus/common/MHVPremium'

const tabs = ['Due', 'Ongoing', 'Upcoming']

export const ReminderList = () => {
  const dispatch = useAppDispatch()
  const { list, loading, error } = useAppSelector((state) => state.reminder)
  const { propertyId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [step, setStep] = useState(2)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isReminderOpen, setIsReminderOpen] = useState(false)

  const { propertyStatus } = usePremium(propertyId)
  const { openPremium } = usePremiumContext()
  // const { vaults } = useAppSelector((state) => state.subscription)
  // const subscriptionStatus = vaults[propertyId]
  // const { isShown, onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )

  // const [showFreemiumAlert, setShowFreemiumAlert] = useState(isShown)

  // useEffect(() => {
  //   setShowFreemiumAlert(isShown)
  // }, [isShown])

  // const handleOnCancel = () => {
  //   setShowFreemiumAlert(false)
  //   onCancel()
  // }

  // const handleViewPlan = () => {
  //   setShowFreemiumAlert(false)
  //   onViewPlan()
  // }

  useEffect(() => {
    if (propertyId && propertyStatus) {
      console.log('trigger', propertyStatus)

      // remove premium check for reminder list
      //      if (propertyStatus === FREE) {
      //        openPremium()
      //      } else {
      dispatch(getReminderList(propertyId))
      //      }
    }
  }, [propertyId, propertyStatus])

  // const { isShared } = useShareStatus()
  // if (isShared) return null

  if (loading || !list) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  const upcoming = list.filter(({ status }) => status === 'UPCOMING')
  const due = list.filter(({ status }) => status === 'DUE')
  const ongoing = list.filter(({ status }) => status === 'ONGOING')

  const pressDetail = (detail: TDetail) => {
    const { status } = detail
    dispatch(setDetail(detail))
    dispatch(setStatus(status))

    const index = status === 'DUE' ? 0 : status === 'ONGOING' ? 1 : 2
    if (index !== activeIndex) setActiveIndex(index)

    setStep(2)
    setIsOpen(true)
  }

  const handleCloseReminder = () => {
    setStep(2)
    setIsOpen(false)
  }

  const handlePressAlertOK = () => {
    dispatch(resetError())
  }

  const handleCloseNewReminder = () => {
      setIsReminderOpen(false)
  }

  const openNewReminder = () => {
      // check if current property is premium or not
      if (propertyStatus !== 'paid') {
        openPremium()
      } else {
        setIsReminderOpen(true)
      }
  }

  const tabs = [
    <Row>
      <Text
        style={{ whiteSpace: 'nowrap' }}
        value={'Due'}
        format='h3'
        fontSize={12}
        color={activeIndex === 0 ? theme.colors.Primary : theme.colors.DarkGrey}
      />
      {!!due.length && (
        <PillTag
          label={due.length}
          background={theme.colors.ErrorState}
          color={theme.colors.White}
          fontSize={14}
          fontWeight={500}
          lineHeight={1}
          paddingHorizontal={10}
          paddingVertical={2}
          style={{ margin: '2px 8px' }}
        />
      )}
    </Row>,
    <Row>
      <Text
        style={{ whiteSpace: 'nowrap' }}
        value={'Ongoing'}
        format='h3'
        fontSize={12}
        color={activeIndex === 1 ? theme.colors.Primary : theme.colors.DarkGrey}
      />
      {!!ongoing.length && (
        <PillTag
          label={ongoing.length}
          background={activeIndex === 1 ? theme.colors.Primary : theme.colors.DarkGrey}
          color={theme.colors.White}
          fontSize={14}
          fontWeight={500}
          lineHeight={1}
          paddingHorizontal={10}
          paddingVertical={2}
          style={{ margin: '2px 8px' }}
        />
      )}
    </Row>,
    <Row>
      <Text
        style={{ whiteSpace: 'nowrap' }}
        value={'Upcoming'}
        format='h3'
        fontSize={12}
        color={activeIndex === 2 ? theme.colors.Primary : theme.colors.DarkGrey}
      />
      {!!upcoming.length && (
        <PillTag
          label={upcoming.length}
          background={activeIndex === 2 ? theme.colors.Primary : theme.colors.DarkGrey}
          color={theme.colors.White}
          fontSize={14}
          fontWeight={500}
          lineHeight={1}
          paddingHorizontal={10}
          paddingVertical={2}
          style={{ margin: '2px 8px' }}
        />
      )}
    </Row>,
  ]

  return (
    <>
      <Create isOpen={isReminderOpen} onClose={handleCloseNewReminder} />
      <Wrapper>
        <Header>
          <Text value='Reminder' format='h1' />
          <IconArea>
            <Button
              type='icon_button'
              text='Add'
              onClick={openNewReminder}
              iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
              style={{ width: 'auto' }}
            />
          </IconArea>
        </Header>
      <ListContainer>
        <TabView
          defaultActiveIndex={activeIndex}
          onChange={setActiveIndex}
          tabs={tabs}
          paddingBottom={100}
          headerStyle={{ padding: '0 34px' }}
          style={{ minHeight: 0, padding: 0 }}
        >
          <SubList handlePress={pressDetail} list={due} />
          <SubList handlePress={pressDetail} list={ongoing} />
          <SubList handlePress={pressDetail} list={upcoming} />
        </TabView>
      </ListContainer>
      <Reminder
        onClose={handleCloseReminder}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        step={step}
        setStep={setStep}
      />
      <AlertBox
        cancel={handlePressAlertOK}
        title={`Reminder Error`}
        message={error}
        btnGroup={[
          {
            text: 'OK',
            onClick: handlePressAlertOK,
            color: 'Primary',
            type: 'error',
          },
        ]}
        showAlert={!!error}
      />
      {/* <MHVPremium
        isOpen={showFreemiumAlert}
        onViewPlan={handleViewPlan}
        onCancel={handleOnCancel}
      /> */}
     </Wrapper>
    </>
  )
}
