export const theme = {
  colors: {
    Primary: '#100d42',
    Secondary: '#49c3ca',
    LightBlue: '#F5F6FF',
    BlueGrey: '#CFCFD9',
    ErrorState: '#E46363',
    Black: '#202020',
    DarkGrey: '#AFAFAF',
    Grey: '#d8d8d8',
    LightGrey: '#F9F9F9',
    LighterGrey: '#f5f5f5',
    White: '#ffffff',
    LightGreen: '#22dbdc',
    SafeGreen: '#92C580',
    FreeYellow: '#fbd322',
    Purple: '#AF2A82',
    Yellow: '#FABA17',
    Green: '#057C11',
    Orange: '#de6714',
    DarkOrange: '#F26601',
    LightOrange: '#faf4ee',
    DangerRed: '#DE0D0D',
    DarkestGrey: '#717171',
    LightRed: '#FEF0F0',
  },
  opacity: {
    minimum: 'rgba(52, 52, 52, 0.1)',
    grey: 'rgba(0,0,0,0.5)',
  },
}

type TColors = typeof theme.colors

export type TColor = keyof TColors
