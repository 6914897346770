import styled from 'styled-components'

export const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.LighterGrey};
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
`

export const Section = styled.div`
  display: flex;
`
