import React from 'react'
import { useState } from 'react'
import { Text } from 'venus/components'
import { Input } from 'venus/components'
import { REFERRAL_GUIDE_TEXT } from 'venus/layout/ProfileMenu/ProfileSettings/Referral/Constants'
import { REFERRAL_INPUT_LABEL } from 'venus/layout/ProfileMenu/ProfileSettings/Referral/Constants'
import { REFERRAL_BTN_TEXT } from 'venus/layout/ProfileMenu/ProfileSettings/Referral/Constants'
import { InputWrapper } from 'venus/layout/ProfileMenu/ProfileSettings/Referral/Referral.styles'
import { useAppSelector } from 'venus/redux/hooks'
import { ActionLayout } from 'venus/components'
import { SettingsHeader } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader'
import { REFERRAL_COPIED_TEXT } from 'venus/layout/ProfileMenu/ProfileSettings/Referral/Constants'

const Referral = () => {
  const { info } = useAppSelector((state) => state.user)
  const [isCopied, setIsCopied] = useState(false)

  const handleClickCopyCode = () => {
    if (!isCopied) {
      navigator.clipboard.writeText(info?.referralCode).then(() => {
        setIsCopied(true)
      })
    }
  }

  return (
    <ActionLayout
      margin={28}
      children={
        <>
          <SettingsHeader title={'Referral'} />
          <Text format={'h4'} value={REFERRAL_GUIDE_TEXT} style={{ marginTop: '28px' }} />
          <InputWrapper>
            <Input
              type={'text'}
              label={REFERRAL_INPUT_LABEL}
              onChange={null}
              value={info?.referralCode || ''}
              isDisabled={true}
              style={{
                marginTop: '24px',
              }}
            />
          </InputWrapper>
        </>
      }
      rightActions={[
        {
          type: !isCopied ? 'primary' : 'secondary',
          text: !isCopied ? REFERRAL_BTN_TEXT : REFERRAL_COPIED_TEXT,
          onClick: handleClickCopyCode,
          isDisabled: isCopied,
        },
      ]}
    />
  )
}

export default Referral
