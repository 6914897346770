import _ from 'lodash'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAuthToken, setAuthToken } from 'venus/auth/auth'
import { getErrorMsg } from 'venus/utils'
import { setErrorMessage } from 'venus/redux/features/error/reducer'
import getStore from 'venus/redux/store'

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean
  }
}

export const baseURL = process.env.API_URL || 'https://api.dev.myhomevault.com.au'
// export const baseURL = 'http://localhost:8000'

let isRefreshing = false
const refreshSubscribers: (() => void)[] = []

export const axiosClient = axios.create({
  baseURL,
})

let api = axios.create({
  baseURL,
  headers: {
    'Cache-Control': 'no-cache',
  },
})

const status = [403]
const notAuthenticated = (response: AxiosResponse) =>
  response ? status.includes(response.status) : false
const subscribeToRefresh = (cb: () => void) => {
  refreshSubscribers.push(cb)
}
const onRefreshed = () => {
  refreshSubscribers.map((cb) => cb())
  refreshSubscribers.length = 0 // Empty subscribers array after all callbacks are executed
}

const redirectToLogin = () => {
  //   cleanUp()
  //   navigate(Login_Page_URLs.StackName.url, { screen: Login_Page_URLs.Login.url })

  window.location.replace('/login')
}

let requestInterceptor: any
let responseInterceptor: any

const createAxiosInstance = () => {
  requestInterceptor = api.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const originalConfig = config
      const authToken = getAuthToken()
      if (authToken) {
        originalConfig.headers!.Authorization = `Bearer ${authToken}`
      }
      return Promise.resolve(originalConfig)
    },
    (error: AxiosError) => Promise.reject(error),
  )

  responseInterceptor = api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const { config, response } = error

      console.log('interceptor', response)

      if (notAuthenticated(response!)) {
        /*
          Attempt to refresh accessToken if the request is not a retry
          Second unsuccessful retry will redirect to login
        */
        if (!config._retry) {
          config._retry = true

          if (!isRefreshing) {
            isRefreshing = true

            getAccessToken()
              .then(() => {
                isRefreshing = false
                onRefreshed()
              })
              .catch((err: any) => {
                isRefreshing = false

                console.log('redirect to login', err)
                // if transfer, send redirection url to login page
                if (window.location.pathname.includes('/deeplink/')) {
                  return (window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`)
                }
                redirectToLogin()
              })
          }

          const retryOrig = new Promise((resolve) => {
            subscribeToRefresh(async () => {
              const token = getAuthToken()

              console.log('set header', token)
              config.headers!.Authorization = `Bearer ${token}`
              config.baseURL = undefined // Otherwise results in urls like /api/v1/api/v1
              resolve(api(config))
            })
          })

          return retryOrig
        }
        // Do not need to redirect to logout as session is effectively does not exist at this stage
        // redirectToLogin();
        console.log('Not authed')
        // This is here jic if workflow changes
        // Currently will not get executed as redirectToLogin();
        return Promise.reject(response)
      }
      console.log('Reject', error)
      const { status } = response
      // TODO: 500 show internal server error (from BE)
      if (status === 400 || status === 500) {
        const errorMsg = getErrorMsg(error)
        const store = getStore()
        // dispatch string error message
        if (_.isString(errorMsg)) {
          store.dispatch(setErrorMessage(errorMsg))
        }
      }
      return Promise.reject(response)
    },
  )

  return api
}

const API_REFRESH_TOKEN = '/auth/refresh-token'

export const getAccessToken = async () => {
  // const credential = await getAuthCredential()

  //   const refreshToken = await getRefreshToken()

  //   if (!refreshToken) {
  //     console.log('no credential', refreshToken)
  //     throw new Error('No credential')
  //   }
  try {
    const response = await axios({
      baseURL,
      method: 'post',
      url: API_REFRESH_TOKEN,
      withCredentials: true,
    })

    const { accessToken } = response.data

    await setAuthToken(accessToken)

    return accessToken
  } catch (error) {
    throw new Error('No credential')
  }
}
createAxiosInstance()

export default api
