import { ImageWrapper } from './ConfirmNonNpdHomePhoto.styles'
import { Add_Dream_Property_Type, theme } from 'venus/config'
import { Text, ActionLayout, Image } from 'venus/components'
import { useAppSelector } from 'venus/redux/hooks'
import { formatFullAddress } from 'venus/utils/address'

interface IConfirmNonNpdHomePhoto {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  addType: string
}

export const ConfirmNonNpdHomePhoto = ({
  setStep,
  setTitle,
  addType,
}: IConfirmNonNpdHomePhoto) => {
  const { preview } = useAppSelector((state) => state.address)
  const { address, image } = preview[0] as any

  const handlePress = async () => {
    const isDreamProperty = addType === Add_Dream_Property_Type
    setStep(isDreamProperty ? 2 : 3)
    setTitle('Confirm your home')
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          text: 'This is my home',
          onClick: handlePress,
          isDisabled: !address,
        },
      ]}
    >
      <ImageWrapper>
        <Image
          image={image}
          style={{ width: '412px', aspectRatio: 1.3, borderRadius: '5px' }}
        />
        <Text
          value={formatFullAddress(address)}
          color={theme.colors.Black}
          style={{ alignSelf: 'flex-start', marginTop: 12, marginBottom: 12 }}
          format='body'
        />
      </ImageWrapper>
    </ActionLayout>
  )
}
