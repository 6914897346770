import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from 'venus/api'
import { errorHandling } from 'venus/utils'
import { getDreamAVM, getDreamRentalAVM, getDreamStatistics } from '../dreamprice/async'

export const getData = createAsyncThunk(
  'npdData/getData',
  async ({ propertyId }: { propertyId: string }) => {
    try {
      const response = await api.get(`/property/${propertyId}/data`)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const getRentalAVM = createAsyncThunk(
  'npdData/getRental',
  async ({ propertyId }: { propertyId: string }) => {
    try {
      const response = await api.get(`/property/${propertyId}/rental`)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const getAVM = createAsyncThunk(
  'npdData/getAVM',
  async ({ propertyId }: { propertyId: string }) => {
    try {
      const response = await api.get(`/property/${propertyId}/avm`)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const getStatistics = createAsyncThunk(
  'npdData/getStatistics',
  async ({ propertyId, type = 'A' }: { propertyId: string; type: string }) => {
    try {
      const response = await api.get(`/property/${propertyId}/statistics`, {
        params: { type },
      })
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

const initialState = {
  error: null,
  loading: true,
  data: { avm: {}, rentalAvm: {} },
} as {
  error: object | null | undefined
  loading: boolean
  data: object
}

const npdSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    resetNpdStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state) => {
      state.loading = true
      state.data = {}
    })
    builder.addCase(getData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })

    builder.addCase(getData.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })

    builder.addCase(getStatistics.pending, (state, action) => {
      //  state.loading = false
      state.data = { ...state.data, statistics: {} }
    })

    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.loading = false
      state.data = { ...state.data, statistics: action.payload }
    })

    builder.addCase(getDreamStatistics.pending, (state, action) => {
      //  state.loading = false
      state.data = { ...state.data, statistics: {} }
    })

    builder.addCase(getAVM.fulfilled, (state, action) => {
      state.loading = false
      state.data = { ...state.data, avm: action.payload }
    })

    builder.addCase(getDreamAVM.pending, (state) => {
      state.data = { ...state.data, avm: {} }
    })

    builder.addCase(getRentalAVM.fulfilled, (state, action) => {
      state.loading = false
      state.data = { ...state.data, rentalAvm: action.payload }
    })

    builder.addCase(getDreamRentalAVM.pending, (state) => {
      state.data = { ...state.data, rentalAvm: {} }
    })
  },
})

export const { resetNpdStore } = npdSlice.actions

export default npdSlice.reducer
