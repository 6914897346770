import { ActionLayout, Modal } from 'venus/components'
import ItemForm from './ItemForm'
import styled from 'styled-components'

import { useRef } from 'react'
const ModalContent = styled.div`
  overflow: auto;
  padding: 0 34px;
`

const EditItem = ({ isOpen, onClose, item, onSubmit, isPersonal = false }) => {
  const refSubmitButtom = useRef<HTMLButtonElement>(null)
  const triggerSubmit = () => {
    refSubmitButtom?.current?.click()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Edit Item'>
      <ActionLayout
        margin={34}
        rightActions={[
          {
            type: 'primary',
            text: 'Update',
            onClick: triggerSubmit,
          },
        ]}
      >
        <ItemForm
          isPersonal={isPersonal}
          onSubmit={onSubmit}
          defaultValues={item}
          refSubmitButtom={refSubmitButtom}
        />
      </ActionLayout>
    </Modal>
  )
}

export default EditItem
