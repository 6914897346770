import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import api from 'venus/api'
import { IImage } from 'venus/types/Image'
import { IItem } from 'venus/types/Item'
import { ISpace } from 'venus/types/Space'
import { getErrorMsg } from 'venus/utils'

export interface IProperty {
  id: string
  address: {
    state: string
    suburb: string
    address: string
    postcode: string
  }
  propertyData: {
    area: number
    beds: number
    baths: number
    parking: number
  }
  spacesId: string[]
  propertyWideSpaceId: string
  coverImage?: string
  images: IImage[]
  spaces: ISpace[]
}

export const getItems = createAsyncThunk(
  'personal/getItems',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/personal/itemList')
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  }
)

const initialState = {
  error: null,
  items: [],
  loading: false,
} as {
  error: ''
  items: IItem[]
  loading: boolean
}

const personal = createSlice({
  name: 'personal',
  initialState,
  reducers: {
    resetPersonalStore: () => initialState,
    setError: (state, action) => {
      state.error = action.payload
    },
    addPersonalItems: (state, action) => {
      state.items = action.payload
    },
    updatePersonalItem: (state, action) => {
      if (_.isArray(action.payload)) {
        state.items = action.payload
      } else {
        const filtered = state.items.filter(
          (item) => item.id !== action.payload.id
        )
        const oldItem = state.items.find(
          (item) => item.id === action.payload.id
        )
        state.items = [{ ...oldItem, ...action.payload }, ...filtered]
      }
    },
    removePersonalItem: (state, action) => {
      const deleteItem = action.payload
      state.items = state.items.filter((item) => item.id !== deleteItem.id)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItems.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getItems.fulfilled, (state, action) => {
      state.loading = false
      state.items = action.payload
    })
    builder.addCase(getItems.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
  },
})

export const {
  setError,
  updatePersonalItem,
  addPersonalItems,
  removePersonalItem,
  resetPersonalStore,
} = personal.actions

export default personal.reducer
