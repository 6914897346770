import styled from 'styled-components'
import { theme } from 'venus/config'

export const TitleArea = styled.div`
  display: flex;
  width: 100%;
  height: 28px;
  background-color: ${theme.colors.LightBlue};
  justify-content: center;
  align-items: center;
`

export const CheckBoxArea = styled.div`
  display: flex;
  width: calc(100% - 48px);
  margin: 18px 24px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`

export const CheckBoxContent = styled.div`
  display: flex;
  width: 45%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 3px;
  height: 28px;
`

export const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: ${theme.colors.LightBlue};
  }
`
