import React, { createContext, useContext, useEffect, useState } from 'react'
import { isFromMobileBrowser } from 'venus/utils'

interface IRedirection {
  isMobile: boolean
  showDesktop: () => void
}

export const Mobile = createContext<IRedirection>({
  isMobile: false,
  showDesktop: () => {},
})

export const MobileProvider = ({ children }: { children: React.ReactNode }) => {
  const session = window.sessionStorage && window.sessionStorage.getItem('isDesktop')

  console.log('session', session)

  const [isMobile, setIsMobile] = useState(session ? false : isFromMobileBrowser())

  const showDesktop = () => {
    setIsMobile(false)
    window.sessionStorage.setItem('isDesktop', 'true')
  }

  return <Mobile.Provider value={{ isMobile, showDesktop }}>{children}</Mobile.Provider>
}

export const useMobileContext = () => useContext(Mobile)

export default useMobileContext
