import api from 'venus/api'
import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import { IHistory } from 'venus/redux/features/history/types'
import { updateHistory, createHistory, deleteHistory } from 'venus/redux/features/history/history'

export const getHistories = createAsyncThunk(
  'history/getHistories',
  async ({
    propertyId,
    spaceId,
    itemId,
    shareId,
    dreamPropertyId,
  }: {
    propertyId: string
    spaceId?: string
    itemId?: string
    shareId?: string
    dreamPropertyId?: string
  }) => {
    try {
      const response = await api.get('/histories', {
        params: {
          propertyId,
          spaceId,
          itemId,
          shareId,
          dreamPropertyId,
        },
      })
      return response.data
    } catch (err) {
      // @ts-ignore
      const error: AxiosError = err
      if (!error.response) {
        throw err
      }
      return isRejectedWithValue(error.response.data)
    }
  },
)

const initialState = {
  error: null,
  histories: [],
  loading: false,
  showHistoryModal: false,
} as {
  error: object | null | undefined
  histories: IHistory[]
  loading: boolean
  showHistoryModal: boolean
}

const historiesSlice = createSlice({
  name: 'histories',
  initialState,
  reducers: {
    resetHistoriesStore: () => initialState,
    setShowHistoryDrawer: (state, action) => {
      state.showHistoryModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getHistories.fulfilled, (state, action) => {
      state.loading = false
      state.histories = action.payload
    })
    builder.addCase(getHistories.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(updateHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateHistory.fulfilled, (state, action) => {
      state.loading = false
      state.histories = state.histories.map((history) => {
        if (history?.id === action?.payload?.id) {
          return action.payload
        }
        return history
      })
    })
    builder.addCase(updateHistory.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(createHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createHistory.fulfilled, (state, action) => {
      state.loading = false
      state.histories = [action.payload, ...state.histories]
      state.showHistoryModal = false
    })
    builder.addCase(createHistory.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(deleteHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteHistory.fulfilled, (state, action) => {
      state.loading = false
      state.histories = state.histories.filter(
        (history) => history.id !== action.meta.arg.historyId,
      )
      state.showHistoryModal = false
    })
    builder.addCase(deleteHistory.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
  },
})

export const { resetHistoriesStore, setShowHistoryDrawer } = historiesSlice.actions

export default historiesSlice.reducer
