import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Text, Logo } from 'venus/components'
import { theme } from 'venus/config'
import { useMedaQuery } from 'venus/hooks/useMedia'
import {
  AppStoreWrapper,
  GooglePlayWrapper,
  ButtonsArea,
} from './DownloadApp.styles'

export const DownloadApp = () => {
  const isMobile = useMedaQuery()

  const handleGoToAppStore = () => {
    window.location.href = 'https://apps.apple.com/au/app/myhomevault/id1619854877'
  }

  const handleGoToGoogleStore = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.myhomevault.myhomevault'
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>MyHomeVault App</title>
        <link rel='icon' href='/public/svg/logo_icon.svg'/>
      </Helmet>
      <Link to='/'>
        <Logo size={128} isWithText/>
      </Link>
      <Text
        color={theme.colors.White}
        value='Get Your Free Download Of'
        format='h2'
        style={{
          margin: 18,
          textAlign: 'center',
          fontSize: isMobile ? 24 : 32,
        }}
      />
      <Text
        color={theme.colors.White}
        value='MyHomeVault'
        format='h1'
        style={{
          margin: 18,
          textAlign: 'center',
          fontSize: isMobile ? 48 : 64,
        }}
      />
      <Text
        color={theme.colors.White}
        value='Mobile App'
        format='h2'
        style={{
          margin: 18,
          textAlign: 'center',
          fontSize: isMobile ? 24 : 32,
        }}
      />
      <ButtonsArea style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <AppStoreWrapper
          alt='The house from the offer.'
          src='./svg/App_Store.svg'
          onClick={handleGoToAppStore}
        />
        <GooglePlayWrapper
          alt='The house from the offer.'
          src='./png/global-playstore.png'
          onClick={handleGoToGoogleStore}
        />
      </ButtonsArea>
    </>
  )
}
