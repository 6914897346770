import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

type PanelProps = {
  margin: number
  overFlowY?: string
}

export const ActionBody = styled.div<PanelProps>`
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => props.overFlowY || 'auto'};
  overflow-x: hidden;
  padding: 0 ${(props) => props.margin}px;
`

export const ActionFooter = styled.div<PanelProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e8eaee;
  padding: 20px ${(props) => props.margin}px;
  z-index: 2;
`

export const Groups = styled.div`
  display: flex;
  align-items: center;
`
