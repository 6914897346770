import { useEffect, useMemo, useRef, useState } from 'react'
import api from 'venus/api'
import useDebounce from 'venus/hooks/useDebounceVaule'
import { v4 as uuidv4 } from 'uuid'

import { Container, DeleteButton, Input, InputContainer } from './ItemTag.styles'
import AutoCompleteList from './AutoCompleteList'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import { theme } from 'venus/config'

const getRawLabel = (str) => str.replace(/^New /g, '')

const ItemTag = ({
  items,
  editing,
  scale,
  position: initPosition,
  boxSize: initBoxsize,
  addItem,
  updateItem,
  deleteItem,
  cancelSelect,
}: any) => {
  const processedPosition =
    initBoxsize && initBoxsize.width
      ? initPosition
      : { x: initPosition.x - 50, y: initPosition.y - 50 }

  const [position, setPosition] = useState(processedPosition)
  const [boxSize, setBoxSize] = useState(
    initBoxsize && initBoxsize.width && initBoxsize.height
      ? initBoxsize
      : { width: 100, height: 100 },
  )
  const { x, y } = position || { x: 0, y: 0 }
  const inputRef = useRef<any>()
  const [isOpen, setIsOpen] = useState(true)
  const [text, setText] = useState(editing ? editing.label : '')
  const [option, setOption] = useState()
  const [options, setOptions] = useState<any>()
  const debounceValue = useDebounce(text)

  useEffect(() => {
    if (editing) {
      setText(editing.label)
    }
  }, [editing])

  useEffect(() => {
    setPosition(
      initBoxsize && initBoxsize.width
        ? initPosition
        : { x: initPosition.x - 50, y: initPosition.y - 50 },
    )

    setBoxSize(
      initBoxsize && initBoxsize.width && initBoxsize.height
        ? initBoxsize
        : { width: 100, height: 100 },
    )
  }, [initPosition, initBoxsize])

  useEffect(() => {
    const getOptions = async () => {
      const options = await api
        .get('/autocomplete/item/type', { params: { name: '' } })
        .then((res) => res.data)

      const defaultOptions = (options || []).map(
        (item: { name: string; type: string }) => ({
          label: `New ${item.name}`,
          value: item.type,
        }),
      )

      const existingOptions = items.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.typeId,
      }))

      setOptions([...existingOptions, ...defaultOptions])
    }

    getOptions()
  }, [items])

  const filtered = useMemo(() => {
    if (!options) {
      return []
    }
    if (!debounceValue) {
      return options
    }
    const newRegex = new RegExp(debounceValue, 'gi')
    return options?.filter((option) => {
      return newRegex.test(option.label)
    })
  }, [debounceValue, options])

  const handlePress = (item) => {
    inputRef.current?.blur()

    console.log('item click', item)

    if (item.id) {
      setText(item.label)
      setOption(item)

      handleAddItem(item)
    } else {
      const label = item.label.replace(/^New /g, '')
      setText(label)
      setOption({ ...item, label })

      handleAddItem({ ...item, label })
    }

    setIsOpen(false)
  }

  const handleFinishItem = () => {
    // handleAddItem(null)
    console.log('TRIGGER')
    setIsOpen(false)
    cancelSelect()
  }

  const handleDelete = () => {
    deleteItem(editing.id)
    setIsOpen(false)
    cancelSelect()
  }

  const handleAddItem = (op) => {
    const item = op || option

    console.log('item', item)

    if (item) {
      console.log('eiditng', editing)

      if (editing) {
        updateItem({
          itemId: editing.itemId,
          id: editing.id,
          type: item.value,
          label: item.label,
          bbox: editing.bbox,
        })
      } else {
        addItem({
          id: uuidv4(),
          itemId: item.id,
          type: item.value,
          label: item.id ? item.label : getRawLabel(item.label),
          bbox: [initPosition.x / scale, initPosition.y / scale, 0, 0],
        })
      }

      cancelSelect()
    } else {
      cancelSelect()
    }
  }

  const autoCompleteListPosition =
    y > 400 ? { bottom: boxSize.height / 2 + 10 } : { top: boxSize.height / 2 + 10 }

  return (
    <>
      <Container x={x} y={y} w={boxSize.width} h={boxSize.height}>
        <InputContainer>
          <Input
            ref={inputRef}
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
            onFocus={() => setIsOpen(true)}
          />
          <DeleteButton onClick={handleDelete}>
            <CrossIcon width={16} color={theme.colors.White} />
          </DeleteButton>
        </InputContainer>

        {isOpen && (
          <AutoCompleteList
            style={autoCompleteListPosition}
            options={filtered}
            handleOptionSelect={handlePress}
            close={handleFinishItem}
          ></AutoCompleteList>
        )}
      </Container>
    </>
  )
}

export default ItemTag
