import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  progress: 0,
  url: '',
  image: '',
  name: 'uploading',
}

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    reset: () => initialState,
    setProgress: (state, action) => {
      state.progress = action.payload
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    setUrl: (state, action) => {
      state.url = action.payload
    },
    setName: (state, action) => {
      state.name = action.payload
    },
  },
})

export const { reset, setProgress, setUrl, setName, setImage } = imageSlice.actions

export default imageSlice.reducer
