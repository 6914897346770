import { Button, Item, List } from './DocList.styles'
import { ReactComponent as File } from 'venus/assets/img/icons/File.svg'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import { Text } from 'venus/components'
import { theme } from 'venus/config'

const DocList = ({ docs, removeDoc }) => {
  const handleRemove = (index) => () => {
    removeDoc(index)
  }

  return (
    <List>
      {docs.map((doc, index) => (
        <Item key={doc.uri}>
          <File color={theme.colors.DarkestGrey} />
          <Text
            style={{ margin: '0 8px', flex: 1 }}
            format='h4'
            color={theme.colors.DarkestGrey}
          >
            {doc.filename}
          </Text>
          <Button onClick={handleRemove(index)}>
            <CrossIcon color={theme.colors.DarkestGrey} height={16} />
          </Button>
        </Item>
      ))}
    </List>
  )
}

export default DocList
