import styled from 'styled-components'
import { theme } from 'venus/config'

export const BodyArea = styled.div`
  height: 560px;
  background-color: ${theme.colors.White};
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  margin-left: 32px;
  margin-right: 32px;
`

export const ConfirmOwnerPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 38px;
  padding: 16px;
  background-color: #F5F6FF;
  border-radius: 8px;
`

export const CompleteViewContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 32px;
`

export const IconWrapper = styled.div`
  margin: 24px 0;
`