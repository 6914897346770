import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from 'venus/api'
import { deleteReceivedShareProperty } from 'venus/redux/features/share'
import { IImage } from 'venus/types/Image'
import { ISpace } from 'venus/types/Space'
import { getErrorMsg } from 'venus/utils'
// import { updateProperty } from './property'

export interface IProperty {
  id: string
  address: {
    state: string
    suburb: string
    address: string
    postcode: string
  }
  propertyData: {
    area: number
    beds: number
    baths: number
    parking: number
  }
  spacesId: string[]
  propertyWideSpaceId: string
  coverImage?: string
  images: IImage[]
  spaces: ISpace[]
  documents?: {
    displayName: string
    url: string
    updatedAt: string
    contentType: string
  }[]
  transfer?: object | null
}

// First, create the thunk
export const getSharedProperties = createAsyncThunk(
  'property/getSharedProperties',
  async (_, { getState }) => {
    try {
      const response = await api.get('/share/list')

      // console.log('getSharedProperties=>res', response)

      return response.data
    } catch (err) {
      console.log('err', err)
      // @ts-ignore
      const error: AxiosError = err

      if (!error.response) {
        throw err
      }

      return isRejectedWithValue(error.response.data)
    }
  },
)

export interface ISharedScope {
  id: string
  sharedBy?: string
  isSharedAnoymously: boolean
  isShareDocument: boolean
  isShareHistory: boolean
}

const initialState = {
  error: null,
  sharedProperties: [],
  sharedScope: [],
  loading: false,
} as {
  error: object | null | undefined
  sharedProperties: IProperty[]
  sharedScope: ISharedScope[]
  loading: boolean
}

const sharedPropertiesSlice = createSlice({
  name: 'sharedProperties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSharedProperties.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getSharedProperties.fulfilled, (state, action) => {
      state.loading = false
      state.sharedProperties = action.payload.properties || []
      state.sharedScope = action.payload.shares
    })
    builder.addCase(getSharedProperties.rejected, (state, action) => {
      state.loading = false

      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(deleteReceivedShareProperty.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteReceivedShareProperty.fulfilled, (state, action) => {
      const { properties, shares } = action.payload
      if (!properties) {
        state.sharedProperties = []
      } else {
        state.sharedScope = shares
        state.sharedProperties = properties.map((value: any, index: number) => ({
          ...value,
          shareId: shares[index].id,
          isSharedDreamPrice: shares[index].isSharedDreamPrice,
          isShareDocument: shares[index].isShareDocument,
          isShareHistory: shares[index].isShareHistory,
        }))
      }
      state.loading = false
    })
    builder.addCase(deleteReceivedShareProperty.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })

    // builder.addCase(updateProperty, (state, action) => {
    //   state.properties = state.properties.map((property) => {
    //     // @ts-ignore
    //     return property.id === action.payload.id
    //       ? // @ts-ignore
    //         { ...property, ...action.payload }
    //       : property
    //   })

    //   console.log('action', action.payload)
    // })
  },
})

export default sharedPropertiesSlice.reducer
