import styled from 'styled-components'
import { ReactComponent as AvatarSquareIcon } from 'venus/assets/img/icons/AvatarSquare.svg'

export const Container = styled.div`
`

export const ContentTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const HeaderButtonBox = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const ContactCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ContactInfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const ImageContainer = styled.div`
`

export const AvatarWrapper = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 20px;
`

export const DefaultAvatarIcon = styled(AvatarSquareIcon)`
  width: 90px;
  height: 90px;
  border-radius: 20px;
`

export const FirstTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const SecondTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`