import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

type PanelProps = {
  margin: number
}

export const ActionBody = styled.div<PanelProps>`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 ${(props) => props.margin}px;
  height: 100%;
`

export const ActionFooter = styled.div<PanelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${(props) => props.margin}px 20px;
`

export const Groups = styled.div`
  display: flex;
  align-items: center;
`

export const MessageArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
