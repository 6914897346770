import styled from 'styled-components'

export const BtnArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  margin-top: 24px;
  margin-bottom: 48px;
  width: 100%;
`

export const DateWrapper = styled.div`
  width: 100%;
  margin-bottom: 18px;
`
