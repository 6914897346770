import styled from 'styled-components'

export const PersonalItemsContainer = styled.div`
  margin: 0 18px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  overflow: scroll;
  padding: 26px 0 200px 0;
`

export const Container = styled.div`
  padding: 0 24px;
  min-height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`
