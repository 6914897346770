import format from 'date-fns/format'

export const displayDaysInMonth = (
  year: number,
  month: number
): Array<{ d?: Date; date: number | null; day: number | null }> => {
  const d1 = new Date(year, month, 1)
  const d2 = new Date(year, month + 1, 0)
  const days = []
  for (let d = d1; d <= d2; d.setDate(d.getDate() + 1)) {
    const _d = new Date(d)
    days.push({
      d: _d,
      date: _d.getDate(),
      day: _d.getDay(),
    })
  }
  if (days.length > 0) {
    for (let i = 0, j = days[0].day; i < j; i++) {
      days.unshift({
        date: null,
        day: null,
      })
    }
  }
  while (days.length % 7 !== 0) {
    days.push({
      date: null,
      day: null,
    })
  }
  return days
}
export const getDate = (date: string) => {
  const ddMMyyyy =
    /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
  if (ddMMyyyy.test(date)) return date
  return date ? format(new Date(date), 'dd/MM/yyyy') : ''
}
