import api from 'venus/api'
import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import { ITradespeople } from 'venus/redux/features/tradespeople/types'

export const getTradespeopleAvailability = createAsyncThunk(
  'tradespeople/getTradespeopleAvailability',
  async (propertyId: string) => {
    try {
      const url = `/tradespeople/available/${propertyId}`
      const response = await api.get(url)
      console.log('getTradespeopleAvailability::', response.data)
      return response.data
    } catch (err) {
      // @ts-ignore
      const error: AxiosError = err
      if (!error.response) {
        throw err
      }
      return isRejectedWithValue(error.response.data)
    }
  }
)

export const getTradespeopleList = createAsyncThunk(
  'tradespeople/getTradespeople',
  async ({
    propertyId,
    spaceId = null,
    itemId = null,
  }: {
    propertyId: string;
    spaceId?: string;
    itemId?: string;
  }) => {
    try {
      let url = ''
      if (propertyId) {
        if (spaceId && itemId) {
          url = `/tradespeople/?propertyId=${propertyId}&spaceId=${spaceId}&itemId=${itemId}`
        } else if (spaceId && !itemId) {
          url = `/tradespeople/?propertyId=${propertyId}&spaceId=${spaceId}`
        } else {
          url = `/tradespeople/?propertyId=${propertyId}`
        }
      }
      const response = await api.get(url)
      return response.data
    } catch (err) {
      // @ts-ignore
      const error: AxiosError = err
      if (!error.response) {
        throw err
      }
      return isRejectedWithValue(error.response.data)
    }
  }
)

const initialState = {
  error: null,
  tradespeople: [],
  numberOfTradespeopleAvailable: 0,
  loading: false,
} as {
  error: object | null | undefined
  tradespeople: ITradespeople[]
  numberOfTradespeopleAvailable: number
  loading: boolean
}

const tradespeopleSlice = createSlice({
  name: 'tradespeople',
  initialState,
  reducers: {
    resetTradespeopleStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTradespeopleList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTradespeopleList.fulfilled, (state, action) => {
      state.loading = false
      state.tradespeople = action.payload
    })
    builder.addCase(getTradespeopleList.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(getTradespeopleAvailability.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTradespeopleAvailability.fulfilled, (state, action) => {
      state.loading = false
      state.numberOfTradespeopleAvailable = action.payload
    })
  }
})

export const { resetTradespeopleStore } = tradespeopleSlice.actions

export default tradespeopleSlice.reducer
