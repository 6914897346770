import styled from 'styled-components'
import { colorPalette } from 'venus/config'

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  list-style: none;
  padding: 0;
`

type ColorBackgroundProps = {
  readonly colors: string[]
}
const ColorBackground = styled.li<ColorBackgroundProps>`
  aspect-ratio: 1.3333;
  background: ${(props) => `linear-gradient(0deg, ${props.colors[0]}, ${props.colors[1]})`};
  cursor: pointer;
`

const BackgroundPicker = ({ onChange }) => {
  const handleClick = (color) => () => {
    onChange(`gradient://${color}`)
  }

  return (
    <List>
      {colorPalette.map((color) => {
        const colors = color.split('-')

        return <ColorBackground key={color} colors={colors} onClick={handleClick(color)} />
      })}
    </List>
  )
}

export default BackgroundPicker
