import styled from 'styled-components'
import { theme } from 'venus/config'

export const ListContainer = styled.div``

export const IconArea = styled.div`
  position: absolute;
  top: 21px;
  right: 6px;
  &:hover {
    button {
      svg {
        color: ${theme.colors.Primary};
      }
    }
  }
`

export const SelectArea = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  background: white;
`

export const SelectRoleArea = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 0 6px;
`

export const SubListWrapper = styled.div`
  height: fit-content;
  margin-top: 30px;
`
