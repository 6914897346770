import { ReactNode } from 'react'
import { theme } from 'venus/config'
import { BtnWrapper } from './LinkButton.styles'
import { Text } from 'venus/components'
import { Link } from 'react-router-dom'

interface ITextButton {
  handlePress?: () => void
  label: string
  format: 'h1' | 'h2' | 'h3' | 'h4' | 'body'
  color?: string
  style?: any
  hideUnderline?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  fullBlock?: boolean
  textStyle?: any
  to?: string
  target?: string
  rel?: string
  isDisabled?: boolean
}

export const LinkButton = ({
  handlePress,
  label,
  format,
  color,
  style = {},
  hideUnderline = false,
  leftIcon = null,
  rightIcon = null,
  fullBlock = false,
  textStyle = {},
  to,
  target,
  rel,
  isDisabled = false,
}: ITextButton) => {
  const textColor = color || theme.colors.DarkGrey

  const fullBlockStyle = fullBlock
    ? { textAlign: 'center', alignSelf: 'stretch' }
    : {}

  if (isDisabled) {
    return (
      <BtnWrapper
        hideUnderline={hideUnderline}
        style={{ margin: 0, padding: 0, cursor: 'not-allowed', ...style }}
      >
        {leftIcon}
        <Text
          style={{ ...fullBlockStyle, ...textStyle }}
          value={label}
          color={theme.colors.DarkGrey}
          format={format}
        />
        {rightIcon}
      </BtnWrapper>
    )
  }

  return (
    <Link
      to={to || ''}
      style={{ textDecoration: 'none' }}
      target={target}
      rel={rel}
    >
      <BtnWrapper
        hideUnderline={hideUnderline}
        style={{ margin: 0, padding: 0, ...style }}
        onClick={handlePress}
      >
        {leftIcon}
        <Text
          style={{ ...fullBlockStyle, ...textStyle }}
          value={label}
          color={textColor}
          format={format}
        />
        {rightIcon}
      </BtnWrapper>
    </Link>
  )
}
