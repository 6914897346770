import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import api from 'venus/api'
import Processing from './Processing'
import Succeed from './Succeed'
import Failure from './Failure'
import { BgWrapper } from 'venus/layout/MHV/MHV.styles'

const PaymentResult = () => {
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('session_id')
  const propertyIds = (searchParams.get('propertyIds') || '').split(',')

  const [isSuccessful, setIsSuccessful] = useState(false)

  useEffect(() => {
    const finishSession = async () => {
      const res = await api.post('/payment/session/success', { sessionId, propertyIds })

      if (res.status) {
        setIsSuccessful(true)
      }
    }

    if (sessionId) {
      finishSession()
    }
  }, [sessionId, propertyIds])

  if (!sessionId) {
    return (
      <BgWrapper>
        <Failure />
      </BgWrapper>
    )
  }

  return <BgWrapper>{isSuccessful ? <Succeed /> : <Processing />}</BgWrapper>
}

export default PaymentResult
