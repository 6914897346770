import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { ISubscription } from 'venus/types/Subscription'
import api from 'venus/api'
import { FREE, FREE_PREMIUM, PAID } from 'venus/config'

export const getSubscription = createAsyncThunk(
  'subscription/getSubscription',
  async () => {
    try {
      const response = await api.get('/payment')
      return response.data

      //  setSubscribed(data.subscribed.map((sub) => ({ ...sub, isSubscribed: true })))
      //  setUnsubscribed(
      //    data.unsubscribed.map((sub) => ({ ...sub, isSubscribed: false }))
      //  )
      //  setCredit(data.credit)
    } catch (err) {
      const error: AxiosError = err

      if (!error.response) {
        throw err
      }

      return isRejectedWithValue(error.response.data)
    }
  }
)

type TVaults = {
  [propertyId: string]: 'free' | 'freePremium' | 'paid' | undefined
}

const initialState = {
  propertyId: '',
  error: null,
  subscribed: [],
  unsubscribed: [],
  vaults: {},
  credit: 0,
  loading: false,
  productId: '',
  isInPromotion: false,
  expireDate: null,
  status: '',
  type: '',
} as {
  propertyId: string
  error: object | null | undefined
  subscribed: ISubscription[]
  unsubscribed: ISubscription[]
  vaults: TVaults
  credit: number
  loading: boolean
  productId: string
  isInPromotion: boolean
  expireDate: string | null
  status: string
  type?: string
  purchaseToken?: string
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetSubscriptionStore: () => initialState,
    setPropertyId: (state, action) => {
      state.propertyId = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSubscription.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.loading = false
      const {
        subscribed,
        unsubscribed,
        credit,
        productId,
        isInPromotion,
        expireDate,
        status,
        type,
        purchaseToken,
      } = action.payload
      const freeVaults: TVaults = {}
      const freePremiumVaults: TVaults = {}
      const paidVaults: TVaults = {}
      state.subscribed = subscribed.map((sub: ISubscription) => {
        const { id } = sub
        paidVaults[id] = PAID
        return {
          ...sub,
          isSubscribed: true,
        }
      })

      state.unsubscribed = unsubscribed.map((sub: ISubscription) => {
        const { id, isPremium } = sub
        if (isPremium) {
          freePremiumVaults[id] = FREE_PREMIUM
        } else {
          freeVaults[id] = FREE
        }
        return {
          ...sub,
          isSubscribed: false,
        }
      })
      state.vaults = {
        ...state.vaults,
        ...freeVaults,
        ...freePremiumVaults,
        ...paidVaults,
      }
      state.credit = credit
      state.productId = productId
      state.isInPromotion = isInPromotion
      state.expireDate = expireDate
      state.status = status
      state.type = type
      state.purchaseToken = purchaseToken
    })
    builder.addCase(getSubscription.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
  },
})

export const { setPropertyId, resetSubscriptionStore } =
  subscriptionSlice.actions

export default subscriptionSlice.reducer
