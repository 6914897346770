import styled from 'styled-components'

export const Container = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
`

export const ContentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ContentTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 32px 36px;
`

export const ContentsBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100px;
  float: right;
  margin: 20px 36px 20px 0;

  button {
    &:hover {
      svg {
        color: white;
      }
    }
  }
`

export const RatingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 24px 32px;
`

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
`