import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'
import { errorHandling } from './asyncSignIn'
import { IUserCompanyState } from './types'

export const createProfile = createAsyncThunk(
  'profile/create',
  async (profileObj: IUserCompanyState) => {
    try {
      await api.post('/profile', { profile: profileObj })
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const updateProfile = createAsyncThunk(
  'profile/update',
  async (fields: any, { rejectWithValue }) => {
    try {
      const response = await api.put('/profile', fields)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  },
)

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  async ({ newPassword }: { newPassword: string }) => {
    try {
      const response = await api.put('/auth/change-password', { newPassword })
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const getProfile = createAsyncThunk('profile/get', async () => {
  try {
    const response = await api.get('/profile')
    return response.data
  } catch (err) {
    return errorHandling(err)
  }
})

export const sendEmailVerificationLink = createAsyncThunk(
  'profile/sendEmailVerificationLink',
  async (email: string) => {
    try {
      const response = await api.post('/auth/resend-email-link', { email })
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)
