import { useState } from "react"
import { PillTag, PillTabs, Pills, Text, Row } from "venus/components"

export const PillSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  console.log("activeIndex::", activeIndex)

  const handlePressPillsOptions = () => {}

  const tabs = [
    { key: "all", label: "All" },
    { key: "plumber", label: "Plumber" },
    { key: "electrician", label: "Electrician" },
    { key: "landscaper", label: "Landscaper" },
    { key: "painter", label: "Painter" },
    { key: "other", label: "Other" },
  ]

  const options = [
    { id: "i1", name: "Property Wide" },
    { id: "i2", name: "Master Bedroom" },
    { id: "i3", name: "Pool" },
    { id: "i1", name: "Property Wide" },
    { id: "i2", name: "Master Bedroom" },
    { id: "i3", name: "Pool" },
    { id: "i1", name: "Property Wide" },
    { id: "i2", name: "Master Bedroom" },
    { id: "i3", name: "Pool" },
    { id: "i1", name: "Property Wide" },
    { id: "i2", name: "Master Bedroom" },
    { id: "i3", name: "Pool" },
    { id: "i1", name: "Property Wide" },
  ]

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Row height={120} style={{ marginTop: -20 }}>
        <Text
          value="(a) Pill Tag"
          format="h3"
          style={{ margin: 18, textAlign: "left" }}
        />
        <PillTag
          label="Shared"
          background="DarkOrange"
          color="White"
          paddingVertical={2}
          paddingHorizontal={10}
        />
      </Row>
      <Row height={130}>
        <Text
          value="(b) Pill Tabs"
          format="h3"
          style={{ margin: 18, textAlign: "left" }}
        />
        <PillTabs onChange={setActiveIndex} tabs={tabs} />
      </Row>
      <Row height={150}>
        <Text
          value="(c) Pills"
          format="h3"
          style={{ margin: 18, textAlign: "left" }}
        />
        <Pills options={options} handlePress={handlePressPillsOptions} />
      </Row>
    </>
  )
}
