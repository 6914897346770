import { useState } from 'react'
import { theme } from 'venus/config'
import { get1stLevelRoleLabel } from 'venus/utils'
import {
  PanelDisplayArea,
  AvatarArea,
  AvatarWrapper,
  TitleWrapper,
  TitleArea,
  RatingRow,
  ContentArea,
  TitleRow,
  EditWrapper,
} from './ContactDetails.styles'
import {
  Button,
  Text,
  MenuText,
  AverageRating,
  Avatar,
  Drawer,
  ActionLayout,
  TabView, Rating,
} from 'venus/components'
import { ReactComponent as Pencil } from 'venus/assets/img/icons/Pencil.svg'
import { CreateEditContact } from 'venus/pages/Contact/CreateEditContact'
import { IContactEntry } from 'venus/redux/features/contact'
import { AddContactWrapper } from './ContactDetails.styles'
import { ReactComponent as AddSignIcon } from 'venus/assets/img/icons/AddSign.svg'
import { useAppSelector } from 'venus/redux/hooks'
import { useAppDispatch } from 'venus/redux/hooks'
import { useEffect } from 'react'
import { createContact } from 'venus/redux/features/contact'
import { Notification } from 'venus/components'

interface IContactDetails {
  setShow: Function
  selected: IContactEntry
  isEditable?: boolean
  showAddToContact?: boolean
  propertyId: string
  isAddToContact?: boolean
}

export const ContactDetails = ({
  setShow,
  selected,
  isEditable = true,
  showAddToContact = false,
  propertyId,
  isAddToContact = false,
}: IContactDetails) => {
  const dispatch = useAppDispatch()
  const { entries, loading } = useAppSelector((state) => state.contact)
  const [isExistContact, setIsExistContact] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    const isExist = entries.find((entry) => entry.mobile === selected.mobile)
    setIsExistContact(!!isExist)
  }, [entries])

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [showPopup, setShowPopup] = useState(false)
  const handlePressPlus = () => {
    setShowPopup(true)
  }

  const {
    profile,
    firstName,
    lastName,
    mobile,
    email,
    role,
    tradespeopleRole,
    companyName,
    companyAddress,
    companyURL,
    rating,
    averageRating,
    companyPhone,
    abn,
    license,
  } = selected
  const { pricing, communication, quality, timeManagement } = rating || {}

  const handlePressGoBack = () => {
    setShow(false)
  }

  const handleOnClickAddContact = async () => {
    const result = await dispatch(
      createContact({
        propertyId,
        body: selected,
      })
    )
    if (result.meta.requestStatus === 'fulfilled') {
      setShowAlert(true)
    }
  }

  const handleOnClickCloseAlert = () => {
    setShowAlert(false)
  }

  return (
    <Drawer onClose={handlePressGoBack} isOpen>
      <ActionLayout
        rightActions={
          isEditable && !showPopup && activeIndex === 0
            ? [
                {
                  type: 'primary',
                  text: 'Edit',
                  onClick: handlePressPlus,
                  isLoading: loading,
                  iconLeft: (
                    <Pencil
                      style={{ width: 12, marginRight: 6, color: 'white' }}
                    />
                  ),
                },
              ]
            : []
        }
      >
        <>
          <Notification
            message={`${selected?.firstName} ${selected?.lastName} has been added into Contact.`}
            closeAlert={handleOnClickCloseAlert}
            showAlert={showAlert}
          />
          <ContentArea>
            {!showPopup && (
              <>
                <AvatarWrapper>
                  <AvatarArea>
                    <Avatar
                      isBorder={false}
                      size={120}
                      avatar={profile?.avatar}
                    />
                  </AvatarArea>
                  <Text format='h1' value={`${firstName} ${lastName}`} />
                  {isAddToContact && (
                    <AddContactWrapper>
                      <Button
                        type='primary'
                        isLoading={false}
                        onClick={handleOnClickAddContact}
                        text={'Add to Contacts'}
                        isDisabled={isExistContact}
                        iconLeft={
                          <AddSignIcon
                            style={{
                              width: '30px',
                              height: '30px',
                              color: 'white',
                            }}
                          />
                        }
                        style={{
                          width: 'auto',
                          height: '30px',
                          float: 'center',
                        }}
                      />
                    </AddContactWrapper>
                  )}
                  <TitleArea>
                    <TitleRow>
                      <TitleWrapper>
                        <Text
                          value={
                            role && role.length
                              ? get1stLevelRoleLabel(role[0])
                              : ''
                          }
                          format='h4'
                          color={theme.colors.Primary}
                        />
                      </TitleWrapper>
                      {!!tradespeopleRole && tradespeopleRole.length > 0 && (
                        <TitleWrapper>
                          <Text
                            value={tradespeopleRole[0].toLowerCase()}
                            format='h4'
                            color={theme.colors.Primary}
                            style={{
                              textTransform: 'capitalize',
                            }}
                          />
                        </TitleWrapper>
                      )}
                    </TitleRow>
                    <AverageRating
                      average={String(averageRating || 0)}
                      style={{ alignSelf: 'center', width: '62px' }}
                    />
                  </TitleArea>
                </AvatarWrapper>
                <TabView
                  tabs={['Details', 'Ratings']}
                  defaultActiveIndex={0}
                  paddingBottom={0}
                  headerStyle={{ marginBottom: 10, paddingLeft: 32 }}
                >
                  <PanelDisplayArea>
                    <MenuText
                      title='Mobile Number'
                      text={mobile}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='E-mail'
                      text={email}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='Company Name'
                      text={companyName || ''}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='ABN'
                      text={abn || ''}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='Company Address'
                      text={companyAddress || ''}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='Company Phone'
                      text={companyPhone || ''}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='Company Website'
                      text={companyURL || ''}
                      style={{ marginBottom: '12px' }}
                    />
                    <MenuText
                      title='License'
                      text={license || ''}
                      style={{ marginBottom: '12px' }}
                    />
                  </PanelDisplayArea>
                  <PanelDisplayArea style={{ marginTop: 24 }}>
                    <RatingRow>
                      <Text
                        value='Time Management'
                        format='h3'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignSelf: 'flex-start',
                        }}
                      />
                      <Rating rating={timeManagement} readonly/>
                    </RatingRow>
                    <RatingRow>
                      <Text
                        value='Communication'
                        format='h3'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignSelf: 'flex-start',
                        }}
                      />
                      <Rating rating={communication} readonly/>
                    </RatingRow>
                    <RatingRow>
                      <Text
                        value='Pricing'
                        format='h3'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignSelf: 'flex-start',
                        }}
                      />
                      <Rating rating={pricing} readonly/>
                    </RatingRow>
                    <RatingRow>
                      <Text
                        value='Quality'
                        format='h3'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignSelf: 'flex-start',
                        }}
                      />
                      <Rating rating={quality} readonly/>
                    </RatingRow>
                  </PanelDisplayArea>
                </TabView>
              </>
            )}
            {showPopup && (
              <EditWrapper>
                <CreateEditContact
                  contactId={selected.id}
                  propertyId={propertyId}
                  setShow={setShowPopup}
                  setShowEdit={setShow}
                  isEditMode
                  isCreateMode={false}
                  showModal={false}
                />
              </EditWrapper>
            )}
          </ContentArea>
        </>
      </ActionLayout>
    </Drawer>
  )
}
