import { Text } from 'venus/components'
import { theme } from 'venus/config'
import {
  ContextButton,
  KebabWrapper,
  OptionItem,
  OptionWrapper,
} from './ContextMenu.styles'
import { ReactComponent as Kebab } from 'venus/assets/img/icons/Kebab.svg'
import { useState } from 'react'

const ContextMenu = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (event) => {
    event.stopPropagation()
    event.preventDefault()
    // setIsOpen((isOpen) => !isOpen)
    console.log('toggle')
  }

  return (
    <KebabWrapper isOpen={isOpen}>
      <ContextButton onClick={toggleMenu}>
        <Kebab onClick={(event) => event.preventDefault()} />
      </ContextButton>

      <OptionWrapper isOpen={isOpen}>
        {options.map((option) => (
          <OptionItem key={option.label} onClick={option.onClick}>
            <Text format='h4' value={option.label} />
          </OptionItem>
        ))}
        {/* <OptionItem onClick={() => handleClickDelete(property.id)}>
          <Text format='h4' value='Remove dream property' />
        </OptionItem>
        <OptionItem
          style={{
            cursor: !isSharedDreamPrice ? 'not-allowed' : 'pointer',
          }}
          onClick={handlePressShowInterest}
        >
          <Text
            format='h4'
            fontWeight={isSharedDreamPrice ? 700 : 500}
            color={!isSharedDreamPrice ? theme.colors.DarkGrey : theme.colors.Black}
            value={isExpressedInterest ? 'Expressed Interest' : ' Express Interest'}
          />
        </OptionItem> */}
      </OptionWrapper>
    </KebabWrapper>
  )
}

export default ContextMenu
