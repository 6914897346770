import api from 'venus/api'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const useSpaceItems = ({ propertyId, spaceId, predictions }) => {
  const [items, setItems] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)
  const [matched, setMatched] = useState([])

  useEffect(() => {
    const getItems = async () => {
      setIsProcessing(true)
      const response = await api.get(`property/${propertyId}/space/${spaceId}/items`)

      setItems(response.data)
    }

    if (spaceId) {
      if (spaceId !== 'new') {
        getItems()
        setIsProcessing(false)
      } else {
        setItems([])
      }
    }
  }, [propertyId, spaceId])

  useEffect(() => {
    const map = items.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.typeId]: acc[curr.typeId] || {
          itemId: curr.id,
          label: curr.name,
          value: curr.typeId,
        },
      }),
      {},
    )

    if (predictions) {
      const predicted = predictions.map((pre) => {
        if (map[pre.type]) {
          return { ...map[pre.type], ...pre }
        }
        return pre
      })

      const predictedIds = new Set(predictions.map((pre) => pre.id))

      const newItems = matched.filter((match) => !predictedIds.has(match.id))
      setMatched([...newItems, ...predicted])
    } else {
      setMatched([])
    }
  }, [items, predictions])

  // console.log('MATCHE', matched)

  return { isProcessing, items, matched, setMatched }
}

export default useSpaceItems
