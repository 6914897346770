import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from 'venus/api'
import { errorHandling } from 'venus/utils'

export const getItem = createAsyncThunk(
  'item/getItem',
  async ({
    propertyId,
    spaceId,
    itemId,
    shareId,
    dreamPropertyId,
  }: {
    propertyId: string
    spaceId: string
    itemId: string
    shareId?: string
    dreamPropertyId?: string
  }) => {
    try {
      const response = await api.get(
        `/property/${propertyId}/space/${spaceId}/item/${itemId}`,
        {
          params: {
            shareId,
            dreamPropertyId,
          },
        },
      )
      console.log('getItem response::', response.data)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const createItems = createAsyncThunk(
  'item/createItems',
  async ({ propertyId, spaceId, items }: any) => {
    try {
      const response = await api.post(
        `/property/${propertyId}/space/${spaceId}/items`,
        items,
      )
      const result = response.data

      return result
    } catch (err) {
      const error: any = err

      if (!error?.response) {
        throw err
      }

      return isRejectedWithValue(error.response.data)
    }
  },
)

const initialState = {
  error: null,
  loading: true,
  item: null,
} as {
  error: object | null | undefined
  loading: boolean
  item: any
}

const itemsSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    resetItemStore: () => initialState,
    updateItem: (state, action) => {
      state.item = action.payload
    },
    partialUpdateItem: (state, action) => {
      state.item = { ...state.item, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createItems.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(createItems.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(getItem.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getItem.fulfilled, (state, action) => {
      state.loading = false
      console.log('get item action.payload::', action.payload)
      state.item = action.payload
    })
  },
})

export const { updateItem, partialUpdateItem, resetItemStore } =
  itemsSlice.actions

export default itemsSlice.reducer
