import { useRef } from 'react'

const BOUNCE_RATE = 800

export const useDebounceAction = (rate: number = BOUNCE_RATE) => {
  const busy = useRef(false)

  const debounce = async (callback: Function) => {
    setTimeout(() => {
      busy.current = false
    }, rate)

    if (!busy.current) {
      busy.current = true
      callback()
    }
  }

  return debounce
}

export default useDebounceAction
