import styled from 'styled-components'
import { theme } from 'venus/config'

export const LoginPanel = styled.div`
  display: flex;
  flex-direction: column;

  padding: 40px 32px;
  padding-bottom: 55px;

  width: 406px;
  border-radius: 12px;
  border: 0.5px solid ${(props) => props.theme.colors.Grey};
  background-color: ${(props) => props.theme.colors.White};

  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.06), 2px 2px 10px rgba(0, 0, 0, 0.06);
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 68px;
  margin-bottom: 44px;
`

export const Divider = styled.div`
  margin: 25px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  background-color: ${(props) => props.theme.colors.LighterGrey};
`
export const DividerContent = styled.div`
  position: absolute;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.White};
  padding: 0 8px;
`

export const SignUpSection = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
`

export const PasswordWrapper = styled.div`
  position: relative;
  margin-bottom: 18px;
`

export const ForgotPasswordBtn = styled.button`
  cursor: pointer;
  position: absolute;
  right: 12px;
  height: 44px;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
`

export const CustomPasswordInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 150px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  border-radius: 4px;
  height: 44px;
  text-align: left;
  border: ${`0.5px solid ${theme.colors.Grey}`};
  color: ${theme.colors.Black};
  outline: none;
  transition-duration: 0.3s;

  &::placeholder {
    color: ${theme.colors.DarkestGrey};
  }

  &:hover {
    border-color: ${theme.colors.Secondary};
  }
`
