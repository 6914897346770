import { useState } from 'react'
import { Add_Dream_Property_Type } from 'venus/config'
import { AutoComplete, Text, ActionLayout } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  previewAddressData,
  IAddressOption,
  addAddressId,
} from 'venus/redux/features/address'
import { AutoCompleteWrapper } from './AddHome.styles'
import api from 'venus/api'

interface IAddHome {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  addType?: string
}

export const AddHome = ({ setStep, setTitle, addType }: IAddHome) => {
  const dispatch = useAppDispatch()
  const [address, setAddress] = useState('')
  const [addressId, setAddressId] = useState(-1)
  const [googlePlaceId, setGooglePlaceId] = useState('')
  const [options, setOptions] = useState<IAddressOption[]>([])
  const { loading } = useAppSelector((state) => state.address)

  const onSearch = async (value: string) => {
    try {
      const response = await api.get(`/address`, {
        params: {
          query: value,
          size: 10,
        },
      })
      setOptions(response.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const onChange = (value: string, label: string) => {
    if (!value) {
      return
    }
    const obj = JSON.parse(value)
    const { addressId, googlePlaceId } = obj
    setAddressId(addressId)
    setGooglePlaceId(googlePlaceId)
    setAddress(label)
  }

  const handlePressContinue = async () => {
    const data = await dispatch(previewAddressData({ address, googlePlaceId, addressId }))

    const isDreamProperty = addType === Add_Dream_Property_Type

    const title = isDreamProperty ? 'Confirm Dream Property' : 'Confirm your home'

    if (addressId) {
      dispatch(addAddressId(Number(addressId)))
      if (data.meta.requestStatus === 'fulfilled') {
        if (data.payload.length > 1) {
          setStep(5)
          setTitle(isDreamProperty ? 'Select Dream Property' : 'Select Your Property')
        } else {
          const { beds } = data.payload[0]
          if (!beds) {
            setStep(isDreamProperty ? 2 : 3)
            setTitle(title)
          } else {
            setStep(2)
            setTitle(title)
          }
        }
      }
    } else {
      if (data.meta.requestStatus === 'fulfilled') {
        if (addType) {
          setStep(2)
          setTitle(title)
        } else {
          setStep(4)
          setTitle(title)
        }
      }
    }
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          text: 'Continue',
          onClick: handlePressContinue,
          isDisabled: !address || options.length === 0,
          isLoading: loading,
        },
      ]}
    >
      <>
        {addType === 'RequestOwnership' && (
          <Text
            value='Please enter the address of your new property to begin a vault ownership transfer if it is available.'
            format='body'
            style={{ paddingTop: 12, marginBottom: -6 }}
          />
        )}
        <AutoCompleteWrapper>
          <AutoComplete
            isSelectedOnly
            placeholder='Enter home address'
            label=''
            value={address}
            onChange={onChange}
            onSearch={onSearch}
            options={options.map(({ address, addressId, googlePlaceId }) => ({
              label: address,
              value: JSON.stringify({
                addressId: addressId,
                googlePlaceId,
              }),
            }))}
          />
        </AutoCompleteWrapper>
      </>
    </ActionLayout>
  )
}
