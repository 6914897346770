import styled from 'styled-components'

export const BackBtnArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  height: 60px;
  margin-top: 36px;
`

export const BackBtnWrapper = styled.div`
  position: absolute;
  left: 24px;
  top: 20px;
  width: 40px;
  height: 30px;
  cursor: pointer;
`

export const SafeAreaViewWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RightBtnArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-start;
  width: 50%;
  height: 50px;
`

export const TagArea = styled.div`
  display: flex;
  height: 22px;
  padding: 1px 7px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 6px;
  position: absolute;
  right: 24px;
  top: 20px;
`
