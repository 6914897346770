export const weekDayStartsOnMonday = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
]

export const weekDayStartsOnSunday = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
]

export enum MonthList {
  January = 0,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const Years = () => {
  const y = []
  for (let i = 1970; i <= 2050; i++) {
    y.push(i)
  }
  return y
}

export const MonthDropDown = [
  { label: "January", value: "0" },
  { label: "February", value: "1" },
  { label: "March", value: "2" },
  { label: "April", value: "3" },
  { label: "May", value: "4" },
  { label: "June", value: "5" },
  { label: "July", value: "6" },
  { label: "August", value: "7" },
  { label: "September", value: "8" },
  { label: "October", value: "9" },
  { label: "November", value: "10" },
  { label: "December", value: "11" },
]

export const YearDropDown = () => {
  const result: { label: string; value: string }[] = []
  const d = new Date()
  for (let i = d.getFullYear() + 10, l = 1970; i >= l; i--) {
    result.push({
      label: String(i),
      value: String(i),
    })
  }
  return result
}
