import React from 'react'
import { Rating } from 'react-simple-star-rating'
import { Text } from 'venus/components'
import { Container, StarRatingWrapper } from 'venus/components/Rating/Rating.styles'
import { theme } from 'venus/config'
import { ReactComponent as Star } from 'venus/assets/img/icons/Star.svg'

const RatingComponent = ({
  rating = null,
  label = '',
  onClick = () => {},
  size = 25,
  readonly = false,
  allowHalfIcon = false,
  allowHover = true,
  isError = false,
  errorMsg = 'This is required.',
}: {
  rating: number | string;
  label?: string;
  onClick?: any;
  size?: number;
  readonly?: boolean;
  allowHalfIcon?: boolean;
  allowHover?: boolean;
  isError?: boolean
  errorMsg?: string
}) => {
  const percentage = Number(rating)*20

  const handleRating = (rate: number) => {
    onClick(rate / 20)
  }

  return (
    <>
      {label && (
        <Text format={'h3'} value={label}/>
      )}
      <Container>
        <StarRatingWrapper>
          <Rating
            size={size}
            ratingValue={percentage}
            onClick={handleRating}
            fillColor={theme.colors.Primary}
            readonly={readonly}
            allowHalfIcon={allowHalfIcon}
            allowHover={allowHover}
            fullIcon={<Star style={{ marginRight: '6px'}}/>}
            emptyIcon={<Star style={{ marginRight: '6px'}}/>}
          />
        </StarRatingWrapper>
        {isError && (
          <Text
            value={String(errorMsg)}
            format="h4"
            lineHeight="21px"
            color={theme.colors.DangerRed}
            style={{ marginLeft: '18px' }}
          />
        )}
      </Container>
    </>
  )
}

export default RatingComponent