import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { acceptOffer, getDreamPropertyList } from 'venus/redux/features/dreamprice'
import { formatPriceWithDollarSign } from 'venus/utils'
import { Button, Text, AlertBox } from 'venus/components'
import { theme } from 'venus/config'
import { RowWrapper, SalePricePanel, Container } from './DreamPriceOffer.styles'
import useNavigateSearch from 'venus/hooks/useNavigateSearch'

export const DreamPriceOffer = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigateSearch = useNavigateSearch()
  const { currentDreamProperty, loading, list } = useAppSelector(
    (state) => state.dreamprice,
  )

  const [searchParams] = useSearchParams()
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  const dreamProperty = useMemo(() => {
    return (list || []).find(({ id }) => id === dreamPropertyId)
  }, [list, dreamPropertyId])

  const { id, status, dreamPrice, isExpressInterest } =
    dreamProperty || currentDreamProperty
  const [showAcceptOfferOptions, setShowAcceptOfferOptions] = useState(false)
  const isSharedDreamPrice = status === 'SHARE_DREAM_PRICE'
  const isExpressedInterest = isSharedDreamPrice && !isExpressInterest

  useEffect(() => {
    dispatch(getDreamPropertyList())
  }, [])

  const handlPressAcceptOffer = () => {
    setShowAcceptOfferOptions(true)
  }

  const handlPressOkToAcceptOffer = async () => {
    setShowAcceptOfferOptions(false)
    await dispatch(acceptOffer(id))
    await dispatch(getDreamPropertyList())

    navigateSearch('/', { activeIndex: 'Dream Properties' })
  }

  const handlPressCancelToAcceptOffer = () => {
    setShowAcceptOfferOptions(false)
  }

  if (!isSharedDreamPrice) {
    return null
  }
  return (
    <Container>
      <RowWrapper>
        <SalePricePanel>
          <Text
            format='h1'
            value='Dream Sale Price'
            color={theme.colors.Primary}
            style={{ alignSelf: 'flex-start' }}
          />
          <Text
            format='h1'
            value={formatPriceWithDollarSign(dreamPrice)}
            fontSize={32}
            lineHeight={'48px'}
            color={'#F26601'}
            style={{ alignSelf: 'flex-start', marginTop: 18 }}
          />
          <div>
            <Text
              format='h4'
              value={`This house has been listed for `}
              style={{ alignSelf: 'flex-start', marginTop: 18 }}
            />
            <Text
              format='h4'
              value={formatPriceWithDollarSign(dreamPrice)}
              style={{ alignSelf: 'flex-start', marginTop: 18 }}
              fontWeight={600}
            />
            <Text
              format='h4'
              value={`. By registering interest, you will be connected to a local real estate agent for further action.`}
              style={{ alignSelf: 'flex-start', marginTop: 18 }}
            />
          </div>
          {isSharedDreamPrice && (
            <Button
              onClick={handlPressAcceptOffer}
              text={isExpressInterest ? 'Expressed Interest' : ' Express Interest'}
              isDisabled={!isExpressedInterest}
              type='secondary'
              style={{
                left: '32px',
                bottom: '32px',
                position: 'absolute',
                width: '170px',
                backgroundColor: theme.colors.LightBlue,
              }}
            />
          )}
        </SalePricePanel>
      </RowWrapper>
      <AlertBox
        cancel={handlPressCancelToAcceptOffer}
        title='Express Interest'
        message='We will notify to real estate agent in the area.  They may contact with you later.  Do you want to proceed to express the interest?'
        btnGroup={[
          {
            text: 'OK',
            onClick: handlPressOkToAcceptOffer,
            loading,
            type: 'primary',
          },
          {
            text: 'Cancel',
            onClick: handlPressCancelToAcceptOffer,
            color: 'LightBlue',
            textColor: 'Primary',
            type: 'secondary',
          },
        ]}
        showAlert={showAcceptOfferOptions}
      />
    </Container>
  )
}
