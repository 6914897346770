import _ from 'lodash'
import { useEffect, useState } from 'react'
import api from 'venus/api'
import { useDropzone } from 'react-dropzone'
import ImagePreview from 'venus/common/ImagePreview'
import { partialUpdateItem } from 'venus/redux/features/item/item'
import {
  updateItemInProperty,
  updateProperty,
} from 'venus/redux/features/property/property'
import { updateItemInSpace, updateSpace } from 'venus/redux/features/space/space'
import { useAppDispatch } from 'venus/redux/hooks'
import ImageUploading from '../ImageUploading'
import { Container, DropContainer, DropIndicator, Image } from './PhotoLibrary.styles'
import { EmptyData } from '../EmptyData'
import image from 'venus/redux/features/image/image'
import { Text } from '../Text'
import { theme } from 'venus/config'
import { useSearchParams } from 'react-router-dom'

const processMove = (dispatch, propertyWideSpaceId, { source, target, result }) => {
  if (source.itemId) {
    dispatch(partialUpdateItem(result.source))
  } else if (source.spaceId === propertyWideSpaceId) {
    dispatch(updateProperty(_.omit(result.source, 'id')))
  } else {
    dispatch(updateSpace(result.source))
  }

  if (!target.itemId && target.spaceId === propertyWideSpaceId) {
    dispatch(updateProperty(_.omit(result.target, 'id')))
  } else if (!target.itemId && target.spaceId === source.spaceId) {
    dispatch(updateSpace(result.target))
  }

  if (target.itemId) {
    if (target.spaceId === source.spaceId) {
      if (target.spaceId === propertyWideSpaceId) {
        dispatch(updateItemInProperty(result.target))
      } else {
        dispatch(updateItemInSpace(result.target))
      }
    }
  }
}

const PhotoLibrary = ({
  images,
  uploader,
  psiId,
  update,
  propertyWideSpaceId,
  isShared,
  uploadImages,
  uploading,
  isPersonal = false,
  style,
}) => {
  const [index, setIndex] = useState(null)
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const defaultIndex = searchParams.get('index')

  useEffect(() => {
    if (!_.isEmpty(defaultIndex)) setIndex(+defaultIndex)
  }, [defaultIndex])

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept } =
    useDropzone({
      noClick: true,
      accept: {
        'image/*': ['.jpeg', '.png'],
      },
    })

  const close = () => setIndex(null)

  useEffect(() => {
    if (acceptedFiles.length) {
      uploadImages(acceptedFiles)
    }
  }, [acceptedFiles])

  const handleMove = async ({ image, source, target }) => {
    const updated = await api
      .put(`/images`, {
        images: [image],
        source,
        target,
      })
      .then(({ data }) => data)

    processMove(dispatch, propertyWideSpaceId, {
      source,
      target,
      result: updated,
    })

    close()
  }

  const handleDelete = async () => {
    if (_.isNumber(index)) {
      const image = images[index]

      const response = await api.delete(`/images`, {
        data: { images: [image], ...psiId },
      })
      const updatedObject = response.data

      if (psiId.itemId || psiId.personalItemId) {
        dispatch(update(updatedObject))
      } else {
        updatedObject.spaceId = updatedObject.id
        dispatch(update(_.omit(updatedObject, 'id')))
      }

      if (images.length === 1) {
        close()
      }
      // closeDeleteConfirm()
      // closeImagePreview()
    }
  }

  if (!images) {
    return null
  }

  return (
    <DropContainer {...getRootProps({ isFocused, isDragAccept })}>
      <input {...getInputProps()} />

      {images.length === 0 && (
        <EmptyData
          fullBlock
          message={
            isShared
              ? ''
              : `To add images, upload them directly to this space or classify existing ones.`
          }
        />
      )}

      <Container>
        {/* <div style={{ position: 'relative', paddingTop: 130 }}></div> */}
        {!!uploading}
        {!!uploader.image && (
          <ImageUploading
            style={{ borderRadius: 5 }}
            progress={uploader.progress}
            image={uploader.image}
            url={uploader.url}
          />
        )}

        {images.map((image, index) => (
          <Image key={image.url} image={image.url} onClick={() => setIndex(index)} />
        ))}

        <ImagePreview
          isShared={isShared}
          index={index}
          images={images}
          isOpen={!_.isNull(index)}
          close={close}
          onDelete={!isShared && handleDelete}
          onMove={!isShared && !isPersonal && handleMove}
          psiId={psiId}
        />
      </Container>

      <DropIndicator>
        <Text format='h1' fontSize={26} color={theme.colors.Primary}>
          Drop your image here
        </Text>
      </DropIndicator>
    </DropContainer>
  )
}

export default PhotoLibrary
