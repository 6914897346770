import styled, { keyframes } from 'styled-components'

const rotate = keyframes` 
  to {
    transform: rotate(1turn);
  }
`

export const LoadingDiv = styled.div`
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: ${rotate} 1s infinite linear;
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
