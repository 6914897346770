import { useState } from 'react'
import { Add_Dream_Property_Type, theme } from 'venus/config'
import { Text, Image, ActionLayout } from 'venus/components'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { setPreview } from 'venus/redux/features/address'
import { addressPreviewStr } from 'venus/utils'
import { RowContainer, InfoWrapper, RadioWrapper } from './SelectHome.styles'
import { ReactComponent as Checked } from 'venus/assets/img/icons/Checked.svg'
import { ReactComponent as UnChecked } from 'venus/assets/img/icons/UnChecked.svg'

interface ISelectHome {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  addType?: string
}

export const SelectHome = ({ setStep, setTitle, addType }: ISelectHome) => {
  const dispatch = useAppDispatch()
  const { preview } = useAppSelector((state) => state.address)
  const [selected, setSelected] = useState(-1)

  const handlePress = (index: number) => {
    setSelected(index)
  }

  const handlePressContinue = () => {
    dispatch(setPreview([preview[selected]]))
    const isDreamProperty = addType === Add_Dream_Property_Type
    if (isDreamProperty) {
      setStep(2)
      setTitle('Confirm Dream Property')
    }
    const { beds } = preview[selected]
    if (!beds) {
      setStep(isDreamProperty ? 2 : 3)
      setTitle(isDreamProperty ? 'Confirm dream home' : 'Confirm your home')
    } else {
      setStep(2)
      setTitle('Confirm your home')
    }
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          text: 'Next',
          onClick: handlePressContinue,
          isDisabled: selected === -1,
        },
      ]}
    >
      {preview.map((item, index) => {
        const isSelected = selected === -1 || selected === index
        const {
          address,
          suburb,
          state,
          postcode,
          beds,
          baths,
          area,
          imageBaseUrl,
        } = item as any
        return (
          <RowContainer
            onClick={() => handlePress(index)}
            key={`property_${index}`}
            style={{ marginBottom: 12 }}
          >
            <Image
              image={`${imageBaseUrl}.jpg`}
              style={{ width: '120px', height: '76px' }}
              imageStyle={{ borderRadius: '8px' }}
            />
            <InfoWrapper>
              <Text
                value={addressPreviewStr({ beds, baths, area })}
                color={!isSelected ? theme.colors.Grey : theme.colors.DarkGrey}
                format="body"
                style={{ marginLeft: 12 }}
                fontSize={12}
              />
              <Text
                value={address}
                color={!isSelected ? theme.colors.Grey : theme.colors.Black}
                format="body"
                style={{ marginLeft: 12 }}
                fontSize={12}
              />
              <Text
                value={`${suburb} ${state} ${postcode}`}
                color={!isSelected ? theme.colors.Grey : theme.colors.Black}
                format="body"
                style={{ marginLeft: 12 }}
                fontSize={12}
              />
            </InfoWrapper>
            <RadioWrapper>
              {selected === index ? (
                <Checked
                  style={{
                    alignSelf: 'center',
                  }}
                />
              ) : (
                <UnChecked
                  style={{
                    alignSelf: 'center',
                  }}
                />
              )}
            </RadioWrapper>
          </RowContainer>
        )
      })}
    </ActionLayout>
  )
}
