import styled from 'styled-components'

export const BtnArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  padding: 24px 24px 48px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ItemsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 440px;
  overflow: auto;
`
