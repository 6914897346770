import styled from 'styled-components'

export const ChangePwdContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ChangePwdBodyWrapper = styled.div`
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
`
