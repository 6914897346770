import styled from 'styled-components'

export const Container = styled.ul`
  position: relative;
  padding: 10px;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`

export const ImageContainer = styled.li``

type ImageProps = {
  readonly image: string
}

export const Image = styled.div<ImageProps>`
  position: relative;
  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(90%);
  }
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;

  cursor: pointer;
  aspect-ratio: 1;
`

type DropContainerProps = {
  readonly isDragAccept: boolean
}

export const DropIndicator = styled.div`
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);
  border: 1px dashed ${(props) => props.theme.colors.Secondary};
  border-radius: 5px;

  align-items: center;
  justify-content: center;
`

export const DropContainer = styled.div<DropContainerProps>`
  position: relative;
  height: 100%;

  ${({ isDragAccept }) =>
    isDragAccept &&
    `
  ${DropIndicator} {
    display: flex;
  }
  `}
`
