import { SpaceTypes } from 'venus/config'
import _ from 'lodash'

export type THistory = {
  space?: {
    type:
      | 'ENTRANCE_HALL'
      | 'LIVING'
      | 'KITCHEN'
      | 'BATH'
      | 'BED'
      | 'STUDY'
      | 'LAUNDRY'
      | 'GARAGE'
      | 'GARDEN'
      | 'POOL'
      | 'BALCONY'
      | 'TERRACE'
      | 'SHED'
      | 'WINE_CELLAR'
      | 'BOAT_HOUSE'
      | 'PROPERTY'
      | 'OTHER'
  }
  item?: {
    name: string
  }
}

export const getHistoryNames = (histories: THistory[], type: string) => {
  const spaces: string[] = []
  const items: string[] = []
  histories.forEach(({ space, item }) => {
    if (space) {
      const { type } = space
      const displayName = _.capitalize(SpaceTypes[type] || type)
      if (displayName && spaces.indexOf(displayName) === -1) spaces.push(displayName)
    }
    if (item) {
      const name = _.capitalize(item?.name || '')
      if (name && items.indexOf(name) === -1) items.push(name)
    }
  })
  return {
    spaceTags: spaces,
    itemTags: items
  }
}
