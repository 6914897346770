import { useEffect, useState } from 'react'
import { isFromMobileBrowser } from 'venus/utils'
import {
  Container,
  DownloadIcon,
  DownloadSection,
  PhoneContainer,
} from './MobileView.styles'
import { ReactComponent as Phone } from 'venus/assets/img/Iphone.svg'
import { theme } from 'venus/config'
import { LinkButton, Text } from 'venus/components'
import { Outlet } from 'react-router-dom'
import useMobileContext from 'venus/context/MobileBrowser'

const MobileView = ({ children }: any) => {
  // const [isMobile, setIsMobile] = useState(isFromMobileBrowser())

  // const showDesktop = () => setIsMobile(false)

  const { isMobile, showDesktop } = useMobileContext()

  console.log('isMobile', isMobile)

  const handleGoToAppStore = () => {
    window.location.href = 'https://apps.apple.com/au/app/myhomevault/id1619854877'
  }

  const handleGoToGoogleStore = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.myhomevault.myhomevault'
  }

  if (!isMobile) {
    return <Outlet />
  }

  return (
    <Container>
      <PhoneContainer>
        <Phone width={50} height={50} color={theme.colors.Secondary} />
      </PhoneContainer>
      <Text format='h2' style={{ maxWidth: '260px', textAlign: 'center' }} fontSize={20}>
        Download our mobile app to get an experience that’s optimized for smaller screens
      </Text>
      <DownloadSection>
        <DownloadIcon onClick={handleGoToAppStore} src='/svg/App_Store.svg' />
        <DownloadIcon onClick={handleGoToGoogleStore} src='/png/global-playstore.png' />
      </DownloadSection>
      <LinkButton
        color={theme.colors.Secondary}
        format='body'
        handlePress={showDesktop}
        label={'Show desktop site'}
      />
    </Container>
  )
}

export default MobileView
