import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from 'venus/api'

export const getAcceptOwnership = createAsyncThunk(
  'profile/getAcceptOwnership',
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/transfer/${code}`)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  },
)
