import React from 'react'
import { useState } from 'react'
import { Input, Notification, Button } from 'venus/components'
import { Text } from 'venus/components'
import api, { axiosClient } from 'venus/api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BgWrapper } from 'venus/layout/MHV/MHV.styles'
import { LoginPanel } from '../Login/Login.styles'
import { BackIconWrapper, ModalHeader, ModalTitle } from './OTPInput.styles'
import { ReactComponent as BackIcon } from 'venus/assets/img/icons/Back.svg'
import { theme } from 'venus/config/theme'
import { setAuthToken } from 'venus/auth/auth'
import _ from 'lodash'

export const OTPInput = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const otpToken = searchParams.get('otpToken')
  const [code, setCode] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [msg, setMsg] = useState('')

  const goBack = () => navigate('/login')

  const handleOnChangeText = async (value: string) => {
    setCode(value)
    if (value.length === 6) {
      try {
        const response = await axiosClient.post('/auth/validate-otp', 
            { otpToken, otp: value}
        )
        console.log('validate OTP::', response.data)

        if (response.data?.accessToken) {
          // sign-in
          const accessToken=response.data.accessToken
          setAuthToken(accessToken)

          // if redirected from another page, redirect to that page
          const query = window.location.search
          if (query.startsWith('?redirect=')) {
            const url = query.replace('?redirect=', '')
            window.location.replace(url)
          }
          navigate('/', { replace: true })
        }
      } catch (err) {
        console.log('validate OTP err', err.data)
        setCode('')
        setMsg(_.get(err, 'response.data.errors[0].msg', err?.response?.data))
        setShowAlert(true);
      }
    }
  }

  const handlePressResendCode = async () => {
    try {
      const response = await axiosClient.post('/auth/resend-otp', 
        { otpToken }, 
      )
      setCode('')
      setMsg('One time passcode has been resent.')
      setShowAlert(true)
    } catch (err) {
      console.log('resendOTP err', err.data)
      setCode('')
      setMsg(_.get(err, 'response.data.errors[0].msg', err?.response?.data))
      setShowAlert(true);
    }
  }

  const handlPressAlertOK = async () => {
    if (
      msg === 'Hit 3 times retry limitation.' ||
      msg ===
        'Issuing one time passcode exceeds 5 times a day.  Please retry tomorrow.' ||
      msg === 'Resent OTP error'
    ) {
      navigate('/login')
    }
    setShowAlert(false)
    setMsg('')
  }

  return (
    <BgWrapper
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPanel style={{ paddingTop: 0 }}>
        <ModalHeader>
          <BackIconWrapper onClick={goBack}>
            <BackIcon />
          </BackIconWrapper>
          <ModalTitle>
            <Text format={'h1'} value={'One Time Passcode'} />
          </ModalTitle>
          <BackIconWrapper></BackIconWrapper>
        </ModalHeader>
        <Text format='body' style={{ textAlign: 'center' }}>
          Sent you one time passcode via email or SMS.
        </Text>
        <Input
          value={code}
          onChange={handleOnChangeText}
          maxLength={6}
          style={{ margin: '18px 0' }}
        />
        <Button
              type = 'secondary'
              onClick={handlePressResendCode}
              text="Resend code"
        />
      </LoginPanel>
      <Notification
        showAlert={showAlert}
        closeAlert={handlPressAlertOK}
        message={msg}
      />
    </BgWrapper>
  )
}

export default OTPInput
