import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api, { axiosClient } from 'venus/api'
import { ISignUpAsync, ISignInAsync } from './types'

export const errorHandling = (err: any) => {
  const error: AxiosError = err
  console.log('err', err)
  if (!error?.response) {
    throw err
  }
  return isRejectedWithValue(error.response.data)
}

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (body: ISignUpAsync, { rejectWithValue }) => {
    try {
      console.log('sign up body::', body)
      const response = await api.post('/auth/sign-up', body)
      console.log('signUp response.data::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (body: ISignInAsync, { rejectWithValue }) => {
    try {
      const response = await axiosClient.post('/auth/sign-in', body)
      console.log('signIn response.data::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
