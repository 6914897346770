import { theme } from 'venus/config'
import { IconArea } from './Chevron.styles'

interface IChevron {
  width?: number
  height?: number
  color?: string
  bgColor?: string
  strokeWidth?: number
  direction: 'left' | 'right' | 'up' | 'down'
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}

export const Chevron = ({
  width = 24,
  height = 24,
  color = theme.colors.White,
  bgColor,
  strokeWidth = 2,
  direction,
  marginTop = 0,
  marginRight = 0,
  marginBottom = 0,
  marginLeft = 0,
}: IChevron) => {
  const rotate = {
    left: '90deg',
    right: '270deg',
    up: '180deg',
    down: '0deg',
  }
  return (
    <IconArea
      style={{
        height,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        backgroundColor: bgColor,
        transform: `rotate(${rotate[direction]})`,
      }}
    >
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none'>
        <path
          d='M8 10L12.1667 14.1667L16.3333 10'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={strokeWidth}
        />
      </svg>
    </IconArea>
  )
}
