import * as tf from '@tensorflow/tfjs'
import { CLASSES, MHV_CLASSES, PREDICTABLE } from './constants'
import { SpaceDropdown } from 'venus/config'

interface DetectedObject {
  bbox: [number, number, number, number] // [x, y, width, height]
  class: string
  score: number
}

const maxNumBoxes = 10
const minScore = 0.5

const calculateMaxScores = (
  scores: Float32Array,
  numBoxes: number,
  numClasses: number,
): [number[], number[]] => {
  const maxes = []
  const classes = []
  for (let i = 0; i < numBoxes; i++) {
    let max = Number.MIN_VALUE
    let index = -1
    for (let j = 0; j < numClasses; j++) {
      if (scores[i * numClasses + j] > max) {
        max = scores[i * numClasses + j]
        index = j
      }
    }
    maxes[i] = max
    classes[i] = index
  }

  return [maxes, classes]
}

const buildDetectedObjects_o = (
  width: number,
  height: number,
  boxes: Float32Array,
  scores: number[],
  indexes: Float32Array,
  classes: number[],
): DetectedObject[] => {
  const count = indexes.length
  const objects: DetectedObject[] = []
  for (let i = 0; i < count; i++) {
    const bbox = []
    for (let j = 0; j < 4; j++) {
      bbox[j] = boxes[indexes[i] * 4 + j]
    }
    const minY = bbox[0] * height
    const minX = bbox[1] * width
    const maxY = bbox[2] * height
    const maxX = bbox[3] * width
    bbox[0] = minX
    bbox[1] = minY
    bbox[2] = maxX - minX
    bbox[3] = maxY - minY
    objects.push({
      bbox: bbox as [number, number, number, number],
      class: CLASSES[classes[indexes[i]] + 1].displayName,
      score: scores[indexes[i]],
    })
  }
  return objects
}

export const buildDetectedObjects = (
  width: number,
  height: number,
  scores: Float32Array,
  boxes,
  classes,
) => {
  const detectionObjects = []
  // @ts-ignore
  scores[0].forEach((score, i) => {
    if (score > minScore) {
      const bbox = []
      const minY = boxes[0][i][0] * height
      const minX = boxes[0][i][1] * width
      const maxY = boxes[0][i][2] * height
      const maxX = boxes[0][i][3] * width
      bbox[0] = minX
      bbox[1] = minY
      bbox[2] = maxX - minX
      bbox[3] = maxY - minY

      const classIndex = Math.round(classes[i])

      detectionObjects.push({
        class: MHV_CLASSES[classIndex].displayName,
        score: score.toFixed(4),
        bbox: bbox,
      })
    }
  })
  return detectionObjects
}

export const getResult = async (model, batched) => {
  //   const batched = tf.tidy(() => {
  //     if (!(img instanceof tf.Tensor)) {
  //       img = tf.browser.fromPixels(img);
  //     }
  //     // Reshape to a single-element batch so we can pass it to executeAsync.
  //     return tf.expandDims(img);
  //   });
  const height = batched.shape[1]
  const width = batched.shape[2]

  // model returns two tensors:
  // 1. box classification score with shape of [1, 1917, 90]
  // 2. box location with shape of [1, 1917, 1, 4]
  // where 1917 is the number of box detectors, 90 is the number of classes.
  // and 4 is the four coordinates of the box.
  const result = (await model.executeAsync(batched)) as tf.Tensor[]

  const scores = result[0].dataSync() as Float32Array
  const boxes = result[1].dataSync() as Float32Array
  // clean the webgl tensors
  batched.dispose()
  tf.dispose(result)

  const [maxScores, classes] = calculateMaxScores(
    scores,
    result[0].shape[1],
    result[0].shape[2],
  )

  const prevBackend = tf.getBackend()
  // run post process in cpu
  if (tf.getBackend() === 'webgl') {
    tf.setBackend('cpu')
  }
  const boxes2 = tf.tidy(() => tf.tensor2d(boxes, [result[1].shape[1], result[1].shape[3]]))

  const indexTensor = await tf.image.nonMaxSuppressionAsync(
    boxes2,
    maxScores,
    maxNumBoxes,
    minScore,
    minScore,
  )

  const indexes = indexTensor.dataSync() as Float32Array
  indexTensor.dispose()

  // restore previous backend
  if (prevBackend !== tf.getBackend()) {
    tf.setBackend(prevBackend)
  }

  return buildDetectedObjects_o(width, height, boxes, maxScores, indexes, classes)
}

export const getPredictSpace = (predictions) => {
  const spacesNumber = predictions.reduce((acc, curr) => {
    const space = PREDICTABLE[curr.class].space
    if (acc[space]) {
      return { ...acc, [space]: acc[space] + 1 }
    }

    return { ...acc, [space]: 1 }
  }, {})

  // @ts-ignore
  const max = Math.max(...Object.values(spacesNumber))

  return Object.keys(spacesNumber).find((space) => spacesNumber[space] === max)
}

export const getDetectedSpace = (spaceType: string, spaces: any) => {
  console.log('SpaceType', spaceType, spaces)
  const existingSpace = spaces.find((s) => s.type === spaceType)

  const newSpace = SpaceDropdown.find((s) => s.value === spaceType)

  return existingSpace
    ? { id: existingSpace.id, value: existingSpace.type, label: existingSpace.name }
    : { id: 'new', ...newSpace }
}

export const getUpdatedDetections = (matched, items) => {
  const map = items.reduce((acc, curr) => {
    const arr = acc[curr.typeId] || []
    return { ...acc, [curr.typeId]: [...arr, curr.id] }
  }, {})

  return matched.map((match) => {
    if (match.itemId) {
      return match
    }

    const { type } = match

    if (map[type]) {
      const itemId = map[type].pop()
      return { ...match, itemId }
    }

    return match
  })
}

const getIntersectingRectangle = (r1, r2) => {
  ;[r1, r2] = [r1, r2].map((r) => {
    return {
      x: [r.x1, r.x2].sort((a, b) => a - b),
      y: [r.y1, r.y2].sort((a, b) => a - b),
    }
  })

  const noIntersect =
    r2.x[0] > r1.x[1] || r2.x[1] < r1.x[0] || r2.y[0] > r1.y[1] || r2.y[1] < r1.y[0]

  return noIntersect
    ? false
    : {
        x1: Math.max(r1.x[0], r2.x[0]), // _[0] is the lesser,
        y1: Math.max(r1.y[0], r2.y[0]), // _[1] is the greater
        x2: Math.min(r1.x[1], r2.x[1]),
        y2: Math.min(r1.y[1], r2.y[1]),
      }
}

const getRBox = (box) => ({
  x1: box[0],
  y1: box[1],
  x2: box[0] + box[2],
  y2: box[1] + box[3],
})

const getArea = (box) => (box.x2 - box.x1) * (box.y2 - box.y1)
export const detectDuplicatedObject = (object, predictions) => {
  return predictions.some((pre) => {
    const objectBox = getRBox(object.bbox)
    const predictBox = getRBox(pre.bbox)
    const box = getIntersectingRectangle(objectBox, predictBox)
    if (!box) {
      return false
    }

    const intersectArea = getArea(box)

    const maxArea = Math.max(getArea(objectBox), getArea(predictBox))

    return intersectArea / maxArea > 0.5
  })
}

export const readImageFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result)

    reader.readAsDataURL(file)
  })
}

export const createHTMLImageElement = (imageSrc) => {
  return new Promise((resolve) => {
    const img = new Image()
    // @ts-ignore
    img.crossorigin = 'anonymous'
    img.onload = () => resolve(img)

    img.src = imageSrc
  })
}

export const generateBBox = (boundingBox) => [
  boundingBox.originX,
  boundingBox.originY,
  boundingBox.width,
  boundingBox.height,
]
