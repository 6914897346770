import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useParams } from 'react-router-dom'
// import { useShareStatus } from 'venus/hooks/useShareStatus'
import {
  getContactList,
  IContactEntry,
  IPhoneContactFields,
} from 'venus/redux/features/contact'
import { Loader, Button, Modal, ActionLayout, Select } from 'venus/components'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { roles, tradesPeopleSubRolesArr } from 'venus/config'
import { CreateEditContact } from 'venus/pages/Contact/CreateEditContact'
import { SubList } from 'venus/pages/Contact/List/SubList'
import { SelectArea, SelectRoleArea, SubListWrapper } from './SelectContact.styles'

interface IList {
  handleSelect?: (contact: IContactEntry) => void
  isShowAddBtn?: boolean
  isOpen: boolean
  onClose: () => void
  onBack: () => void
  title: string
  actions?: any[]
  type?: string
  selectedId?: string
}

export const SelectContact = ({
  handleSelect,
  onClose,
  onBack,
  isShowAddBtn = true,
  isOpen,
  title,
  actions,
  type = 'WithHomeOwner',
  selectedId,
}: IList) => {
  const dispatch = useAppDispatch()
  const { propertyId } = useParams()
  const { entries, loading } = useAppSelector((state) => state.contact)
  const [showManuallyInputModal, setShowManuallyInputModal] = useState(false)
  const [role1, setRole1] = useState('all')
  const [role2, setRole2] = useState('')

  useEffect(() => {
    if (propertyId) dispatch(getContactList(propertyId))
  }, [propertyId])

  // const { isShared } = useShareStatus()
  // if (isShared) return null

  if (loading || !entries) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  const handlePressPlus = () => {
    handlePressManuallyInput()
  }

  const handlePressManuallyInput = () => {
    setShowManuallyInputModal(true)
  }

  const handleClose = () => {
    setRole1('all')
    setRole2('')
    onClose()
  }

  const handleSetRole1 = (v) => {
    setRole1(v)
    if (v !== 'TRADESPEOPLE') setRole2('')
  }

  const listAll = entries
    ? entries.filter(({ id }) => (type === 'WithHomeOwner' ? true : id !== '-1'))
    : []
  const listTradespeople = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[1].value?.toLowerCase(),
  )
  const listRealEstateAgent = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[2].value?.toLowerCase(),
  )
  const listPropertyManager = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[3].value?.toLowerCase(),
  )
  const listBuilder = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[4].value?.toLowerCase(),
  )
  const listDeveloper = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[5].value?.toLowerCase(),
  )
  const listOther = listAll.filter(
    ({ role }) =>
      role[0]?.toLowerCase() === roles[0].value?.toLowerCase() ||
      role[0]?.toLowerCase() === roles[6].value?.toLowerCase(),
  )

  const listPlumber = listTradespeople.filter(
    ({ tradespeopleRole }) =>
      !!tradespeopleRole &&
      tradespeopleRole.indexOf(tradesPeopleSubRolesArr[0].label?.toUpperCase()) >= 0,
  )
  const listElectrician = listTradespeople.filter(
    ({ tradespeopleRole }) =>
      !!tradespeopleRole &&
      tradespeopleRole.indexOf(tradesPeopleSubRolesArr[1].label?.toUpperCase()) >= 0,
  )
  const listLandscaper = listTradespeople.filter(
    ({ tradespeopleRole }) =>
      !!tradespeopleRole &&
      tradespeopleRole.indexOf(tradesPeopleSubRolesArr[2].label?.toUpperCase()) >= 0,
  )
  const listPainter = listTradespeople.filter(
    ({ tradespeopleRole }) =>
      !!tradespeopleRole &&
      tradespeopleRole.indexOf(tradesPeopleSubRolesArr[3].label?.toUpperCase()) >= 0,
  )
  const listTradesPeopleOther = listTradespeople.filter(
    ({ tradespeopleRole }) =>
      !!tradespeopleRole &&
      tradespeopleRole.indexOf(tradesPeopleSubRolesArr[4].label?.toUpperCase()) >= 0,
  )

  const list = () => {
    if (role1 === '' || role1 === 'all') return listAll
    if (role1 === roles[1].value) {
      if (role2 === '') return listTradespeople
      if (role2 === tradesPeopleSubRolesArr[0].value) return listPlumber
      if (role2 === tradesPeopleSubRolesArr[1].value) return listElectrician
      if (role2 === tradesPeopleSubRolesArr[2].value) return listLandscaper
      if (role2 === tradesPeopleSubRolesArr[3].value) return listPainter
      return listTradesPeopleOther
    }
    if (role1 === roles[2].value) return listRealEstateAgent
    if (role1 === roles[3].value) return listPropertyManager
    if (role1 === roles[4].value) return listBuilder
    if (role1 === roles[5].value) return listDeveloper
    return listOther
  }

  return (
    <Modal
      width={520}
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      closeOnClickOutside={false}
      onBack={onBack}
      headerStyle={{
        textAlign: 'center',
        width: '380px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <ActionLayout
        margin={34}
        rightActions={actions}
        children={
          <>
            <SelectArea>
              <SelectRoleArea
                style={{
                  width: isShowAddBtn ? '190px' : 'calc(50% - 8px)',
                }}
              >
                <Select
                  title={''}
                  options={[{ label: 'All', value: 'all' }, ...roles.slice(1)]}
                  value={role1}
                  onChange={handleSetRole1}
                  isSmallVersion
                />
              </SelectRoleArea>
              {role1 === 'TRADESPEOPLE' && (
                <SelectRoleArea
                  style={{
                    width: isShowAddBtn ? '150px' : 'calc(50% - 8px)',
                  }}
                >
                  <Select
                    title={''}
                    options={tradesPeopleSubRolesArr}
                    value={role2}
                    onChange={setRole2}
                    isDisabled={role1 !== roles[1].value}
                    isSmallVersion
                  />
                </SelectRoleArea>
              )}
              <SelectRoleArea
                style={{
                  width: isShowAddBtn ? '110px' : '0px',
                }}
              >
                {isShowAddBtn && (
                  <Button
                    type='tertiary'
                    text='Contact'
                    onClick={handlePressPlus}
                    iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
                    style={{ width: 'auto' }}
                  />
                )}
              </SelectRoleArea>
            </SelectArea>
            <SubListWrapper>
              <SubList
                handlePress={handleSelect}
                persons={list()}
                selectedId={selectedId}
                displaySelectIndicator
              />
            </SubListWrapper>
            {showManuallyInputModal && (
              <CreateEditContact
                propertyId={propertyId}
                setShow={setShowManuallyInputModal}
                isEditMode={false}
                isCreateMode
              />
            )}
          </>
        }
      />
    </Modal>
  )
}
