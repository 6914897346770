import { VideoContainer, VideoLength } from './Videos.styles'
import ReactPlayer from 'react-player'
import { Text } from 'venus/components'
import { theme } from 'venus/config'

const UploadingVideo = ({ video, progress }) => {
  return (
    <VideoContainer>
      <ReactPlayer className='react-player' url={video} width='100%' height='auto' />
      <div
        className='placeholder'
        style={{ backgroundColor: theme.colors.LightGrey }}
      ></div>
      <VideoLength>
        <Text format='h4' color={theme.colors.White} style={{ padding: '4px 8px' }}>
          Uploading ... {progress}%
        </Text>
      </VideoLength>
    </VideoContainer>
  )
}

export default UploadingVideo
