import styled from 'styled-components'
import { theme } from 'venus/config'
import { ReactComponent as MHV } from 'venus/assets/img/icons/MHV.svg'
import { Text } from 'venus/components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const PremiumSign = ({ text = 'Premium' }: { text?: string }) => {
  return (
    <Container>
      <MHV color={theme.colors.FreeYellow} width={14} height={14} />
      <Text
        format='h3'
        style={{ marginLeft: 10 }}
        value={text}
        color={theme.colors.White}
      />
    </Container>
  )
}

export default PremiumSign
