import { Route, Routes } from 'react-router-dom'
import { AcceptRequestOwnership } from 'venus/layout/ProfileMenu/ProfileSettings/AcceptRequestOwnership'
import Authenticated from './auth/Authenticated'
import { Login } from './auth/SignIn'
import Logout from './auth/SignIn/Logout'
import GeneralLayout from './layout/GeneralLayout/GeneralLayout'
import { Deeplink } from './pages/Deeplink'
import { DownloadApp } from './pages/DownloadApp'
import { PersonalVault } from './pages/Personal'
import PersonalItem from './pages/Personal/PersonalItems/PersonalItem'
import Properties from './pages/Properties'
import { UI } from 'venus/pages/UI'
import { List as ContactList } from 'venus/pages/Contact'
import { Shared, AddReceivedShare } from 'venus/pages/Share'
import { ReminderList } from 'venus/pages/Reminder'
import SignUp from './auth/SignUp'
import Property from './pages/Property'
import Space from './pages/Space'
import Item from './pages/Item'
import SpaceContainer from './pages/Space/SpaceContainer'
import { AcceptOwnership } from 'venus/layout/ProfileMenu/ProfileSettings/AcceptOwnership'
import { PrivacyPolicy } from 'venus/pages/PrivacyPolicy'
import { TermsAndConditions } from 'venus/pages/TermsAndConditions'
import DreamPropertyPreivew from './pages/Property/DreamProperty/DreamPropertyPreivew'
import PaymentResult from './pages/PaymentResult'
import ForgotPassword from './auth/SignIn/ForgotPassword'
import ResetPassword from './auth/SignIn/ResetPassword'
import DeeplinkLayout from './layout/Deeplink'
import MobileView from './common/MobileView'
import { OTPInput } from './auth/SignIn/OTPInput'
import { ModelProvider } from './context/ModelContext'
import AppScanHome from './common/AppScanHome'

const Router = () => {
  return (
    <Routes>
      <Route path='scan-home' element={<AppScanHome />} />
      <Route
        path='transfer'
        element={<Deeplink description={'MyHomeVault - Accept Ownership'} />}
      />
      <Route
        path='share'
        element={<Deeplink description={'MyHomeVault - Share Property'} />}
      />
      <Route
        path='request-ownership'
        element={<Deeplink description={'MyHomeVault - Claim Existing Vault'} />}
      />
      <Route
        path='auth/reset-password'
        element={<Deeplink description={'MyHomeVault - Reset Password'} />}
      />

      <Route element={<MobileView />}>
        <Route path='login' element={<Login />} />
        <Route path='sign-up' element={<SignUp />} />

        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='logout' element={<Logout />} />
        <Route path='otp-input' element={<OTPInput />} />

        <Route
          element={
            <Authenticated>
              <GeneralLayout />
            </Authenticated>
          }
        >
          <Route path='personal'>
            <Route index element={<PersonalVault />} />
            <Route path='item/:itemId' element={<PersonalItem />} />
          </Route>

          <Route index element={<Properties />} />

          <Route path='property/:propertyId'>
            <Route index element={<Property />} />
            <Route path='space/:spaceId' element={<SpaceContainer />}>
              <Route index element={<Space />} />
              <Route path='item/:itemId' element={<Item />} />
            </Route>
            <Route path='contacts' element={<ContactList />} />
            <Route path='shared' element={<Shared />} />
            <Route path='reminders' element={<ReminderList />} />
          </Route>
          <Route path='deeplink' element={<DeeplinkLayout />}>
            <Route path='transfer-ownership' element={<AcceptOwnership />} />
            <Route path='request-ownership' element={<AcceptRequestOwnership />} />
            <Route path='add-share-property' element={<AddReceivedShare />} />
          </Route>

          <Route path='dream/:dreamId' element={<DreamPropertyPreivew />} />
        </Route>

        <Route path='reset-password' element={<ResetPassword />} />

        <Route
          path='payment'
          element={
            <Authenticated>
              <PaymentResult />
            </Authenticated>
          }
        />
        <Route path='*' element={<DownloadApp />} />
      </Route>
      <Route path='demo' element={<UI />} />
      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      <Route path='terms-and-conditions' element={<TermsAndConditions />} />
    </Routes>
  )
}

export default Router
