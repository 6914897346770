import styled from 'styled-components'
import { theme } from 'venus/config'

type ContainerProps = {
  readonly isActive: boolean
}

export const Container = styled.div<ContainerProps>`
  margin: 6px 0;
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  cursor: pointer;
  transition-duration: 0.3s;
`

type SpaceProps = {
  readonly pressed: boolean
  readonly isActive: boolean
}

export const Space = styled.div<SpaceProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  opacity: ${({ pressed, isActive }) => (pressed || isActive ? 1 : 0.5)};
`

export const ImageContainer = styled.div`
  aspect-ratio: 1;
  width: 43px;
  margin-right: 20px;
  border-radius: 2px;
  overflow: hidden;
`

export const ItemsContainer = styled.div`
  display: flex;
  margin-left: 63px;
  flex-direction: row;
  margin-top: 12px;
`
