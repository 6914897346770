import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'
import { IGetAddressAsync, IPreviewAddressAsync } from '.'

export const getAddress = createAsyncThunk(
  'address/get',
  async ({ query, size = 10 }: IGetAddressAsync, { rejectWithValue }) => {
    try {
      console.log('getAddress::query::', query)
      const response = await api.get(`/address`, {
        params: {
          query,
          size,
        },
      })
      console.log('getAddress::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const previewAddressData = createAsyncThunk(
  'address/previewData',
  async (
    { address, addressId, googlePlaceId }: IPreviewAddressAsync,
    { rejectWithValue }
  ) => {
    try {
      const url = addressId
        ? `/address/preview?addressId=${addressId}&address=${address}`
        : `/address/preview?googlePlaceId=${googlePlaceId}`
      const response = await api.get(url)
      console.log('previewData response::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
