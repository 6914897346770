import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from 'venus/api'
import { ISpace } from 'venus/types/Space'
import { createItems, partialUpdateItem, updateItem } from '../item/item'

interface ISpaceReq {
  propertyId: string
  spaceId: string
  shareId?: string
  isShared?: boolean
  dreamPropertyId?: string
}

export const getSpace = createAsyncThunk(
  'space/getSpace',
  async ({ propertyId, spaceId, shareId, isShared, dreamPropertyId }: ISpaceReq) => {
    try {
      // const url = isShared
      //   ? `/property/${propertyId}/spaces/${spaceId}?shareId=${shareId}`
      //   : `/property/${propertyId}/spaces/${spaceId}`
      const response = await api.get(`/property/${propertyId}/spaces/${spaceId}`, {
        params: {
          shareId,
          dreamPropertyId,
        },
      })
      return response.data
    } catch (err) {
      const error: AxiosError = err

      if (!error.response) {
        throw err
      }

      return isRejectedWithValue(error.response.data)
    }
  },
)

const initialState = {
  error: null,
  space: undefined,
  loading: true,
} as {
  error: object | null | undefined
  space?: ISpace
  loading: boolean
}

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    resetSpaceStore: () => initialState,
    updateSpace: (state, action) => {
      state.space = { ...state.space, ...action.payload }
    },
    addItems: (state, action) => {
      if (!state.space) {
        return
      }
      state.space = {
        ...state.space,
        items: action.payload,
      }
    },
    updateItemInSpace: (state, action) => {
      const oldItem = state.space?.items.find((item) => item.id == action.payload.id)

      if (oldItem) {
        const items = state.space.items?.filter((item) => item.id !== action.payload.id)

        const newItem = { ...oldItem, ...action.payload }
        state.space = { ...state.space, items: [newItem, ...items] }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createItems.fulfilled, (state, action) => {
      if (!state.space) {
        return
      }
      state.space = {
        ...state.space,
        items: action.payload,
      }
    })
    builder.addCase(getSpace.pending, (state, action) => {
      state.loading = true
      state.space = undefined
    })
    builder.addCase(getSpace.fulfilled, (state, action) => {
      state.loading = false
      state.space = action.payload
    })
    builder.addCase(getSpace.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(updateItem, (state, action) => {
      if (!state.space) {
        return
      }
      const item = action.payload
      const items = state.space.items || []
      // update item sequence.

      const oldItem = items?.find((i) => i.id === item.id) || {}

      state.space = {
        ...state.space,
        // items: items?.map((i) => (i.id === item.id ? { ...i, ...item } : i))
        items: [item, ...items.filter((i) => i.id !== item?.id)],
      }
    })
    builder.addCase(partialUpdateItem, (state, action) => {
      if (!state.space) {
        return
      }
      const item = action.payload
      const items = state.space.items || []
      // update item sequence.

      if (item.id) {
        const oldItem = items?.find((i) => i.id === item.id) || {}

        state.space = {
          ...state.space,
          // items: items?.map((i) => (i.id === item.id ? { ...i, ...item } : i))
          items: [{ ...oldItem, ...item }, ...items.filter((i) => i.id !== item?.id)],
        }
      }
    })
  },
})

export const { updateSpace, addItems, updateItemInSpace, resetSpaceStore } =
  spaceSlice.actions

export default spaceSlice.reducer
