import { useState } from 'react'
import { Text, Button, Notification } from 'venus/components'
import { ReactComponent as SuccessIcon } from 'venus/assets/img/icons/Success.svg'

export const NotificationSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [showNotification, setShowNotification] = useState(false)
  const [showNotificationWithIcon, setNotificationWithIcon] = useState(false)

  const handleOpenNotification = () => {
    setShowNotification(true)
  }

  const handleCloseNotification = () => {
    setShowNotification(false)
  }

  const toggleIconNotification = () => {
    setNotificationWithIcon(!showNotificationWithIcon)
  }

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Button
        onClick={handleOpenNotification}
        type="secondary"
        text="Open Notification"
        style={{ width: 300 }}
      />
      <Button
        onClick={toggleIconNotification}
        type="secondary"
        text="Open Notification with Icon"
        style={{ width: 300, marginTop: '10px' }}
      />
      <Notification
        showAlert={showNotification}
        closeAlert={handleCloseNotification}
        message="This is place holder."
      />
      {/* sample with icon and longer text */}
      <Notification
        showAlert={showNotificationWithIcon}
        closeAlert={toggleIconNotification}
        message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget mauris pharetra et ultrices neque. Eget magna fermentum iaculis eu non diam phasellus. Viverra orci sagittis eu volutpat. Posuere morbi leo urna molestie at."
        icon={<SuccessIcon style={{ marginTop: '38px' }}/>}
      />
    </>
  )
}
