import { Button, EmptyData, Loader } from 'venus/components'
import ImageGrids from 'venus/pages/components/ImageGrids'
import { useEffect, useState, useMemo } from 'react'
import { setErrorTitle } from 'venus/redux/features/error/reducer'
import { Wrapper } from './PersonalItems.styles'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  getItems,
  addPersonalItems,
  setError,
} from 'venus/redux/features/personal/reducer'
import AddItem from 'venus/common/AddItem'
import { extractNumber, generateRandomCoverImage } from 'venus/utils'
import api from 'venus/api'

const PersonalItems = ({ setTabButtons }) => {
  const dispatch = useAppDispatch()
  const { items, loading } = useAppSelector((state) => state.personal)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigateAddItem = () => {
    setIsOpen(true)
  }

  const closeAddItem = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(setErrorTitle('Personal Vault Error'))
    dispatch(getItems())
    setTabButtons(
      <Button
        type="icon_button"
        text="Add Item"
        onClick={navigateAddItem}
        iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }}/>}
        style={{ width: 'auto' }}
      />
    )
  }, [])

  const onSubmit = async (data: any) => {
    setIsLoading(true)
    console.log('new item data', data)
    const formattedData = {
      ...data,
      value: extractNumber(data.value),
    }
    const formatData = data.images
      ? formattedData
      : { ...formattedData, coverImage: generateRandomCoverImage() }

    try {
      const response = await api.post(`/personal/item`, [formatData])
      const updated = response.data

      dispatch(addPersonalItems(updated))
      setIsLoading(false)
      closeAddItem()
    } catch (error) {
      setIsLoading(false)
      console.log('error in new item', error.data)
      if (error.data?.errors) {
        dispatch(setError(error.data.errors[0].msg))
      } else {
        dispatch(setError(error.data))
      }
    }
  }

  const grids = useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        image: item.coverImage,
        url: `/personal/item/${item.id}`,
        name: item.name,
      })),
    [items]
  )

  if (loading || isLoading) {
    return <Loader isFull/>
  }

  return (
    <Wrapper>
      {items.length === 0 ? (
        <EmptyData message="To add item, just click on the add button below."/>
      ) : (
        <ImageGrids grids={grids}/>
      )}
      <AddItem
        isOpen={isOpen}
        onClose={closeAddItem}
        template={null}
        onSubmit={onSubmit}
        isPersonal
      />
    </Wrapper>
  )
}

export default PersonalItems
