import styled from 'styled-components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { ReactComponent as ChevronRight } from 'venus/assets/img/icons/ChevronRight.svg'

const Container = styled.div`
  cursor: pointer;
  border-radius: 10px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.LightRed};

  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const MobilePayment = ({ type }) => {
  const href =
    type === 'APPLE'
      ? 'https://apps.apple.com/account/subscriptions'
      : `https://play.google.com/store/account/subscriptions?package=com.myhomevault.myhomevault`

  return (
    <a href={href} target='_blank' style={{ textDecoration: 'none' }}>
      <Container>
        <Text format='h4' color={theme.colors.ErrorState}>
          You can update and change your subscription terms on the MyHomeVault mobile app.
        </Text>
        <ChevronRight color={theme.colors.ErrorState} />
      </Container>
    </a>
  )
}

export default MobilePayment
