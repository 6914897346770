import { useEffect } from 'react'
import { Outlet, useParams, useSearchParams } from 'react-router-dom'
import { getSpace } from 'venus/redux/features/space/space'
import { useAppDispatch } from 'venus/redux/hooks'

const SpaceContainer = () => {
  const dispatch = useAppDispatch()
  const { propertyId, spaceId } = useParams()
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  useEffect(() => {
    dispatch(getSpace({ propertyId, spaceId, shareId, dreamPropertyId }))
  }, [propertyId, spaceId, shareId, dreamPropertyId])

  return <Outlet />
}

export default SpaceContainer
