import styled from 'styled-components'
import PremiumSign from './PremiumSign'

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.Grey};
`

const Header = styled.div`
  height: 38px;
  display: flex;

  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.Secondary};
`

const PremiumContainer = ({ children, style }) => {
  return (
    <Container style={style}>
      <Header>
        <PremiumSign />
      </Header>
      {children}
    </Container>
  )
}

export default PremiumContainer
