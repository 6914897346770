import React from 'react'
import { theme } from 'venus/config'
import { StyledTextInput, InputArea } from './Input.styles'
import { Text } from '..'
import _ from 'lodash'

interface IInputText {
  name?: string
  label?: string
  type?: 'text' | 'password'
  value: string
  onChange: (t: string) => void
  placeholder?: string
  style?: any
  inputStyle?: any
  isError?: boolean
  errorMsg?: string
  isDisabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  maxLength?: number
}

const Input = (
  {
    name,
    label,
    type = 'text',
    value,
    onChange,
    placeholder = '',
    style = {},
    inputStyle = {},
    isError = false,
    errorMsg,
    isDisabled = false,
    onFocus,
    onBlur,
    maxLength,
  }: IInputText,
  ref: any,
) => {
  const handleOnBlur = () => {
    onChange(_.trim(value))
  }

  return (
    <InputArea style={style}>
      <Text
        value={label || ''}
        format='h3'
        style={{
          textAlign: 'left',
          marginBottom: '6px',
          display: 'inline-block',
        }}
      />
      <StyledTextInput
        ref={ref}
        name={name}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlurCapture={handleOnBlur}
        isError={isError}
        disabled={isDisabled}
        style={{
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '21px',
          borderRadius: 4,
          ...inputStyle,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
      />
      {isError && (
        <Text
          value={String(errorMsg)}
          format='h4'
          lineHeight='21px'
          color={theme.colors.DangerRed}
          style={{
            marginTop: '6px',
            display: 'inline-block',
          }}
        />
      )}
    </InputArea>
  )
}

export default React.forwardRef(Input)
