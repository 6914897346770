import { Text, TabView } from "venus/components"

export const TabViewSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const tabs = [
    "All",
    "Tradespeople",
    "Real Estate Agent",
    "Property Manager",
    "Builder",
    "Other",
  ]

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <TabView defaultActiveIndex={0} tabs={tabs} paddingBottom={0}>
        <div>All</div>
        <div>Tradespeople</div>
        <div>Real Estate Agent</div>
        <div>Property Manager</div>
        <div>Builder</div>
        <div>Other</div>
      </TabView>
    </>
  )
}
