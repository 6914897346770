import styled from 'styled-components'

export const DropContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 195px;
  margin: 0 24px;
  border-radius: 5px;
  border: 2px dashed ${(props) => props.theme.colors.BlueGrey};
  background-color: ${(props) => props.theme.colors.LightBlue};
`
