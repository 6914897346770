import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IContact } from './types'
import {
  createContact,
  getContactList,
  deleteContact,
  updateContact,
} from './async'
import { getErrorMsg } from 'venus/utils'

const initialState: IContact = {
  entries: [],
  loading: false,
  error: '',
}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetContactStore: () => initialState,
    resetError: (state) => {
      state.error = ''
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    updateLocalContact: (state, action: PayloadAction<any>) => {
      const { payload } = action
      const { contactId } = payload
      let index = 0
      state.entries.forEach((c, i) => {
        const { id } = c
        if (id === contactId) index = i
      })
      state.entries = [
        ...state.entries.slice(0, index),
        payload,
        ...state.entries.slice(index + 1),
      ]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createContact.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createContact.fulfilled, (state, action) => {
      console.log('new contact":action.payload::', action.payload)
      state.entries = [...state.entries, action.payload]
      state.loading = false
    })
    builder.addCase(createContact.rejected, (state, action) => {
      state.loading = false
      const errors = getErrorMsg(action.payload)
      if (Array.isArray(errors)) {
        let message = ''
        errors.forEach(({ msg }) => {
          message += `${msg} `
        })
        state.error = message
      } else {
        state.error = errors
      }
    })
    builder.addCase(getContactList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getContactList.fulfilled, (state, action) => {
      console.log(
        'get contact list action.payload.length::',
        action.payload.length
      )
      state.entries = action.payload
      state.loading = false
    })
    builder.addCase(getContactList.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(updateContact.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(updateContact.rejected, (state, action) => {
      state.loading = false
      const errors = getErrorMsg(action.payload)

      if (Array.isArray(errors)) {
        let message = ''
        errors.forEach(({ msg }) => {
          message += `${msg} `
        })
        state.error = message
      } else {
        console.log('state error', errors)

        state.error = errors
      }
    })
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.entries = action.payload
      state.loading = false
    })
    builder.addCase(deleteContact.rejected, (state, action) => {
      const payload = action.payload as any
      state.error = payload.errors
      state.loading = false
    })
  },
})

export const { updateLocalContact, resetError, setError, resetContactStore } =
  contactSlice.actions

export default contactSlice.reducer
