interface IProfile {
  firstName: string
  lastName?: string
}

export const getFullName = ({ firstName, lastName }: IProfile) => {
  if (lastName) {
    return `${firstName} ${lastName}`
  }
  return firstName
}
