import styled from 'styled-components'
import { theme } from 'venus/config'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
`

export const CalendarView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 338px;
  align-self: center;
  margin-top: -12px;
  border-radius: 8px;
`

export const CalendarBackground = styled.div`
  background-color: ${theme.colors.White};
  border-radius: 8px;
`

export const CalendarContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: transparent;
`

export const StyledDate = styled.div`
  display: flex;
  border-color: lightgray;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledDateWrapper = styled.div`
  display: flex;
  width: 14%;
  height: 36px;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${theme.colors.LightBlue};
  }
`

export const StyledDateTitle = styled.div`
  display: flex;
  border-color: lightgray;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const InputWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 38px;
  right: 19px;
`

export const StyledBlankGrid = styled.div`
  width: 30px;
  height: 30px;
  margin: 3px 0;
`

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`

export const DropdownArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 6px;
  background: white;
`

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ButtonText = styled.div`
  cursor: pointer;
`

export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`

export const ContentArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3px 12px 12px 12px;
  height: 338px;
`

export const ChevronWrapper = styled.div`
  cursor: pointer;
`

export const WeekdayWrapper = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DaysWrapper = styled(WeekdayWrapper)`
  height: 275px;
  flex-wrap: wrap;
`
