import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { theme, FREE } from 'venus/config'
import { formateDate } from 'venus/utils'
// import MHVPremium from 'mhv/scenes/Transfer/MHVPremium'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  createReminder,
  getReminderList,
  resetError,
} from 'venus/redux/features/reminder'
import {
  Input,
  Text,
  DatePicker,
  InputMultipleLine,
  AlertBox,
  Modal,
  ActionLayout,
} from 'venus/components'
import { ContentWrapper, FieldWrapper } from './Create.styles'
// import useShowPremium from 'mhv/hooks/useShowPremium'

type PropertyFormData = {
  notes: string
  dueDateTime: string
  eventName: string
}

const getDefaultValue = () => {
  return {
    defaultValues: {
      notes: '',
      dueDateTime: formateDate(''),
      eventName: '',
    },
  }
}

interface ISelect {
  isOpen: boolean
  onClose: () => void
}

export const Create = ({ isOpen, onClose }: ISelect) => {
  const { loading, error } = useAppSelector((state) => state.reminder)
  const [isCreated, setIsCreated] = useState(false)
  const buttonText = isCreated ? 'Reminder Created.' : 'Save'
  const dispatch = useAppDispatch()
  // const { vaults } = useAppSelector((state) => state.subscription)
  // const subscriptionStatus = vaults[property?.id]
  // const { isShown, onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<PropertyFormData>(getDefaultValue())

  const { propertyId, spaceId, itemId } = useParams() || {}
  const { property } = useAppSelector((state) => state.property)

  if (!property) return null

  const { propertyWideSpaceId } = property
  const type = !!itemId
    ? 'item'
    : !!spaceId && spaceId !== propertyWideSpaceId
    ? 'space'
    : 'property'

  const onSubmit = async (data: PropertyFormData) => {
    const today = formateDate(null)
    const body: any = {
      dueDateTime: data.dueDateTime.trim(),
      eventName: data.eventName.trim(),
      notes: data.notes.trim(),
      spaceId: propertyWideSpaceId,
      status: data.dueDateTime.trim() === today ? 'DUE' : 'UPCOMING',
    }
    if (type === 'space') body.spaceId = spaceId
    if (type === 'item') {
      body.spaceId = spaceId
      body.itemId = itemId
    }
    console.log('body::', body)
    const result = await dispatch(
      createReminder({
        propertyId,
        body,
      })
    )
    await dispatch(getReminderList(propertyId))
    console.log('createReminder result::', result.meta.requestStatus)
    if (result.meta.requestStatus === 'fulfilled') {
//      setIsCreated(true)
//      setTimeout(() => handleClose(), 1000)
        handleClose()
    }
  }

  const handlPressAlertOK = () => {
    handleClose()
  }

  const handleClose = () => {
    setIsCreated(false)
    reset()
    onClose()
  }

  const handlPressCloseError = () => {
    dispatch(resetError())
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title='Create Reminder'>
      <ActionLayout
        leftActions={[]}
        rightActions={[
          {
            type: 'primary',
            isLoading: loading,
            text: buttonText,
            onClick: handleSubmit(onSubmit),
            isDisabled: isCreated,
          },
        ]}
      >
        <>
          <ContentWrapper>
            <Controller
              control={control}
              rules={{
                required: true,
                maxLength: 30,
                validate: (v) => v === v.trim(),
              }}
              render={({ field: { onChange, value } }) => (
                <FieldWrapper>
                  <Input
                    label='Reminder Name'
                    value={value}
                    onChange={onChange}
                    style={{ width: '100%' }}
                    isError={!!errors.eventName}
                    errorMsg={
                      errors?.eventName?.type === 'maxLength'
                        ? 'Maximum 30 characters.'
                        : !!value && value !== value?.trim()
                        ? 'Please remove leading or trailing spaces.'
                        : 'This is required.'
                    }
                    isDisabled={isCreated}
                  />
                </FieldWrapper>
              )}
              name='eventName'
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <FieldWrapper>
                  <DatePicker
                    onChange={onChange}
                    value={value}
                    disablePastDate
                    isDisabled={isCreated}
                  />
                </FieldWrapper>
              )}
              name='dueDateTime'
            />
            {(errors.dueDateTime ||
              getValues('dueDateTime') === 'dd/mm/yyyy') && (
              <Text
                value='This is required.'
                color={theme.colors.ErrorState}
                format='h3'
                style={{ marginTop: 28, marginBottom: -28 }}
              />
            )}
            <Controller
              rules={{
                maxLength: 1024,
              }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputMultipleLine
                  label='Notes (optional)'
                  value={value}
                  onChange={onChange}
                  isDisabled={isCreated}
                  isError={!!errors.notes}
                  errorMsg={
                    errors?.notes?.type === 'maxLength'
                      ? 'Maximum 1024 characters.'
                      : ''
                  }
                />
              )}
              name='notes'
            />
          </ContentWrapper>
          <AlertBox
            cancel={handlPressAlertOK}
            title={`Reminder Error`}
            message={error}
            btnGroup={[
              {
                text: 'OK',
                onClick: handlPressCloseError,
                color: 'Primary',
                type: 'error',
              },
            ]}
            showAlert={!!error}
          />
          {/* <MHVPremium
      isOpen={isShown}
      onViewPlan={onViewPlan}
      onCancel={onCancel}
    /> */}
        </>
      </ActionLayout>
    </Modal>
  )
}
