import { Text } from 'venus/components';
import {
  Container,
  IconWrapper,
  MenuItem,
  MenuLink,
} from './ProfileMenu.styles';
import { ReactComponent as SettingsIcon } from 'venus/assets/img/icons/Settings.svg';
import { ReactComponent as Apps } from 'venus/assets/img/icons/Apps.svg';
import { ReactComponent as Logout } from 'venus/assets/img/icons/Logout.svg';
import React from "react";

interface IProfileMenu {
  onClickSettings: () => void;
}

const Menu = ({ icon, label, url, onClick }: any) => {
  return (
    <MenuItem>
      <MenuLink
        to={url}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault();
            onClick();
          }
        }}
      >
        <IconWrapper>{icon}</IconWrapper>
        <Text format="body" fontSize={14}>
          {label}
        </Text>
      </MenuLink>
    </MenuItem>
  );
};

const ProfileMenu = ({
  onClickSettings,
}: IProfileMenu) => {
  const MENU = [
    {
      label: 'Settings',
      icon: <SettingsIcon/>,
      url: '',
      onClick: onClickSettings,
    },
    { label: 'Download App', icon: <Apps/>, url: '/download' },
    { label: 'Log out', icon: <Logout/>, url: '/logout' },
  ];

  return (
    <Container>
      {MENU.map((menu) => (
        <Menu key={menu.label} {...menu} />
      ))}
    </Container>
  );
};

export default ProfileMenu;
