import { createSlice } from '@reduxjs/toolkit'
import { IDocs } from './types'
import {
  getUploadUrl,
  uploadDoc,
  createDocInfo,
  deletePsiDocs,
  deletePersonalDocs,
  deletePersonalItemDocs,
  moveDocs,
  getPersonalDocs,
  createPersonalDocInfo,
  createPersonalItemDocInfo,
  editPersonalDocs,
  editPersonalItemDocs,
  getPSIDocs,
  getPersonalItemDocs,
} from './async'
import { getErrorMsg } from 'venus/utils'

const initialState: IDocs = {
  loading: false,
  isError: false,
  errorMsg: '',
  docs: [],
  personalDocs: [],
  personalItemDocs: [],
}

export const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {
    resetDocsStore: () => initialState,
    resetError: (state) => {
      state.isError = false
      state.errorMsg = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUploadUrl.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUploadUrl.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(getUploadUrl.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(uploadDoc.pending, (state) => {
      state.loading = true
    })
    builder.addCase(uploadDoc.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(uploadDoc.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(createDocInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createDocInfo.fulfilled, (state, action) => {
      state.docs = action.payload
      state.loading = false
    })
    builder.addCase(createDocInfo.rejected, (state, action) => {
      state.loading = false
      state.isError = true
      console.log(
        'createDocInfo getErrorMsg(action.payload)::',
        getErrorMsg(action.payload),
        action.payload
      )
      const obj = action.payload as any
      state.errorMsg = getErrorMsg(obj.code)
    })
    builder.addCase(deletePsiDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deletePsiDocs.fulfilled, (state, action) => {
      state.docs = action.payload
      state.loading = false
    })
    builder.addCase(deletePsiDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(deletePersonalDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deletePersonalDocs.fulfilled, (state, action) => {
      state.personalDocs = action.payload
      state.loading = false
    })
    builder.addCase(deletePersonalDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(deletePersonalItemDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deletePersonalItemDocs.fulfilled, (state, action) => {
      state.personalItemDocs = action.payload
      state.loading = false
    })
    builder.addCase(deletePersonalItemDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(moveDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(moveDocs.fulfilled, (state, action) => {
      state.docs = action.payload
      state.loading = false
    })
    builder.addCase(moveDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(getPersonalDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPersonalDocs.fulfilled, (state, action) => {
      state.personalDocs = action.payload
      state.loading = false
    })
    builder.addCase(getPersonalDocs.rejected, (state, action) => {
      state.loading = false
      state.isError = true
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(createPersonalDocInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createPersonalDocInfo.fulfilled, (state, action) => {
      state.personalDocs = action.payload
      state.loading = false
    })
    builder.addCase(createPersonalDocInfo.rejected, (state, action) => {
      state.loading = false
      state.isError = true
      const obj = action.payload as any
      state.errorMsg = getErrorMsg(obj.code)
    })
    builder.addCase(createPersonalItemDocInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createPersonalItemDocInfo.fulfilled, (state, action) => {
      state.personalItemDocs = action.payload
      state.loading = false
    })
    builder.addCase(createPersonalItemDocInfo.rejected, (state, action) => {
      state.loading = false
      state.isError = true
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(editPersonalDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(editPersonalDocs.fulfilled, (state, action) => {
      state.personalDocs = action.payload
      state.loading = false
    })
    builder.addCase(editPersonalDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(editPersonalItemDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(editPersonalItemDocs.fulfilled, (state, action) => {
      state.personalItemDocs = action.payload
      state.loading = false
    })
    builder.addCase(editPersonalItemDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(getPSIDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPSIDocs.fulfilled, (state, action) => {
      state.docs = action.payload
      state.loading = false
    })
    builder.addCase(getPSIDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
    builder.addCase(getPersonalItemDocs.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPersonalItemDocs.fulfilled, (state, action) => {
      state.personalItemDocs = action.payload
      state.loading = false
    })
    builder.addCase(getPersonalItemDocs.rejected, (state, action) => {
      state.loading = false
      state.errorMsg = getErrorMsg(action.payload)
    })
  },
})

export const { resetError, resetDocsStore } = docsSlice.actions

export default docsSlice.reducer
