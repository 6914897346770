import { useState } from 'react'
import { TSpace } from 'venus/redux/features/onboarding'
import { createSpace } from 'venus/redux/features/property/property'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { theme } from 'venus/config'
import { BtnArea, ChecksArea, CheckBoxContent } from './HomeLayout.styles'
import { Checkbox, Button, Text, LinkButton } from 'venus/components'
import { ReactComponent as Plus } from 'venus/assets/img/icons/Add.svg'

interface IHomeLayout {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  spaces: TSpace[]
  setSpaces: (s: TSpace[]) => void
}

export const HomeLayout = ({
  setStep,
  setTitle,
  spaces,
  setSpaces,
}: IHomeLayout) => {
  const dispatch = useAppDispatch()
  const { propertyId, loading } = useAppSelector((state) => state.property)
  const [isDisabled, setIsDisabled] = useState(false)
  console.log('propertyId::', propertyId)

  const handlePressAdd = () => {
    setStep(2)
    setTitle('Add Space')
  }

  const handlePressSave = async () => {
    setIsDisabled(true)
    const checkedSpaces = spaces
      .filter(({ checked }) => checked === true)
      .map(({ name, type }) => ({ name, type }))
    const data = await dispatch(
      createSpace({ propertyId, spaces: checkedSpaces })
    )
    if (data.meta.requestStatus === 'fulfilled') {
      setStep(3)
      setTitle('Add Items')
    }
  }

  const handleClickCheck = (name: string) => {
    for (let i = 0, l = spaces.length; i < l; i++) {
      const space = spaces[i]
      if (name === space.name) {
        spaces[i].checked = !spaces[i].checked
      }
    }
    setSpaces([...spaces])
  }

  return (
    <>
      <Text
        format='body'
        color={theme.colors.Black}
        value='Select and add spaces to your Vault'
      />
      <ChecksArea>
        {spaces.map((item: TSpace, index) => {
          const { name, checked } = item
          return (
            <CheckBoxContent key={`space_${name}_${index}`}>
              <Checkbox
                checked={!!checked}
                onChange={() => handleClickCheck(name)}
              />
              <Text
                value={name}
                color={theme.colors.Black}
                format='body'
                style={{ flex: 1, alignSelf: 'center', marginLeft: 12 }}
              />
            </CheckBoxContent>
          )
        })}
      </ChecksArea>
      <BtnArea>
        <LinkButton
          handlePress={handlePressAdd}
          label='Add Space'
          format='body'
          color={theme.colors.Primary}
          leftIcon={<Plus color={theme.colors.Secondary} />}
          style={{
            marginBottom: 24,
            width: 120,
            height: 24,
          }}
        />
        <Button
          style={{ height: '36px', padding: '4px 28px', width: 'auto' }}
          onClick={handlePressSave}
          text='Save Spaces'
          isLoading={loading}
          isDisabled={
            isDisabled ||
            spaces.filter(({ checked }) => checked === true).length === 0
          }
        />
      </BtnArea>
    </>
  )
}
