import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { TabView, ActionLayout } from 'venus/components'
import CompanyProfileForm from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Company'
import ProfileForm from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal'
import { updateProfileSteps } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal/Constants'
import { Header } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/ProfileTabMenu.styles'
import { useAppSelector } from 'venus/redux/hooks'
import SettingsHeader from '../SettingsHeader/SettingsHeader'

const ProfileTabMenu = () => {
  const tabs = ['Personal', 'Company']
  const [actions, setActions] = useState([])
  const [step, setStep] = useState(updateProfileSteps.GET_FORM)
  const { info } = useAppSelector((state) => state.user)

  useEffect(() => {
    handleTabChange()
  }, [])

  const handleTabChange = () => {
    setStep(updateProfileSteps.GET_FORM)
  }
  const isEdit = step !== updateProfileSteps.GET_FORM

  let childrenComponent = null
  if (_.get(info, 'role[0]', '') === 'OWNER') {
    childrenComponent = (
      <ProfileForm step={step} setStep={setStep} setActions={setActions} />
    )
  } else {
    childrenComponent = (
      <TabView
        isWrap={isEdit}
        defaultActiveIndex={0}
        tabs={tabs}
        paddingBottom={0}
        headerStyle={{
          display: step !== updateProfileSteps.GET_FORM ? 'none' : 'flex',
          borderBottomWidth: step !== updateProfileSteps.GET_FORM ? 0 : 1,
        }}
        style={{
          overflowY: 'auto',
          minHeight: 0,
          marginBottom: 0,
        }}
        onChange={handleTabChange}
      >
        <ProfileForm step={step} setStep={setStep} setActions={setActions} />
        <CompanyProfileForm step={step} setStep={setStep} setActions={setActions} />
      </TabView>
    )
  }

  // if (step === updateProfileSteps.GET_FORM) {
  //   return (
  //     <Header>
  //       <SettingsHeader title={'Profile'} />
  //     </Header>
  //   )
  // }

  return (
    <>
      <Header>
        <SettingsHeader
          title={isEdit ? 'Edit Profile' : 'Profile'}
          onBackBtnClick={isEdit ? handleTabChange : null}
        />
      </Header>
      <ActionLayout margin={28} rightActions={actions}>
        {childrenComponent}
      </ActionLayout>
    </>
  )
}

export default ProfileTabMenu
