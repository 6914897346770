import styled from "styled-components"

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  cursor: pointer;
`
