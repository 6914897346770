import styled from 'styled-components'
import { theme } from 'venus/config'

type StyledTextInputProps = {
  readonly isError: boolean
}

export const StyledTextInput = styled.input<StyledTextInputProps>`
  height: 44px;
  padding: 12px 20px;
  text-align: left;
  border: ${(props) =>
    `0.5px solid ${
      props.isError ? theme.colors.DangerRed : theme.colors.Grey
    }`};
  color: ${theme.colors.Black};
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  &::placeholder {
    color: ${theme.colors.DarkestGrey};
  }
  &:hover {
    border-color: ${(props) =>
      props.isError ? theme.colors.DangerRed : theme.colors.Secondary};
  }
`

export const InputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
