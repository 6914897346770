import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'venus/redux/store'
import { getFullImages } from 'venus/utils'
import {
  IOnboarding,
  IConfirmHomeLayoutDone,
  IConfirmEditImageDone,
  IConfirmUploadDocsDone,
} from './types'
import { getItemTemplate, bulkCreateItemsInSpaces } from './asyncItem'

const initialState: IOnboarding = {
  loading: false,
  isConfirmHomeLayoutDone: false,
  isConfirmEditImageDone: false,
  isConfirmUploadDocsDone: false,
  isUploadNonNpdImagesDone: false,
  isScanHomeDone: false,
  addressId: 0,
  spaces: [],
  homeItems: [],
  homeImages: [],
  homeDocs: [],
  error: '',
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.error = payload
    },
    resetOnboardingStore: () => initialState,
    addAddressId: (state, action: PayloadAction<number>) => {
      const { payload } = action
      state.addressId = payload
    },
    setConfirmHomeLayoutDone: (state, action: PayloadAction<IConfirmHomeLayoutDone>) => {
      const { payload } = action
      const { isConfirmHomeLayoutDone } = payload
      state.isConfirmHomeLayoutDone = isConfirmHomeLayoutDone
    },
    setUploadNonNpdImagesDone: (state, action: PayloadAction<boolean>) => {
      const { payload } = action
      state.isUploadNonNpdImagesDone = payload
    },
    setConfirmEditImageDone: (state, action: PayloadAction<IConfirmEditImageDone>) => {
      const { payload } = action
      const { isConfirmEditImageDone } = payload
      state.isConfirmEditImageDone = isConfirmEditImageDone
    },
    setScanHomeDone: (state, action) => {
      state.isScanHomeDone = action.payload
    },
    setConfirmUploadDocsDone: (state, action: PayloadAction<IConfirmUploadDocsDone>) => {
      const { payload } = action
      const { isConfirmUploadDocsDone } = payload
      state.isConfirmUploadDocsDone = isConfirmUploadDocsDone
    },
    markSpaceAsChecked: (state, action: PayloadAction<{ name: string }>) => {
      const { payload } = action
      const { name } = payload
      for (let i = 0, l = state.spaces.length; i < l; i++) {
        const space = state.spaces[i]
        if (name === space.name) {
          state.spaces[i].checked = !state.spaces[i].checked
        }
      }
    },
    createHomeImages: (state, action) => {
      const { imageBaseUrl, imageCount, image } = action.payload
      const homeImages = imageBaseUrl ? getFullImages(imageBaseUrl, imageCount) : [image]
      state.homeImages = homeImages.map((url) => ({
        url,
        isArchived: false,
        type: '',
        name: '',
      }))
    },
    createHomeImagesForNonNpdProperty: (
      state,
      action: PayloadAction<{ url: string }[]>,
    ) => {
      const { payload } = action
      state.homeImages = payload.map(({ url }) => ({
        url,
        isArchived: false,
        type: '',
        name: '',
      }))
    },
    // createSpace: (
    //   state,
    //   action: PayloadAction<{ label: string; type: string; checked: boolean }[]>
    // ) => {
    //   const { payload } = action
    //   state.spaces = payload
    // },
    addSpace: (state, action: PayloadAction<{ name: string; type: string }>) => {
      const { payload } = action
      const { name, type } = payload
      state.spaces.push({
        type,
        name,
        checked: true,
      })
    },
    addDoc: (
      state,
      action: PayloadAction<{
        uri: string[]
        filename: string[]
        contentType: string[]
      }>,
    ) => {
      const { payload } = action
      const { filename, uri, contentType } = payload
      const docs = uri.map((v, index) => ({
        filename: filename[index],
        uri: v,
        contentType: contentType[index],
        item: '',
        space: '',
        spaceType: '',
      }))
      state.homeDocs = [...state.homeDocs, ...docs]
    },

    editDocs: (state, action) => {
      const { payload } = action
      state.homeDocs = payload
    },
    resetOnboardingData: (state) => {
      state.homeDocs = []
      state.isConfirmHomeLayoutDone = false
      state.isConfirmEditImageDone = false
      state.isConfirmUploadDocsDone = false
      state.isUploadNonNpdImagesDone = false
      state.isScanHomeDone = false
    },
    editItem: (state, action: PayloadAction<{ spaceLabel: string; itemName: string }>) => {
      const { payload } = action
      const { spaceLabel, itemName: currentItemName } = payload
      for (let i = 0, l = state.homeItems.length; i < l; i++) {
        const { name: spaceName, itemTemplate } = state.homeItems[i]
        if (spaceLabel === spaceName) {
          for (let p = 0, q = itemTemplate.length; p < q; p++) {
            const { name: itemName } = itemTemplate[p]
            if (itemName === currentItemName) {
              const { checked } = state.homeItems[i].itemTemplate[p]
              state.homeItems[i].itemTemplate[p].checked = !checked
            }
          }
        }
      }
    },
    editImage: (
      state,
      action: PayloadAction<{
        url: string
        isArchived: boolean
      }>,
    ) => {
      const { payload } = action
      const { url, isArchived } = payload
      for (let i = 0, l = state.homeImages.length; i < l; i++) {
        const { url: thisUrl } = state.homeImages[i]
        if (url === thisUrl) state.homeImages[i].isArchived = isArchived
      }
    },
    checkDoc: (state, action) => {
      const uri = action.payload
      for (let i = 0, l = state.homeDocs.length; i < l; i++) {
        const { uri: thisUrl } = state.homeDocs[i]
        if (uri === thisUrl) state.homeDocs[i].checked = !state.homeDocs[i].checked
      }
    },
    batchEditDoc: (
      state,
      action: PayloadAction<
        {
          checked?: boolean
          filename: string
          item: string
          space: string
          spaceType: string
          uri: string
        }[]
      >,
    ) => {
      const { payload } = action
      payload.forEach((p) => {
        const { uri } = p
        for (let i = 0, l = state.homeDocs.length; i < l; i++) {
          const { uri: docUri } = state.homeDocs[i]
          if (uri === docUri) {
            state.homeDocs[i].isUploaded = true
          }
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(bulkCreateItemsInSpaces.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(bulkCreateItemsInSpaces.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(bulkCreateItemsInSpaces.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(getItemTemplate.fulfilled, (state, action) => {
      state.homeItems = action.payload
    })
  },
})

export const {
  setConfirmHomeLayoutDone,
  setUploadNonNpdImagesDone,
  setConfirmEditImageDone,
  setConfirmUploadDocsDone,
  setScanHomeDone,
  markSpaceAsChecked,
  addSpace,
  editItem,
  addDoc,
  editDocs,
  editImage,
  checkDoc,
  batchEditDoc,
  createHomeImages,
  createHomeImagesForNonNpdProperty,
  addAddressId,
  resetOnboardingData,
  resetOnboardingStore,
  setError,
} = onboardingSlice.actions

export const isConfirmHomeLayoutDone = (state: RootState) =>
  state.onboarding.isConfirmHomeLayoutDone
export const isConfirmEditImageDone = (state: RootState) =>
  state.onboarding.isConfirmEditImageDone
export const isConfirmUploadDocsDone = (state: RootState) =>
  state.onboarding.isConfirmUploadDocsDone
export const spaces = (state: RootState) => state.onboarding.spaces
export const homeItems = (state: RootState) => state.onboarding.homeItems
export const homeImages = (state: RootState) => state.onboarding.homeImages
export const homeDocs = (state: RootState) => state.onboarding.homeDocs

export default onboardingSlice.reducer
