import styled from 'styled-components'

export const DetailSection = styled.div`
  padding-top: 8px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${(props) => props.theme.colors.LightGrey};

  display: flex;
  flex-direction: column;
`

export const DetailSections = styled.div`
  padding-top: 8px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${(props) => props.theme.colors.LightGrey};

  display: flex;
`

export const DetailGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
