import { useCallback, useEffect, useState } from 'react'
import { Loader, Button, Modal, AlertBox } from 'venus/components'
import { AddProperty } from 'venus/common/AddProperty/AddProperty'
import { getProperties } from 'venus/redux/features/property/properties'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { Onboarding } from 'venus/pages/Onboarding'
import { Container } from './MyProperties.styles'
import PropertyList from '../PropertyList'
import { getSubscription } from 'venus/redux/features/subscription/subscription'
import usePremiumContext from 'venus/context/MHVPremium'

const MyProperties = ({ setTabButtons, isOpenAddProperty = false }) => {
  const dispatch = useAppDispatch()
  const { loading, error, properties } = useAppSelector((state) => state.properties)
  const [isAddPropertyOpen, setIsAddPropertyOpen] = useState(isOpenAddProperty)
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)
  const { vaults, subscribed, unsubscribed } = useAppSelector((state) => state.subscription)
  const { openPremium } = usePremiumContext()

  const [showUpgrade, setShowUpgrade] = useState(false)

  const handleNavigate = () => {
    setShowUpgrade(false)
    openPremium()
  }

  const handleCloseAddHome = () => {
    setIsAddPropertyOpen(false)
  }

  const handleOnFinishAddHome = () => {
    setIsAddPropertyOpen(false)
    setIsOnboardingOpen(true)
  }

  const handleCloseOnboarding = () => {
    setIsOnboardingOpen(false)
  }

  useEffect(() => {
    dispatch(getProperties())
    dispatch(getSubscription())
  }, [])

  useEffect(() => {
    if (!loading) {
      setTabButtons(
        <Button
          type='icon_button'
          text='Add Property'
          onClick={() => {
            if (properties.length > 2 && !subscribed.length) {
              setShowUpgrade(true)

              return
            } else {
              setIsAddPropertyOpen(true)
            }
          }}
          iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
          style={{ width: 'auto' }}
        />,
      )

      return () => setTabButtons(<></>)
    }
  }, [loading, properties, subscribed])

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  return (
    <Container>
      <PropertyList
        type='property'
        properties={properties}
        vaults={vaults}
        emptyMessage='Click the add property button to add properties to your Vault.'
      />
      <AddProperty
        isOpen={isAddPropertyOpen}
        onClose={handleCloseAddHome}
        onFinish={handleOnFinishAddHome}
      />
      <Onboarding isOpen={isOnboardingOpen} onClose={handleCloseOnboarding} />
      <AlertBox
        title='Free Property Limit'
        message='You have reached the maximum number of properties for free user. Do you want to upgrade to premium plan to add more properties?'
        btnGroup={[
          { text: 'Upgrade', onClick: handleNavigate, type: 'primary' },
          {
            text: 'Cancel',
            onClick: () => setShowUpgrade(false),
            type: 'secondary',
          },
        ]}
        showAlert={showUpgrade}
        cancel={() => setShowUpgrade(false)}
      />
    </Container>
  )
}

export default MyProperties
