import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { deleteShareItem } from 'venus/redux/features/share'
import { theme } from 'venus/config'
import { Text, Avatar, AlertBox } from 'venus/components'
import { setHistory } from 'venus/redux/features/history/history'
import { TitleWrapper, ShareScopeArea } from './Details.styles'
import { DETAIL_WHO_ACCESS_TITLE } from 'venus/pages/Share/Constants'
import { TitleTextWrapper } from './Details.styles'
import { ValueTextWrapper } from './Details.styles'
import { DETAIL_REMOVE_ALERT_MSG } from 'venus/pages/Share/Constants'
import { DETAIL_REMOVE_ALERT_TITLE } from 'venus/pages/Share/Constants'
import { DETAIL_HISTORY_CANCEL_BTN_TEXT } from 'venus/pages/Share/Constants'
import { DETAIL_HISTORY_CONFIRM_BTN_TEXT } from 'venus/pages/Share/Constants'
import { SHARE_ENTIRE_PROPERTY_TEXT } from 'venus/pages/Share/Constants'
import { useParams } from 'react-router-dom'

interface IDetails {
  index: number
  handleClose: () => void
  handleOpenHistory: () => void
  removeAccess: boolean
  closeRemoveAccess: () => void
}

export const Details = ({
  index,
  handleClose,
  handleOpenHistory,
  removeAccess,
  closeRemoveAccess,
}: IDetails) => {
  const dispatch = useAppDispatch()
  const { property } = useAppSelector((state) => state.property)
  const { shareList } = useAppSelector((state) => state.share)

  const { propertyId } = useParams()

  if (!shareList[index]) return null
  const { contactId, shareScope, isShareWholeProperty, eventName, id, notes, contact } =
    shareList[index]

  const avatar = contact?.profile?.avatar

  if (!shareScope.value) return null
  const sharedSpaces = shareScope?.value.map(({ spaceName }) => spaceName)
  const sharedSpaceIds = shareScope?.value.map(({ spaceId }) => spaceId)
  const sharedItemArr = shareScope?.value.map(({ items }) =>
    (items || []).map(({ itemName }) => itemName),
  )
  const sharedItemIdArr = shareScope?.value.map(({ items }) =>
    (items || []).map(({ itemId }) => itemId),
  )
  const sharedItems: string[] = []
  const sharedItemIds: string[] = []
  sharedItemArr.forEach((arr) => {
    arr.forEach((s) => {
      if (sharedItems.indexOf(s) === -1) sharedItems.push(s)
    })
  })
  sharedItemIdArr.forEach((value) => sharedItemIds.push(...value))
  const spaceIds: string[] = []
  const itemIds: string[] = []
  sharedSpaceIds.forEach((id) => {
    if (spaceIds.indexOf(id) === -1) spaceIds.push(id)
  })
  sharedItemIds.forEach((id) => {
    if (itemIds.indexOf(id) === -1) itemIds.push(id)
  })
  const spaceId =
    spaceIds.length === 1 && !isShareWholeProperty
      ? spaceIds[0]
      : property?.propertyWideSpaceId
  const itemId = itemIds.length === 1 ? itemIds[0] : ''

  const handlPressOKNoHistory = async () => {
    await dispatch(deleteShareItem({ propertyId: property?.id || propertyId, shareId: id }))
    handleClose()
    closeRemoveAccess()
  }

  const handlPressOKAddHistory = () => {
    handleClose()
    closeRemoveAccess()
    const tags = isShareWholeProperty
      ? [SHARE_ENTIRE_PROPERTY_TEXT]
      : [
          ...sharedSpaces
            .filter((value) => !!value && typeof value === 'string')
            .map((value) => value),
          ...sharedItems
            .filter((value) => !!value && typeof value === 'string')
            .map((value) => value),
        ]
    dispatch(
      setHistory({
        id: null,
        propertyId: property?.id,
        spaceId,
        itemId,
        contacts: [],
        eventName,
        eventDate: null,
        rating: {},
        tags,
        notes,
      }),
    )
    handleOpenHistory()
  }

  return (
    <>
      <ShareScopeArea>
        {isShareWholeProperty && (
          <TitleWrapper>
            <Text
              value={SHARE_ENTIRE_PROPERTY_TEXT}
              format='h3'
              color={theme.colors.White}
              style={{
                textTransform: 'capitalize',
              }}
            />
          </TitleWrapper>
        )}
        {!isShareWholeProperty &&
          sharedSpaces
            .filter((value) => !!value && typeof value === 'string')
            .map((value: string, index: number) => (
              <TitleWrapper key={`sharedSpaces_${index}_${id}`}>
                <Text
                  value={value}
                  format='h4'
                  color={theme.colors.White}
                  style={{
                    textTransform: 'capitalize',
                  }}
                />
              </TitleWrapper>
            ))}
        {!isShareWholeProperty &&
          sharedItems
            .filter((value) => !!value && typeof value === 'string')
            .map((value: string, index: number) => (
              <TitleWrapper
                key={`sharedItems_${index}_${id}`}
                style={{ backgroundColor: theme.colors.LightBlue }}
              >
                <Text
                  value={value}
                  format='h4'
                  color={theme.colors.Primary}
                  style={{
                    textTransform: 'capitalize',
                  }}
                />
              </TitleWrapper>
            ))}
      </ShareScopeArea>
      <TitleTextWrapper>
        <Text
          value={DETAIL_WHO_ACCESS_TITLE}
          color={theme.colors.DarkestGrey}
          format='h3'
          fontSize={16}
          fontWeight={500}
        />
      </TitleTextWrapper>
      {!contactId && (
        <ValueTextWrapper>
          <Text
            value='Anyone'
            color={theme.colors.Black}
            format='h3'
            fontSize={16}
            fontWeight={500}
          />
        </ValueTextWrapper>
      )}
      {contactId && (
        <div style={{ alignSelf: 'flex-start', marginLeft: 24 }}>
          <Avatar size={36} avatar={avatar} isBorder={false} />
        </div>
      )}
      <TitleTextWrapper>
        <Text
          value='Event Name'
          color={theme.colors.DarkestGrey}
          format='h3'
          fontSize={16}
          fontWeight={500}
        />
      </TitleTextWrapper>
      <ValueTextWrapper>
        <Text
          value={eventName}
          color={theme.colors.Black}
          format='h3'
          fontSize={16}
          fontWeight={500}
        />
      </ValueTextWrapper>
      <TitleTextWrapper>
        <Text
          value='Notes'
          color={theme.colors.DarkestGrey}
          format='h3'
          fontSize={16}
          fontWeight={500}
        />
      </TitleTextWrapper>
      <ValueTextWrapper>
        <Text
          value={notes}
          color={theme.colors.Black}
          format='h3'
          fontSize={16}
          fontWeight={500}
        />
      </ValueTextWrapper>
      <AlertBox
        showAlert={removeAccess}
        cancel={closeRemoveAccess}
        title={DETAIL_REMOVE_ALERT_TITLE}
        message={DETAIL_REMOVE_ALERT_MSG}
        btnGroup={[
          {
            text: DETAIL_HISTORY_CANCEL_BTN_TEXT,
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: handlPressOKNoHistory,
            type: 'secondary',
          },
          {
            text: DETAIL_HISTORY_CONFIRM_BTN_TEXT,
            onClick: handlPressOKAddHistory,
            color: 'Primary',
            type: 'primary',
          },
        ]}
      />
    </>
  )
}
