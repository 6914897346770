import styled from 'styled-components'
import ImageGrid from './ImageGrid'

const GridContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: 14px;
`

export interface IGrid {
  id?: string
  name: string
  image?: string
  url?: string
  defaultType?: string
  onClick?: (grid: any) => void
}

const ImageGrids = ({
  grids,
  onClick,
}: {
  grids: IGrid[]
  onClick?: (grid: IGrid) => void
}) => {
  const handleClick = (grid) => {
    onClick(grid)
  }

  return (
    <GridContainer>
      {grids.map((grid) => (
        <ImageGrid key={grid.id} {...grid} onClick={() => handleClick(grid)} />
      ))}
    </GridContainer>
  )
}

export default ImageGrids
