import isEmail from 'validator/lib/isEmail'
import { roles } from 'venus/config'
import { theme } from 'venus/config'
import { useForm, Controller } from 'react-hook-form'
import { Button, Input, LinkButton, Select, Text, Checkbox } from 'venus/components'
import { PRIVACY_POLICY_LINK_TEXT } from 'venus/pages/PrivacyPolicy/PrivacyPolicy'
import { TERMS_OF_SERVICE_LINK_TEXT } from 'venus/pages/TermsAndConditions/TermsAndConditions'
import {
  InputSection,
  Row,
  TnCSection,
  GeneralInfoWrapper,
  CheckBoxContent,
} from './styles'
import { tradesPeopleSubRolesArr } from 'venus/config'
import { verifyPwd } from 'venus/utils'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { handleAllowedToShareContactInfo } from 'venus/redux/features/user'
import { ERR_REQUIRED_FIELD } from 'venus/layout/ProfileMenu/ProfileSettings/Constants'
import { useLocation } from 'react-router-dom'

type UserFormData = {
  firstName: string
  lastName: string
  email: string
  password: string
  mobile?: string
  role: string
  tradespeopleRole?: string
  mobileValidationCode?: string
}

const GeneralInfo = ({ onSubmit }: { onSubmit: (data: UserFormData) => void }) => {
  const { state } = useLocation()

  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<UserFormData>({ defaultValues: state })
  const { isAllowedToShareContactInfo } = useAppSelector((state) => state.user)
  const role = watch('role')
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const email = watch('email')
  const password = watch('password')
  const tradespeopleRole = watch('tradespeopleRole')
  const checkEmptyInfo =
    !firstName || !lastName || !role || (role === 'TRADESPEOPLE' && !tradespeopleRole)

  const isDisabled =
    // @ts-ignore
    state && state?.oAuthType
      ? checkEmptyInfo
      : checkEmptyInfo || !firstName || !email || !password

  const handleValidateEmail = (v: string) => {
    if (!v) return true
    return isEmail(v)
  }

  const handleClickAllowedToShareContactInfo = () => {
    dispatch(handleAllowedToShareContactInfo())
  }

  return (
    <>
      <GeneralInfoWrapper>
        <Row>
          <InputSection>
            <Controller
              rules={{ required: true }}
              name='firstName'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    label='First Name'
                    value={value || ''}
                    onChange={onChange}
                    isError={!!errors.firstName}
                    errorMsg={ERR_REQUIRED_FIELD}
                  />
                )
              }}
            />
          </InputSection>
          <InputSection>
            <Controller
              rules={{ required: true }}
              name='lastName'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    label='Last Name'
                    value={value || ''}
                    onChange={onChange}
                    isError={!!errors.lastName}
                    errorMsg={ERR_REQUIRED_FIELD}
                  />
                )
              }}
            />
          </InputSection>
        </Row>
        <Row>
          <Controller
            rules={{ required: true, validate: (v) => handleValidateEmail(v) }}
            name='email'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label='Email'
                  value={value || ''}
                  onChange={onChange}
                  isError={!!errors.email}
                  errorMsg={
                    !getValues('email')
                      ? ERR_REQUIRED_FIELD
                      : getValues('email') !== getValues('email')?.trim()
                      ? 'Please remove leading or trailing spaces.'
                      : 'This email address is invalid.'
                  }
                />
              )
            }}
          />
        </Row>
        {/* @ts-ignore */}
        {!state?.oAuthType && (
          <Row>
            <Controller
              rules={{ required: true, validate: (v) => !verifyPwd(v) }}
              name='password'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    type='password'
                    label='Password'
                    value={value || ''}
                    onChange={onChange}
                    isError={!!errors.password}
                    errorMsg={verifyPwd(getValues('password'))}
                  />
                )
              }}
            />
          </Row>
        )}
        <Row>
          <Controller
            rules={{ required: true }}
            name='role'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  title='Role'
                  value={value}
                  onChange={onChange}
                  options={roles}
                  isError={!!errors.role}
                  errorMsg={ERR_REQUIRED_FIELD}
                  showTransparentBg={false}
                />
              )
            }}
          />
        </Row>
        {role === 'TRADESPEOPLE' && (
          <Row>
            <Controller
              rules={{ required: true }}
              name='tradespeopleRole'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    title='Tradespeople Role'
                    value={value}
                    options={tradesPeopleSubRolesArr}
                    onChange={onChange}
                    isError={!!errors.tradespeopleRole}
                    errorMsg={ERR_REQUIRED_FIELD}
                    style={{
                      marginBottom: '18px',
                    }}
                    showTransparentBg={false}
                  />
                )
              }}
            />
          </Row>
        )}
        {role === 'TRADESPEOPLE' && (
          <CheckBoxContent>
            <Checkbox
              checked={isAllowedToShareContactInfo}
              onChange={handleClickAllowedToShareContactInfo}
            />
            <Text
              value='If you are a service provider you consent to MyHomeVault sharing your contact details with other MyHomeVault registered users.'
              format='body'
              style={{ alignSelf: 'center', marginLeft: 12, marginTop: 12 }}
            />
          </CheckBoxContent>
        )}
      </GeneralInfoWrapper>
      <>
        <Row>
          <Button
            isSubmit
            style={{ margin: '10px 0' }}
            type='primary'
            text='Next'
            isDisabled={isDisabled}
            onClick={handleSubmit(onSubmit)}
          />
        </Row>
        <TnCSection>
          <Text format='body' style={{ textAlign: 'center' }}>
            By creating an account, I accept MyHomeVault's
          </Text>
          <Text
            format='body'
            style={{
              marginTop: '4px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LinkButton
              color={theme.colors.Secondary}
              format='body'
              to='/terms-and-conditions'
              target='_blank'
              rel='noopener noreferrer'
              label={TERMS_OF_SERVICE_LINK_TEXT}
            />
            <div style={{ margin: '0 4px' }}>and</div>
            <LinkButton
              color={theme.colors.Secondary}
              format='body'
              to='/privacy-policy'
              target='_blank'
              rel='noopener noreferrer'
              label={PRIVACY_POLICY_LINK_TEXT}
            />
          </Text>
        </TnCSection>
      </>
    </>
  )
}

export default GeneralInfo
