import { Text, Row, Button, Logo } from 'venus/components'

export const ButtonSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Row style={{ width: 400 }} height={80}>
        <Button
          type="primary"
          onClick={() => null}
          text="Primary Button Style"
        />
      </Row>
      <Row style={{ width: 400 }} height={80}>
        <Button
          type="secondary"
          onClick={() => null}
          text="Secondary  Button Style"
        />
      </Row>
      <Row style={{ width: 400 }} height={80}>
        <Button
          type="disabled"
          onClick={() => null}
          text="Disabled  Button Style"
        />
      </Row>
      <Row style={{ width: 400 }} height={80}>
        <Button
          type="error"
          onClick={() => null}
          text="Error State Button Style"
        />
      </Row>
      <Row style={{ width: 400 }} height={80}>
        <Button
          type="primary"
          isLoading
          onClick={() => null}
          text="isLoading"
        />
      </Row>
      <Row
        height={80}
        style={{
          backgroundColor: 'lightyellow',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type="round"
          onClick={() => null}
          iconLeft={<Logo size={32} />}
        />
      </Row>
      <Row height={80}>
        <Button
          type="icon_button"
          text="Share"
          onClick={() => null}
          iconLeft={<Logo size={24} style={{ marginLeft: -9 }} />}
          style={{ width: 'auto' }}
        />
      </Row>
    </>
  )
}
