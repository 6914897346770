import { useState } from 'react'
import { AutoComplete, Text } from 'venus/components'

export const AutoCompleteSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />

      <AutoComplete options={[]} onChange={() => {}} onSearch={() => {}} />
    </>
  )
}
