import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  acceptOffer,
  setCurrentDreamProperty,
  getDreamPropertyList,
  deleteDreamProperty,
} from 'venus/redux/features/dreamprice'
import { PillTag, AlertBox, Text } from 'venus/components'
import {
  getPropertyName,
  formatPriceWithDollarSign,
  displayLongText,
} from 'venus/utils'
import { ReactComponent as Kebab } from 'venus/assets/img/icons/Kebab.svg'
import { PropertyContainer, LabelArea } from './Preview.styles'
import {
  AddressShader,
  Item,
  KebabWrapper,
  OptionWrapper,
  OptionItem,
} from '../List.styles'
import { theme } from 'venus/config'
import { useNavigate } from 'react-router-dom'
import { buildURLQuery } from 'venus/utils/url'

export const Preview = (property: any) => {
  const { id, dreamPrice, status, isExpressInterest } = property
  const { loading } = useAppSelector((state) => state.dreamprice)
  const isSharedDreamPrice = status === 'SHARE_DREAM_PRICE'
  const isExpressedInterest = isSharedDreamPrice && isExpressInterest
  const [showAcceptOffer, setShowAcceptOffer] = useState(false)
  const [selectedDreamPropertyId, setSelectedDreamPropertyId] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleNavigation = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(setCurrentDreamProperty(property))

    if (property.propertyId) {
      navigate(
        `/property/${property.propertyId}?${buildURLQuery({
          dreamPropertyId: property.id,
        })}`
      )
    } else {
      navigate(`/dream/${property.id}`)
    }

    /// go to <NavLink to={`/property/${id}/dreamproperty`}>
  }

  const handlePressShowInterest = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (isExpressedInterest || !isSharedDreamPrice) return
    setShowAcceptOffer(true)
  }

  const handlPressCloseAcceptOffer = () => {
    setShowAcceptOffer(false)
  }

  const handlPressAcceptOffer = async () => {
    await dispatch(acceptOffer(id))
    await dispatch(getDreamPropertyList())
    setShowAcceptOffer(false)
  }

  const handleClickDelete = (event, id: string) => {
    event.preventDefault()
    event.stopPropagation()
    setSelectedDreamPropertyId(id)
    setShowConfirmDelete(true)
  }

  const handlPressAlertCancel = () => {
    setShowConfirmDelete(false)
  }

  const handlPressAlertOK = async () => {
    const data = await dispatch(deleteDreamProperty(selectedDreamPropertyId))
    await dispatch(getDreamPropertyList())
    setShowConfirmDelete(false)
    console.log('deleteDreamProperty return data::', data)
  }

  return (
    <>
      <Item
        key={property.id}
        coverImage={property.coverImage}
        onClick={handleNavigation}
      >
        <KebabWrapper>
          <Kebab color={theme.colors.White} />
          <OptionWrapper>
            {isSharedDreamPrice && !isExpressInterest && (
              <OptionItem onClick={handlePressShowInterest}>
                <Text format='h4' value='Express Interest' />
              </OptionItem>
            )}
            <OptionItem
              onClick={(e) => {
                handleClickDelete(e, property.id)
              }}
            >
              <Text format='h4' value='Remove Dream Property' />
            </OptionItem>
          </OptionWrapper>
        </KebabWrapper>
        <LabelArea>
          {!!dreamPrice && dreamPrice !== '0' && (
            <PillTag
              label={formatPriceWithDollarSign(dreamPrice)}
              color={theme.colors.Primary}
              background={theme.colors.White}
              paddingVertical={4}
              paddingHorizontal={14}
            />
          )}
          {isExpressInterest && (
            <PillTag
              style={{ marginLeft: '8px' }}
              label={'Expressed Interest'}
              color={theme.colors.White}
              background={theme.colors.Primary}
              paddingVertical={4}
              paddingHorizontal={14}
            />
          )}
        </LabelArea>
        <AddressShader />
        <PropertyContainer>
          <Text
            value={displayLongText(getPropertyName(property), 30)}
            format='h3'
            color={theme.colors.White}
            fontWeight={400}
            style={{
              position: 'absolute',
              bottom: 16,
              left: 20,
            }}
          />
        </PropertyContainer>
      </Item>
      <AlertBox
        cancel={handlPressCloseAcceptOffer}
        title='Dream Home'
        message={`By registering interest, you will be contacted with a local real estate agent for further action.`}
        btnGroup={[
          {
            text: 'Express Interest',
            onClick: handlPressAcceptOffer,
            color: 'Primary',
            loading,
            type: 'primary',
          },
          {
            text: 'Cancel',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: handlPressCloseAcceptOffer,
            type: 'secondary',
          },
        ]}
        showAlert={showAcceptOffer}
      />
      <AlertBox
        cancel={handlPressAlertCancel}
        title='Delete Dream Property'
        message='Are you sure you want to delete this dream property?'
        btnGroup={[
          {
            text: 'Delete',
            onClick: handlPressAlertOK,
            color: 'ErrorState',
            type: 'error',
          },
          {
            text: 'Cancel',
            onClick: handlPressAlertCancel,
            color: 'LightBlue',
            textColor: 'Primary',
            type: 'secondary',
          },
        ]}
        showAlert={showConfirmDelete}
      />
    </>
  )
}
