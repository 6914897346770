import styled from 'styled-components'

export const ModalBodyWrapper = styled.div`
  width: 480px;
  min-height: 480px;
  max-height: 680px;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
`

export const ModalContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 32px;
`

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100px;
  float: right;
  margin: 24px 32px;

  button {
    &:hover {
      svg {
        color: white;
      }
    }
  }
`
export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 34px;
`

export const BackIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 24px 32px;
`

export const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 24px 32px;
`
