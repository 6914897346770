import { LinkButton, Modal, PillTag, Text } from 'venus/components'
import { theme } from 'venus/config'
import { PRIVACY_POLICY_LINK_TEXT } from 'venus/pages/PrivacyPolicy/PrivacyPolicy'
import { TERMS_OF_SERVICE_LINK_TEXT } from 'venus/pages/TermsAndConditions/TermsAndConditions'
import {
  ChildrenContainer,
  Container,
  Content,
  DownloadIcon,
  DownloadSection,
  Footer,
  Header,
  Layout,
  Links,
  ProfileHeader,
  SidePanel,
} from './GeneralLayout.styles'
import Menus from './Menus'

import { ReactComponent as Logo } from 'venus/assets/img/icons/Logo.svg'
import { ReactComponent as Home } from 'venus/assets/img/icons/Home.svg'
import { ReactComponent as File } from 'venus/assets/img/icons/File.svg'
import { ReactComponent as Info } from 'venus/assets/img/icons/Info.svg'
import { ReactComponent as Contact } from 'venus/assets/img/icons/Contact.svg'
import { ReactComponent as Shared } from 'venus/assets/img/icons/Shared.svg'
import { ReactComponent as Reminder } from 'venus/assets/img/icons/Reminder.svg'
import { ReactComponent as Folder } from 'venus/assets/img/icons/Folder.svg'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'

import Authenticated from 'venus/auth/Authenticated'
import { Link, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import Profile from './Profile'
import { buildURLQuery } from 'venus/utils/url'
import MHVPremium from 'venus/common/MHVPremium'
import { PremiumProvider } from 'venus/context/MHVPremium'
import PremiumBanner from './PremiumBanner'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useEffect, useState } from 'react'
import { ButtonWrapper, CloseButton } from 'venus/common/MHVPremium/MHVPremium.styles'
import Subscriptions from '../ProfileMenu/ProfileSettings/Subscriptions'
import { getReminderList } from 'venus/redux/features/reminder'
import { getSubscription } from 'venus/redux/features/subscription/subscription'

const HELP_URL = 'https://myhomevault.zendesk.com/hc/en-us'

const MENUS = [
  {
    label: 'Property List ',
    url: '/',
    icon: Home,
  },
  {
    label: 'Personal Vault',
    url: 'personal',
    icon: File,
  },
  {
    label: 'Help',
    externalUrl: HELP_URL,
    icon: Info,
  },
]

const PROPERTY_MENUS = [
  {
    label: 'Home',
    url: '',
    icon: Home,
  },
  {
    label: 'Contacts',
    url: '/contacts',
    icon: Contact,
  },
  {
    label: 'Shared',
    url: '/shared',
    icon: Shared,
  },
  {
    label: 'Reminders',
    url: '/reminders',
    icon: Reminder,
  },
  {
    title: 'For me',
  },
  {
    label: 'Personal Vault',
    url: '/personal',
    isGlobal: true,
    icon: Folder,
  },
  {
    label: 'Help',
    externalUrl: HELP_URL,
    isGlobal: true,
    icon: Info,
  },
]

const GeneralLayout = ({ children }: { children?: any }) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  const [isPaymentOpen, setIsPaymentOpen] = useState(false)

  const openPremium = () => setIsPaymentOpen(true)

  const { property } = useAppSelector((state) => state.property)
  const { subscribed } = useAppSelector((state) => state.subscription)
  const { list, loading, error } = useAppSelector((state) => state.reminder)
  // const { profile } = useAppSelector((state) => state.profile)

  const location = useLocation()
  const params = useParams()
  const propertyId = params.propertyId
  const isInProperty = !!params.propertyId

  const due = (list || []).filter(({ status }) => status === 'DUE')

  useEffect(() => {
    if (propertyId && !shareId && !dreamPropertyId) {
      dispatch(getReminderList(propertyId))
    }
    if (!isInProperty) {
      dispatch(getSubscription())
    }
  }, [propertyId, isInProperty])

  const propertyMenus = PROPERTY_MENUS.map((menu) =>
    menu.isGlobal
      ? menu
      : {
          ...menu,
          url: `/property/${params.propertyId}${menu.url}?${buildURLQuery({
            shareId,
            dreamPropertyId,
          })}`,

          ...(!loading && property && due.length && menu.label === 'Reminders'
            ? {
                badge: (
                  <PillTag
                    label={due.length}
                    background={theme.colors.ErrorState}
                    color={theme.colors.White}
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={1}
                    paddingHorizontal={10}
                    paddingVertical={2}
                    style={{ margin: '2px 8px' }}
                  />
                ),
              }
            : {}),
        },
  )

  if (shareId || dreamPropertyId) {
    propertyMenus.splice(1, 3)
  }

  const handleGoToAppStore = () => {
    window.location.href = 'https://apps.apple.com/au/app/myhomevault/id1619854877'
  }

  const handleGoToGoogleStore = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.myhomevault.myhomevault'
  }

  const displayBanner = isInProperty ? !shareId && !dreamPropertyId : !subscribed.length

  console.log('isInProperty', displayBanner, isInProperty, subscribed, dreamPropertyId)
  // const selectedProperty =
  //   (!isInProperty && !subscribed.length) || (isInProperty && )
  //     ? property
  //     : null

  return (
    <PremiumProvider>
      <Layout>
        {displayBanner && (
          <PremiumBanner
            isInProperty={isInProperty}
            selectedProperty={property}
            openPremium={openPremium}
          />
        )}
        <Container>
          <SidePanel>
            <Header>
              <Link to='/'>
                <Logo />
              </Link>
            </Header>
            <Menus menus={isInProperty ? propertyMenus : MENUS} />
            <Footer>
              <DownloadSection>
                <DownloadIcon onClick={handleGoToAppStore} src='/svg/App_Store.svg' />
                <DownloadIcon
                  onClick={handleGoToGoogleStore}
                  src='/png/global-playstore.png'
                />
              </DownloadSection>
              <Links>
                <LinkButton
                  textStyle={{ fontSize: 12 }}
                  color={theme.colors.DarkGrey}
                  format='body'
                  to='/terms-and-conditions'
                  target='_blank'
                  rel='noopener noreferrer'
                  label={TERMS_OF_SERVICE_LINK_TEXT}
                />
                <LinkButton
                  textStyle={{ fontSize: 12 }}
                  color={theme.colors.DarkGrey}
                  format='body'
                  to='/privacy-policy'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginLeft: '24px' }}
                  label={PRIVACY_POLICY_LINK_TEXT}
                />
              </Links>
              <Text format='body' color={theme.colors.DarkGrey} fontSize={12}>
                © 2022 MyHomeVault
              </Text>
            </Footer>
          </SidePanel>
          <Content>
            <ProfileHeader>
              <Profile />
            </ProfileHeader>

            <ChildrenContainer>
              <Outlet />
            </ChildrenContainer>
          </Content>

          <MHVPremium />
        </Container>
      </Layout>

      <Modal hideHeader isOpen={isPaymentOpen}>
        <Subscriptions
          upgrade={isInProperty ? 1 : 0}
          style={{ paddingTop: 24 }}
          initialStep={'ChoosePlan'}
        />
        <ButtonWrapper>
          <CloseButton onClick={() => setIsPaymentOpen(false)}>
            <CrossIcon onClick={(e) => e.preventDefault()} />
          </CloseButton>
        </ButtonWrapper>
      </Modal>
    </PremiumProvider>
  )
}

export default GeneralLayout
