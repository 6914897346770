import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  errorTitle: '',
  errorMessage: '',
} as {
  errorTitle?: string,
  errorMessage?: string
}

const errorHandlerSlice = createSlice({
  name: 'errorMessage',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action
      state.errorMessage = payload
    },
    resetErrorMessage: (state) => {
      state.errorMessage = ''
    },
    setErrorTitle: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action
      state.errorTitle = payload
    },
    resetErrorTitle: (state) => {
      state.errorTitle = ''
    },
  },
})

export const { setErrorMessage, resetErrorMessage, setErrorTitle, resetErrorTitle } = errorHandlerSlice.actions
export default errorHandlerSlice.reducer
