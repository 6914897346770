import { useState } from "react"
import { Text, DatePicker, Row } from "venus/components"

export const DatePickerSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [value, onChange] = useState("")
  const [value2, onChange2] = useState("")
  return (
    <div style={{ width: 500 }}>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Row height={120}>
        <DatePicker onChange={onChange} value={value} disableFutureDate />
      </Row>
      <Row height={120}>
        <DatePicker
          onChange={onChange2}
          value={value2}
          isError={!value2}
          errorMsg="This is required."
        />
      </Row>
    </div>
  )
}
