import { useState } from 'react'
import { Text, Button, AlertBox } from 'venus/components'

export const AlertBoxSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [showAlertBox, setShowAlertBox] = useState(false)

  const handleOpenAlertBox = () => {
    setShowAlertBox(true)
  }

  const handleCloseAlertBox = () => {
    setShowAlertBox(false)
  }

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Button
        onClick={handleOpenAlertBox}
        type='primary'
        text='Open AlertBox'
        style={{ width: 300 }}
      />
      <AlertBox
        cancel={handleCloseAlertBox}
        title='Delete contact'
        message='Are you sure you want to remove this contact?'
        btnGroup={[
          {
            text: 'Delete very long text',
            onClick: handleCloseAlertBox,
            color: 'ErrorState',
            type: 'error',
          },
          {
            text: 'Cancel',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: handleCloseAlertBox,
            type: 'secondary',
          },
        ]}
        showAlert={showAlertBox}
      />
    </>
  )
}
