import { useEffect, useState } from 'react'
import { Text } from '..'
import { formatPrice } from 'venus/utils/number'
import {
  PriceInputWrapper,
  StyledValueInput,
} from 'venus/components/PriceInput/PriceInput.styles'
import { theme, Max_Price } from 'venus/config'
import { InputArea } from 'venus/components/Input/Input.styles'

interface IPriceInput {
  label: string
  value: number
  onChange: (v: number) => void
  style?: any
  isError?: boolean
  errorMsg?: string
}

export const PriceInput = ({
  label,
  value,
  onChange,
  style,
  isError = false,
  errorMsg,
}: IPriceInput) => {
  const [text, setText] = useState('')

  useEffect(() => {
    const price = formatPrice(value)
    setText(price)
  }, [value])

  const handleOnFocus = (e: any) => {
    const value = e.target.value
    const num = String(value).replaceAll(',', '')
    setText(num)
  }

  const handleOnBlur = (e: any) => {
    const value = e.target.value
    onChange(Number(value))
    const price = formatPrice(value)
    setText(price)
  }

  const handleChange = (e: any) => {
    const newValue = e.target.value
    const num = String(newValue).replaceAll(',', '')
    if (!/^\d*\.?\d{0,2}$/.test(num)) return
    if (Number(num) > Max_Price) return
    setText(num)
    onChange(Number(num))
  }

  return (
    <InputArea style={style}>
      <Text
        value={label}
        format='h3'
        lineHeight={1}
        style={{
          textAlign: 'left',
          marginBottom: '10px',
          display: 'inline-block',
        }}
      />
      <PriceInputWrapper>
        <Text
          value='$'
          color={theme.colors.Primary}
          format='h3'
          style={{ position: 'absolute', top: 13, left: 15 }}
        />
        <StyledValueInput
          min={0}
          type='text'
          value={text}
          onChange={handleChange}
          isError={isError}
          style={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '21px',
            borderRadius: 4,
            width: '-webkit-fill-available',
          }}
        />
        {isError && (
          <Text
            value={String(errorMsg)}
            format='h4'
            lineHeight='21px'
            color={theme.colors.DangerRed}
            style={{
              marginTop: '6px',
              display: 'inline-block',
            }}
          />
        )}
      </PriceInputWrapper>
    </InputArea>
  )
}
