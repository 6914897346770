import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from 'venus/api'
import { IImage } from 'venus/types/Image'
import { ISpace } from 'venus/types/Space'
// import { updateProperty } from './property'

export interface IProperty {
  shareId?: string
  sharedBy?: string
  id: string
  address: {
    state: string
    suburb: string
    address: string
    postcode: string
  }
  propertyData: {
    area: number
    beds: number
    baths: number
    parking: number
  }
  spacesId: string[]
  propertyWideSpaceId: string
  coverImage?: string
  images: IImage[]
  spaces: ISpace[]
  documents?: {
    displayName: string
    url: string
    updatedAt: string
    contentType: string
  }[]
  transfer?: object | null
  dreamPrice?: number
  googlePlaceId?: string
  propertyInfo?: any
}

// First, create the thunk
export const getProperties = createAsyncThunk(
  'property/getProperties',
  async (_, { getState }) => {
    try {
      const response = await api.get('/property')

      // console.log('res', response)

      return response.data
    } catch (err) {
      console.log('err', err)
      // @ts-ignore
      const error: AxiosError = err

      if (!error.response) {
        throw err
      }

      return isRejectedWithValue(error.response.data)
    }
  }
)

const initialState = {
  error: null,
  properties: [],
  loading: false,
} as {
  error: object | null | undefined
  properties: IProperty[]
  loading: boolean
}

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProperties.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getProperties.fulfilled, (state, action) => {
      state.loading = false
      state.properties = action.payload
    })
    builder.addCase(getProperties.rejected, (state, action) => {
      state.loading = false

      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })

    // builder.addCase(updateProperty, (state, action) => {
    //   state.properties = state.properties.map((property) => {
    //     // @ts-ignore
    //     return property.id === action.payload.id
    //       ? // @ts-ignore
    //         { ...property, ...action.payload }
    //       : property
    //   })

    //   console.log('action', action.payload)
    // })
  },
})

export default propertiesSlice.reducer
