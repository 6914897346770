import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { ReactNode } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useImperativeHandle } from 'react'
import { Avatar } from 'venus/components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { useAppSelector } from 'venus/redux/hooks'
import { SideBarHeader } from './ProfileSettings.styles'
import { SideBarArea } from './ProfileSettings.styles'
import { ModalBodyWrapper } from './ProfileSettings.styles'
import { SideBar } from './ProfileSettings.styles'
import { Contents } from './ProfileSettings.styles'
import { CloseButton } from './ProfileSettings.styles'
import { TextSelectArea } from './ProfileSettings.styles'
import { SideMenuItemText } from './ProfileSettings.styles'
import { settingsMenus } from 'venus/layout/ProfileMenu/ProfileSettings/Constants'
import { modalStyles } from 'venus/layout/ProfileMenu/ProfileSettings/Constants'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import { Loader } from 'venus/components'
import { updateProfile } from 'venus/redux/features/user'
import { useAppDispatch } from 'venus/redux/hooks'

const ProfileSettings = (
  {
    size = 'default',
    tabs,
    children,
    defaultActiveIndex,
    onChange = () => {},
    onClose = () => {},
    showSettings,
    paddingBottom,
    style,
  }: {
    size?: string
    tabs: string[]
    children: ReactNode[]
    defaultActiveIndex?: number
    paddingBottom?: number
    onChange?: (index: number) => void
    onClose?: () => void
    showSettings: boolean
    style?: any
  },
  ref: any,
) => {
  const dispatch = useAppDispatch()
  const [activeIndex, setActiveIndex] = useState<number>(defaultActiveIndex || 0)
  const { info, loadingProfile } = useAppSelector((state) => state.user)

  useEffect(() => {
    setActiveIndex(defaultActiveIndex)
  }, [defaultActiveIndex])

  const handlePress = (index: number) => {
    setActiveIndex(index)
    onChange(index)
  }

  const handleOnEditAvatar = async (selectedFile) => {
    if (!selectedFile) return
    await dispatch(
      updateProfile({
        profile: {
          avatar: selectedFile,
        },
      }),
    )
  }

  useImperativeHandle(ref, () => ({
    resetIndex() {
      setActiveIndex(0)
    },
  }))

  const contents = useMemo(() => {
    return children.filter((child) => child)
  }, [children])

  if (loadingProfile) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }
  return (
    <ReactModal
      isOpen={showSettings}
      style={{
        overlay: { ...modalStyles.overlay, ...style?.overlay },
        content: { ...modalStyles.content, ...style?.content },
      }}
    >
      <ModalBodyWrapper>
        <SideBar>
          <SideBarHeader>
            <Avatar
              size={83}
              avatar={info?.profile?.avatar}
              showEditBtn
              editable={true}
              handleOnEditAvatar={handleOnEditAvatar}
            />
          </SideBarHeader>
          <SideBarArea>
            {settingsMenus.map((tab: string, index: number) => (
              <TextSelectArea key={`text_area_${index}`} isActive={index === activeIndex}>
                <SideMenuItemText key={`tab_${index}`} onClick={() => handlePress(index)}>
                  <Text
                    value={tab}
                    format='h4'
                    fontSize={14}
                    fontWeight={500}
                    color={
                      index === activeIndex ? theme.colors.Primary : theme.colors.DarkGrey
                    }
                  />
                </SideMenuItemText>
              </TextSelectArea>
            ))}
          </SideBarArea>
        </SideBar>
        <Contents>
          <CloseButton onClick={onClose}>
            <CrossIcon />
          </CloseButton>
          {contents[activeIndex]}
        </Contents>
      </ModalBodyWrapper>
    </ReactModal>
  )
}

export default React.forwardRef(ProfileSettings)
