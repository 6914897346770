import styled from 'styled-components'
import { theme } from 'venus/config'

interface IShorterBorderArea {
  isActive: boolean
}

type HeaderProps = {
  readonly isWrap: boolean
  readonly center?: boolean
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  flex-wrap: wrap;

  border-bottom: ${(props) =>
    props.isWrap ? 'none' : `1px solid ${props.theme.colors.LighterGrey}`};
`

export const HeaderArea = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
`

export const HeaderTextItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  height: 100%;

  border-radius: 16px;
  cursor: pointer;
  transition-duration: 0.3s;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    > div > span {
      color: ${theme.colors.Primary} !important;
    }
  }
`

export const ShorterBorderArea = styled.div<IShorterBorderArea>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: ${(props) => (props.isActive ? theme.colors.Secondary : 'transparent')};
  border-radius: 3px;
`

export const BodyArea = styled.div`
  width: 100%;
`

export const HeaderButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
