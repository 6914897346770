import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, StepList, Modal, Notification, AlertBox } from 'venus/components'
import { theme } from 'venus/config'
import { AssignSpaceImages } from 'venus/pages/Onboarding/AssignSpaceImages'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getPropertyTemplate, createSpace } from 'venus/redux/features/property/property'
import {
  TSpace,
  resetOnboardingData,
  setConfirmHomeLayoutDone,
  setConfirmEditImageDone,
  setConfirmUploadDocsDone,
  setUploadNonNpdImagesDone,
  setError,
  setScanHomeDone,
} from 'venus/redux/features/onboarding'
import { createDocInfo } from 'venus/redux/features/docs'
import {
  HomeLayout,
  AddItems,
  UploadNonNpdImages,
  UploadDocs,
  AssignDocs,
  MoveDocs,
} from 'venus/pages/Onboarding'
import AddSpace from 'venus/common/AddSpace'
import { ReactComponent as Back } from 'venus/assets/img/icons/Back.svg'
import {
  IconButton,
  RightArea,
  LeftArea,
  HeaderArea,
  BodyArea,
  Content,
  BackButton,
  HeaderPlaceHolder,
} from './Onboarding.styles'
import OnboardingScanHome from './ScanHome'

interface IOnboarding {
  isOpen: boolean
  onClose: () => void
}
const onboardingTitle = 'Home Layout'

export const Onboarding = ({ isOpen, onClose }: IOnboarding) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [step, setStep] = useState(1)
  const [title, setTitle] = useState(onboardingTitle)
  const [spaces, setSpaces] = useState<TSpace[]>([])
  const { preview } = useAppSelector((state) => state.address)
  const {
    isScanHomeDone,
    isConfirmHomeLayoutDone,
    isConfirmEditImageDone,
    isConfirmUploadDocsDone,
    homeDocs,
    error,
  } = useAppSelector((state) => state.onboarding)
  const { propertyId, property } = useAppSelector((state) => state.property)
  const unclassifiedDocs = homeDocs.filter(({ isUploaded }) => isUploaded !== true)

  useEffect(() => {
    const getSpaces = async () => {
      const data = await dispatch(getPropertyTemplate(propertyId))
      if (data.meta.requestStatus === 'fulfilled') {
        setSpaces(data.payload)
      }
    }
    if (propertyId) getSpaces()
  }, [propertyId, dispatch, setSpaces])

  const { googlePlaceId } = preview[0] || {}
  const isNonNdp = !!googlePlaceId
  const skippable = [1, 3, 4, 5, 6, 7, 8].indexOf(step) >= 0
  const canGoBack = [1, 3, 4, 5, 6, 7, 10].indexOf(step) === -1
  const titleArr = [
    'Home Layout',
    'Add Space',
    'Add Items',
    'Images',
    'Images',
    'Scan Home',
    'Upload',
    'Assign Documents',
    'Move To',
    '',
  ]

  const onSkip = async () => {
    if (step === 1) {
      const checkedSpaces = spaces.filter(({ checked }) => checked === true)
      await dispatch(createSpace({ propertyId, spaces: checkedSpaces }))
      dispatch(resetOnboardingData())
      dispatch(
        setConfirmHomeLayoutDone({
          isConfirmHomeLayoutDone: true,
        }),
      )
      setTitle('Images')
      setStep(isNonNdp ? 4 : 5)
    } else if (step === 3) {
      dispatch(resetOnboardingData())
      dispatch(
        setConfirmHomeLayoutDone({
          isConfirmHomeLayoutDone: true,
        }),
      )
      setTitle('Images')
      setStep(isNonNdp ? 4 : 5)
    } else if (step === 4) {
      dispatch(setUploadNonNpdImagesDone(true))
      setStep(5)
    } else if (step === 5) {
      dispatch(
        setConfirmEditImageDone({
          isConfirmEditImageDone: true,
        }),
      )
      setTitle('Scan Home')
      setStep(6)
    } else if (step === 6) {
      dispatch(setScanHomeDone(true))
      setTitle('Upload')
      setStep(7)
    } else if (step === 7) {
      dispatch(
        setConfirmUploadDocsDone({
          isConfirmUploadDocsDone: false,
        }),
      )
      setStep(10)
    } else if (step === 8) {
      const toBeUploaded = unclassifiedDocs.map(({ filename, uri, contentType }) => ({
        displayName: filename,
        url: uri,
        contentType,
      }))
      const body: {
        propertyId: string
        spaceId?: string
        itemId?: string
        documents: {
          displayName: string
          url: string
          contentType: string
        }[]
      } = {
        documents: toBeUploaded,
        propertyId,
        spaceId: property.propertyWideSpaceId,
      }
      await dispatch(createDocInfo(body))
      dispatch(
        setConfirmUploadDocsDone({
          isConfirmUploadDocsDone: false,
        }),
      )
      setStep(10)
    }
  }

  const onAddSpace = async (newSpace) => {
    const { name: thisName } = newSpace
    const isExisted = spaces.find(({ name }) => name === thisName)
    if (isExisted === undefined) {
      setSpaces([...spaces, newSpace])
      setStep(1)
      setTitle('Home Layout')
    } else {
      dispatch(setError('Duplicated Space Name.'))
    }
  }

  const handlPressAlertOK = () => {
    dispatch(setError(''))
  }

  const handleGoBack = () => {
    let preStep = 1
    if (step === 4) preStep = 3
    if (step === 5) {
      if (isNonNdp) {
        preStep = 4
      } else {
        preStep = 3
      }
    }
    if (step >= 7) preStep = step - 1
    setTitle(titleArr[preStep - 1])
    setStep(preStep)
  }

  const handlePressExploreHome = () => {
    dispatch(resetOnboardingData())
    setStep(1)
    onClose()
    navigate(`/property/${propertyId}`)
  }

  // 1 2 1
  // 1 3 isNonNdp ? 4 : 5
  // 4 5
  // 5 6 7 8/9

  if (step === 10)
    return (
      <Modal
        isOpen={isOpen}
        width={0}
        height={0}
        style={{
          overlay: {
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/png/bg.png) no-repeat center`,
            backgroundSize: 'cover',
          },
          content: {
            width: 0,
            height: 0,
          },
        }}
        hideHeader
      >
        <Notification
          message='Set Up Complete!'
          closeAlert={handlePressExploreHome}
          showAlert
        />
      </Modal>
    )

  console.log('STEP', step, canGoBack)

  return (
    <Modal
      isOpen={isOpen}
      width={680}
      height={680}
      style={{
        overlay: {
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/png/bg.png) no-repeat center`,
          backgroundSize: 'cover',
        },
      }}
      hideHeader
    >
      <Content>
        <LeftArea>
          <StepList
            text='Confirm Home Layout'
            isDisabled={isConfirmHomeLayoutDone}
            isCompleted={isConfirmHomeLayoutDone}
          />
          <StepList
            text={isNonNdp ? 'Upload & Edit Home Images' : 'View & Edit Home Images'}
            isDisabled={!isConfirmHomeLayoutDone}
            isCompleted={isConfirmEditImageDone}
          />
          <StepList
            text={'Scan Home'}
            isDisabled={!isConfirmEditImageDone}
            isCompleted={isScanHomeDone}
          />
          <StepList
            text='Upload Documents'
            isDisabled={!isScanHomeDone}
            isCompleted={isConfirmUploadDocsDone}
          />
        </LeftArea>
        <RightArea>
          <HeaderArea>
            {canGoBack && (
              <BackButton onClick={handleGoBack}>
                <Back />
              </BackButton>
            )}
            {!canGoBack && <HeaderPlaceHolder />}
            <Text value={title} color={theme.colors.Black} format='h1' />
            {skippable && (
              <IconButton onClick={onSkip}>
                <Text value='Skip' color={theme.colors.Black} format='h3' />
              </IconButton>
            )}
            {!skippable && <HeaderPlaceHolder />}
          </HeaderArea>
          <BodyArea>
            {step === 1 && (
              <HomeLayout
                setStep={setStep}
                setTitle={setTitle}
                spaces={spaces}
                setSpaces={setSpaces}
              />
            )}
            {step === 2 && (
              <AddSpace
                // setStep={setStep}
                // setTitle={setTitle}
                onSubmit={onAddSpace}
                // spaces={spaces}
                // setSpaces={setSpaces}
                // isOnboarding
              />
            )}
            {step === 3 && (
              <AddItems setStep={setStep} setTitle={setTitle} isNonNdp={isNonNdp} />
            )}
            {step === 4 && <UploadNonNpdImages setStep={setStep} setTitle={setTitle} />}
            {step === 5 && <AssignSpaceImages setStep={setStep} setTitle={setTitle} />}
            {step === 6 && <OnboardingScanHome setStep={setStep} setTitle={setTitle} />}
            {step === 7 && <UploadDocs setStep={setStep} setTitle={setTitle} />}
            {step === 8 && <AssignDocs setStep={setStep} setTitle={setTitle} />}
            {step === 9 && (
              <MoveDocs setStep={setStep} setTitle={setTitle} propertyId={propertyId} />
            )}
          </BodyArea>
        </RightArea>
      </Content>
      <AlertBox
        cancel={handlPressAlertOK}
        title='Onboarding Error'
        message={error}
        btnGroup={[
          {
            text: 'OK',
            onClick: handlPressAlertOK,
            color: 'Primary',
            type: 'error',
          },
        ]}
        showAlert={!!error}
      />
    </Modal>
  )
}
