import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'

export const getDreamPropertyList = createAsyncThunk(
  'dream/getDreamPropertyList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dreams`)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const addDreamProperty = createAsyncThunk(
  'dream/addDreamProperty',
  async (
    {
      addressId,
      propertyKey,
      googlePlaceId,
      nationalParcelId,
    }: {
      addressId: number
      propertyKey: number
      googlePlaceId: string
      nationalParcelId: string
    },
    { rejectWithValue },
  ) => {
    try {
      const body_NPD = {
        addressId,
        nationalParcelId,
        propertyKey,
      }
      const body_NON_NPD = {
        googlePlaceId,
      }
      const body = googlePlaceId ? body_NON_NPD : body_NPD
      console.log('addDreamProperty body::', body)
      const response = await api.post(`/dreams`, body)
      console.log('addDreamProperty::', response.data)
      return response.data
    } catch (err) {
      console.log('ERROR in RES:', err)

      if (err.data) {
        return rejectWithValue(err.data)
      }

      return rejectWithValue(err)
    }
  },
)

export const deleteDreamProperty = createAsyncThunk(
  'contact/deleteDreamProperty',
  async (dreamPropertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/dreams/${dreamPropertyId}`)
      console.log('deleteDreamProperty::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const unshareDreamPrice = createAsyncThunk(
  'contact/unshareDreamPrice',
  async (propertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/dream/${propertyId}`)
      console.log('unshareDreamPrice::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getNumberOfUserTrackingProperty = createAsyncThunk(
  'dream/getNumberOfUserTrackingProperty',
  async (propertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dream/${propertyId}`)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getDreamPropertyDetails = createAsyncThunk(
  'dream/getDreamPropertyDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dreams/${id}`)
      console.log('url::', `/dreams/${id}`)
      console.log('getDreamPropertyDetails::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getPropertyWithDreamId = createAsyncThunk(
  'dream/getPropertyWithDreamId',
  async (
    { propertyId, dreamId }: { propertyId: string; dreamId: string },
    { rejectWithValue },
  ) => {
    try {
      console.log('url::', `/property/${propertyId}/?dreamPropertyId=${dreamId}`)
      const response = await api.get(`/property/${propertyId}/?dreamPropertyId=${dreamId}`)
      console.log('getPropertyWithDreamId::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getDreamPropertyWideSpace = createAsyncThunk(
  'dream/getDreamPropertyWideSpace',
  async (
    {
      propertyId,
      spaceId,
      dreamId,
    }: {
      propertyId: string
      spaceId: string
      dreamId: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.get(
        `/property/${propertyId}/spaces/${spaceId}?dreamPropertyId=${dreamId}`,
      )
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const acceptOffer = createAsyncThunk(
  'dream/acceptOffer',
  async (dreamPropertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.put(`/dreams/${dreamPropertyId}`)
      console.log('acceptOffer::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  },
)

export const shareDreamPrice = createAsyncThunk(
  'dream/shareDreamPrice',
  async (
    {
      isShareWholeProperty,
      propertyId,
      dreamPrice,
      shareScope,
    }: {
      isShareWholeProperty: boolean
      propertyId: string
      dreamPrice: number
      shareScope: any
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(`/dream`, {
        propertyId,
        dreamPrice,
        shareScope: { ...shareScope, isShareWholeProperty },
      })
      console.log('shareDreamPrice::', response.data)
      return response.data
    } catch (err) {
      console.log('err.data::', err.data)
      return rejectWithValue(err.data)
    }
  },
)

export const getDreamStatistics = createAsyncThunk(
  'dream/getStatistics',
  async (
    { type = 'A', dreamPropertyId }: { type: string; dreamPropertyId?: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.get(`/dreams/${dreamPropertyId}/statistics`, {
        params: { type },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  },
)

export const getDreamAVM = createAsyncThunk(
  'dream/getAVM',
  async ({ dreamPropertyId }: { dreamPropertyId?: string }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dreams/${dreamPropertyId}/avm`)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  },
)

export const getDreamRentalAVM = createAsyncThunk(
  'dream/getRental',
  async ({ dreamPropertyId }: { dreamPropertyId?: string }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/dreams/${dreamPropertyId}/rental`)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  },
)
