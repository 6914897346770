import styled from 'styled-components'

export const ChecksArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  margin-top: 24px;
  width: calc(100% - 48px);
  height: 430px;
  overflow-y: scroll;
`

export const CheckBoxContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
  margin-bottom: -6px;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-bottom: 24px;
  margin-top: 24px;
`
