import styled from 'styled-components'
import { theme } from 'venus/config'

interface ITextSelectedArea {
  isActive: boolean
}

export const ModalBodyWrapper = styled.div`
  width: 680px;
  height: 680px;
  max-width: 680px;
  max-height: 680px;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
`

export const SideBar = styled.div`
  flex: 1;
  background: ${theme.colors.LighterGrey};
`

export const Contents = styled.div`
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`

export const SideBarHeader = styled.div`
  margin: 30px 24px;
`

export const SideBarArea = styled.div`
  margin-top: 10px;
`

export const TextSelectArea = styled.div<ITextSelectedArea>`
  width: 100%;
  background-color: ${(props) =>
          props.isActive ? theme.colors.LightBlue : 'transparent'};
`

export const SideMenuItemText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  margin-right: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  padding: 8px;

  &:hover {
    span {
      color: ${theme.colors.Primary} !important;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 32px;
  right: 28px;
  border: 0;
  outline: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
