import { Text, Row, IconButton } from 'venus/components'
import { ReactComponent as Back } from 'venus/assets/img/icons/Back.svg'
import { ReactComponent as Calendar } from 'venus/assets/img/icons/Calendar.svg'
import { theme } from 'venus/config'

export const IconButtonSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Row
        style={{
          width: 400,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: theme.colors.LightBlue,
          padding: 16,
        }}
        height={50}
      >
        <IconButton onClick={() => null} icon={<Back />} />
        <IconButton
          onClick={() => null}
          icon={<Calendar />}
          textColor={theme.colors.Black}
          textColorOnHover={theme.colors.White}
        />
        <IconButton
          onClick={() => null}
          icon={<Calendar />}
          textColor={theme.colors.Black}
          textColorOnHover={theme.colors.DangerRed}
        />
      </Row>
    </>
  )
}
