import { theme } from 'venus/config'
import { ReactComponent as Star } from 'venus/assets/img/icons/Star.svg'
import { Text } from '..'
import {
  Wrapper,
  StarWrapper,
  RatingArea,
} from './AverageRating.styles'
import React from 'react'

interface IRating {
  average: string | number
  style?: any
  noOfRatings?: string
  showNoOfRatings?: boolean
}

export const AverageRating = ({
  average = '0',
  style = {},
  noOfRatings = '',
  showNoOfRatings = false,
}: IRating) => {
  const numOfRatingsText = `${noOfRatings} Ratings`
  return (
    <Wrapper style={style}>
      <RatingArea>
        <StarWrapper style={{ marginRight: 12 }}>
          <Star/>
        </StarWrapper>
        <Text value={String(average)} format="h3" color={theme.colors.Primary}/>
        {showNoOfRatings && (
          <>
            <Text format={'body'} fontWeight={600} value={'•'} style={{ margin: '0 10px' }}/>
            <Text format={'body'} fontWeight={600} value={numOfRatingsText}/>
          </>
        )}
      </RatingArea>
    </Wrapper>
  )
}
