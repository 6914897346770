import styled from 'styled-components'
import { theme } from 'venus/config'

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 36px;
  right: 24px;
`

export const SpaceLabelView = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.LightBlue};
  padding: 3px 12px;
  height: 27px;
`

export const ItemLabelView = styled(SpaceLabelView)`
  background-color: ${theme.colors.LightBlue};
  margin-left: 6px;
`

export const LabelArea = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
`

export const OptionWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3px;
`

export const SelectWrapper = styled.div`
  padding-bottom: 24px;
  width: 100%;
  margin-top: -24px;
`

export const BodyArea = styled.div`
  background-color: ${theme.colors.White};
  padding: 0 24px 24px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
`

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 480px;
  height: 560px;
  z-index: 10;
  border-radius: 12px;
`

export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 34px 0;
`

export const RightBtnArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: 50px;
  padding: 3px 12px;
  align-self: center;
`

export const ItemWrapper = styled.div`
  margin-bottom: 18px;
`

export const PlaceHolder = styled.div`
  width: 126px;
`
