import { ReactNode } from "react"
import { RowArea } from "./Row.styles"

interface IRow {
  height?: number
  style?: any
  children: ReactNode
}

export const Row = ({ height = 48, style, children }: IRow) => {
  return (
    <RowArea
      style={{
        width: "100%",
        height,
        margin: 0,
        ...style,
      }}
    >
      {children}
    </RowArea>
  )
}
