import styled from 'styled-components'

export const ListContainer = styled.div`
  flex: 1;
  min-height: 0;

  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
`
