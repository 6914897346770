import _ from 'lodash'
import { useEffect, useState, useRef } from 'react'
// import MHVPremium from 'venus/scenes/Transfer/MHVPremium'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getContactList } from 'venus/redux/features/contact'
import { createHistory } from 'venus/redux/features/history/history'
import { deleteShareItem } from 'venus/redux/features/share'
import HistoryForm from 'venus/pages/History/HistoryForm'
import { getShareList, resetError } from 'venus/redux/features/share'
import { FREE, FREE_PREMIUM, PAID, theme } from 'venus/config'
import { formateDate } from 'venus/utils'
import { Text, Loader, Avatar, EmptyData, Drawer, AlertBox } from 'venus/components'
import { Details } from 'venus/pages/Share'
import {
  SharedInfoArea,
  LeftSide,
  RightSide,
  TitleWrapper,
  ShareScopeArea,
  EmptyDataWrapper,
  Wrapper,
  BodyArea,
  ContentArea,
  HeaderArea,
  IconArea,
  Header,
} from './Shared.styles'
import { HistoryContainer } from './Shared.styles'
import { HistoryHeader } from './Shared.styles'
import { HistoryTitle } from './Shared.styles'
import React from 'react'
import { HistoryBody } from './Shared.styles'
import { resetHistoryStore } from 'venus/redux/features/history/history'
import { getProperty } from 'venus/redux/features/property/property'
import { CREATE_HISTORY_TITLE } from 'venus/pages/History/HistoryForm/Constants'
import { DETAIL_TITLE } from 'venus/pages/Share/Constants'
import { ActionLayout, Button } from 'venus/components'
import usePremium from 'venus/hooks/usePremium'
import usePremiumContext from 'venus/context/MHVPremium'
// import useShowPremium from 'mhv/hooks/useShowPremium'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { Share } from 'venus/pages/Share'
import SharedList from './SharedList'

const getShareScope = (isShareWholeProperty, shareScope) => {
  if (isShareWholeProperty) {
    return [{ value: 'Entire Property', type: 'space' }]
  }

  const sharedSpaces =
    shareScope.map(({ spaceName }) => ({ value: spaceName, type: 'space' })) || []

  const sharedItems = shareScope
    .reduce(
      (acc, curr) => [...acc, ...(curr.items || []).map(({ itemName }) => itemName)],
      [],
    )
    .filter((itemName) => !!itemName)
    .map((value) => ({ value, type: 'item' }))

  const shares = [...sharedSpaces, ...sharedItems]
  if (shares.length > 2) {
    return [...shares.slice(0, 2), { value: '...', type: 'space' }]
  }
  return shares
}

const Shared = () => {
  const dispatch = useAppDispatch()
  const { shareList, loading, error } = useAppSelector((state) => state.share)
  const { property } = useAppSelector((state) => state.property)
  const { history } = useAppSelector((state) => state.history)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isOpenAddToHistory, setIsOpenAddToHistory] = useState(false)
  const [showShareDrawer, setShowShareDrawer] = useState(false)
  const { propertyId } = useParams()
  const [removeAccess, setRemoveAccess] = useState(false)
  const onCloseDrawer = useRef(null)

  const { propertyStatus } = usePremium(propertyId)
  const { openPremium } = usePremiumContext()

  const [isShareOpen, setIsShareOpen] = useState(false)

  // const subscriptionStatus = vaults[propertyId]
  // console.log('subscriptionStatus::', subscriptionStatus)

  // const { isShown, onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )
  // const [showFreemiumAlert, setShowFreemiumAlert] = useState(isShown)

  useEffect(() => {
    // make "Share" feature free
    //    if (propertyId && propertyStatus !== FREE && propertyStatus !== undefined) {
    dispatch(getShareList(propertyId))
    dispatch(getContactList(propertyId))
    dispatch(
      getProperty({
        propertyId,
      }),
    )
    //    }

    //    if (propertyId && propertyStatus === FREE) {
    //      openPremium()
    //    }

    // setShowFreemiumAlert(isShown)
  }, [propertyId, propertyStatus])

  // const handleOnCancel = () => {
  //   setShowFreemiumAlert(false)
  //   onCancel()
  // }

  // const handleViewPlan = () => {
  //   setShowFreemiumAlert(false)
  //   onViewPlan()
  // }

  // if (loading) return <Loader isFull />

  const handlePress = (index: number) => {
    setSelectedIndex(index)
    setShowShareDrawer(true)
  }

  const onCloseDetails = () => {
    setShowShareDrawer(false)
  }

  const onCloseDetailsAddHistory = () => {
    setIsOpenAddToHistory(true)
  }

  const onCloseHistory = () => {
    if (_.isFunction(onCloseDrawer?.current)) {
      onCloseDrawer?.current()
    }
    dispatch(resetHistoryStore())
    setIsOpenAddToHistory(false)
    onCloseDetails()
  }

  const handlPressAlertOK = () => {
    dispatch(resetError())
  }

  const handleOnSubmitHistory = async (data) => {
    if (!data) return
    const body: any = {
      propertyId,
      spaceId: history.spaceId || property?.propertyWideSpaceId,
      eventName: data.eventName.trim(),
      eventDate: data.eventDate,
      itemCost: parseFloat(data.itemCost),
      laborCost: parseFloat(data.laborCost),
      notes: data.notes?.trim() || '',
      contactIds: data.contacts.map((contact) => contact.id),
      rating: {
        timeManagement: data.timeManagementRating,
        communication: data.communicationRating,
        pricing: data.pricingRating,
        quality: data.qualityRating,
      },
    }
    // only add itemId when not property share and itemId is valid
    if (history.spaceId !== property?.propertyWideSpaceId && history?.itemId) {
      body.itemId = history.itemId
    }
    await dispatch(createHistory(body)).then(async (res) => {
      onCloseHistory()
      // Delete share when history is created successfully
      if (res.meta?.requestStatus === 'fulfilled') {
        await dispatch(
          deleteShareItem({ propertyId, shareId: shareList[selectedIndex].id }),
        )
      }
    })
  }

  const handleClickAddShare = (event) => {
    //  event.stopPropagation()

    // make "Share" feature free
    //    if (propertyStatus === 'free') {
    //      return openPremium()
    //    }

    setIsShareOpen(true)
  }

  const handleCloseShare = () => {
    console.log('handleCloseShare')
    setIsShareOpen(false)
  }

  return (
    <>
      <Share onClose={handleCloseShare} isOpen={isShareOpen} />
      <Wrapper>
        <Header>
          <Text value='Shared' format='h1' />
          <IconArea>
            <Button
              type='icon_button'
              text='Add'
              onClick={handleClickAddShare}
              iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
              style={{ width: 'auto' }}
            />
          </IconArea>
        </Header>

        {!shareList ||
          (shareList.length === 0 && (
            <EmptyDataWrapper>
              <EmptyData
                message={`To share your property, space or item go to one of your properties and click on the share icon in the top right corner.`}
              />
            </EmptyDataWrapper>
          ))}
        {shareList.length > 0 && (
          <SharedList shareList={shareList} handlePress={handlePress} />
        )}
        <Drawer
          onClose={() => {
            setShowShareDrawer(false)
          }}
          isOpen={showShareDrawer}
        >
          <ActionLayout
            margin={36}
            children={
              <ContentArea>
                <HeaderArea>
                  <Text value={DETAIL_TITLE} color={theme.colors.Black} format='h1' />
                </HeaderArea>
                <BodyArea>
                  <Details
                    index={selectedIndex}
                    handleClose={onCloseDetails}
                    handleOpenHistory={onCloseDetailsAddHistory}
                    removeAccess={removeAccess}
                    closeRemoveAccess={() => {
                      setRemoveAccess(false)
                    }}
                  />
                </BodyArea>
              </ContentArea>
            }
            rightActions={[
              {
                type: 'primary',
                text: 'Remove access',
                onClick: () => {
                  setRemoveAccess(true)
                },
              },
            ]}
          />
        </Drawer>
        {/* <MHVPremium
       isOpen={showFreemiumAlert}
       onViewPlan={handleViewPlan}
       onCancel={handleOnCancel}
       /> */}
        <Drawer onClose={onCloseHistory} isOpen={isOpenAddToHistory}>
          <HistoryContainer>
            <HistoryHeader>
              <HistoryTitle>
                <Text format={'h1'} value={CREATE_HISTORY_TITLE} />
              </HistoryTitle>
            </HistoryHeader>
            <HistoryBody>
              <HistoryForm
                history={history}
                onSubmit={handleOnSubmitHistory}
                onCloseDrawer={onCloseDrawer}
              />
            </HistoryBody>
          </HistoryContainer>
        </Drawer>
        <AlertBox
          cancel={handlPressAlertOK}
          title={`Share Error`}
          message={error}
          btnGroup={[
            {
              text: 'OK',
              onClick: handlPressAlertOK,
              color: 'Primary',
              type: 'error',
            },
          ]}
          showAlert={!!error}
        />
      </Wrapper>
    </>
  )
}

export default Shared
