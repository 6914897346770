import styled from 'styled-components'
import { theme } from 'venus/config'

export const DocumentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 12px;
  border-bottom: 1px solid ${theme.colors.LightBlue};
  padding: 3px 12px;
`

export const DocumentLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 6px;
`

export const DocumentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 40px;
  margin-left: 12px;
`

export const KebabIconWrapper = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
`

export const EmptyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  height: 100%;
`

export const HyperLink = styled.a`
  text-decoration: none;
`

export const SelectOptionsContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 0;
  z-index: 1;
  margin-top: 4px;
  background-color: ${theme.colors.LightBlue};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  width: 200px;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;
`

export const SubListBg = styled.div`
  position: fixed;
  width: calc(100% - 2px);
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 1;
  background-color: transparent;
`

export const ModalContent = styled.iframe`
  width: 600px;
  height: 100%;
`
