import React from 'react';
import { HistoryCardView } from 'venus/components';
import { Text } from 'venus/components';

export const HistoryListViewSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const data = {
    'id': 't-hisotry-0001',
    'propertyId': 'test-property-0001',
    'eventName': 'MHV History 2',
    'eventDate': '31/08/2022',
    'notes': 'Etiam condimentum mattis turpis, nec facilisis nibh suscipit eget. Pellentesque vel placerat enim, et convallis elit. Quisque eu lectus in mauris rutrum gravida.',
    'contacts': [
      {
        'id': 'test-contact-3',
        'firstName': 'Chris',
        'lastName': 'Lee',
        'profile': {
          'avatar': 'https://i.pravatar.cc/150?img=32',
          'companyName': 'Vault Co. Ltd.',
          'isAllowedToShareContactInfo': true,
          'isAllowedToSharePropertyInfo': true
        },
        'companyName': 'Vault Co. Ltd.',
        'companyPhone': null,
      }
    ],
    'tags': [
      'Kitchen', 'TV', 'Air Condition', 'Washing Machine', 'Paint', 'Bath', 'Living Room'
    ]
  };
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <div style={{ width: '980px' }} >
        <HistoryCardView data={data}></HistoryCardView>
      </div>
    </>
  );
};
