import { useEffect, useState } from 'react'
import { Button } from 'venus/components'
import { extractNumber, formatPrice, getDate, getErrorMsg } from 'venus/utils'
import { updatePersonalItem } from 'venus/redux/features/personal/reducer'
import ItemDetail from './ItemDetail'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'
import EditItem from 'venus/common/AddItem/EditItem'
import api from 'venus/api'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'venus/redux/hooks'
import { updateItem } from 'venus/redux/features/item/item'
import { updatePropertyItem } from 'venus/redux/features/property/property'

const infos = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Brand Name',
    key: 'brandName',
  },
  {
    label: 'Model Name',
    key: 'modelName',
  },
  {
    label: 'Serial Number',
    key: 'serialNo',
  },
  [
    { label: 'Installed Date', key: 'installedAt', formatter: getDate },
    { label: 'Warranty Date', key: 'warrantyDate', formatter: getDate },
  ],
  {
    label: 'Value',
    key: 'value',
    formatter: (value: string) =>
      value ? `$ ${formatPrice(Number(value).toFixed(2))}` : '',
  },
  {
    label: 'Notes',
    key: 'note',
  },
]

const ItemDetails = ({
  item,
  setTabButtons,
  isPropertyWideSpace,
  shareId,
  dreamPropertyId,
  isPersonal = false,
}) => {
  const { propertyId, spaceId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState('')

  const dispatch = useAppDispatch()
  const openEditModal = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!shareId && !dreamPropertyId)
      setTabButtons(
        <Button
          onClick={openEditModal}
          type='icon_button_tertiary'
          text='Edit Item'
          iconLeft={<EditIcon style={{ marginRight: 8 }} />}
          style={{ width: 'auto' }}
        />
      )
    return () => setTabButtons(<></>)
  }, [shareId, dreamPropertyId])

  const onSubmit = async (data) => {
    // setIsLoading(true)
    console.log('edit item data', data)
    const formattedData = {
      ...data,
      value: extractNumber(data.value),
    }
    if (isPersonal) {
      try {
        const response = await api.put(
          `/personal/item/${item.id}`,
          formattedData
        )
        if (response.status === 200) {
          const updated = response.data
          dispatch(updatePersonalItem(updated))
          onClose()
        }
        return
      } catch (error) {
        const errors = getErrorMsg(error.data)
        if (Array.isArray(errors)) {
          let message = ''
          errors.forEach(({ msg }) => {
            message += `${msg} `
          })
          setError(message)
        } else {
          setError(errors)
        }
        return
      }
    }
    try {
      const response = await api.put(
        `/property/${propertyId}/space/${spaceId}/item/${item.id}`,
        formattedData
      )
      const updated = response.data
      dispatch(updateItem(updated))
      if (isPropertyWideSpace) {
        dispatch(updatePropertyItem(updated))
      }
      // setIsLoading(false)
      // navigation.goBack()
      onClose()
    } catch (error) {
      // setIsLoading(false)
      // if (error.data?.errors) {
      //   setError(error.data.errors[0].msg)
      // } else {
      //   setError(error.data)
      // }
    }
  }

  return (
    <div>
      {infos.map((info, index) => (
        <ItemDetail key={index} detail={info} item={item} />
      ))}

      <EditItem
        item={item}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isPersonal={isPersonal}
      />
    </div>
  )
}

export default ItemDetails
