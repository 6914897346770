import { Text, Image } from 'venus/components'

export const ImageSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Image
        style={{ width: '300px', height: '300px', marginBottom: '12px' }}
        image="https://www.myhomevault.com.au/images/MHV_LandingPage_Mobile_Banner.jpg"
        actions={[
          {
            icon: <img alt="close" src="./svg/ColorPencil.svg" />,
            onClick: () => null,
          },
        ]}
      />
      <Image
        style={{ width: '300px', height: '200px' }}
        image="https://www11.myhomevault.com.au/images/MHV_LandingPage_Mobile_Banner.jpg"
      />
    </>
  )
}
