import styled from 'styled-components'
import ImageUploader from '../ImageUploader'
import { ReactComponent as UploadIcon } from 'venus/assets/img/icons/Upload.svg'
import { theme } from 'venus/config'
import { Text } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import ImageUploading from 'venus/components/ImageUploading'
import { useEffect } from 'react'
import { reset } from 'venus/redux/features/image/image'

const UploadContainer = styled.button`
  margin-top: 30px;
  cursor: pointer;
  border: 0;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
`

const Upload = ({ onChange }) => {
  const { url, progress, image } = useAppSelector((state) => state.image)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (url) {
      onChange(url)
      dispatch(reset())
    }
  }, [url])

  const handleSubmit = () => onChange(url)

  if (image) {
    return (
      <>
        <ImageUploading
          url={url}
          image={image}
          progress={progress}
          style={{ backgroundSize: 'contain' }}
        />
        {/* <TouchButton
            isLoading={loading}
            onPress={handleSubmit}
            backgroundColor={theme.colors.Primary}
            color={theme.colors.White}
            text='Submit'
          /> */}
      </>
    )
  }

  return (
    <>
      <ImageUploader name='coverImage'>
        <UploadContainer>
          <UploadIcon width={90} height={90} color={theme.colors.BlueGrey} />
          <Text
            format='h3'
            style={{ margin: 65, textAlign: 'center' }}
            value='Images wider than 1500 pixels work best 5 MB maximum file size '
            color={theme.colors.BlueGrey}
          />
        </UploadContainer>
      </ImageUploader>
      {/* ,
      <UploadContainer onClick={pickImage}>
        <UploadIcon width={90} height={90} color={theme.colors.BlueGrey} />
        <Typography
          styles={{ margin: 65, textAlign: 'center' }}
          text='Images wider than 1500 pixels work best 5 MB maximum file size '
          fontWeight='semiBold'
          fontSize={12}
          color={theme.colors.BlueGrey}
        />
      </UploadContainer> */}
    </>
  )
}

export default Upload
