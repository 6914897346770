import styled from 'styled-components'

export const ImageArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin: 0 24px 6px 24px;
`

export const ImageView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 36px;
`

export const ImageWrapper = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 190px;
  height: 146px;
  border-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

export const ImageIndexView = styled.div`
  display: flex;
  width: 54px;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  position: absolute;
  bottom: 50px;
  right: 50px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
`

export const FooterBtnArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const UploadContainer = styled.button`
  margin-top: 30px;
  cursor: pointer;
  border: 0;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  width: 200px;
`
