import React from 'react'
import { ReactElement } from 'react'
import { DropBack } from './Drawer.styles'
import { ChildrenWrapper } from './Drawer.styles'
import { CloseButton, DrawerContainer } from './Drawer.styles'
import { ReactComponent as Cross } from 'venus/assets/img/icons/Cross.svg'

const Drawer = ({
  isOpen = false,
  onClose = () => {},
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement | string;
}) => {
  return (
    <>
      {isOpen && (
        <DropBack onClick={onClose}/>
      )}
      <DrawerContainer isOpen={isOpen}>
        <CloseButton onClick={onClose}>
          <Cross/>
        </CloseButton>
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      </DrawerContainer>
    </>
  )
}

export default Drawer
