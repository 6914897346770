import { useEffect, useState } from 'react'
import api from 'venus/api'
import ImageUploader from 'venus/common/ImageUploader'
import Video from './Video'
import { VideoList, VideosContainer } from './Videos.styles'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { Button, EmptyData } from 'venus/components'
import VideoUploader from 'venus/common/VideoUploader'
import { useAppSelector } from 'venus/redux/hooks'
import UploadingVideo from './UploadingVideo'

const Videos = ({
  updateUrl,
  update,
  psiId,
  propertyId,
  dreamPropertyId,
  shareId,
  setTabButtons,
}) => {
  const [videos, setVideos] = useState([])
  const { videos: updated, progress, video } = useAppSelector((state) => state.video)

  const isShared = !!shareId || !!dreamPropertyId

  useEffect(() => {
    const fetchVideo = async () => {
      const videoList = await api
        .get(`/property/${propertyId}/video`, {
          params: { dreamPropertyId, shareId },
        })
        .then((res) => res.data)
      setVideos(videoList)
    }

    fetchVideo()

    return () => {
      setTabButtons(<></>)
    }
  }, [propertyId, shareId, dreamPropertyId])

  useEffect(() => {
    if (!shareId && !dreamPropertyId && !videos.length) {
      setTabButtons(
        <VideoUploader propertyId={propertyId}>
          {/* @ts-ignore */}
          <Button
            type='icon_button'
            text='Add Video'
            iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
            style={{ width: 'auto' }}
          />
        </VideoUploader>,
      )
    } else {
      setTabButtons(<></>)
    }
  }, [shareId, dreamPropertyId, propertyId, videos])

  useEffect(() => {
    if (updated.length) {
      setVideos(updated)
    }
  }, [updated])

  const handleDelete = async (id: string) => {
    const updatedVideos = await api
      .delete(`/property/${propertyId}/video/${id}`)
      .then((res) => res.data)

    setVideos(updatedVideos)
  }

  console.log('video', videos, !!video, progress)

  return (
    <VideosContainer>
      {videos.length === 0 && !progress && (
        <EmptyData
          fullBlock
          message={
            isShared ? '' : 'To add video, please click on the add video button above.'
          }
        />
      )}
      <VideoList>
        {!!progress && <UploadingVideo video={video} progress={progress} />}
        {videos.map((video) => (
          <Video
            key={video.id}
            {...video}
            isShared={shareId || dreamPropertyId}
            handleDelete={handleDelete}
          />
        ))}
      </VideoList>
    </VideosContainer>
  )
}

export default Videos
