import { useForm, Controller } from 'react-hook-form'
import { Button, Input, LinkButton, Select, Text } from 'venus/components'
import { States } from 'venus/config'
import { PRIVACY_POLICY_LINK_TEXT } from 'venus/pages/PrivacyPolicy/PrivacyPolicy'
import { TERMS_OF_SERVICE_LINK_TEXT } from 'venus/pages/TermsAndConditions/TermsAndConditions'
import { Row, TnCSection, FormWrapper, FixedButtonArea } from './styles'
import { theme } from 'venus/config'
import { LABELS } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Company/Constants'
import { ERR_REQUIRED_FIELD } from 'venus/layout/ProfileMenu/ProfileSettings/Constants'

type CompanyInfoForm = {
  companyName?: string
  companyABN?: string
  companyAddress?: string
  companySuburb?: string
  companyState?: string
  companyPhone?: string
  companyEmail?: string
  companyWebsite?: string
  licenseNo?: string
}

const CompanyInfo = ({ onSubmit }: { onSubmit: (data: CompanyInfoForm) => void }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyInfoForm>({})

  return (
    <>
      <FormWrapper>
        <Row>
          <Controller
            name='companyName'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.COMPANY_NAME}
                  value={value || ''}
                  onChange={onChange}
                />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            name='companyABN'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input label={LABELS.COMPANY_ABN} value={value || ''} onChange={onChange} />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            name='companyAddress'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.COMPANY_ADDRESS}
                  value={value || ''}
                  onChange={onChange}
                />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            rules={{ required: true }}
            name='companySuburb'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.COMPANY_SUBURB}
                  value={value || ''}
                  onChange={onChange}
                  isError={!!errors.companySuburb}
                  errorMsg={ERR_REQUIRED_FIELD}
                />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            rules={{ required: true }}
            name='companyState'
            control={control}
            render={({ field }) => (
              <Select
                title='State'
                options={States}
                {...field}
                isError={!!errors.companyState}
                errorMsg={ERR_REQUIRED_FIELD}
              />
            )}
          />
        </Row>
        <Row>
          <Controller
            name='companyPhone'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.COMPANY_PHONE}
                  value={value || ''}
                  onChange={onChange}
                />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            name='companyEmail'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.COMPANY_EMAIL}
                  value={value || ''}
                  onChange={onChange}
                  style={{
                    marginBottom: '18px',
                  }}
                />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            name='companyWebsite'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.COMPANY_WEBSITE}
                  value={value || ''}
                  onChange={onChange}
                />
              )
            }}
          />
        </Row>
        <Row>
          <Controller
            name='licenseNo'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Input
                  label={LABELS.LICENSE_NUMBER}
                  value={value || ''}
                  onChange={onChange}
                  style={{
                    marginBottom: '18px',
                  }}
                />
              )
            }}
          />
        </Row>
      </FormWrapper>
      <FixedButtonArea>
        <Row>
          <Button
            isSubmit
            style={{ margin: '10px 0' }}
            type='primary'
            text='Next'
            onClick={handleSubmit(onSubmit)}
          />
        </Row>
        <TnCSection>
          <Text format='body' style={{ textAlign: 'center' }}>
            By creating an account, I accept MyHomeVault's
          </Text>
          <Text
            format='body'
            style={{
              marginTop: '4px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LinkButton
              color={theme.colors.Secondary}
              format='body'
              to='/terms-and-conditions'
              label={TERMS_OF_SERVICE_LINK_TEXT}
            />
            <div style={{ margin: '0 4px' }}>and</div>
            <LinkButton
              color={theme.colors.Secondary}
              format='body'
              to='/privacy-policy'
              label={PRIVACY_POLICY_LINK_TEXT}
            />
          </Text>
        </TnCSection>
      </FixedButtonArea>
    </>
  )
}

export default CompanyInfo
