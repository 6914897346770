import React from 'react'
import { Text } from 'venus/components'
import { AssignSpaceImages } from 'venus/pages/Onboarding/AssignSpaceImages/AssignSpaceImages'

export const AssignSpaceImagesSample = ({ index, title }: { index: number; title: string }) => {
  // const test_onboard_images = JSON.parse(`[{"url":"https://images.realestateworld.com.au/photos/05/800881305.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_1.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_2.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_3.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_4.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_5.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_6.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_7.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_8.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_9.jpg","isArchived":false,"type":"","name":""},{"url":"https://images.realestateworld.com.au/photos/05/800881305_10.jpg","isArchived":false,"type":"","name":""}]`)
  // const test_spaces = JSON.parse(`[{"id":"8b4c6d2b-3381-48fa-bf0d-45690d98379f","name":"Master Bedroom","type":"BED","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"3bd49386-2bc2-4244-a454-09be4723ce3b","name":"Bedroom 2","type":"BED","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"1561f963-d70e-4faf-bf6c-d743918971ac","name":"Bedroom 3","type":"BED","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"cebf6286-4025-483a-b2e4-7870ad711bd3","name":"Bath Room 1","type":"BATH","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"985514c5-8778-4502-93c4-82328d5d1026","name":"Bath Room 2","type":"BATH","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"8c614741-b951-42d2-8020-8afa803546d5","name":"Living Room","type":"LIVING","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"66c1be87-229c-4c41-a45f-023b23130438","name":"Kitchen","type":"KITCHEN","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]},{"id":"4071b7f3-4722-47e6-8e36-81d213f56e53","name":"Garage","type":"GARAGE","isPropertyWideSpace":false,"propertyId":"68178591-b941-46ed-a9e7-db337e32dd5a","coverImage":null,"isArchived":false,"images":[]}]`)

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <AssignSpaceImages setStep={() => {}} setTitle={() => {}}/>
    </>
  )
}
