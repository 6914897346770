import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'

export const createReminder = createAsyncThunk(
  'Share/createReminder',
  async (
    {
      propertyId,
      body,
    }: {
      propertyId: string
      body: {
        spaceId: string
        itemId?: string
        eventName: string
        dueDateTime: string
        status: string
        notes: string
      }
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(`/property/${propertyId}/reminders`, body)
      console.log('createReminder::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getReminderList = createAsyncThunk(
  'Share/getReminderList',
  async (propertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/property/${propertyId}/reminders`)
      console.log('getReminderList::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getReminderDetail = createAsyncThunk(
  'Share/getReminderDetail',
  async (
    {
      propertyId,
      reminderId,
    }: {
      propertyId: string
      reminderId: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.get(`/property/${propertyId}/reminder/${reminderId}`)
      console.log('getReminderDetail::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateReminder = createAsyncThunk(
  'Share/updateReminder',
  async (
    {
      propertyId,
      reminderId,
      body,
    }: {
      propertyId: string
      reminderId: string
      body: {
        spaceId: string
        itemId?: string
        eventName: string
        dueDateTime: string
        status: string
        notes: string
      }
    },
    { rejectWithValue },
  ) => {
    try {
      console.log('updateReminder url::', `/property/${propertyId}/reminder/${reminderId}`)
      console.log('updateReminder body::', body)
      const response = await api.put(`/property/${propertyId}/reminder/${reminderId}`, body)
      console.log('updateReminder::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteReminder = createAsyncThunk(
  'Share/deleteReminder',
  async (
    {
      propertyId,
      reminderId,
    }: {
      propertyId: string
      reminderId: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.delete(`/property/${propertyId}/reminder/${reminderId}`)
      console.log('delete reminder response.data::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
