import { theme } from 'venus/config'
import { Text } from '../Text'
import { CheckboxBase, CheckboxArea } from './ToggleButton.styles'
import { ReactComponent as TurnOff } from 'venus/assets/img/icons/TurnOff.svg'
import { ReactComponent as TurnOn } from 'venus/assets/img/icons/TurnOn.svg'

interface IToggleButton {
  text: string
  checked: boolean
  setChecked: (b: boolean) => void
  isDisabled?: boolean
}

export const ToggleButton = ({
  text,
  checked,
  setChecked,
  isDisabled = false,
}: IToggleButton) => {
  const handlePress = () => {
    setChecked(!checked)
  }

  if (isDisabled) {
    return (
      <CheckboxArea>
        <Text
          style={{ textAlign: 'center' }}
          value={text}
          format='body'
          color={theme.colors.Black}
        />
        <TurnOff color={theme.colors.LighterGrey} />
      </CheckboxArea>
    )
  }

  return (
    <CheckboxArea>
      <Text
        style={{ textAlign: 'center' }}
        value={text}
        format='body'
        color={theme.colors.Black}
      />
      <CheckboxBase onClick={handlePress}>
        {checked && <TurnOn color={theme.colors.DarkGrey} />}
        {!checked && <TurnOff color={theme.colors.DarkGrey} />}
      </CheckboxBase>
    </CheckboxArea>
  )
}
