import api from 'venus/api'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SignUpLayout from 'venus/layout/SignUp'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { checkExistingEmail } from 'venus/redux/features/user'
import { GeneralInfo } from './SignUpForm'
import CompanyInfo from './SignUpForm/CompanyInfo'
import { SignUpSteps } from 'venus/layout/SignUp/Constants'
import { ENTER_REFERRAL_CODE_TITLE } from 'venus/layout/SignUp/Constants'
import { CREATE_ACCOUNT_TITLE, COMPANY_DETAILS } from 'venus/layout/SignUp/Constants'
import { ReferralCode } from 'venus/layout/SignUp/Referral'
import { setAuthToken } from 'venus/auth/auth'
import EnterMobile from 'venus/layout/SignUp/MobileValidation/EnterMobile'
import MobileValidation from 'venus/layout/SignUp/MobileValidation/MobileValidation'
import { ENTER_MOBILE_NUMBER_TITLE } from 'venus/layout/SignUp/Constants'
import { ENTER_MOBILE_CODE_TITLE } from 'venus/layout/SignUp/Constants'
import { getErrorMsg } from 'venus/utils'

const SignUp = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(SignUpSteps.ENTER_GENERAL_INFO)
  const [user, setUser] = useState<any>({})
  const [error, setError] = useState('')
  const { isAllowedToShareContactInfo, isAllowedToSharePropertyInfo } = useAppSelector(
    (state) => state.user,
  )

  const goBack = () => {
    navigate(-1)
  }

  const handleGeneralInfo = async (info: any) => {
    const data = await dispatch(checkExistingEmail(info.email))
    if (data.meta.requestStatus === 'fulfilled') {
      setUser({
        ...info,
        role: info?.role ? [info?.role] : [],
        tradespeopleRole: info?.tradespeopleRole ? [info?.tradespeopleRole] : [],
      })
      setStep((step) => step + 1)
    }
  }

  const handleCompanyInfo = async (info: any) => {
    const data = { ...user, ...info }
    setUser(data)
    setStep((step) => step + 1)
  }

  const processSignUp = async (mobileValidationCode) => {
    try {
      const result = await api.post(
        '/auth/sign-up',
        {
          ...user,
          mobileValidationCode,
          profile: {
            isAllowedToShareContactInfo,
            isAllowedToSharePropertyInfo,
          },
        },
        {
          withCredentials: true,
        },
      )
      // trigger log in
      const { accessToken } = result.data
      setAuthToken(accessToken)
      navigate('/', { replace: true, state: { isOpenAddProperty: '1' } })
    } catch (err) {
      const errors = getErrorMsg(err.data)
      if (Array.isArray(errors)) {
        setError(errors[0].msg)
      } else {
        setError(errors)
      }
    }
  }

  const handlPressCloseErrorMsg = () => {
    setError('')
  }

  switch (step) {
    case SignUpSteps.ENTER_GENERAL_INFO:
      return (
        <SignUpLayout title={CREATE_ACCOUNT_TITLE} onBack={goBack}>
          <GeneralInfo onSubmit={handleGeneralInfo} />
        </SignUpLayout>
      )
    case SignUpSteps.ENTER_COMPANY_INFO:
      if (user?.role[0] !== 'OWNER') {
        return (
          <SignUpLayout
            title={COMPANY_DETAILS}
            onSkip={
              user?.role[0] !== 'TRADESPEOPLE'
                ? () => setStep(SignUpSteps.ENTER_REFERRAL_CODE)
                : undefined
            }
            onBack={() => {
              setStep(SignUpSteps.ENTER_GENERAL_INFO)
            }}
          >
            <CompanyInfo onSubmit={handleCompanyInfo} />
          </SignUpLayout>
        )
      } else {
        setStep(SignUpSteps.ENTER_REFERRAL_CODE)
        break
      }
    case SignUpSteps.ENTER_REFERRAL_CODE:
      return (
        <SignUpLayout
          title={ENTER_REFERRAL_CODE_TITLE}
          onSkip={() => {
            setStep(SignUpSteps.ENTER_MOBILE)
          }}
          onBack={() => {
            if (user?.role[0] !== 'OWNER') {
              setStep(SignUpSteps.ENTER_COMPANY_INFO)
            } else {
              setStep(SignUpSteps.ENTER_GENERAL_INFO)
            }
          }}
        >
          <ReferralCode setStep={setStep} setUser={setUser} />
        </SignUpLayout>
      )
    case SignUpSteps.ENTER_MOBILE:
      return (
        <SignUpLayout
          title={ENTER_MOBILE_NUMBER_TITLE}
          onBack={() => setStep(SignUpSteps.ENTER_REFERRAL_CODE)}
        >
          <EnterMobile setStep={setStep} setUser={setUser} />
        </SignUpLayout>
      )
    case SignUpSteps.ENTER_VALIDATION_CODE:
      return (
        <SignUpLayout
          title={ENTER_MOBILE_CODE_TITLE}
          onBack={() => setStep(SignUpSteps.ENTER_MOBILE)}
          error={error}
          handlPressCloseErrorMsg={handlPressCloseErrorMsg}
        >
          <MobileValidation mobile={user?.mobile} onSuccess={processSignUp} />
        </SignUpLayout>
      )
    default:
      break
  }
}

export default SignUp
