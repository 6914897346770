import { useState, useEffect } from 'react'
import ImageUploader from 'venus/common/ImageUploader'
import {
  createHomeImagesForNonNpdProperty,
  setUploadNonNpdImagesDone,
} from 'venus/redux/features/onboarding'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { ReactComponent as UploadIcon } from 'venus/assets/img/icons/Upload.svg'
import { Button, Text, Image } from 'venus/components'
import { Wrapper, FooterBtnArea, UploadContainer } from './UploadNonNpdImages.styles'
import { ScrollableList, FileListWrapper } from '../UploadDocs/UploadDocs.styles'
import { theme } from 'venus/config'

interface IUploadNonNpdImages {
  setStep: (s: number) => void
  setTitle: (s: string) => void
}

export const UploadNonNpdImages = ({ setStep, setTitle }: IUploadNonNpdImages) => {
  const dispatch = useAppDispatch()
  const { preview } = useAppSelector((state) => state.address)
  const { image } = preview[0]

  const [images, setImages] = useState([])
  const { url, progress } = useAppSelector((state) => state.image)
  console.log('progress::', progress)

  useEffect(() => {
    if (!!url) {
      setImages([...images, url])
    }
  }, [url])

  const handlePressDone = () => {
    const imageObj = images.map((url) => ({ url }))
    if (!!image) imageObj.push({ url: image })
    dispatch(createHomeImagesForNonNpdProperty(imageObj))
    dispatch(setUploadNonNpdImagesDone(true))
    setStep(5)
    setTitle('Images')
  }

  return (
    <Wrapper>
      <Text
        style={{ marginBottom: 24, alignSelf: 'center' }}
        format='body'
        value='Upload images'
      />
      <ScrollableList>
        <FileListWrapper
          style={{
            justifyContent: images.length === 0 ? 'center' : 'flex-start',
            alignItems: images.length === 0 ? 'center' : 'flex-start',
          }}
        >
          {images.length > 0 &&
            images.map((uri) => {
              return (
                <Image
                  key={uri}
                  image={uri}
                  style={{
                    width: '190px',
                    height: '146px',
                    aspectRatio: 1.3,
                    marginRight: '12px',
                  }}
                />
              )
            })}
          <ImageUploader>
            <UploadContainer>
              <UploadIcon width={90} height={90} color={theme.colors.BlueGrey} />
            </UploadContainer>
          </ImageUploader>
        </FileListWrapper>
      </ScrollableList>
      <FooterBtnArea>
        <Button
          onClick={handlePressDone}
          text='Continue'
          isLoading={progress !== 0 && progress !== 100}
          style={{
            height: '36px',
            padding: '4px 28px',
            width: 'auto',
            marginBottom: 48,
          }}
        />
      </FooterBtnArea>
    </Wrapper>
  )
}
