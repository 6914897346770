import { Placeholder, Text } from 'venus/components'

export const PlaceholderSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Placeholder height={200} />
    </>
  )
}
