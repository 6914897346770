import React from 'react'
import { useEffect } from 'react'
import { theme } from 'venus/config'
import { AverageRating } from 'venus/components'
import { getHistoryNames } from 'venus/utils'
import PillTag from 'venus/components/Pill/PillTag'
import { AvatarCard } from 'venus/components/AvatarCard'
import { ITradespeople } from 'venus/redux/features/tradespeople/types'
import { CardViewContainer } from 'venus/pages/Tradespeople/TradespeopleCardView/TradespeopleCardView.styles'
import { CardBox } from 'venus/pages/Tradespeople/TradespeopleCardView/TradespeopleCardView.styles'
import { RatingBox } from 'venus/pages/Tradespeople/TradespeopleCardView/TradespeopleCardView.styles'
import { TagBox } from 'venus/pages/Tradespeople/TradespeopleCardView/TradespeopleCardView.styles'
import {
  SearchTradesPeopleContainer
} from 'venus/pages/Tradespeople/TradespeopleCardView/SearchTradespeopleCard.styles'

const TradespeopleCardView = ({
  data,
  type,
  onClick,
  setSelectedContact,
  isSearchResult,
  style,
}: {
  data: ITradespeople;
  type: string;
  onClick: Function,
  setSelectedContact: Function
  isSearchResult: boolean;
  style?: any;
}) => {
  const [spaceTags, setSpaceTags] = React.useState([])
  const [itemTags, setItemTags] = React.useState([])

  useEffect(() => {
    if (!data || !data.histories) return
    const { spaceTags, itemTags } = getHistoryNames(data.histories, type)
    const spaceTagList = spaceTags?.map((name, index) => {
      return {
        id: String(index),
        name: name,
      }
    })
    setSpaceTags(spaceTagList)
    const itemTagList = itemTags?.map((name, index) => {
      return {
        id: String(index),
        name: name,
      }
    })
    setItemTags(itemTagList)
  }, [data])

  const handleOnClick = () => {
    setSelectedContact(data)
    onClick()
  }

  if (isSearchResult) {
    return (
      <SearchTradesPeopleContainer onClick={handleOnClick} style={style}>
        <AvatarCard
          data={data}
          avatarSize={54}
          nameStyle={{ format: 'h2', fontSize: '18px', fontWeight: '600' }}
          showRole={true}
          showRating={true}
          showContactInfo={true}
          showArrow={true}
        />
      </SearchTradesPeopleContainer>
    )
  } else {
    return (
      <CardViewContainer onClick={handleOnClick} style={style}>
        <CardBox flex={1} style={{ marginLeft: '30px' }}>
          <AvatarCard data={data}/>
        </CardBox>
        <RatingBox flex={1}>
          <AverageRating average={String(data?.averageRating) || '0'}/>
        </RatingBox>
        <TagBox>
          {spaceTags?.map((tag) => {
            return (
              <PillTag
                key={`spaceTag-${tag.name}`}
                label={tag.name}
                fontSize={12}
                fontWeight={500}
                lineHeight={1}
                paddingHorizontal={13}
                paddingVertical={5}
                style={{ margin: '2px 4px' }}
              />
            )
          })}
          {itemTags?.map((tag) => {
            return (
              <PillTag
                key={`itemTag-${tag.name}`}
                label={tag.name}
                background={theme.colors.LightBlue}
                color={theme.colors.Primary}
                fontSize={12}
                fontWeight={500}
                lineHeight={1}
                paddingHorizontal={13}
                paddingVertical={5}
                style={{ margin: '2px 4px' }}
              />
            )
          })}
        </TagBox>
      </CardViewContainer>
    )
  }
}

export default TradespeopleCardView
