import React from 'react'
import { LinkButton, Text, AlertBox } from 'venus/components'
import { BgWrapper } from '../MHV/MHV.styles'
import { Button, HeaderSection, HeaderSubSection, Panel } from './SignUp.styles'
import { ReactComponent as Back } from 'venus/assets/img/icons/Back.svg'
import { theme } from 'venus/config'

const SignUpLayout = ({
  children,
  title,
  onBack,
  onSkip,
  error = '',
  handlPressCloseErrorMsg = () => null,
}: {
  children: React.ReactNode
  title: string
  onBack?: () => void
  onSkip?: () => void
  error?: string
  handlPressCloseErrorMsg?: () => void
}) => {
  return (
    <BgWrapper
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Panel>
        <HeaderSection>
          <HeaderSubSection>
            {onBack && (
              <Button onClick={onBack}>
                <Back color={theme.colors.Primary} />
              </Button>
            )}
          </HeaderSubSection>

          <Text
            format='h1'
            style={{ textAlign: 'center' }}
            color={theme.colors.Primary}
          >
            {title}
          </Text>
          <HeaderSubSection>
            {onSkip && (
              <LinkButton
                hideUnderline
                format='h3'
                label='Skip'
                handlePress={onSkip}
                color={theme.colors.Primary}
              />
            )}
          </HeaderSubSection>
        </HeaderSection>
        {children}
      </Panel>
      <AlertBox
        title='Sign Up Error'
        message={error}
        cancel={handlPressCloseErrorMsg}
        btnGroup={[
          { text: 'OK', onClick: handlPressCloseErrorMsg, type: 'error' },
        ]}
        showAlert={!!error}
      />
    </BgWrapper>
  )
}

export default SignUpLayout
