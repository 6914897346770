import styled from 'styled-components';
import { theme } from 'venus/config';

interface IStyledValueInput {
  isError: boolean;
}

export const StyledValueInput = styled.input<IStyledValueInput>`
  padding: 12px 30px;
  text-align: left;
  border: ${(props) =>
    `0.5px solid ${
      props.isError ? theme.colors.DangerRed : theme.colors.Grey
    }`};
  color: ${theme.colors.Black};
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;

  &::placeholder {
    color: ${theme.colors.DarkestGrey};
  }

  &:hover {
    border-color: ${(props) =>
      props.isError ? theme.colors.DangerRed : theme.colors.Secondary};
  }
`;

export const PriceInputWrapper = styled.div`
  position: relative;
`;
