import styled from 'styled-components'
import RadioSelect from './RadioSelect'

const Container = styled.div`
  display: flex;
`

interface IRadioGroup {
  style?: any
  value: string
  values: string[]
  onChange: (v: string) => void
  isHorizontal?: boolean
}

export const RadioGroup = ({
  style = {},
  value,
  onChange,
  values,
  isHorizontal,
}: IRadioGroup) => {
  return (
    <Container style={style}>
      {values.map((v: string) => (
        <RadioSelect
          key={v}
          style={{ marginRight: 16 }}
          label={v}
          selected={value === v}
          onSelect={onChange}
        />
      ))}
    </Container>
  )
}

export default RadioGroup
