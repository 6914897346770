import api from 'venus/api'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import ReactCodeInput from 'react-code-input'
import { useForm, Controller } from 'react-hook-form'
import useDebounceAction from 'venus/hooks/useDebounceAction'
import { Header } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/ProfileTabMenu.styles'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { Input, Select, Text, Loader, Checkbox, Notification } from 'venus/components'
import { theme, roles, tradesPeopleSubRolesArr } from 'venus/config'
import {
  PersonalProfilePanel,
  PersonalProfileContainer,
  VerifiedWrapper,
  CheckBoxWrapper,
  ValidationContainer,
  VerifyButton,
  InputWrapper,
  Row,
} from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal/ProfileForm.styles'
import { updateProfile } from 'venus/redux/features/user'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'
import { IPersonalInfo } from 'venus/redux/features/user'
import {
  SHARE_CONTACT_INFO_AGREEMENT_TEXT,
  SHARE_CONTACT_INFO_ACCEPTED_TEXT,
} from 'venus/layout/ProfileMenu/ProfileSettings/Constants'
import { updateProfileSteps } from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile/Personal/Constants'
import {
  RESEND_MOBILE_CODE_MSG,
  ENTER_MOBILE_CODE_MSG,
  RESEND_EMAIL_CODE_MSG,
  VERIFIED_TEXT,
  VERIFY_EMAIL_BUTTON_TEXT,
  VERIFY_MOBILE_BUTTON_TEXT,
  SENT_EMAIL_CODE_MSG,
} from 'venus/layout/SignUp/Constants'
import SettingsHeader from '../../SettingsHeader/SettingsHeader'

const ProfileForm = ({
  setActions,
  step,
  setStep,
}: {
  setActions: (actions: any) => void
  step: number
  setStep: (n: number) => void
}) => {
  const { info, loadingProfile } = useAppSelector((state) => state.user)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    getValues,
    setError,
  } = useForm<IPersonalInfo>({
    defaultValues: {
      firstName: info?.firstName || '',
      lastName: info?.lastName || '',
      email: info?.email || '',
      mobile: info?.mobile || '',
      role: _.get(info, ['role', '0'], ''),
      tradespeopleRole: _.get(info, ['tradespeopleRole', '0'], ''),
      isEmailValidated: info?.isEmailValidated || false,
      isMobileValidated: info?.isMobileValidated || false,
      isAllowedToShareContactInfo: info?.profile?.isAllowedToShareContactInfo || false,
    },
  })
  const role = watch('role')
  const newMobile = watch('mobile')

  const dispatch = useAppDispatch()
  const [validationCode, setValidationCode] = useState('') // validation code sent to user

  const [notificationMsg, setNotificationMsg] = useState('')
  const [showNotification, setNotification] = useState(false)
  const [sentVerifyEmail, setSentVerifyEmail] = useState(false)

  // set buttons depends on step changes
  useEffect(() => {
    switch (step) {
      case updateProfileSteps.GET_FORM:
        setActions([
          {
            type: 'icon_button',
            text: 'Edit',
            onClick: handleOnEdit,
            iconLeft: (
              <EditIcon style={{ marginRight: '9px', color: theme.colors.White }} />
            ),
            style: { width: 'auto' },
          },
        ])
        break
      case updateProfileSteps.EDIT_PROFILE:
        setActions([
          {
            type: 'primary',
            isSubmit: true,
            isLoading: loadingProfile,
            onClick: handleSubmit(handleOnSubmitProfile),
            text: 'Update',
          },
        ])
        break
      case updateProfileSteps.VALIDATE_MOBILE:
        setActions([
          {
            key: 'resend_code_btn',
            type: 'secondary',
            onClick: handleOnResendCode,
            text: 'Re-send code',
          },
          {
            key: 'validate_btn',
            type: 'primary',
            onClick: handleOnValidate,
            text: 'Validate',
          },
        ])
        break
    }
  }, [step, validationCode])

  const handleOnEdit = () => {
    setStep(updateProfileSteps.EDIT_PROFILE)
  }

  const handleVerifyMobile = async () => {
    await api.post(`/auth/send-mobile-code`, {
      mobile: getValues('mobile'),
    })
    setStep(updateProfileSteps.VALIDATE_MOBILE)
  }

  const handleOnSubmitProfile = async (data) => {
    if (!data) return

    const regex = /^[04]{2}[0-9]{8}$/
    const str = data.mobile.toString()
    if (!regex.test(str)) {
      return setError('mobile', {})
    }

    const updateData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      role: [data.role],
      tradespeopleRole: [data.tradespeopleRole],
      profile: {
        ...info.profile,
        isAllowedToShareContactInfo: data.isAllowedToShareContactInfo,
      },
    }
    if (info?.email === updateData?.email) {
      delete updateData['email']
    }
    await dispatch(updateProfile(updateData))
    if (info?.mobile === data.mobile) {
      setStep(updateProfileSteps.GET_FORM)
    } else {
      await handleVerifyMobile()
    }
  }

  const handleOnValidate = async () => {
    if (!validationCode) return

    const response = await api.post(`/auth/validate-mobile`, {
      mobile: newMobile,
      code: validationCode,
    })
    if (response.status === 200) {
      await dispatch(
        updateProfile({
          mobileValidationCode: response?.data?.mobileValidationCode,
        }),
      )
      setStep(updateProfileSteps.GET_FORM)
    }
  }

  const handleOnResendCode = async () => {
    const response = await api.post(`/auth/send-mobile-code`, { mobile: newMobile })
    if (response?.data?.status === 200) {
      setNotificationMsg(RESEND_MOBILE_CODE_MSG)
      setNotification(true)
    }
  }

  const debounce = useDebounceAction()
  const handleOnClickVerifyEmail = async () => {
    if (sentVerifyEmail) return

    const response = await api.post('/auth/resend-email-link', { email: info?.email })
    if (response?.data === 'OK') {
      setNotificationMsg(RESEND_EMAIL_CODE_MSG)
      setNotification(true)
      setSentVerifyEmail(true)
    }
  }
  const handleVerifyEmail = () => debounce(handleOnClickVerifyEmail)

  const handleCloseNotification = () => {
    setNotification(false)
  }

  if (loadingProfile) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  const verifiedText = (
    <Text
      format={'body'}
      value={VERIFIED_TEXT}
      fontWeight={500}
      lineHeight={'21px'}
      color={theme.colors.Secondary}
    />
  )

  let bodyComponent = <></>
  switch (step) {
    case updateProfileSteps.GET_FORM:
      bodyComponent = (
        <PersonalProfileContainer>
          <PersonalProfilePanel>
            <Text
              format={'h2'}
              value={'First Name'}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
            <Text
              format={'body'}
              value={info?.firstName}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
            <Text
              format={'h2'}
              value={'Last Name'}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
            <Text
              format={'body'}
              value={info?.lastName}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
            <Text format={'h2'} value={'Email'} style={{ textAlign: 'left' }} />
            <VerifiedWrapper>
              <Text format={'body'} value={info?.email} />
              {!info?.isEmailValidated ? (
                <VerifyButton onClick={handleVerifyEmail}>
                  <Text
                    format={'body'}
                    value={
                      !sentVerifyEmail ? VERIFY_EMAIL_BUTTON_TEXT : SENT_EMAIL_CODE_MSG
                    }
                    fontWeight={500}
                    lineHeight={'21px'}
                    color={
                      !sentVerifyEmail ? theme.colors.DangerRed : theme.colors.Secondary
                    }
                  />
                </VerifyButton>
              ) : (
                verifiedText
              )}
            </VerifiedWrapper>
            <Text format={'h2'} value={'Mobile'} style={{ textAlign: 'left' }} />
            <VerifiedWrapper>
              <Text format={'body'} value={info?.mobile} />
              {!info?.isMobileValidated && (
                <VerifyButton onClick={handleVerifyMobile}>
                  <Text
                    format={'body'}
                    value={VERIFY_MOBILE_BUTTON_TEXT}
                    fontWeight={500}
                    lineHeight={'21px'}
                    color={theme.colors.Secondary}
                  />
                </VerifyButton>
              )}
              {info?.isMobileValidated && verifiedText}
            </VerifiedWrapper>
            <Text
              format={'h2'}
              value={'Role'}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
            <Text
              format={'body'}
              value={_.get(info, 'role[0]', '').toLowerCase()}
              style={{
                textAlign: 'left',
                marginBottom: '18px',
                textTransform: 'capitalize',
              }}
            />
            {role === 'TRADESPEOPLE' && (
              <>
                <Text
                  format={'h2'}
                  value={'Tradespeople Role'}
                  style={{ textAlign: 'left', marginBottom: '18px' }}
                />
                <Text
                  format={'body'}
                  value={info?.tradespeopleRole[0].toLowerCase()}
                  style={{
                    textAlign: 'left',
                    marginBottom: '18px',
                    textTransform: 'capitalize',
                  }}
                />
                {!!info?.profile?.isAllowedToShareContactInfo && (
                  <Text
                    format={'body'}
                    value={SHARE_CONTACT_INFO_ACCEPTED_TEXT}
                    style={{ textAlign: 'left', marginBottom: '18px' }}
                  />
                )}
              </>
            )}
          </PersonalProfilePanel>
        </PersonalProfileContainer>
      )
      break
    case updateProfileSteps.EDIT_PROFILE:
      bodyComponent = (
        <>
          <PersonalProfileContainer>
            <form>
              <PersonalProfilePanel>
                <Controller
                  rules={{
                    required: true,
                  }}
                  name='firstName'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label='First Name'
                        value={value}
                        onChange={onChange}
                        isError={!!errors.firstName}
                        errorMsg='First name required.'
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label='Last Name'
                        value={value}
                        onChange={onChange}
                        isError={!!errors.lastName}
                        errorMsg='Last name required.'
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label='Email'
                        value={value}
                        onChange={onChange}
                        isError={!!errors.email}
                        errorMsg='Email required.'
                        style={{
                          marginBottom: '18px',
                        }}
                      />
                    )
                  }}
                />
                <Controller
                  name='mobile'
                  control={control}
                  rules={{
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputWrapper>
                        <Input
                          label='Mobile'
                          value={value || '04'}
                          maxLength={10}
                          onChange={(value) => {
                            onChange(value)
                          }}
                          isError={!!errors.mobile}
                          errorMsg='Invalid mobile number.'
                          style={{
                            marginBottom: '18px',
                          }}
                        />
                      </InputWrapper>
                    )
                  }}
                />
                <Row>
                  <Controller
                    name='role'
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          title='Role'
                          value={value}
                          onChange={onChange}
                          options={roles}
                          isError={!!errors.role}
                          errorMsg='Role required.'
                          style={{
                            marginBottom: '18px',
                          }}
                        />
                      )
                    }}
                  />
                </Row>
                {role === 'TRADESPEOPLE' && (
                  <>
                    <Controller
                      name='tradespeopleRole'
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            title='Tradespeople Role'
                            value={value}
                            options={tradesPeopleSubRolesArr}
                            onChange={onChange}
                            style={{
                              marginBottom: '18px',
                            }}
                          />
                        )
                      }}
                    />
                    <CheckBoxWrapper>
                      <Controller
                        name='isAllowedToShareContactInfo'
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return <Checkbox checked={value} onChange={onChange} />
                        }}
                      />
                      <Text format={'body'} value={SHARE_CONTACT_INFO_AGREEMENT_TEXT} />
                    </CheckBoxWrapper>
                  </>
                )}
              </PersonalProfilePanel>
            </form>
          </PersonalProfileContainer>
        </>
      )
      break
    case updateProfileSteps.VALIDATE_MOBILE:
      bodyComponent = (
        <ValidationContainer>
          <Text
            format={'body'}
            value={`${ENTER_MOBILE_CODE_MSG} ${newMobile}`}
            style={{ marginBottom: '42px' }}
          />
          <ReactCodeInput
            type='number'
            fields={4}
            name='mobile'
            inputMode='numeric'
            value={validationCode}
            onChange={setValidationCode}
            inputStyle={{
              borderRadius: '6px',
              border: '1px solid lightgrey',
              boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
              margin: '6px',
              paddingLeft: '12px',
              width: '48px',
              height: '56px',
              fontSize: '32px',
              boxSizing: 'border-box',
              color: 'black',
              backgroundColor: 'white',
            }}
          />
        </ValidationContainer>
      )
      break
    default:
      break
  }

  return (
    <>
      <Notification
        showAlert={!!notificationMsg.length && showNotification}
        closeAlert={handleCloseNotification}
        message={notificationMsg}
      />
      {bodyComponent}
    </>
  )
}

export default ProfileForm
