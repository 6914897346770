import styled from 'styled-components'
import { theme } from 'venus/config'

export const ToggleBtnArea = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
`

export const QRCodeArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UploadImageArea = styled.div`
  margin: 20px 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  border-color: ${theme.colors.LighterGrey};
  border-width: 1px;
  border-radius: 4px;
`

export const PhotosContainer = styled.div`
  margin: 20px 2px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  border-color: ${theme.colors.LighterGrey};
  border-width: 1px;
  border-radius: 4px;
  overflow: hidden;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 24px 0 24px;
  width: 100%;
  position: absolute;
  bottom: 12px;
  border-top: 1px solid #e8eaee;
`
