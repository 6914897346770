import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

export const ItemContainer = styled.div`
  cursor: pointer;
  height: 98px;
  border-radius: 12px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 14px;
  :hover {
    background-color: ${(props) => props.theme.colors.LightBlue};
  }
`

type CoverImageProps = {
  readonly image?: string
}

export const CoverImage = styled.div<CoverImageProps>`
  height: 100%;
  aspect-ratio: 1.58;
  border-radius: 8px;
  overflow: hidden;

  background: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;

  background-color: ${(props) => props.theme.colors.Grey};
`

export const Address = styled.div`
  flex: 1;
`

type RadioProps = {
  readonly isSelected: boolean
}
export const Radio = styled.div<RadioProps>`
  margin-left: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  border: 1px solid
    ${(props) =>
      props.isSelected ? props.theme.colors.Secondary : props.theme.colors.DarkGrey};

  background-color: ${(props) => (props.isSelected ? 'rgba(73, 195, 202, 0.4)' : '')};
`

export const TagDisplay = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`
