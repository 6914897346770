import _ from 'lodash'
import React from 'react'
import { theme } from 'venus/config'
import { Text, PillTag } from 'venus/components'
import { AvatarCard } from 'venus/components/AvatarCard'
import { IContact } from 'venus/redux/features/history/types'
import {
  CardViewContainer,
  NameCard,
  DateCard,
  TagCard,
  CardBox,
} from 'venus/pages/History/HistoryCardView/HistoryCardView.styles'

export const HistoryCardView = ({
    data,
    onClick
  }: {
    data: any,
    onClick?: (historyId: string) => void,
  }
) => {
  const contact = _.get(data, ['contacts', '0'], {}) as IContact
  const tags = _.get(data, 'tags', []) as string[]
  const tagPills = _.take(tags, 5) as string[]

  const handleOnClick = () => {
    onClick(data.id)
  }

  return (
    <CardViewContainer onClick={handleOnClick}>
      <CardBox flex={1}>
        <DateCard>
          <Text
            format={'body'}
            lineHeight={'21px'}
            color={theme.colors.DarkestGrey}
            value={data?.eventDate}>
          </Text>
        </DateCard>
      </CardBox>
      <CardBox flex={4}>
        <NameCard>
          <Text
            format={'h3'}
            fontSize={16}
            color={theme.colors.Black}
            fontWeight={500}
            lineHeight={'24px'}
            value={data?.eventName}>
          </Text>
          <Text
            format={'h4'}
            color={theme.colors.DarkestGrey}
            fontWeight={400}
            lineHeight={'21px'}
            value={data?.notes}
            style={{
              maxWidth: '580px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
          </Text>
        </NameCard>
      </CardBox>
      <CardBox flex={2}>
        <TagCard>
          {tagPills.map((tag, index) => {
            if (index === 0) {
              return (
                <PillTag
                  key={index}
                  format="h4"
                  label={tag}
                  style={{ margin: '2px 4px' }}
                />
              )
            }
            return (
              <PillTag
                key={index}
                format="h4"
                label={tag}
                background={theme.colors.LighterGrey}
                color={theme.colors.Black}
                style={{ margin: '2px 4px' }}
              />
            )
          })}
        </TagCard>
      </CardBox>
      <CardBox flex={1}>
        <AvatarCard data={contact} style={{ maxWidth: '380px' }}/>
      </CardBox>
    </CardViewContainer>
  )
}
