import styled from 'styled-components'

export const ToggleBtnArea = styled.div`
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
  margin-top: 12px;
`

export const ToggleBtnWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`

export const BtnArea = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 36px;
  margin-top: 24px;
  border-top: 1px solid #e8eaee;
  border-radius: 8px;
`

export const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 18px 0;
  height: 360px;
`
