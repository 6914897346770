import { MultiItemSelect, Text } from 'venus/components'

export const MultiItemSelectSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <MultiItemSelect
        spaceLabel={'Kitchen'}
        items={[
          {
            name: 'K1',
            type: 'T1',
          },
          {
            name: 'K2',
            type: 'T2',
          },
          {
            name: 'K3',
            type: 'T3',
          },
          {
            name: 'K4',
            type: 'T4',
          },
          {
            name: 'K5',
            type: 'T5',
          },
          {
            name: 'K6',
            type: 'T6',
          },
          {
            name: 'K7',
            type: 'T7',
          },
        ]}
        handleCheck={() => null}
      />
    </>
  )
}
