import styled from 'styled-components'

export const ImagePreviewContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 150px;

  display: flex;
  align-items: center;
`

type PredictionsContainerProps = {
  readonly width: number
  readonly height: number
}

export const PredictionsContainer = styled.div<PredictionsContainerProps>`
  position: absolute;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`

type ImagePreviewContainerProps = {
  readonly image: string
}
export const Image = styled.div<ImagePreviewContainerProps>`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseButton = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  position: absolute;
  top: 42px;
  right: 42px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.White};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CircleButton = styled.button`
  cursor: pointer;

  background: none;
  border: 0;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.White};
  color: ${(props) => props.theme.colors.White};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const BottomActions = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    margin: 0 10px;
  }
`
