import styled from 'styled-components'
import { theme } from 'venus/config'

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const SalePricePanel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  height: 286px;
  border: 1px solid ${theme.colors.LighterGrey};
  border-radius: 8px;
  padding: 32px;
  position: relative;
`

export const LabelArea = styled.div`
  width: 50%;
  height: 21px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  background-color: ${theme.colors.Primary};
  border-radius: 12px;
  margin-top: 12px;
`

export const Container = styled.div`
  width: 100%;
  padding-bottom: 96px;
`
