import { useState } from 'react'
import { Avatar, Text, EmptyData } from 'venus/components'
import { theme } from 'venus/config'
import { IContactEntry } from 'venus/redux/features/contact'
import { getRoleLabel } from 'venus/utils'
import { ReactComponent as Checked } from 'venus/assets/img/icons/Checked.svg'
import { ReactComponent as UnChecked } from 'venus/assets/img/icons/UnChecked.svg'
import {
  RowWrapper,
  RowLeftPartWrapper,
  RightLeftPartWrapper,
  ListArea,
  RightArea,
} from './SubList.styles'

interface ISubList {
  persons: IContactEntry[]
  handlePress: Function
  displaySelectIndicator?: boolean
  selectedId?: string
}

export const SubList = ({
  persons,
  handlePress,
  displaySelectIndicator = false,
  selectedId,
}: ISubList) => {
  const [newSelectedId, setSelectedId] = useState(selectedId)

  const handlePressPerson = (person: IContactEntry) => {
    console.log('person::', person)
    setSelectedId(person.id)
    handlePress(person)
  }

  const renderItem = ({ item: person }: { item: IContactEntry }) => {
    const isSelected = person.id === newSelectedId
    return (
      <RowWrapper
        style={{
          backgroundColor: isSelected
            ? theme.colors.LightBlue
            : theme.colors.White,
          width: '100%',
          padding: displaySelectIndicator ? '0 14px' : '0 34px',
          borderRadius: displaySelectIndicator ? '8px' : '0',
        }}
        key={`contact_list_${person.id}`}
        onClick={() => handlePressPerson(person)}
      >
        <RowLeftPartWrapper>
          <Avatar isBorder={false} size={36} avatar={person.profile.avatar} />
          <Text
            value={`${person.firstName} ${person.lastName}${
              person.id === '-1' ? ' (me)' : ''
            }`}
            format='h4'
            color={theme.colors.Black}
            style={{
              marginLeft: 12,
              maxWidth: 140,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          />
        </RowLeftPartWrapper>
        <RightArea>
          <RightLeftPartWrapper
            style={{
              backgroundColor: isSelected
                ? theme.colors.White
                : theme.colors.LightBlue,
            }}
          >
            <Text
              value={getRoleLabel(person.role[0], person.tradespeopleRole)}
              format='h4'
              color={theme.colors.Primary}
            />
          </RightLeftPartWrapper>
          {displaySelectIndicator ? (
            isSelected ? (
              <Checked />
            ) : (
              <UnChecked />
            )
          ) : null}
        </RightArea>
      </RowWrapper>
    )
  }

  const bodyContents = persons.map((item: IContactEntry) => renderItem({ item }))
  return (
    <>
      <ListArea>
        {persons.length === 0 ? (
          <EmptyData
            message={`Click the Add button to add contacts to your vault.`}
          />
        ) : (
          bodyContents
        )}
      </ListArea>
    </>
  )
}
