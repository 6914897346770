import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Text } from 'venus/components'
import useOutsideClicker from 'venus/hooks/useOutsideAlerter'

const Container = styled.ul`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  list-style: none;
  margin: 10px 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.White};
  border: 1px solid ${(props) => props.theme.colors.Grey};
  max-height: 380px;
  overflow-y: auto;
  border-radius: 5px;
`

const OptionContainer = styled.li`
  cursor: pointer;
  white-space: nowrap;
  padding: 4px 12px;

  &:hover {
    background-color: ${(props) => props.theme.colors.LightGrey};
  }
`

const AutoCompleteList = ({
  style = {},
  handleOptionSelect,
  value,
  options,
  onChange,
  close,
}: any) => {
  const container = useRef()
  useOutsideClicker(container, close)

  const handleSelect = (option) => () => {
    handleOptionSelect(option)
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     debugger
  //   }, 3000)
  // }, [])

  return (
    <Container ref={container} style={style}>
      {options.map((option) => (
        <OptionContainer key={option.id || option.label} onClick={handleSelect(option)}>
          <Text fontSize={14} fontWeight={500} format='h4'>
            {option.label}
          </Text>
        </OptionContainer>
      ))}
    </Container>
  )
}

export default AutoCompleteList
