import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getErrorMsg } from 'venus/utils'
import { IAddress, IAddressPreview } from './types'
import { previewAddressData } from './async'

const initialState: IAddress = {
  preview: [{}] as IAddressPreview[],
  error: '',
  loading: false,
  addressId: 0,
}

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setPreview: (state, action) => {
      const { payload } = action
      state.preview = payload
    },
    setError: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.error = payload
    },
    resetError: (state) => {
      state.error = ''
    },
    resetLoading: (state) => {
      state.loading = false
    },
    addAddressId: (state, action: PayloadAction<number>) => {
      const { payload } = action
      state.addressId = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(previewAddressData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(previewAddressData.fulfilled, (state, action) => {
      state.preview = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]
      state.loading = false
    })
    builder.addCase(previewAddressData.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
  },
})

export const { setPreview, setError, resetError, resetLoading, addAddressId } =
  addressSlice.actions

export default addressSlice.reducer
