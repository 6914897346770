export const settingsMenus = [
  "Profile",
  "Security",
  "Transfer Vault",
  "Claim Existing Vault",
  "Referral",
  "Advanced Security",
  "Subscriptions",
];

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 11,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '9px',
    border: 0,
    padding: 0,
    overflow: '',
  },
}

export const ERR_REQUIRED_FIELD = 'This is required.'
export const SHARE_CONTACT_INFO_AGREEMENT_TEXT = 'If you are a service provider you consent to MyHomeVault sharing your contact details with other MyHomeVault registered users.'
export const SHARE_CONTACT_INFO_ACCEPTED_TEXT = 'I consent to MyHomeVault sharing my contact details with other MyHome Vault registered users.'