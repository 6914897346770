import { useNavigate, useParams } from 'react-router-dom'
import PSIPhotos from 'venus/common/PSIPhotos/PSIPhotos'
import { TabView } from 'venus/components'
import { DocumentList } from 'venus/pages/Documents/List'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { IImage } from 'venus/types/Image'
import { isTempUrl } from 'venus/utils/url'
import {
  updatePersonalItem,
  setError,
  removePersonalItem,
} from 'venus/redux/features/personal/reducer'
import ItemDetails from 'venus/pages/Item/ItemDetails'
import PSILayout from 'venus/layout/PSI'
import { useMemo } from 'react'
import { Container } from './PersonalItems.styles'
import api from 'venus/api'

const tabs = ['Details', 'Photos', 'Documents']

const PersonalItem = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const { itemId } = useParams()
  const { items } = useAppSelector((state) => state.personal)

  const item = useMemo(() => {
    return items.find((i) => i.id === itemId)
  }, [itemId, items])

  const formatData = (data: any, images: IImage[]) => {
    if (data.coverImage && isTempUrl(data.coverImage)) {
      return { ...data, images: [...images, { url: data.coverImage }] }
    }
    return data
  }

  const onUpdateSetting = async (data: any) => {
    try {
      const response = await api.put(
        `/personal/item/${item.id}`,
        formatData(data, item.images || []),
      )
      const updatedItem = response.data
      dispatch(updatePersonalItem(updatedItem))
    } catch (err) {
      dispatch(setError(err.data))
    }
  }

  const onDelete = async () => {
    try {
      const response = await api.delete(`/personal/item/${item.id}`)
      // const updatedPersonalItems = response.data

      dispatch(removePersonalItem(item))
      navigate(`/personal`)
      // navigation.pop(2)
    } catch (err) {
      // setIsOpen(false)
      setError(err.data)
    }
  }

  return (
    <PSILayout
      tag='Personal Item'
      title={item?.name}
      subTitle={'Personal'}
      headerBackground={item?.coverImage}
      goBack={goBack}
      setting={{
        name: item?.name,
        coverImage: item?.coverImage,
        images: item?.images,
      }}
      onUpdateSetting={onUpdateSetting}
      onDelete={onDelete}
      isShared={false}
      isDreamProperty={false}
      isPersonal
    >
      <Container>
        <TabView
          tabs={tabs}
          paddingBottom={0}
          style={{ minHeight: 0, overflow: 'auto', paddingTop: 26 }}
        >
          <ItemDetails
            item={item}
            setTabButtons={() => null}
            isPropertyWideSpace
            shareId=''
            dreamPropertyId=''
            isPersonal
          />
          <PSIPhotos
            enableScanHome={false}
            isPersonal
            images={item?.images}
            updateUrl={`/personal/item/${item?.id}`}
            update={updatePersonalItem}
            psiId={{ personalItemId: item?.id }}
            propertyWideSpaceId=''
            isShared={false}
            setTabButtons={() => null}
          />
          <DocumentList type='personalItem' personalItemId={item?.id} />
        </TabView>
      </Container>
    </PSILayout>
  )
}

export default PersonalItem
