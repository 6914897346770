import { ReactComponent as RadioSelected } from 'venus/assets/img/icons/RadioSelected.svg'
import { ReactComponent as RadioUnselected } from 'venus/assets/img/icons/RadioUnselected.svg'
import { theme } from 'venus/config'

const RadioButton = ({
  selected = false,
  color = theme.colors.Primary,
}: {
  selected?: boolean
  color?: string
}) => {
  return selected ? <RadioSelected color={color} /> : <RadioUnselected color={color} />
}

export default RadioButton
