import { useState } from 'react'
import { Text, Button, Drawer } from 'venus/components'

export const DrawerSample = ({ index, title }: { index: number; title: string }) => {
  const [showAlertBox, setShowAlertBox] = useState(false)

  const handleOpenAlertBox = () => {
    setShowAlertBox(true)
  }

  const handleCloseAlertBox = () => {
    setShowAlertBox(false)
  }

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Button
        onClick={handleOpenAlertBox}
        type='primary'
        text='Open Drawer'
        style={{ width: 300 }}
      />
      <Drawer onClose={handleCloseAlertBox} isOpen={showAlertBox}>
        sdfdsfdsfdsfdsf
      </Drawer>
    </>
  )
}
