import { Modal } from 'venus/components'
import { MoveTo } from './Move'

export const MoveToModal = ({ isOpen, close, ...props }: any) => {
  return (
    <Modal
      style={{ overlay: { zIndex: 1000 } }}
      isOpen={isOpen}
      onClose={close}
      title='Move To'
    >
      {isOpen && <MoveTo {...props} close={close} />}
    </Modal>
  )
}
