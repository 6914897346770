import styled from 'styled-components'

export const BodyArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  width: 100%;
  padding: 0 24px;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
`
