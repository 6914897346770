import { createSearchParams, useNavigate } from 'react-router-dom'

const useNavigateSearch = () => {
  const navigate = useNavigate()
  return (pathname, params) => {
    const filteredParams = Object.keys(params)
      .filter((k) => params[k] != null)
      .reduce((a, k) => ({ ...a, [k]: params[k] }), {})
    return navigate({ pathname, search: `?${createSearchParams(filteredParams)}` })
  }
}

export default useNavigateSearch
