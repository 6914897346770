import { theme } from 'venus/config'
import {
  CheckBoxContent,
  CheckBoxArea,
  CheckBoxTitle,
} from './MultiSpaceItemSelect.styles'
import { Checkbox, Text } from 'venus/components'

interface IMultiSpaceItemSelect {
  spaceLabel: string
  id: string
  items: {
    name: string
    spaceId: string
    id: string
  }[]
  checkedItemIds: {
    spaceId: string
    itemId: string
    spaceName: string
    itemName: string
  }[]
  setCheckedItemIds: Function
  isDisabled: boolean
}

const formatItem = (space, item) => ({
  spaceId: space.id,
  spaceName: space.name,
  itemId: item.id,
  itemName: item.name,
})

export const MultiSpaceItemSelect = ({
  spaceLabel,
  id,
  items,
  checkedItemIds,
  setCheckedItemIds,
  isDisabled = false,
}: IMultiSpaceItemSelect) => {
  const handlePress = (
    spaceId: string,
    itemId: string,
    spaceName: string,
    itemName: string
  ) => {
    const isExsitingItem = checkedItemIds.some((item) => item.itemId === itemId)
    let ids = checkedItemIds

    if (isExsitingItem) {
      ids = checkedItemIds.filter((item) => item.itemId !== itemId)

      if (!ids.some((item) => item.spaceId === spaceId)) {
        ids = [...ids, { spaceId, spaceName, itemId: null, itemName: null }]
      }
    } else {
      ids = [...checkedItemIds, { spaceId, spaceName, itemId, itemName }]
    }

    setCheckedItemIds(ids)
  }

  const handlePressAll = () => {
    const hasExisitingItem = checkedItemIds.some((item) => item.spaceId === id)
    if (hasExisitingItem) {
      const otherSpaceItems = checkedItemIds.filter(
        (item) => item.spaceId !== id
      )

      setCheckedItemIds(otherSpaceItems)
    } else {
      const otherSpaceItems = checkedItemIds.filter(
        (item) => item.spaceId !== id
      )

      if (items && items.length) {
        setCheckedItemIds([
          ...otherSpaceItems,
          ...items.map((item) => formatItem({ id, name: spaceLabel }, item)),
        ])
      } else {
        setCheckedItemIds([
          ...otherSpaceItems,
          { spaceId: id, spaceName: spaceLabel, itemId: '', itemName: null },
        ])
      }
    }
  }

  const isSpaceChecked =
    checkedItemIds.filter(({ spaceId }) => spaceId === id).length > 0

  return (
    <>
      <CheckBoxTitle style={{ marginBottom: 3 }} onClick={handlePressAll}>
        <Checkbox
          checked={isDisabled ? true : isSpaceChecked}
          onChange={handlePressAll}
          disabled={isDisabled}
        />
        <Text
          value={spaceLabel}
          color={theme.colors.Black}
          format='h4'
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignSelf: 'center',
          }}
        />
      </CheckBoxTitle>
      <CheckBoxArea>
        {!!items &&
          items.map(({ name, id: itemId }) => {
            const isItemChecked = checkedItemIds.some(
              (item) => item.itemId === itemId
            )
            return (
              <CheckBoxContent
                key={`multi_select_${name}`}
                onClick={() => handlePress(id, itemId, spaceLabel, name)}
                style={{
                  width: items.length === 1 ? '100%' : 'fit-content',
                  marginRight: '12px',
                }}
              >
                <Checkbox
                  checked={isDisabled ? true : isItemChecked}
                  onChange={() => handlePress(id, itemId, spaceLabel, name)}
                  disabled={isDisabled}
                />
                <Text
                  value={name}
                  color={theme.colors.Black}
                  format='body'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    alignSelf: 'center',
                    flexWrap: 'wrap',
                  }}
                />
              </CheckBoxContent>
            )
          })}
      </CheckBoxArea>
    </>
  )
}
