import styled from 'styled-components'
import { theme } from 'venus/config'

export const RowLeftPartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 64px;
  justify-content: flex-start;
  align-items: center;
`

export const RightLeftPartWrapper = styled.div`
  display: flex;
  height: 22px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  background: ${theme.colors.LightBlue};
  border-radius: 50px;
  align-self: center;
  margin-right: 12px;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 64px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.LightBlue} !important;
  }

  &:hover ${RightLeftPartWrapper} {
    background-color: ${theme.colors.White} !important;
  }
`

export const ListArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 120px;
  margin-top: 20px;
  overflow-y: scroll;
  min-height: 480px;
  max-height: 680px;
`

export const SelectIndicator = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.colors.DarkGrey};
  border-radius: 50%;
  margin-right: 12px;
`

export const RightArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
