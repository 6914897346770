import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
`

export const PillContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`

interface PillButtonProps {
  readonly isActive: boolean
  children: React.ReactNode
}
export const PillButton = styled.button<PillButtonProps>`
  cursor: pointer;
  border: none;
  padding: 7px 30px;
  border-radius: 20px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.White : theme.colors.Primary)};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.Secondary : 'transparent'};
`
