import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const PropertySpacesContainer = styled.div`
  height: 100%;
`
