import { Text } from "venus/components"

export const TextSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Text
        value="h1 -- This is the Demo Text."
        format="h1"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Text
        value="h2 -- This is the Demo Text."
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Text
        value="h3 -- This is the Demo Text."
        format="h3"
        color="blue"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Text
        value="h4 -- This is the Demo Text."
        format="h4"
        color="blue"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Text
        value="body -- This is the Demo Text."
        format="body"
        color="blue"
        style={{ margin: 18, textAlign: "left" }}
      />
    </>
  )
}
