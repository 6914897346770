import _ from 'lodash'
import api from 'venus/api'
import React, { useEffect, useState, useMemo } from 'react'
import isEmail from 'validator/lib/isEmail'
import { ActionLayout, AlertBox, Input, Loader, PropertyCardView, TabView, Text } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getProperties } from 'venus/redux/features/property/properties'
import { TransferVaultBody, ConfirmStateWrapper, TransferViewContainer } from './TransferOwnership.styles'
import { SettingsHeader } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader'
import PropertyListCardView from 'venus/components/PropertyListCardView/PropertyListCardView'
import { setErrorMessage } from 'venus/redux/features/error/reducer'
import { PAID } from 'venus/config'
import {
  CANCEL_BTN_TEXT,
  CONFIRM_TRANSFER,
  CONFIRM_TRANSFER_MSG,
  INVALID_INPUT_MSG,
  OWNER_EMAIL_INPUT_TEXT,
  OWNER_NAME_INPUT_TEXT,
  SELECT_TRANSFER_MSG,
  TABS,
  TransferOwnershipStep,
  TRANSFER_BTN_TEXT,
  TRANSFER_PAGE_TITLE, INVALID_EMAIL_MSG,
} from './Constants'

const TransferOwnership = () => {
  const dispatch = useAppDispatch()
  const { properties } = useAppSelector((state) => state.properties)
  const { vaults } = useAppSelector((state) => state.subscription)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(TransferOwnershipStep.TRANSFER_SELECT)
  const [showTransferAlertBox, setShowTransferAlertBox] = useState(false)
  const [showCancelAlertBox, setShowCancelAlertBox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [defaultTabIndex, setDefaultTabIndex] = useState(0)

  useEffect(() => {
    // handle document level ENTER key event
    const handleKeyEvent = async (event) => {
      event.stopPropagation()
      if (event.keyCode === 13) {
        if (!selectedProperty) return
        switch (step) {
          case TransferOwnershipStep.TRANSFER_SELECT:
            setStep(TransferOwnershipStep.TRANSFER_CONFIRM)
            break
          case TransferOwnershipStep.TRANSFER_CONFIRM:
            setShowTransferAlertBox(true)
            break
          case TransferOwnershipStep.CANCEL_SELECT:
            setShowCancelAlertBox(true)
            break
          default:
            break
        }
      }
    }
    window.addEventListener('keydown', handleKeyEvent)

    return () => {
      window.removeEventListener('keydown', handleKeyEvent)
    }
  }, [step, selectedProperty])

  useEffect(() => {
    if (!isEmail(email)) {
      setIsValidEmail(false)
    } else {
      setIsValidEmail(true)
    }
  }, [email])

  const groups = useMemo(
    () => _.partition(properties, (property) => property.transfer),
    [properties],
  )
  const transferList = useMemo(() => {
    return groups[1].filter((property) => vaults[property.id] === PAID)
  }, [groups, vaults])
  const pendingList = groups[0]

  const closeTransferAlertBox = () => {
    setShowTransferAlertBox(false)
  }

  const closeCancelAlertBox = () => {
    setShowCancelAlertBox(false)
  }

  const handleOnClickTransfer = async () => {
    try {
      setLoading(true)
      await api.post('/transfer', {
        name: name.trim(),
        email: email.trim(),
        propertyId: selectedProperty?.id
      })
      await dispatch(getProperties())
    } catch (error) {
      dispatch(setErrorMessage('Invalid Request'))
    } finally {
      setShowTransferAlertBox(false)
      setLoading(false)
      handleOnChangeTab(0)
    }
  }

  const handleOnClickCancelTransfer = async () => {
    try {
      setLoading(true)
      await api.delete(`/transfer/${selectedProperty.id}`)
      await dispatch(getProperties())
    } catch (error) {
      dispatch(setErrorMessage('Invalid Request'))
    } finally {
      setShowCancelAlertBox(false)
      setLoading(false)
      handleOnChangeTab(1)
    }
  }

  const clearConfirmForm = () => {
    setSelectedProperty(null)
    setName('')
    setEmail('')
  }

  const handleOnChangeTab = (index: number) => {
    setDefaultTabIndex(index)
    clearConfirmForm()
    if (index === 0) {
      setStep(TransferOwnershipStep.TRANSFER_SELECT)
    } else {
      setStep(TransferOwnershipStep.CANCEL_SELECT)
    }
  }

  const handleOnClickBack = () => {
    handleOnChangeTab(0)
  }

  const handleOnClickTransferBtn = () => {
    if (!selectedProperty) return

    switch (step) {
      case TransferOwnershipStep.TRANSFER_SELECT:
        setStep(TransferOwnershipStep.TRANSFER_CONFIRM)
        break
      case TransferOwnershipStep.TRANSFER_CONFIRM:
        if (!name.trim() || !email.trim() || !isValidEmail) return
        setShowTransferAlertBox(true)
        break
      case TransferOwnershipStep.CANCEL_SELECT:
        if (!selectedProperty) return
        setShowCancelAlertBox(true)
        break
      default:
        break
    }
  }

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull/>
      </div>
    )
  }
  return (
    <>
      <AlertBox
        showAlert={showTransferAlertBox}
        cancel={closeTransferAlertBox}
        title={CONFIRM_TRANSFER}
        message={CONFIRM_TRANSFER_MSG}
        btnGroup={[
          {
            text: 'Cancel',
            type: 'secondary',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: closeTransferAlertBox,
          },
          {
            text: 'Confirm',
            type: 'primary',
            color: 'Primary',
            textColor: 'White',
            onClick: handleOnClickTransfer,
          },
        ]}
      />
      <AlertBox
        showAlert={showCancelAlertBox}
        cancel={closeCancelAlertBox}
        title="Cancel Transfer"
        message={`You have sent ownership transfer invitation to ${name}. Do you have cancel it?`}
        btnGroup={[
          {
            text: 'Cancel',
            type: 'secondary',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: closeCancelAlertBox
          },
          {
            text: 'Cancel Transfer',
            type: 'error',
            color: 'ErrorState',
            textColor: 'White',
            onClick: handleOnClickCancelTransfer,
          },
        ]}
      />
      <ActionLayout
        overFlowY={'hidden'}
        margin={28}
        children={
          <>
            {(step === TransferOwnershipStep.TRANSFER_SELECT ||
              step === TransferOwnershipStep.CANCEL_SELECT) && (
              <SettingsHeader title={TRANSFER_PAGE_TITLE}/>
            )}
            {step === TransferOwnershipStep.TRANSFER_CONFIRM && (
              <SettingsHeader
                title={TRANSFER_PAGE_TITLE}
                onBackBtnClick={handleOnClickBack}
              />
            )}
            <TransferVaultBody>
              {(step === TransferOwnershipStep.TRANSFER_SELECT ||
                step === TransferOwnershipStep.CANCEL_SELECT) && (
                <>
                  <Text
                    value={SELECT_TRANSFER_MSG}
                    format={'body'}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={'21px'}
                  />
                  <TabView
                    defaultActiveIndex={defaultTabIndex}
                    tabs={TABS}
                    paddingBottom={0}
                    onChange={handleOnChangeTab}
                    isScrollable={true}
                    scrollBodyHeight={380}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflowY: 'hidden',
                    }}
                  >
                    {/* First Tab Content */}
                    <TransferViewContainer>
                      {transferList?.map((property) => (
                        <PropertyListCardView
                          key={property.id}
                          property={property}
                          isSelectable={true}
                          isChecked={property?.id === selectedProperty?.id}
                          onClick={setSelectedProperty}
                        />
                      ))}
                    </TransferViewContainer>
                    {/* Second Tab Content */}
                    <>
                      {pendingList?.map((property) => (
                        <PropertyListCardView
                          key={property.id}
                          property={property}
                          isSelectable={true}
                          isChecked={property?.id === selectedProperty?.id}
                          onClick={setSelectedProperty}
                        />
                      ))}
                    </>
                  </TabView>
                </>
              )}
              {step === TransferOwnershipStep.TRANSFER_CONFIRM && (
                <>
                  <ConfirmStateWrapper>
                    <PropertyCardView
                      property={selectedProperty}
                      imageHeight={200}
                      isSelected={true}
                      showPropertyData={true}
                    />
                    <Input
                      type={'text'}
                      label={OWNER_NAME_INPUT_TEXT}
                      value={name}
                      onChange={setName}
                      style={{ marginTop: '18px' }}
                      isError={!!name.length && !name.trim()}
                      errorMsg={INVALID_INPUT_MSG}
                    />
                    <Input
                      type={'text'}
                      label={OWNER_EMAIL_INPUT_TEXT}
                      value={email}
                      onChange={setEmail}
                      style={{ marginTop: '18px' }}
                      isError={!!email.length && (!email.trim() || !isValidEmail)}
                      errorMsg={INVALID_EMAIL_MSG}
                    />
                  </ConfirmStateWrapper>
                </>
              )}
            </TransferVaultBody>
          </>
        }
        rightActions={[
          {
            type: step !== TransferOwnershipStep.CANCEL_SELECT ? 'primary' : 'error',
            isDisabled: !selectedProperty
              || (step === TransferOwnershipStep.TRANSFER_CONFIRM && (!name.trim() || !email.trim() || !isValidEmail)),
            onClick: handleOnClickTransferBtn,
            text:
              step !== TransferOwnershipStep.CANCEL_SELECT
                ? TRANSFER_BTN_TEXT
                : CANCEL_BTN_TEXT,
          },
        ]}
      />
    </>

  )
}

export default TransferOwnership