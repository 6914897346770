import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import api from 'venus/api'
import {
  IEditSpaceDetailsAsync,
  IEditSingleSpaceDetailsAsync,
  IEditSpaceOrderAsync,
  IRemoveSpace,
} from './types'

export const bulkAddSpaceImages = createAsyncThunk(
  'space/bulkAddSpaceImages',
  async ({ propertyId, spaceImages }: IEditSpaceDetailsAsync, { rejectWithValue }) => {
    try {
      console.log('spaceImages', spaceImages)
      await api.put(`/property/${propertyId}/images`, spaceImages)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const addSpaceImages = createAsyncThunk(
  'space/addSpaceImages',
  async ({ source, target, images }: IEditSingleSpaceDetailsAsync, { rejectWithValue }) => {
    try {
      console.log('body::', {
        source,
        target,
        images,
      })
      const response = await api.put(`/images`, {
        source,
        target,
        images,
      })
      console.log('addSpaceImages response::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const editSpaceOrder = createAsyncThunk(
  'space/editOrder',
  async ({ propertyId, spaces }: IEditSpaceOrderAsync, { rejectWithValue }) => {
    try {
      const response = await api.put(`/property/${propertyId}/spaces`, spaces)
      console.log('editSpaceOrder response::', response)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const removeSpaceOrder = createAsyncThunk(
  'space/remove',
  async ({ propertyId, spaceId }: IRemoveSpace, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/property/${propertyId}/spaces/${spaceId})`)
      console.log('removeSpaceOrder response::', response)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
