import { useEffect } from 'react'
import MobileView from 'venus/common/MobileView'
import useMobileContext from 'venus/context/MobileBrowser'
import { DownloadApp } from 'venus/pages/DownloadApp'
import { isFromMobileBrowser } from 'venus/utils'

const deeplinks = {
  '/transfer': 'deeplink/transfer-ownership',
  '/share': 'deeplink/add-share-property',
  '/request-ownership/': 'deeplink/request-ownership',
  '/auth/reset-password': 'reset-password',
}

export const Deeplink = ({
  description = 'MyHomeVault Web App',
}: {
  description: string
}) => {
  const { isMobile, showDesktop } = useMobileContext()

  useEffect(() => {
    // if not mobile browser, we go to the web app page /transfer-ownership?id=xxx
    if (!isMobile) {
      const query = window.location.search || ''

      console.log('query', query)
      const url = `${window.location.origin}/${deeplinks[window.location.pathname]}${query}`
      window.location.replace(url)
    } else {
      // Detect whether this is a mobile browser
      try {
        const url = window.location.href.replace(window.location.origin, 'myhomevault:/')
        window.location.replace(url)
      } catch (error) {
        console.log('Error in deeplink', error)
      }
    }
  }, [isMobile])

  if (!isMobile) return <></>
  return (
    <>
      {/* <Helmet>
      <meta name="description" content={description} />
    </Helmet>  */}
      <MobileView />
    </>
  )
}
