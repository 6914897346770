import { useNavigate } from 'react-router-dom'
import { setErrorTitle } from 'venus/redux/features/error/reducer'
import { ImageWrapper, ImageContent } from './ConfirmHome.styles'
import { theme, Add_Dream_Property_Type, Request_Ownership_Type } from 'venus/config'
import { Text, ActionLayout, Image } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { createProperty, setPropertyId } from 'venus/redux/features/property/property'
import { addDreamProperty, getDreamPropertyList } from 'venus/redux/features/dreamprice'
import { formatFullAddress, getAddressPreviewStr } from 'venus/utils'
import { createHomeImages } from 'venus/redux/features/onboarding'

interface IConfirmHome {
  onFinish: () => void
  addType?: string
  onClose?: () => void
}

export const ConfirmHome = ({ onFinish, addType, onClose }: IConfirmHome) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isAddDreamProperty = addType === Add_Dream_Property_Type
  const isRequestOwnership = addType === Request_Ownership_Type
  const { preview, addressId } = useAppSelector((state) => state.address)
  const { loading } = useAppSelector((state) => state.property)
  const {
    address,
    area,
    baths,
    beds,
    parking,
    postcode,
    suburb,
    state,
    nationalParcelId,
    googlePlaceId,
    propertyKey,
    propertyType,
    imageBaseUrl,
    imageCount,
    image,
  } = preview[0]
  const coverImage =
    image ||
    (!!imageBaseUrl && !imageBaseUrl?.endsWith('null')
      ? `${imageBaseUrl}.jpg`
      : '/png/No_Img.png')
  const handlePress = async () => {
    if (isAddDreamProperty) {
      dispatch(setErrorTitle('Dream Property Error'))
      const data = await dispatch(
        addDreamProperty({
          addressId: Number(addressId),
          nationalParcelId,
          propertyKey,
          googlePlaceId,
        }),
      )
      if (data.meta.requestStatus === 'fulfilled') {
        await dispatch(getDreamPropertyList())
        onFinish()
      } else {
        onClose()
      }
    } else if (isRequestOwnership) {
      // navigation.navigate('SendRequest', {
      //   propertyKey,
      //   googlePlaceId,
      //   address,
      //   coverImage,
      //   goBack,
      // })
    } else {
      dispatch(setErrorTitle('Add Property Error'))
      const data = await dispatch(
        createProperty({
          address: {
            state,
            suburb,
            address,
            postcode,
          },
          addressId: Number(addressId),
          nationalParcelId,
          propertyKey,
          propertyData: {
            area,
            beds,
            baths,
            parking,
          },
          imageBaseUrl,
          imageCount,
        }),
      )
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(setPropertyId(data.payload.id))
        dispatch(createHomeImages(preview[0]))
        onFinish()
      }
    }
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          text: isAddDreamProperty
            ? 'Add'
            : isRequestOwnership
            ? 'Claim Existing Vault'
            : 'This is my home',
          onClick: handlePress,
          isDisabled: !address,
          isLoading: loading,
        },
      ]}
    >
      <ImageWrapper>
        <Image
          image={googlePlaceId ? image : coverImage}
          style={{ width: '412px', aspectRatio: 1.3, borderRadius: '5px' }}
        />
        {!googlePlaceId && (
          <Text
            value={getAddressPreviewStr({ beds, baths, area, propertyType })}
            color={theme.colors.DarkGrey}
            style={{ alignSelf: 'flex-start', marginTop: 12, marginBottom: 12 }}
            format='h3'
          />
        )}
        <Text
          value={formatFullAddress({
            address: googlePlaceId ? address?.address : address,
            suburb: googlePlaceId ? address.suburb : suburb,
            state: googlePlaceId ? address.state : state,
            postcode: googlePlaceId ? address.postcode : postcode,
          })}
          color={theme.colors.Black}
          style={{ alignSelf: 'flex-start', margin: '2px 0 18px' }}
          format='h2'
        />
      </ImageWrapper>
    </ActionLayout>
  )
}
