import styled from 'styled-components'

type BtnWrapperProps = {
  readonly textColorOnHover: string
  readonly textColor: string
}

export const BtnWrapper = styled.button<BtnWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 12px;
  cursor: pointer;
  background-color: transparent;
  transition-duration: 0.3s;
  color: ${(props) => props.textColor} !important;

  &:hover {
    color: ${(props) => props.textColorOnHover} !important;
  }
`
