import _ from 'lodash'
import React from 'react'
import { IContactEntry } from 'venus/redux/features/contact'
import { theme } from 'venus/config'
import PillTag from '../Pill/PillTag'
import { Text, Rating, AverageRating, TabView } from 'venus/components'
import { getFullName } from 'venus/utils/fullName'
import {
  Container,
  ContactCard,
  ContactInfoCard,
  FirstTab,
  SecondTab,
  RatingWrapper,
  AvatarWrapper,
  RoleWrapper,
  ImageContainer,
  DefaultAvatarIcon,
  ContentTitle,
  HeaderButtonBox
} from 'venus/components/ContactDrawView/ContactDrawView.styles'
import { ReactComponent as BackArrow } from 'venus/assets/img/icons/BackArrow.svg'

const ContactDrawView = ({
  contact,
  title = '',
  onClickBackBtn,
}: {
  contact: IContactEntry
  title?: string
  onClickBackBtn?: () => void
}) => {
  const rating = _.get(contact, ['rating'], {
    timeManagement: '0',
    communication: '0',
    pricing: '0',
    quality: '0',
  })

  return (
    <Container style={onClickBackBtn && { marginTop: '32px' }}>
      {onClickBackBtn ? (
        <ContentTitle>
          <HeaderButtonBox onClick={onClickBackBtn}>
            <BackArrow/>
          </HeaderButtonBox>
          <Text format={'h1'} value={title}/>
          <HeaderButtonBox/>
        </ContentTitle>
      ) : (
        <ContentTitle>
          <HeaderButtonBox/>
          <Text format={'h1'} value={title}/>
          <HeaderButtonBox/>
        </ContentTitle>
      )}
      <ContactCard>
        <ImageContainer>
          {contact?.profile?.avatar ? (
            <AvatarWrapper src={contact?.profile?.avatar || ''} alt={'Avatar'}/>
          ) : (
            <DefaultAvatarIcon/>
          )}
        </ImageContainer>
        <ContactInfoCard>
          <Text
            format={'h1'}
            fontSize={'26px'}
            fontWeight={600}
            lineHeight={'39px'}
            value={getFullName(contact)}>
          </Text>
          <RoleWrapper>
            <PillTag
              label={_.capitalize(contact?.role[0])}
              background={theme.colors.LightBlue}
              color={theme.colors.Primary}
              fontSize={14}
              fontWeight={500}
              lineHeight={1}
              paddingHorizontal={13}
              paddingVertical={5}
              style={{ margin: '2px 4px' }}
            />
            <PillTag
              label={_.capitalize(contact?.tradespeopleRole[0])}
              background={theme.colors.LightBlue}
              color={theme.colors.Primary}
              fontSize={14}
              fontWeight={500}
              lineHeight={1}
              paddingHorizontal={13}
              paddingVertical={5}
              style={{ margin: '2px 4px' }}
            />
            <AverageRating average={String(contact?.averageRating || '0')}/>
          </RoleWrapper>
        </ContactInfoCard>
      </ContactCard>
      <TabView
        tabs={['Details', 'Ratings']}
        defaultActiveIndex={0}
        paddingBottom={0}
        headerStyle={{ marginBottom: 10 }}
      >
        <FirstTab>
          <Text format={'h2'} value={'Mobile Number'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.mobile} style={{ textAlign: 'left', marginBottom: '18px' }}/>

          <Text format={'h2'} value={'Email'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.email} style={{ textAlign: 'left', marginBottom: '18px' }}/>

          <Text format={'h2'} value={'Company name'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.companyName} style={{ textAlign: 'left', marginBottom: '18px' }}/>

          <Text format={'h2'} value={'Company address'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.companyAddress} style={{ textAlign: 'left', marginBottom: '18px' }}/>

          <Text format={'h2'} value={'ABN'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.abn} style={{ textAlign: 'left', marginBottom: '18px' }}/>

          <Text format={'h2'} value={'Company phone'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.companyPhone} style={{ textAlign: 'left', marginBottom: '18px' }}/>

          <Text format={'h2'} value={'Company website'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <Text format={'body'} value={contact?.companyURL} style={{ textAlign: 'left', marginBottom: '18px' }}/>
        </FirstTab>
        <SecondTab>
          <RatingWrapper>
            <Text format={'h3'} value={'Time Management'}/>
            <Rating rating={rating?.timeManagement} readonly/>
          </RatingWrapper>
          <RatingWrapper>
            <Text format={'h3'} value={'Communication'}/>
            <Rating rating={rating?.communication} readonly/>
          </RatingWrapper>
          <RatingWrapper>
            <Text format={'h3'} value={'Pricing'}/>
            <Rating rating={rating?.pricing} readonly/>
          </RatingWrapper>
          <RatingWrapper>
            <Text format={'h3'} value={'Quality'}/>
            <Rating rating={rating?.quality} readonly/>
          </RatingWrapper>
        </SecondTab>
      </TabView>
    </Container>
  )
}

export default ContactDrawView