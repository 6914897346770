import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'venus/redux/store'
import { getErrorMsg } from 'venus/utils'
import {
  ISignUp,
  IUserStateWithPassword,
  IUserState,
  IUserCompanyState,
  IUserMobile,
} from './types'
import { signUp, signIn } from './asyncSignIn'
import {
  resendMobileCode,
  validateMobile,
  checkExistingEmail,
  forgotPassword,
} from './asyncValidation'
import { getProfile, updateProfile, changePassword } from './asyncProfile'
import { getAcceptOwnership } from './asyncOwnership'

const initialState: ISignUp = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roles: [],
  isAllowedToSharePropertyInfo: true,
  isAllowedToShareContactInfo: true,
  companyName: '',
  companyABN: '',
  companyAddress: '',
  companySuburb: '',
  companyState: '',
  companyPhone: '',
  companyEmail: '',
  licenseNo: '',
  mobile: '',
  token: '',
  oAuthType: '',
  expoPushToken: '',
  isEmailValidated: false,
  isMobileValidated: false,
  error: '',
  loginErrorInfo: {},
  loading: true,
  loadingProfile: true,
  updating: false,
  info: null,
  baseURL: '',
  isNewUser: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.error = payload
    },
    resetError: (state) => {
      state.error = ''
    },
    resetLoginErrorInfo: (state) => {
      state.loginErrorInfo = {}
    },
    resetLoading: (state) => {
      state.loading = false
    },
    setLocalBaseURL: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.baseURL = payload
    },
    handleAllowedToSharePropertyInfo: (state) => {
      state.isAllowedToSharePropertyInfo = !state.isAllowedToSharePropertyInfo
    },
    handleAllowedToShareContactInfo: (state) => {
      state.isAllowedToShareContactInfo = !state.isAllowedToShareContactInfo
    },
    registerUser: (state, action: PayloadAction<IUserStateWithPassword>) => {
      const { payload } = action
      const {
        firstName,
        lastName,
        email,
        password,
        roles,
        token,
        oAuthType,
        expoPushToken,
      } = payload
      state.firstName = firstName
      state.lastName = lastName
      state.email = email
      state.password = password
      state.roles = roles
      state.token = token
      state.oAuthType = oAuthType
      state.expoPushToken = expoPushToken
      state.isNewUser = true
    },
    restoreNewUser: (state) => {
      state.isNewUser = false
    },
    editPassword: (state, action: PayloadAction<{ password: string }>) => {
      const { payload } = action
      const { password } = payload
      state.password = password
    },
    editUserInfo: (state, action: PayloadAction<IUserState>) => {
      const { payload } = action
      const { firstName, lastName, email, roles } = payload
      state.firstName = firstName
      state.lastName = lastName
      state.email = email
      state.roles = roles
    },
    registerCompany: (state, action: PayloadAction<IUserCompanyState>) => {
      const { payload } = action
      const {
        companyName,
        companyABN,
        companyAddress,
        companySuburb,
        companyState,
        companyPhone,
        companyEmail,
        companyWebsite,
        licenseNo,
        avatar,
      } = payload
      state.companyName = companyName
      state.companyABN = companyABN
      state.companyAddress = companyAddress
      state.companySuburb = companySuburb
      state.companyState = companyState
      state.companyPhone = companyPhone
      state.companyEmail = companyEmail
      state.companyWebsite = companyWebsite
      state.licenseNo = licenseNo
      state.avatar = avatar
    },
    registerMobile: (state, action: PayloadAction<IUserMobile>) => {
      const { payload } = action
      const { mobile } = payload
      state.mobile = mobile
    },
    setMobileValidationStatus: (
      state,
      action: PayloadAction<{ isMobileValidated: boolean }>,
    ) => {
      const { payload } = action
      const { isMobileValidated } = payload
      state.isMobileValidated = isMobileValidated
    },
    setEmailValidationStatus: (
      state,
      action: PayloadAction<{ isEmailValidated: boolean }>,
    ) => {
      const { payload } = action
      const { isEmailValidated } = payload
      state.isEmailValidated = isEmailValidated
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (state) => {
      state.loading = true
    })
    builder.addCase(signUp.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(signUp.rejected, (state, action) => {
      state.loading = false
      const errorMsg = getErrorMsg(action.payload)
      state.error = errorMsg
    })
    builder.addCase(signIn.pending, (state) => {
      state.loading = true
    })
    builder.addCase(signIn.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false
      const errorMsg = getErrorMsg(action.payload)
      if (Array.isArray(errorMsg)) {
        let message = ''
        errorMsg.forEach(({ msg }) => {
          message += `${msg} `
        })
        state.error = message
      } else if (typeof errorMsg === 'string') {
        state.error = errorMsg
      } else if (typeof errorMsg === 'object') {
        state.loginErrorInfo = errorMsg
        const { isMobileValidated, status } = errorMsg
        if (isMobileValidated === false && status === 401) {
          state.error = 'Please verify your mobile number.'
        }
      }
    })
    builder.addCase(validateMobile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(validateMobile.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(validateMobile.rejected, (state, action) => {
      state.loading = false
      const errorMsg = getErrorMsg(action.payload)
      if (Array.isArray(errorMsg)) {
        let message = ''
        errorMsg.forEach(({ msg }) => {
          message += `${msg} `
        })
        state.error = message
      } else {
        state.error = JSON.stringify(errorMsg)
      }
    })
    builder.addCase(resendMobileCode.pending, (state) => {
      state.loading = true
    })
    builder.addCase(resendMobileCode.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(resendMobileCode.rejected, (state, action) => {
      state.loading = false
      const errorMsg = getErrorMsg(action.payload)
      if (Array.isArray(errorMsg)) {
        state.error = errorMsg[0]?.msg || ''
      } else if (typeof errorMsg === 'string') {
        state.error = errorMsg
      } else {
        state.error = JSON.stringify(action.payload)
      }
    })
    builder.addCase(getProfile.pending, (state) => {
      state.loadingProfile = true
      state.error = ''
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loadingProfile = false
      state.info = action.payload
    })
    builder.addCase(getProfile.rejected, (state) => {
      state.loadingProfile = false
    })
    builder.addCase(updateProfile.pending, (state) => {
      state.updating = true
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updating = false
      if (typeof action.payload === 'object') state.info = action.payload
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updating = false
      const errorMsg = getErrorMsg(action.payload)
      if (Array.isArray(errorMsg)) {
        state.error = errorMsg[0]?.msg || ''
      } else if (typeof errorMsg === 'string') {
        state.error = errorMsg
      } else {
        state.error = JSON.stringify(action.payload)
      }
    })
    builder.addCase(checkExistingEmail.pending, (state) => {
      state.loading = true
    })
    builder.addCase(checkExistingEmail.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(checkExistingEmail.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false
      const errorMsg = getErrorMsg(action.payload)
      if (Array.isArray(errorMsg)) {
        state.error = errorMsg[0]?.msg || ''
      } else if (typeof errorMsg === 'string') {
        state.error = errorMsg
      } else {
        state.error = JSON.stringify(action.payload)
      }
    })
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(getAcceptOwnership.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  setError,
  resetError,
  resetLoginErrorInfo,
  resetLoading,
  setLocalBaseURL,
  handleAllowedToSharePropertyInfo,
  handleAllowedToShareContactInfo,
  registerUser,
  editUserInfo,
  editPassword,
  registerCompany,
  registerMobile,
  setMobileValidationStatus,
  setEmailValidationStatus,
  restoreNewUser,
} = userSlice.actions

export const firstName = (state: RootState) => state.user.firstName
export const lastName = (state: RootState) => state.user.lastName
export const email = (state: RootState) => state.user.email
export const password = (state: RootState) => state.user.password
export const mobile = (state: RootState) => state.user.mobile
export const roles = (state: RootState) => state.user.roles
export const companyName = (state: RootState) => state.user.companyName
export const companyABN = (state: RootState) => state.user.companyABN
export const companyAddress = (state: RootState) => state.user.companyAddress
export const companySuburb = (state: RootState) => state.user.companySuburb
export const companyState = (state: RootState) => state.user.companyState
export const companyPhone = (state: RootState) => state.user.companyPhone
export const companyEmail = (state: RootState) => state.user.companyEmail
export const licenseNo = (state: RootState) => state.user.licenseNo
export const isAllowedToSharePropertyInfo = (state: RootState) =>
  state.user.isAllowedToSharePropertyInfo
export const isAllowedToShareContactInfo = (state: RootState) =>
  state.user.isAllowedToShareContactInfo

export default userSlice.reducer
