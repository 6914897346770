import styled from 'styled-components'
import { theme } from 'venus/config'

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 48px);
`

export const UploadImageArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 2px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  border-color: ${theme.colors.LighterGrey};
  border-width: 1px;
  border-radius: 4px;
`

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 2px;
  flex: 1;
  align-self: stretch;
  border-color: ${theme.colors.LighterGrey};
  border-width: 1px;
  border-radius: 4px;
  overflow: hidden;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px 24px 24px;
  width: 100%;
`

export const AddIcon = styled.div`
  position: absolute;
  bottom: 0px;
  right: 3px;
`

export const AddImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 30px;
`
