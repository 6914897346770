import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'venus/api'
import { setAuthToken } from 'venus/auth/auth'
import { Loader } from 'venus/components'
import { resetPropertyStore } from 'venus/redux/features/property/property'
import { useAppDispatch } from 'venus/redux/hooks'

const Logout = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      await dispatch(resetPropertyStore())
      const res = await api.post('auth/logout', {}, { withCredentials: true })

      setAuthToken('')
      navigate('/login', { replace: true })
    })()
  }, [])

  return <Loader isFull />
}

export default Logout
