import styled from 'styled-components'

export const Banner = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  background-color: ${(props) => props.theme.colors.Secondary};
  padding: 0 20px;
`
