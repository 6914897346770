import React from 'react'
import { useEffect } from 'react'
import DefaultAvatar from 'venus/assets/img/icons/Avatar.svg'
import ImageUploader from 'venus/common/ImageUploader'
import { AvatarImgContainer } from 'venus/components/Avatar/Avatar.styles'
import { AvatarContainer } from 'venus/components/Avatar/Avatar.styles'
import { AvatarEditIconContainer } from 'venus/components/Avatar/Avatar.styles'
import { AvatarEditIcon } from 'venus/components/Avatar/Avatar.styles'
import { reset } from 'venus/redux/features/image/image'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'

interface IAvatar {
  avatar?: string
  size: number
  handleOnEditAvatar?: (selectedFile) => void
  isBorder?: boolean
  showEditBtn?: boolean
  style?: any
  editable?: boolean
}

export const Avatar = ({
  avatar,
  size,
  handleOnEditAvatar,
  isBorder = true,
  showEditBtn = false,
  style,
  editable = false,
}: IAvatar) => {
  const dispatch = useAppDispatch()
  const { image, url } = useAppSelector((state) => state.image)

  const [iconSize, setIconSize] = React.useState(size * 0.3)

  useEffect(() => {
    if (url) {
      handleOnEditAvatar && handleOnEditAvatar(url)
      dispatch(reset())
    }
  }, [url])

  useEffect(() => {
    setIconSize(size * 0.25)
  }, [size])

  const Container = editable ? ImageUploader : React.Fragment

  return (
    <Container>
      <AvatarContainer size={size}>
        <AvatarImgContainer
          isBorder={isBorder}
          style={{ ...style }}
          size={size}
          image={image || avatar || DefaultAvatar}
        />
        {showEditBtn && (
          <AvatarEditIconContainer size={iconSize}>
            <AvatarEditIcon/>
          </AvatarEditIconContainer>
        )}
      </AvatarContainer>
    </Container>
  )
}
