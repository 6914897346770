import styled from 'styled-components'
import { theme } from 'venus/config'

type CheckboxContainerProps = {
  readonly noMargin?: boolean
}
export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;

  margin-top: ${(props) => (props.noMargin ? 0 : 12)}px;
  margin-bottom: ${(props) => (props.noMargin ? 0 : 12)}px;
  cursor: pointer;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: round;
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 19px;
  height: 19px;
  background: ${(props) => (props.checked ? theme.colors.Primary : theme.colors.White)};
  border: ${theme.colors.Primary} solid 1px;
  border-radius: 3px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

export const StyledDisabledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 19px;
  height: 19px;
  background: ${(props) => (props.checked ? theme.colors.DarkGrey : theme.colors.White)};
  border: ${theme.colors.DarkGrey} solid 1px;
  border-radius: 3px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
