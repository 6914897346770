import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const MenuContainer = styled.ul`
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Item = styled.li`
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
`

export const MenuItemLink = styled(NavLink)`
  text-decoration: none;
`

export const MenuItemExternalLink = styled.a`
  text-decoration: none;
`

export const IconWrapper = styled.div`
  margin-left: 26px;
  margin-right: 14px;

  height: 24px;
  width: 24px;
`
type BackgroundProps = {
  readonly isActive: boolean
}
export const Background = styled.div<BackgroundProps>`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.LightBlue : 'transparent'};
`

export const ActiveIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: ${(props) => props.theme.colors.Secondary};
`
