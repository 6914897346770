import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import api from 'venus/api'
import { ActionLayout, Input, Modal, Text, AlertBox } from 'venus/components'
import { theme } from 'venus/config'
import { IImage } from 'venus/types/Image'
import { ImageWrapper } from './PSISetting.styles'
import CoverImage from './CoverImage'

interface IPSISetting {
  isOpen: boolean
  onClose: () => void
  name: string
  coverImage: string
  images: IImage[]
  onSubmit: (data: any) => void
  isPersonal?: boolean
  tag: string
  onDelete: () => void
  hideDelete: boolean
}

const PSISetting = ({
  isOpen,
  onClose,
  name,
  coverImage,
  images,
  onSubmit,
  onDelete,
  hideDelete,
  isPersonal,
  tag,
}: IPSISetting) => {
  const params = useParams()
  const refSubmitButtom = useRef<HTMLButtonElement>(null)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const triggerSubmit = () => {
    refSubmitButtom?.current?.click()
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name,
      coverImage,
    },
  })

  useEffect(() => {
    if (isOpen) {
      reset({ name, coverImage })
    }
  }, [isOpen])

  const handleOnClickDelete = async () => {
    // if (params.itemId) {
    //   if (isPersonal) {
    //     await api.delete(`/personal/item/${params.itemId}`)
    //   } else {
    //     await api.delete(
    //       `/property/${params.propertyId}/space/${params.spaceId}/item/${params.itemId}`,
    //     )
    //   }
    // }
    if (onDelete) {
      onDelete()
    }
    closeConfirmationBox();
    onClose()
  }

  const showConfirmationBox = () => {
    setShowConfirmation(true)
  }

  const closeConfirmationBox = () => {
    setShowConfirmation(false)
  }

  let deleteButtonText
  if (params.itemId) {
    deleteButtonText = 'Delete Item'
  } else if (params.spaceId) {
    deleteButtonText = 'Delete Space'
  } else {
    deleteButtonText = 'Delete'
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Settings'>
      <AlertBox
        showAlert={showConfirmation}
        cancel={closeConfirmationBox}
        title={`Remove ${tag}`}
        message={`Are you sure you want to remove '${name}' from your MyHomeVault? This ${tag} will be deleted immediately. You can't undo this action.`}
        btnGroup={[
          {
            text: 'Cancel',
            type: 'secondary',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: closeConfirmationBox,
          },
          {
            text: 'Delete',
            type: 'error',
            color: 'Primary',
            textColor: 'White',
            onClick: handleOnClickDelete,
          },
        ]}
      />
      <ActionLayout
        margin={34}
        leftActions={
          hideDelete
            ? []
            : [
                {
                  type: 'icon_button_error',
                  onClick: showConfirmationBox,
                  text: deleteButtonText,
                },
              ]
        }
        rightActions={[
          {
            text: 'Save',
            onClick: triggerSubmit,
          },
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='coverImage'
            control={control}
            render={({ field: { onChange, value } }) => (
              <div style={{ marginBottom: 16 }}>
                <Text
                  format='h3'
                  value='Cover Image'
                  color={theme.colors.Black}
                  fontSize={14}
                />
                <ImageWrapper>
                  <CoverImage coverImage={value} onChange={onChange} images={images} />
                </ImageWrapper>
              </div>
            )}
          />
          {!isPersonal && (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  style={{ marginBottom: 8 }}
                  label='Name'
                  value={value}
                  onChange={onChange}
                />
              )}
              name='name'
              rules={{ required: true, maxLength: 20 }}
            />
          )}

          {errors.name?.type === 'required' && (
            <div style={{ paddingBottom: 16 }}>
              <Text format='h4' value='This is required.' color={theme.colors.ErrorState} />
            </div>
          )}

          {errors.name?.type === 'maxLength' && (
            <div style={{ paddingBottom: 16 }}>
              <Text
                format='h4'
                value='Maximum 20 characters.'
                color={theme.colors.ErrorState}
              />
            </div>
          )}

          <button hidden={true} ref={refSubmitButtom} type={'submit'} />
        </form>
      </ActionLayout>
    </Modal>
  )
}

export default PSISetting
