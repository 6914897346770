import styled from 'styled-components'

export const Container = styled.div`
  align-self: center;
  position: relative;
`

export const Input = styled.input`
  align-self: center;
  z-index: 2;
  /* width: 80%; */
  border-radius: 50px;
  padding: 3px 0px;
  text-align: center;
  border-width: 0;

  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  height: 31px;
  /* min-width: 40px; */
  background-color: ${(props) => props.theme.colors.Primary};
  color: ${(props) => props.theme.colors.White};

  :focus {
    outline: none;
  }
`
