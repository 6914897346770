import { Button } from '../Button'
import { Container, Section } from './ActionBar.styles'

interface IActionBar {
  leftActions?: any
  rightActions?: any
}

const ActionBar = ({ leftActions = [], rightActions = [] }: IActionBar) => {
  return (
    <Container>
      <Section>
        {leftActions.map((action) => (
          <Button key={action.key} {...action} />
        ))}
      </Section>
      <Section>
        {rightActions.map((action) => (
          <Button key={action.key} {...action} />
        ))}
      </Section>
    </Container>
  )
}

export default ActionBar
