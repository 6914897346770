import styled from 'styled-components'
import { theme } from 'venus/config'

interface IBtnWrapper {
  hideUnderline: boolean
}

export const BtnWrapper = styled.div<IBtnWrapper>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    text-decoration: ${({ hideUnderline }) =>
      hideUnderline ? 'none' : 'underline'};
    text-underline-offset: 3px;
    text-decoration-color: ${theme.colors.DarkGrey};
  }
`
