import { useState } from 'react'
import { Modal } from 'venus/components'
import { Add_Dream_Property_Type } from 'venus/config'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { AddHome } from 'venus/common/AddProperty/AddHome'
import { ConfirmHome } from 'venus/common/AddProperty/ConfirmHome'
import { ConfirmNonNpdHome } from 'venus/common/AddProperty/ConfirmNonNpdHome'
import { ConfirmNonNpdHomePhoto } from 'venus/common/AddProperty/ConfirmNonNpdHomePhoto'
import { SelectHome } from 'venus/common/AddProperty//SelectHome'

interface IAddProperty {
  isOpen: boolean
  onClose: () => void
  onFinish: () => void
  addType?: string
}

export const AddProperty = ({
  isOpen,
  onClose,
  onFinish,
  addType,
}: IAddProperty) => {
  const addHomeTitle =
    addType === Add_Dream_Property_Type ? 'Add Dream Property' : 'Add your home'
  const [step, setStep] = useState(1)
  const [title, setTitle] = useState(addHomeTitle)
  const { addressId, preview } = useAppSelector((state) => state.address)
  const displayBackButton = step !== 1

  const handleGoBack = () => {
    const goToStep1 = () => {
      setStep(1)
      setTitle(addHomeTitle)
    }

    const goToStep4 = () => {
      setStep(4)
      const title =
        addType === Add_Dream_Property_Type
          ? 'Confirm Dream Property'
          : 'Confirm your home'
      setTitle(title)
    }

    const goToStep5 = () => {
      setStep(5)
      const title =
        addType === Add_Dream_Property_Type
          ? 'Select Dream Property'
          : 'Select Your Property'
      setTitle(title)
    }

    if (step === 4 || step === 5) {
      goToStep1()
    }
    if (step === 2) {
      if (!!addressId && preview.length > 1) {
        goToStep5()
      } else {
        goToStep1()
      }
    }
    if (step === 3) {
      if (!addressId && !addType) {
        goToStep4()
      } else if (!!addressId && preview.length > 1) {
        goToStep5()
      } else {
        goToStep1()
      }
    }
  }

  const handleClose = () => {
    setStep(1)
    setTitle(addHomeTitle)
    onClose()
  }

  // 2: 19 clanwilliam st
  // 3: 17 clancy ct
  // 4: 5 Fawcett Rd, BYFORD WA 6122
  // 5: 7 clanwilliam st

  // 4: !addressId, !addType
  // 5: addressId, preview.length > 1

  // 1 -> 4 -> 3
  // 1 -> 3
  // 1 -> 2
  // 1 -> 5 -> 2 / 3

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onBack={displayBackButton ? handleGoBack : null}
      onClose={handleClose}
      style={{
        overlay: {
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/png/bg.png) no-repeat center`,
          backgroundSize: 'cover',
        },
      }}
    >
      {step === 1 && (
        <AddHome setStep={setStep} setTitle={setTitle} addType={addType} />
      )}
      {step === 2 && <ConfirmHome addType={addType} onFinish={onFinish} onClose={handleClose}/>}
      {step === 3 && (
        <ConfirmNonNpdHome onFinish={onFinish} addType={addType} handleGoBack={handleGoBack}/>
      )}
      {step === 4 && (
        <ConfirmNonNpdHomePhoto
          setStep={setStep}
          setTitle={setTitle}
          addType={addType}
        />
      )}
      {step === 5 && (
        <SelectHome setStep={setStep} setTitle={setTitle} addType={addType} />
      )}
    </Modal>
  )
}
