import React from 'react'
import { ContentsTitle } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader/SettingsHeader.styles'
import { BackBtnWrapper } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader/SettingsHeader.styles'
import { Text } from 'venus/components'
import { CloseBtnWrapper } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader/SettingsHeader.styles'
import { ReactComponent as BackIcon } from 'venus/assets/img/icons/Back.svg'

const SettingsHeader = ({
  title,
  onBackBtnClick,
  style = {},
}: {
  title: string
  onBackBtnClick?: Function
  style?: any
}) => {
  return (
    <ContentsTitle style={style}>
      <BackBtnWrapper
        onClick={() => {
          onBackBtnClick()
        }}
      >
        {onBackBtnClick && <BackIcon />}
      </BackBtnWrapper>
      <Text format={'h1'} value={title} />
      <CloseBtnWrapper />
    </ContentsTitle>
  )
}

export default SettingsHeader
