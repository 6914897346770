import isEmail from 'validator/lib/isEmail'
import { signUpMsg, roles, minPasswordLength } from 'venus/config'

export const verifyInfo = (
  oAuthType: string | undefined,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  selectedRoles: string[]
): string => {
  const errorMsg = verifyInfoWithoutPassword(
    firstName,
    lastName,
    email,
    selectedRoles
  )
  if (!errorMsg && !oAuthType) return verifyPwd(password)
  return errorMsg
}

export const verifyInfoWithoutPassword = (
  firstName: string,
  lastName: string,
  email: string,
  selectedRoles: string[]
): string => {
  if (firstName.trim() === '') return signUpMsg.emptyFirstName
  if (lastName.trim() === '') return signUpMsg.emptyLastName
  if (email.trim() === '') return signUpMsg.emptyEmail
  if (!validateEmail(email.trim())) return signUpMsg.invalidEmailFormat
  if (selectedRoles[0] === '') return signUpMsg.emptyRole
  if (selectedRoles[0] === roles[1].value && selectedRoles[1] === '')
    return signUpMsg.emptyTradespeople
  return ''
}

export const validateEmail = (address: string) => {
  return isEmail(address)
}

const hasNumber = (str: string) => {
  return /\d/.test(str)
}

const hasUpperCase = (str: string) => {
  return str.toLowerCase() !== str
}

const hasLowerCase = (str: string) => {
  return str.toUpperCase() !== str
}

const hasSpecialCharacters = (str: string) => {
  const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return format.test(str)
}

export const verifyPwd = (password: string) => {
  if (!password) return signUpMsg.emptyPassword
  if (password.length < minPasswordLength) return signUpMsg.invalidPassword
  if (!hasNumber(password)) return signUpMsg.invalidPassword
  if (!hasUpperCase(password)) return signUpMsg.invalidPassword
  if (!hasLowerCase(password)) return signUpMsg.invalidPassword
  if (!hasSpecialCharacters(password)) return signUpMsg.invalidPassword
  return ''
}

export const verifyResetPwd = (
  storedPwd: string,
  oldPwd: string,
  newPwd1: string,
  newPwd2: string
) => {
  if (storedPwd !== oldPwd) return signUpMsg.wrongPassword
  if (newPwd1 !== newPwd2) return signUpMsg.passwordNotMatch
  return verifyPwd(newPwd1)
}
