import _ from 'lodash'
import React from 'react'
import { theme } from 'venus/config'
import { Text, PillTag, ActionLayout } from 'venus/components'
import { getFullName } from 'venus/utils/fullName'
import { BodyPanel, TagsWrapper, CostWrapper, RowWrapper } from 'venus/pages/History/HistoryForm/HistoryForm.styles'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'
import { formatPriceWithDollarSign } from 'venus/utils'
import { useAppSelector } from 'venus/redux/hooks'

const HistoryFormReadonly = ({
  toggleEditMode,
  isShared = false,
}: {
  toggleEditMode: () => void
  isShared: boolean
}) => {
  const { history } = useAppSelector((state) => state.history)
  const tags = _.get(history, ['tags'], [])
  const contact = _.get(history, ['contacts', '0'], '')
  const contactName = getFullName(contact)

  return (
    <ActionLayout
      margin={36}
      children={
        <BodyPanel>
          <Text format={'h2'} value={'Location'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <TagsWrapper>
            {tags.map((tag, index) => {
              if (index === 0) {
                return <PillTag key={index} format="h4" label={tag}/>
              }
              return (
                <PillTag
                  key={index}
                  format="h4"
                  label={tag}
                  background={theme.colors.LightBlue}
                  color={theme.colors.Black}
                />
              )
            })}
          </TagsWrapper>
          <Text format={'h2'} value={'Tradespeople'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
          <TagsWrapper>
            <PillTag
              format="h4"
              label={_.truncate(contactName, { length: 80 })}
              background={theme.colors.LightBlue}
              color={theme.colors.Black}
            />
          </TagsWrapper>
          <RowWrapper>
            <Text format={'h2'} value={'Title'} style={{ textAlign: 'left', marginBottom: '18px'  }}/>
            <Text
              format={'body'}
              value={history?.eventName}
              style={{ textAlign: 'left', marginBottom: '18px'  }}
            />
          </RowWrapper>
          <RowWrapper>
            <Text format={'h2'} value={'Date'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
            <Text
              format={'body'}
              value={history?.eventDate}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
          </RowWrapper>
          <CostWrapper>
            <RowWrapper>
              <Text format={'h2'} value={'Item Cost'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
              <Text
                format={'body'}
                value={formatPriceWithDollarSign(history?.itemCost?.toString())}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
            </RowWrapper>
            <RowWrapper>
              <Text format={'h2'} value={'Labour Cost'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
              <Text
                format={'body'}
                value={formatPriceWithDollarSign(history?.laborCost?.toString())}
                style={{ textAlign: 'left', marginBottom: '18px' }}
              />
            </RowWrapper>
          </CostWrapper>
          <RowWrapper>
            <Text format={'h2'} value={'Notes'} style={{ textAlign: 'left', marginBottom: '18px' }}/>
            <Text
              format={'body'}
              value={history?.notes}
              style={{ textAlign: 'left', marginBottom: '18px' }}
            />
          </RowWrapper>
        </BodyPanel>
      }
      rightActions={!isShared && [
        {
          type: 'icon_button_tertiary',
          text: 'Edit',
          onClick: toggleEditMode,
          iconLeft: (
            <EditIcon style={{ marginRight: '9px', color: theme.colors.Primary }}/>
          ),
        },
      ]}
    />
  )
}

export default HistoryFormReadonly
