import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 22px;
`

export const EmptyArea = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`