import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Text } from 'venus/components'
import { ReactComponent as Remove } from 'venus/assets/img/icons/Remove.svg'
import { Button, SpaceItemContainer } from './EditSpaces.styles'

const portal = document.createElement('div')
document.body.appendChild(portal)

const SpaceItem = ({ id, name, snapshot, provided, onDelete }) => {
  const handleDelete = () => onDelete(id, name)
  const usePortal: boolean = snapshot.isDragging
  const child = (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      <SpaceItemContainer>
        <Button onClick={handleDelete}>
          <Remove />
        </Button>
        <Text format='h4'>{name}</Text>
      </SpaceItemContainer>
    </div>
  )

  if (!usePortal) {
    return child
  }

  return ReactDOM.createPortal(child, portal)
  //   return child
}

export default SpaceItem
