export const gradientPrefix = 'gradient://'

export const colorPalette = [
  '#F39293-#F39293',
  '#F3CC92-#F3CC92',
  '#F1F392-#F1F392',
  '#ABF392-#ABF392',
  '#8DDA8C-#8DDA8C',
  '#BAD02F-#BAD02F',
  '#A8EDE5-#A8EDE5',
  '#F392E9-#F392E9',
  '#C892F3-#C892F3',
  '#385A79-#385A79',
  '#228C46-#228C46',
  '#B94A27-#B94A27',
  '#F3CC92-#F3CC92',
  '#1F8B51-#929CF3',
  '#F392C1-#DDFF0D',
  '#2C363F-#794BDB'
]
