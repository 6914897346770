import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { baseURL } from 'venus/api'
import { IShare } from './types'
import {
  getShareList,
  createShareLink,
  createShareTo,
  getShareItem,
  deleteShareItem,
  deleteReceivedShareProperty,
  addReceivedShareProperty,
  getReceivedShareProperty,
} from './async'
import { getErrorMsg } from 'venus/utils'

const initialState: IShare = {
  isOpen: false,
  propertyId: '',
  isShareDocument: false,
  isShareHistory: false,
  isShareWholeProperty: false,
  shareScope: { value: [] },
  eventName: '',
  sharedContact: '',
  notes: '',
  isSharedAnonymously: false,
  isGenerateQRCode: false,
  isSharedDreamPrice: false,
  share: {
    shareTo: '',
    shareMethod: '',
  },
  shareList: [],
  shareLink: '',
  shareItem: {},
  receivedShare: {
    propertyData: { area: 0, beds: 0, baths: 0, parking: 0, propertyType: '' },
    address: { state: '', suburb: '', address: '', postcode: '' },
    spaces: [{ coverImage: '' }],
    coverImage: '',
  },
  receivedSharePropertyList: [],
  receivedShareInfoList: [],
  loading: false,
  error: '',
}

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    setOpenShareModal: (state, action) => {
      state.isOpen = action.payload
    },
    resetShareStore: () => initialState,
    resetShareList: (state) => {
      state.shareList = []
    },
    saveShareScope: (
      state,
      action: PayloadAction<{
        propertyId: string
        isShareDocument: boolean
        isShareHistory: boolean
        isShareWholeProperty: boolean
        shareScope: {
          spaceId: string
          itemId: string
          spaceName: string
          itemName: string
        }[]
      }>,
    ) => {
      const { payload } = action
      const {
        propertyId,
        isShareDocument,
        isShareHistory,
        isShareWholeProperty,
        shareScope,
      } = payload as any
      state.propertyId = propertyId
      state.isShareDocument = isShareDocument
      state.isShareHistory = isShareHistory
      state.isShareWholeProperty = isShareWholeProperty
      if (isShareWholeProperty) {
        state.shareScope = { value: shareScope }
      } else {
        let formattedShareScope: {
          spaceId: string
          spaceName: string
          items: { itemId: string; itemName: string }[]
        }[] = []

        shareScope.forEach(({ spaceId, itemId, spaceName, itemName }) => {
          const existingSpace = formattedShareScope.find(
            (space) => space.spaceId === spaceId,
          )

          if (!existingSpace) {
            formattedShareScope.push({
              spaceId,
              spaceName,
              items: itemId ? [{ itemId, itemName }] : [],
            })
          } else {
            existingSpace.items.push({ itemId, itemName })
            formattedShareScope = [
              ...formattedShareScope.filter((space) => space.spaceId !== spaceId),
              existingSpace,
            ]
          }
        })
        state.shareScope = { value: formattedShareScope }
      }
    },
    resetError: (state) => {
      state.error = ''
    },
    saveSettings: (
      state,
      action: PayloadAction<{
        eventName: string
        notes: string
        isSharedAnonymously: boolean
        isGenerateQRCode: boolean
        shareLink: string
      }>,
    ) => {
      const { payload } = action
      const { eventName, notes, isSharedAnonymously, isGenerateQRCode, shareLink } = payload
      state.eventName = eventName
      state.notes = notes
      state.isSharedAnonymously = isSharedAnonymously
      state.isGenerateQRCode = isGenerateQRCode
      const shareLinkWithBaseUrl = shareLink.replace('https://undefined', baseURL)
      state.shareLink = shareLinkWithBaseUrl
    },
    resetReceivedShare: (state) => {
      state.receivedSharePropertyList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShareList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getShareList.fulfilled, (state, action) => {
      state.shareList = action.payload
      state.loading = false
    })
    builder.addCase(getShareList.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(createShareLink.pending, (state) => {
      // state.loading = true
    })
    builder.addCase(createShareLink.fulfilled, (state, action) => {
      // state.loading = false
    })
    builder.addCase(createShareLink.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      // state.loading = false
    })
    builder.addCase(createShareTo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createShareTo.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(createShareTo.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(getShareItem.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getShareItem.fulfilled, (state, action) => {
      state.shareItem = action.payload
      state.loading = false
    })
    builder.addCase(getShareItem.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(deleteShareItem.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteShareItem.fulfilled, (state, action) => {
      state.shareList = action.payload
      state.loading = false
    })
    builder.addCase(deleteShareItem.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(getReceivedShareProperty.pending, (state) => {
      state.receivedShare = {
        propertyData: {
          area: 0,
          beds: 0,
          baths: 0,
          parking: 0,
          propertyType: '',
        },
        address: { state: '', suburb: '', address: '', postcode: '' },
        spaces: [{ coverImage: '' }],
        coverImage: '',
      }
      state.loading = true
    })
    builder.addCase(getReceivedShareProperty.fulfilled, (state, action) => {
      state.receivedShare = action.payload
      state.loading = false
    })
    builder.addCase(getReceivedShareProperty.rejected, (state, action) => {
      state.receivedShare = {
        propertyData: {
          area: 0,
          beds: 0,
          baths: 0,
          parking: 0,
          propertyType: '',
        },
        address: { state: '', suburb: '', address: '', postcode: '' },
        spaces: [{ coverImage: '' }],
        coverImage: '',
      }
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(addReceivedShareProperty.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addReceivedShareProperty.fulfilled, (state, action) => {
      state.receivedSharePropertyList = action.payload.properties
      state.receivedShareInfoList = action.payload.shares
      state.loading = false
    })
    builder.addCase(addReceivedShareProperty.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
  },
})

export const {
  setOpenShareModal,
  resetShareList,
  saveShareScope,
  saveSettings,
  resetError,
  resetReceivedShare,
  resetShareStore,
} = shareSlice.actions

export default shareSlice.reducer
