import { theme } from 'venus/config'
import { Text } from 'venus/components'
import { MenuContentWrapper, MenuItemWrapper } from './MenuText.styles'

interface IMenuText {
  title: string
  text: string
  label?: string
  hideBottomLine?: boolean
  style?: any
}

export const MenuText = ({
  title,
  text,
  label = '',
  hideBottomLine = false,
  style = {},
}: IMenuText) => {
  return (
    <MenuContentWrapper style={{ ...style }}>
      <MenuItemWrapper>
        <Text
          value={title}
          format='h3'
          color={theme.colors.DarkestGrey}
          style={{ marginBottom: '6px' }}
        />
        <Text
          value={text}
          format='body'
          color={theme.colors.Primary}
          style={{ height: '18px' }}
        />
        <Text
          value={label}
          format='body'
          color={theme.colors.SafeGreen}
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        />
      </MenuItemWrapper>
    </MenuContentWrapper>
  )
}
