import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { shareDreamPrice, setError, resetError } from 'venus/redux/features/dreamprice'
import { getProperty } from 'venus/redux/features/property/property'
// import useShowPremium from 'venus/hooks/useShowPremium'
import { extractNumber } from 'venus/utils'
import { Text, PriceInput, AlertBox, ActionLayout } from 'venus/components'
import { theme, FREE, Max_Price } from 'venus/config'
// import MHVPremium from 'mhv/scenes/Transfer/MHVPremium'
import { ShareScopeArea, TitleWrapper } from './SetDreamPrice.styles'

interface ISetDreamPrice {
  onClose?: () => void
}

export const SetDreamPrice = ({ onClose }: ISetDreamPrice) => {
  const dispatch = useAppDispatch()
  const { property } = useAppSelector((state) => state.property)
  const propertyId = property?.id
  const { shareScope, isShareWholeProperty } = useAppSelector((state) => state.share)
  const { error, loading } = useAppSelector((state) => state.dreamprice)
  const [price, setPrice] = useState<string | number>(0)
  const [showFreemiumAlert, setShowFreemiumAlert] = useState(false)
  const { vaults } = useAppSelector((state) => state.subscription)
  const subscriptionStatus = vaults[propertyId]
  // const { onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )

  // const handleOnCancel = () => {
  //   setShowFreemiumAlert(false)
  //   onCancel()
  // }

  // const handleViewPlan = () => {
  //   setShowFreemiumAlert(false)
  //   onViewPlan()
  // }

  const handlePressSetPrice = async () => {
    // if (!subscriptionStatus || subscriptionStatus === FREE) {
    //   setShowFreemiumAlert(true)
    //   return
    // }
    const dreamPrice = extractNumber(String(price))
    if (dreamPrice > Max_Price) {
      dispatch(setError(`Dream Price can not be more than $99,999,999,999.`))
      return
    }
    if (dreamPrice < 0) {
      dispatch(setError(`Dream Price can not be less than $0.`))
      return
    }
    const data = await dispatch(
      shareDreamPrice({
        isShareWholeProperty,
        propertyId,
        shareScope,
        dreamPrice,
      }),
    )
    if (data.meta.requestStatus === 'fulfilled') {
      await dispatch(getProperty({ propertyId }))
      onClose()
    }
  }

  const handlPressAlertOK = () => {
    dispatch(resetError())
  }

  const labels = isShareWholeProperty ? [{ name: 'Entire Property', type: 'space' }] : []
  if (!isShareWholeProperty) {
    shareScope.value.forEach(({ spaceName, items }) => {
      labels.push({
        name: spaceName,
        type: 'space',
      })
      if (!!items && items.length > 0) {
        items.forEach(({ itemName }) => {
          labels.push({
            name: itemName,
            type: 'item',
          })
        })
      }
    })
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          isDisabled: !price,
          isLoading: loading,
          text: 'Share Dream Price',
          onClick: handlePressSetPrice,
        },
      ]}
    >
      <>
        <Text
          color={theme.colors.Black}
          format='h3'
          value='Shared'
          style={{
            alignSelf: 'flex-start',
          }}
        />
        <ShareScopeArea>
          {labels.map(({ name, type }, index: number) => (
            <TitleWrapper
              key={`Share_Scope_${index}`}
              style={{
                background:
                  type === 'space' ? theme.colors.Primary : theme.colors.LightBlue,
              }}
            >
              <Text
                format='h3'
                value={name}
                color={type === 'space' ? theme.colors.White : theme.colors.Primary}
                style={{
                  textTransform: 'capitalize',
                }}
              />
            </TitleWrapper>
          ))}
        </ShareScopeArea>
        <PriceInput label='Dream Price' value={Number(price)} onChange={setPrice} />
        <AlertBox
          cancel={() => null}
          title='Set Dream Price Failed'
          message={error}
          btnGroup={[{ text: 'OK', onClick: handlPressAlertOK, type: 'primary' }]}
          showAlert={!!error}
        />
        {/* <MHVPremium
        isOpen={showFreemiumAlert}
        onViewPlan={handleViewPlan}
        onCancel={handleOnCancel}
      /> */}
      </>
    </ActionLayout>
  )
}
