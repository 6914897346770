import { useMemo, useState } from 'react'
import ImageGrids from '../components/ImageGrids'
import { useEffect } from 'react'
import { Button } from 'venus/components'
import { buildURLQuery } from 'venus/utils/url'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import AddItem from 'venus/common/AddItem'
import useFetchTemplate from 'venus/hooks/useFetchTemplate'
import { extractNumber, generateRandomCoverImage } from 'venus/utils'
import api from 'venus/api'
import { useAppDispatch } from 'venus/redux/hooks'
import { addItems } from 'venus/redux/features/space/space'
import { addNewItem } from 'venus/redux/features/property/property'

const Items = ({ propertyId, spaceId, items, setTabButtons, shareId, dreamPropertyId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const template = useFetchTemplate({ propertyId, spaceId })
  const [selectedItem, setSelectedItem] = useState({})
  const dispatch = useAppDispatch()

  useEffect(() => {
    // set the header button
    if (!shareId && !dreamPropertyId) {
      setTabButtons(
        <Button
          type='icon_button'
          iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
          style={{ width: 'auto' }}
          fullBlock={false}
          onClick={() => setIsOpen(true)}
          text='Add Item'
        />,
      )
    }

    // remove the header button on detach this component
    return () => {
      setTabButtons(<></>)
    }
  }, [])

  const grids = useMemo(() => {
    if (shareId || dreamPropertyId) {
      return (items || []).map((item) => ({
        id: item.id,
        image: item.coverImage,
        url: `/property/${propertyId}/space/${spaceId}/item/${item.id}?${buildURLQuery({
          shareId,
          dreamPropertyId,
        })}`,
        name: item.name,
      }))
    }
    const itemObjects = (items || []).map((item) => ({
      id: item.id,
      image: item.coverImage,
      url: `/property/${propertyId}/space/${spaceId}/item/${item.id}`,
      name: item.name,
    }))

    const exsitingTypes = new Set((items || []).map((item) => item.typeId))

    const remaining = template
      .filter(({ type }) => !exsitingTypes.has(type))
      .map(({ type, name }) => ({
        id: type,
        defaultType: type,
        name,
      }))

    return [...itemObjects, ...remaining]
  }, [items, template, shareId])

  const openAddItemModal = (grid) => {
    setIsOpen(true)
    setSelectedItem({
      type: grid.defaultType,
      name: grid.name,
    })
  }

  const onSubmit = async (data) => {
    // setIsLoading(true)
    console.log('new item data', data)
    const formattedData = {
      ...data,
      value: extractNumber(data.value),
    }
    // const formatData = data.images
    //   ? formattedData
    //   : { ...formattedData, coverImage: generateRandomCoverImage() }
    try {
      const response = await api.post(`/property/${propertyId}/space/${spaceId}/items`, [
        { ...formattedData, coverImage: generateRandomCoverImage() },
      ])
      const updated = response.data
      dispatch(addItems(updated))
      dispatch(addNewItem(updated))
      // setIsLoading(false)
      // navigation.goBack()
      setIsOpen(false)
      setSelectedItem({})
    } catch (error) {
      // setIsLoading(false)
    }
  }

  return (
    <>
      <ImageGrids grids={grids} onClick={openAddItemModal} />
      <AddItem
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        template={template}
        onSubmit={onSubmit}
        {...selectedItem}
      />
    </>
  )
}

export default Items
