import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from 'venus/config'
import { Button, Text, Loader, Image, Modal } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  getReceivedShareProperty,
  addReceivedShareProperty,
  resetError,
} from 'venus/redux/features/share'
import { formatFullAddress, getAddressPreviewStr } from 'venus/utils'
import {
  BodyArea,
  ImageWrapper,
  ImageContent,
  ButtonsWrapper,
} from './AddReceivedShare.styles'

const AddReceivedShare = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [code, setCode] = useState('')

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')

    if (code) {
      setCode(code)
      dispatch(getReceivedShareProperty(code))
    }
  }, [code])

  const { receivedShare, loading } = useAppSelector((state) => state.share)
  const { address, propertyData, coverImage } = receivedShare
  const { state, suburb, address: addressDetails, postcode } = address
  const { area, beds, baths, propertyType } = propertyData

  console.log('receivedShare', receivedShare, coverImage)

  const handlePressGoBack = () => {
    navigate('/?activeIndex=Shared%20Properties')
  }

  const handlePressOK = async () => {
    const data = await dispatch(addReceivedShareProperty(code))
    if (data.meta.requestStatus === 'fulfilled') {
      handlePressGoBack()
    }
  }

  if (loading) {
    return <Loader isFull />
  }

  return (
    <Modal isOpen={true} showCloseButton={false} title={'Add Share Property'}>
      <BodyArea>
        <div>
          <Text
            value='The homeowner wants to share you the property. Do you want to add this property into your shared property list?'
            format='h4'
            style={{
              textAlign: 'center',
              margin: 24,
              marginTop: 10,
              marginBottom: 0,
              display: 'inline-block',
            }}
          />
          <ImageWrapper>
            <ImageContent>
              <Image enablePreview={false} image={coverImage} />
            </ImageContent>
            <Text
              value={getAddressPreviewStr({ beds, baths, area, propertyType })}
              color={theme.colors.DarkGrey}
              format='h3'
              style={{ alignSelf: 'flex-start', marginTop: 12 }}
            />
            <Text
              value={formatFullAddress({
                address: addressDetails,
                suburb,
                state,
                postcode,
              })}
              color={theme.colors.Black}
              format='h3'
              style={{ alignSelf: 'flex-start', marginTop: 6 }}
            />
          </ImageWrapper>
        </div>
        <ButtonsWrapper>
          <Button
            onClick={handlePressGoBack}
            type='secondary'
            text='Cancel'
            style={{
              height: '36px',
              padding: '4px 28px',
              width: '136px',
              margin: '24px',
              alignSelf: 'flex-end',
            }}
          />
          <Button
            onClick={handlePressOK}
            type='primary'
            text='Add Property'
            style={{
              height: '36px',
              padding: '4px 28px',
              width: '160px',
            }}
          />
        </ButtonsWrapper>
      </BodyArea>
    </Modal>
  )
}

export default AddReceivedShare
