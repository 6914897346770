import styled from 'styled-components'
import { Checkbox, Text } from 'venus/components'

const ItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const ItemContent = styled.li`
  display: flex;

  padding: 5px 15px;
  width: 50%;
  flex-direction: row;
  align-items: center;
`

const Templates = ({ checkedItems, template, handleCheckItem }) => {
  return (
    <ItemList>
      {template.map((item) => (
        <ItemContent key={item.type}>
          <Checkbox
            noMargin
            checked={!!checkedItems[item.name]}
            onChange={() => handleCheckItem(item.name)}
          />
          <Text format='h4' value={item.name} />
        </ItemContent>
      ))}
    </ItemList>
  )
}

export default Templates
