import styled from 'styled-components'
import { theme } from 'venus/config'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const SharedInfoArea = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 6px;
  border-radius: 6px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: ${theme.colors.LightBlue};
  }
`

export const LeftSide = styled.div`
  margin-top: 6px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const RightSide = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TitleWrapper = styled.div`
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  background: ${theme.colors.Primary};
  border-radius: 50px;
  margin-top: 3px;
  margin-right: 6px;
`

export const ShareScopeArea = styled.div`
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
`

export const EmptyDataWrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const BodyArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`

export const IconButton = styled.div`
  cursor: pointer;
`

export const ContentArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const HeaderArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 32px 0;
`

export const HistoryContainer = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
`

export const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HistoryTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 32px 36px;
`

export const HistoryBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.LighterGrey};

  margin: 72px 36px 0px;
  padding-bottom: 20px;
`

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;
  &:hover {
    button {
      svg {
        color: white;
      }
    }
  }
`
