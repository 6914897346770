import { useState, useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import {
  BottomActions,
  CircleButton,
  CloseButton,
  Image,
  ImagePreviewContainer,
  PredictionsContainer,
} from './ImagePreview.styles'
import { ReactComponent as Close } from 'venus/assets/img/icons/Close.svg'
import { ReactComponent as Chevron } from 'venus/assets/img/icons/Chevron.svg'
import { ReactComponent as Delete } from 'venus/assets/img/icons/Delete.svg'
import { ReactComponent as MoveFiles } from 'venus/assets/img/icons/MoveFiles.svg'
import { ReactComponent as EyeOpen } from 'venus/assets/img/icons/EyeOpen.svg'
import { ReactComponent as EyeClose } from 'venus/assets/img/icons/EyeClose.svg'
import { AlertBox, PillTag } from 'venus/components'
import { MoveToModal } from '../MoveTo'
import Predictions from '../ScanHome/Predictions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useRefDimensions from 'venus/hooks/useRefDimensions'
import SpaceTag from '../ScanHome/SpaceTag'

const ImagePreview = ({
  isShared,
  isOpen,
  close,
  index,
  images,
  onDelete,
  onMove,
  psiId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(index)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [displayTag, setDisplayTag] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const imageRef = useRef()

  const navigate = useNavigate()

  const dimensions = useRefDimensions(imageRef)

  const openDeleteModal = () => setIsDeleteModalOpen(true)
  const closeDeleteModal = () => setIsDeleteModalOpen(false)

  const openMoveModal = () => setIsMoveModalOpen(true)
  const closeMoveModal = () => setIsMoveModalOpen(false)

  const closePreview = () => {
    if (!isShared) {
      setSearchParams({})
    }
    close()
  }

  useEffect(() => {
    setCurrentIndex(index)
  }, [index])

  const moveNext = () => {
    setCurrentIndex((index) => index + 1)
  }

  const movePrev = () => {
    setCurrentIndex((index) => index - 1)
  }

  // const handleMove = (props) => {
  //   onMove({ ...props, images: [images[index]] })
  // }

  const handleDelete = () => {
    closeDeleteModal()
    onDelete(images[index])
    closePreview()
  }

  const scale = Math.min(
    //@ts-ignore
    dimensions?.width / images[currentIndex]?.scan?.size?.width,
    //@ts-ignore
    dimensions?.height / images[currentIndex]?.scan?.size?.height,
  )

  const handleItemSelect = ({ itemId }) => {
    if (isShared) {
      return
    }

    if (itemId) {
      closePreview()
      const { propertyId, spaceId } = psiId
      setSearchParams({ activeIndex: 'Photos', index: currentIndex })
      navigate(`/property/${propertyId}/space/${spaceId}/item/${itemId}`)
    }
  }

  console.log('sc', dimensions, images[currentIndex]?.scan?.size, scale)

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closePreview}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 12,
          },
          content: {
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(16, 13, 66,0.8)',
            border: 0,
            padding: 0,
            height: '100%',
            width: '100%',
          },
        }}
      >
        {(index || index === 0) && (
          <ImagePreviewContainer>
            <CloseButton onClick={closePreview}>
              <Close />
            </CloseButton>

            <Image image={images[currentIndex]?.url}>
              <img
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                ref={imageRef}
                src={images[currentIndex]?.url}
                onLoad={() => {
                  window.dispatchEvent(new Event('resize'))
                }}
              />

              <PredictionsContainer
                width={images[currentIndex]?.scan?.size?.width * scale}
                height={images[currentIndex]?.scan?.size?.height * scale}
              >
                {displayTag && images[currentIndex]?.scan && (
                  <>
                    <PillTag
                      style={{
                        transform: 'translate(-50%, -40px)',
                        position: 'absolute',
                        left: '50%',
                      }}
                      format='h2'
                      label={images[currentIndex]?.scan?.space?.label}
                    />
                    <Predictions
                      viewOnly
                      onSelect={(props) => handleItemSelect(props)}
                      onSelectBox={() => {}}
                      selectedBox={null}
                      editing={false}
                      predictions={images[currentIndex]?.scan.predictions}
                      scale={scale}
                      width={images[currentIndex]?.scan?.size?.width || 1024}
                      height={images[currentIndex]?.scan?.size?.height || 768}
                    />
                  </>
                )}
              </PredictionsContainer>
            </Image>
          </ImagePreviewContainer>
        )}
        {currentIndex !== 0 && (
          <CircleButton
            style={{
              position: 'absolute',
              left: 80,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            onClick={movePrev}
          >
            <Chevron style={{ transform: 'rotate(90deg)' }} />
          </CircleButton>
        )}
        {currentIndex < images.length - 1 && (
          <CircleButton
            style={{
              position: 'absolute',
              right: 80,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            onClick={moveNext}
          >
            <Chevron style={{ transform: 'rotate(-90deg)' }} />
          </CircleButton>
        )}

        <BottomActions>
          {!isShared && !!images[currentIndex]?.scan?.predictions.length && (
            <CircleButton
              title={displayTag ? 'Hide Item Tags' : 'Show Item Tags'}
              onClick={() => setDisplayTag((display) => !display)}
            >
              {displayTag ? <EyeClose /> : <EyeOpen />}
            </CircleButton>
          )}
          {!images[currentIndex]?.scan?.predictions.length && onMove && (
            <CircleButton title='Move Image' onClick={openMoveModal}>
              <MoveFiles />
            </CircleButton>
          )}
          {onDelete && (
            <CircleButton title='Delete Image' onClick={openDeleteModal}>
              <Delete />
            </CircleButton>
          )}
        </BottomActions>
      </ReactModal>
      <AlertBox
        cancel={closeDeleteModal}
        title='Delete Photo'
        message={`Are you sure you want to delete this photo?`}
        btnGroup={[
          {
            type: 'error',
            text: 'Delete',
            onClick: handleDelete,
            color: 'ErrorState',
          },
          {
            type: 'secondary',
            text: 'Cancel',
            onClick: closeDeleteModal,
            color: 'LightBlue',
            textColor: 'Primary',
          },
        ]}
        showAlert={isDeleteModalOpen}
      />
      {onMove && (
        <MoveToModal
          isOpen={isMoveModalOpen}
          psiId={psiId}
          image={images[index]}
          type={'property'}
          close={closeMoveModal}
          handleMove={onMove}
        />
      )}
    </>
  )
}

export default ImagePreview
