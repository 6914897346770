import styled from 'styled-components'

type ContentProps = {
  readonly height?: number
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 480px;
  height: ${(props) => props.height || 340}px;

  background-color: ${(props) => props.theme.colors.White};
  overflow: hidden;
  border-radius: 10px;
`

export const Header = styled.div`
  border-bottom: 0.5px solid ${(props) => props.theme.colors.Grey};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
`

export const Footer = styled.div`
  border-top: 0.5px solid ${(props) => props.theme.colors.Grey};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 88px;
  padding: 24px;
`

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 46px;
`

type StatusCircleProps = {
  readonly color: string
}
export const StatusCircle = styled.div<StatusCircleProps>`
  align-self: center;

  height: 43px;
  width: 43px;
  border-radius: 50%;
  background-color: ${(props) => props.color};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Link = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colors.Secondary};
`
