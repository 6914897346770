import {
  ActionContainer,
  CloseButton,
  IconWrapper,
  PlayButton,
  VideoContainer,
  VideoLength,
  VideoPreviewContainer,
} from './Videos.styles'
import ReactPlayer from 'react-player'
import { AlertBox, Text } from 'venus/components'
import { theme } from 'venus/config'
import { ReactComponent as PlayIcon } from 'venus/assets/img/icons/Play.svg'
import { ReactComponent as Close } from 'venus/assets/img/icons/Close.svg'
import { ReactComponent as Delete } from 'venus/assets/img/icons/Delete.svg'

import { useState } from 'react'
import ReactModal from 'react-modal'

// PlayIcon

const formatTime = (seconds: string) => {
  if (!seconds) {
    return ''
  }
  const secs = parseInt(seconds, 10)

  const minutes = Math.floor(secs / 60)
  const sec = `${secs % 60}`.padStart(2, '0')
  return `${minutes}:${sec}`
}

const Video = ({ id, url, metaData, handleDelete, isShared }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const openConfirm = () => setIsConfirmOpen(true)
  const closeConfirm = () => setIsConfirmOpen(false)

  const onDelete = () => handleDelete(id)

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 12,
          },
          content: {
            top: '50%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(16, 13, 66,0.44)',
            border: 0,
            padding: 0,
            height: '100%',
            width: '100%',
          },
        }}
      >
        <VideoPreviewContainer>
          <CloseButton onClick={() => setIsOpen(false)}>
            <Close />
          </CloseButton>

          <ReactPlayer
            className='react-player'
            controls={true}
            url={url}
            width='100%'
            height='auto'
          />
        </VideoPreviewContainer>
      </ReactModal>

      <AlertBox
        cancel={closeConfirm}
        title='Delete video'
        message='Are you sure you want to remove this video?'
        btnGroup={[
          { type: 'error', text: 'Delete', onClick: onDelete },
          {
            type: 'secondary',
            text: 'Cancel',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: closeConfirm,
          },
        ]}
        showAlert={isConfirmOpen}
      />

      <VideoContainer>
        <ReactPlayer className='react-player' url={url} width='100%' height='auto' />
        <IconWrapper>
          <PlayButton onClick={() => setIsOpen(true)}>
            <PlayIcon color={theme.colors.Black} />
          </PlayButton>
        </IconWrapper>
        <VideoLength>
          <Text format='h4' color={theme.colors.White} style={{ padding: '4px 8px' }}>
            {formatTime(metaData?.duration)}
          </Text>
        </VideoLength>
        {!isShared && !!handleDelete && (
          <ActionContainer onClick={openConfirm}>
            <Delete color='white' />
          </ActionContainer>
        )}
      </VideoContainer>
    </>
  )
}

export default Video
