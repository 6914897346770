import PillTag, { IPillTagConfig } from './PillTag'
import { PillButtonWrapper } from './Pills.styles'

interface IPillButton extends IPillTagConfig {
  index?: number
  onClick: (...args: any[]) => void
  label: string
  style?: any
}

const PillButton = ({
  index,
  onClick,
  style,
  format = 'h3',
  ...pillProps
}: IPillButton) => {
  const handlePress = () => onClick(index)

  return (
    <PillButtonWrapper onClick={handlePress} style={style}>
      <PillTag format={format} {...pillProps} />
    </PillButtonWrapper>
  )
}

export default PillButton
