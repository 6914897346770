import jwt_decode from 'jwt-decode'

let token = ''

export const getAuthToken = () => {
  if (token) {
    const payload = jwt_decode<{ exp: number }>(token)
    if (payload.exp > Math.floor(Date.now() / 1000)) {
      return token
    }
  }

  return ''
}

export const setAuthToken = (accessToken: string) => {
  token = accessToken
}
