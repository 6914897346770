import React, { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Loader, AlertBox } from 'venus/components'
import { theme } from 'venus/config'
import { IProperty } from 'venus/redux/features/property/properties'
import { deleteReceivedShareProperty } from 'venus/redux/features/share'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getFullPropertyName } from 'venus/utils'
import { buildURLQuery } from 'venus/utils/url'
import PremiumTag from '../components/PremiumTag'
import ShareTag from '../components/ShareTag'
import TransferTag from '../components/TransferTag'
import ContextMenu from './ContextMenu'
import { PropertyCover } from './PropertyList.styles'

interface PropertyInterface extends IProperty {
  isPremium?: boolean
  isShared?: boolean
  type: 'property' | 'share' | 'dream'
  status?: any
  transfer?: object | null
}

const Property = ({
  id,
  coverImage,
  address,
  isPremium,
  shareId,
  sharedBy,
  type,
  status,
  propertyData,
  transfer,
}: PropertyInterface) => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.sharedProperties)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const isSharedDreamPrice = status === 'SHARE_DREAM_PRICE'

  const expressInterest = isSharedDreamPrice
    ? [
        {
          label: 'Express Interest',
          onClick: () => {},
        },
      ]
    : []

  const showConfirmationBox = () => {
    setShowConfirmation(true)
  }

  const closeConfirmationBox = () => {
    setShowConfirmation(false)
  }

  const deleteSharedProperty = useCallback(() => {
    dispatch(deleteReceivedShareProperty(shareId))
  }, [shareId])

  const contextMenu =
    type === 'dream'
      ? [
          ...expressInterest,
          {
            label: 'Remove dream property',
            onClick: () => {},
          },
        ]
      : [
          {
            label: 'Remove shared property',
            onClick: showConfirmationBox,
          },
        ]

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }
  return (
    <>
      <AlertBox
        key={shareId}
        showAlert={showConfirmation}
        cancel={closeConfirmationBox}
        title={`Delete Shared Property`}
        message={`Are you sure you want to delete this shared property?`}
        btnGroup={[
          {
            text: 'Cancel',
            type: 'secondary',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: closeConfirmationBox,
          },
          {
            text: 'Delete',
            type: 'error',
            color: 'Primary',
            textColor: 'White',
            onClick: deleteSharedProperty,
          },
        ]}
      />
      <NavLink to={`/property/${id}?${buildURLQuery({ shareId })}`}>
        <PropertyCover>
          {isPremium && (transfer ? <TransferTag /> : <PremiumTag />)}
          {!!sharedBy && <ShareTag sharedBy={sharedBy} />}
          <Text format='body' color={theme.colors.White}>
            {getFullPropertyName({ address, propertyData })}
          </Text>
        </PropertyCover>
      </NavLink>
      {type !== 'property' && <ContextMenu options={contextMenu} />}
    </>
  )
}

export default Property
