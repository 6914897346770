import styled from 'styled-components'
import { theme } from 'venus/config'

export const TitleArea = styled.div`
  width: 100%;
  height: 28px;
  background-color: ${theme.colors.LightBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const CheckBoxArea = styled.div`
  width: calc(100% - 56px);
  margin: 0 28px;
  margin-top: -9px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`

export const CheckBoxTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 3px;
  cursor: pointer;
`

export const CheckBoxView = styled.div`
  width: 100%;
  height: 24px;
  background: yellow;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 3px;
  flex-wrap: wrap;
`

export const CheckBoxContent = styled(CheckBoxTitle)``
