import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const Backdrop = styled.div`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/png/bg.png) no-repeat center;
  background-size: cover;
`

const DeeplinkLayout = () => {
  return (
    <Backdrop>
      <Outlet />
    </Backdrop>
  )
}

export default DeeplinkLayout
