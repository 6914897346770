import _ from 'lodash'

export const DocumentContentTypes = {
  LEGAL: 'Legal',
  DRAWING: 'Drawing',
  RECEIPT: 'Receipt',
  USER_MANUAL: 'User Manual',
  INSURANCE: 'Insurance',
  OTHER: 'Other',
}

export const DocumentContentTypeDropdown = _.sortBy(
  Object.entries(DocumentContentTypes).map(([key, value]) => ({
    label: value,
    value: key,
  })),
  (obj) => obj.label
)

export const Max_Number_Of_Docs_For_Free_Users = 3

export const File_Types = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/jpeg',
  'image/png',
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.file',
  'application/vnd.google-apps.folder',
  'application/vnd.google-apps.photo',
  'application/vnd.google-apps.presentation',
  'application/vnd.google-apps.spreadsheet',
]

export const tabs = [
  { key: 'ALL', label: 'All' },
  { key: 'LEGAL', label: 'Legal' },
  { key: 'DRAWING', label: 'Drawing' },
  { key: 'RECEIPT', label: 'Receipt' },
  { key: 'USER_MANUAL', label: 'User Manual' },
  { key: 'INSURANCE', label: 'Insurance' },
  { key: 'OTHER', label: 'Other' },
]
