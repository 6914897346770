import styled from 'styled-components'

type ImageBackgroundProps = {
  readonly image: string
}

export const ImageBackground = styled.div<ImageBackgroundProps>`
  position: relative;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  aspect-ratio: 1;
`

export const LoadingCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
`

export const LoadingIndicatorContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  right: 10%;
  height: 6px;
  background-color: ${(props) => props.theme.colors.Grey};
  border-radius: 3px;
`

type LoadingIndicatorProps = {
  readonly progress: number
}
export const LoadingIndicator = styled.div<LoadingIndicatorProps>`
  width: ${(props) => props.progress}%;
  height: 6px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.Primary};
`

export const ImagePlaceholder = styled.img`
  max-width: 100%;
  height: auto;
  opacity: 0;
`
