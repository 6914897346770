import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { DeleteAccount } from 'venus/layout/ProfileMenu/ProfileSettings/DeleteAccount'
import { useAppSelector } from 'venus/redux/hooks'
import { getFullName } from 'venus/utils/fullName'
import {
  AvatarContainer,
  Container,
  ProfileContainer,
  ProfileMenuContainer,
  ProfileMenuDropback,
} from './Profile.styles'
import { ReactComponent as Chevron } from 'venus/assets/img/icons/Chevron.svg'
import { useState } from 'react'
import ProfileMenu from 'venus/layout/ProfileMenu'
import ProfileSettings from 'venus/layout/ProfileMenu/ProfileSettings/ProfileSettings'
import { settingsMenus } from 'venus/layout/ProfileMenu/ProfileSettings/Constants'
import ProfileTabMenu from 'venus/layout/ProfileMenu/ProfileSettings/UserProfile'
import ChangePasswordForm from 'venus/layout/ProfileMenu/ProfileSettings/ChangePassword'
import { useAppDispatch } from 'venus/redux/hooks'
import { changePassword } from 'venus/redux/features/user'
import TransferOwnership from 'venus/layout/ProfileMenu/ProfileSettings/TransferOwnership'
import ClaimExistingVault from 'venus/layout/ProfileMenu/ProfileSettings/ClaimExistingVault'
import { Notification } from 'venus/components'
import { useEffect } from 'react'
import { Referral } from 'venus/layout/ProfileMenu/ProfileSettings/Referral'
import { Loader } from 'venus/components'
import Subscriptions from 'venus/layout/ProfileMenu/ProfileSettings/Subscriptions'
import { verifyPwd } from 'venus/utils'
import { Avatar } from 'venus/components'
import AdvancedSecurity from 'venus/layout/ProfileMenu/ProfileSettings/AdvancedSecurity/AdvancedSecurity'

const Profile = () => {
  const dispatch = useAppDispatch()
  const { info, loadingProfile } = useAppSelector((state) => state.user)
  const [isOpen, setIsOpen] = useState(false)
  const [isPremiumOpen, setIsPremiumOpen] = useState(true)
  const [showSettings, setShowSettings] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [isConfirmDeleteAccount, setConfirmDeleteAccount] = useState(false)

  useEffect(() => {
    if (notificationMessage) setShowNotification(true)
  }, [notificationMessage])

  const toggleNotification = () => {
    setShowNotification(!showNotification)
  }

  const toggleProfileMenu = () => setIsOpen((isOpen) => !isOpen)

  if (!info) {
    return <Container></Container>
  }

  const {
    profile: { avatar },
  } = info

  const handleOpenSettings = () => {
    setIsOpen(false)
    setShowSettings(true)
  }

  const handleCloseSettings = () => {
    setShowSettings(false)
  }

  const onChgPasswordSubmit = async (data) => {
    const { newPassword, confirmPassword } = data

    if (newPassword !== confirmPassword) {
      setNotificationMessage('Your new password and confirmation password do not match.')
      setShowNotification(true)
      return
    }

    const verifyMsg = verifyPwd(newPassword)
    if (verifyMsg) {
      setNotificationMessage(verifyMsg)
      setShowNotification(true)
      return
    }
    try {
      setNotificationMessage('You have updated your password.')
      await dispatch(changePassword(data))
      setShowNotification(true)
      handleCloseSettings()
    } catch (err) {
      return err
    }
  }

  if (loadingProfile) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  let childComponent
  if (isConfirmDeleteAccount) {
    childComponent = <DeleteAccount setConfirmDeleteAccount={setConfirmDeleteAccount} />
  } else {
    childComponent = (
      <ProfileSettings
        showSettings={showSettings}
        defaultActiveIndex={0}
        tabs={settingsMenus}
        paddingBottom={0}
        onClose={handleCloseSettings}
      >
        <ProfileTabMenu />
        <ChangePasswordForm
          onSubmit={onChgPasswordSubmit}
          handleCloseSettings={handleCloseSettings}
          setConfirmDeleteAccount={setConfirmDeleteAccount}
        />
        <TransferOwnership />
        <ClaimExistingVault />
        <Referral />
        <AdvancedSecurity />
        <Subscriptions />
      </ProfileSettings>
    )
  }
  return (
    <Container>
      <AvatarContainer>
        <Avatar size={32} avatar={avatar} isBorder={false} />
      </AvatarContainer>
      <ProfileContainer onClick={toggleProfileMenu}>
        <Text
          style={{ marginLeft: 4 }}
          format='body'
          fontSize={16}
          fontWeight={500}
          color={theme.colors.Primary}
        >
          {getFullName(info)}
        </Text>
        <Chevron style={{ color: theme.colors.Secondary }} />
      </ProfileContainer>
      {isOpen && (
        <>
          <ProfileMenuDropback onClick={toggleProfileMenu} />
          <ProfileMenuContainer>
            <ProfileMenu onClickSettings={handleOpenSettings} />
          </ProfileMenuContainer>
        </>
      )}
      {childComponent}
      <Notification
        showAlert={showNotification}
        closeAlert={toggleNotification}
        message={notificationMessage}
      />
      {/* <MHVPremium isOpen={isPremiumOpen} close={() => setIsPremiumOpen(false)} /> */}
    </Container>
  )
}

export default Profile
