import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import api from 'venus/api'

export const getUploadUrl = createAsyncThunk(
  'docs/getUploadUrl',
  async (
    filesInfo: { name: string; type: string; ext: string }[],
    { rejectWithValue }
  ) => {
    try {
      const getUrlResponse = await api.post('files', filesInfo)
      return getUrlResponse.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data.error)
    }
  }
)

export const uploadDoc = createAsyncThunk(
  'docs/uploadDoc',
  async (
    {
      uploadURL,
      content,
      type,
    }: { uploadURL: string; content: string | ArrayBuffer; type: string },
    { rejectWithValue }
  ) => {
    try {
      const uploadResponse = await axios.put(uploadURL, content, {
        headers: {
          'Content-Type': type,
        },
      })
      console.log('upload doc result::', uploadResponse.data)
      return uploadResponse.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const createDocInfo = createAsyncThunk(
  'docs/createDocInfo',
  async (
    body: {
      propertyId: string
      spaceId?: string
      itemId?: string
      documents: {
        displayName: string
        url: string
        contentType: string
      }[]
    },
    { rejectWithValue }
  ) => {
    try {
      console.log('createDocInfo body::', body)
      const assignDocsResponse = await api.post('/documents', body)
      console.log('createDocInfo response.data::', assignDocsResponse.data)
      return assignDocsResponse.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err?.data)
    }
  }
)

export const deletePsiDocs = createAsyncThunk(
  'docs/deletePsiDocs',
  async (
    body: {
      propertyId: string
      spaceId: string
      itemId?: string
      documents: string[]
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.delete('/documents', { data: body })
      console.log('deletePsiDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const deletePersonalDocs = createAsyncThunk(
  'docs/deletePersonalDocs',
  async (body: string[], { rejectWithValue }) => {
    try {
      const response = await api.delete('/personal/documents', { data: body })
      console.log('deletePersonalDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const deletePersonalItemDocs = createAsyncThunk(
  'docs/deletePersonalItemDocs',
  async ({ data, id }: { data: string[]; id: string }, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/personal/item/${id}/documents`, {
        data,
      })
      console.log('deletePersonalItemDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const moveDocs = createAsyncThunk(
  'docs/moveDocs',
  async (
    body: {
      source: {
        propertyId: string
        spaceId: string
        itemId?: string
      }
      target: {
        propertyId: string
        spaceId: string
        itemId?: string
      }
      documents: string[]
    },
    { rejectWithValue }
  ) => {
    try {
      console.log('moveDocs body::', body)
      const response = await api.put('/documents/move/', body)
      console.log('moveDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const getPersonalDocs = createAsyncThunk(
  'docs/getPersonalDocs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`/personal/documents`)
      console.log('getPersonalDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err?.data)
    }
  }
)

export const getPersonalItemDocs = createAsyncThunk(
  'docs/getPersonalItemDocs',
  async (
    { personalItemId }: { personalItemId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(
        `/personal/item/${personalItemId}/documents`
      )
      console.log('getPersonalItemDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err?.data)
    }
  }
)

export const createPersonalDocInfo = createAsyncThunk(
  'docs/createPersonalDocInfo',
  async (
    documents: { displayName: string; url: string; contentType: string }[],
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post('/personal/documents', documents)
      console.log('createPersonalDocInfo response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err?.data)
    }
  }
)

export const createPersonalItemDocInfo = createAsyncThunk(
  'docs/createPersonalItemDocInfo',
  async (
    {
      documents,
      id,
    }: {
      documents: { displayName: string; url: string; contentType: string }[]
      id: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(
        `/personal/item/${id}/documents`,
        documents
      )
      console.log('createPersonalItemDocInfo response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err?.data)
    }
  }
)

export const editPersonalDocs = createAsyncThunk(
  'docs/editPersonalDocs',
  async (
    body: {
      displayName: string
      url: string
      contentType: string
    }[],
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put('/personal/documents', body[0])
      console.log('editPersonalDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const editPersonalItemDocs = createAsyncThunk(
  'docs/editPersonalItemDocs',
  async (
    {
      body,
      id,
    }: {
      body: {
        displayName: string
        url: string
        contentType: string
      }[]
      id: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put(`/personal/item/${id}/documents`, body[0])
      console.log('editPersonalItemDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)

export const getPSIDocs = createAsyncThunk(
  'docs/getPSIDocs',
  async (
    {
      propertyId,
      propertyWideSpaceId,
      spaceId,
      itemId,
      type,
    }: {
      propertyId: string
      propertyWideSpaceId?: string
      spaceId?: string
      itemId?: string
      type: 'property' | 'space' | 'item'
    },
    { rejectWithValue }
  ) => {
    try {
      const url = {
        property: `/documents?propertyId=${propertyId}&spaceId=${propertyWideSpaceId}`,
        space: `/documents?propertyId=${propertyId}&spaceId=${spaceId}`,
        item: `/documents?propertyId=${propertyId}&spaceId=${spaceId}&itemId=${itemId}`,
      }
      const response = await api.get(url[type])
      console.log('getPSIDocs response.data::', response.data)
      return response.data
    } catch (error) {
      const err = error as any
      return rejectWithValue(err.data)
    }
  }
)
