import styled from 'styled-components'
import { theme } from 'venus/config'

export const DocumentListView = styled.div`
  width: 100%;
  padding: 12px;
`

export const CheckBoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0 12px;
  border-bottom-color: ${theme.colors.Grey};
  border-bottom-width: 0px;
`

export const BtnArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 24px;
`
