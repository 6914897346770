import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 84px;
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const IconArea = styled.div`
  position: absolute;
  top: 84px;
  right: 24px;
  &:hover {
    button {
      svg {
        color: white;
      }
    }
  }
`
