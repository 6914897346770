import styled from 'styled-components'

export const Layout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
`

export const SidePanel = styled.aside`
  width: 270px;
  height: 100%;

  background: ${(props) => props.theme.colors.LightGrey};
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.06);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const ChildrenContainer = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

export const ProfileHeader = styled.div`
  position: absolute;
  top: 20px;
  right: 24px;
  z-index: 10;
`

export const Header = styled.header`
  margin-left: 36px;
  margin-top: 52px;
  margin-bottom: 52px;
`

// export const Logo = styled.img`
//   height: 91px;
// `

export const Footer = styled.footer`
  margin: 40px 24px;
`

export const DownloadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DownloadIcon = styled.img`
  height: 33px;
  cursor: pointer;
`

export const Links = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
`
