import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
export const StarRatingWrapper = styled.div`
  margin: 10px 10px
`