import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Chevron, PillTag, Text } from 'venus/components'
type BoxProps = {
  readonly x: number
  readonly y: number
  readonly w: number
  readonly h: number
}
const Box = styled.div<BoxProps>`
  position: absolute;
  z-index: 0;
  left: ${(props) => props.x}px;
  top: ${(props) => props.y}px;

  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;

  border: 1px solid ${(props) => props.theme.colors.LighterGrey};

  align-items: center;
  justify-content: center;
`

const Prediction = ({
  id,
  itemId,
  onSelect,
  onSelectBox,
  bbox,
  label,
  type,
  score,
  width,
  height,
  viewOnly,
  scale,
}: any) => {
  const [size, setSize] = useState({ width: 0, height: 0 })

  const [x, y, w, h] = useMemo(() => {
    let [x, y, w, h] = bbox

    if (!w || !h) {
      console.log([x * scale - 50, y * scale - 50, 100, 100])

      return [x * scale - 50, y * scale - 50, 100, 100]
    }

    return [x * scale, y * scale, w * scale, h * scale]
  }, [bbox, scale])

  const handleSelect = (event) => {
    event.preventDefault()
    event.stopPropagation()
    onSelect({ id, bbox, label, type, itemId })
  }

  const handleSelectBox = () => {
    onSelectBox({ id, bbox })
  }

  const onLayout = ({ nativeEvent: { layout } }) => {
    setSize({ ...layout })
  }

  return (
    <>
      {/* <Box x={x} y={y} w={w} h={h}></Box> */}
      <PillTag
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: y + h / 2,
          left: x + w / 2,
          transform: 'translate(-50%, -50%)',
          // transform: [
          //   {
          //     translateX: '-50%',
          //   },
          //   { translateY: -size.height / 2 },
          // ],
        }}
        // onLayout={onLayout}
        onClick={handleSelect}
        // onLongPress={handleSelectBox}
        label={
          <>
            <Text
              color='White'
              value={label}
              format={'h3'}
              fontSize={14}
              fontWeight={400}
              style={{ whiteSpace: 'nowrap' }}
            />
            {/* <Chevron direction={viewOnly ? 'right' : 'down'} /> */}
          </>
        }
        fontSize={14}
        fontWeight={400}
        background={'rgba(73, 195, 202, 0.6)'}
        paddingVertical={1}
        paddingHorizontal={8}
        // textProps={{ numberOfLines: 1 }}
      />
      {/* <TouchableOpacity> <Box x={x} y={y} w={w} h={h}>*/}
      {/* </TouchableOpacity> </Box>*/}
    </>
  )
}

const Predictions = ({
  viewOnly = false,
  predictions = [],
  editing,
  width,
  height,
  onSelect,
  onSelectBox,
  selectedBox,
  scale,
}) => {
  const filtered = useMemo(
    () =>
      selectedBox ? predictions.filter((pre) => pre.id !== selectedBox.id) : predictions,
    [predictions, selectedBox],
  )

  return (
    <>
      {filtered.map((prediction) =>
        editing && editing.id === prediction.id ? null : (
          <Prediction
            key={prediction.id}
            {...prediction}
            {...{
              viewOnly,
              width,
              height,
              onSelect,
              onSelectBox,
              scale,
            }}
          />
        ),
      )}
    </>
  )
}

export default Predictions
