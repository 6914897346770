import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  updateReminder,
  deleteReminder,
  deleteLocalReminder,
} from 'venus/redux/features/reminder'
import {
  MenuText,
  Text,
  Select,
  AlertBox,
  Drawer,
  ActionLayout,
} from 'venus/components'
import { setHistory } from 'venus/redux/features/history/history'
import { theme } from 'venus/config'
import { ReactComponent as Clock } from 'venus/assets/img/icons/Clock.svg'
import { ReactComponent as ReminderClock } from 'venus/assets/img/icons/ReminderClock.svg'
import {
  SpaceLabelView,
  ItemLabelView,
  LabelArea,
  OptionWrapper,
  SelectWrapper,
  HeaderArea,
  RightBtnArea,
  ItemWrapper,
  PlaceHolder,
} from './Detail.styles'
import { CREATE_HISTORY_TITLE } from 'venus/pages/History/HistoryForm/Constants'

interface ISelect {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  onClose: () => void
  isOpen: boolean
  setIsOpen: Function
}

const options = [
  {
    label: (
      <OptionWrapper>
        <Clock />
        <Text
          value='Snooze for 1 day'
          format='h4'
          color={theme.colors.Black}
          style={{ marginLeft: 18 }}
        />
      </OptionWrapper>
    ),
    value: '1',
  },
  {
    label: (
      <OptionWrapper>
        <Clock />
        <Text
          value='Snooze for 1 week'
          format='h4'
          color={theme.colors.Black}
          style={{ marginLeft: 18 }}
        />
      </OptionWrapper>
    ),
    value: '7',
  },
  {
    label: (
      <OptionWrapper>
        <Clock />
        <Text
          value='Snooze for 1 month'
          format='h4'
          color={theme.colors.Black}
          style={{ marginLeft: 18 }}
        />
      </OptionWrapper>
    ),
    value: '30',
  },
]

export const Detail = ({
  setStep,
  setTitle,
  onClose,
  isOpen,
  setIsOpen,
}: ISelect) => {
  const dispatch = useAppDispatch()
  const { detail } = useAppSelector((state) => state.reminder)

  const {
    eventName,
    dueDateTime,
    notes,
    space,
    item,
    id,
    propertyId,
    spaceId,
    itemId,
  } = detail

  const status = detail.status

  const [showSnoozeOptions, setShowSnoozeOptions] = useState(false)
  const [showBeginEventOptions, setShowBeginEventOptions] = useState(false)
  const [showMarkAsComplete, setShowMarkAsComplete] = useState(false)
  const [snoozeDays, setSnoozeDays] = useState(1)

  const handlePressPlus = () => {
    setStep(3)
  }

  const handleCloseOptionsBox = () => {
    setShowSnoozeOptions(false)
  }

  const openSnooze = () => {
    setShowSnoozeOptions(true)
  }

  const handlPressMarkAsCompleteCancel = () => {
    setShowMarkAsComplete(false)
  }

  const handleSnooze = () => {
    const [dd, mm, yyyy] = dueDateTime.split('/')
    const d = new Date(Number(yyyy), Number(mm) - 1, Number(dd))
    d.setDate(d.getDate() + Number(snoozeDays))
    const newDd = `0${d.getDate()}`.slice(-2)
    const newMm = `0${d.getMonth() + 1}`.slice(-2)
    const newYyyy = d.getFullYear()
    const body: any = {
      dueDateTime: `${newDd}/${newMm}/${newYyyy}`,
      spaceId,
      eventName,
      status: 'UPCOMING',
    }
    if (itemId) body.itemId = itemId
    dispatch(
      updateReminder({
        propertyId,
        reminderId: id,
        body,
      })
    )
    setShowSnoozeOptions(false)
    onClose()
  }

  const handleShareEvent = () => {
    setToOnGoing()
    setShowBeginEventOptions(false)
    setTitle('Share')
    setStep(5)
  }

  const handleMarkAsCompleteAddHistory = () => {
    dispatch(
      deleteReminder({
        propertyId,
        reminderId: id,
      })
    )
    dispatch(deleteLocalReminder(id))
    setShowMarkAsComplete(false)
    const tags = [space?.name]
    if (!!item) tags.push(item.name)
    dispatch(
      setHistory({
        id: null,
        propertyId,
        spaceId,
        itemId,
        contacts: [],
        eventName,
        eventDate: dueDateTime,
        rating: {},
        tags,
        notes,
      })
    )
    setTitle(CREATE_HISTORY_TITLE)
    setStep(4)
    setIsOpen(false)
  }

  const handleBeginEvent = () => {
    setShowBeginEventOptions(true)
  }

  const setToOnGoing = async () => {
    const body = {
      spaceId,
      itemId,
      eventName,
      dueDateTime,
      notes,
      status: 'ONGOING',
    }
    await dispatch(
      updateReminder({
        propertyId,
        reminderId: id,
        body,
      })
    )
  }

  const closeBeginEventOptions = () => {
    setShowBeginEventOptions(false)
  }

  const handleBeginEventWithoutShare = () => {
    setToOnGoing()
    setShowBeginEventOptions(false)
  }

  const handlPressMarkAsComplete = () => {
    dispatch(
      deleteReminder({
        propertyId,
        reminderId: id,
      })
    )
    dispatch(deleteLocalReminder(id))
    setShowMarkAsComplete(false)
    onClose()
  }

  const isDisabled = eventName?.trim() === ''
  const actions =
    status === 'DUE'
      ? [
          {
            type: 'secondary',
            isDisabled,
            text: 'Snooze',
            onClick: openSnooze,
          },
          {
            type: 'secondary',
            isDisabled,
            text: 'Mark as Complete',
            onClick: () => {
              setShowMarkAsComplete(true)
            },
          },
          {
            type: 'primary',
            isDisabled,
            text: 'Begin Event',
            onClick: handleBeginEvent,
          },
        ]
      : status === 'ONGOING'
      ? [
          {
            type: 'primary',
            isDisabled,
            text: 'Mark as Complete',
            onClick: () => setShowMarkAsComplete(true),
          },
        ]
      : [
          {
            type: 'primary',
            isDisabled,
            text: 'Edit',
            onClick: handlePressPlus,
          },
        ]

  const colors = {
    DUE: theme.colors.ErrorState,
    ONGOING: theme.colors.Yellow,
    UPCOMING: theme.colors.Green,
    COMPLETE: null,
  }
  const color = colors[status]

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <ActionLayout rightActions={actions}>
        <HeaderArea>
          <Text
            value='Reminder'
            format='h1'
            style={{
              alignSelf: 'flex-start',
              marginRight: '12px',
            }}
            fontSize='26px'
          />
          <RightBtnArea style={{ backgroundColor: color }}>
            <ReminderClock color={theme.colors.White} />
            <Text
              value={status?.toLowerCase()}
              format='h4'
              color={theme.colors.White}
              style={{ textTransform: 'capitalize', marginLeft: 4 }}
            />
          </RightBtnArea>
          <PlaceHolder />
        </HeaderArea>
        <Text
          value='Location'
          format='h3'
          color={theme.colors.DarkestGrey}
          style={{
            marginBottom: '6px',
          }}
        />
        <LabelArea>
          <SpaceLabelView>
            <Text
              value={space?.name}
              format='h4'
              color={theme.colors.Primary}
            />
          </SpaceLabelView>
          {!!item && (
            <ItemLabelView>
              <Text
                value={item.name}
                format='h4'
                color={theme.colors.Primary}
              />
            </ItemLabelView>
          )}
        </LabelArea>
        <ItemWrapper>
          <MenuText title='Reminder Name' text={eventName} />
        </ItemWrapper>
        <ItemWrapper>
          <MenuText title='Due' text={dueDateTime} />
        </ItemWrapper>
        <ItemWrapper>
          <MenuText title='Notes' text={notes} style={{ height: '290px' }} />
        </ItemWrapper>
        <AlertBox
          cancel={handleCloseOptionsBox}
          title='Snooze'
          message='You will be notified to complete this reminder later. How long would you like to snooze the reminder for?'
          body={
            <SelectWrapper>
              <Select
                title=''
                options={options}
                value={String(snoozeDays)}
                onChange={setSnoozeDays}
              />
            </SelectWrapper>
          }
          btnGroup={[
            {
              text: 'Snooze',
              color: 'Primary',
              textColor: 'White',
              onClick: handleSnooze,
              type: 'primary',
            },
          ]}
          showAlert={showSnoozeOptions}
        />
        <AlertBox
          cancel={handlPressMarkAsCompleteCancel}
          title='Remove Reminder'
          message='This reminder will be removed from your list. Would you like to add the event to your history?'
          btnGroup={[
            {
              text: 'Don’t Add to History',
              color: 'LightBlue',
              textColor: 'Primary',
              onClick: handlPressMarkAsComplete,
              type: 'secondary',
            },
            {
              text: 'Add to History',
              onClick: handleMarkAsCompleteAddHistory,
              color: 'ErrorState',
              type: 'primary',
            },
          ]}
          showAlert={showMarkAsComplete}
        />
        <AlertBox
          showAlert={showBeginEventOptions}
          title='Begin Event'
          message='Would you like to share this reminder with your contacts?'
          btnGroup={[
            {
              text: 'Don’t Share',
              color: 'LightBlue',
              textColor: 'Black',
              onClick: handleBeginEventWithoutShare,
              type: 'secondary',
            },
            {
              text: 'Share Event',
              color: 'Primary',
              textColor: 'White',
              onClick: handleShareEvent,
              type: 'primary',
            },
          ]}
          cancel={closeBeginEventOptions}
        />
      </ActionLayout>
    </Drawer>
  )
}
