import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from 'venus/api'
import { updateProfile } from 'venus/redux/features/user'

export interface IProfile {
  email: string
  mobile: string
  firstName: string
  lastName?: string
  profile: {
    avatar: string
    isAllowedToShareContactInfo: boolean
    isAllowedToSharePropertyInfo: boolean
  }
  role: string[]
  tradespeopleRole: string[]
  isEmailValidated: boolean
  isMobileValidated: boolean
  isIdValidated: boolean
}

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/profile')

      return response.data
    } catch (err) {
      const error = err as any
      return rejectWithValue(error.response.data)
    }
  },
)

const initialState = {
  error: null,
  profile: {},
  loading: true,
} as {
  error: object | null | undefined
  profile: IProfile
  loading: boolean
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.loading = true
      state.profile = {} as IProfile
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false
      state.profile = action.payload
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      console.log('failed')
      state.loading = false
      if (action.payload) {
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loading = false
      state.profile = action.payload
    })
  },
})

export const { resetProfileStore } = profileSlice.actions

export default profileSlice.reducer
