import styled from 'styled-components'
import { theme } from 'venus/config'

export const Dropdown = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
`

export const IconArea = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
`

export const OptionArea = styled.div`
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
`

export const DropdownBg = styled.div`
  position: fixed;
  width: calc(100% - 2px);
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 1;
  background-color: transparent;
`

export const SelectButton = styled.div`
  display: flex;
  width: 100%;
  height: 45px;
  padding: 12px 20px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.colors.White};
  &:hover {
    background-color: ${theme.colors.LightBlue};
    border-color: transparent;
  }
`

export const SelectWrapper = styled.select`
  appearance: none;
  display: flex;
  width: 100%;
  border-radius: 4px;
  outline: none;

  &:hover {
    background-color: ${theme.colors.LightBlue};
    border-color: ${(props) => props.theme.colors.Secondary};
  }

  &:focus {
    outline: none;
  }
`

export const RatingStarWrapper = styled.div`
  position: absolute;
  top: 44px;
  left: 24px;
  z-index: 1;
`

export const TitleWrapper = styled.div`
  height: 27px;
  width: 1px;
`

export const BodyWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const DefaultOption = styled.option`
  :focus {
    outline: none;
  }
`
