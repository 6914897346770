import { theme } from 'venus/config'
import React from 'react'
// import { useWindowDimensions } from 'react-native'
// import { StyleSheet, View } from 'react-native'
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTheme,
  VictoryLabel,
} from 'victory'
import {
  RecentMedianSalesEntity,
  CapitalGrowthEntity,
  IStatisticsConfig,
} from './Statistics.t'
import { formatPriceWithDollarSign } from 'venus/utils'

interface IStatisticsChart {
  data: RecentMedianSalesEntity[] | CapitalGrowthEntity[]
  config: IStatisticsConfig
}

const data = [
  { quarter: 1, earnings: 13000 },
  { quarter: 2, earnings: 16500 },
  { quarter: 3, earnings: 14250 },
  { quarter: 4, earnings: 19000 },
]

const StatisticsChart = ({ data, config }: IStatisticsChart) => {
  if (!data) {
    return null
  }

  const dataArray = [...data].reverse()

  return (
    <div style={{ height: 400 }}>
      <VictoryChart domainPadding={20}>
        <VictoryAxis
          standalone={false}
          style={{
            axis: {
              stroke: theme.colors.DarkGrey,
            },
            tickLabels: {
              angle: -80,
              fontSize: 8,
              padding: 20,
              color: theme.colors.DarkGrey,
              fill: theme.colors.DarkGrey,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(t) => formatPriceWithDollarSign(t)}
          style={{
            axis: {
              stroke: theme.colors.DarkGrey,
            },
            grid: { stroke: theme.colors.DarkGrey, strokeWidth: 0.5 },
            tickLabels: {
              fontSize: 8,
              color: theme.colors.DarkGrey,
              fill: theme.colors.DarkGrey,
            },
          }}
        />
        <VictoryBar
          style={{ data: { fill: theme.colors.Primary } }}
          barWidth={20}
          data={dataArray}
          labels={({ datum }) => `${datum.suburbSaleCount}`}
          labelComponent={
            <VictoryLabel
              style={{ fill: theme.colors.DarkestGrey, transform: 'translate(0, 4)' }}
            />
          }
          {...config.config.bar}
        />
        <VictoryLine
          style={{ data: { stroke: theme.colors.Secondary } }}
          data={dataArray}
          interpolation='natural'
          standalone={false}
          {...config.config.line}
        />
      </VictoryChart>
    </div>
  )
}

// const styles = StyleSheet.create({
//   container: {
//     height: 240,
//     justifyContent: 'center',
//     alignItems: 'center',
//     marginBottom: 10,
//   },
// })

export default StatisticsChart
