import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.ul`
  list-style: none;
  padding: 16px;
  margin: 0;
  width: 260px;
  background: ${(props) => props.theme.colors.White};
  /* Blacks/Grey */

  border: 0.5px solid ${(props) => props.theme.colors.Grey};
  border-radius: 5px;
`

export const MenuItem = styled.li`
  width: 100%;
  margin-bottom: 16px;
  &:last-child {
    margin: 0;
  }
`

export const MenuLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
  margin-right: 12px;
  color: ${(props) => props.theme.colors.DarkGrey};
`
