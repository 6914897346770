import styled from 'styled-components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import NPDImage from 'venus/assets/NPD.png'

const NPDContainer = styled.div`
  padding: 24px;
  padding-bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const NPD = () => (
  <NPDContainer>
    <Text format='body' color={theme.colors.DarkGrey} value='Powered By' />
    <div style={{ width: 48 }} />
    <img src={NPDImage} />
  </NPDContainer>
)

export default NPD
