import styled from 'styled-components'
import { AddressShader } from 'venus/pages/Properties/PropertyList/PropertyList.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 32px;
`

export const TransferVaultBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 28px;
`

type ItemProps = {
  readonly coverImage?: string
}
export const Item = styled.div<ItemProps>`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Grey};

  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(66%);
  }

  :hover ${AddressShader} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 100%);
  }

  ${(props) =>
          !!props.coverImage &&
          `background-image: url(${props.coverImage});
    background-position: center center;
    background-repeat: none;
    background-size: cover;`}
`

export const ConfirmStateWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding-right: 10px;
`

export const TransferViewContainer = styled.div`
  overflow-y: auto;
  padding-right: 10px;
`
