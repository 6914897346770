import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 24px;
  min-height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
