import styled from 'styled-components'

export const Panel = styled.div`
  min-height: 480px;
  max-height: 680px;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  padding: 34px 34px;

  width: 480px;
  border-radius: 12px;
  border: 0.5px solid ${(props) => props.theme.colors.Grey};
  background-color: ${(props) => props.theme.colors.White};

  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.06),
    2px 2px 10px rgba(0, 0, 0, 0.06);
`

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`
export const HeaderSubSection = styled.div`
  display: flex;
  min-width: 30px;
`

export const Button = styled.button`
  display: flex;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
`
