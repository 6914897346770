import styled from 'styled-components'

export const ContactCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 0;
`

export const AvatarWrapper = styled.div`
  height: 100%;
  vertical-align: top;
  margin-left: 22px;
`

export const ContactInfoCard = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 13px;
`

export const ContactRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;
  padding-bottom: 15px;
`

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ArrowIconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

export const ArrowContainer = styled.div`
`