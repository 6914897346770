import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useAppSelector } from 'venus/redux/hooks'
import { AlertBox } from 'venus/components'
import { resetErrorMessage } from 'venus/redux/features/error/reducer'
import { useAppDispatch } from 'venus/redux/hooks'
import { resetErrorTitle } from 'venus/redux/features/error/reducer'

const ErrorHandler = ({
  children
}) => {
  const dispatch = useAppDispatch()
  const { errorTitle, errorMessage } = useAppSelector(state => state.error)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  useEffect(() => {
    if (errorMessage) {
      setShowErrorMessage(true)
    }
  }, [errorMessage])

  const closeErrorMessage = () => {
    dispatch(resetErrorTitle())
    dispatch(resetErrorMessage())
    setShowErrorMessage(false)
  }

  return (
    <>
      <AlertBox
        showAlert={showErrorMessage}
        cancel={closeErrorMessage}
        title={errorTitle || 'Oops!'}
        message={errorMessage}
        btnGroup={[
          {
            text: 'OK',
            type: 'primary',
            onClick: closeErrorMessage,
          },
        ]}
      />
      {children}
    </>
  )
}

export default ErrorHandler
