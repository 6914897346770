import { useEffect, useMemo, useState } from 'react'
import PhotoLibrary from 'venus/components/PhotoLibrary'
import { Button } from 'venus/components'
import { theme } from 'venus/config'
import ImageUploader from 'venus/common/ImageUploader'
import api from 'venus/api'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { reset } from 'venus/redux/features/image/image'
import _ from 'lodash'
import { IImage } from 'venus/types/Image'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import axios from 'axios'
import { getExtension } from 'mime'
import { setErrorTitle } from 'venus/redux/features/error/reducer'
import { useParams } from 'react-router-dom'

interface IPSIPhotos {
  images: IImage[]
  setTabButtons?: () => void
  psiId?: any
  update: () => void
  updateUrl: string
  propertyWideSpaceId?: string
  isShared?: boolean
  isPersonal?: boolean
}

const getUploadConfigs = async (files) => {
  if (!files) {
    return null
  }

  try {
    const config = files.map((file) => {
      const mimeType = file?.type || 'image/jpeg'

      const ext = getExtension(mimeType || 'jpg')
      const fileName = file?.name

      return { name: fileName, type: mimeType, ext }
    })

    const uploadConfig = await api.post('/images', config).then((res) => res.data)
    return uploadConfig
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

const PSIPhotos = ({
  images: defaultImages,
  setTabButtons,
  psiId,
  update,
  updateUrl,
  propertyWideSpaceId,
  isShared,
  isPersonal,
  enableScanHome = false,
  style = {},
}) => {
  const { url, progress, image, name } = useAppSelector((state) => state.image)
  const dispatch = useAppDispatch()

  const [uploading, setUploading] = useState([])
  const { space: defaultSpace } = useAppSelector((state) => state.space)

  const [images, setImages] = useState(defaultImages)
  const { spaceId } = useParams()

  const space = useMemo(() => {
    return spaceId === defaultSpace?.id ? defaultSpace : null
  }, [defaultSpace, spaceId])

  useEffect(() => {
    const processImages = async () => {
      const response = await api.get(
        `property/${psiId.propertyId}/spaces/${psiId.spaceId}/images`,
      )
      setImages(response.data)
    }

    if (
      !psiId.itemId &&
      !psiId.personalItemId &&
      !psiId.dreamPropertyId &&
      !psiId.shareId
    ) {
      processImages()
    } else {
      setImages(defaultImages)
    }
  }, [defaultImages, psiId])

  useEffect(() => {
    if (!isShared) {
      setTabButtons(
        <ImageUploader
          uploadImages={uploadImages}
          isMultiple
          enableScanHome={enableScanHome}
          space={space}
        >
          {/* @ts-ignore */}
          <Button
            type='icon_button'
            text='Add Photo'
            iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
            style={{ width: 'auto' }}
          />
        </ImageUploader>,
      )
    }

    return () => setTabButtons(<></>)
  }, [isShared])

  useEffect(() => {
    const addImage = async () => {
      dispatch(setErrorTitle('Upload Photos Error'))
      try {
        const response = await api.put(updateUrl, {
          images: [...images, { url }],
        })
        const updatedObject = response.data
        if (psiId.itemId || psiId.personalItemId) {
          dispatch(update(updatedObject))
        } else {
          updatedObject.spaceId = updatedObject.id
          dispatch(update(_.omit(updatedObject, 'id')))
        }
        dispatch(reset())
      } catch (error) {
        reset()
        console.log('ERROR in image upload', error.data)
      }
    }

    if (url && name === 'uploading') {
      addImage()
    }
  }, [url, name])

  const resetUploading = () => setUploading([])

  useEffect(() => {
    // console.log('UPDATE UPLOADING, ', uploading)
    const addImages = async () => {
      const newImages = uploading.map((u) => ({ url: u.url }))
      try {
        const response = await api.put(updateUrl, {
          images: [...images, ...newImages],
        })
        const updatedObject = response.data
        if (psiId.itemId || psiId.personalItemId) {
          dispatch(update(updatedObject))
        } else {
          updatedObject.spaceId = updatedObject.id
          dispatch(update(_.omit(updatedObject, 'id')))
        }
        // dispatch(reset())
        resetUploading()
      } catch (error) {
        // reset()
        resetUploading()
        console.log('ERROR in image upload', error.data)
      }
    }

    if (uploading.length) {
      const newImages = uploading.filter((u) => u.url)

      if (newImages.length === uploading.length) {
        addImages()
      }
    }
  }, [uploading])

  const uploadImages = async (files) => {
    const uploadConfig = await getUploadConfigs(files)

    setUploading(
      uploadConfig.map((config) => ({
        progress: 0,
        url: '',
        image: null,
      })),
    )

    files.forEach((file, index) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        setUploading((uploadingConfig) => {
          const uploading = [...uploadingConfig]
          uploading[index].image = reader.result
          return uploading
        })
      }
    })

    files.forEach((file, index) => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(file)

      reader.onload = () => {
        const fileType = file.type
        const { uploadURL } = uploadConfig[index]
        const content = reader.result
        axios
          .put(uploadURL, content, {
            headers: {
              'Content-Type': String(fileType),
            },
            onUploadProgress: (progress: ProgressEvent) => {
              const progressPercentage = Math.round(
                (progress.loaded / progress.total) * 100,
              )
              setUploading((uploadingConfig) => {
                const uploading = [...uploadingConfig]
                uploading[index].progress = progressPercentage
                return uploading
              })

              // dispatch(setProgress(progressPercentage))
            },
          })
          .then((res) => {
            // dispatch(setUrl(uploadConfig.url))
            setUploading((uploadingConfig) => {
              const uploading = [...uploadingConfig]
              uploading[index].url = uploadConfig[index].url
              // uploading[index].image = null
              return uploading
            })
            return uploadConfig[index].url
          })
      }
    })
  }

  return (
    <PhotoLibrary
      style={style}
      images={images}
      uploader={{ url, progress, image }}
      psiId={psiId}
      update={update}
      propertyWideSpaceId={propertyWideSpaceId}
      isShared={isShared}
      uploadImages={uploadImages}
      uploading={uploading}
      isPersonal={isPersonal}
    />
  )
}

export default PSIPhotos
