import { useState, useEffect } from 'react'
import { homeImages, batchEditDoc } from 'venus/redux/features/onboarding'
import { getProperty } from 'venus/redux/features/property/property'
import { createDocInfo } from 'venus/redux/features/docs'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { theme } from 'venus/config'
import { Button, Loader, Text, Pills, Image } from 'venus/components'
import {
  BtnArea,
  SpaceListView,
  SpaceListContent,
  SpaceNameArea,
  SpaceItemsArea,
  SpaceImageArea,
} from './MoveDocs.styles'
import { MoveTo } from 'venus/common/MoveTo'

interface IMoveDocs {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  propertyId: string
}

export const MoveDocs = ({ setStep, setTitle, propertyId }: IMoveDocs) => {
  const dispatch = useAppDispatch()
  const { homeDocs } = useAppSelector((state) => state.onboarding)
  const checkedDocs = homeDocs.filter(({ checked, isUploaded }) => checked && !isUploaded)

  const { property } = useAppSelector((state) => state.property)
  // const images = useAppSelector(homeImages)

  // const { preview } = useAppSelector((state) => state.address)
  // const { loading } = useAppSelector((state) => state.docs)
  // const { googlePlaceId, image } = preview[0]
  // const [showBtn, setShowBtn] = useState(false)

  // useEffect(() => {
  //   if (!spaces && !!propertyId) {
  //     dispatch(getProperty({ propertyId }))
  //   }
  // }, [spaces, propertyId])

  const handleMove = async (props) => {
    const toBeUploaded = checkedDocs.map(({ filename, uri, contentType }) => ({
      displayName: filename,
      url: uri,
      contentType,
    }))

    let body = {
      documents: toBeUploaded,
      ...props.target,
    }

    const result = await dispatch(createDocInfo(body))
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(batchEditDoc(checkedDocs))
      setStep(7)
      setTitle('Assign Documents')
    }
  }

  // const handlePressMove = async () => {
  //   const toBeUploaded = checkedDocs.map(({ filename, uri, contentType }) => ({
  //     displayName: filename,
  //     url: uri,
  //     contentType,
  //   }))
  //   const body: {
  //     propertyId: string
  //     spaceId?: string
  //     itemId?: string
  //     documents: {
  //       displayName: string
  //       url: string
  //       contentType: string
  //     }[]
  //   } = {
  //     documents: toBeUploaded,
  //     propertyId: propertyId || property.id,
  //     spaceId: selectedSpaceId,
  //   }
  //   if (selectedItemId) {
  //     body.itemId = selectedItemId
  //   }
  //   const result = await dispatch(createDocInfo(body))
  //   if (result.meta.requestStatus === 'fulfilled') {
  //     dispatch(batchEditDoc(checkedDocs))
  //     setStep(7)
  //     setTitle('Assign Documents')
  //   }
  // }

  return (
    <>
      <MoveTo
        type='property'
        psiId={{
          propertyId,
          propertyWideSpaceId: property?.propertyWideSpaceId,
          spaceId: property?.propertyWideSpaceId,
        }}
        handleMove={handleMove}
      />
      {/* <SpaceListView>
        {!!spaces &&
          spaceList.map(
            ({ name, id: spaceId, items, coverImage, images }, index) => {
              const options =
                !!items && !!items.length
                  ? items.map(({ name: itemName, id: itemId }) => ({
                      name: itemName,
                      itemId,
                      spaceId,
                    }))
                  : []
              options.unshift({
                name,
                spaceId,
                itemId: '',
              })
              const isSelected = index === selectedIndex || selectedIndex === -1
              const coverImageUrl =
                coverImage || (!!images && !!images[0] ? images[0]?.url : '')
              return (
                <SpaceListContent
                  key={`space_list_${index}`}
                  onClick={() => handlePressContent(index)}
                >
                  <SpaceNameArea>
                    <SpaceImageArea
                      style={{
                        backgroundColor: isSelected
                          ? theme.colors.Grey
                          : theme.opacity.minimum,
                      }}
                    >
                      <Image
                        image={coverImageUrl}
                        style={{
                          width: '44px',
                          height: '44px',
                          opacity: isSelected ? 1 : 0.4,
                        }}
                      />
                    </SpaceImageArea>
                    <Text
                      value={name}
                      format='body'
                      color={
                        isSelected ? theme.colors.Black : theme.opacity.minimum
                      }
                      style={{ marginLeft: 12 }}
                    />
                  </SpaceNameArea>
                  {index === selectedIndex && (
                    <SpaceItemsArea>
                      <Pills
                        options={
                          options as {
                            id: string
                            name: string
                            itemId: string
                            spaceId: string | undefined
                          }[]
                        }
                        handlePress={handlePressPillsOptions}
                      />
                    </SpaceItemsArea>
                  )}
                </SpaceListContent>
              )
            }
          )}
      </SpaceListView> 
      <BtnArea>
        <Button
          onClick={handlePressMove}
          isLoading={loading}
          text='Move Files'
          style={{ height: '36px', padding: '4px 28px', width: 'auto' }}
          isDisabled={!showBtn}
        />
      </BtnArea>*/}
    </>
  )
}
