import styled from 'styled-components'
import { ReactComponent as Pencil } from 'venus/assets/img/icons/Pencil.svg'
import { theme } from 'venus/config'

type ContainerProps = {
  readonly image: string
  readonly isGradient?: boolean
  readonly colors: string[]
  readonly isShared: boolean
}
export const Container = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;
  cursor: ${(props) => (props.isShared ? 'default' : 'pointer')};

  min-height: 204px;

  background-image: url(${(props) => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${(props) =>
    props.isGradient &&
    `
    background: linear-gradient(0deg, ${props.colors[0]}, ${props.colors[1]});
  `}

  :hover {
    // @ts-ignore
    .editIcon {
      opacity: 1;
    }
  }

  .editIcon {
    opacity: 0;
  }
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 24px;
`

export const BackCover = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
`

export const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Actions = styled.div`
  position: absolute;
  right: 0;
  bottom: 20px;
  padding: 0 24px;
  display: flex;
`

export const ActionButton = styled.button`
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin-left: 36px;
  color: ${(props) => props.theme.colors.White};
  background: none;
  display: flex;
  align-items: center;

  opacity: 0.9;
  :hover {
    opacity: 1;
  }
`

export const TagSection = styled.div`
  position: absolute;
  left: 32px;
  bottom: 24px;
`

export const TagWrapper = styled.div`
  padding: 0px 20px;
  border-radius: 21px;
  background-color: ${theme.colors.White};
`

export const TagSubTitleWrapper = styled(TagWrapper)`
  background-color: ${theme.colors.Primary};
  margin-top: 12px;
`
