import { ReactNode } from 'react'
import { useState } from 'react'
import { defaultPillsTabConfig } from 'venus/config'
import { PillTabsHeader } from './Pills.styles'
import PillButton from './PillButton'
import { IPillTagConfig } from './PillTag'

interface IPill {
  label: string
  key: string
}

interface IPillTabs {
  onChange?: (index: number) => void
  tabs: IPill[]
  defaultActiveIndex?: number
  config?: {
    active?: IPillTagConfig
    normal: IPillTagConfig
  }
  children?: ReactNode[]
  style?: any
}

const PillTabs = ({
  style,
  tabs,
  defaultActiveIndex,
  config = defaultPillsTabConfig,
  onChange = () => {},
  children,
}: IPillTabs) => {
  const [activeIndex, setActiveIndex] = useState<number>(
    defaultActiveIndex || 0,
  )

  const handlePress = (index: number) => {
    onChange(index)
    setActiveIndex(index)
  }

  return (
    <>
      <PillTabsHeader style={style}>
        {tabs.map((pill, index) => {
          const isActive = index === activeIndex

          const pillConfig = isActive ? config.active : config.normal

          return (
            <PillButton
              key={pill.key}
              index={index}
              onClick={handlePress}
              label={pill.label}
              style={{ marginRight: 10 }}
              {...pillConfig}
            />
          )
        })}
      </PillTabsHeader>
      {children && children[activeIndex]}
    </>
  )
}

export default PillTabs
