import { Text, StepList } from 'venus/components'

export const StepButtonSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <StepList text='Confirm Home Layout' isDisabled isCompleted />
      <StepList
        text={'View & Edit Home Images'}
        isDisabled={false}
        isCompleted={false}
      />
      <StepList text='Upload Documents' isDisabled isCompleted={false} />
    </>
  )
}
