import { useEffect, useState } from 'react'
import { Button, Text, AlertBox } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  getNumberOfUserTrackingProperty,
  unshareDreamPrice,
} from 'venus/redux/features/dreamprice'
import { getProperty } from 'venus/redux/features/property/property'
import { theme, FREE, PAID } from 'venus/config'
import { formatPriceWithDollarSign } from 'venus/utils'
import { ShareDreamProperty } from 'venus/pages/DreamPrice/ShareDreamProperty'
import { ReactComponent as Trash } from 'venus/assets/img/icons/Trash.svg'
import { ReactComponent as Add } from 'venus/assets/img/icons/Add.svg'
import {
  DreamPriceHeader,
  DreamPriceSection,
  Panel,
  DisplayBoard,
  EditIcon,
  AddIcon,
} from './PropertyDetails.styles'
import api from 'venus/api'
import { loadStripe } from '@stripe/stripe-js'
import usePremium from 'venus/hooks/usePremium'
import usePremiumContext from 'venus/context/MHVPremium'
// const stripe = await loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx')
// const stripePromise = loadStripe(
//   'pk_test_51KdmvKFS9yrnVuppp2zd9DsilENAv7UW4K2KyW58H2tDKphDoIg91agh7QCLcKxsjCGcFul24d9PnLfHvanJND2V00KN2O3eU1',
// )

let stripe

const DreamPrice = ({ propertyId, dreamPrice }) => {
  // const [stripe, setStripe] = useState()
  const dispatch = useAppDispatch()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showVerified, setShowVerified] = useState(false)
  const [isOpenShareDreamProperty, setIsOpenShareDreamProperty] = useState(false)
  const { numberOfUserTrackingProperty, numberShowInterest, dreamPriceStatus } =
    useAppSelector((state) => state.dreamprice)

  const { vaults } = useAppSelector((state) => state.subscription)
  // const { profile } = useAppSelector((state) => state.profile)
  const { info } = useAppSelector((state) => state.user)

  const subscriptionStatus = vaults[propertyId]
  const isSharedDreamPrice =
    dreamPriceStatus === 'SHARE_DREAM_PRICE' && subscriptionStatus !== FREE
  const [showVerfication, setShowVerification] = useState(false)
  // const [showFreemiumAlert, setShowFreemiumAlert] = useState(false)
  // const { onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )
  const { openPremium } = usePremiumContext()
  const { propertyStatus } = usePremium(propertyId)

  useEffect(() => {
    const setUp = async () => {
      stripe = await loadStripe(
        'pk_test_51KdmvKFS9yrnVuppp2zd9DsilENAv7UW4K2KyW58H2tDKphDoIg91agh7QCLcKxsjCGcFul24d9PnLfHvanJND2V00KN2O3eU1',
      )
    }

    setUp()
  }, [])

  useEffect(() => {
    dispatch(getNumberOfUserTrackingProperty(propertyId))
  }, [propertyId])

  // useEffect(() => {
  //   if (profile) {
  //     setShowVerification(!profile.isIdValidated)
  //   }
  // }, [profile, loading])

  // const handleOnCancel = () => {
  //   setShowFreemiumAlert(false)
  //   onCancel(true)
  // }

  // const handleViewPlan = () => {
  //   setShowFreemiumAlert(false)
  //   onViewPlan()
  // }

  const handlePressSetDreamPrice = () => {
    if (!info.isIdValidated) {
      return setShowVerification(true)
    }

    if (propertyStatus !== 'paid') {
      openPremium()
    } else {
      // if (!subscriptionStatus || subscriptionStatus !== PAID) {
      //   setShowFreemiumAlert(true)
      //   return
      // }
      setIsOpenShareDreamProperty(true)
    }
  }

  const startVerification = async (event) => {
    const response = await api.post('/auth/create-verification-session')
    const session = response.data

    if (!stripe) {
      console.log('no stripe')
      return
    }

    console.log('session', session)

    // @ts-ignore
    const { error } = await stripe.verifyIdentity(session.clientSecret)

    if (error) {
      console.log('[error]', error)
    } else {
      console.log('Verification submitted!')
      setShowVerified(true)
    }

    setShowVerification(false)
    // setInVerification(true)
  }

  const handlePressDeleteDreamPrice = () => {
    setShowConfirmDelete(true)
  }

  const handlPressAlertOK = async () => {
    await dispatch(unshareDreamPrice(propertyId))
    await dispatch(getProperty({ propertyId }))
    setShowConfirmDelete(false)
  }

  const handlPressAlertCancel = () => {
    setShowConfirmDelete(false)
  }

  const handleCloseShareDreamProperty = () => {
    setIsOpenShareDreamProperty(false)
  }

  return (
    <Panel style={{ marginBottom: 28 }}>
      <DreamPriceSection>
        <div style={{ marginBottom: 12 }}>
          <Text format='h1'>Dream Sale Price</Text>
        </div>

        <div>
          <Text format='body' color={theme.colors.Black}>
            Let people who track your home know the price for which you would consider
            selling your property if it were for sale.
          </Text>
        </div>
        <DreamPriceHeader>
          <Text
            format='h3'
            style={{
              alignSelf: 'center',
            }}
          >
            Your Dream Price
          </Text>
          {!!dreamPrice && dreamPrice !== '0' ? (
            <DisplayBoard style={{ width: '100%' }}>
              <Text
                value={formatPriceWithDollarSign(dreamPrice)}
                format='h1'
                fontSize={32}
                lineHeight={1.5}
                color={theme.colors.Orange}
              />
              {isSharedDreamPrice && (
                <EditIcon onClick={handlePressDeleteDreamPrice}>
                  <Trash width={14} height={14} color={theme.colors.Black} />
                </EditIcon>
              )}
            </DisplayBoard>
          ) : (
            <AddIcon>
              <Add
                color={theme.colors.Orange}
                onClick={handlePressSetDreamPrice}
                style={{ cursor: 'pointer' }}
              />
            </AddIcon>
          )}
        </DreamPriceHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DreamPriceHeader style={{ marginRight: 46 }}>
            <Text format='h3'>Home Trackers</Text>
            <Text
              format='h1'
              fontSize={24}
              lineHeight={1.5}
              color={theme.colors.Orange}
              style={{
                alignSelf: 'center',
              }}
            >
              {numberOfUserTrackingProperty}
            </Text>
          </DreamPriceHeader>
          <DreamPriceHeader>
            <Text format='h3'>Expressed Interest</Text>
            <Text
              format='h1'
              fontSize={24}
              lineHeight={1.5}
              color={theme.colors.Orange}
              style={{
                alignSelf: 'center',
              }}
            >
              {numberShowInterest}
            </Text>
          </DreamPriceHeader>
        </div>
      </DreamPriceSection>
      <AlertBox
        cancel={handlPressAlertCancel}
        title='Delete Dream Price'
        message={`Are you sure you want to delete this dream price?`}
        btnGroup={[
          {
            text: 'Delete',
            onClick: handlPressAlertOK,
            color: 'ErrorState',
            type: 'error',
          },
          {
            text: 'Cancel',
            onClick: handlPressAlertCancel,
            color: 'LightBlue',
            textColor: 'Primary',
            type: 'secondary',
          },
        ]}
        showAlert={showConfirmDelete}
      />

      <AlertBox
        showAlert={showVerfication}
        cancel={() => setShowVerification(false)}
        title='Verify your ID'
        message='In order to set the dream price, we need to verify your identity.'
        btnGroup={[{ text: 'Verify Now', onClick: startVerification, type: 'primary' }]}
      />

      <AlertBox
        showAlert={showVerified}
        cancel={() => setShowVerified(false)}
        title='Verify your ID'
        message='Your identification is being verified. Please check your email and set up dream property later.'
        btnGroup={[
          {
            text: 'OK',
            onClick: () => setShowVerified(false),
            type: 'primary',
          },
        ]}
      />

      {isOpenShareDreamProperty && (
        <ShareDreamProperty
          isOpen={isOpenShareDreamProperty}
          onClose={handleCloseShareDreamProperty}
        />
      )}
      {/* <MHVPremium
        isOpen={showFreemiumAlert}
        onViewPlan={handleViewPlan}
        onCancel={handleOnCancel}
      /> */}
    </Panel>
  )
}

export default DreamPrice
