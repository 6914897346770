import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ImageArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin: 0 24px 6px 24px;
  height: 470px;
`

export const ImageView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  overflow: scroll;
  position: relative;
  margin-bottom: 24px;
`

export const ImageWrapper = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 300px;
  aspect-ratio: 1.3333;
  border-radius: 6px;
`

export const ImageIndexView = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 12px;
  right: 10px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
`

export const FooterBtnArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 34px;
  margin-bottom: 12px;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

export const SingleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 15px;
  border-radius: 50px;
  margin: 5px;
  width: fit-content;
  cursor: pointer;
`

export const ForwardIcon = styled.div`
  transform: rotate(180deg);
  display: flex;
  margin-left: 3px;
`

export const BackIcon = styled.div`
  display: flex;
  margin-right: 3px;
`

export const PreviousButtonHolder = styled.div`
  width: 80px;
  height: 24px;
`

export const NextButtonHolder = styled.div`
  width: 58px;
  height: 24px;
`
