import { useEffect, useState } from 'react'
import { CheckboxContainer } from 'venus/components/Checkbox/Checkbox.styles'
import { HiddenCheckbox } from 'venus/components/Checkbox/Checkbox.styles'
import { StyledCheckbox } from 'venus/components/Checkbox/Checkbox.styles'
import { Icon } from 'venus/components/Checkbox/Checkbox.styles'
import { Text } from '..'
import { StyledDisabledCheckbox } from 'venus/components/Checkbox/Checkbox.styles'

export const Checkbox = ({
  checked,
  onChange,
  label = '',
  disabled = false,
  className,
  noMargin,
  ...props
}: {
  checked: boolean
  onChange: (event: any) => void
  label?: string
  disabled?: boolean
  className?: string
  noMargin?: boolean
  props?: any[]
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleOnClick = (event: any) => {
    event.preventDefault()
    setIsChecked(!isChecked)
    onChange(!isChecked)
  }

  if (disabled) {
    return (
      <CheckboxContainer className={className}>
        <StyledDisabledCheckbox checked={isChecked}>
          <Icon viewBox='0 0 24 24'>
            <polyline points='20 6 9 17 4 12' />
          </Icon>
        </StyledDisabledCheckbox>
        <Text
          value={label}
          format='body'
          style={{ textAlign: 'left', marginLeft: '5px' }}
        />
      </CheckboxContainer>
    )
  }
  return (
    <CheckboxContainer noMargin={noMargin} className={className} onClick={handleOnClick}>
      <HiddenCheckbox checked={isChecked} onChange={onChange} {...props} />
      <StyledCheckbox checked={isChecked}>
        <Icon viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </StyledCheckbox>
      <Text value={label} format='body' style={{ textAlign: 'left', marginLeft: '10px' }} />
    </CheckboxContainer>
  )
}
