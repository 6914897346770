import React, { useImperativeHandle, useState, ReactElement, ReactNode } from 'react'
import { theme } from 'venus/config'
import { Text } from '../'
import {
  HeaderArea,
  HeaderTextItem,
  BodyArea,
  ShorterBorderArea,
  Header,
} from './TabView.styles'
import { HeaderButtonArea } from './TabView.styles'

interface ITabView {
  size?: string
  tabs: string[] | ReactNode[]
  children: ReactElement[]
  defaultActiveIndex?: number
  paddingBottom?: number
  onChange?: (index: number) => void
  style?: any
  headerStyle?: any
  headerBtn?: ReactElement
  isScrollable?: boolean
  scrollBodyHeight?: number
  isWrap?: boolean
  center?: boolean
}

const DEFAULT = 'default'
const LARGE = 'large'

const SIZE_CONFIG: { [key: string]: number } = {
  [DEFAULT]: 16,
  [LARGE]: 20,
}

export const TabView = (
  {
    size = DEFAULT,
    tabs,
    children,
    defaultActiveIndex,
    onChange = () => {},
    paddingBottom,
    style,
    headerStyle,
    isWrap = false,
    center,
  }: ITabView,
  ref: any,
) => {
  const [activeIndex, setActiveIndex] = useState<number>(defaultActiveIndex || 0)
  const [tabButtons, setTabButtons] = useState()

  const handlePress = (index: number) => {
    setActiveIndex(index)
    onChange(index)
  }

  useImperativeHandle(ref, () => ({
    resetIndex() {
      setActiveIndex(0)
    },
  }))

  // const contents = useMemo(() => {
  //   return children.filter((child) => child)
  // }, [children])

  const avaliableChildren = children.filter((c) => c)

  return (
    <>
      <Header isWrap={isWrap} center={center}>
        <HeaderArea
          style={{
            ...headerStyle,
            flexWrap: isWrap ? 'wrap' : 'nowrap',
            height: '66px',
          }}
        >
          {tabs.map((tab: string, index: number) => (
            <HeaderTextItem key={`tab_${index}`} onClick={() => handlePress(index)}>
              {typeof tab === 'string' ? (
                <Text
                  style={{ whiteSpace: 'nowrap' }}
                  value={tab}
                  format='h3'
                  fontSize={SIZE_CONFIG[size] as number}
                  color={
                    index === activeIndex ? theme.colors.Primary : theme.colors.DarkGrey
                  }
                />
              ) : (
                tab
              )}
              {!isWrap && <ShorterBorderArea isActive={index === activeIndex} />}
            </HeaderTextItem>
          ))}
        </HeaderArea>
        <HeaderButtonArea>
          {/* {tabButtons.map((button) => (
            <Button key={button.text} {...button} style={{ marginLeft: 12 }} />
          ))} */}
          {tabButtons}
        </HeaderButtonArea>
      </Header>

      <BodyArea
        style={{
          flex: 1,
          paddingBottom: paddingBottom,
          marginTop: isWrap ? '24px' : '0px',
          ...style,
        }}
      >
        {avaliableChildren[activeIndex] &&
          React.cloneElement(avaliableChildren[activeIndex], { setTabButtons })}
      </BodyArea>
    </>
  )
}

export default React.forwardRef(TabView)
