export const formatPrice = (number: number | string): string => {
  if (typeof number === 'string') {
    const decimalStr = String(number).replaceAll(/,/g, '')
    number = parseFloat(decimalStr).toFixed(2)
  } else {
    number = number.toFixed(2)
  }
  if (Number(number) <= 0 || !isFinite(Number(number))) return '0'
  const str = Number(number).toFixed(2)
  const parts = str.split('.')
  if (parts.length > 1) {
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const decimalPart = parts[1]
    if (parseInt(decimalPart, 10) === 0) {
      return `${integerPart}`
    }
    return `${integerPart}.${decimalPart}`
  }
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const extractNumber = (str: string): number => {
  if (!str) return 0
  if (!isNaN(Number(str))) {
    return +str
  }
  const result = Number(str.replace(/,/g, ''))
  if (isNaN(result)) return 0
  return result
}

export const formatPriceWithSign = (text: string | number) => {
  if (!text) return ''
  const str = formatPrice(text)
  return str
}

export const formatPriceWithDollarSign = (text: string | number) => {
  if (!text) return ''
  const str = formatPrice(text)
  return `$${str}`
}

export const formatPercentage = (percentage: number, fixed: number = 0) => {
  const number = (percentage * 100).toFixed(fixed)
  return `${number}%`
}

export const IsPositiveIntegar = (v: string) => {
  const reg = /^[0-9]*$/
  return reg.test(v)
}
