import styled from 'styled-components'

type ContainerProps = {
  readonly x: number
  readonly y: number
  readonly w: number
  readonly h: number
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  /* background-color: red; */
  z-index: 1;
  /* align-self: stretch; */
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InputContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  transform: translate(-50%, -50%);
`

export const Input = styled.input`
  align-self: center;
  z-index: 2;
  border-radius: 25px;
  padding: 6px 10px;
  padding-right: 20px;
  min-height: 24px;
  text-align: center;
  border-width: 0;
  font-size: 14px;
  min-width: 140px;
  /* width: auto; */
  background-color: ${(props) => props.theme.colors.Secondary};
  color: ${(props) => props.theme.colors.White};
  opacity: 0.75;

  :focus {
    outline: none;
  }
`

export const DeleteButton = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 3;

  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;

  display: flex;
  align-items: center;
`
