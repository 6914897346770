import { useState } from 'react'
import styled from 'styled-components'
import { RadioGroup, Text } from 'venus/components'
import { theme } from 'venus/config'
import { Container, PlanRow } from './PlanSelect.styles'
import PremiumContainer from './PremiumContainer'
import { periods } from './Subscriptions'

const PlanSelect = ({ name, price, description, periodSelect, setPeriodSelect }) => {
  const period = periods[periodSelect]

  return (
    <div>
      <PremiumContainer style={{ margin: '30px 0' }}>
        <Container>
          <PlanRow>
            <Text format='h2' value={name} color={theme.colors.Primary} />
            <Text
              format='h2'
              value={`A$${price[period]}/${period}`}
              color={theme.colors.Primary}
            />
          </PlanRow>
          <RadioGroup
            style={{ marginTop: 10 }}
            values={['Monthly', 'Annual']}
            value={periodSelect}
            onChange={setPeriodSelect}
          />

          <Text
            format='body'
            style={{ marginTop: 12, display: 'block' }}
            value={description}
          />
        </Container>
      </PremiumContainer>
      <Text
        format='body'
        value='“Premium Vault” allow you to enable subscription of premium features for your property vault, such as upload unlimited documents, reminder and set dream price within the vault.'
      />
    </div>
  )
}

export default PlanSelect
