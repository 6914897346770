// import { asyncStorageIO, bundleResourceIO } from '@tensorflow/tfjs-react-native'
import { createContext, useContext, useEffect, useState } from 'react'
import * as tf from '@tensorflow/tfjs'
import _ from 'lodash'
import { differenceInSeconds } from 'date-fns'
import * as tfTask from '@tensorflow-models/tasks'

const detectionUrl =
  'https://mhv-dev-file.s3.ap-southeast-2.amazonaws.com/models/ssd_mobilenet.tflite'

const classificationUrl =
  'https://mhv-dev-file.s3.ap-southeast-2.amazonaws.com/models/classification/classification.tflite'

const customModelUrl =
  'https://mhv-dev-file.s3.ap-southeast-2.amazonaws.com/models/colab_output_4/colab_output_4/model.json'

export const ModelContext = createContext({
  isReady: false,
  isLoading: false,
  models: {},
  init: async () => {},
})

export const ModelProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [models, setModels] = useState({})

  const init = async () => {
    console.log('INIT')
    const start = new Date()
    if (isReady || !_.isEmpty(models)) {
      return
    }

    setIsLoading(true)
    await tf.ready()

    const classification = await tfTask.ImageClassification.CustomModel.TFLite.load({
      model: classificationUrl,
    })

    const defaultModel = await tfTask.ObjectDetection.CocoSsd.TFLite.load()
    // CustomModel.TFLite.load({
    //   model: detectionUrl,
    // })

    // const model = await tfTask.ObjectDetection.CustomModel.TFLite.load({
    //   model: customDetectionUrl,
    // })

    const customModel = await tf.loadGraphModel(customModelUrl)
    setModels((existing) => ({
      ...existing,
      defaultModel,
      custom: customModel,
      classification,
    }))

    // await tf.setBackend('cpu');

    setIsReady(true)
    setIsLoading(false)

    const end = new Date()

    console.log('TIME', differenceInSeconds(start, end))
  }

  return (
    <ModelContext.Provider
      value={{
        init,
        isReady,
        isLoading,
        models,
      }}
    >
      {children}
    </ModelContext.Provider>
  )
}

export const useModelContext = () => useContext(ModelContext)
