import React, { createContext, useContext, useState } from 'react'

interface IRedirection {
  isOpen: boolean
  openPremium: () => void
  closePremium: () => void
}

export const Premium = createContext<IRedirection>({
  isOpen: false,
  openPremium: () => {},
  closePremium: () => {},
})

export const PremiumProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openPremium = () => setIsOpen(true)
  const closePremium = () => setIsOpen(false)

  return (
    <Premium.Provider value={{ isOpen, openPremium, closePremium }}>
      {children}
    </Premium.Provider>
  )
}

export const usePremiumContext = () => useContext(Premium)

export default usePremiumContext
