import { useState } from 'react'
import { AlertBox, Button, Input, Notification, Text } from 'venus/components'
import { BgWrapper } from 'venus/layout/MHV/MHV.styles'
import { ReactComponent as BackIcon } from 'venus/assets/img/icons/Back.svg'
import { LoginPanel } from '../Login/Login.styles'
import { BackIconWrapper, ModalHeader, ModalTitle } from './ForgotPassword.styles'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'venus/redux/hooks'
import api from 'venus/api'

const ForgotPassword = () => {
  const [userName, setUserName] = useState('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [success, setSuccess] = useState(false)

  const onFinish = () => {
    setSuccess(false)
    navigate('/')
  }

  const goBack = () => navigate('/login')

  const handleNext = async () => {
    try {
      const type = userName.indexOf('@') >= 0 ? 'email' : 'mobile'
      const body = {
        [type]: userName,
      }

      const response = await api.put(`/auth/forgot-password`, body)

      setSuccess(true)
    } catch (error) {
      console.log('forgot password error::', error)
    }
  }

  return (
    <BgWrapper
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPanel style={{ paddingTop: 0 }}>
        <ModalHeader>
          <BackIconWrapper onClick={goBack}>
            <BackIcon />
          </BackIconWrapper>
          <ModalTitle>
            <Text format={'h1'} value={'Forgot Password'} />
          </ModalTitle>
          <BackIconWrapper></BackIconWrapper>
        </ModalHeader>
        <Text format='body' style={{ textAlign: 'center' }}>
          We will send you reset password link via email or SMS if email address or mobile
          number is validated.
        </Text>
        <Input
          style={{ marginBottom: 18, marginTop: 18 }}
          placeholder='Your Mobile / Email'
          value={userName}
          onChange={setUserName}
        />
        <Button
          type={'primary'}
          text={'Next'}
          isDisabled={!userName}
          //   isLoading={loading}
          onClick={handleNext}
        />
      </LoginPanel>
      <Notification
        showAlert={success}
        closeAlert={onFinish}
        message={
          'Reset password link has been sent, please follow the link to reset your password.'
        }
      />
    </BgWrapper>
  )
}

export default ForgotPassword
