import { useState } from 'react'
import { DocumentContentTypeDropdown, theme } from 'venus/config'
import { Select, Input, Text } from 'venus/components'
import {
  PreviewArea,
  Wrapper,
  EditLeftPart,
  EditRightPart,
  PreviewPlaceHolder,
} from './UploadDocs.styles'

interface IEditDoc {
  uri: string
  newName: string[]
  setNewName: (s: string[]) => void
  docContentType: string[]
  setDocContentType: (s: string[]) => void
  index: number
}

export const EditDoc = ({
  uri,
  newName,
  setNewName,
  docContentType,
  setDocContentType,
  index,
}: IEditDoc) => {
  const [name, setName] = useState(newName[index])
  const [type, setType] = useState(docContentType[index])

  const fileExtension = uri.split('.').slice(-1)[0]
  const previewable =
    ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(fileExtension) === -1

  const handleSetNewName = (v) => {
    setName(v)
    const arr = [...newName]
    arr[index] = v
    setNewName(arr)
  }

  const handleSetDocContentType = (v) => {
    setType(v)
    console.log('change v', v)
    const arr = [...docContentType]
    arr[index] = v
    setDocContentType([...arr])
  }

  return (
    <Wrapper>
      <EditLeftPart>
        {previewable && <PreviewArea src={uri} />}
        {!previewable && (
          <PreviewPlaceHolder>
            <Text format='h2' color={theme.colors.Grey}>
              {fileExtension.toUpperCase()}
            </Text>
          </PreviewPlaceHolder>
        )}
      </EditLeftPart>
      <EditRightPart>
        <Input
          label='Document Name'
          value={name}
          onChange={(v) => handleSetNewName(v)}
          style={{ marginBottom: 12 }}
          isError={name !== name.trim()}
          errorMsg='Please remove leading or trailing spaces.'
        />
        <Select
          title='Document Type'
          options={DocumentContentTypeDropdown}
          value={type}
          onChange={(v) => handleSetDocContentType(v)}
        />
      </EditRightPart>
    </Wrapper>
  )
}
