import { useState } from "react"
import { Text, Input, Row } from "venus/components"

export const FixedHeightRowSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [text2, setText2] = useState("")

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <Row style={{ padding: 18, width: 500 }} height={80}>
        <Input
          label="Name"
          type="text"
          value={text2}
          onChange={setText2}
          placeholder="This is place holder."
        />
      </Row>
      <Row style={{ padding: 18, width: 500 }} height={80}>
        <Input
          label="Phone"
          type="text"
          value={text2}
          onChange={setText2}
          placeholder="This is place holder."
        />
      </Row>
      <Row style={{ padding: 18, width: 500 }} height={80}>
        <Input
          label="Email"
          type="text"
          value={text2}
          onChange={setText2}
          placeholder="This is place holder."
        />
      </Row>
    </>
  )
}
