// import React from 'react'
import './App.css'
import '@fontsource/poppins'
import '@fontsource/roboto-mono'
// import { MHV } from 'venus/layout'
import Router from './Router'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { theme } from './config'
import getStore from './redux/store'
import ErrorHandler from 'venus/layout/ErrorHandler'
import { MobileProvider } from 'venus/context/MobileBrowser'
import { ModelProvider } from './context/ModelContext'
// import { UploadContextProvider } from './context/UploadContext'

function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
  // app.get('/transfer', deeplink);
  // app.get('/share', deeplink);
  // app.get('/request-ownership', deeplink);
  // app.get('/sign-up', deeplink);
  // app.get('/auth/reset-password', deeplink);

  const store = getStore()

  return (
    // <MHV>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId='697648818312-e2qqosjm9m2v321tkc0etai5u7l92ton.apps.googleusercontent.com'>
          {/* <UploadContextProvider> */}
          <ErrorHandler>
            <MobileProvider>
              <ModelProvider>
                <Router />
              </ModelProvider>
            </MobileProvider>
          </ErrorHandler>
          {/* </UploadContextProvider> */}
        </GoogleOAuthProvider>
      </ThemeProvider>
    </Provider>
    // </MHV>
  )
}

export default App
