import { useState } from "react"
import { theme } from "venus/config"
import { BtnArea, SingleButton } from "./Pills.styles"
import { Text } from "../Text"

interface IPills {
  options: {
    name: string
    id: string
  }[]
  handlePress: Function
  style?: any
}

export const Pills = ({ options, handlePress, style }: IPills) => {
  const allOptions = [...options]
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const handlePressBtn = (
    option: {
      name: string
      id: string
    },
    index: number
  ) => {
    handlePress(option)
    setSelectedIndex(index)
  }

  return (
    <BtnArea>
      {allOptions.map((option, index) => {
        const { name, id } = option
        return (
          <SingleButton
            key={`pill_button_${id}_${index}`}
            onClick={() => handlePressBtn(option, index)}
            style={{
              backgroundColor:
                index === selectedIndex
                  ? theme.colors.Primary
                  : theme.colors.LightBlue,
              ...style,
            }}
          >
            <Text
              value={name}
              format="h4"
              fontWeight={index === selectedIndex ? 600 : 500}
              color={
                index === selectedIndex
                  ? theme.colors.White
                  : theme.colors.Primary
              }
            />
          </SingleButton>
        )
      })}
    </BtnArea>
  )
}
