import { roles, tradesPeopleSubRolesArr } from 'venus/config'

export const getRoleLabel = (v: string, subRole?: string[]) => {
  for (let i = 0, j = roles.length; i < j; i++) {
    const { label, value } = roles[i]
    if (value === v && v !== 'TRADESPEOPLE') return label
    if (value === v && v === 'TRADESPEOPLE') {
      if (!subRole) return ''
      let text = ''
      for (let p = 0, q = subRole?.length || 0; p < q; p++) {
        const sub = subRole[p]
        for (let i = 0, j = tradesPeopleSubRolesArr.length; i < j; i++) {
          const { label, value } = tradesPeopleSubRolesArr[i]
          if (value === sub) {
            text += `  ${label}`
          }
        }
      }
      return text.trim()
    }
  }
  return ''
}

export const get1stLevelRoleLabel = (v: string) => {
  for (let i = 0, j = roles.length; i < j; i++) {
    const { label, value } = roles[i]
    if (value === v) return label
  }
  return ''
}
