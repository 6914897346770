import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useState, useEffect } from 'react'
import { theme, MIN_NUMBER_TO_SHOW_FIND_TRADESPEOPLE } from 'venus/config'
import { Text, Loader, Chevron, Drawer, ActionLayout, EmptyData, ContactDrawView, Notification } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { IContactEntry, createContact, getContactList } from 'venus/redux/features/contact'
import TradespeopleCardView from 'venus/pages/Tradespeople/TradespeopleCardView'
import { getTradespeopleList, getTradespeopleAvailability } from 'venus/redux/features/tradespeople/tradespeople'
import {
  Container,
  SearchBoxContainer,
  SearchResultContainer,
  SearchResultTitle,
  ListViewContainer,
  HeaderButtonBox,
  EmptyArea, SearchEmptyArea
} from 'venus/pages/Tradespeople/TradespeopleList.styles'
import { searchTradespeople } from 'venus/redux/features/tradespeople/searchTradespeople'
import {
  TradespeopleSteps,
  SEARCH_LOADING_TEXT,
  SEARCH_AVAILABLE_TEXT,
  ADD_CONTACT_TEXT,
  SEARCH_RESULT_TITLE,
  EMPTY_DATA_TEXT,
  SEARCH_TRADESPEOPLE_NOT_FOUND
} from 'venus/pages/Tradespeople/Constants'
import { ReactComponent as AddIcon } from 'venus/assets/img/icons/AddSign.svg'
import { ReactComponent as SearchIcon } from 'venus/assets/img/icons/Search.svg'
import { getFullName } from 'venus/utils/fullName'


interface ITradespeopleListQuery {
  propertyId: string;
  spaceId?: string;
  itemId?: string;
}

const TradespeopleList = ({
  propertyId,
  spaceId = null,
  itemId = null,
}: ITradespeopleListQuery) => {
  const dispatch = useAppDispatch()
  const { tradespeople, numberOfTradespeopleAvailable, loading } = useAppSelector((state) => state.tradespeople)
  const { status } = useAppSelector((state) => state.subscription)

  const { searchTradespeopleResult } = useAppSelector((state) => state.searchTradespeopleResult)
  const { entries } = useAppSelector((state) => state.contact)
  const [selectedContact, setSelectedContact] = useState({} as IContactEntry)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [isExistContact, setIsExistContact] = useState(false)
  const [step, setStep] = useState(TradespeopleSteps.GET_TRADESPEOPLE)

  const [showNotification, setShowNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')

  useEffect(() => {
    // handle document level ESC key event
    const handleKeyEvent = (event) => {
      event.stopPropagation()
      if (event.keyCode === 27) {
        closeContactDetailDrawer()
      }
    }
    window.addEventListener('keydown', handleKeyEvent)

    return () => {
      window.removeEventListener('keydown', handleKeyEvent)
    }
  }, [])

  useEffect(() => {
    dispatch(getTradespeopleList({ propertyId, spaceId, itemId }))
    dispatch(getTradespeopleAvailability(propertyId))
  }, [propertyId, spaceId, itemId])

  useEffect(() => {
    dispatch(getContactList(propertyId))
    const isExist = entries.filter((entry) => entry.mobile === selectedContact.mobile)
    setIsExistContact(!!isExist.length)
  }, [selectedContact])

  const showContactDetail = () => {
    setStep(TradespeopleSteps.SELECT_TRADESPEOPLE)
    setIsOpenDrawer(true)
  }

  const handleOnClickNewTradespeopleDetail = () => {
    setStep(TradespeopleSteps.SELECT_NEW_TRADESPEOPLE)
  }

  const closeContactDetailDrawer = () => {
    setSelectedContact({} as IContactEntry)
    setStep(TradespeopleSteps.GET_TRADESPEOPLE)
    setIsOpenDrawer(false)
  }

  const onClickSearchTradespeopleList = () => {
    setStep(TradespeopleSteps.SEARCH_TRADESPEOPLE)
    dispatch(searchTradespeople({ propertyId, spaceId, itemId }))
    setIsOpenDrawer(true)
  }

  const handleOnClickAddToContact = async () => {
    await dispatch(createContact({
      propertyId,
      body: selectedContact,
    }))
    displayNotification(`${getFullName(selectedContact)} ${ADD_CONTACT_TEXT}`)
    closeContactDetailDrawer()
  }

  const handleOnClickBack = () => {
    setStep(TradespeopleSteps.SEARCH_TRADESPEOPLE)
  }

  const displayNotification = (msg: string) => {
    setNotificationMessage(msg)
    setShowNotification(true)
  }

  if (loading) return <Loader isFull/>
  let bodyComponent
  if (!tradespeople || !tradespeople.length) {
    bodyComponent = (
      <EmptyArea>
        <EmptyData message={EMPTY_DATA_TEXT} fullBlock/>
      </EmptyArea>
    )
  } else {
    bodyComponent = (
      <ListViewContainer>
        {tradespeople.map((item) => {
          return (
            <TradespeopleCardView
              key={`tradespeople-card-${nanoid()}`}
              data={item}
              type={'property'}
              onClick={showContactDetail}
              setSelectedContact={setSelectedContact}
              isSearchResult={false}
            />
          )
        })}
      </ListViewContainer>
    )
  }

  let searchTradespeopleContents

  if (searchTradespeopleResult.length > 0) {
    searchTradespeopleContents = searchTradespeopleResult.map((item) => {
      return (
        <TradespeopleCardView
          key={`search-result-card-${nanoid()}`}
          data={item}
          type={'property'}
          onClick={handleOnClickNewTradespeopleDetail}
          setSelectedContact={setSelectedContact}
          isSearchResult={true}
          style={{ marginBottom: '18px' }}
        />
      )
    })
  } else {
    searchTradespeopleContents = (
      <SearchEmptyArea>
        <EmptyData defaultMessage={SEARCH_TRADESPEOPLE_NOT_FOUND} message={''} fullBlock/>
      </SearchEmptyArea>
    )
  }

  return (
    <>
      <Container>
        {bodyComponent}
        {/* Enable search tradespeople button if available */}
        {status !== 'FREE' && numberOfTradespeopleAvailable >= MIN_NUMBER_TO_SHOW_FIND_TRADESPEOPLE && (
          <SearchBoxContainer onClick={onClickSearchTradespeopleList}>
            <SearchIcon/>
            <Text
              format={'h4'}
              fontSize={12}
              color={theme.colors.Black}
              value={loading ? SEARCH_LOADING_TEXT : SEARCH_AVAILABLE_TEXT}
              fontWeight={400}
              lineHeight={'18px'}
            />
            <Chevron direction="right" color={theme.colors.Black} marginLeft={20}/>
          </SearchBoxContainer>
        )}
      </Container>
      <Drawer isOpen={isOpenDrawer} onClose={closeContactDetailDrawer}>
        <>
          {/* 1. On select tradespeople list */}
          {step === TradespeopleSteps.SELECT_TRADESPEOPLE && (
            <ActionLayout
              margin={36}
              children={
                <ContactDrawView contact={selectedContact}/>
              }
            />
          )}
          {/* 2. On select search result */}
          {step === TradespeopleSteps.SELECT_NEW_TRADESPEOPLE && (
            <>
              <ActionLayout
                margin={36}
                children={
                  <ContactDrawView
                    title={SEARCH_RESULT_TITLE}
                    contact={selectedContact}
                    onClickBackBtn={handleOnClickBack}
                  />
                }
                rightActions={!isExistContact && [
                  {
                    type: 'icon_button',
                    text: 'Add to contacts',
                    iconLeft: <AddIcon style={{ marginRight: '9px', color: theme.colors.White }}/>,
                    onClick: handleOnClickAddToContact,
                  }
                ]}
              />
            </>
          )}
          {/* 3. Show search result */}
          {step === TradespeopleSteps.SEARCH_TRADESPEOPLE && (
            <ActionLayout
              margin={36}
              children={
                <SearchResultContainer>
                  <SearchResultTitle>
                    <HeaderButtonBox/>
                    <Text format={'h1'} value={SEARCH_RESULT_TITLE}/>
                    <HeaderButtonBox/>
                  </SearchResultTitle>
                  {searchTradespeopleContents}
                </SearchResultContainer>
              }
            />
          )}
        </>
      </Drawer>
      <Notification
        showAlert={showNotification}
        closeAlert={() => {
          setShowNotification(false)
        }}
        message={notificationMessage}
      />
    </>
  )
}

export default TradespeopleList
