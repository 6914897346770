import { isRejectedWithValue } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

export const errorHandling = (err: any) => {
  const error: AxiosError = err
  if (!error?.response) {
    if (err?.data) {
      return isRejectedWithValue(err.data)
    }
    throw err
  }

  return isRejectedWithValue(error.response.data)
}
