import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setErrorMessage } from 'venus/redux/features/error/reducer'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getHistories, setShowHistoryDrawer } from 'venus/redux/features/history/histories'
import { setHistory, updateHistory, deleteHistory, createHistory, resetHistoryStore } from 'venus/redux/features/history/history'
import { Loader, Button, HistoryCardView, Drawer, EmptyData } from 'venus/components'
import HistoryDetailsTabView from 'venus/pages/History/HistoryDetailsTabView'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { EmptyArea, Container } from './HistoryList.styles'

interface IHistoryListProps {
  propertyId: string
  spaceId: string
  itemId?: string
  spaceNameTag: string
  itemName?: string
  setTabButtons?: React.Dispatch<React.SetStateAction<JSX.Element>>
}

const HistoryList = ({
  propertyId,
  spaceId,
  itemId,
  spaceNameTag,
  itemName,
  setTabButtons,
}: IHistoryListProps) => {
  const dispatch = useAppDispatch()
  const { histories, loading, showHistoryModal } = useAppSelector(
    (state) => state.histories,
  )
  const { history } = useAppSelector((state) => state.history)
  const [isEditMode, setIsEditMode] = useState(false)
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode)
  }

  useEffect(() => {
    if (spaceNameTag === 'Property Wide') {
      dispatch(getHistories({ propertyId, shareId, dreamPropertyId }))
    } else {
      dispatch(getHistories({ propertyId, spaceId, itemId, shareId, dreamPropertyId }))
    }
    if (!shareId && !dreamPropertyId) {
      setTabButtons(
        <Button
          type='icon_button'
          text='Add History'
          iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
          style={{ width: 'auto' }}
          onClick={onClickCreateBtn}
        />,
      )
    }
    // handle document level ESC key event
    const handleKeyEvent = (event) => {
      event.stopPropagation()
      if (event.keyCode === 27) {
        handleCloseDrawer()
      }
    };
    window.addEventListener('keydown', handleKeyEvent);

    return () => {
      // reset buttons on unmount
      setTabButtons(<></>)
      // reset form on unmount
      dispatch(resetHistoryStore())
      // unbind key events
      window.removeEventListener('keydown', handleKeyEvent)
    }
  }, [])

  const onClickHistoryRow = (historyId) => {
    const targetHistory = histories.find((history) => history.id === historyId)
    if (!targetHistory) return
    dispatch(setHistory(targetHistory))
    dispatch(setShowHistoryDrawer(true))
  }

  const onClickCreateBtn = () => {
    const tags = []
    if (spaceNameTag) tags.push(spaceNameTag)
    if (itemName) tags.push(itemName)

    dispatch(
      setHistory({
        id: null,
        propertyId: propertyId,
        spaceId: spaceId,
        itemId: itemId,
        contacts: [],
        eventName: '',
        eventDate: null,
        rating: {
          timeManagement: null,
          communication: null,
          quality: null,
          pricing: null,
        },
        tags,
      }),
    )
    dispatch(setShowHistoryDrawer(true))
  }

  const onUpdateHistory = async (formData) => {
    if (!formData) return

    const historyData = {
      propertyId: propertyId,
      spaceId: spaceId || null,
      itemId: itemId || null,
      historyId: history.id || null,
      eventName: formData.eventName,
      eventDate: formData.eventDate,
      itemCost: parseFloat(formData.itemCost),
      laborCost: parseFloat(formData.laborCost),
      notes: formData.notes,
      contactIds: formData.contacts.map((contact) => contact.id),
      rating: {
        timeManagement: formData.timeManagementRating,
        communication: formData.communicationRating,
        pricing: formData.pricingRating,
        quality: formData.qualityRating,
      },
    }
    let result;
    if (history.id) {
      result = await dispatch(updateHistory(historyData))
    } else {
      result = await dispatch(createHistory(historyData))
    }
    // if string error returns, show error message
    if (result.meta.requestStatus === 'rejected') {
      dispatch(setErrorMessage(result.payload))
    }
  }

  const onClickDeleteHistory = () => {
    if (!history) return
    setIsEditMode(false)
    dispatch(
      deleteHistory({
        historyId: history.id,
        propertyId: history.propertyId,
        spaceId: history.spaceId,
        itemId: history.itemId,
      }),
    )
  }

  const onCloseDrawer = useRef(null)

  const handleCloseDrawer = () => {
    if (_.isFunction(onCloseDrawer?.current)) {
      onCloseDrawer?.current()
    }
    setIsEditMode(false)
    dispatch(resetHistoryStore())
    dispatch(setShowHistoryDrawer(false))
  }

  if (loading) return <Loader isFull/>

  let bodyContents
  if (!histories || !histories.length) {
    bodyContents = (
      <EmptyArea>
        <EmptyData
          message={
            'Click the plus button to create a timeline event. This will then appear in your history.'
          }
        />
      </EmptyArea>
    )
  } else {
    bodyContents = (
      <Container>
        {histories.map((item, index) => {
          return <HistoryCardView key={index} data={item} onClick={onClickHistoryRow}/>
        })}
      </Container>
    )
  }
  return (
    <>
      {bodyContents}
      <Drawer onClose={handleCloseDrawer} isOpen={showHistoryModal}>
        <HistoryDetailsTabView
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          onDelete={onClickDeleteHistory}
          onUpdate={onUpdateHistory}
          onCloseDrawer={onCloseDrawer}
        />
      </Drawer>
    </>
  )
}

export default HistoryList
