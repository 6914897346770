import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  screen: '',
  data: null,
}

const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    resetRedirectStore: () => initialState,
    setRedirect: (state, action) => {
      state = { ...action.payload }
    },
    resetRedirect: (state) => {
      state = { ...initialState }
    },
  },
})

export const { setRedirect, resetRedirect, resetRedirectStore } =
  redirectSlice.actions

export default redirectSlice.reducer
