import { useState } from 'react'
import { Text, InputMultipleLine } from 'venus/components'

export const InputMultipleLineSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [text, setText] = useState('')
  const [text2, setText2] = useState('')

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <div style={{ width: 500 }}>
        <InputMultipleLine
          label='Name'
          type='text'
          value={text}
          onChange={setText}
          placeholder='This is place holder.'
          style={{ margin: 18 }}
        />
      </div>
      <div style={{ width: 500 }}>
        <InputMultipleLine
          label='Error Name'
          isError
          type='text'
          value={text2}
          onChange={setText2}
          placeholder='This is error state.'
          style={{ margin: 18 }}
          rows={5}
          errorMsg='This is error state.'
        />
      </div>
    </>
  )
}
