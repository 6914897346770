import React from 'react'
import { theme } from 'venus/config'
import useDebounceAction from 'venus/hooks/useDebounceAction'
import { BtnWrapper } from './IconButton.styles'

interface IIconButton {
  onClick: () => void
  icon: React.ReactElement
  textColor?: string
  textColorOnHover?: string
  style?: any
}

export const IconButton = ({
  onClick,
  icon,
  textColor = theme.colors.Black,
  textColorOnHover = theme.colors.Black,
  style = {},
}: IIconButton) => {
  const debounce = useDebounceAction()
  const handlePress = () => debounce(onClick)

  return (
    <BtnWrapper
      textColor={textColor}
      textColorOnHover={textColorOnHover || textColor}
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        ...style,
      }}
      onClick={handlePress}
    >
      {icon}
    </BtnWrapper>
  )
}
