import { useState } from 'react'
import { Text, LinkButton } from 'venus/components'
import { theme } from 'venus/config'
import { displayLongText } from 'venus/utils'
import { ReactComponent as Kebab } from 'venus/assets/img/icons/Kebab.svg'
import { PreviewDoc } from 'venus/pages/Documents/PreviewDoc'
import {
  DocumentWrapper,
  KebabIconWrapper,
  HyperLink,
  SelectOptionsContainer,
  SubListBg,
} from './Details.styles'

interface IDetails {
  updatedAt: string
  url: string
  displayName: string
  contentType: string
  handlePress: Function
  handlePressKebab: Function
  handlePressRename: () => void
  handlePressMove: () => void
  handlePressDelete: () => void
  isShare?: boolean
  isPersonal: boolean
  isPersonalItem: boolean
}

export const Details = ({
  updatedAt,
  url,
  displayName,
  contentType,
  handlePress,
  handlePressKebab,
  handlePressRename,
  handlePressMove,
  handlePressDelete,
  isShare,
  isPersonal,
  isPersonalItem,
}: IDetails) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenPreview, setIsOpenPreview] = useState(false)

  const onRename = () => {
    handlePressRename()
    setIsOpen(false)
  }

  const onMove = () => {
    handlePressMove()
    setIsOpen(false)
  }

  const onDelete = () => {
    handlePressDelete()
    setIsOpen(false)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const handleClickPreview = () => {
    const type = url.split('.').slice(-1)[0]
    const isOpen =
      ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(type) === -1
    if (isOpen) window.open(url, '_blank').focus()
  }

  const handleClosePreview = () => {
    setIsOpenPreview(false)
  }

  return (
    <>
      <DocumentWrapper
        onClick={() => handlePress(updatedAt, url, displayName, contentType)}
      >
        <HyperLink onClick={handleClickPreview}>
          <Text
            value={displayLongText(displayName || url)}
            color={theme.colors.Black}
            format='h4'
            style={{ marginBottom: 3, width: 256, display: 'inline-block' }}
          />
        </HyperLink>
        <Text
          value={updatedAt}
          color={theme.colors.DarkestGrey}
          format='h4'
          style={{ width: 120, display: 'inline-block' }}
        />
        {!isShare && (
          <KebabIconWrapper
            onClick={() => {
              setIsOpen(true)
              handlePressKebab(updatedAt, url, displayName, contentType)
            }}
          >
            <Kebab />
          </KebabIconWrapper>
        )}
        {isOpen && (
          <SelectOptionsContainer
            style={{
              width: !isPersonal && !isPersonalItem ? '200px' : '138px',
              zIndex: 2,
            }}
          >
            <LinkButton
              handlePress={onRename}
              label='Edit'
              color={theme.colors.Black}
              format='h4'
            />
            {!isPersonal && !isPersonalItem && (
              <LinkButton
                handlePress={onMove}
                label='Move'
                color={theme.colors.Black}
                format='h4'
              />
            )}
            <LinkButton
              handlePress={onDelete}
              label='Delete'
              color={theme.colors.Black}
              format='h4'
            />
          </SelectOptionsContainer>
        )}
      </DocumentWrapper>
      {isOpen && <SubListBg onClick={onClose} />}
      <PreviewDoc
        isOpenPreview={isOpenPreview}
        handleClosePreview={handleClosePreview}
        url={url}
        displayName={displayName}
      />
    </>
  )
}
