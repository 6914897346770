import styled from 'styled-components'
import { Panel } from './PropertyDetails.styles'
import Comparable from './Comparable'
import { Text } from 'venus/components'

const Container = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
`

const Comparables = ({ comparables = [], googlePlaceId = false }: any) => {
  console.log('comparables', comparables)
  if (googlePlaceId) {
    return null
  }
  return (
    <Panel style={{ padding: 36, marginBottom: 20 }}>
      <Text format='h1' value='Comparable Sales' style={{ marginBottom: 20 }} />

      <Container>
        {comparables.map((comparable) => (
          <Comparable {...comparable} key={comparable.address} />
        ))}
      </Container>
    </Panel>
  )
}

export default Comparables
