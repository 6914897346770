export const signUpMsg = {
  emptyFirstName: 'First Name is missing.',
  emptyLastName: 'Last Name is missing.',
  emptyEmail: 'Email is missing.',
  invalidEmailFormat: 'Please enter in a valid email address.',
  repeatedEmail: 'Email address has been used.  Please try another one.',
  emptyEmailMobile: 'Please enter your mobile / email to login.',
  invalidMobile: 'Please enter a valid 10 digits Australian mobile phone number.',
  repeatedMobile: 'This mobile phone number has been used.',
  emptyPassword: 'Password is missing.',
  emptyRole: 'Role is missing.',
  emptyTradespeople: 'Role of tradespeople is missing.',
  invalidPassword:
    'Password should contain at least one upper case alphabet letter, one lower case alphabet letter, one number, one special character and at least 10 characters.',
  wrongPin: 'The PIN is incorrect. Please try it again.',
  passwordNotMatch: 'Your new password and confirmation password do not match.',
  wrongPassword: 'The current password is wrong.',
  wrongUserNamePassword: 'The user name or password is wrong.',
  wrongCode: 'The verification code is wrong.',
  pinNotMatch: 'Your new PIN and confirmation PIN do not match.',
  invalidPin: 'Please choose PIN other than 0000 or 1111.',
  invalidPinLength: 'PIN should be 4 numbers.',
  setPin: 'Pin changed successfully.',
  invalidSignUp: 'Sign up failed.',
  resentCode: 'Code has resent.',
  setPwd: 'Password changed successfully.'
}

export const ResetPwdAlert = "We've sent you an email with a link to reset your password."
export const ResetPINAlert = "We've sent you an email with a link to reset your PIN."
