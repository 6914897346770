import { configureStore } from '@reduxjs/toolkit'
import user from 'venus/redux/features/user/reducer'
import properties from 'venus/redux/features/property/properties'
import property from 'venus/redux/features/property/property'
import space from 'venus/redux/features/space/space'
import item from 'venus/redux/features/item/item'
import personal from 'venus/redux/features/personal/reducer'
import address from 'venus/redux/features/address/reducer'
import onboarding from 'venus/redux/features/onboarding/reducer'
import docs from 'venus/redux/features/docs/reducer'
import contact from 'venus/redux/features/contact/reducer'
import profile from 'venus/redux/features/profile/reducer'
import histories from 'venus/redux/features/history/histories'
import history from 'venus/redux/features/history/history'
import sharedProperties from 'venus/redux/features/property/sharedProperties'
import npd from 'venus/redux/features/npdData/npdData'
import tradespeople from 'venus/redux/features/tradespeople/tradespeople'
import searchTradespeopleResult from 'venus/redux/features/tradespeople/searchTradespeople'
import share from 'venus/redux/features/share/reducer'
import redirect from 'venus/redux/features/redirect/reducer'
import reminder from 'venus/redux/features/reminder/reducer'
import subscription from 'venus/redux/features/subscription/subscription'
import dreamprice from 'venus/redux/features/dreamprice/reducer'
import image from 'venus/redux/features/image/image'
import video from 'venus/redux/features/video/video'

import error from 'venus/redux/features/error/reducer'

let store

const createStore = () => {
  const newStore = configureStore({
    reducer: {
      error,
      user,
      properties,
      sharedProperties,
      property,
      space,
      item,
      npd,
      address,
      onboarding,
      contact,
      profile,
      histories,
      history,
      tradespeople,
      searchTradespeopleResult,
      share,
      redirect,
      subscription,
      reminder,
      image,
      dreamprice,
      personal,
      docs,
      video,
    },
  })
  return newStore
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

const getStore = () => {
  if (!store) store = createStore()
  return store
}

export { store }

export default getStore
