import styled from 'styled-components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'

const Container = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.ErrorState};
  padding: 5px 10px;
  padding-left: 12px;

  border-radius: 20px;
`

const TransferTag = () => {
  return (
    <Container>
      <Text color={theme.colors.White} format='h4' fontSize={12} lineHeight={1.5}>
        Pending Transfer
      </Text>
    </Container>
  )
}

export default TransferTag
