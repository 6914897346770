import ReactModal from 'react-modal'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { Content } from './PaymentResult.styles'
import { ReactComponent as Loader } from 'venus/assets/img/icons/Loader.svg'

const Processing = () => {
  return (
    <ReactModal
      isOpen
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 12,
          background: 'none',
        },
        content: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0,0,0,0.6)',
          border: 0,
          padding: 0,
          height: '100%',
          width: '100%',
        },
      }}
    >
      <Content>
        <Loader style={{ marginTop: 40 }} />
        <Text
          format='h1'
          fontSize={18}
          style={{ margin: 16 }}
          fontWeight={700}
          color={theme.colors.Primary}
        >
          Processing Payment
        </Text>
        <Text format='body' style={{ width: 312, textAlign: 'center' }}>
          Please wait while we process your transaction. Do not exit or refresh this page.
        </Text>
      </Content>
    </ReactModal>
  )
}

export default Processing
