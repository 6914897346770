import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { buildDetectedObjects, createHTMLImageElement } from 'venus/common/ScanHome/utils'
import { useModelContext } from 'venus/context/ModelContext'
import * as tf from '@tensorflow/tfjs'
import { formatPredictions } from '../ScanHome/Scanner'

const AppScanHome = () => {
  const [searchParams] = useSearchParams()

  const [imageLoaded, setImageLoaded] = useState(false)
  const url = searchParams.get('url')
  const { isReady, init, isLoading, models } = useModelContext()

  useEffect(() => {
    if (!isReady && !isLoading) {
      init()
    }
  }, [isReady, isLoading])

  useEffect(() => {
    const sendDataToReactNativeApp = async (data: string) => {
      //@ts-ignore
      window.ReactNativeWebView?.postMessage(data)
    }

    const processImage = async () => {
      console.log('isR', isReady)
      // const img = await createHTMLImageElement(url)

      const img = document.getElementById('scan_image')
      // await sendDataToReactNativeApp(url)

      // img.onload = () => {
      //   const a = tf.browser.fromPixels(im, 4)
      //   a.print()
      //   console.log(a.shape)
      // }

      // @ts-ignore
      let imageTensor = tf.browser.fromPixels(img)

      // const batched = imageTensor.expandDims(0)

      const batched = tf.tidy(() => tf.expandDims(imageTensor))

      // @ts-ignore
      let result = await models.custom.executeAsync(batched)
      const height = batched.shape[1]
      const width = batched.shape[2]
      console.log('result', result)

      const scores = result[3].arraySync()
      const boxes = result[6].arraySync()
      const classes = result[7].dataSync()

      const detections = buildDetectedObjects(width, height, scores, boxes, classes)
      const formatted = formatPredictions([...detections])
      console.log('DETECT', formatted)

      await sendDataToReactNativeApp(JSON.stringify(formatted))
    }

    if (imageLoaded && isReady) {
      processImage()
    }
  }, [isReady, url, imageLoaded])

  return (
    <div>
      {!!url && (
        <img
          onLoad={() => {
            setImageLoaded(true)
          }}
          crossOrigin='anonymous'
          id='scan_image'
          src={url}
        />
      )}
    </div>
  )
}

export default AppScanHome
