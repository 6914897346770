import { Option } from './Select'
import { Text } from '..'
import { DropdownBg, OptionArea, SelectButton } from './Select.styles'

const SelectOptions = ({
  options,
  onSelect,
  onClose,
  isSmallVersion = false,
  forSelect = false,
  showTransparentBg = true,
}: {
  options: Option[]
  onSelect: (option: Option) => void
  onClose: () => void
  isSmallVersion?: boolean
  forSelect?: boolean
  showTransparentBg?: boolean
}) => {
  return (
    <>
      <OptionArea
        style={{
          border: options.length > 0 ? '0.5px solid #d8d8d8' : 'none',
          position: forSelect ? 'absolute' : 'relative',
          top: !forSelect ? '1px' : isSmallVersion ? '67px' : '74px',
        }}
      >
        {options.map((option: Option) => (
          <SelectButton
            key={option.value}
            onClick={() => onSelect(option)}
            style={{
              border: 'none',
            }}
          >
            <Text value={option.label} format='body' />
          </SelectButton>
        ))}
      </OptionArea>
      {showTransparentBg && <DropdownBg onClick={onClose} />}
    </>
  )
}

export default SelectOptions
