import { useState } from 'react'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { saveShareScope } from 'venus/redux/features/share'
import { SelectPSIScope } from 'venus/common/SelectPSIScope'
import { Modal } from 'venus/components'
import useSpaces from 'venus/hooks/useSpaces'
import { SetDreamPrice } from '../SetDreamPrice'
import { useParams } from 'react-router-dom'

interface IShareDreamProperty {
  isOpen: boolean
  onClose: () => void
}

export const ShareDreamProperty = ({ isOpen, onClose }: IShareDreamProperty) => {
  const dispatch = useAppDispatch()
  const [isShareWholeProperty, setIsShareWholeProperty] = useState(true)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [step, setStep] = useState(1)
  const { property } = useAppSelector((state) => state.property)
  const { spaces: spaceList } = useSpaces(property?.id)
  const { propertyId } = useParams()

  if (!property) return null

  // const { id: propertyId } = property
  const allCheckedItemIds = spaceList.map(({ id, name, items }) => ({
    spaceId: id,
    spaceName: name,
    items: items ? items.map(({ id, name }) => ({ itemId: id, itemName: name })) : [],
  }))

  const handlePressNext = () => {
    dispatch(
      saveShareScope({
        propertyId,
        isShareDocument: false,
        isShareHistory: false,
        isShareWholeProperty,
        shareScope: isShareWholeProperty ? allCheckedItemIds : checkedItemIds,
      }),
    )
    setStep(2)
  }

  const onCloseSetDreamPrice = () => {
    setIsShareWholeProperty(true)
    setCheckedItemIds([])
    setStep(1)

    onClose()
  }

  return (
    <Modal
      onBack={
        step === 2
          ? () => {
              setStep(1)
            }
          : null
      }
      isOpen={isOpen}
      onClose={onCloseSetDreamPrice}
      title={step === 1 ? 'Select Items to Share' : 'Share Dream Price'}
    >
      {step === 1 && (
        <SelectPSIScope
          isShareWholeProperty={isShareWholeProperty}
          setIsShareWholeProperty={setIsShareWholeProperty}
          spaceList={spaceList}
          checkedItemIds={checkedItemIds}
          setCheckedItemIds={setCheckedItemIds}
          handlePressNext={handlePressNext}
          displayPremiumOption={false}
          type='property'
          isRequiredIDVerification={false}
          onClose={onClose}
        />
      )}
      {step === 2 && <SetDreamPrice onClose={onCloseSetDreamPrice} />}
    </Modal>
  )
}
