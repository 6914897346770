export enum ClaimExistingVaultStep {
  SELECT_ADDRESS = 'SELECT_ADDRESS',
  ENTER_OWNER_NAME = 'ENTER_OWNER_NAME',
  CONFIRM_OWNER_NAME = 'CONFIRM_OWNER_NAME',
  COMPLETE = 'COMPLETE',
}

export const SELECT_ADDRESS_BTN_TEXT = 'Continue'
export const OWNER_NAME_INPUT_LABEL = 'Legal name of previous homeowner'
export const OWNER_NAME_INPUT_DESCRIPTION = 'This feature is only available when the previous homeowner has MyHomeVault.'
export const CONFIRM_OWNER_NAME_BTN_TEXT = 'Send Request'
export const CLAIM_ADDRESS_TEXT = 'Please enter the address of your new property to begin a vault ownership transfer if it is available.'
export const CONFIRM_OWNER_TEXT = 'We\'ll send this request to the current registered owner for them to approve. Once approved, you\'ll become the vault owner.'
export const ERROR_MSG_TITLE = 'Claim Existing Vault Error'
export const COMPLETE_MESSAGE_TITLE = 'Request Sent'
export const COMPLETE_MESSAGE = 'Your request has been sent to the current registered owner for approval. Once approved, you\'ll become the vault owner.'
