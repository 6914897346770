import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'

export const getShareList = createAsyncThunk(
  'Share/getShareList',
  async (propertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/property/${propertyId}/shares`)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const createShareLink = createAsyncThunk(
  'Share/createShareLink',
  async (
    {
      propertyId,
      eventName,
      shareScope,
      isShareWholeProperty,
      isShareDocument,
      isShareHistory,
      notes,
      isSharedAnonymously,
      isSharedDreamPrice = false,
    }: {
      propertyId: string
      eventName: string
      shareScope: { value: { spaceId: string; items: { itemId: string }[] }[] }
      isShareWholeProperty: boolean
      isShareDocument: boolean
      isShareHistory: boolean
      notes: string
      isSharedAnonymously: boolean
      isSharedDreamPrice?: boolean
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`/property/${propertyId}/share`, {
        propertyId,
        eventName,
        shareScope,
        isShareWholeProperty,
        isShareDocument,
        isShareHistory,
        notes,
        isSharedAnonymously,
        isSharedDreamPrice,
      })
      console.log('createShareLink::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const createShareTo = createAsyncThunk(
  'Share/createShareTo',
  async (
    body: {
      propertyId: string
      eventName: string
      shareScope: { value: { spaceId: string; items: { itemId: string }[] }[] }
      isShareWholeProperty: boolean
      isShareDocument: boolean
      isShareHistory: boolean
      notes: string
      isSharedAnonymously: boolean
      share: {
        shareTo: string
        shareMethod: string
      }
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`/property/${body.propertyId}/share`, {
        ...body,
        isSharedDreamPrice: false,
      })
      console.log('createShareTo::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  }
)

export const getShareItem = createAsyncThunk(
  'Share/getShareItem',
  async (
    { propertyId, shareId }: { propertyId: string; shareId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(
        `/property/${propertyId}/shares/${shareId}`
      )
      console.log('getShareItem::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteShareItem = createAsyncThunk(
  'Share/deleteShareItem',
  async (
    { propertyId, shareId }: { propertyId: string; shareId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.delete(
        `/property/${propertyId}/share/${shareId}`
      )
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteReceivedShareProperty = createAsyncThunk(
  'Share/deleteReceivedShareProperty',
  async (shareId: string, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/share/${shareId}`)
      console.log('deleteReceivedShareProperty::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const getReceivedShareProperty = createAsyncThunk(
  'Share/getReceivedShareProperty',
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/share/${code}`)
      console.log('getReceivedShareProperty::', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const addReceivedShareProperty = createAsyncThunk(
  'Share/addReceivedShareProperty',
  async (code: string, { rejectWithValue }) => {
    try {
      const response = await api.post(`/share`, {
        code,
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  }
)
