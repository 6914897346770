import styled from 'styled-components'
import { theme } from 'venus/config'

export const ListWrapper = styled.ul`
  margin: 0;
  margin-top: 24px;
  margin-bottom: 48px;
  padding: 0;
  list-style: none;
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 28px;
  grid-row-gap: 28px;
`
type ItemProps = {
  readonly coverImage?: string
}

export const AddressShader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0) 100%);
  transition: all 0.3s;
`

export const OptionWrapper = styled.div`
  position: absolute;
  top: 31px;
  right: 0px;
  width: 209px;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  display: none;
`

export const KebabWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(16, 13, 66, 0.5);
  :hover {
    background: ${theme.colors.White};
  }

  :hover ${OptionWrapper} {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  :hover svg {
    color: ${theme.colors.Black};
  }
`

export const OptionItem = styled.div`
  width: 100%;
  height: 40px;
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: ${theme.colors.LightBlue};
  }
`

export const Item = styled.li<ItemProps>`
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Grey};

  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(66%);
  }

  :hover ${AddressShader} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 100%);
  }

  ${(props) =>
    !!props.coverImage &&
    `background-image: url(${props.coverImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;`}
`

export const PropertyCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: flex-end;
  padding: 12px 20px;

  cursor: pointer;
`

export const IconArea = styled.div`
  position: absolute;
  top: 100px;
  right: 24px;
  &:hover {
    button {
      svg {
        color: white;
      }
    }
  }
`
