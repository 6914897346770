import styled from 'styled-components'
import { Text } from 'venus/components'
import { ReactComponent as MHV } from 'venus/assets/img/icons/MHV.svg'
import { theme } from 'venus/config'

const Container = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.Secondary};
  padding: 5px 10px;
  padding-left: 12px;

  border-radius: 20px;
`

const PremiumTag = () => (
  <Container>
    <MHV color={theme.colors.FreeYellow} width={14} height={14} />
    <Text
      format='h4'
      fontSize={12}
      lineHeight={'18px'}
      style={{ marginLeft: 8 }}
      color={theme.colors.White}
    >
      Premium
    </Text>
  </Container>
)

export default PremiumTag
