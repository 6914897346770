import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import {
  ActiveIndicator,
  Background,
  IconWrapper,
  Item,
  MenuItemLink,
} from './Menus.styles'
import { MenuItemExternalLink } from './Menus.styles'

export interface IMenu {
  icon?: any
  isActive?: boolean
  label?: string
  url?: string
  title?: string
  isGlobal?: boolean
  externalUrl?: string
  badge?: ReactNode
}

const MenuItem = (menu: IMenu) => {
  const { label, url, icon, title, isGlobal, externalUrl, badge } = menu

  const IconComponent = icon

  if (title) {
    return (
      <Item>
        <Text
          format='h4'
          value={title}
          fontWeight={600}
          fontSize={12}
          color={theme.colors.DarkestGrey}
          style={{ marginLeft: 24 }}
        />
      </Item>
    )
  }
  if (externalUrl) {
    return (
      <Item>
        <MenuItemExternalLink
          href={externalUrl}
          target={'_blank'}
          referrerPolicy={'no-referrer'}
          rel={'noopener'}
          style={{ height: 48 }}
        >
          <Background isActive={false}>
            <IconWrapper>
              <IconComponent color={theme.colors.DarkestGrey} />
            </IconWrapper>
            <Text
              format='h4'
              value={label}
              fontWeight={500}
              fontSize={16}
              color={theme.colors.DarkestGrey}
            />
          </Background>
        </MenuItemExternalLink>
      </Item>
    )
  }
  return (
    <Item>
      <MenuItemLink end to={url} style={{ height: 48, width: '100%' }}>
        {({ isActive }) => (
          <Background isActive={isActive}>
            {isActive && <ActiveIndicator />}
            <IconWrapper>
              <IconComponent
                color={isActive ? theme.colors.Primary : theme.colors.DarkestGrey}
              />
            </IconWrapper>
            <Text
              format='h4'
              value={label}
              fontWeight={500}
              fontSize={16}
              color={isActive ? theme.colors.Primary : theme.colors.DarkestGrey}
            />
            {badge}
          </Background>
        )}
      </MenuItemLink>
    </Item>
  )
}

export default MenuItem
