import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  margin: 24px 0;
`

export const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
`
