import styled from 'styled-components'

export const ModalContent = styled.iframe`
  margin-top: -72px;
  height: 600px;
  border: none;
`

export const DownLoadBtn = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 330px;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 17px;
  right: 148px;
`
