import { theme } from 'venus/config'
import { Button } from 'venus/components'
import { ModalLayout } from 'venus/layout/ModalLayout'
import { EditDoc } from 'venus/pages/Onboarding/UploadDocs/EditDoc'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { tabs } from 'venus/config'
import {
  editPersonalDocs,
  editPersonalItemDocs,
  createDocInfo,
  createPersonalDocInfo,
  createPersonalItemDocInfo,
  IDoc,
} from 'venus/redux/features/docs'
import { BtnArea, Wrapper, EditDocsWrapper } from './CreateEditDoc.styles'
import { useState } from 'react'

interface ICreateEditDoc {
  handleClose: () => void
  propertyId: string
  propertyWideSpaceId: string
  spaceId?: string
  itemId?: string
  personalItemId?: string
  type: 'property' | 'space' | 'item' | 'personal' | 'personalItem'
  onUpdate?: Function
  actionType: 'create' | 'edit'
  uploadURL: string[]
  uploadName: string[]
  setUploadName: (s: string[]) => void
  activeIndex: number
  document: IDoc
}

export const CreateEditDoc = ({
  handleClose,
  propertyId,
  propertyWideSpaceId,
  spaceId,
  itemId,
  type,
  onUpdate,
  personalItemId,
  actionType,
  uploadURL,
  uploadName,
  setUploadName,
  activeIndex,
  document,
}: ICreateEditDoc) => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.docs)
  const [contentType, setContentType] = useState(
    actionType === 'create'
      ? uploadURL.map(() => tabs[activeIndex].key)
      : [document.contentType],
  )
  console.log('uploadURL::', uploadURL)
  console.log('uploadName::', uploadName)
  console.log('contentType::', contentType)

  const handlePressSave = async () => {
    const docObj = uploadURL.map((url, index) => {
      const selectedType = contentType[index] as any
      return {
        displayName: uploadName[index],
        url,
        contentType: selectedType,
      }
    })
    if (type !== 'personal' && type !== 'personalItem') {
      const body: {
        propertyId: string
        spaceId?: string
        itemId?: string
        documents: {
          displayName: string
          url: string
          contentType: string
        }[]
      } = {
        propertyId,
        spaceId: propertyWideSpaceId,
        documents: docObj,
      }
      if (type === 'space') body.spaceId = spaceId
      if (type === 'item') {
        body.spaceId = spaceId
        body.itemId = itemId
      }

      const result = await dispatch(createDocInfo(body))
      console.log('createDocInfo result::', result)

      if (onUpdate) {
        dispatch(onUpdate(result.payload))
      }
    } else if (type === 'personal') {
      const result =
        actionType === 'create'
          ? await dispatch(createPersonalDocInfo(docObj))
          : await dispatch(editPersonalDocs(docObj))
      console.log(`${actionType} PersonalDocInfo result::`, result)
    } else if (type === 'personalItem') {
      const result =
        actionType === 'create'
          ? await dispatch(
              createPersonalItemDocInfo({
                documents: docObj,
                id: personalItemId,
              }),
            )
          : await dispatch(
              editPersonalItemDocs({
                body: docObj,
                id: personalItemId,
              }),
            )
      console.log(`${actionType} PersonalItemDocInfo result::`, result)
    }
    handleClose()
  }

  const handlePressClose = () => {
    handleClose()
  }

  const title =
    actionType === 'create'
      ? uploadURL.length > 1
        ? `Upload documents (${uploadURL.length})`
        : `Upload document`
      : 'Edit document'

  const checkIsDisabled = () => {
    if (contentType.indexOf('ALL') >= 0) return true
    if (uploadURL.length !== contentType.length) return true
    if (uploadName.indexOf('') >= 0) return true
    for (let i = 0, l = uploadName.length; i < l; i++) {
      const name = uploadName[i]
      if (name !== name.trim()) return true
    }
    return false
  }

  return (
    <ModalLayout isOpen closeModal={handleClose} title={title}>
      <Wrapper>
        <EditDocsWrapper>
          {uploadURL.map((_, index) => {
            return (
              <EditDoc
                uri={uploadURL[index]}
                newName={uploadName}
                setNewName={setUploadName}
                docContentType={contentType}
                setDocContentType={setContentType}
                index={index}
                key={`edit_doc_${index}`}
              />
            )
          })}
        </EditDocsWrapper>
        <BtnArea>
          <Button
            onClick={handlePressClose}
            text='Cancel'
            isLoading={loading}
            type='secondary'
            style={{
              height: '36px',
              padding: '4px 28px',
              marginRight: '12px',
              width: 'auto',
            }}
          />
          <Button
            onClick={handlePressSave}
            text='Save'
            isDisabled={checkIsDisabled()}
            isLoading={loading}
            style={{
              backgroundColor: theme.colors.Primary,
              height: '36px',
              padding: '4px 28px',
              width: 'auto',
            }}
          />
        </BtnArea>
      </Wrapper>
    </ModalLayout>
  )
}
