import _ from 'lodash'
import React from 'react'
import { theme } from 'venus/config'
import { Avatar } from 'venus/components/Avatar'
import { Text, AverageRating } from 'venus/components'
import { getFullName } from 'venus/utils/fullName'
import PillTag from '../Pill/PillTag'
import {
  ContactRow,
  ArrowContainer,
  ContactCard,
  ContactInfoCard,
  TextIconWrapper,
  ArrowIconWrapper,
  ContactInfoWrapper,
  AvatarWrapper
} from 'venus/components/AvatarCard/AvatarCard.styles'
import { ReactComponent as EmailIcon } from 'venus/assets/img/icons/Email.svg'
import { ReactComponent as MobileIcon } from 'venus/assets/img/icons/Mobile.svg'
import { ReactComponent as ChevronRight } from 'venus/assets/img/icons/ChevronRight.svg'

export const AvatarCard = ({
  data,
  style,
  avatarSize = 46,
  nameStyle,
  showRole = false,
  showRating = false,
  showContactInfo = false,
  showArrow = false,
}: {
  data: any;
  style?: any;
  avatarSize?: number;
  nameStyle?: any;
  showRole?: boolean;
  showRating?: boolean;
  showContactInfo?: boolean;
  showArrow?: boolean;
}) => {
  const contactName = getFullName(data)
  const companyName = _.get(data, ['companyName'], '')
  const avatar = _.get(data, ['profile', 'avatar'], '')

  return (
    <ContactCard style={{ ...style }}>
      <AvatarWrapper>
        <Avatar size={avatarSize} avatar={avatar} isBorder={false}/>
      </AvatarWrapper>
      <ContactInfoCard>
        <Text
          format={nameStyle?.format || 'h4'}
          fontSize={nameStyle?.fontSize}
          fontWeight={nameStyle?.fontWeight}
          color={theme.colors.Primary}
          value={contactName}
          style={{
            width: '280px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
        </Text>
        <Text
          format={'body'}
          color={theme.colors.DarkestGrey}
          fontSize={'12px'}
          fontWeight={400}
          lineHeight={'18px'}
          value={companyName}
          style={{
            width: '280px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}/>
        <ContactRow>
          {showRole && (
            <PillTag
              label={_.capitalize(data?.role[0])}
              fontSize={14}
              fontWeight={500}
              color={theme.colors.Primary}
              background={theme.colors.LightBlue}
              paddingVertical={5}
            />
          )}
          {showRating && (
            <AverageRating
              average={String(data?.averageRating) || '0'}
              style={{ marginLeft: '8px', fontWeight: 500 }}
              noOfRatings={String(data?.ratingCount) || '0'}
              showNoOfRatings={true}
            />
          )}
        </ContactRow>
        {showContactInfo && (
          <ContactInfoWrapper>
            <TextIconWrapper>
              <MobileIcon/><Text format={'body'} value={data.mobile} style={{ marginLeft: '16px' }}/>
            </TextIconWrapper>
            <TextIconWrapper style={{ marginLeft: '36px' }}>
              <EmailIcon/><Text format={'body'} value={data.email} style={{ marginLeft: '16px' }}/>
            </TextIconWrapper>
          </ContactInfoWrapper>
        )}
      </ContactInfoCard>
      <ArrowContainer>
        {showArrow && (
          <ArrowIconWrapper>
            <ChevronRight style={{ color: theme.colors.BlueGrey }}/>
          </ArrowIconWrapper>
        )}
      </ArrowContainer>
    </ContactCard>
  )
}
