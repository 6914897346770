import styled from 'styled-components'

export const BodyArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 24px;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 12px;
`

export const ImageContent = styled.div`
  border-radius: 5px;
  border-radius: 8px;
  aspect-ratio: 1.38;
  overflow: hidden;
`

export const ButtonsWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
