import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { PillTag } from 'venus/components'
import PSIHeader from './PSIHeader'
import { Header } from './PSILayout.styles'

interface IPSILayout {
  title: string
  setTitle: Dispatch<SetStateAction<string>>
}

export const PSIContext = createContext<IPSILayout>({
  title: '',
  setTitle: () => {},
})

const PSILayout = ({
  children,
  title,
  subTitle = '',
  headerBackground,
  goBack,
  setting,
  onUpdateSetting,
  isShared,
  isDreamProperty,
  sharedBy = '',
  isPersonal = false,
  tag,
  onDelete,
}: any) => {
  return (
    <>
      <PSIHeader
        title={title}
        subTitle={subTitle}
        background={headerBackground}
        onBack={goBack}
        setting={setting}
        onUpdateSetting={onUpdateSetting}
        onDelete={onDelete}
        isShared={isShared}
        isPersonal={isPersonal}
        isDreamProperty={isDreamProperty}
        sharedBy={sharedBy}
        tag={tag}
      />
      {children}
    </>
  )
}

export default PSILayout
