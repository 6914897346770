export const MIN_NUMBER_TO_SHOW_FIND_TRADESPEOPLE = 1

export const Tradespeople_URLs = {
  StackName: {
    label: 'Tradespeople',
    url: 'Tradespeople'
  },
  FindTradespeople: {
    label: 'FindTradespeople',
    url: 'FindTradespeople'
  }
}
