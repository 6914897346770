import pluralize from 'pluralize'
import { useEffect, useState } from 'react'
import api from 'venus/api'
import { ActionLayout, AlertBox } from 'venus/components'
import { getProperties } from 'venus/redux/features/property/properties'
import { getSubscription } from 'venus/redux/features/subscription/subscription'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { SettingsHeader } from '../SettingsHeader'
import ChoosePlan from './ChoosePlan'
import PlanDetail from './PlanDetail'
import PlanSelect from './PlanSelect'
import SelectProperty from './SelectProperty'

const PLAN_1 = {
  productId: {
    month: '1_vault_month',
    year: '1_vault_year',
  },
  sku: {
    month: 'price_1LrrsFFS9yrnVuppTYCElZKs',
    year: 'price_1LrrsCFS9yrnVupp1O0tR3nM',
  },
  number: 1,
  name: '1 Premium Vault Plan',
  description: 'A$9.99 per month, A$99.99 per year',
  price: {
    year: 99.99,
    month: 9.99,
  },
  key: '1-Vault',
  color: '#F68500',
  tag: 'Best Offer',
}

const PLAN_2 = {
  number: 2,
  productId: {
    month: '2_vault_month',
    year: '2_vault_year',
  },
  sku: {
    month: 'price_1Lrrs7FS9yrnVuppb7dPCTA2',
    year: 'price_1Lrrs0FS9yrnVuppGvOkjGE2',
  },
  name: '2 Premium Vault Plan',
  description: 'A$18.99 per month, A$194.99 per year',
  key: '2-Vault',
  color: '#49C3CA',
  tag: 'Best Offer',
  price: {
    year: 194.99,
    month: 18.99,
  },
}

export const MHV_PLANS = [PLAN_1, PLAN_2]
export const periods = {
  Annual: 'year',
  Monthly: 'month',
}

interface ISubscriptions {
  style?: any
  initialStep?: string
  upgrade?: number
}

const Subscriptions = ({ style, initialStep, upgrade }: ISubscriptions) => {
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(initialStep || 'ChoosePlan')
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(1)
  const [periodSelect, setPeriodSelect] = useState('Monthly')
  const [propertyIds, setPropertyIds] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const { properties } = useAppSelector((state) => state.properties)

  const { subscribed, unsubscribed, credit, productId, loading, type } = useAppSelector(
    (state) => state.subscription,
  )

  const plan = MHV_PLANS.find((p) => p.sku.month === productId || p.sku.year === productId)
  const { number, name } = plan || { number: 1 }
  const creditNumber = credit >= number ? Math.floor(credit / number) : 0

  useEffect(() => {
    if (productId && !initialStep) {
      setStep('PlanDetail')
    }
  }, [productId, initialStep])

  const selectedPlan = MHV_PLANS[selectedPlanIndex]

  useEffect(() => {
    const init = async () => {
      await dispatch(getProperties())
      await dispatch(getSubscription())
    }

    init()
  }, [])

  const handleSelectProperties = (ids: string[]) => {
    setPropertyIds(ids)
  }

  const createSession = async () => {
    const res = await api.post('/payment/session', {
      priceId: selectedPlan.sku[periods[periodSelect]],
      propertyIds: propertyIds.length
        ? propertyIds
        : properties.map((property) => property.id),
    })
    console.log('res', res)

    window.location.assign(res.data.url)
  }

  const updateSubscription = async () => {
    await api.put('/payment', {
      propertyIds: [...subscribed.map((sub) => sub.id), ...propertyIds],
    })
    dispatch(getSubscription())
    setStep('PlanDetail')
  }

  const onApplyCredit = async () => {
    setShowAlert(false)
    await api.post('/payment/credit', { credit: creditNumber })
    await dispatch(getSubscription())

    // setIsLoading(false)
  }

  const cancelSubscription = async () => {
    await api.post('/payment/cancel')
    await dispatch(getSubscription())
  }

  const handlePlanSelect = (index: number) => {
    setSelectedPlanIndex(index)
    setStep('PlanSelect')
  }

  const openSelectProperty = () => {
    setStep('SelectProperty')
  }

  const onAddProperty = () => {
    setStep('AddProperty')
  }

  const isDirectPurchase = selectedPlan.number >= properties.length

  let leftActions = []
  let rightActions = []

  if (step === 'PlanSelect') {
    rightActions = [
      {
        type: 'primary',
        text: isDirectPurchase ? 'Purchase' : 'Choose Properties',
        onClick: isDirectPurchase ? createSession : openSelectProperty,
      },
    ]
  }

  if (step === 'SelectProperty') {
    rightActions = [
      {
        isDisabled: !propertyIds.length,
        type: 'primary',
        text: 'Subscribe',
        onClick: createSession,
      },
    ]
  }

  if (step === 'AddProperty') {
    rightActions = [
      {
        type: 'primary',
        text: 'Add Property',
        onClick: updateSubscription,
      },
    ]
  }

  if (step === 'PlanDetail') {
    rightActions =
      type === 'STRIPE'
        ? [
            {
              type: creditNumber ? 'primary' : 'disabled',
              text: 'Apply Credit',
              onClick: () => setShowAlert(true),
            },
            {
              type: 'icon_button_tertiary',
              text: 'Change Plan',
              onClick: () => setStep('ChoosePlan'),
            },
          ]
        : []
  }

  let onBackBtnClick = null

  if (step === 'PlanSelect') {
    onBackBtnClick = () => setStep('ChoosePlan')
  }

  if (step === 'AddProperty') {
    onBackBtnClick = () => setStep('PlanDetail')
  }

  return (
    <ActionLayout
      style={{ flex: 1, ...style }}
      overFlowY={'hidden'}
      leftActions={leftActions}
      rightActions={rightActions}
    >
      <SettingsHeader
        style={{ overflow: 'initial' }}
        title={step === 'SelectProperty' ? 'Select Property' : 'Subscriptions'}
        onBackBtnClick={onBackBtnClick}
      />
      <div style={{ height: 20 }} />
      {/* <button onClick={createSession}>Test</button> */}
      {step === 'ChoosePlan' && (
        <ChoosePlan onSelect={handlePlanSelect} upgrade={upgrade} />
      )}
      {step === 'PlanSelect' && (
        <PlanSelect
          {...MHV_PLANS[selectedPlanIndex]}
          periodSelect={periodSelect}
          setPeriodSelect={setPeriodSelect}
        />
      )}
      {step === 'SelectProperty' && (
        <SelectProperty
          onSelect={handleSelectProperties}
          properties={properties}
          {...MHV_PLANS[selectedPlanIndex]}
        />
      )}

      {step === 'AddProperty' && (
        <SelectProperty
          onSelect={handleSelectProperties}
          properties={unsubscribed}
          {...MHV_PLANS[selectedPlanIndex]}
        />
      )}

      {step === 'PlanDetail' && (
        <PlanDetail
          type={type}
          cancelSubscription={cancelSubscription}
          onAddProperty={onAddProperty}
        />
      )}

      <AlertBox
        title='Apply Credit'
        message={`Are you sure to apply credits to extend\n "${name}" subscription for ${pluralize(
          'month',
          creditNumber,
          true,
        )}?`}
        cancel={() => setShowAlert(false)}
        btnGroup={[
          { type: 'primary', text: 'Apply Credit', onClick: onApplyCredit },
          { type: 'secondary', text: 'Cancel', onClick: () => setShowAlert(false) },
        ]}
        showAlert={showAlert}
      />
    </ActionLayout>
  )
}

export default Subscriptions
