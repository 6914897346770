import { useState } from 'react'
import { baseURL } from 'venus/api'
// import MHVPremium from 'mhv/scenes/Transfer/MHVPremium'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { createShareTo, getShareList } from 'venus/redux/features/share'
import { Notification } from 'venus/components'
import { SelectContact } from 'venus/pages/Contact/SelectContact'
import { ReactComponent as Email } from 'venus/assets/img/icons/Email.svg'
import { ReactComponent as Mobile } from 'venus/assets/img/icons/Mobile.svg'
// import useShowPremium from 'venus/hooks/useShowPremium'

interface IShareTo {
  onClose: () => void
  setStep: (s: number) => void
}

export const ShareTo = ({ onClose, setStep }: IShareTo) => {
  const dispatch = useAppDispatch()
  const {
    propertyId,
    shareScope,
    eventName,
    notes,
    isSharedAnonymously,
    isShareWholeProperty,
    isShareDocument,
    isShareHistory,
  } = useAppSelector((state) => state.share)
  const initialActions = [
    {
      type: 'secondary',
      text: 'Share via SMS',
      onClick: () => null,
      iconLeft: <Mobile style={{ marginRight: 3 }} />,
      isDisabled: true,
    },
    {
      type: 'secondary',
      text: 'Share via Email',
      onClick: () => null,
      iconLeft: <Email style={{ marginRight: 3 }} />,
      isDisabled: true,
    },
  ]
  const [actions, setActions] = useState([...initialActions])
  const [showAlertError, setShowAlertError] = useState(false)
  // const [showPremiumAlert, setShowPremiumAlert] = useState(false)
  const [fname, setFname] = useState('')
  const [id, setId] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  // const { vaults } = useAppSelector((state) => state.subscription)
  // const subscriptionStatus = vaults[propertyId]
  // console.log('subscriptionStatus::', subscriptionStatus)

  // const { onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )

  // const handleOnCancel = () => {
  //   setShowPremiumAlert(false)
  //   onCancel()
  //   navigation.goBack()
  // }

  // const handleViewPlan = () => {
  //   setShowPremiumAlert(false)
  //   onViewPlan()
  // }

  const handlePressShare = async (
    shareMethod: string,
    isDisable: boolean,
    shareTo: string
  ) => {
    if (isDisable) {
      setShowAlertError(true)
      return
    }
    // if (!subscriptionStatus || subscriptionStatus === FREE) {
    //   setShowPremiumAlert(true)
    //   return
    // }
    const data = await dispatch(
      createShareTo({
        propertyId,
        eventName,
        shareScope,
        isShareWholeProperty,
        isShareDocument,
        isShareHistory,
        notes,
        isSharedAnonymously,
        share: {
          shareTo,
          shareMethod,
        },
      })
    )
    if (data.payload) {
      const shareLinkWithBaseUrl = data.payload.replace(
        'https://undefined',
        baseURL
      )
      await navigator.clipboard.writeText(shareLinkWithBaseUrl)
    }

    dispatch(getShareList(propertyId))
    setActions([...initialActions])
    setShowNotification(true)
  }

  const handlPressAlertOK = () => {
    setShowNotification(false)
    onClose()
  }

  const handlPressAlertError = () => {
    setShowAlertError(false)
  }

  const setSelected = (person: any) => {
    const { id, firstName, email } = person
    setId(id)
    setFname(firstName)
    setActions([
      {
        type: 'secondary',
        text: 'Share via SMS',
        onClick: () => handlePressShare('SMS', false, id),
        iconLeft: <Mobile style={{ marginRight: 3 }} />,
        isDisabled: false,
      },
      {
        type: 'secondary',
        text: 'Share via Email',
        onClick: () => handlePressShare('EMAIL', !email, id),
        iconLeft: <Email style={{ marginRight: 3 }} />,
        isDisabled: false,
      },
    ])
  }

  const onBack = () => {
    setStep(2)
  }

  const handleClose = () => {
    onClose()
    setStep(1)
  }

  return (
    <>
      <SelectContact
        isOpen
        onClose={handleClose}
        onBack={onBack}
        handleSelect={setSelected}
        title='Share to'
        actions={actions}
        selectedId={id}
        type='WithOutHomeOwner'
      />
      <Notification
        message={`The property information has been shared with ${fname}.`}
        closeAlert={handlPressAlertOK}
        showAlert={showNotification}
      />
      <Notification
        message="This contact doesn't have an email."
        closeAlert={handlPressAlertError}
        showAlert={showAlertError}
      />
      {/* <MHVPremium
        isOpen={showPremiumAlert}
        onCancel={handleOnCancel}
        onViewPlan={handleViewPlan}
      /> */}
    </>
  )
}
