import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { ReactComponent as Back } from 'venus/assets/img/icons/Back.svg'
import { Text, Image, LinkButton } from 'venus/components'
import { theme } from 'venus/config'
import {
  ImageArea,
  ImageView,
  ImageIndexView,
  SingleButton,
  BtnArea,
  FooterBtnArea,
  BackIcon,
  ForwardIcon,
  Container,
  PreviousButtonHolder,
  NextButtonHolder,
} from 'venus/pages/Onboarding/AssignSpaceImages/AssignSpaceImages.styles'
import {
  bulkAddSpaceImages,
  setConfirmEditImageDone,
} from 'venus/redux/features/onboarding'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'

interface IEditImages {
  setStep: (s: number) => void
  setTitle: (s: string) => void
}

export const AssignSpaceImages = ({ setStep, setTitle }: IEditImages) => {
  const dispatch = useAppDispatch()
  const onboardImages = useAppSelector((state) => state.onboarding.homeImages)
  const { property, propertyId, spaces } = useAppSelector((state) => state.property)
  const options = [{ id: property?.propertyWideSpaceId, name: 'Property Wide' }, ...spaces]

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [originalImages, setOriginalImages] = useState([])
  const [assignedImages, setAssignedImages] = useState([])
  const [isLastImage, setIsLastImage] = useState(false)
  const [selectedSpaceId, setSelectedSpaceId] = useState(property?.propertyWideSpaceId)

  useEffect(() => {
    const structured = onboardImages.map((image) => {
      return {
        ...image,
        spaceId: '',
        clicked: false,
      }
    })
    setOriginalImages(structured)
  }, [])

  useEffect(() => {
    // check selection reach to the last image
    if (selectedIndex === originalImages?.length - 1) {
      setIsLastImage(true)
    } else {
      setIsLastImage(false)
    }
  }, [selectedIndex])

  const onClickPillButton = (targetSpace) => {
    setSelectedSpaceId(targetSpace?.id)
  }

  const processImages = async (imageList) => {
    // assign cover image by spaceId
    const spaceIdsHasCovers = []
    const finalList = imageList.map((image) => {
      if (spaceIdsHasCovers.indexOf(image?.spaceId) >= 0) {
        return image
      } else {
        spaceIdsHasCovers.push(image?.spaceId)
        return {
          ...image,
          isCoverImage: true,
        }
      }
    })
    await dispatch(
      bulkAddSpaceImages({
        propertyId,
        spaceImages: finalList,
      }),
    )
    dispatch(
      setConfirmEditImageDone({
        isConfirmEditImageDone: true,
      }),
    )
    setStep(6)
    setTitle('Scan Home')
  }

  const onClickArchive = async () => {
    // remove image from selection list
    const target = originalImages[selectedIndex]
    const filtered = originalImages.filter((image) => image?.url !== target?.url)

    // if no more image, end as done
    const checkNext = originalImages[selectedIndex + 1]
    if (!checkNext) {
      await processImages(assignedImages)
    } else {
      setOriginalImages(filtered)
    }
  }

  const onClickPrevious = () => {
    // go back to previous image
    const prevIndex = selectedIndex - 1
    if (prevIndex >= 0) {
      // remove last image when go back to previous
      const assigned = [...assignedImages]
      assigned.splice(-1)
      setAssignedImages(assigned)
      setSelectedIndex(prevIndex)
    }
  }

  const onClickDone = async () => {
    // assign last image to update list
    const lastIndex = originalImages?.length - 1
    const targetImage = {
      ...originalImages[lastIndex],
      spaceId: selectedSpaceId,
    }
    const finalImages = [...assignedImages]
    finalImages.push(targetImage)
    await processImages(finalImages)
  }

  const onClickNext = () => {
    // store image to update list and show next image
    const nextIndex = selectedIndex + 1
    if (nextIndex < originalImages?.length) {
      // assign spaceId to selected image except property wise space
      if (selectedSpaceId) {
        const targetImage = {
          ...originalImages[selectedIndex],
          spaceId: selectedSpaceId,
        }
        const assigned = [...assignedImages]
        assigned.push(targetImage)
        setAssignedImages(assigned)
      }
      // reset pill to property
      setSelectedSpaceId(property?.propertyWideSpaceId)
      // show next image
      setSelectedIndex(nextIndex)
    }
  }

  return (
    <>
      <Container>
        <ImageArea>
          <Text style={{ marginBottom: 24 }} format='body' value={'Categorise images'} />
          <ImageView>
            <Image
              image={_.get(originalImages, [selectedIndex, 'url'], '')}
              style={{ width: '380px', borderRadius: '10px', overflow: 'hidden' }}
            />
            <ImageIndexView>
              <Text
                format='body'
                value={`${selectedIndex + 1} of ${originalImages.length}`}
              />
            </ImageIndexView>
          </ImageView>
          <BtnArea>
            {options.map((option, index) => {
              const { name, id } = option
              return (
                <SingleButton
                  key={id}
                  onClick={() => {
                    onClickPillButton(option)
                  }}
                  style={{
                    backgroundColor:
                      selectedSpaceId === id
                        ? theme.colors.Primary
                        : theme.colors.LightBlue,
                  }}
                >
                  <Text
                    value={name}
                    format='h4'
                    fontWeight={selectedSpaceId === id ? 600 : 500}
                    color={
                      selectedSpaceId === id ? theme.colors.White : theme.colors.Primary
                    }
                  />
                </SingleButton>
              )
            })}
          </BtnArea>
        </ImageArea>
        <FooterBtnArea>
          <PreviousButtonHolder>
            {selectedIndex > 0 && (
              <LinkButton
                handlePress={onClickPrevious}
                label={'Previous'}
                format='body'
                color={theme.colors.Black}
                leftIcon={
                  <BackIcon>
                    <Back />
                  </BackIcon>
                }
              />
            )}
          </PreviousButtonHolder>
          <LinkButton
            handlePress={onClickArchive}
            label={'Archive Image'}
            format='body'
            color={theme.colors.ErrorState}
          />
          <NextButtonHolder>
            {selectedIndex <= originalImages?.length && (
              <LinkButton
                handlePress={
                  originalImages.length === 1 || isLastImage ? onClickDone : onClickNext
                }
                label={originalImages.length === 1 || isLastImage ? 'Done' : 'Next'}
                format='body'
                color={theme.colors.Black}
                rightIcon={
                  <ForwardIcon>
                    <Back />
                  </ForwardIcon>
                }
              />
            )}
          </NextButtonHolder>
        </FooterBtnArea>
      </Container>
    </>
  )
}
