import styled from 'styled-components'

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-right: 34px;
  margin-left: -34px;
  position: absolute;
  bottom: 0px;
  height: 78px;
  border-top: 1px solid #e8eaee;
  border-radius: 0 0 9px 9px;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 34px;
`

export const PreviewArea = styled.iframe`
  width: 100%;
  height: 220px;
  border: 1px solid;
  overflow-y: scroll;
`

export const EditDocsWrapper = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 100px;
`
