import styled from 'styled-components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'

const Container = styled.div`
  position: absolute;
  top: 18px;
  left: 20px;

  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.Secondary};
  padding: 5px 10px;
  padding-left: 12px;

  border-radius: 20px;
`

const ShareTag = ({ sharedBy }) => {
  return (
    <Container>
      <Text color={theme.colors.White} format='h4' fontSize={12} lineHeight={1.5}>
        Shared by {sharedBy}
      </Text>
    </Container>
  )
}

export default ShareTag
