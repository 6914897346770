import styled from 'styled-components'

export const BodyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  width: calc(100% - 48px);
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
`

export const ImageContent = styled.img`
  width: 100%;
  aspect-ratio: 1.3;
  border-radius: 5px;
`
