import styled from 'styled-components'
import { theme } from 'venus/config'

export const PersonalProfileContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 18px;
  margin: 28px 0;
`

export const PersonalProfilePanel = styled.div`
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: column;
`

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;

  button {
    &:hover {
      svg {
        color: white;
      }
    }
  }
`

export const VerifiedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 18px;
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const VerifiedInputWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 30px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.colors.SafeGreen};
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 24px 0;
`

export const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  margin-top: 38px;
`

export const VerifyButton = styled.div`
  cursor: pointer;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`
