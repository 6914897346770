import { theme } from "./theme"

export const defaultPillsTabConfig = {
  active: {
    color: theme.colors.Primary,
    background: theme.colors.LightBlue,
  },
  normal: {
    color: theme.colors.BlueGrey,
    background: "transparent",
    borderBottomColor: theme.colors.White,
  },
}

const defaultPillSelector = {
  paddingHorizontal: 15,
  paddingVertical: 5,
  fontSize: 12,
  fontWeight: "medium",
}

export const defaultPillsSelectorConfig = {
  active: {
    color: theme.colors.White,
    background: theme.colors.Primary,
    borderBottomColor: "transparent",
    ...defaultPillSelector,
  },
  normal: {
    color: theme.colors.Primary,
    background: theme.colors.LightBlue,
    borderBottomColor: "transparent",
    ...defaultPillSelector,
  },
}
