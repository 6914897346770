import { Text } from 'venus/components'
import { useAppSelector } from 'venus/redux/hooks'
import { Banner } from './PremiumBanner.styles'

const PremiumBanner = ({ isInProperty, selectedProperty, openPremium }: any) => {
  const { subscribed, unsubscribed, loading, vaults } = useAppSelector(
    (state) => state.subscription,
  )

  // const handleNavigate = () => {
  //   if (selectedProperty) {
  //     navigation.navigate(User_Profile_Setting_Menu_URLs.StackName.url, {
  //       screen: 'ChoosePlan',
  //       params: {
  //         number: subscribed.length + 1,
  //         properties: [...subscribed, selectedProperty],
  //       },
  //     })
  //   } else {
  //     navigation.navigate(User_Profile_Setting_Menu_URLs.StackName.url, {
  //       screen: 'ChoosePlan',
  //       params: {
  //         properties: [...unsubscribed],
  //       },
  //     })
  //   }
  // }

  const subscriptionStatus = vaults[selectedProperty?.id]

  const isPremiumProperty =
    isInProperty && selectedProperty && subscriptionStatus === 'paid'

  if (loading || (isInProperty && !selectedProperty) || isPremiumProperty) {
    return null
  }

  console.log('load', loading, subscriptionStatus)
  return (
    <Banner onClick={openPremium}>
      <Text
        format='h3'
        color='White'
        value='Upgrade to premium to unlock all Premium Features'
      />
    </Banner>
  )
}

export default PremiumBanner
