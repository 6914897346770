import api from 'venus/api'
import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Modal, PropertyCardView, Text, ActionLayout, Loader } from 'venus/components'
import {
  Container, TextWrapper
} from 'venus/layout/ProfileMenu/ProfileSettings/AcceptRequestOwnership/AcceptRequestOwnership.styles'
import {
  ACCEPT_REQUEST_OWNERSHIP_TITLE, CONFIRM_BTN_TEXT, DECLINE_BTN_TEXT, ERROR_MSG_TITLE
} from 'venus/layout/ProfileMenu/ProfileSettings/AcceptRequestOwnership/Constants'
import { setErrorTitle } from 'venus/redux/features/error/reducer'
import { useAppDispatch } from 'venus/redux/hooks'
import { formatFullAddress } from 'venus/utils'
import { getFullName } from 'venus/utils/fullName'

const AcceptRequestOwnership = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const [isLoading, setIsLoading] = useState(false)
  const [requestProperty, setRequestProperty] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/transfer/request/${id}`).then((res) => res.data)
        setRequestProperty(response)
      } catch (error) {
        return error
      }
    })()
  }, [])

  const [isOpen, setIsOpen] = useState(true)

  const handleOnClickDecline = async () => {
    try {
      setIsLoading(true)
      await api.delete(`/transfer/request/${id}`)
      setIsLoading(false)
    } catch (error) {
      dispatch(setErrorTitle(ERROR_MSG_TITLE))
      return error
    } finally {
      setIsOpen(false)
      navigate('/', { replace: true })
    }
  }
  const handleOnClickConfirm = async () => {
    if (!requestProperty?.propertyId) return

    try {
      setIsLoading(true)
      await api.put(`/transfer/request/${id}`, {
        status: 'DONE',
        propertyId: requestProperty.propertyId
      })
      await api.post('/transfer', {
        name: getFullName(requestProperty.user),
        email: requestProperty.user.email,
        propertyId: requestProperty.propertyId
      })
      setIsLoading(false)
    } catch (error) {
      dispatch(setErrorTitle(ERROR_MSG_TITLE))
      return error
    } finally {
      setIsOpen(false)
      navigate('/', { replace: true })
    }
  }

  if (isLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull/>
      </div>
    )
  }
  if (!requestProperty) {
    return <></>
  }
  return (
    <>
      <Modal
        isOpen={isOpen}
        title={ACCEPT_REQUEST_OWNERSHIP_TITLE}
        showCloseButton={false}
        width={495}
        height={578}
      >
        <ActionLayout
          margin={42}
          children={
            <Container>
              <PropertyCardView
                property={requestProperty}
                imageHeight={240}
                isSelected={true}
                showPropertyData={true}
                addressFontSize={14}
                propertyDataFontSize={14}
              />
              <TextWrapper>
                <Text format={'h3'} value={`${getFullName(requestProperty?.user)}`}/>
                <Text format={'h4'} value={` has requested ownership for `}/>
                <Text format={'h3'} value={`${formatFullAddress(requestProperty?.address)}.`}/>
                <Text format={'h4'}
                      value={` By accepting this request you will loose access to your existing property vault.`}/>
              </TextWrapper>
            </Container>
          }
          rightActions={[
            {
              type: 'icon_button_error',
              text: DECLINE_BTN_TEXT,
              // color: 'LightBlue',
              // textColor: 'Primary',
              onClick: handleOnClickDecline,
            },
            {
              type: 'primary',
              text: CONFIRM_BTN_TEXT,
              onClick: handleOnClickConfirm
            }
          ]}
        />
      </Modal>
    </>
  )
}

export default AcceptRequestOwnership