import React, { useState } from 'react'
import api from 'venus/api'
import { ReactComponent as SuccessIcon } from 'venus/assets/img/icons/Success.svg'
import {
  Text,
  Input,
  Loader,
  ActionLayout,
  PropertyCardView,
  AutoComplete,
  PropertyListCardView,
} from 'venus/components'
import {
  CompleteViewContainer,
  IconWrapper,
  ConfirmOwnerPropertyContainer,
} from 'venus/layout/ProfileMenu/ProfileSettings/ClaimExistingVault/ClaimExistingValut.styles'
import {
  CLAIM_ADDRESS_TEXT,
  SELECT_ADDRESS_BTN_TEXT,
  ClaimExistingVaultStep,
  OWNER_NAME_INPUT_DESCRIPTION,
  OWNER_NAME_INPUT_LABEL,
  CONFIRM_OWNER_TEXT,
  CONFIRM_OWNER_NAME_BTN_TEXT,
  COMPLETE_MESSAGE_TITLE,
  COMPLETE_MESSAGE,
  ERROR_MSG_TITLE,
} from 'venus/layout/ProfileMenu/ProfileSettings/ClaimExistingVault/Constants'
import { SettingsHeader } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader'
import { IAddressOption, previewAddressData } from 'venus/redux/features/address'
import { setErrorTitle, setErrorMessage } from 'venus/redux/features/error/reducer'
import { useAppDispatch } from 'venus/redux/hooks'

const ClaimExistingVault = () => {
  const dispatch = useAppDispatch()
  const [foundProperty, setFoundProperty] = useState(null)
  const [step, setStep] = useState(ClaimExistingVaultStep.SELECT_ADDRESS)
  const [address, setAddress] = React.useState('')
  const [addressId, setAddressId] = useState(-1)
  const [googlePlaceId, setGooglePlaceId] = useState('')
  const [options, setOptions] = useState<IAddressOption[]>([])
  const [ownerName, setOwnerName] = useState('')
  const [loading, setLoading] = useState(false)

  const onSearch = async (value: string) => {
    if (!value) return resetFields()
    try {
      const response = await api.get(`/address`, {
        params: {
          query: value,
          size: 10,
        },
      })
      setOptions(response.data)
    } catch (error) {
      return error
    }
  }

  const resetFields = () => {
    setAddress('')
    setAddressId(-1)
    setGooglePlaceId('')
  }

  const handleOnChangeAddress = (value: string, label: string) => {
    resetFields()

    if (!value) {
      return
    }

    const obj = JSON.parse(value)
    const { addressId, googlePlaceId } = obj
    setAddressId(addressId)
    setGooglePlaceId(googlePlaceId)
    setAddress(label)
  }

  const formatPropertyPreviewData = (foundProperty) => {
    // set data by NPD or google property
    if (googlePlaceId) {
      return {
        coverImage: foundProperty?.image,
        address: {
          ...foundProperty?.address,
        },
      }
    } else {
      return {
        coverImage: `${foundProperty?.imageBaseUrl}.jpg`,
        address: {
          address: foundProperty?.address,
          suburb: foundProperty?.suburb,
          state: foundProperty?.state,
          postcode: foundProperty?.postcode,
        },
        propertyData: {
          ...foundProperty,
        },
      }
    }
  }

  const handleClickBtn = async () => {
    switch (step) {
      // 1. search address and get preview data
      case ClaimExistingVaultStep.SELECT_ADDRESS:
        const foundAddress = await dispatch(
          previewAddressData({ address, googlePlaceId, addressId }),
        )
        let searchResult
        if (foundAddress?.payload.length > 0) {
          searchResult = foundAddress.payload[0]
        } else {
          searchResult = foundAddress.payload
        }
        setFoundProperty(formatPropertyPreviewData(searchResult))
        setStep(ClaimExistingVaultStep.ENTER_OWNER_NAME)
        break
      // 2. show preview and confirm address
      case ClaimExistingVaultStep.ENTER_OWNER_NAME:
        setStep(ClaimExistingVaultStep.CONFIRM_OWNER_NAME)
        break
      // 3. get owner name and send claim request
      case ClaimExistingVaultStep.CONFIRM_OWNER_NAME:
        try {
          setLoading(true)
          const result = await api.post('/transfer/request', {
            ownerName: ownerName.trim(),
            propertyKey: foundProperty?.propertyData?.propertyKey,
            googlePlaceId,
          })
          if (result?.status === 200) {
            setStep(ClaimExistingVaultStep.COMPLETE)
          }
        } catch (error) {
          dispatch(setErrorTitle(ERROR_MSG_TITLE))
          dispatch(setErrorMessage(error?.data))
        } finally {
          setLoading(false)
        }
        break
      default:
        break
    }
  }

  const searchAddressView = (
    <>
      <ActionLayout
        margin={28}
        children={
          <>
            <SettingsHeader title={'Claim Existing Vault'} />
            <Text
              format={'h4'}
              value={CLAIM_ADDRESS_TEXT}
              fontSize={12}
              style={{ marginBottom: '18px', marginTop: '28px' }}
            />
            <AutoComplete
              isSelectedOnly
              placeholder='Enter home address'
              label=''
              value={address}
              onChange={handleOnChangeAddress}
              onSearch={onSearch}
              options={options.map(({ address, addressId, googlePlaceId }) => ({
                label: address,
                value: JSON.stringify({
                  addressId: addressId,
                  googlePlaceId,
                }),
              }))}
            />
          </>
        }
        rightActions={[
          {
            type: 'primary',
            isDisabled: !address,
            text: SELECT_ADDRESS_BTN_TEXT,
            onClick: handleClickBtn,
          },
        ]}
      />
    </>
  )

  const confirmAddressView = foundProperty && (
    <>
      <ActionLayout
        margin={28}
        children={
          <>
            <SettingsHeader
              title={'Claim Existing Vault'}
              onBackBtnClick={() => {
                setOwnerName('')
                setStep(ClaimExistingVaultStep.SELECT_ADDRESS)
              }}
            />
            <PropertyCardView
              property={foundProperty}
              imageHeight={240}
              isSelected={true}
              showPropertyData={true}
              style={{ marginTop: '28px' }}
            />
            <Input
              type='text'
              label={OWNER_NAME_INPUT_LABEL}
              placeholder={''}
              value={ownerName}
              onChange={setOwnerName}
              style={{ marginTop: '24px' }}
              isError={!!ownerName.length && !ownerName.trim()}
              errorMsg={'This is required.'}
            />
            <Text
              format={'body'}
              value={OWNER_NAME_INPUT_DESCRIPTION}
              style={{ marginTop: '18px' }}
            />
          </>
        }
        rightActions={[
          {
            type: 'primary',
            isDisabled: !ownerName.trim(),
            onClick: handleClickBtn,
            text: CONFIRM_OWNER_NAME_BTN_TEXT,
          },
        ]}
      />
    </>
  )

  const confirmOwnerNameView = (
    <>
      <ActionLayout
        margin={28}
        children={
          <>
            <SettingsHeader
              title={'Claim Existing Vault'}
              onBackBtnClick={() => setStep(ClaimExistingVaultStep.ENTER_OWNER_NAME)}
            />
            <ConfirmOwnerPropertyContainer>
              <PropertyListCardView
                imageWidth={120}
                imageHeight={76}
                property={foundProperty}
                isSelectable={false}
                showPropertyData={false}
              />
              <Text
                format={'h3'}
                value={OWNER_NAME_INPUT_LABEL}
                style={{ padding: '10px' }}
              />
              <Text format={'h4'} value={ownerName} style={{ padding: '10px' }} />
            </ConfirmOwnerPropertyContainer>
            <Text
              format={'body'}
              value={CONFIRM_OWNER_TEXT}
              style={{ marginTop: '24px' }}
            />
          </>
        }
        rightActions={[
          {
            text: 'Cancel',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: () => {
              setStep(ClaimExistingVaultStep.ENTER_OWNER_NAME)
            },
            type: 'secondary',
          },
          {
            type: 'primary',
            isDisabled: !ownerName.trim(),
            onClick: handleClickBtn,
            text: CONFIRM_OWNER_NAME_BTN_TEXT,
          },
        ]}
      />
    </>
  )
  const completeView = (
    <CompleteViewContainer>
      <IconWrapper>
        <SuccessIcon style={{ marginTop: '38px' }} />
      </IconWrapper>
      <Text format={'h2'} fontSize={20} value={COMPLETE_MESSAGE_TITLE} />
      <Text
        format={'h4'}
        value={COMPLETE_MESSAGE}
        style={{ textAlign: 'center', marginTop: '24px' }}
      />
    </CompleteViewContainer>
  )

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }
  switch (step) {
    case ClaimExistingVaultStep.SELECT_ADDRESS:
      return searchAddressView
    case ClaimExistingVaultStep.ENTER_OWNER_NAME:
      return confirmAddressView
    case ClaimExistingVaultStep.CONFIRM_OWNER_NAME:
      return confirmOwnerNameView
    case ClaimExistingVaultStep.COMPLETE:
      return completeView
    default:
      return searchAddressView
  }
}

export default ClaimExistingVault
