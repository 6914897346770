import { theme } from 'venus/config'
import { Text } from 'venus/components'
import { PillContainer } from './Pills.styles'
import { TFontWeight } from '../Text/Text'

export interface IPillTagConfig {
  style?: any
  color?: string
  icon?: string
  format?: 'h1' | 'h2' | 'h3' | 'h4' | 'body'
  background?: string
  paddingHorizontal?: number
  paddingVertical?: number
  fontWeight?: TFontWeight
  fontSize?: number
  lineHeight?: string | number
}

interface IPillTag extends IPillTagConfig {
  label: string | React.ReactNode
  onClick?: (event: any) => void
}

const PillTag = ({
  style = {},
  label,
  format = 'h3',
  fontWeight,
  fontSize,
  lineHeight,
  background = theme.colors.Primary,
  color = theme.colors.White,
  paddingHorizontal = 10,
  paddingVertical = 3,
  onClick,
}: IPillTag) => {
  return (
    <PillContainer
      onClick={onClick}
      style={style}
      paddingHorizontal={paddingHorizontal}
      paddingVertical={paddingVertical}
      background={background}
    >
      {typeof label === 'string' || typeof label === 'number' ? (
        <Text
          value={label}
          color={color}
          format={format}
          fontSize={fontSize}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
          style={{ whiteSpace: 'nowrap' }}
        />
      ) : (
        label
      )}
    </PillContainer>
  )
}

export default PillTag
