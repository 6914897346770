import styled from 'styled-components'
import { theme } from 'venus/config'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: auto;
`

type SectionProps = {
  readonly flex: number
}
export const Section = styled.section<SectionProps>`
  flex: ${(props) => props.flex};
  margin: 8px 0;
`
export const PlaceHolder = styled.div`
  width: 28px;
  height: 100%;
`

export const Panel = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.LighterGrey};
`

export const AddressSection = styled.div`
  /* padding: 16px 20px; */

  margin: 24px 0;
`

export const DreamPriceSection = styled.div`
  padding: 32px;
`

export const DreamPriceHeader = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`

export const CoverImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`

export const Line = styled.div`
  margin-bottom: 16px;
`

export const DisplayBoard = styled.div`
  width: 46%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const EditIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #e8eaee;
  margin-left: 12px;
`

export const AddIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
`
