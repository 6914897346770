import { useDropzone } from 'react-dropzone'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { DropContainer } from './Dropzone.styles'
import { ReactComponent as UploadIcon } from 'venus/assets/img/icons/Upload.svg'
import { useEffect } from 'react'

const Dropzone = ({ uploadDocs }) => {
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept } =
    useDropzone({
      accept: {
        'image/*': [],
        'text/*': ['.txt', '.csv'],
        'application/pdf': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.ms-powerpoint': [],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
        'application/vnd.google-apps.document': [],
        'application/vnd.google-apps.file': [],
        'application/vnd.google-apps.folder': [],
        'application/vnd.google-apps.photo': [],
        'application/vnd.google-apps.presentation': [],
        'application/vnd.google-apps.spreadsheet': [],
      },
    })

  useEffect(() => {
    if (acceptedFiles.length) {
      uploadDocs(acceptedFiles)
    }
  }, [acceptedFiles])

  return (
    <DropContainer {...getRootProps({ isFocused, isDragAccept })}>
      <UploadIcon width={47} height={47} color={theme.colors.BlueGrey} />
      <Text style={{ marginTop: 37 }} format='body' color={theme.colors.DarkestGrey}>
        Drag and drop or browse your files
      </Text>
    </DropContainer>
  )
}

export default Dropzone
