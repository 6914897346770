import { useState } from 'react'
import ReactModal from 'react-modal'
import { Chevron, Text, Button } from 'venus/components'
import {
  displayDaysInMonth,
  formateDate,
  formateDateWithYYYYMMDD,
} from 'venus/utils'
import { theme, Months, Years, weekDayStartsOnSunday } from 'venus/config'
import { ReactComponent as Triangle } from 'venus/assets/img/icons/Triangle.svg'
import { ReactComponent as Calendar } from 'venus/assets/img/icons/Calendar.svg'
import {
  CalendarView,
  StyledDateTitle,
  StyledDate,
  StyledBlankGrid,
  StyledWrapper,
  CalendarContent,
  ButtonArea,
  Wrapper,
  TitleArea,
  StyledDateWrapper,
  InputWrapper,
  CalendarBackground,
  ContentArea,
  ButtonText,
  WeekdayWrapper,
  DaysWrapper,
  ChevronWrapper,
} from './DatePicker.styles'

interface IDatePicker {
  onChange: Function
  value?: string
  disableFutureDate?: boolean
  disablePastDate?: boolean
  label?: string
  isError?: boolean
  errorMsg?: string
  style?: any
  isDisabled?: boolean
}

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 11,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '9px',
    border: 0,
    padding: 0,
    overflow: '',
  },
}

export const DatePicker = ({
  onChange,
  value,
  disableFutureDate = false,
  disablePastDate = false,
  label = 'Select Date',
  isError = false,
  errorMsg,
  style = {},
  isDisabled = false,
}: IDatePicker) => {
  const [thisDate, thisMonth, thisYear] = !!value
    ? value.split('/')
    : formateDate('').split('/')
  const [type, setType] = useState<'date' | 'month' | 'year'>('date')
  const [selectedYear, setSelectedYear] = useState(thisYear)
  const [titleYear, setTitleYear] = useState(thisYear)
  const [selectedMonth, setSelectedMonth] = useState(Number(thisMonth) - 1)
  const [titleMonth, setTitleMonth] = useState(Number(thisMonth) - 1)
  const [selectedDate, setSelectedDate] = useState(thisDate)
  const daysInMonth = displayDaysInMonth(Number(titleYear), Number(titleMonth))
  const [showPopup, setShowPopup] = useState(false)
  const yearsArr = Years()
  const yearsToDisplay = 24
  const [yearPageIndex, setYearPageIndex] = useState(
    Math.floor(yearsArr.indexOf(Number(titleYear)) / yearsToDisplay)
  )
  const yearsPage = yearsArr.slice(
    yearPageIndex * yearsToDisplay,
    yearPageIndex * yearsToDisplay + yearsToDisplay
  )

  const handlePress = () => {
    setShowPopup(true)
  }

  const handleCloseModal = () => {
    setShowPopup(false)
    setType('date')
  }

  const handlePressDate = (d: any, isGreyedOut: boolean) => {
    if (isGreyedOut) return
    const selected = new Date(d.d)
    const year = selected.getFullYear()
    const month = selected.getMonth()
    const date = d.date
    setSelectedYear(String(year))
    setTitleYear(String(year))
    setSelectedMonth(month)
    setTitleMonth(month)
    setSelectedDate(date)
    const ddMMyyyy = formateDateWithYYYYMMDD(year, month, date)
    onChange(ddMMyyyy)
    setShowPopup(false)
  }

  const handleClickYear = (d: number) => {
    setSelectedYear(String(d))
    setTitleYear(String(d))
    setType('date')
  }

  const handleClickMonth = (d: number) => {
    setSelectedMonth(d)
    setTitleMonth(d)
    setType('date')
  }

  const handleClickLeftChevron = () => {
    if (type === 'date') {
      if (titleMonth - 1 >= 0) {
        setTitleMonth(titleMonth - 1)
      } else {
        setTitleMonth(11)
        setTitleYear(String(Number(titleYear) - 1))
      }
    }
    if (type === 'year') {
      setYearPageIndex(Math.max(0, yearPageIndex - 1))
    }
  }

  const handleClickRightChevron = () => {
    if (type === 'date') {
      if (titleMonth + 1 < 12) {
        setTitleMonth(titleMonth + 1)
      } else {
        setTitleMonth(0)
        setTitleYear(String(Number(titleYear) + 1))
      }
    }
    if (type === 'year') {
      setYearPageIndex(Math.min(3, yearPageIndex + 1))
    }
  }

  if (isDisabled)
    return (
      <Wrapper style={style}>
        <StyledWrapper>
          <Text
            value={label}
            format='h3'
            lineHeight={'21px'}
            style={{
              textAlign: 'left',
              marginBottom: '6px',
              display: 'inline-block',
            }}
          />
          <Button
            isDisabled
            onClick={handlePress}
            type='dropdown'
            text={value || 'DD/MM/YYYY'}
            style={{
              height: '44px',
              justifyContent: 'flex-start',
              padding: '12px 20px',
              border: `0.5px solid ${
                isError ? theme.colors.DangerRed : '#D8D8D8'
              }`,
              borderRadius: 4,
            }}
          />
          <InputWrapper>
            <Calendar />
          </InputWrapper>
        </StyledWrapper>
      </Wrapper>
    )

  return (
    <Wrapper style={style}>
      <StyledWrapper>
        <Text
          value={label}
          format='h3'
          lineHeight={'21px'}
          style={{
            textAlign: 'left',
            marginBottom: '6px',
            display: 'inline-block',
          }}
        />
        <Button
          onClick={handlePress}
          type='dropdown'
          text={value || 'DD/MM/YYYY'}
          style={{
            height: '44px',
            justifyContent: 'flex-start',
            padding: '12px 20px',
            border: `0.5px solid ${
              isError ? theme.colors.DangerRed : '#D8D8D8'
            }`,
            borderRadius: 4,
          }}
        />
        <InputWrapper onClick={handlePress}>
          <Calendar />
        </InputWrapper>
        {isError && (
          <Text
            value={String(errorMsg)}
            format='h4'
            lineHeight='21px'
            color={theme.colors.DangerRed}
            style={{ marginTop: 6 }}
          />
        )}
      </StyledWrapper>
      <ReactModal
        isOpen={showPopup}
        onRequestClose={handleCloseModal}
        style={modalStyles as any}
      >
        <CalendarView>
          <CalendarBackground>
            <CalendarContent>
              {type !== 'month' && (
                <TitleArea>
                  <ChevronWrapper onClick={handleClickLeftChevron}>
                    <Chevron
                      direction='left'
                      strokeWidth={1}
                      color={theme.colors.Black}
                    />
                  </ChevronWrapper>
                  {type === 'date' && (
                    <ButtonArea>
                      <ButtonText onClick={() => setType('month')}>
                        <Text
                          value={Months[titleMonth]}
                          style={{ marginRight: '6px' }}
                          format='h4'
                          fontSize={16}
                        />
                      </ButtonText>
                      <ButtonText onClick={() => setType('year')}>
                        <Text value={titleYear} format='h4' fontSize={16} />
                      </ButtonText>
                      <Triangle
                        onClick={() => setType('year')}
                        style={{
                          color: theme.colors.Black,
                          marginLeft: '12px',
                          cursor: 'pointer',
                        }}
                      />
                    </ButtonArea>
                  )}
                  {type === 'year' && (
                    <ButtonArea>
                      <Text
                        value={`${yearsPage[0]} - ${yearsPage.slice(-1)[0]}`}
                        format='h4'
                        fontSize={16}
                      />
                      <Triangle
                        style={{
                          color: theme.colors.Black,
                          marginLeft: '12px',
                          cursor: 'pointer',
                        }}
                      />
                    </ButtonArea>
                  )}
                  <ChevronWrapper onClick={handleClickRightChevron}>
                    <Chevron
                      direction='right'
                      strokeWidth={1}
                      color={theme.colors.Black}
                    />
                  </ChevronWrapper>
                </TitleArea>
              )}
              {type === 'date' && (
                <ContentArea>
                  <WeekdayWrapper>
                    {weekDayStartsOnSunday.map((d, i) => {
                      return (
                        <StyledDateWrapper key={`day-letter-${i}`}>
                          <StyledDateTitle>
                            <Text
                              value={`${d[0]}  `}
                              format='h2'
                              fontWeight={400}
                            />
                          </StyledDateTitle>
                        </StyledDateWrapper>
                      )
                    })}
                  </WeekdayWrapper>
                  <DaysWrapper>
                    {daysInMonth.map((d, i) => {
                      const isFutherDate = !!d.d
                        ? d.d.toISOString().slice(0, 10) >=
                          new Date().toISOString().slice(0, 10)
                        : true
                      const isToday =
                        !!d.d &&
                        new Date(d.d).toDateString() ===
                          String(new Date()).slice(0, 15)
                      const isSelecte =
                        !!d.d &&
                        new Date(d.d).toDateString() ===
                          String(
                            new Date(
                              Number(selectedYear),
                              selectedMonth,
                              Number(selectedDate)
                            )
                          ).slice(0, 15)
                      const isGreyedOut =
                        (isFutherDate && disableFutureDate) ||
                        (!isFutherDate && !isToday && disablePastDate)
                      return d.date ? (
                        <StyledDateWrapper key={`days-${i}`}>
                          <StyledDate
                            onClick={() => handlePressDate(d, isGreyedOut)}
                            style={{
                              border: `${
                                isToday || isSelecte ? 1 : 0
                              }px solid ${theme.colors.LightGreen}`,
                              backgroundColor: isSelecte
                                ? theme.colors.LightGreen
                                : 'transparent',
                              cursor: isGreyedOut ? 'default' : 'pointer',
                            }}
                          >
                            <Text
                              value={`${d.date}  `}
                              format='h2'
                              fontWeight={400}
                              color={
                                isGreyedOut
                                  ? theme.colors.Grey
                                  : theme.colors.Black
                              }
                            />
                          </StyledDate>
                        </StyledDateWrapper>
                      ) : (
                        <StyledDateWrapper key={`days-${i}`}>
                          <StyledBlankGrid />
                        </StyledDateWrapper>
                      )
                    })}
                  </DaysWrapper>
                </ContentArea>
              )}
              {type === 'month' && (
                <ContentArea>
                  {Months.map((d, i) => {
                    return (
                      <StyledDateWrapper
                        key={`day-letter-${i}`}
                        style={{
                          borderRadius: 8,
                          width: '21%',
                          cursor: 'pointer',
                          border: `${
                            i === selectedMonth || i === new Date().getMonth()
                              ? 1
                              : 0
                          }px solid ${theme.colors.LightGreen}`,
                          backgroundColor:
                            i === selectedMonth
                              ? theme.colors.LightGreen
                              : 'transparent',
                        }}
                        onClick={() => handleClickMonth(i)}
                      >
                        <StyledDateTitle>
                          <Text
                            value={d.slice(0, 3)}
                            format='h2'
                            fontWeight={400}
                          />
                        </StyledDateTitle>
                      </StyledDateWrapper>
                    )
                  })}
                </ContentArea>
              )}
              {type === 'year' && (
                <ContentArea>
                  {yearsPage.map((d, i) => {
                    return (
                      <StyledDateWrapper
                        key={`day-letter-${i}`}
                        style={{
                          borderRadius: 8,
                          width: '21%',
                          cursor: 'pointer',
                          border: `${
                            d === Number(selectedYear) ||
                            d === new Date().getFullYear()
                              ? 1
                              : 0
                          }px solid ${theme.colors.LightGreen}`,
                          backgroundColor:
                            d === Number(selectedYear)
                              ? theme.colors.LightGreen
                              : 'transparent',
                        }}
                        onClick={() => handleClickYear(d)}
                      >
                        <StyledDateTitle>
                          <Text
                            value={String(d)}
                            format='h2'
                            fontWeight={400}
                          />
                        </StyledDateTitle>
                      </StyledDateWrapper>
                    )
                  })}
                </ContentArea>
              )}
            </CalendarContent>
          </CalendarBackground>
        </CalendarView>
      </ReactModal>
      {/* </Modal> */}
    </Wrapper>
  )
}
