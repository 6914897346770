import styled from 'styled-components'

export const ToggleBtnArea = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 24px 0 24px;
  width: 100%;
  position: absolute;
  bottom: 12px;
  border-top: 1px solid #e8eaee;
`
