import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  padding: 4px 0;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.White};

  cursor: pointer;
`

export const ProfileMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  margin-top: 4px;
`

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 7px;
  margin-right: 10px;

  width: 32px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Grey};
`

export const ProfileContainer = styled.div`
  height: 32px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.White};
  display: flex;
  align-items: center;
  padding-right: 12px;
`

export const ProfileMenuDropback = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const ContentsWrapper = styled.div`
  height: 640px;
`
