import { useEffect, useState, useRef } from 'react'
import { Add_Dream_Property_Type } from 'venus/config'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { AddProperty } from 'venus/common/AddProperty/AddProperty'
import { getDreamPropertyList } from 'venus/redux/features/dreamprice'
import { Loader, Button, EmptyData, AlertBox } from 'venus/components'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { Preview } from './Preview/Preview'
import { ListWrapper, IconArea } from './List.styles'
import usePremiumContext from 'venus/context/MHVPremium'

export interface IProperty {
  id: string
  address: {
    address: string
  }
  coverImage?: string
}

export const DreamProperties = ({ setTabButtons }) => {
  const { loading, list } = useAppSelector((state) => state.dreamprice)
  const dispatch = useAppDispatch()
  const [isAddPropertyOpen, setIsAddPropertyOpen] = useState(false)
  const { subscribed } = useAppSelector((state) => state.subscription)

  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showMaximum, setShowMaximum] = useState(false)

  const { openPremium } = usePremiumContext()

  useEffect(() => {
    dispatch(getDreamPropertyList())
  }, [])

  useEffect(() => {
    if (!loading)
      setTabButtons(
        <Button
          type='icon_button'
          text='Add Dream Property'
          onClick={() => {
            if (list.length > 2 && !subscribed.length) {
              setShowUpgrade(true)
              return
            } else if (list.length > 9 && subscribed.length) {
              setShowMaximum(true)
              return
            } else {
              setIsAddPropertyOpen(true)
            }
          }}
          iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
          style={{ width: 'auto' }}
        />,
      )

    return () => setTabButtons(<></>)
  }, [loading, list, subscribed])

  const handleCloseAddProperty = () => {
    setIsAddPropertyOpen(false)
  }

  const handlePressPlus = () => {
    if (list.length > 2 && !subscribed.length) {
      setShowUpgrade(true)
      return
    } else if (list.length > 9 && subscribed.length) {
      setShowMaximum(true)
      return
    } else {
      setIsAddPropertyOpen(true)
    }
  }

  const handleNavigate = () => {
    setShowUpgrade(false)
    openPremium()
  }

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  return (
    <>
      {list.length === 0 && (
        <div style={{ marginTop: '110px' }}>
          <EmptyData
            message={`Click the plus button to add dream\nproperties to your Vault.`}
          />
        </div>
      )}
      {list.length !== 0 && (
        <ListWrapper>
          {list.map((property) => (
            <Preview key={property.id} {...property} />
          ))}
        </ListWrapper>
      )}

      <AddProperty
        isOpen={isAddPropertyOpen}
        onClose={handleCloseAddProperty}
        onFinish={handleCloseAddProperty}
        addType={Add_Dream_Property_Type}
      />

      <AlertBox
        title='Dream Property'
        message='You have reached the maximum number of dream property for free user. Do you want to upgrade to premium plan to unlock more dream properties?'
        btnGroup={[
          { text: 'Upgrade', onClick: handleNavigate, type: 'primary' },
          {
            text: 'Cancel',
            onClick: () => setShowUpgrade(false),
            type: 'secondary',
          },
        ]}
        showAlert={showUpgrade}
        cancel={() => setShowUpgrade(false)}
      />

      <AlertBox
        title='Dream Property'
        message='You have reached the maximum number of dream properties.'
        btnGroup={[{ text: 'Ok', onClick: () => setShowMaximum(false), type: 'primary' }]}
        showAlert={showMaximum}
        cancel={() => setShowMaximum(false)}
      />
    </>
  )
}

export default DreamProperties
