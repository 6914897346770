import { theme } from 'venus/config'
import { formatPrice } from 'venus/utils/number'
import React from 'react'

import { IStatistics } from './Statistics.t'
import {
  Container,
  Legend,
  Section,
  LengendSection,
  DisplayWrapper,
} from './StatisticsLegend.styles'
import { Text } from 'venus/components'
import _ from 'lodash'

const DataDisplay = ({ title, content }: { title: string; content: string }) => (
  <DisplayWrapper>
    <Text format='h3' color={theme.colors.Black} style={{ marginBottom: 6 }}>
      {title}
    </Text>
    <Text format='body' color={theme.colors.DarkGrey}>
      {content}
    </Text>
  </DisplayWrapper>
)

const getPercentageString = (x: number, y: number) => {
  const percent = Math.round((x / y) * 1000) / 10
  return `(${percent}%)`
}

const StatisticsLegend = ({ data }: { data: IStatistics }) => {
  const suburb = _.get(data, 'suburbStatistics[0]')
  const lga = _.get(data, 'lgaStatistics[0]')

  if (!suburb || !lga) {
    return null
  }

  // const populationPercentage =
  //   Math.round((suburb.suburbPopulation / lga.lgaPopulation) * 1000) / 10
  // const medianPercentage =

  return (
    <Container>
      <Section>
        <LengendSection>
          <Legend color={theme.colors.Primary} />
          <Text format='body' color={theme.colors.Primary}>
            {data.suburbStatistics[0].suburb}
          </Text>
          {/* <Text
            text=
            fontSize={12}
            color={theme.colors.Primary}
            fontWeight="semiBold"
          /> */}
        </LengendSection>
        <DataDisplay
          title='Population'
          content={`${formatPrice(suburb.suburbPopulation)} ${getPercentageString(
            suburb.suburbPopulation,
            lga.lgaPopulation,
          )}`}
        />
        <DataDisplay
          title='YTD Median'
          content={`$${formatPrice(suburb.suburbMedianPrice)} ${getPercentageString(
            suburb.suburbMedianPrice,
            lga.lgaMedianPrice,
          )}`}
        />
        <DataDisplay
          title='YTD Sales'
          content={`${formatPrice(suburb.suburbSaleCount)} ${getPercentageString(
            suburb.suburbSaleCount,
            lga.lgaSaleCount,
          )}`}
        />
        <DataDisplay
          title='No of Dwellings'
          content={`${formatPrice(suburb.suburbDwellings)} ${getPercentageString(
            suburb.suburbDwellings,
            lga.lgaDwellings,
          )}`}
        />
      </Section>
      <Section>
        <LengendSection>
          <Legend color={theme.colors.Secondary} />
          <Text format='body' color={theme.colors.Secondary}>
            {data.lgaStatistics[0].lga}
          </Text>
        </LengendSection>
        <DataDisplay title='Population' content={`${formatPrice(lga.lgaPopulation)}`} />
        <DataDisplay title='YTD Median' content={`$${formatPrice(lga.lgaMedianPrice)}`} />
        <DataDisplay title='YTD Sales' content={`${formatPrice(lga.lgaSaleCount)}`} />
        <DataDisplay title='No of Dwellings' content={`${formatPrice(lga.lgaDwellings)}`} />
      </Section>
    </Container>
  )
}

export default StatisticsLegend
