import styled from 'styled-components'

interface PillContainerProps {
  readonly background: string
  readonly paddingHorizontal: number
  readonly paddingVertical: number
}

export const PillContainer = styled.div<PillContainerProps>`
  display: flex;
  align-content: center;
  padding: ${(props) =>
    `${props.paddingVertical}px ${props.paddingHorizontal}px`};
  background-color: ${(props) => props.background};
  border-radius: 50px;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

export const SingleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 15px;
  border-radius: 50px;
  margin: 5px;
  width: fit-content;
  cursor: pointer;
`

export const PillTabsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  margin: 5px;
`

export const PillButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
