import { ActionLayout, Modal, Text } from 'venus/components'
import { theme } from 'venus/config'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { useMemo, useState } from 'react'
import Templates from './Templates'
import { generateRandomCoverImage } from 'venus/utils'
import { useAppDispatch } from 'venus/redux/hooks'
import api from 'venus/api'
import { updateSpace } from 'venus/redux/features/space/space'

const AddItems = ({ isOpen, template, close, propertyId, spaceId }) => {
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    const data = Object.keys(checkedItems).reduce((acc, curr) => {
      if (checkedItems[curr]) {
        return [
          ...acc,
          {
            name: curr,
            typeId: templateTypeMap[curr],
            coverImage: generateRandomCoverImage(),
          },
        ]
      }
      return acc
    }, [])

    const items = await api
      .post(`property/${propertyId}/space/${spaceId}/items`, data)
      .then((res) => res.data)

    dispatch(updateSpace({ items }))

    close()
  }

  const templateTypeMap = useMemo(() => {
    return template.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.type }), {})
  }, [template])

  const [checkedItems, setCheckedItems] = useState({})

  const handleCheckItem = (type: string) => {
    if (checkedItems[type]) {
      setCheckedItems({ ...checkedItems, [type]: false })
    } else {
      setCheckedItems({ ...checkedItems, [type]: true })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={close} title='Add Items'>
      <ActionLayout
        margin={34}
        rightActions={[
          {
            type: 'icon_button',
            text: 'Save',
            // iconLeft: <AddSign color={theme.colors.White} />,
            onClick: handleSubmit,
          },
        ]}
      >
        <Text
          style={{ textAlign: 'center', marginBottom: 20 }}
          format='h4'
          fontSize={16}
          color={theme.colors.Black}
          value={'Select from the list or create a new item'}
        />

        <Templates
          template={template}
          checkedItems={checkedItems}
          handleCheckItem={handleCheckItem}
        />
      </ActionLayout>
    </Modal>
  )
}

export default AddItems
