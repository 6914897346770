import styled from 'styled-components'

type DrawerContainerProps = {
  readonly isOpen: boolean
}
export const DrawerContainer = styled.div<DrawerContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 680px;
  height: 100vh;
  top: 0;
  right: 0;

  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100%')});
  background: ${(props) => props.theme.colors.White};
  box-shadow: 0 4px 60px rgba(22, 18, 66, 0.16);

  transition: transform ease-in-out 0.3s;
  z-index: 10;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 32px;
  right: 36px;
  border: 0;
  outline: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;
`

export const DropBack = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.55);
`

export const ChildrenWrapper = styled.div`
  height: 100vh;
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
`
