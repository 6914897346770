export enum TradespeopleSteps {
  GET_TRADESPEOPLE = 0,
  SELECT_TRADESPEOPLE = 1,
  SEARCH_TRADESPEOPLE = 2,
  SELECT_NEW_TRADESPEOPLE = 3,
}

export const SEARCH_RESULT_TITLE = `Local Tradespeople`
export const SEARCH_LOADING_TEXT = `Exploring...`
export const SEARCH_AVAILABLE_TEXT = `Explore more tradespeople in your area`
export const EMPTY_DATA_TEXT = `You will see tradespeople listed here if they are added to a timeline event.`
export const ADD_CONTACT_TEXT = `has been added into Contact.`
export const SEARCH_TRADESPEOPLE_NOT_FOUND = 'There is no tradespeople available.'