import { ReactNode } from 'react'
import ReactModal from 'react-modal'
import {
  Button,
  ButtonWrapper,
  // IconWrapper,
  ModalBodyWrapper,
  ModalContents,
  ModalHeader,
  ModalTitle,
  ButtonPlaceHolder,
} from './Modal.styles'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import { ReactComponent as BackIcon } from 'venus/assets/img/icons/Back.svg'
import { Text } from '../Text'

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 11,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '9px',
    border: 0,
    padding: 0,
    overflow: '',
  },
}

ReactModal.setAppElement('#root')

interface IModal {
  hideHeader?: boolean
  width?: number
  height?: number
  isOpen: boolean
  style?: any
  showCloseButton?: boolean
  headerStyle?: any
  children?: ReactNode
  title?: string
  onClose?: () => void
  onBack?: () => void
  onSkip?: () => void
  closeOnClickOutside?: boolean
}

export const Modal = ({
  hideHeader = false,
  width,
  height,
  isOpen,
  showCloseButton = true,
  style = {},
  headerStyle = {},
  title,
  onClose,
  onBack,
  onSkip,
  children,
  closeOnClickOutside = true,
}: IModal) => {
  const handleClose = (event) => {
    event.stopPropagation()
    if (onClose) onClose()
  }

  const handleOnRequestClose = (event) => {
    event.stopPropagation()
    if (onClose) onClose()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleOnRequestClose}
      shouldCloseOnOverlayClick={closeOnClickOutside}
      shouldCloseOnEsc
      style={{
        overlay: { ...modalStyles.overlay, ...style?.overlay },
        content: { ...modalStyles.content, ...style?.content },
      }}
    >
      <ModalBodyWrapper width={width} height={height}>
        <ModalContents>
          {!hideHeader && (
            <ModalHeader>
              {onBack ? (
                <>
                  <ButtonWrapper>
                    <Button onClick={onBack}>
                      <BackIcon />
                    </Button>
                  </ButtonWrapper>
                </>
              ) : (
                <>
                  <ButtonWrapper />
                </>
              )}
              {title && (
                <ModalTitle>
                  <Text
                    format={'h2'}
                    fontSize={20}
                    lineHeight={'25px'}
                    value={title}
                    style={headerStyle}
                  />
                </ModalTitle>
              )}
              {onSkip ? (
                <ButtonWrapper>
                  <Button onClick={onSkip}>
                    <Text format='h3' value='Skip' />
                  </Button>
                </ButtonWrapper>
              ) : showCloseButton ? (
                <ButtonWrapper>
                  <Button onClick={handleClose}>
                    <CrossIcon />
                  </Button>
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  <ButtonPlaceHolder />
                </ButtonWrapper>
              )}
            </ModalHeader>
          )}
          {isOpen && children}
        </ModalContents>
      </ModalBodyWrapper>
    </ReactModal>
  )
}
