import { useState, useEffect, useRef } from 'react'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
// import useShowPremium from 'venus/hooks/useShowPremium'
import {
  deletePsiDocs,
  deletePersonalDocs,
  deletePersonalItemDocs,
  getPersonalItemDocs,
  getPersonalDocs,
  IDoc,
  moveDocs,
  getPSIDocs,
  resetError,
} from 'venus/redux/features/docs'
import { defaultPillsTabConfig, tabs } from 'venus/config'
// import MHVPremium from 'venus/scenes/Transfer/MHVPremium'
import { MoveTo, MoveToModal } from 'venus/common/MoveTo'
import { AlertBox, Loader, PillTabs, FileUploader } from 'venus/components'
import { PropertySpacesContainer, Wrapper } from './List.styles'
import { SubList } from './SubList'
import { CreateEditDoc } from '../CreateEditDoc'
import usePremium from 'venus/hooks/usePremium'
import usePremiumContext from 'venus/context/MHVPremium'

interface IDocumentList {
  type: 'property' | 'space' | 'item' | 'personal' | 'personalItem'
  propertyId?: string
  propertyWideSpaceId?: string
  spaceId?: string
  itemId?: string
  shareId?: string
  dreamPropertyId?: string
  personalItemId?: string
  onUpdate?: (data: any) => void
  setTabButtons?: (v: any) => void
}

export const DocumentList = ({
  type,
  propertyId,
  propertyWideSpaceId,
  spaceId,
  itemId,
  personalItemId,
  shareId,
  dreamPropertyId,
  onUpdate,
  setTabButtons,
}: IDocumentList) => {
  const { propertyStatus } = usePremium(propertyId)
  const { openPremium } = usePremiumContext()
  const isPersonal = type === 'personal'
  const isPersonalItem = type === 'personalItem'
  const dispatch = useAppDispatch()
  const {
    docs: psiDocs,
    personalDocs,
    personalItemDocs,
    errorMsg,
    loading,
  } = useAppSelector((state) => state.docs)
  const docs = isPersonal ? personalDocs : isPersonalItem ? personalItemDocs : psiDocs

  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [contentTypeModal, setContentTypeModal] = useState(false)
  const [uploadURL, setUploadURL] = useState([])
  const [uploadName, setUploadName] = useState([])

  const docsRef = useRef()
  const uploadURLRef = useRef()
  docsRef.current = psiDocs
  uploadURLRef.current = uploadURL as any

  const [showMoveModal, setShowMoveModal] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState<IDoc>()
  // const { vaults } = useAppSelector((state) => state.subscription)
  // const { property } = useAppSelector((state) => state.property)
  // const subscriptionStatus = vaults[property?.id]
  // const { onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )

  useEffect(() => {
    const setDocs = async () => {
      if (isPersonal) {
        await dispatch(getPersonalDocs())
      } else if (isPersonalItem) {
        await dispatch(getPersonalItemDocs({ personalItemId }))
      } else {
        await dispatch(
          getPSIDocs({
            propertyId,
            propertyWideSpaceId,
            spaceId,
            itemId,
            type,
          }),
        )
      }
    }
    setDocs()
  }, [type, propertyId, propertyWideSpaceId, spaceId, itemId])

  const handleOnFinish = () => {
    // if (
    //   propertyStatus === 'free' &&
    //   // @ts-ignore
    //   docsRef.current.length + uploadURLRef.current.length > 3
    // ) {
    //   return openPremium()
    // } else {
    //   setContentTypeModal(true)
    // }
    //
    setContentTypeModal(true)
  }

  useEffect(() => {
    if (!shareId && !dreamPropertyId)
      setTabButtons(
        <FileUploader
          setUploadName={setUploadName}
          setUploadURL={setUploadURL}
          onFinish={handleOnFinish}
        />,
      )
    return () => {
      setTabButtons(<></>)
    }
  }, [])

  if (loading || !docs) return <Loader isFull />

  const handleOpenFile = (
    updatedAt: string,
    url: string,
    displayName: string,
    contentType: string,
  ) => {
    setSelectedDoc({
      updatedAt,
      url,
      displayName,
      contentType,
    })
    setUploadName([displayName])
    setUploadURL([url])
  }

  const handlePressKebab = (
    updatedAt: string,
    url: string,
    displayName: string,
    contentType: string,
  ) => {
    setSelectedDoc({ updatedAt, url, displayName, contentType })
    setUploadName([displayName])
    setUploadURL([url])
  }

  const handleClose = () => {
    setShowRenameModal(false)
    setContentTypeModal(false)
  }

  const handlePressRename = () => {
    setShowRenameModal(true)
  }

  const handlePressMove = () => {
    setShowMoveModal(true)
  }

  const handlePressDelete = () => {
    setShowConfirmDelete(true)
  }

  const handlPressAlertOK = async () => {
    if (isPersonal) {
      dispatch(deletePersonalDocs([selectedDoc?.url]))
    } else if (isPersonalItem) {
      dispatch(deletePersonalItemDocs({ data: [selectedDoc?.url], id: personalItemId }))
    } else {
      const body: {
        propertyId: string
        spaceId: string
        documents: string[]
        itemId?: string
      } = {
        propertyId,
        spaceId: propertyWideSpaceId,
        documents: [selectedDoc?.url],
      }
      if (type === 'space') {
        body.spaceId = spaceId
      } else if (type === 'item') {
        body.spaceId = spaceId
        body.itemId = itemId
      }
      await dispatch(deletePsiDocs(body))
    }
    setShowConfirmDelete(false)
  }

  const handlPressAlertCancel = () => {
    setShowConfirmDelete(false)
  }

  // const handleOnCancel = () => {
  //   setShowAlert(false)
  //   onCancel()
  //   dispatch(resetError())
  // }

  // const handleViewPlan = () => {
  //   setShowAlert(false)
  //   dispatch(resetError())
  //   onViewPlan()
  // }

  const listLegal = docs.filter(({ contentType }) => contentType === 'LEGAL')
  const listDrawing = docs.filter(({ contentType }) => contentType === 'DRAWING')
  const listReceipt = docs.filter(({ contentType }) => contentType === 'RECEIPT')
  const listUserManual = docs.filter(({ contentType }) => contentType === 'USER_MANUAL')
  const listInsurance = docs.filter(({ contentType }) => contentType === 'INSURANCE')
  const listOther = docs.filter(({ contentType }) => contentType === 'OTHER')

  const list = [
    docs,
    listLegal,
    listDrawing,
    listReceipt,
    listUserManual,
    listInsurance,
    listOther,
  ]

  const handleMove = async (body: {
    source: {
      propertyId: string
      spaceId: string
      itemId?: string
    }
    target: {
      propertyId: string
      spaceId: string
      itemId?: string
    }
    documents: string[]
  }) => {
    await dispatch(moveDocs(body))

    closeMoveToModal()
  }

  const closeMoveToModal = () => {
    setShowMoveModal(false)
  }

  const isShared = !!shareId || !!dreamPropertyId

  return (
    <Wrapper>
      <PropertySpacesContainer>
        <PillTabs
          onChange={setActiveIndex}
          tabs={tabs}
          config={defaultPillsTabConfig}
          style={{ marginLeft: -5, marginBottom: 20 }}
          defaultActiveIndex={activeIndex}
        />
        <SubList
          handlePress={handleOpenFile}
          handlePressKebab={handlePressKebab}
          handlePressRename={handlePressRename}
          handlePressMove={handlePressMove}
          handlePressDelete={handlePressDelete}
          data={list[activeIndex]}
          isShare={isShared}
          isPersonal={isPersonal}
          isPersonalItem={isPersonalItem}
        />
      </PropertySpacesContainer>
      <AlertBox
        title='Delete Document'
        message={`Are you sure you want to delete this document?`}
        cancel={handlPressAlertCancel}
        btnGroup={[
          {
            text: 'Delete',
            onClick: handlPressAlertOK,
            color: 'ErrorState',
            type: 'error',
          },
          {
            text: 'Cancel',
            onClick: handlPressAlertCancel,
            color: 'LightBlue',
            textColor: 'Primary',
            type: 'secondary',
          },
        ]}
        showAlert={showConfirmDelete}
      />
      {/* <MHVPremium
        isOpen={showAlert}
        onViewPlan={handleViewPlan}
        onCancel={handleOnCancel}
      /> */}
      {(contentTypeModal || showRenameModal) && (
        <CreateEditDoc
          onUpdate={onUpdate}
          handleClose={handleClose}
          propertyId={propertyId}
          propertyWideSpaceId={propertyWideSpaceId}
          spaceId={spaceId}
          itemId={itemId}
          personalItemId={personalItemId}
          type={type}
          actionType={contentTypeModal ? 'create' : 'edit'}
          uploadURL={uploadURL}
          uploadName={uploadName}
          setUploadName={setUploadName}
          activeIndex={activeIndex}
          document={selectedDoc}
        />
      )}
      {!isShared && (
        <MoveToModal
          psiId={{ propertyId, propertyWideSpaceId, spaceId, itemId }}
          document={selectedDoc}
          type={type}
          isOpen={showMoveModal}
          close={closeMoveToModal}
          handleMove={handleMove}
        />
      )}
    </Wrapper>
  )
}
