import { useEffect } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { theme } from 'venus/config'
import { Text } from '../Text'

type ContainerProps = {
  readonly isSmallVersion?: boolean
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  .react-select__control {
    outline: none;
    box-shadow: none;
    cursor: pointer;
    height: ${(props) => (props.isSmallVersion ? 38 : 45)}px;
    border: 0.5px solid ${(props) => props.theme.colors.Grey};

    :hover {
      border-color: ${(props) => props.theme.colors.Grey};
    }
  }

  .react-select__value-container {
    padding: 2px 20px;
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    border: 0.5px solid ${(props) => props.theme.colors.Grey};
    margin-top: 3px;
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.0627732);
    border-radius: 4px;
    z-index: 1000000 !important;
  }
  .react-select__menu-list {
    padding-top: 0;
  }

  .react-select__option {
    height: 45px;
    padding: 12px 20px;

    &:active {
      background-color: ${(props) => props.theme.colors.LightBlue};
    }
  }
  .react-select__option--is-focused {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.LightBlue};
  }

  .react-select__option--is-selected {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.LightBlue};
    color: ${(props) => props.theme.colors.Primary};
  }
  /* .react-select-container {
    width: 100%;
  } */
`

const Title = styled.div`
  margin-bottom: 6px;
`
const ErrorMessage = styled.div`
  margin-top: 10px;
`

export type Option = {
  value: string
  label: any
}

interface ISelect {
  title: string
  value: string
  onChange: (v: any) => void
  options: Option[]
  isError?: boolean
  errorMsg?: string
  isDisabled?: boolean
  style?: {}
  isSmallVersion?: boolean
  showTransparentBg?: boolean
}

const ReactSelect = ({
  title,
  value,
  options,
  onChange,
  isError = false,
  errorMsg,
  isDisabled,
  style = {},
  isSmallVersion,
  showTransparentBg,
}: ISelect) => {
  // useEffect(() => {
  //   setTimeout(function () {
  //     debugger
  //   }, 5000)
  // }, [])

  const handleChange = (option) => onChange(option.value)

  const defaultVaule = options.find((option) => option.value === value)

  return (
    <Container isSmallVersion={isSmallVersion} style={style}>
      {title && (
        <Title>
          <Text value={title} format='h3' />
        </Title>
      )}

      <Select
        menuPortalTarget={document.body}
        className='react-select-container'
        classNamePrefix='react-select'
        options={options}
        onChange={handleChange}
        defaultValue={defaultVaule}
      />
      {isError && (
        <ErrorMessage>
          <Text value={String(errorMsg)} format='h4' color={theme.colors.DangerRed} />
        </ErrorMessage>
      )}
    </Container>
  )
}

export default ReactSelect
