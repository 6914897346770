import { useState } from 'react'
import { Text } from 'venus/components'
import { Avatar } from 'venus/components/Avatar'
import { useEffect } from 'react'
import ImageUploader from 'venus/common/ImageUploader'
import { useAppSelector } from 'venus/redux/hooks'
import { Loader } from 'venus/components'
import { useAppDispatch } from 'venus/redux/hooks'
import { updateProfile } from 'venus/redux/features/user'
import { getProfile } from 'venus/redux/features/user'

export const AvatarSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  // enable this if want to test with user profile

  const [avatar, setAvatar] = useState('')
  // const dispatch = useAppDispatch()
  // const { loading, info } = useAppSelector((state) => state.user)
  // const { url, progress } = useAppSelector((state) => state.image)
  //
  // useEffect(() => {
  //   dispatch(getProfile())
  // }, [])
  //
  // useEffect(() => {
  //   const avatar = info?.profile?.avatar
  //   setAvatar(avatar)
  // }, [info])
  //
  // useEffect(() => {
  //   if (url && progress === 100) {
  //     dispatch(updateProfile({
  //       profile: {
  //         avatar: url
  //       }
  //     }))
  //   }
  // }, [url, progress])
  //
  // if (loading) {
  //   return (
  //     <div style={{ height: '100vh' }}>
  //       <Loader isFull/>
  //     </div>
  //   )
  // }
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <div style={{ width: 500 }}>
        <ImageUploader>
          <Avatar size={80} avatar={avatar}/>
        </ImageUploader>
        <Avatar size={80} avatar={avatar} isBorder={false}/>
        <ImageUploader>
          <Avatar size={129} avatar={avatar} showEditBtn={true}/>
        </ImageUploader>
        <ImageUploader>
          <Avatar size={129} avatar={undefined} isBorder={false} showEditBtn={true}/>
        </ImageUploader>
      </div>
    </>
  )
}