import ReactModal from 'react-modal'
import { Button, Modal, Text } from 'venus/components'
import {
  ActionFooter,
  Body,
  ButtonWrapper,
  CloseButton,
  Content,
  Header,
  MHVBadge,
} from './MHVPremium.styles'
import { theme } from 'venus/config'
import { useAppSelector } from 'venus/redux/hooks'
import { getNumberFromProductSku } from 'venus/utils'
import { ReactComponent as MHV } from 'venus/assets/img/icons/MHV.svg'
import Subscriptions from 'venus/layout/ProfileMenu/ProfileSettings/Subscriptions'
import { useState } from 'react'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import usePremiumContext from 'venus/context/MHVPremium'

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 29,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '9px',
    border: 0,
    padding: 0,
    overflow: '',
    background: 'transparent',
  },
}

const message = {
  enoughQuota: {
    message:
      'Do you want to convert this free vault into a premium vault to unlock all premium features?',
    buttonText: 'Yes',
  },
  notEnoughQuota: {
    message:
      'Please upgrade your current package plan to be able to convert this free vault into a premium vault to unlock all premium features.',
    buttonText: 'Upgrade',
  },
  noQuota: {
    message:
      'Please subscribe a package plan to convert this free vault into a premium vault to unlock all premium features.',
    buttonText: 'Subscribe',
  },
}

const MHVPremium = () => {
  const { isOpen, closePremium } = usePremiumContext()
  const [isPaymentOpen, setIsPaymentOpen] = useState(false)

  const { subscribed, unsubscribed, productId } = useAppSelector(
    (state) => state.subscription,
  )

  const number = getNumberFromProductSku(productId)

  let type = subscribed.length < number ? 'enoughQuota' : 'notEnoughQuota'
  if (!number) {
    type = 'noQuota'
  }

  const openPayment = () => {
    setIsPaymentOpen(true)
    closePremium()
  }

  if (isPaymentOpen) {
    return (
      <Modal hideHeader isOpen={isPaymentOpen}>
        <Subscriptions
          upgrade={type === 'notEnoughQuota' ? 1 : 0}
          style={{ paddingTop: 24 }}
          initialStep={'ChoosePlan'}
        />
        <ButtonWrapper>
          <CloseButton onClick={() => setIsPaymentOpen(false)}>
            <CrossIcon onClick={(e) => e.preventDefault()} />
          </CloseButton>
        </ButtonWrapper>
      </Modal>
    )
  }

  return (
    //@ts-ignore
    <ReactModal isOpen={isOpen} style={{ ...modalStyles }}>
      <Content>
        <MHVBadge>
          <MHV color={theme.colors.White} width={24} height={24} />
        </MHVBadge>
        <Header>
          <Text format='h3' color={theme.colors.White}>
            MHV Premium
          </Text>
        </Header>
        <Body>
          <Text style={{ textAlign: 'center' }} format='h3'>
            {message[type].message}
          </Text>
        </Body>
        <ActionFooter>
          {/* [
              {
                text: 'Delete',
                onClick: handlPressDelete,
                color: 'ErrorState',
                type: 'error',
              },
              {
                text: 'Cancel',
                onClick: handlPressAlertCancel,
                color: 'LightBlue',
                textColor: 'Primary',
                type: 'secondary',
              },
            ] */}
          <Button
            style={{ width: '45%' }}
            type='primary'
            text={message[type].buttonText}
            onClick={openPayment}
          />
          <Button
            style={{ width: '45%' }}
            type='secondary'
            text='Cancel'
            onClick={closePremium}
          />
        </ActionFooter>
      </Content>
    </ReactModal>
  )
}

export default MHVPremium
