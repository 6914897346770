import React from 'react'
import { StyledTextArea } from 'venus/components/Textarea/TextArea.styles'
import { TextAreaWrapper } from 'venus/components/Textarea/TextArea.styles'
import { Text } from 'venus/components'
import { theme } from 'venus/config'

const TextArea = ({
  rows = 5,
  cols = 50,
  label = '',
  value = '',
  placeholder = '',
  onChange,
  style,
  isError = false,
  errorMsg,
  disabled,
}: {
  rows: number
  cols: number
  label?: string
  value?: string
  placeholder?: string
  onChange?: (t: string) => void
  style?: any
  isError?: boolean
  errorMsg?: string
  disabled?: boolean
}) => {
  return (
    <TextAreaWrapper style={style}>
      <Text
        format={'h3'}
        value={label}
        style={{ textAlign: 'left', marginBottom: 6 }}
      />
      <StyledTextArea
        rows={rows}
        cols={cols}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value)}
        isError={isError}
        disabled={disabled}
      />
      {isError && (
        <Text
          value={String(errorMsg)}
          format='h4'
          lineHeight='21px'
          color={theme.colors.DangerRed}
          style={{
            marginTop: '6px',
            display: 'inline-block',
          }}
        />
      )}
    </TextAreaWrapper>
  )
}

export default TextArea
