import { useEffect, useState } from 'react'
import { BackgroundContainer, ContentContainer } from './Move.styles'
import { Button } from 'venus/components'
import api from 'venus/api'
import SpaceDisplay from './SpaceDisplay'

interface IMoveTo {
  image?: string
  document?: { url: string }
  psiId: {
    propertyId: string
    propertyWideSpaceId: string
    spaceId: string
    itemId?: string
  }
  type: 'property' | 'space' | 'item' | 'personal' | 'personalItem'
  handleMove: Function
  close?: () => void
}

export const MoveTo = ({ image, document, psiId, type, handleMove, close }: IMoveTo) => {
  const [spaces, setSpaces] = useState([])
  const [selectedSpace, setSelectedSpace] = useState('')
  const [selectedItem, setSelectedItem] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { propertyId, propertyWideSpaceId, spaceId, itemId } = psiId

  useEffect(() => {
    const getSpaces = async () => {
      const result = await api
        .get(`/property/${propertyId}/spaces`)
        .then(({ data }) => data)
      setSpaces(result)
    }

    if (propertyId) {
      getSpaces()
    }
  }, [propertyId])

  // useEffect(() => {
  //   setIsLoading(false)
  //   setSelectedSpace('')
  //   setSelectedItem('')
  // }, [isOpen])

  if (spaces.length === 0) return null

  const onSelectSpace = (spaceId: string) => {
    setSelectedSpace(spaceId)
    setSelectedItem('')
  }
  const onSelectItem = (itemId: string) => {
    setSelectedItem(itemId)
  }

  const onMove = async () => {
    setIsLoading(true)
    if (!!document) {
      const body: {
        source: {
          propertyId: string
          spaceId: string
          itemId?: string
        }
        target: {
          propertyId: string
          spaceId: string
          itemId?: string
        }
        documents: string[]
      } = {
        source: {
          propertyId,
          spaceId: propertyWideSpaceId,
        },
        target: {
          propertyId,
          spaceId: selectedSpace,
        },
        documents: [document.url],
      }
      if (type !== 'property') {
        body.source.spaceId = spaceId
      }
      if (type === 'item') {
        body.source.itemId = itemId
      }
      if (selectedItem) {
        body.target.itemId = selectedItem
      }
      await handleMove(body)
    } else {
      const extra = selectedItem
        ? {
            itemId: selectedItem,
          }
        : {}

      await handleMove({
        image,
        document,
        source: psiId,
        target: {
          propertyId,
          spaceId: selectedSpace,
          ...extra,
        },
      })
    }
    setIsLoading(false)
    close && close()
  }

  return (
    <BackgroundContainer>
      <ContentContainer>
        {spaces.map(({ items, coverImage, id, name }, index) => (
          <SpaceDisplay
            isActive={!selectedSpace || selectedSpace === id}
            isSelected={selectedSpace === id}
            onSelectSpace={onSelectSpace}
            onSelectItem={onSelectItem}
            key={`space_move_to_${index}`}
            items={items}
            coverImage={coverImage}
            id={id}
            name={name}
          />
        ))}
      </ContentContainer>
      {selectedSpace && (
        <Button
          text='Move Here'
          onClick={onMove}
          isLoading={isLoading}
          style={{
            height: '36px',
            padding: '4px 28px',
            width: '136px',
            margin: '24px',
            alignSelf: 'flex-end',
          }}
        />
      )}
    </BackgroundContainer>
  )
}
