import _ from "lodash"
import { colorPalette, gradientPrefix } from "venus/config"

export const generateRandomCoverImage = () => {
  const index = _.random(colorPalette.length - 1)
  return `${gradientPrefix}${colorPalette[index]}`
}

export const isColorBackground = (image: string) =>
  image && image.startsWith(gradientPrefix)

export const getColorsFromUrl = (url: string) =>
  url.replace(gradientPrefix, "").split("-")
