import React from 'react'
import { Container } from 'venus/pages/TermsAndConditions/TermsAndConditions.styles'

export const TERMS_OF_SERVICE_LINK_TEXT = 'Terms of Service'

export const TermsAndConditions = ({
  top = 0,
  isModal = false
} : {
  top?: number,
  isModal?: boolean
}) => {
  return (
    <Container top={top} isModal={isModal}>
      <h1>MHV Platform</h1>
      <h4>Terms and Conditions of Access and Use</h4>
      <p>Welcome, and thank you for using the MyHomeVault platform (the<b>&nbsp;MHV Platform</b>)! &nbsp;</p>
      <p><b>Background</b></p>
      <p>
        The MHV Platform is a property search and property/home ownership
        management, utility and documentation monitoring application, that
        allows users to:
      </p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          &#8226; monitor real estate market data relevant to their property(ies) and
          to third party owned properties, including properties the user would
          like to live in or add to their property portfolio (ie their
          &ldquo;dream properties&rdquo;);</p>
        <p>
          &#8226; track and monitor in one place the status of items relevant to their
          property(ies)/home, such as, title deeds, contracts, plans, Council
          development applications, insurance policies, utilities, appliances,
          instruction manuals, historical information, and important contacts,
          eg, builder, architect, real estate agents or tradespeople; and</p>
        <p>
          &#8226; engage with other homeowners, property investors and developers, real
          estate agents, tradespeople, and other persons providing goods or
          services, in relation to the real estate industry, and getting things
          done around the home and monitoring those items.</p>
      </div>
      <p>
        The MHV Platform is owned and operated by MyHomeVault Pty Limited ACN
        648 732 441 or, from time to time, by any one or more of that
        company&rsquo;s related bodies corporate or related entities (<b>MyHomeVault</b>). &nbsp;
      </p>
      <p>
        From time to time, certain functionalities of the MHV Platform are made
        available to users through MyHomeVault with or without the use of or
        accessing third party products or services (the <b>MHV Associated Products/Services</b>).
        Unless dealt with otherwise in these Terms, references to the MHV
        Platform are intended to also be references to MHV Associated
        Products/Services.
      </p>
      <p>
        Access to and use of the MHV Platform is strictly subject to these
        terms of access and use (<b>Terms</b>),
        so before you access and or use the MHV Platform, you <b>must</b>
        &nbsp;ensure that you are permitted to access or use the functionality
        offered by the MHV Platform and, if so, have read and understood the
        Terms and agree to be bound by them. &nbsp;If you have not done so, then
        you are not permitted to access or use the MHV Platform, and any such
        use or access is strictly contrary to law. Furthermore, if you have not
        done so, you should immediately delete anything on any of your devices
        that would otherwise provide you with access to or use of the MHV
        Platform.
      </p>
      <p>
        In these Terms and Conditions, headings are for convenience only and do
        not affect interpretation.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        <b>1. Access, use, and categories of lawful users</b>
      </p>
      <div style={{ paddingTop: '1rem', paddingLeft: '2rem' }}>
        <p>
          1.1. You agree that by clicking &ldquo;
          <i>Sign Up</i>
          &rdquo;, &ldquo;
          <i>Register</i>
          &rdquo;, &ldquo;
          <i>Join Now</i>
          &rdquo;, &ldquo;
          <i>Join MyHomeVault</i>
          &rdquo;, or similar, that you have read, understood, and agree to be
          bound by, these Terms, and that you are entering into a legally
          binding agreement with MyHomeVault (also referred to as &ldquo;
          <b>us</b>
          &rdquo; or &ldquo;
          <b>we</b>
          &ldquo;) in accordance with these Terms in relation to access to or
          use of the MHV Platform by you at any time.
        </p>
        <p style={{ paddingTop: '1rem' }}>
          1.2. MyHomeVault has a Privacy Statement which sets out how we collect,
          use, share, and store, users&rsquo; personal information. The Privacy
          Statement appears at
          &nbsp;<a href="https://app.myhomevault.com.au/privacy-policy" target="_blank" rel="noreferrer"
             style={{ lineBreak: 'anywhere' }}>https://www.myhomevault.com.au</a>
          , and forms part of these Terms.&nbsp;
          Please read the&nbsp;Privacy Statement as it describes how we use
          data that we collect from you. The Privacy Statement also describes
          how MyHomeVault uses your content, which includes any communications
          you have with others on or in association with the MHV Platform;
          postings submitted by you to the MHV Platform; and the files, photos,
          documents, audio, digital works, livestreams and videos that you
          upload, store, broadcast or share through the MHV Platform. Where
          processing is based on consent and to the extent permitted by law, by
          agreeing to these Terms, you consent to MyHomeVault&rsquo;s
          collection, use and disclosure of your content and data as further
          described in these Terms and the Privacy Statement.
        </p>
        <p>
          1.3. You may not use the MHV Platform and may not accept the Terms
          if:
        </p>
        <div style={{ paddingTop: '1rem', paddingLeft: '2rem' }}>
          <p>
            a. you are not of legal age to form a binding contract with MyHomeVault
            or are under 18 years of age;
          </p>
          <p style={{ paddingTop: '1rem' }}>
            b. you are a person barred from accessing or using the functionality
            offered by the MHV Platform under the laws of Australia or any other
            country including the country in which you are resident or from which
            you intend to access and use the MHV Platform.
          </p>
        </div>
        <p style={{ paddingTop: '1rem' }}>
          1.4. Your access to or use of the MHV Platform is subject to these Terms,
          including the Privacy Statement, and to any other terms or conditions
          that are made known to you from time to time while you are accessing
          or using the MHV Platform, all of which may be amended by us from time
          to time with notice to you.
        </p>
        <p style={{ paddingTop: '1rem' }}>
          1.5. If you do not agree to be bound by these Terms, or are otherwise not
          permitted to so agree, for example, pursuant to clause 1.3, you must
          NOT click &ldquo;
          Sign up
          &rdquo; (or similar) and/or immediately discontinue any actual or
          planned access or use. Any access or use of the MHV Platform by a user
          who does not agree to these Terms is strictly prohibited and contrary
          to law.
        </p>
        <p style={{ paddingTop: '1rem' }}>
          1.6. There are two categories of lawful users of the MHV Platform (<b>Registered Users</b>):
        </p>
        <div style={{ paddingTop: '1rem', paddingLeft: '2rem' }}>
          <p>
            a. <b>Subscribers </b>
            are users who have subscribed to a particular plan for access to and
            use of the MHV Platform (<b>Subscription Plans</b>); and
          </p>
          <p>
            b. <b>Visitors</b>
            &nbsp;are users who are accessing and using limited functionality of
            the MHV Platform available without subscription. &nbsp;
          </p>
          <p>
            Any user who complies with clauses 1.1 to 1.5 inclusive may become
            Registered Users and are referred to in these Terms as &ldquo;
            <b>you</b>&rdquo;, &ldquo;
            <b>Subscriber</b>
            &rdquo;, &ldquo;<b>Visitor</b>
            &rdquo;, or &ldquo;
            <b>Registered User</b>
            &rdquo; (as the case may be or as context requires).
          </p>
        </div>
        <p style={{ paddingTop: '1rem' }}>
          1.7. These Terms apply to all Registered Users.
        </p>
      </div>
      <p style={{ paddingTop: '1rem' }}>
        <b>2. Subscription Plans &ndash; Visitors vs Subscribers and levels of MHV Platform functionality</b>
      </p>
      <div style={{ paddingTop: '1rem', paddingLeft: '2rem' }}>
        <p>
          2.1. You can become a Visitor by registering through the MHV Platform as a
          Registered User. If you are a Visitor, you can become a Subscriber by
          purchasing a Subscription Plan on the MHV Platform. If you are not a
          Visitor, you can become a Subscriber by registering through the MHV
          Platform as a Registered User and purchasing a Subscription
          Plan.
        </p>
        <p>
          2.2. To become a Registered User, the minimum amount of Your Personal
          Information which you will be required to provide, or otherwise make
          available, to MyHomeVault, is your:
        </p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>&nbsp;a. email address;</p>
          <p>&nbsp;b. username (if different to your email address);</p>
          <p>&nbsp;c. telephone number;</p>
          <p>d. MHV Platform password; </p>
          <p>e. MHV Platform pin; and</p>
          <p>f. billing/payment information.</p>
        </div>
        <p>
          2.3. Access to and use of the MHV Platform will be provided to you as soon
          as practicable following your becoming a Registered User. The level of
          access provided will correspond to whether you are a Visitor or a
          Subscriber and, if a Subscriber, to the Subscription Plan to which you
          have subscribed.
        </p>
        <p>
          2.4. As a Visitor, you have access to and use of basic functionality of
          the MHV Platform, which basic functionality will be determined from
          time to time by MyHomeVault in its sole and unfettered discretion, and
          may include a limited version of one or more of the functionalities
          identified in clauses 2.10 and 2.11 of these Terms.
        </p>
        <p>
          2.5. MyHomeVault reserves the right to require a Visitor at any time to
          become a Subscriber by subscribing to a Subscription Plan. In the
          event MyHomeVault exercises that right, your access to and use of the
          MHV Platform as a Visitor will be discontinued unless you subscribe to
          a Subscription Plan within the time required by the MHV Platform. If
          your access to and use of the MHV Platform as a Visitor has been
          discontinued in accordance with this clause 2.5, or you have cancelled
          your Visitor status or deleted your login account prior to subscribing
          to a Subscription Plan, then all of Your Personal Information defined
          in clause 2.2 (minimum personal details required) and, if available to
          a Visitor from time to time, 2.10.a.iii (Items), 2.10.a.vi
          (Documents), 2.10.a.vii (History), and 2.10.a.xi (Reminders), will be
          irrevocably deleted, but all of Your MHV Platform Data defined in
          clause 2.11, and elsewhere in these Terms, will continue to be stored
          and used by the MHV Platform and MyHomeVault in accordance with these
          Terms. However, any continued access or use of the MHV Platform by you
          will be contrary to law.
        </p>
        <p>
          2.6. As a Subscriber, you have access to and use of the particular
          functionalities of the MHV Platform identified in the Subscription
          Plan to which you have subscribed, and as varied by MyHomeVault from
          time to time, in its sole and unfettered discretion, until the earlier
          of:
        </p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            a. your subscribing to a new Subscription Plan, in which case all of
            Your Personal Information and all of Your MHV Platform Data will
            continue to be available to your login under the new Subscription
            Plan, and to the MHV Platform and MyHomeVault to be accessed and used
            in accordance with these Terms; or
          </p>
          <p>
            b. your cancelling your current Subscription Plan, or cancelling or
            deleting your login account, in which case all of Your Personal
            Information defined in clause 2.2 (minimum personal details required),
            2.10.a.iii (Items), 2.10.a.vi (Documents), 2.10.a.vii (History), and
            2.10.a.xi (Reminders), will be irrevocably deleted, but the balance of
            Your Personal Information as defined in these Terms (including in
            clause 2.10) and all of Your MHV Platform Data defined in clause 2.11,
            and elsewhere in these Terms, will continue to be stored and used by
            the MHV Platform and MyHomeVault in accordance with these Terms.
          </p>
        </div>
        <p>
          2.7. Unless otherwise expressly indicated, all Subscription Plans have an
          automatic rollover into a subsequent term.
        </p>
        <p>
          2.8. The MHV Platform will identify functionalities for the use of which:
        </p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>a. a Visitor must become a Subscriber; or</p>
          <p>b. a Subscriber must subscribe to a different Subscription Plan,</p>
          <p>
            by listing those functionalities in relevant Subscription Plan
            advertisements and or by inviting a Registered User who
            &lsquo;clicks&rsquo; on an icon/link for a particular functionality to
            subscribe to any of the Subscription Plans through which that particular
            functionality is available.</p>
        </div>
        <p>
          2.9. Subscription Plans, the functionalities made available in them, and
          the costs to a Registered User for subscribing to them, will be
          determined from time to time by MyHomeVault in its sole and unfettered
          discretion; provided, however, that the functionalities identified in
          a particular Subscription Plan to which a Subscriber has subscribed
          will be available to that Subscriber until the earlier of the end of
          the then current term of that member&rsquo;s current Subscription
          Plan, or until you cancel your then current Subscription Plan, or
          cancel or delete your login account.
        </p>
        <p>
          2.10. The MHV Platform is adapted to receive, store, use and process
          information provided/uploaded through your login, or in association
          with your login, or with one or more property address(es), about your
          property(ies) and properties of interest to you or others (including
          your property(ies)), and which are shared with your login by other
          Registered Users. This information, together with the information in
          clause 2.2, comprises Your Personal Information as follows:
        </p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>a. <b>
            If you are a Registered User who chooses to make use of the MHV
            Platform in relation to your property(ies) (&ldquo;My
            Properties&rdquo;),</b>&nbsp;other personal information of yours which may be provided/uploaded
            through or against your login includes, but is not limited to,
            information in relation to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>i. the fact that you have become a Registered User;</p>
            <p>
              ii. rooms/spaces in your property(ies) with or without photographs/images
              of them (<b>Spaces</b>);</p>
            <p>
              iii. items, such as, fixtures, fittings, or appliances in your
              property(ies) with or without information, such as, receipts, serial
              numbers, warranty information, instruction manuals and
              photographs/images of them (<b>Items</b>);</p>
            <p>iv. photographs/images of your property(ies) (<b>Photos</b>);</p>
            <p>v. videos/animations of your property(ies) (<b>Videos</b>);</p>
            <p>
              vi. documentary materials concerning your property(ies), such as, title
              deeds, contracts, plans, Council development applications, home and
              contents insurance policies, materials concerning utilities, such as
              water, electricity and gas (<b>Documents</b>);</p>
            <p>
              vii. historical information concerning your property(ies), such as,
              information recording changes made in or to the property(ies) over
              time (<b>History</b>);</p>
            <p>
              viii. tradespeople who have done any works in or to your property(ies),
              including data as to what those works were, how much they costed, and
              contact information in relation to such tradespeople. It is your
              responsibility to obtain consent from each tradesperson to record such
              personal data of theirs against or in association with your login, or
              one or more property address(es) (<b>Tradespeople</b>);</p>
            <p>
              ix. contacts for persons who have had anything to do with your
              property(ies), or may do so, such as, tradespeople, real estate
              agents, property managers, builders, and other categories of such
              persons. It is your responsibility to obtain consent from each such
              person to record such of their personal data against or in association
              with your login, or one or more property address(es) &nbsp;(<b>Contacts</b>);</p>
            <p>
              x. information that you have chosen to share with one or more other
              Registered User(s) about your property(ies), such as, the dream price
              for which you would consider selling your property(ies) (<b>Dream Price</b>),
              the number of other Registered Users who are tracking the status
              of your property(ies) on the MHV Platform (<b>Home Trackers</b>),
              and the number of other Registered Users who have expressed an
              interest in your property(ies) (<b>Expressed Interest</b>).
              You acknowledge and agree that, you will only use this
              functionality of the MHV Platform if you are the owner of the relevant
              property, and that, by using this functionality, you are not placing
              your property(ies) on the market, merely indicating a price at which
              you would consider selling your property(ies) should you be approached
              through the appropriate channels to negotiate the potential sale of
              your property(ies). The MHV Platform does not provide any means to
              enable you to lawfully engage in negotiations for the sale of your
              property(ies). You further acknowledge and agree that the MHV
              Platform, or MyHomeVault may share with one or more real estate agents
              the fact that you have recorded a Dream Price and that another
              Registered User has Expressed Interest in your property at that Dream
              Price (<b>Shared</b>);</p>
            <p>
              xi. reminder data concerning, for example, dates of significance in or
              for your property(ies), such as, home and contents insurance renewals,
              utilities payment due dates and strata levies due dates. The MHV
              Platform can be set up to send you reminders of dates of your
              choosing, which can be sent to you on the relevant date via push
              notification to, for example, your mobile device. You acknowledge and
              agree that, by using the Reminders function, the MHV Platform may send
              you notifications to one or more of your devices. You further
              acknowledge and agree that the Reminders functionality is not
              infallible and that you do not rely on the Reminders functionality
              other than as a guide, and that you will make your own independent
              arrangements to ensure that you meet relevant deadlines (<b>Reminders</b>);</p>
            <p>
              xii. any other data points in relation to you or the property(ies) against
              or in association with your, or someone else&rsquo;s, login which the
              MHV Platform makes available from time to time to a Registered User of
              this kind at MyHomeVault&rsquo;s sole and unfettered discretion;
              and</p>
            <p>
              xiii. any other personal information recorded against, or in association
              with, your login, including in relation to one or more property
              address(es).</p>
          </div>
          <p>b.&nbsp;
            <b>If you are a Registered User who chooses to make use of the MHV
              Platform in relation to one or more shared property(ies) of other
              Registered Users (&ldquo;Shared Properties&rdquo;), </b>
            other personal information of yours which may be provided/uploaded
            through or against, or in association with, your login or one or more
            property address(es), includes, but is not limited to, information in
            relation to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>i. the fact that you have become a Registered User;</p>
            <p>
              ii. the fact that another Registered User has elected to share with your
              login any of the data identified in clause 2.10 provided/uploaded
              through or against, or in association with, their login about
              property(ies) recorded against their login in the &ldquo;My
              Properties&rdquo; field;</p>
            <p>
              iii. one or more of your contact details, your profession/trade, details
              of your qualifications, including details of your license permitting
              you to practice in your profession/trade and any breadth or
              limitations on such license, the fact of (and possibly reason for)
              your interest in those specific property(ies), the content of any
              opinion/quotation that you give in relation to, or to do anything to
              or for, one or more of those property(ies);
            </p>
            <p>
              iv. any other personal information recorded against, or in association
              with, your login or one or more property address(es), including,
              information in relation to the property(ies) recorded against your
              login in the &ldquo;My Properties) field, as has been
              provided/uploaded through, or in association with, your login or with
              one or more property address(es);</p>
            <p>
              v. any other data points in relation to you, your, or someone
              else&rsquo;s property(ies) which the MHV Platform makes available from
              time to time to a Registered User of this kind at MyHomeVault&rsquo;s
              sole and unfettered discretion; and</p>
            <p>
              vi. any other personal information recorded against, or in association
              with, your login, including in relation to one or more property
              address(es).</p>
          </div>
          <p>c.&nbsp;
            <b>If you are a Registered User who chooses to make use of the MHV
              Platform in relation to one or more dream property of yours
              (&ldquo;Dream Properties&rdquo;),</b>&nbsp;other personal information of yours which may be
            provided/uploaded through or against, or in association with, your
            login or one or more property address(es), includes, but is not
            limited to, information in relation to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>i. the fact that you have become a Registered User;</p>
            <p>
              ii. the fact that you are interested in a property that is recorded in
              the Dream Properties field of your login. At the very least, this fact
              will be recorded against such property on the MHV Platform by
              increasing the tally of person(s) who is/are tracking that property.
              Such tally is made known to any Registered User against whose login
              that property is recorded in the &ldquo;My Properties&rdquo; field,
              and potentially to all other Registered Users;</p>
            <p>
              iii. The fact that you wish to express an interest in acquiring that
              property. At the very least, this fact will be recorded against such
              property as a person who has expressed such interest;</p>
            <p>
              iv. the capacity in which you are interested in the property, such as, an
              incoming homeowner, investor or developer;</p>
            <p>
              v. any other data points in relation to you, your, or someone
              else&rsquo;s, property(ies) which the MHV Platform makes available
              from time to time to a Registered User of this kind at
              MyHomeVault&rsquo;s sole and unfettered discretion; and</p>
            <p>
              vi. any other personal information recorded against, or in association
              with, your login, including in relation to one or more property
              address(es).</p>
          </div>
          <p>d. <b>
            If you are a registered User who chooses to make use of the MHV
            Platform in relation to more than one of the sub-paragraphs in this
            clause 2.10,</b>&nbsp;the other personal information of yours which may be
            provided/uploaded through or against, or in association with, your
            login or one or more property address(es), includes, but is not
            limited to, the cumulative information which is so
            provided/uploaded.
          </p>
        </div>
        <p>
          2.11. The MHV Platform is adapted to receive, store, use and process
          information provided/uploaded through Registered Users&rsquo; logins
          and through third party contractors about properties and
          professionals/tradespeople working in the real estate market. Insofar
          as any such information concerns or comes from, or is otherwise
          associated with, your login, it is Your MHV Platform Data as
          follows:.
        </p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>a.&nbsp;
            <b>If you are a Registered User who chooses to make use of the MHV
              Platform in relation to your property(ies) (&ldquo;My
              Properties&rdquo;), </b>information provided/uploaded through or against your login, or in
            relation to one or more property address(es) which includes, but is
            not limited to, information in relation to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>i. the fact that you have become a Registered User;</p>
            <p>ii. <b>Spaces</b>;</p>
            <p>iii. <b>Photos</b>;</p>
            <p>iv. <b>Videos</b>;</p>
            <p>v. <b>Tradespeople</b>;</p>
            <p>vi. <b>Contacts</b>;</p>
            <p>vii. <b>Shared</b>;</p>
            <p>
              vii. any other data points in relation to you, your, or someone
              else&rsquo;s, property(ies) which the MHV Platform makes available
              from time to time to a Registered User of this kind at
              MyHomeVault&rsquo;s sole and unfettered discretion; and</p>
            <p>
              viii. any other personal information recorded against, or in association
              with, your login, including in relation to one or more property
              address(es).</p>
          </div>
          <p>b.&nbsp;
            <b>If you are a Registered User who chooses to make use of the MHV
              Platform in relation to one or more shared property(ies) of other
              Registered Users (&ldquo;Shared Properties&rdquo;)</b>, information provided through or against your
            login which includes,
            but is not limited to, information in relation to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>i. the fact that you have become a Registered User;</p>
            <p>
              ii. the fact that Registered Users have elected to share with others&#39;
              the data recorded against their logins;</p>
            <p>
              iii. your profession/trade, details of your qualifications, including
              details of your license permitting you to practice in your
              profession/trade and any breadth or limitations on such license;</p>
            <p>
              iv. the fact of (and possibly reason for) Registered Users having
              interests in specific properties;</p>
            <p>
              v. any other data points in relation to you, your, or someone
              else&rsquo;s, property(ies) which the MHV Platform makes available
              from time to time to a Registered User of this kind at
              MyHomeVault&rsquo;s sole and unfettered discretion; and</p>
            <p>
              vi. any other personal information recorded against, or in association
              with, your login, including in relation to one or more property
              address(es)</p>
          </div>
          <p>c.&nbsp;
            <b>If you are a Registered User who chooses to make use of the MHV
              Platform in relation to one or more dream property of yours
              (&ldquo;Dream Properties&rdquo;), </b>information provided/uploaded through or against your login which
            includes, but is not limited to, information in relation to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>i. the fact that you have become a Registered User;</p>
            <p>
              ii. the number of Registered Users interested, or were interested, in a
              property that is recorded in the Dream Properties field of your
              login;;</p>
            <p>
              iii. the number of Registered Users who wish, or wished, to express an
              interest in acquiring a property; and</p>
            <p>
              iv. the capacity in which you are, or were, interested in a property,
              such as, an incoming homeowner, investor or developer;</p>
            <p>
              v. any other data points in relation to you, your, or someone
              else&rsquo;s, property(ies) which the MHV Platform makes available
              from time to time to a Registered User of this kind at
              MyHomeVault&rsquo;s sole and unfettered discretion; and</p>
            <p>
              vi. any other personal information recorded against, or in association
              with, your login, including in relation to one or more property
              address(es).
            </p>
          </div>
          <p>
            d. <b>If you are a registered User who chooses to make use of the MHV
            Platform in relation to more than one of the sub-paragraphs in this
            clause 2.11,</b>&nbsp;the other personal information of yours which may be
            provided/uploaded through or against, or in association with, your
            login or one or more property address(es), includes, but is not
            limited to, the cumulative information which is so
            provided/uploaded.
          </p>
        </div>
        <p>
          2.12. You warrant that all of Your Personal Information and all of your MHV
          Platform Data which you provide, otherwise make available, or have
          agreed (whether expressly or impliedly), pursuant to these Terms, to
          be provided/uploaded against your login, in association with your
          login, and/or in association with a property address, to MyHomeVault
          will always be accurate and/or has been provided, or otherwise made
          available, by you after you have obtained all relevant consent(s) from
          any third parties, and, for so long as you remain a Registered User,
          kept current by you.
        </p>
      </div>
      <span style={{ paddingTop: '1rem' }}>
        <p><b>3. How we use/disclose your information</b></p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            3.1. For so long as you are a Registered User, we may receive, use,
            store, process, and disclose to any third party with which MyHomeVault
            contracts or otherwise engages in association with the MHV Platform,
            or otherwise deal with, Your Personal Information and Your MHV
            Platform Data, in accordance with these Terms, including for
            MyHomeVault&rsquo;s commercial gain, and to assist you to make use of
            the MHV Platform in accordance with your status as a Registered User.
          </p>
          <p>
            3.2. At any time after you have cancelled your Visitor status or current
            Subscription Plan, or cancelled or deleted your login account (unless
            you become a Registered User again at another time, in which case
            clause 3.1 applies), we may receive, use, store, process, and disclose
            to any third party with which MyHomeVault contracts or otherwise
            engages in association with the MHV Platform, or otherwise deal with,
            Your Personal Information, other than the information defined in
            clause 2.2 (minimum personal details required), 2.10.a.iii (Items),
            2.10.a.vi (Documents), 2.10.a.vii (History), and 2.10.a.xi
            (Reminders), and Your MHV Platform Data, in accordance with these
            Terms, including for MyHomeVault&rsquo;s commercial gain.</p>
          <p>
            3.3. You acknowledge and agree that MyHomeVault has absolute and
            unfettered discretion as to with which third parties it chooses to
            contract or otherwise engage in association with the MHV
            Platform.</p>
          <p>
            3.4. You acknowledge and agree that MyHomeVault is free in law and equity
            to receive, use, store and process Your Personal Information and Your
            MHV Platform Data as set out in clauses 3.2 and 3.3, and further in
            any way that it sees fit, and you hereby provide your irrevocable
            consent for MyHomeVault to so use Your Personal Information and Your
            MHV Platform Data.</p>
          <p>
            3.5. We also use your contact details to send information relevant to you.
            This may include to:
          </p>
          <div style={{ paddingLeft: '2rem' }}>
            <p>
              a. contact you with important information about the MHV Platform and or
              your login or information we hold about you (for example, when
              relevant to your privacy or the security of your information);
              and</p>
            <p>
              b. send you general communications, materials or information relevant to
              the MHV Platform, and or your use of it.</p>
          </div>
        </div>
      </span>
      <p style={{ paddingTop: '1rem' }}><b>4. Your obligations as a Registered User</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>As a Registered User, you agree:</p>
        <p>
          a. to only use the MHV Platform for purposes that are permitted by these
          Terms, and strictly in accordance with any applicable law, regulation
          or generally accepted practices or guidelines in jurisdictions
          relevant to your access and use of the MHV Platform;</p>
        <p>
          b. that you have the sole responsibility for protecting the
          confidentiality of your login details, and for obtaining any relevant
          consents from persons in relation to whom you elect to provide/upload
          information through or against your login, or in association with any
          property address(es);</p>
        <p>
          c. that any use of your login details by any other person is strictly
          prohibited. Use of your login details by any other person may result
          in our immediately discontinuing your access and use of the MHV
          Platform;
        </p>
        <p>
          d. to immediately notify MyHomeVault of any unauthorised use of your
          login details or of any breach of security of which you have become
          aware;</p>
        <p>
          e. that your access and use of the MHV Platform is non-exclusive,
          limited and non-transferable;</p>
        <p>
          f. that you will not use the MHV Platform in connection with any
          commercial endeavours except where such endeavours are expressly
          endorsed and approved by MyHomeVault in writing;</p>
        <p>
          g. that you will not use the MHV Platform for any illegal and or
          unauthorised purpose, including, without limitation, the collection of
          personal information of any Registered User by electronic or other
          means for any purpose, such as, for the purpose of sending unsolicited
          email or unauthorised framing or hyperlinking from or to the MHV
          Platform, or for any other purpose whatsoever;</p>
        <p>
          h. that commercial advertisements, affiliate links and other forms of
          solicitation may be removed from the MHV Platform without notice and
          your use of them may result in our discontinuing your access and use
          of the MHV Platform. MyHomeVault reserves its rights to take
          appropriate legal action for any illegal or unauthorised use of the
          MHV Platform; and</p>
        <p>
          i. use any information you obtained, or procured the obtainment of, from
          the MHV Platform for any purpose, other than for purposes permitted by
          these Terms.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>5. Banned Uses</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          You are&nbsp;not allowed&nbsp;to use the MHV Platform:</p>
        <p>
          a. to participate in any illegal or fraudulent activity, including by
          uploading, referencing, posting or sending any fraudulent or illegal
          material in or in association with your login or another
          person&rsquo;s login, in any medium whatsoever;</p>
        <p>
          b. to cause disruption to, or otherwise interfere with the MHV Platform
          or its smooth operation;</p>
        <p>
          c. in a way that infringes a person&#39;s rights or prevents them from
          using the MHV Platform;</p>
        <p>
          d. in any way that may harass, cause distress or inconvenience
          others;</p>
        <p>
          e. to buy or sell real property or any other personal property, or to
          market such real property or personal property as being for purchase
          or sale;</p>
        <p>
          f. to upload, reference, post or send unlawful, defamatory, offensive or
          scandalous material in any medium whatsoever; or</p>
        <p>
          g. to upload, reference, post or send material that breaks, or
          encourages the breaking of, the law.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>6. Payment</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>6.1. Fees may be paid by way of:</p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>a. Electronic funds transfer into our nominated bank amount (<b>EFT</b>);</p>
          <p>b. Credit Card payment (<b>Credit Card</b>);</p>
          <p>c. PayPal payment (<b>PayPal</b>);</p>
          <p>d. SecurePay payment (<b>SecurePay</b>); or</p>
          <p>e. App Store or Google Play payment (<b>In-App Purchase Payment</b>),</p>
          <p>
            as made available in association with the MHV Platform, from time to
            time at MyHomeVault&rsquo;s absolute and unfettered discretion.
          </p>
        </div>
        <p>
          6.2. When making any payment in relation to your use of the MHV Platform,
          you warrant that you have read, understood and agree to be bound by
          the terms and conditions of the payment method you use.</p>
        <p>
          6.3. You acknowledge and agree that where a request for the payment of a
          Subscription Plan is returned or denied by the payment method you have
          chosen, or is unpaid by you for any other reason, then you are liable
          for any costs incurred by MyHomeVault, including banking fees and
          charges, associated with the failed attempt to obtain funds for that
          Subscription Plan.</p>
        <p>
          6.4. You agree and acknowledge that MyHomeVault can vary the costs for any
          Subscription Plan at any time and that the varied costs for such
          Subscription Plan will come into effect for you following the
          conclusion of the current period of your &nbsp;then current
          Subscription Plan, even if that Subscription Plan is set to roll over
          into a subsequent period.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>7. Refund Policy </b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          MyHomeVault does not provide any refunds under any circumstances.
        </p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>8. Copyright and Intellectual Property </b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          8.1. The MHV Platform and MHV Associated Products/Services are subject to
          copyright and other forms of intellectual property. The materials and
          content contained on the MHV Platform are protected intellectual
          property rights under the laws of Australia and through international
          treaties. &nbsp;Unless otherwise indicated, all rights (including
          copyright) in the MHV Platform and MHV Associated Products/Services
          (including, but not limited to, text, graphics, logos, button icons,
          photographs video images, audio clips, computer executing code,
          scripts, design elements and interactive features) are owned or
          licensed for these purposes, and are reserved by MyHomeVault or its
          licensors.</p>
        <p>
          8.2. All trade marks, service marks and or trade names are owned or
          licensed by MyHomeVault.
        </p>
        <p>
          8.3. MyHomeVault hereby grants you a non-exclusive revocable license
          whilst you are a Registered User to use the intellectual property
          rights identified in clauses 8.1 and 8.2 to the limited extent
          necessary to enable your use of the MHV Platform pursuant to the
          Terms, by:</p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            a. copying and storing the MHV Platform and the materials contained in
            the MHV Platform in your device&#39;s cache memory; and</p>
          <p>
            b. printing pages from the MHV Platform for your own personal and
            non-commercial use.</p>
        </div>
        <p>
          8.4. MyHomeVault does not grant you any other rights whatsoever in
          relation to the MHV Platform or the MHV Associated Products/Services.
          All other rights are expressly reserved by MyHomeVault.</p>
        <p>
          8.5. You hereby assign to MyHomeVault all right, title and interest in and
          to all intellectual property rights in any of Your Personal
          Information and any of Your MHV Platform Data, and agree to take all
          steps, including signing any document, that may be required to record
          this assignment in favour of MyHomeVault, and you hereby irrevocably
          provide MyHomeVault with your power of attorney to take any such steps
          on your behalf. You also hereby waive any moral rights (including the
          right of attribution of authorship, and the right of integrity) in
          favour of MyHomeVault that you may have in any of the copyright
          generated in, or in association with, your use of the MHV Platform at
          any time.</p>
        <p>
          8.6. MyHomeVault retains all rights, title and interest in and to the MHV
          Platform and all MHV Associated Products/Services, except in relation
          to one or more MHV Associated Products/Services which it licenses, in
          which case it retains all rights, title and interest in and to such
          licenses. &nbsp;Nothing you do on or in relation to the MHV Platform
          will transfer to you or any other &nbsp;person any business name,
          trading name, domain name, trade mark, industrial design, patent,
          registered design or copyright, or a right to use or exploit a
          business name, trading name, domain name, trade mark or industrial
          design, or a thing, system or process that is the subject of a patent,
          patent application, design application, registered design, copyright,
          or an adaptation or modification of such a thing, system or
          process.</p>
        <p>
          8.7. You may not, without the prior written consent of MyHomeVault,
          broadcast, republish, up-load to a third party, transmit, post
          distribute, show or play in public, adapt or change in any way the MHV
          Platform or any MHV Associated Products/Services for any purpose,
          unless otherwise provided by these Terms.</p>
        <p>
          8.8. MyHomeVault reserves all intellectual property rights in and
          associated with the MHV Platform and the MHV Associated
          Products/Services.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>
        9. Further limitations on your use of the MHV Platform and of MHV
        Associated Products/Services and risks that you accept</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          9.1. You may not rent, lease, lend, sell, redistribute or sublicense the
          MHV Platform or the MHV Associated Products/Services or any of the
          intellectual property rights in any of them. &nbsp;You may not copy
          (except as expressly permitted by this license), decompile, reverse
          engineer, disassemble, attempt to derive the source code of, modify,
          or create derivative works of the MHV Platform or the MHV Associated
          Products/Services, any updates, or any part thereof. Any attempt to do
          so is a violation of MyHomeVault&#39;s rights. &nbsp;If You breach
          this clause, you may be subject to prosecution and damages. These
          Terms govern any upgrades to the MHV Platform or to any MHV Associated
          Products/Services that replace and or supplement the MHV Platform or
          MHV Associated Product/Service, as the case may be, unless such
          upgrade is accompanied by a separate set of terms of use and access in
          which case those terms will govern your access and use of such
          upgrades.</p>
        <p>
          9.2. The MHV Platform uses Secure Sockets Layer (SSL) with a view to
          preserving the safety of data transferred in association with your use
          of it and it stores data in Amazon Web Services (AWS) cloud
          infrastructure. You acknowledge and agree that these systems, and the
          MHV Platform, are not infallible and may be subject to cyber security
          breaches. You further acknowledge and agree that if you upload
          information/data to the MHV Platform, including, without limitation,
          any of Your Personal Information or any of Your MHV Platform Data, you
          do so at your own risk, and hereby indemnify MyHomeVault and, as
          appropriate, any of its related bodies corporate or related entities,
          in relation to any damage, consequential or otherwise, that is caused
          to you under any circumstances whatsoever by your having uploaded any
          such information to the MHV Platform.
        </p>
        <p>
          9.3. You expressly acknowledge and agree that use of the MHV Platform and
          of any MHV Associated Products/Services is at your sole risk and that
          the entire risk as to the nature, quality and accuracy of Your
          Personal Information and Your MHV Platform Data on the MHV Platform is
          solely with you and for which you shall be held liable.
        </p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>10. Disclaimer and Limit of Liability - No Warranty</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          10.1. This is MyHomeVault&rsquo;s disclaimer of legal liability for the
          nature and or quality of any content provided/uploaded and or
          published by a Registered User or by us, including by one or more of
          our related bodies corporate or related entities, on or via the MHV
          Platform and or the reliability of the MHV Associated
          Products/Services.</p>
        <p>
          10.2. TO THE GREATEST EXTENT PERMITTED BY LAW, MYHOMEVAULT (INCLUDING EACH
          OF ITS RELATED BODIES CORPORATE AND RELATED ENTITIES), (A) DISCLAIM
          ALL IMPLIED WARRANTIES AND REPRESENTATIONS (INCLUDING BUT NOT LIMITED
          TO ACCURACY OF DATA, AND NONINFRINGEMENT); (B) DO NOT GUARANTEE THAT
          THE MHV PLATFORM, SSL OR AWS CLOUD INFRASTRUCTURE ADOPTED BY THE MHV
          PLATFORM OR ANY MHV ASSOCIATED PLATFORM/SERVICE WILL FUNCTION WITHOUT
          INTERRUPTION OR ERRORS, AND (C) PROVIDE THE MHV PLATFORM AND ANY MHV
          ASSOCIATED PLATFORM/SERVICE (INCLUDING CONTENT AND INFORMATION) ON AN
          &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS AS POSTED BY
          THE REGISTERED USERS OR US FROM TIME TO TIME. &nbsp;SOME LAWS DO NOT
          ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL OF THESE DISCLAIMERS MAY NOT
          APPLY TO YOU.</p>
        <p>10.3. <b>Exclusion of Liability</b></p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            a. This entire condition and any other limitations in these Terms do not
            apply to any Consumer Guarantee under the Australian Consumer Law
            (Schedule 2 of the Competition and Consumer Act 2010 (Cth))
            (&ldquo;<b>Australian Consumer law</b>&rdquo;) or to any of our liability for failure to comply with a
            Consumer Guarantee under the Australian Consumer Law.
          </p>
          <p>
            b. To the extent permitted under law (and unless we have entered into a
            separate written agreement that supersedes these Terms, we, including
            our related bodies corporate and related entities, shall not be liable
            to you or others for any indirect, incidental, special, consequential
            or punitive damages, or any loss of data, opportunities, reputation,
            profits or revenues, related to the MHV Platform or any MHV Associated
            Products/Service (including but not limited to offensive or defamatory
            statements, down time or loss, use or changes to your information or
            content).</p>
          <p>
            c. In any event, at all times, the liability of MyHomeVault shall not
            exceed, in aggregate for all claims, an amount that is the most recent
            Subscription Plan fee that you paid, or, if you are a Visitor, the
            lowest Subscription Plan fee that you could pay to become a
            Subscriber. &nbsp;This limitation of liability is part of the basis of
            the agreement between you and us and shall apply to all claims of
            liability (including but not limited to tort, negligence, contract, or
            any other claim in law or equity).</p>
          <p>
            d. MyHomeVault does not warrant or make any representations that use of
            the MHV Platform or any MHV Associated Products/Services will be
            uninterrupted or error, bug or virus free or that the contents will be
            correct, accurate, reliable or that MyHomeVault or the servers and
            applications that makes it available are free from viruses or other
            harmful components. MyHomeVault does not warrant or make any
            representations and expressly disclaims all liability and
            responsibility regarding the use of or reliance upon information
            published via the MHV Platform or on any MHV Associated
            Products/Services in terms of accuracy, reliability or
            otherwise.</p>
          <p>
            e. We do not warrant, guarantee or make any representation regarding the
            accuracy, adequacy, reliability, completeness or timeliness of the
            data that is available, or is processed, on MHV Platform or on any MHV
            Associated Products/Services, or that the MHV Platform or any MHV
            Associated Product/Service is suitable for your intended use.
          </p>
          <p>
            f. We do not warrant or represent that the data that is available, or is
            processed, on the MHV Platform or on any MHV Associated
            Products/Services is free from human, computer or mechanical error,
            technical inaccuracies or other typographical errors or defects.
            &nbsp;The use of the MHV Platform or of any of the MHV Associated
            Products/Services and the data that is available, or is processed, by
            any of them, is at your own risk.</p>
          <p>
            g. We make no warranties, guarantees or representations that the data
            that is available, or is processed on the MHV Platform or on any MHV
            Associated Products/Services will not cause damage or that the
            material is free from any computer virus or other defects. &nbsp;It is
            your responsibility to complete a virus check on any data which you
            download or use in association with the MHV Platform or with any MHV
            Associated Products/Services.</p>
          <p>
            h. All warranties, representations and statutory guarantees and any
            liability which may arise in relation to your access to or use of the
            MHV Platform or of any of the MHV Associated Products/Services or any
            data that is available, or is processed, by any of them, is expressly
            excluded to the maximum extent permitted by law.</p>
        </div>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>11. Third Party Transactions or transactions between Registered Users</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          11.1. MyHomeVault does not take responsibility for any contracts,
          agreements and/or deals verbal or written entered/agreed upon between
          any third party/parties and Registered Users, or between two or more
          Registered Users, on or in association with the MHV Platform. All such
          third party contracts, agreements and/or deals entered into by you are
          your sole responsibility.</p>
        <p>
          11.2. MyHomeVault may associate itself with one or more real estate agents,
          tradespersons or other persons claiming to provide a good or service,
          in the real estate industry. Any contracts or engagements between
          MyHomeVault and any such persons are on an arm&rsquo;s-length
          basis.</p>
        <p>11.3. <b>Real Estate Agents and Tradespeople</b></p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            a. MyHomeVault reserves the right to vet all individuals and entities
            claiming to be a &lsquo;Real Estate Agent&rsquo;,
            &lsquo;Tradesperson&rsquo;, or other goods or services provider in the
            real estate industry, including to require such individuals or
            entities to produce documentation necessary to verify their
            authorisation/license to provide the goods or services claimed.</p>
          <p>
            b. MyHomeVault reserves the right to reject any application to become a
            Registered User by an individual or entity claiming to be a
            &lsquo;Real Estate Agent&rsquo;, &lsquo;Tradesperson&rsquo;, or other
            goods or services provider in the real estate industry in
            circumstances where such individual or entity refuses, or is unable,
            to produce documentation necessary to verify their
            authorisation/license to provide the goods or services claimed.</p>
        </div>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>12. Competitors</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          If you are in the business of providing similar goods services to that
          of, or associated with, the MHV Platform to users for a commercial gain,
          then you are a competitor of MyHomeVault. Competitors are not permitted
          to use or access any information or content on or from the MHV Platform.
          If you breach this provision, MyHomeVault will hold you responsible for
          any loss and damage sustained by it (including any of its related bodies
          corporate or related entities) consequent on your access and use of the
          MHV Platform.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>13. Termination of Contract</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          13.1 The Terms will continue to apply until terminated by either you or by
          MyHomeVault as set out Here:</p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            a. You may terminate these Terms without cause by cancelling your
            current Subscription Plan, or cancelling or deleting your login
            account;</p>
          <p>
            b. We may terminate these Terms with you with or without cause by
            cancelling your current Subscription Plan, or requiring you to cancel
            or delete your login account. If we require you to cancel or delete
            your login account, then you must do so as soon as reasonably
            practicable following receipt of our informing you of that
            requirement.</p>
        </div>
        <p>
          13.2. We reserve the right to limit your use of the MHV Platform. &nbsp;We
          reserve the right to restrict, suspend, or terminate your login if we
          believe that you may be in breach of this Agreement or law or are
          misusing the MHV Platform or in any way breaching any of the Terms.
          Our right to so limit, restrict, suspend, or terminate your login,
          extends to circumstances in which we, in our absolute and unfettered
          discretion, consider to be necessary.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>14. Indemnity</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          You indemnify MyHomeVault, including each of its related bodies
          corporate, related entities, and its and their employees, agents,
          contributors, third party contractors and licensors from and
          against:</p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            a. all actions, suits, claims, demands, liabilities, costs, expenses,
            loss and damage (including legal fees on a full indemnity basis)
            incurred, suffered or arising out of or in connection with your use or
            the MHV Platform and or any MHV Associated Products/Services;</p>
          <p>
            b. any direct or indirect consequences of you accessing or using the MHV
            Platform or any MHV Associated Products/Services or attempts to do so;
            and or</p>
          <p>
            c. any breach by you of any of these Terms, or for any circumstance
            otherwise provided in these Terms.</p>
        </div>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>15. Dispute Resolution</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p><b>15.1. Compulsory</b></p>
        <p>
          If a dispute arises out of or relating to the Terms, neither party may
          commence any Tribunal or Court proceedings in relation to the dispute,
          unless the following clauses have been complied with (except where
          urgent interlocutory relief is sought).</p>
        <p><b>15.2. Notice</b></p>
        <p>A party to the Terms claiming a dispute (<b>Dispute</b>) has arisen under the Terms, must give written notice
          to the other
          party detailing the nature of the dispute, the desired outcome and the
          action required to settle the Dispute.</p>
        <p><b>15.3. Resolution</b></p>
        <div style={{ paddingLeft: '2rem' }}>
          <p>
            On receipt of that notice given pursuant to clause 15.2 (<b>Notice</b>)
            by that other party, the parties to the Terms must:</p>
          <p>
            a. Within 10 days of the Notice endeavor in good faith to resolve the
            Dispute expeditiously by negotiation or such other means upon which
            they may mutually agree;</p>
          <p>
            b. If for any reason whatsoever, 10 days after the date of the Notice,
            the Dispute has not been resolved, the parties must either agree upon
            selection of a mediator or request that an appropriate mediator be
            appointed by the Law Society of New South Wales;</p>
          <p>
            c. The parties are equally liable for the fees and reasonable expenses
            of a mediator and the cost of the venue of the mediation and without
            limiting the foregoing undertake to pay any amounts requested by the
            mediator as a pre-condition to the mediation commencing. The parties
            must each pay their own costs associated with the mediation;</p>
          <p>
            d. The mediation will be held in New South Wales, Australia.</p>
        </div>
        <p><b>15.4. Confidential</b></p>
        <p>
          All communications concerning negotiations made by the parties arising
          out of and in connection with this dispute clause 15 are confidential
          and, to the greatest extent possible, must be treated as &#39;without
          prejudice&#39;.</p>
        <p><b>15.5. Termination of Mediation</b></p>
        <p>
          If 21 days have elapsed after the start of a mediation and the Dispute
          has not been resolved, either party may ask the mediator to terminate
          the mediation and the mediator must do so. If that occurs, either party
          may proceed to resolve the dispute in a court of competent
          jurisdiction.</p>
        <p><b>15.6. Venue and Jurisdiction</b></p>
        <p>
          The MHV Platform is intended to be accessed and used in all Australian
          States and Territories. However, in the event of any dispute arising out
          of or in relation to the MHV Platform, you and we agree that the
          exclusive jurisdiction for resolution of any such dispute are the courts
          of New South Wales, Australia.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>16. INTERRUPTIONS AND FORCE MAJEURE</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          MyHomeVault will make reasonable efforts to keep the MHV Platform
          operational, however technical difficulties or maintenance may, from
          time to time, result in temporary and potentially prolonged
          interruptions. MyHomeVault reserves the right, at any time, to modify or
          temporarily suspend the MHV Platform or any of the functions or features
          on the MHV Platform, without notice, and without liability to you for
          any interruption, modification or suspension of the MHV Platform or of
          any function or feature thereof. MyHomeVault will not be liable for any
          problems or failures caused by circumstances outside MyHomeVault&rsquo;s
          control, including but not limited to power outages, surges, data loss
          and failure from third party software that may occur whilst MyHomeVault
          is performing its obligations under these Terms, acts of war, pandemics,
          epidemics, industrial strikes, and any other like circumstance which
          would prevent MyHomeVault from performing its obligations under these
          Terms.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>17. Notices and Service Messages</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          You agree that we may provide notices to you in the following ways: (1)
          a banner notice in or in association with the MHV Platform, or (2) an
          email sent to an email address you provided or have used in association
          with becoming a Registered User, or (3) through other means including
          mobile number, telephone, or mail. You agree to keep current Your
          Personal Information provided pursuant to clause 2.2 up to date.
          &nbsp;You consent to receive periodic administrative messages from us in
          connection with your use of the MHV Platform.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>18. Links to Other Sites</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          18.1. The MHV Platform may contain links to or from other external websites
          not operated by us (<b>Linked Sites</b>). &nbsp;The Linked Sites are not under MyHomeVault&rsquo;s control
          and we are not responsible for the content of or transmissions from
          any linked site. &nbsp;By using a link to an external website, you
          accept the risk of any viruses, Trojan horses or worms associated with
          that website and/or any other negative impact that such use may have
          upon any device of yours or upon your MHV Platform login, and any data
          associated with it anyway. &nbsp;
        </p>
        <p>
          18.2. Unless otherwise specified, the Linked Sites may have different terms
          of use and privacy requirements. &nbsp;Unless otherwise specified, we
          are not responsible for the content of any Linked Sites, or any
          changes or updates to such sites. &nbsp;Unless specified, we do not
          sponsor, endorse, adopt, confirm, guarantee or approve any material or
          representations made in any Linked Sites, or indeed in any site not
          controlled by us.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>19. Governing Laws</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          The Terms are governed by the Laws of New South Wales, Australia. Any
          dispute, controversy, proceeding or claim of whatever nature arising out
          of or in any way relating to the Terms and the rights created hereby
          shall be governed, interpreted and construed by, under and pursuant to
          the laws of New South Wales, Australia, without reference to conflict of
          law principles. The validity of this governing law clause is not
          contested. The Terms shall be binding to the benefit of the parties
          hereto and their successors and assigns.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>20. Independent Legal Advice</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          The parties confirm and declare that the provisions of the Terms are
          fair and reasonable &nbsp;that they have been given the opportunity to
          obtain independent legal advice and that the Terms are not against
          public policy on the grounds of inequality or bargaining power or
          general grounds of restraint of trade.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>21. Severance</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          If any part of these Terms are found to be void and unenforceable by a
          court of competent jurisdiction, that part shall be severed, and the
          rest of the Terms shall remain in force.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>22. Entire agreement</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          These Terms constitute the entire agreement between us and you in
          connection with your access and use of the MHV Platform.</p>
      </div>
      <p style={{ paddingTop: '1rem' }}><b>23. How to Contact Us</b></p>
      <div style={{ paddingLeft: '2rem' }}>
        <p>
          If you want to send us notices or service of process, please contact us:
        </p>
        <p>
          At: <a href="https://www.myhomevault.com.au" target="_blank" rel="noreferrer"
                 style={{ lineBreak: 'anywhere' }}>https://www.myhomevault.com.au</a>; AND fill in
          the contact information form.
        </p>
        <p>
          By physical mail at: Attention: MyHomeVault PTY LTD, Level 11, 80 Mount Street, North Sydney NSW 2060,
          Australia
        </p>
      </div>
    </Container>
  )
}
