import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, AlertBox } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { resetError } from 'venus/redux/features/share'
import { CopyLink, Create, Select, ShareTo } from 'venus/pages/Share'

interface IShare {
  onClose: () => void
  isOpen: boolean
  initStep?: number
  propertyId?: string
  spaceId?: string
  itemId?: string
}

export const Share = ({
  onClose,
  isOpen,
  initStep = 1,
  propertyId,
  spaceId,
  itemId,
}: IShare) => {
  const dispatch = useAppDispatch()
  const {
    propertyId: propertyIdParam,
    spaceId: spaceIdParam,
    itemId: itemIdParam,
  } = useParams()
  const { error } = useAppSelector((state) => state.share)
  const { property } = useAppSelector((state) => state.property)
  const { propertyWideSpaceId } = property || {}
  const shareTitle = 'Share'
  const type = !!itemId
    ? 'item'
    : !!spaceId && spaceId !== propertyWideSpaceId
    ? 'space'
    : 'property'
  const [step, setStep] = useState(initStep)
  const [title, setTitle] = useState(shareTitle)
  const [isShareDocument, setIsShareDocument] = useState(false)
  const [isShareHistory, setIsShareHistory] = useState(false)
  const [isShareWholeProperty, setIsShareWholeProperty] = useState(type === 'property')
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [eventName, setEventName] = useState('')
  const [notes, setNotes] = useState('')
  const [isSharedAnonymously, setIsSharedAnonymously] = useState(true)
  const [isGenerateQRCode, setIsGenerateQRCode] = useState(false)

  const titleArr = ['Share', 'Share Details', 'Share via URL', 'Share To']

  const handlPressAlertOK = () => {
    dispatch(resetError())
  }

  const handleGoBack = () => {
    setTitle(titleArr[step - 2])
    setStep(step - 1)
  }

  const handleClose = () => {
    setIsShareDocument(false)
    setIsShareHistory(false)
    setIsShareWholeProperty(type === 'property')
    setCheckedItemIds([])
    setEventName('')
    setNotes('')
    setIsSharedAnonymously(true)
    setIsGenerateQRCode(false)
    setTitle(titleArr[0])
    setStep(1)
    onClose()
  }

  const onBack = useMemo(() => {
    if (step === 3) {
      return null
    }
    return step !== 1 ? handleGoBack : null
  }, [step])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        closeOnClickOutside={false}
        title={title}
        onBack={onBack}
      >
        <>
          {step === 1 && (
            <Select
              setStep={setStep}
              setTitle={setTitle}
              propertyId={propertyId || propertyIdParam}
              spaceId={spaceId || spaceIdParam}
              itemId={itemId || itemIdParam}
              isShareDocument={isShareDocument}
              setIsShareDocument={setIsShareDocument}
              isShareHistory={isShareHistory}
              setIsShareHistory={setIsShareHistory}
              isShareWholeProperty={isShareWholeProperty}
              setIsShareWholeProperty={setIsShareWholeProperty}
              checkedItemIds={checkedItemIds}
              setCheckedItemIds={setCheckedItemIds}
              type={type}
            />
          )}
          {step === 2 && (
            <Create
              setStep={setStep}
              setTitle={setTitle}
              eventName={eventName}
              setEventName={setEventName}
              notes={notes}
              setNotes={setNotes}
              isSharedAnonymously={isSharedAnonymously}
              setIsSharedAnonymously={setIsSharedAnonymously}
              isGenerateQRCode={isGenerateQRCode}
              setIsGenerateQRCode={setIsGenerateQRCode}
            />
          )}
          {step === 3 && <CopyLink />}
          {step === 4 && <ShareTo onClose={handleClose} setStep={setStep} />}
        </>
      </Modal>
      <AlertBox
        cancel={handlPressAlertOK}
        title={`${title} Error`}
        message={error}
        btnGroup={[
          {
            text: 'OK',
            onClick: handlPressAlertOK,
            color: 'Primary',
            type: 'error',
          },
        ]}
        showAlert={!!error}
      />
    </>
  )
}
