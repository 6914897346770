import styled from 'styled-components'

export const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  width: 100%;
  border-radius: 8px;
`

export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
`
export const BackBtnWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 30px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: scroll;
  margin: 0 20px;
`
