import ReactModal from 'react-modal'
import { Button, Text } from 'venus/components'
import { Body, Content, Footer, Header, Link, StatusCircle } from './PaymentResult.styles'
import { ReactComponent as Cross } from 'venus/assets/img/icons/Cross.svg'
import { theme } from 'venus/config'
import { useNavigate } from 'react-router-dom'

const Failure = () => {
  const navigate = useNavigate()

  const goToHomePage = () => {
    navigate('/', { replace: true })
  }

  return (
    <ReactModal
      isOpen
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 12,
          background: 'none',
        },
        content: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0,0,0,0.6)',
          border: 0,
          padding: 0,
          height: '100%',
          width: '100%',
        },
      }}
    >
      <Content height={400}>
        {/* <Loader style={{ marginTop: 40 }} /> */}
        <Header>
          <Text format='h2'>Purchase Unsuccessful</Text>
        </Header>
        <Body style={{ paddingBottom: 24 }}>
          <StatusCircle color={theme.colors.ErrorState}>
            <Cross color={theme.colors.White} />
          </StatusCircle>
          <Text format='body' style={{ width: 312, textAlign: 'center' }}>
            We were unable to process your payment. Please update your biling infomation.
          </Text>
          <Button
            type='primary'
            text='Back to dashboard'
            fullBlock
            onClick={goToHomePage}
          />
        </Body>
        <Footer>
          <Text
            color={theme.colors.DarkestGrey}
            format='body'
            fontSize={12}
            lineHeight={1.5}
          >
            Need Help? Visit our support site <Link>here</Link> or <Link>email us</Link> and
            we will get right back to you.
          </Text>
        </Footer>
      </Content>
    </ReactModal>
  )
}

export default Failure
