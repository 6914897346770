import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'
import { ICreateContactArgs } from './types'

export const createContact = createAsyncThunk(
  'contact/createContact',
  async (
    { body, propertyId }: { body: ICreateContactArgs; propertyId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(`property/${propertyId}/contacts`, body)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  }
)

export const getContactList = createAsyncThunk(
  'contact/getContactList',
  async (propertyId: string, { rejectWithValue }) => {
    try {
      console.log('url::', `property/${propertyId}/contacts`)
      const response = await api.get(`property/${propertyId}/contacts`)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const deleteContact = createAsyncThunk(
  'contact/deleteContact',
  async (
    {
      propertyId,
      contactId,
    }: {
      propertyId: string
      contactId: string | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.delete(
        `property/${propertyId}/contact/${contactId}`
      )
      console.log('deleteContact::', response.data)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  }
)

export const updateContact = createAsyncThunk(
  'contact/updateContact',
  async (
    {
      body,
      propertyId,
      contactId,
    }: {
      body: any
      propertyId: string
      contactId: string | undefined
    },
    { rejectWithValue }
  ) => {
    try {
      console.log(
        'updateContact url::',
        `property/${propertyId}/contact/${contactId}`
      )
      const response = await api.put(
        `property/${propertyId}/contact/${contactId}`,
        body
      )
      console.log('updateContact::response.data', response.data)
      return response.data
    } catch (err: any) {
      console.log('err in reject', err)
      return rejectWithValue(err.data)
    }
  }
)
