import { useState } from "react"
import { PriceInput, Text } from "venus/components"

export const PriceInputSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [value, setValue] = useState(0)

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <div style={{ width: 500 }}>
        <PriceInput
          label="Price"
          value={value}
          onChange={setValue}
          style={{ margin: 18 }}
        />
        <PriceInput
          label="Price"
          value={value}
          onChange={setValue}
          style={{ margin: 18 }}
          isError
          errorMsg="This is error state."
        />
      </div>
    </>
  )
}
