import styled from 'styled-components'
import { Text } from 'venus/components'
import { ReactComponent as Bath } from 'venus/assets/img/property/Bath.svg'
import { ReactComponent as Bed } from 'venus/assets/img/property/Bed.svg'
import { ReactComponent as Garage } from 'venus/assets/img/property/Garage.svg'
import { formatPriceWithDollarSign, formateDate } from 'venus/utils'
import { format } from 'date-fns'
import { theme } from 'venus/config'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

type CoverImageProps = {
  readonly image?: string
}

const CoverImage = styled.div<CoverImageProps>`
  position: relative;
  height: 90px;
  aspect-ratio: 1.58;
  border-radius: 8px;
  overflow: hidden;

  background: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;

  background-color: ${(props) => props.theme.colors.Grey};
`

const PriceBanner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 16px;
  background-color: ${(props) => props.theme.colors.Primary};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Comparable = ({
  imageBaseUrl,
  address,
  suburb,
  beds,
  baths,
  parking,
  area,
  date,
  price,
}) => {
  return (
    <Container>
      <CoverImage image={`${imageBaseUrl}.jpg`}>
        <PriceBanner>
          <Text
            style={{ lineHeight: '12px' }}
            format='body'
            fontSize={12}
            fontWeight={500}
            color='White'
          >
            {formatPriceWithDollarSign(Math.round(price))}
          </Text>
        </PriceBanner>
      </CoverImage>
      <Info>
        <Row>
          <Bed width={20} height={18} color={theme.colors.DarkGrey} />
          <Text
            format='body'
            style={{ marginLeft: 4, marginRight: 20 }}
            color={theme.colors.DarkGrey}
          >
            {beds}
          </Text>

          <Bath width={20} height={18} color={theme.colors.DarkGrey} />
          <Text
            format='body'
            style={{ marginLeft: 4, marginRight: 20 }}
            color={theme.colors.DarkGrey}
          >
            {baths}
          </Text>

          {parking > 0 && (
            <>
              <Garage width={20} height={18} color={theme.colors.DarkGrey} />
              <Text
                format='body'
                style={{ marginLeft: 4, marginRight: 20 }}
                color={theme.colors.DarkGrey}
              >
                {parking}
              </Text>
            </>
          )}

          {area > 0 && (
            <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
              <Text format='body' style={{ marginLeft: 4 }} color={theme.colors.DarkGrey}>
                {area}sqm
              </Text>
            </Row>
          )}
        </Row>
        <Row style={{ margin: '10px 0' }}>
          <Text format='body' fontSize={14} fontWeight={600}>
            {address} {suburb}
          </Text>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Text format='body' fontSize={14} fontWeight={500} color='DarkGrey'>
            Sold on
          </Text>
          <Text format='body' fontSize={14} fontWeight={600}>
            {format(new Date(date), 'MMM yyyy')}
          </Text>
        </Row>
      </Info>
    </Container>
  )
}

export default Comparable
