import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px 20px;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.Grey};
`

export const PlanRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Renew = styled.div`
  width: 300px;

  margin: 0 auto;
  margin-top: 27px;

  text-align: center;
`

export const CreditContainer = styled.div`
  display: flex;

  margin: 20px 0;
  background-color: ${(props) => props.theme.colors.LightBlue};
  border-radius: 8px;
  overflow: hidden;
  padding: 16px 20px;
`

type SectionProps = {
  readonly flex: number
}
export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${(props) => props.flex};
`

export const LinkButton = styled.button`
  width: 100%;
  padding: 16px;
  background: none;
  outline: none;
  border: 0;

  cursor: pointer;
`
