import api from 'venus/api'
import ReactCodeInput from 'react-code-input'
import { useState } from 'react'
import { Container } from 'venus/layout/SignUp/MobileValidation/EnterMobile.styles'
import { Text } from 'venus/components'
import { Button } from 'venus/components'
import { ENTER_MOBILE_CODE_MSG } from 'venus/layout/SignUp/Constants'
import { theme } from 'venus/config'
import { LinkButton } from 'venus/components'
import { Notification } from 'venus/components'
import { RESEND_MOBILE_CODE_MSG } from 'venus/layout/SignUp/Constants'
import { InputArea } from 'venus/layout/SignUp/MobileValidation/MobileValidation.styles'

const MobileValidation = ({
  mobile,
  onSuccess,
  style,
}: {
  mobile: string
  onSuccess: Function
  style?: {}
}) => {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [showNotification, setNotification] = useState(false)

  const handleOnValidate = async () => {
    try {
      if (!code) return
      setLoading(true)
      const response = await api.post(`/auth/validate-mobile`, {
        mobile,
        code,
      })
      if (response?.status === 200 && response?.data?.mobileValidationCode) {
        return onSuccess(response?.data?.mobileValidationCode)
      }
    } catch (err) {
      return err
    } finally {
      setLoading(false)
    }
  }

  const handleOnResendCode = async () => {
    try {
      setLoading(true)
      const response = await api.post(`/auth/send-mobile-code`, { mobile })
      if (response?.status === 200) {
        console.log('re-send: response::', response)
        setNotificationMsg(RESEND_MOBILE_CODE_MSG)
        setNotification(true)
      }
    } catch (err) {
     return err
    } finally {
      setLoading(false)
    }
  }

  const handleCloseNotification = () => {
    setNotification(false)
  }

  return (
    <Container style={style}>
      <Notification
        showAlert={!!notificationMsg.length && showNotification}
        closeAlert={handleCloseNotification}
        message={notificationMsg}
      />
      <InputArea>
        <Text format={'body'} value={`${ENTER_MOBILE_CODE_MSG} ${mobile}`} />
        <LinkButton
          handlePress={handleOnResendCode}
          label='Resend code'
          format='body'
          color={theme.colors.Secondary}
          style={{
            margin: '12px',
          }}
        />
        <ReactCodeInput
          type='number'
          fields={4}
          name='mobile'
          inputMode='numeric'
          value={code}
          onChange={setCode}
          inputStyle={{
            borderRadius: '6px',
            border: '1px solid lightgrey',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
            margin: '6px',
            paddingLeft: '12px',
            width: '48px',
            height: '56px',
            fontSize: '32px',
            boxSizing: 'border-box',
            color: 'black',
            backgroundColor: 'white',
          }}
        />
      </InputArea>
      <Button
        type={'primary'}
        text={'Next'}
        isDisabled={!code}
        isLoading={loading}
        onClick={handleOnValidate}
      />
    </Container>
  )
}

export default MobileValidation
