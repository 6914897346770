import React from "react"
import { theme } from "venus/config"

interface ILogo {
  size?: number
  color?: string
  style?: any
  isWithText?: boolean
}

export const Logo = ({
  size = 64,
  color = theme.colors.Secondary,
  style = {},
  isWithText = false,
}: ILogo) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {!isWithText && (
        <svg
          width={size / 2}
          height={size / 2}
          viewBox="0 0 74 108"
          fill="none"
        >
          <path
            d="M9.11292 56.849C9.06952 41.9464 9.11292 39.974 9.11292 37.914C9.11292 21.7841 21.8276 9.4237 37.0592 9.4237C52.2908 9.4237 64.4847 22.5292 64.4847 38.6591C64.4847 41.5081 64.4847 43.7873 64.4847 46.6802L73.5108 51.0195C73.5108 46.987 73.5108 42.7792 73.5108 38.6591C73.5108 17.3133 57.2378 0 37.0592 0C16.924 0 0 17.4886 0 38.7906C0 42.0341 0 45.8912 0 61.6266L9.11292 56.849Z"
            fill={color}
          />
          <path
            d="M36.712 37.0812L0 54.1753V64.3003L36.712 46.8994L64.2244 62.6347V98.7078H63.0527H48.6022H45.6948V108H48.6022H63.0527H73.4675V56.5861L36.712 37.0812Z"
            fill={color}
          />
          <path
            d="M31.0273 98.7078H20.1352H9.2431V65.7906L0 70.3929V108H67.6525V98.7078H31.0273Z"
            fill={color}
          />
        </svg>
      )}
      {isWithText && (
        <svg width="187" height="108" viewBox="0 0 187 108" fill="none">
          <g clip-path="url(#clip0_647_58702)">
            <path
              d="M9.11292 56.849C9.06952 41.9464 9.11292 39.974 9.11292 37.914C9.11292 21.7841 21.8276 9.4237 37.0592 9.4237C52.2908 9.4237 64.4847 22.5292 64.4847 38.6591C64.4847 41.5081 64.4847 43.7873 64.4847 46.6802L73.5108 51.0195C73.5108 46.987 73.5108 42.7792 73.5108 38.6591C73.5108 17.3133 57.2378 0 37.0592 0C16.924 0 0 17.4886 0 38.7906C0 42.0341 0 45.8912 0 61.6266L9.11292 56.849Z"
              fill="#49C3CA"
            />
            <path
              d="M112.698 45.9354L112.655 32.3039L106.319 43.5247H104.062L97.7267 32.5669V45.9354H93.04V23.187H97.2059L105.321 37.3445L113.305 23.187H117.428L117.471 45.9354H112.698Z"
              fill="#100D42"
            />
            <path
              d="M138.736 28.4464L131.229 46.987C130.447 49.0033 129.536 50.4059 128.408 51.2386C127.28 52.0714 125.934 52.4659 124.329 52.4659C123.461 52.4659 122.593 52.3344 121.769 52.0276C120.901 51.7646 120.25 51.3701 119.686 50.8442L121.465 47.25C121.855 47.6007 122.246 47.8636 122.767 48.0828C123.244 48.2581 123.721 48.3896 124.199 48.3896C124.85 48.3896 125.414 48.2143 125.804 47.8636C126.238 47.513 126.585 46.987 126.932 46.1981L126.976 46.0227L119.772 28.4026H124.763L129.406 40.3247L134.093 28.4464H138.736Z"
              fill="#100D42"
            />
            <path
              d="M112.87 54.0444V76.7928H107.837V67.5005H97.986V76.8366H92.9521V54.0882H97.986V63.0736H107.837V54.0882H112.87V54.0444Z"
              fill="#100D42"
            />
            <path
              d="M120.857 75.9162C119.468 75.1272 118.383 74.0753 117.602 72.7165C116.821 71.3577 116.431 69.8236 116.431 68.0704C116.431 66.3171 116.821 64.7831 117.602 63.4243C118.383 62.0655 119.468 61.0136 120.857 60.2246C122.246 59.4357 123.808 59.085 125.587 59.085C127.323 59.085 128.928 59.4795 130.274 60.2246C131.662 61.0136 132.747 62.0655 133.528 63.4243C134.309 64.7831 134.7 66.3171 134.7 68.0704C134.7 69.8236 134.309 71.3577 133.528 72.7165C132.747 74.0753 131.662 75.171 130.274 75.9162C128.885 76.7051 127.323 77.0558 125.587 77.0558C123.808 77.0558 122.246 76.7051 120.857 75.9162ZM128.625 71.5769C129.406 70.7003 129.796 69.5168 129.796 68.0704C129.796 66.5801 129.406 65.4405 128.625 64.5639C127.844 63.6873 126.802 63.249 125.587 63.249C124.329 63.249 123.33 63.6873 122.506 64.5639C121.681 65.4405 121.291 66.624 121.291 68.0704C121.291 69.5606 121.681 70.7003 122.506 71.5769C123.33 72.4535 124.329 72.8918 125.587 72.8918C126.802 72.8918 127.844 72.4535 128.625 71.5769Z"
              fill="#100D42"
            />
            <path
              d="M164.208 61.0132C165.423 62.2843 166.031 64.2567 166.031 66.799V76.7925H161.214V67.5441C161.214 66.1415 160.954 65.1334 160.39 64.4321C159.826 63.7308 159.044 63.424 158.046 63.424C156.918 63.424 156.007 63.8184 155.356 64.5636C154.705 65.3525 154.358 66.4921 154.358 67.9824V76.7486H149.541V67.5003C149.541 64.7389 148.499 63.3801 146.373 63.3801C145.245 63.3801 144.377 63.7746 143.726 64.5197C143.075 65.3087 142.728 66.4483 142.728 67.9386V76.7048H137.911V59.2162H142.511V61.2324C143.119 60.4873 143.9 59.9175 144.768 59.523C145.679 59.1285 146.634 58.9532 147.718 58.9532C148.89 58.9532 149.975 59.2162 150.886 59.6983C151.841 60.1804 152.579 60.8817 153.186 61.846C153.88 60.9256 154.748 60.2243 155.79 59.7421C156.831 59.26 157.96 58.997 159.218 58.997C161.344 59.0408 162.993 59.6983 164.208 61.0132Z"
              fill="#100D42"
            />
            <path
              d="M186.814 69.5165H174.229C174.446 70.6123 175.01 71.4451 175.835 72.1026C176.659 72.7162 177.744 73.0668 179.003 73.0668C179.871 73.0668 180.652 72.9354 181.303 72.6724C181.954 72.4094 182.605 71.9711 183.169 71.4013L185.729 74.338C184.167 76.2227 181.867 77.187 178.873 77.187C177.007 77.187 175.314 76.7925 173.882 76.0474C172.45 75.2584 171.322 74.2065 170.541 72.8477C169.76 71.4889 169.369 69.9548 169.369 68.2016C169.369 66.4922 169.76 64.9581 170.541 63.5555C171.322 62.1967 172.363 61.1009 173.709 60.3558C175.054 59.5668 176.573 59.2162 178.222 59.2162C179.871 59.2162 181.346 59.5669 182.648 60.312C183.95 61.0571 184.991 62.1091 185.772 63.4678C186.51 64.8266 186.901 66.4483 186.901 68.2454C186.901 68.2016 186.857 68.6399 186.814 69.5165ZM175.488 63.8623C174.75 64.5198 174.273 65.3964 174.143 66.536H182.344C182.171 65.4402 181.737 64.5636 180.999 63.9061C180.261 63.2487 179.35 62.898 178.265 62.898C177.137 62.898 176.226 63.2048 175.488 63.8623Z"
              fill="#100D42"
            />
            <path
              d="M113.826 84.9454L104.453 107.694H99.5057L90.1758 84.9454H95.6001L102.196 101.207L108.879 84.9454H113.826Z"
              fill="#100D42"
            />
            <path
              d="M126.713 91.8704C128.102 93.1415 128.796 95.1139 128.796 97.6999V107.693H124.283V105.502C123.372 107.124 121.679 107.956 119.206 107.956C117.947 107.956 116.819 107.737 115.864 107.255C114.91 106.817 114.215 106.159 113.695 105.37C113.217 104.581 112.957 103.661 112.957 102.653C112.957 101.031 113.521 99.76 114.693 98.7957C115.864 97.8752 117.644 97.3931 120.074 97.3931H123.893C123.893 96.2973 123.589 95.4207 122.938 94.8509C122.287 94.2372 121.332 93.9742 120.074 93.9742C119.206 93.9742 118.295 94.1057 117.47 94.4126C116.602 94.7194 115.908 95.1139 115.3 95.596L113.565 92.0457C114.476 91.3882 115.561 90.8622 116.819 90.4677C118.078 90.1171 119.379 89.8979 120.725 89.8979C123.328 89.9418 125.325 90.5554 126.713 91.8704ZM122.591 103.924C123.242 103.529 123.676 102.916 123.936 102.171V100.374H120.638C118.642 100.374 117.687 101.075 117.687 102.434C117.687 103.091 117.947 103.617 118.425 103.968C118.902 104.362 119.553 104.538 120.421 104.538C121.202 104.538 121.94 104.318 122.591 103.924Z"
              fill="#100D42"
            />
            <path
              d="M149.237 90.2047V107.693H144.637V105.633C143.986 106.378 143.249 106.992 142.337 107.387C141.469 107.781 140.471 108 139.473 108C137.303 108 135.568 107.343 134.266 106.028C133.007 104.713 132.356 102.74 132.356 100.154V90.2924H137.173V99.4093C137.173 102.214 138.302 103.617 140.558 103.617C141.73 103.617 142.641 103.223 143.335 102.434C144.03 101.645 144.377 100.461 144.377 98.9271V90.2924H149.237V90.2047Z"
              fill="#100D42"
            />
            <path
              d="M153.75 83.5428H158.567V107.65H153.75V83.5428Z"
              fill="#100D42"
            />
            <path
              d="M174.971 106.817C174.494 107.168 173.93 107.475 173.235 107.65C172.541 107.825 171.803 107.913 171.066 107.913C169.069 107.913 167.551 107.387 166.466 106.335C165.381 105.283 164.86 103.705 164.86 101.645V94.4569H162.3V90.5559H164.86V86.3043H169.677V90.5559H173.843V94.4569H169.677V101.558C169.677 102.303 169.851 102.872 170.198 103.267C170.545 103.661 171.066 103.881 171.716 103.881C172.498 103.881 173.149 103.661 173.669 103.223L174.971 106.817Z"
              fill="#100D42"
            />
            <path
              d="M36.712 37.0812L0 54.1753V64.3003L36.712 46.8994L64.2244 62.6347V98.7078H63.0527H48.6022H45.6948V108H48.6022H63.0527H73.4675V56.5861L36.712 37.0812Z"
              fill="#49C3CA"
            />
            <path
              d="M31.0273 98.7078H20.1352H9.2431V65.7906L0 70.3929V108H67.6525V98.7078H31.0273Z"
              fill="#49C3CA"
            />
          </g>
          <defs>
            <clipPath id="clip0_647_58702">
              <rect width="186.902" height="108" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  )
}
