import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Loader, TabView } from 'venus/components'
import PSILayout from 'venus/layout/PSI'
import { getItem, partialUpdateItem, updateItem } from 'venus/redux/features/item/item'
import { DocumentList } from 'venus/pages/Documents/List'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { Container } from './Item.styles'
import ItemDetails from './ItemDetails'
import HistoryList from 'venus/pages/History/HistoryList'
import TradespeopleList from 'venus/pages/Tradespeople'
import PSIPhotos from 'venus/common/PSIPhotos'
import api from 'venus/api'
import { isTempUrl } from 'venus/utils/url'
import { updatePropertyItem } from 'venus/redux/features/property/property'
import useShareStatus from 'venus/hooks/useShareStatus'
import { updateSpace } from 'venus/redux/features/space/space'

const formatData = (data, images) => {
  if (data.coverImage && isTempUrl(data.coverImage)) {
    return { ...data, images: [...images, { url: data.coverImage }] }
  }
  return data
}

const getTabsForItem = (
  isShared: boolean,
  isShareDocument: boolean,
  isShareHistory: boolean,
  items: any[] | undefined,
) => {
  const tabs = ['Details']
  const isDisplayDocument = !isShared || (isShared && isShareDocument)
  const isDisplayHistory = !isShared || (isShared && isShareHistory)
  tabs.push('Photos')
  if (isDisplayDocument) tabs.push('Documents')
  if (isDisplayHistory) tabs.push('History')
  if (!isShared) tabs.push('Tradespeople')
  return { tabs, isDisplayDocument, isDisplayHistory }
}

const Item = () => {
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')
  const { propertyId, spaceId, itemId } = useParams()

  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  const { item, loading } = useAppSelector((state) => state.item)
  const { space } = useAppSelector((state) => state.space)
  const { property } = useAppSelector((state) => state.property)

  const { share } = useShareStatus(propertyId, shareId)

  const isPropertyWideSpace = spaceId === property?.propertyWideSpaceId

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getItem({ propertyId, spaceId, itemId, shareId, dreamPropertyId }))
  }, [propertyId, spaceId, itemId, shareId, dreamPropertyId])

  const onUpdateItemSetting = async (data) => {
    const response = await api.put(
      `/property/${propertyId}/space/${spaceId}/item/${item.id}`,
      formatData(data, item.images || []),
    )
    const updatedItem = response.data

    if (isPropertyWideSpace) {
      dispatch(updatePropertyItem(updatedItem))
    }
    dispatch(updateItem({ ...updatedItem, spaceId }))
  }

  if (loading) {
    return <Loader />
  }

  const isShared = !!shareId || !!dreamPropertyId

  // @ts-ignore
  const { isShareDocument, isShareHistory } = share

  const { tabs, isDisplayDocument, isDisplayHistory } = getTabsForItem(
    isShared,
    isShareDocument,
    isShareHistory,
    space?.items,
  )

  const onDelete = async () => {
    try {
      const response = await api.delete(
        `/property/${propertyId}/space/${spaceId}/item/${item.id}`,
      )
      const updatedSpaces = response.data

      dispatch(updateSpace(updatedSpaces))

      if (isPropertyWideSpace) {
        navigate(`/property/${propertyId}?activeIndex=Items`)
      } else {
        navigate(`/property/${propertyId}/space/${spaceId}`)
      }
    } catch (err) {}
  }

  return (
    <PSILayout
      tag='Item'
      title={item.name}
      subTitle={space?.name}
      headerBackground={item?.coverImage}
      goBack={goBack}
      setting={{ name: item.name, coverImage: item?.coverImage, images: item?.images }}
      onUpdateSetting={onUpdateItemSetting}
      onDelete={onDelete}
      isShared={isShared}
      isDreamProperty={!!dreamPropertyId}
    >
      <Container>
        <TabView tabs={tabs} style={{ minHeight: 0, overflow: 'auto', paddingTop: 26 }}>
          <ItemDetails
            isPropertyWideSpace={false}
            item={item}
            setTabButtons={undefined}
            shareId={shareId}
            dreamPropertyId={dreamPropertyId}
          />
          <PSIPhotos
            images={item?.images}
            updateUrl={`/property/${propertyId}/space/${spaceId}/item/${itemId}`}
            update={partialUpdateItem}
            psiId={{ propertyId, spaceId, itemId, dreamPropertyId, shareId }}
            propertyWideSpaceId={property?.propertyWideSpaceId}
            setTabButtons={undefined}
            isShared={isShared}
            isPersonal={undefined}
          />
          {isDisplayDocument && (
            <DocumentList
              type='item'
              propertyId={propertyId}
              propertyWideSpaceId={property?.propertyWideSpaceId}
              spaceId={spaceId}
              itemId={itemId}
              shareId={shareId}
              dreamPropertyId={dreamPropertyId}
            />
          )}

          {isDisplayHistory && (
            <HistoryList
              propertyId={propertyId}
              spaceId={spaceId}
              itemId={itemId}
              spaceNameTag={item.name}
            />
          )}
          {!isShared && (
            <TradespeopleList propertyId={propertyId} spaceId={spaceId} itemId={itemId} />
          )}
        </TabView>
      </Container>
    </PSILayout>
  )
}

export default Item
