import styled from 'styled-components'

export const Action = styled.button`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px 8px;
  background-color: rgba(0, 0, 0, 0.5);

  border: 0;
  outline: none;
`
