import styled from "styled-components"

export const PageWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  overflow: auto;
`

export const DemoArea = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 36px;
  overflow: scroll;
  margin-bottom: 100px;
`
