import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useModelContext } from 'venus/context/ModelContext'
import * as tf from '@tensorflow/tfjs'

import {
  buildDetectedObjects,
  createHTMLImageElement,
  detectDuplicatedObject,
  generateBBox,
  getDetectedSpace,
  getPredictSpace,
  getUpdatedDetections,
  readImageFile,
} from './utils'
import { v4 as uuidv4 } from 'uuid'
import Predictions from './Predictions'
import useSpaceItems from 'venus/hooks/useSpaceItems'
import { useParams } from 'react-router-dom'
import { PREDICTABLE } from './constants'
import _ from 'lodash'
import useSpaces from 'venus/hooks/useSpaces'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { generateRandomCoverImage } from 'venus/utils'
import api from 'venus/api'
import {
  updateProperty,
  updatePropertyItem,
  updatePropertySpaces,
} from 'venus/redux/features/property/property'
import { addItems, updateSpace } from 'venus/redux/features/space/space'
import { Button, Loader } from 'venus/components'
import SpaceTag from './SpaceTag'
import ItemTag from './ItemTag'
import { PredictionsContainer } from '../ImagePreview/ImagePreview.styles'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  /*top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  position: absolute;*/
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 620px;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const sortFunc = (a, b) => {
  if (a.score === b.score) return 0
  if (a.score > b.score) return -1
  return 1
}

const filterPredictions = (predictions) => {
  const map = predictions.reduce((acc, curr) => {
    const type = curr.type

    if (!acc[type]) {
      return { ...acc, [type]: [curr] }
    }

    const existing = acc[type]

    const isDuplicated = detectDuplicatedObject(curr, existing)

    return isDuplicated ? { ...acc } : { ...acc, [type]: [...acc[type], curr] }
  }, {})

  // @ts-ignore
  return Object.values(map).reduce((acc, curr) => [...acc, ...curr], [])
}

export const formatPredictions = (predictions: any) =>
  filterPredictions(
    predictions
      .filter((prediction) => PREDICTABLE[prediction.class])
      .map((prediction) => ({
        ...prediction,
        id: uuidv4(),
        ...PREDICTABLE[prediction.class],
      }))
      .sort(sortFunc),
  )

const formatDefaultSpace = (space) =>
  space ? { ...space, value: space.type, label: space.name } : null

const Scanner = ({ url, next, back, reset, image, defaultSpace }) => {
  const { models } = useModelContext()
  const [predictions, setPredictions] = useState<any>()
  const [isAnalysing, setIsAnalysing] = useState(false)
  const [detectSpace, setDetectSpace] = useState<any>()
  const [position, setPosition] = useState<any>()
  const [editing, setEditing] = useState<any>()
  const [boxSize, setBoxSize] = useState<any>()

  const dispatch = useAppDispatch()

  const [size, setSize] = useState<any>({})

  const { propertyId: paramPropertyId, spaceId } = useParams()
  const { property } = useAppSelector((state) => state.property)
  const propertyId = paramPropertyId || property.id

  const { getSpaces } = useSpaces(propertyId)

  const propertyWideSpace = formatDefaultSpace(
    property.spaces?.find((s) => s.isPropertyWideSpace),
  )

  const space = detectSpace || defaultSpace

  const { isProcessing, items, matched, setMatched } = useSpaceItems({
    propertyId,
    spaceId: space?.id,
    predictions,
  })

  const scale = useMemo(() => {
    // @ts-ignore
    const xScale = size?.width < 1024 ? 1 : 1024 / size?.width
    // @ts-ignore
    const yScale = size?.height < 620 ? 1 : 620 / size?.height

    return Math.min(xScale, yScale)
  }, [size])

  const processImage = useCallback(async () => {
    setIsAnalysing(true)
    const imageSrc = await readImageFile(image)
    const img = await createHTMLImageElement(imageSrc)

    // @ts-ignore
    let imageTensor = tf.browser.fromPixels(img)

    const batched = tf.tidy(() => tf.expandDims(imageTensor))
    const height = batched.shape[1]
    const width = batched.shape[2]

    setSize({ width, height })

    // @ts-ignore
    let defaultDetections = await models.defaultModel.predict(img)

    const defaults = defaultDetections.objects
      .filter((detection) => detection.score >= 0.5)
      .map((detection) => ({
        score: detection.score.toFixed(4),
        class: detection.className,
        bbox: generateBBox(detection.boundingBox),
      }))

    // @ts-ignore
    const classification = await models.classification.predict(img)
    const spaceValue = classification.classes[0]?.className
    console.log('classification', spaceValue)

    // return setIsAnalysing(false)
    // @ts-ignore
    let result = await models.custom.executeAsync(batched)

    const scores = result[3].arraySync()
    const boxes = result[6].arraySync()
    const classes = result[7].dataSync()

    const detections = buildDetectedObjects(width, height, scores, boxes, classes)
    const formatted = formatPredictions([...detections, ...defaults])

    setPredictions(formatted)

    const spaces = await getSpaces(propertyId)

    const detectedSpace = getDetectedSpace(spaceValue, spaces)

    // @ts-ignore
    if (formatted.length) {
      if (!defaultSpace) {
        if (!spaceValue)
          setDetectSpace({
            id: property!.propertyWideSpaceId,
            value: 'PROPERTY',
            label: 'Property Wide',
          })
        else {
          setDetectSpace(detectedSpace)
        }
      } else {
        setDetectSpace({
          id: defaultSpace.id,
          value: defaultSpace.type,
          label: defaultSpace.name,
        })
      }
    } else {
      setDetectSpace(detectedSpace)
    }

    setIsAnalysing(false)
  }, [image])

  const onSelect = (selected) => {
    console.log('SELECTED', selected)
    cancelSelect()
    const [x, y, w, h] = selected.bbox

    setPosition({ x: x * scale, y: y * scale })
    setBoxSize({ width: w * scale, height: h * scale })
    setEditing(selected)
  }

  const onSave = async () => {
    console.log('SAVE', url)
    let updated
    if (url) {
      try {
        const newItems = matched
          .filter((match) => !match.itemId)
          .filter((match) => match.type !== 'OTHER')
          .map((match) => ({
            name: match.label,
            typeId: match.type,
            coverImage: generateRandomCoverImage(),
          }))

        if (space?.id === 'new') {
          const newSpaceResponse = await api.post(`/property/${propertyId}/spaces`, [
            {
              name: space.label,
              type: space.value,
              coverImage: url,
              images: [],
            },
          ])

          const newSpace = newSpaceResponse.data.find((s) => s.name === space.label)
          dispatch(updateProperty({ spaces: newSpaceResponse.data }))

          const itemResponse = await api.post(
            `property/${propertyId}/space/${newSpace.id}/items`,
            newItems,
          )
          dispatch(addItems(itemResponse.data))

          const response = await api.post(
            `/property/${propertyId}/spaces/${newSpace.id}/images`,
            {
              images: [
                {
                  url,
                  scan: {
                    space,
                    size,
                    predictions: getUpdatedDetections(matched, itemResponse.data),
                  },
                },
              ],
            },
          )
          const updatedObject = response.data
          dispatch(updatePropertySpaces(updatedObject))
          updated = updatedObject
          // setSpace(updatedObject);
        } else if (space?.id) {
          console.log('[SAVE]exist space')

          const itemResponse = await api.post(
            `/property/${propertyId}/space/${space.id}/items`,
            newItems,
          )
          if (defaultSpace?.id === spaceId) {
            if (itemResponse.data.length) dispatch(addItems(itemResponse.data))
          }
          if (space.value === 'PROPERTY') {
            dispatch(updateProperty({ items: itemResponse.data }))
          }

          const response = await api.post(
            `/property/${propertyId}/spaces/${space.id}/images`,
            {
              images: [
                {
                  url,
                  scan: {
                    space,
                    size,
                    predictions: getUpdatedDetections(matched, itemResponse.data),
                  },
                },
              ],
            },
          )
          const updatedObject = response.data
          updatedObject.spaceId = space.id

          if (updatedObject.isPropertyWideSpace) {
            dispatch(
              updateProperty(_.pick(updatedObject, ['items', 'images', 'coverImage'])),
            )
          }

          if (defaultSpace?.id === updatedObject.id) {
            dispatch(updateSpace(_.omit(updatedObject, 'id')))

            if (updatedObject.isPropertyWideSpace) {
              dispatch(updatePropertyItem(updatedObject.items))
            }
          } else {
            dispatch(updatePropertySpaces(updatedObject))
          }
          updated = updatedObject

          // setSpace(updatedObject);
        } else {
          const response = await api.put(`/property/${propertyId}`, {
            images: [
              ...property.images,
              {
                url,
                scan: {
                  space,
                  size,
                  predictions: matched,
                },
              },
            ],
          })
          const updatedObject = response.data
          updatedObject.spaceId = updatedObject.id
          dispatch(updateProperty(_.omit(updatedObject, 'id')))
        }

        // reset()
      } catch (error) {
        console.log('ERROR in save', error)
        // reset()
      }
    }
    setDetectSpace(undefined)
    setPredictions(undefined)

    next(updated)
  }

  const cancelSelect = () => {
    setEditing(undefined)
    setPosition(undefined)
    setBoxSize(undefined)
  }

  useEffect(() => {
    if (image) processImage()
  }, [image])

  const handleAddItem = (event) => {
    event.stopPropagation()
    const bounds = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - bounds.left
    const y = event.clientY - bounds.top

    setPosition({ x, y })
  }

  const addItem = (item) => {
    setMatched((pre) => [...pre, item])
    setEditing(undefined)
  }

  const updateItem = (item) => {
    setMatched((pre) => {
      const filtered = pre.filter((p) => p.id !== item.id)

      return [...filtered, item]
    })
    setEditing(undefined)
  }

  const deleteItem = (itemId) => {
    const updated = matched.filter((prediction) => {
      return prediction.id !== editing.id
    })

    setMatched(updated)
    setPredictions((predictions) =>
      predictions.filter((prediction) => prediction.id !== editing.id),
    )

    cancelSelect()
  }

  return (
    <Container>
      {!!space && (
        <SpaceTag
          style={{ marginBottom: '10px' }}
          // isItemEditing={!!position}
          // isOpen={false}
          // setIsOpen={() => {}}
          // isInputing={false}
          // setIsInputing={() => {}}
          space={space}
          propertyId={propertyId}
          onChange={setDetectSpace}
        />
      )}
      <ImageContainer>
        <Image id='scan_image' src={URL.createObjectURL(image)} />

        {isAnalysing ? (
          <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
            <Loader />
          </div>
        ) : (
          <PredictionsContainer
            onClick={!position && !editing ? handleAddItem : undefined}
            width={size?.width * scale}
            height={size?.height * scale}
          >
            {/* @ts-ignore */}
            <Predictions
              editing={editing}
              scale={scale}
              predictions={matched}
              onSelect={onSelect}
            />
            {!!position && (
              <ItemTag
                items={items}
                editing={editing}
                scale={scale}
                position={position}
                boxSize={boxSize}
                addItem={addItem}
                updateItem={updateItem}
                deleteItem={deleteItem}
                cancelSelect={cancelSelect}
              />
            )}
          </PredictionsContainer>
        )}
      </ImageContainer>

      <Button
        onClick={onSave}
        text='Save'
        isDisabled={false}
        isLoading={isAnalysing}
        style={{
          height: '36px',
          padding: '4px 28px',
          width: 'auto',
          alignSelf: 'flex-end',
          margin: '24px',
        }}
      />
      {/* <img style={{ opacity: 0 }} id='image' src={URL.createObjectURL(image)} /> */}
    </Container>
  )
}

export default Scanner
