import { useState } from 'react'
import { Button, Input, Notification, Text } from 'venus/components'
import { BgWrapper } from 'venus/layout/MHV/MHV.styles'
import {
  BackIconWrapper,
  ModalHeader,
  ModalTitle,
} from '../ForgotPassword/ForgotPassword.styles'
import { LoginPanel } from '../Login/Login.styles'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { verifyResetPwd } from 'venus/utils'
import { axiosClient } from 'venus/api'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const handleNext = async () => {
    const message = verifyResetPwd('', '', password, confirm)
    if (message) {
      return setErrorMessage(message)
    }
    try {
      const response = await axiosClient.put(
        '/auth/change-password',
        { newPassword: password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      close()
      //   handleCancel()
    } catch (error) {
      //   setShowChangePwdAlert(true)
      console.log('Error in update your password. Please try again.')
    }
  }

  const close = () => navigate('/login')

  return (
    <BgWrapper
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPanel style={{ paddingTop: 0 }}>
        <ModalHeader>
          <BackIconWrapper></BackIconWrapper>
          <ModalTitle>
            <Text format={'h1'} value={'Change Password'} />
          </ModalTitle>
          <BackIconWrapper onClick={close}>
            <CrossIcon />
          </BackIconWrapper>
        </ModalHeader>
        <form>
          <Input
            type='password'
            label='New Password'
            style={{ marginBottom: 18, marginTop: 18 }}
            // placeholder='Your Mobile / Email'
            value={password}
            onChange={setPassword}
          />
          <Input
            type='password'
            label='Confirm Password'
            style={{ marginBottom: 18, marginTop: 18 }}
            // placeholder='Your Mobile / Email'
            value={confirm}
            onChange={setConfirm}
          />
          <Button
            type={'primary'}
            text={'Save'}
            isDisabled={!password || password !== confirm}
            //   isLoading={loading}
            onClick={handleNext}
          />
        </form>
      </LoginPanel>
      <Notification
        showAlert={!!errorMessage}
        closeAlert={() => setErrorMessage('')}
        message={errorMessage}
      />
    </BgWrapper>
  )
}

export default ResetPassword
