import { useState } from 'react'
import { PillTabs } from 'venus/components'
import { defaultPillsTabConfig } from 'venus/config/pill'
import { SubList } from 'venus/pages/Contact/List/SubList'
import { IContactEntry } from 'venus/redux/features/contact'

interface ITradespersonList {
  persons: IContactEntry[]
  handlePress: Function
  isWrap?: boolean
}

export const TradespersonList = ({
  persons,
  handlePress,
  isWrap = false,
}: ITradespersonList) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const tabs = [
    { key: 'all', label: 'All' },
    { key: 'plumber', label: 'Plumber' },
    { key: 'electrician', label: 'Electrician' },
    { key: 'landscaper', label: 'Landscaper' },
    { key: 'painter', label: 'Painter' },
    { key: 'other', label: 'Other' },
  ]

  const listPlumber = persons.filter(
    ({ tradespeopleRole }) =>
      tradespeopleRole &&
      tradespeopleRole &&
      tradespeopleRole.indexOf(tabs[1].label?.toUpperCase()) >= 0
  )
  const listElectrician = persons.filter(
    ({ tradespeopleRole }) =>
      tradespeopleRole &&
      tradespeopleRole &&
      tradespeopleRole.indexOf(tabs[2].label?.toUpperCase()) >= 0
  )
  const listLandscaper = persons.filter(
    ({ tradespeopleRole }) =>
      tradespeopleRole &&
      tradespeopleRole.indexOf(tabs[3].label?.toUpperCase()) >= 0
  )
  const listPainter = persons.filter(
    ({ tradespeopleRole }) =>
      tradespeopleRole &&
      tradespeopleRole &&
      tradespeopleRole.indexOf(tabs[4].label?.toUpperCase()) >= 0
  )
  const listOther = persons.filter(
    ({ tradespeopleRole }) =>
      tradespeopleRole &&
      tradespeopleRole &&
      tradespeopleRole.indexOf(tabs[5].label?.toUpperCase()) >= 0
  )

  const list = [
    persons,
    listPlumber,
    listElectrician,
    listLandscaper,
    listPainter,
    listOther,
  ]

  return (
    <>
      <PillTabs
        onChange={setActiveIndex}
        tabs={tabs}
        config={defaultPillsTabConfig}
        style={{ marginLeft: 18, flexWrap: isWrap ? 'wrap' : 'nowrap' }}
      />
      <SubList handlePress={handlePress} persons={list[activeIndex]} />
    </>
  )
}
