import _ from 'lodash'
import api from 'venus/api'
import axios from 'axios'
import { useCallback, useEffect } from 'react'
import React, { useRef, useState } from 'react'
import { getExtension } from 'mime'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  setImage,
  setName,
  setProgress,
  setUrl,
  reset,
} from 'venus/redux/features/image/image'
import { AlertBox } from 'venus/components'
import ScanHome from '../ScanHome'

const getRandom = () => Math.random().toString(36)

export const getUploadConfig = async (file) => {
  if (!file) {
    return
  }
  try {
    const mimeType = file?.type || 'image/jpeg'

    const ext = getExtension(mimeType || 'jpg')

    console.log('mimeType', mimeType, ext)

    const fileName = file?.name
    const uploadConfig = await api
      .post('/images', [{ name: fileName, type: mimeType, ext }])
      .then((res) => res.data)
    return uploadConfig[0]
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

const ImageUploader = ({
  children,
  name = 'uploading',
  uploadImages,
  isMultiple = false,
  enableScanHome = false,
  space,
}: any) => {
  const fileInput = useRef()
  const [selectedFile, setSelectedFile] = useState()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [randomKey, setRandomKey] = useState(getRandom())
  const dispatch = useAppDispatch()
  const [showScanhomeAlert, setShowScanhomeAlert] = useState(false)
  const [shouldScanhome, setShouldScanhome] = useState<any>()

  const [scanIndex, setScanIndex] = useState(0)

  const goNextScan = async (indexes) => {
    console.log('scanIndex', indexes, selectedFile, selectedFiles, scanIndex)

    if (selectedFile || scanIndex + 1 === selectedFiles.length) {
      if (indexes && indexes.length) {
        // upload(indexes)
      }

      setShouldScanhome(undefined)
      reset()
      setScanIndex(0)
      setSelectedFile(undefined)
      setSelectedFiles([])
    } else {
      setScanIndex((index) => index + 1)
    }
  }

  const uploadImage = async (uploadConfig, image) => {
    const { uploadURL } = uploadConfig

    const fileType = image.type

    // setting up the reader
    const reader = new FileReader()
    reader.readAsArrayBuffer(image)
    // reader.readAsText(image, 'UTF-8')

    // here we tell the reader what to do when it's done reading...
    reader.onload = async () => {
      const content = reader.result // this is the content!
      // @ts-ignore
      // const buffer = Buffer.from(content, 'base64')

      try {
        await axios
          .put(uploadURL, content, {
            headers: {
              'Content-Type': String(fileType),
            },
            onUploadProgress: (progress: ProgressEvent) => {
              const progressPercentage = Math.round(
                (progress.loaded / progress.total) * 100,
              )
              dispatch(setProgress(progressPercentage))
            },
          })
          .then((res) => {
            dispatch(setUrl(uploadConfig.url))
            dispatch(setName(name))

            return res.status
          })
          .catch((error) => {
            console.log('error', error.response.data)
            return 403
          })

        // return uploadConfig
      } catch (error) {
        console.log('error in upload', error)
      }
    }
  }

  const processImage = async () => {
    const reader = new FileReader()

    reader.readAsDataURL(selectedFile)
    reader.onload = () => {
      console.log('reader.result', reader.result)
      dispatch(setImage(reader.result))
    }

    const config = await getUploadConfig(selectedFile)

    await uploadImage(config, selectedFile)

    setRandomKey(getRandom())
    setSelectedFile(undefined)
    await dispatch(reset())
  }

  const handleUpload = useCallback(async () => {
    await uploadImages(selectedFiles)
    setSelectedFiles([])
    setRandomKey(getRandom())
    await dispatch(reset())
  }, [selectedFiles])

  useEffect(() => {
    const handleImageChange = async () => {
      if (selectedFile) {
        if (enableScanHome) {
          await processImage()
          setShowScanhomeAlert(true)
        } else await processImage()
      }
    }

    handleImageChange()
  }, [selectedFile])

  useEffect(() => {
    if (selectedFiles.length) {
      console.log('pick files', enableScanHome, selectedFiles)

      if (enableScanHome) {
        setShowScanhomeAlert(true)
      } else handleUpload()
    }
  }, [selectedFiles])

  const handleClick = (event) => {
    if (fileInput.current) {
      if (_.get(event, 'target.files', []).length) {
        event.target.value = null

        console.log('event', event.target)
      }
      // event.target.value = null
      // @ts-ignore
      fileInput.current?.click()
    }
  }

  const onChange = (event) => {
    if (_.get(event, 'target.files', []).length === 0) {
      return
    }

    if (isMultiple) {
      setSelectedFiles(Array.from(_.get(event, 'target.files', [])))
    } else {
      setSelectedFile(_.get(event, 'target.files[0]'))
    }
    event.target.value = null
  }

  const openScanhome = () => {
    setShowScanhomeAlert(false)
    setShouldScanhome(true)
  }
  const cancelScanhome = () => {
    setShowScanhomeAlert(false)
    handleUpload()
    // upload()
  }

  console.log('SELE', selectedFiles)

  return (
    <>
      <input
        key={randomKey}
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={onChange}
        multiple={isMultiple}
      />
      {React.cloneElement(children, { onClick: handleClick })}

      {enableScanHome && (
        <AlertBox
          cancel={cancelScanhome}
          title='Scan Home'
          message='Do you want to scan those photos to add detected items?'
          btnGroup={[
            { type: 'primary', text: 'Yes', onClick: openScanhome, color: 'Primary' },
            {
              type: 'secondary',
              text: 'No',
              onClick: cancelScanhome,
              color: 'LightBlue',
              textColor: 'Primary',
            },
          ]}
          // btnGroup={[
          //   {
          //     type: 'error',
          //     text: 'Delete',
          //     onClick: handleDelete,
          //     color: 'ErrorState',
          //   },
          //   {
          //     type: 'secondary',
          //     text: 'Cancel',
          //     onClick: closeDeleteModal,
          //     color: 'LightBlue',
          //     textColor: 'Primary',
          //   },
          // ]}
          showAlert={showScanhomeAlert}
        />
      )}

      {enableScanHome && (
        <ScanHome
          goNext={goNextScan}
          individual
          space={space}
          isOpen={shouldScanhome}
          close={() => setShouldScanhome(false)}
          image={selectedFile || selectedFiles[scanIndex]}
        />
      )}
    </>
  )
}

export default ImageUploader
