import styled from 'styled-components'
import { theme } from 'venus/config'

export const IconArea = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;

  &:hover {
    button {
      svg {
        color: white;
      }
    }
  }
`

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const AvatarArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin-top: 36px;
  margin-bottom: 24px;
`

export const TitleArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 12px;
`

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
`

export const TitleWrapper = styled.div`
  display: flex;
  height: 29px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  background: ${theme.colors.LightBlue};
  border-radius: 50px;
  align-self: center;
  margin-right: 6px;
`

export const PanelDisplayArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto;
`

export const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 28px;
`

export const LeftArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  align-self: flex-start;
`

export const RightArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`

export const TabBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-left: 24px;

  &:hover {
    background: ${theme.colors.LightBlue} !important;
  }
`

export const AddContactWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    button {
      background-color: ${theme.colors.LightBlue};
    }

    svg {
      color: ${theme.colors.Primary} !important;
    }

    span {
      color: ${theme.colors.Primary} !important;
    }
  }
`

export const EditWrapper = styled.div`
  width: calc(100% + 68px);
  height: 99vh;
  overflow-y: scroll;
  padding-top: 36px;
`
