import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  progress: 0,
  url: '',
  video: '',
  videos: [],
}

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    reset: () => initialState,
    setProgress: (state, action) => {
      state.progress = action.payload
    },
    setVideo: (state, action) => {
      state.video = action.payload
    },
    setUrl: (state, action) => {
      state.url = action.payload
    },
    setVideos: (state, action) => {
      state.videos = action.payload
    },
  },
})

export const { reset, setProgress, setUrl, setVideo, setVideos } = videoSlice.actions

export default videoSlice.reducer
