import { useNavigate } from 'react-router-dom'
import { TabView, EmptyData } from 'venus/components'
// import MHVPremium from 'venus/scenes/Transfer/MHVPremium'
// import useShowPremium from 'venus/hooks/useShowPremium'
import { useEffect } from 'react'
import { DocumentList } from 'venus/pages/Documents/List'
import PersonalItems from './PersonalItems/PersonalItems'
import { Container } from './PersonalVault.styles'
import usePremium from 'venus/hooks/usePremium'
import { FREE, PAID } from 'venus/config'
import usePremiumContext from 'venus/context/MHVPremium'

const tabs = ['Items', 'Documents']

export const PersonalVault = () => {
  // const { status } = useAppSelector((state) => state.subscription)
  // const [isOpen, setIsOpen] = useState(status === 'FREE')

  const { status } = usePremium()
  const { openPremium } = usePremiumContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (status === 'FREE') {
      openPremium()
      navigate('/')
    }
  }, [status])

  // const { onViewPlan } = useShowPremium(status, navigation)

  // const handleCancel = () => {
  //   setIsOpen(false)
  //   navigation.goBack()
  // }

  // const handleViewPlan = () => {
  //   setIsOpen(false)
  //   onViewPlan()
  //   navigation.goBack()
  // }

  return (
    <Container>
      {status !== 'FREE' ? (
        <TabView tabs={tabs} defaultActiveIndex={0} style={{ display: 'flex' }}>
          <PersonalItems setTabButtons={undefined}/>
          <DocumentList type="personal"/>
        </TabView>
      ) : (
        <EmptyData message=""/>
      )}
      {/* <MHVPremium isOpen={isOpen} onViewPlan={handleViewPlan} onCancel={handleCancel} /> */}
    </Container>
  )
}
