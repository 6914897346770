import styled from 'styled-components'
import { theme } from 'venus/config'
import { ReactComponent as EditIcon } from '../../assets/img/icons/AvatarEdit.svg'

type ContainerProps = {
  readonly isBorder?: boolean;
  readonly size: number;
  readonly image?: string;
}

export const AvatarContainer = styled.div<ContainerProps>`
  width: ${(props) => props.size}px;
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
`

export const AvatarImgContainer = styled.div<ContainerProps>`
  border: ${props => props.isBorder ? `4px solid ${theme.colors.Secondary}` : 'none'};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-image: url(${(props) => props.image});
  background-position: center center;
  background-size: cover;
`

export const AvatarEditIconContainer = styled.div<ContainerProps>`
  width: ${(props) => Number(props.size)}px;
  height: ${(props) => Number(props.size)}px;
  border-radius: ${(props) => props.size}px;
  background: ${theme.colors.Secondary};
  position: absolute;
  bottom: 4px;
  left: ${(props) => Number(props.size) * 2.9}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AvatarEditIcon = styled(EditIcon)`
  fill: ${theme.colors.White};
  width: 45%;
  height: 45%;
`
