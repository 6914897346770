import { useEffect, useMemo, useState } from 'react'
import useFetchTemplate from 'venus/hooks/useFetchTemplate'
import ImageGrids from '../components/ImageGrids'
import styled from 'styled-components'
import { Button } from 'venus/components'
import { theme } from 'venus/config'
import { extractNumber, generateRandomCoverImage } from 'venus/utils'

import AddItem from 'venus/common/AddItem'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import api from 'venus/api'
import { addItems } from 'venus/redux/features/space/space'
import { useAppDispatch } from 'venus/redux/hooks'
import { addNewItem } from 'venus/redux/features/property/property'
import { useSearchParams } from 'react-router-dom'
import { buildURLQuery } from 'venus/utils/url'
import AddItems from './AddItems'

const GridContent = styled.li`
  display: flex;
  flex-direction: column;
`

const Items = ({
  propertyId,
  spaceId,
  items,
  setTabButtons,
  isPropertyWideSpace = false,
}) => {
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  const [isOpen, setIsOpen] = useState(false)
  const [addItemsOpen, setAddItemsOpen] = useState(false)
  const template = useFetchTemplate({ propertyId, spaceId })
  const [selectedItem, setSelectedItem] = useState({})

  const dispatch = useAppDispatch()

  const openAddItem = () => setIsOpen(true)
  const openAddItems = () => setAddItemsOpen(true)

  useEffect(() => {
    if (!shareId && !dreamPropertyId) {
      setTabButtons(
        <Button
          type='icon_button'
          text={items.length ? 'Add Item' : 'Add Items'}
          iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
          style={{ width: 'auto' }}
          onClick={items.length ? openAddItem : openAddItems}
        />,
      )
    }

    return () => {
      setTabButtons(<></>)
    }
  }, [shareId, dreamPropertyId, items])

  const grids = useMemo(() => {
    if (shareId || dreamPropertyId) {
      return (items || []).map((item) => ({
        id: item.id,
        image: item.coverImage,
        url: `/property/${propertyId}/space/${spaceId}/item/${item.id}?${buildURLQuery({
          shareId,
          dreamPropertyId,
        })}`,
        name: item.name,
      }))
    }
    const itemObjects = (items || []).map((item) => ({
      id: item.id,
      image: item.coverImage,
      url: `/property/${propertyId}/space/${spaceId}/item/${item.id}`,
      name: item.name,
    }))

    const exsitingTypes = new Set((items || []).map((item) => item.typeId))

    const remaining = template
      .filter(({ type }) => !exsitingTypes.has(type))
      .map(({ type, name }) => ({
        id: type,
        defaultType: type,
        name,
      }))

    return [...itemObjects, ...remaining]
  }, [items, template, shareId])

  const openAddItemModal = (grid) => {
    setIsOpen(true)
    setSelectedItem({
      type: grid.defaultType,
      name: grid.name,
    })
  }

  const onSubmit = async (data) => {
    // setIsLoading(true)
    console.log('new item data', data)
    const formattedData = {
      ...data,
      value: extractNumber(data.value),
    }
    // const formatData = data.images
    //   ? formattedData
    //   : { ...formattedData, coverImage: generateRandomCoverImage() }
    try {
      const response = await api.post(`/property/${propertyId}/space/${spaceId}/items`, [
        { ...formattedData, coverImage: generateRandomCoverImage() },
      ])
      const updated = response.data
      dispatch(addItems(updated))
      console.log('isPropertyWideSpace', isPropertyWideSpace)
      if (isPropertyWideSpace) {
        dispatch(addNewItem(updated))
      }
      // setIsLoading(false)
      // navigation.goBack()
      setIsOpen(false)
      setSelectedItem({})
    } catch (error) {
      // setIsLoading(false)
    }
  }

  return (
    <div style={{ paddingTop: 26 }}>
      <ImageGrids grids={grids} onClick={openAddItemModal} />
      <AddItem
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        template={template}
        onSubmit={onSubmit}
        {...selectedItem}
      />

      <AddItems
        propertyId={propertyId}
        spaceId={spaceId}
        template={template}
        isOpen={addItemsOpen}
        close={() => setAddItemsOpen(false)}
      />
    </div>
  )
}

export default Items
