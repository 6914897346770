import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 0px;
  margin: 0 0px;
  display: grid;
`

export const IconArea = styled.div`
  position: absolute;
  top: 84px;
  right: 24px;
  &:hover {
    button {
      svg {
        color: white;
      }
    }
  }
`
