import { useEffect } from 'react'
import { setConfirmUploadDocsDone, checkDoc } from 'venus/redux/features/onboarding'
import { useAppSelector, useAppDispatch } from 'venus/redux/hooks'
import { Button, Text, Checkbox } from 'venus/components'
import { displayLongText } from 'venus/utils'
import { BtnArea, CheckBoxContent, DocumentListView } from './AssignDocs.styles'

interface IAssignDocs {
  setStep: (s: number) => void
  setTitle: (s: string) => void
}

export const AssignDocs = ({ setStep, setTitle }: IAssignDocs) => {
  const dispatch = useAppDispatch()
  const { homeDocs } = useAppSelector((state) => state.onboarding)
  const unclassifiedDocs = homeDocs.filter(({ isUploaded }) => isUploaded !== true)

  useEffect(() => {
    if (unclassifiedDocs.length === 0) {
      dispatch(
        setConfirmUploadDocsDone({
          isConfirmUploadDocsDone: true,
        }),
      )
      setStep(9)
      setTitle('')
    }
  }, [homeDocs])

  const handleCheck = (uri: string) => {
    dispatch(checkDoc(uri))
  }

  const handlePressMove = () => {
    setStep(8)
    setTitle('Move To')
  }

  return (
    <>
      <Text
        style={{ margin: 24, marginBottom: 48, textAlign: 'center' }}
        format='body'
        value={
          'To easily locate your files, select and move\n them to their associated spaces.'
        }
      />
      <DocumentListView>
        {unclassifiedDocs.map(({ filename, checked, uri }, index) => {
          return (
            <CheckBoxContent key={`docs_${index}`}>
              <Checkbox checked={checked} onChange={() => handleCheck(uri)} />
              <Text
                value={displayLongText(filename)}
                format='body'
                style={{
                  flex: 1,
                  alignSelf: 'center',
                  marginLeft: 12,
                }}
              />
            </CheckBoxContent>
          )
        })}
      </DocumentListView>
      <BtnArea>
        {unclassifiedDocs.filter(({ checked }) => checked === true).length > 0 && (
          <Button
            onClick={handlePressMove}
            text='Move Files'
            style={{ height: '36px', padding: '4px 28px', width: 'auto' }}
          />
        )}
      </BtnArea>
    </>
  )
}
