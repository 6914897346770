import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAVM, getRentalAVM, getStatistics } from 'venus/redux/features/npdData/npdData'

import {
  getDreamStatistics,
  getDreamAVM,
  getDreamRentalAVM,
} from 'venus/redux/features/dreamprice'
import { IProperty } from 'venus/redux/features/property/properties'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import AddressDisplay from './AddressDisplay'
import CoverImage from './CoverImage'
import DreamPrice from './DreamPrice'
import { DreamPriceOffer } from 'venus/pages/DreamPrice/DreamPriceOffer'
import { Container, PlaceHolder, Section } from './PropertyDetails.styles'
import PropertyStatistics from './PropertyStatistics'
import Comparables from './Comparables'
import _ from 'lodash'
import NPD from './PropertyStatistics/NPD'

const PropertyDetails = ({
  dreamId: dreamPropertyId,
  property,
  isShared,
  isDreamProperty,
}: {
  dreamId?: string
  property: IProperty
  isShared: boolean
  isDreamProperty?: boolean
}) => {
  const { loading, data } = useAppSelector((state) => state.npd)
  const { propertyData: dreamData } = useAppSelector((state) => state.dreamprice)
  const dispatch = useAppDispatch()

  const { propertyId } = useParams()

  const { googlePlaceId } = property || {}
  const [type, setType] = useState('A')

  useEffect(() => {
    if (property && !isShared) {
      dispatch(getAVM({ propertyId }))
      dispatch(getRentalAVM({ propertyId }))
    }

    if (dreamPropertyId) {
      dispatch(getDreamAVM({ dreamPropertyId }))
      dispatch(getDreamRentalAVM({ dreamPropertyId }))
    }
  }, [property, isShared, dreamPropertyId])

  useEffect(() => {
    if (dreamPropertyId) {
      dispatch(getDreamStatistics({ dreamPropertyId, type }))
    } else if (!isShared) {
      dispatch(getStatistics({ propertyId, type }))
    }
  }, [propertyId, type, dreamPropertyId])

  const propertyType = property?.propertyInfo?.propertyType

  const propertyData = propertyType
    ? { ...property?.propertyData, propertyType }
    : property?.propertyData

  return (
    <Container>
      <Section flex={3}>
        <CoverImage image={property?.coverImage} images={property?.images} />
        <AddressDisplay
          isShared={isShared}
          isDreamProperty={isDreamProperty}
          googlePlaceId={googlePlaceId || _.isEmpty(property?.propertyData)}
          // remove AVM
          avm={dreamData?.avm || data?.avm}
          rentalAvm={dreamData?.rentalAvm || data?.rentalAvm}
          avmLoading={loading}
          address={property?.address || {}}
          propertyData={propertyData}
          dreamPrice={property?.dreamPrice}
        />
        {!(googlePlaceId || _.isEmpty(property?.propertyData)) &&
          !(isShared && !isDreamProperty) && (
            <PropertyStatistics
              onTypeChange={setType}
              isDataLoading={false}
              statistics={dreamData.statistics || data.statistics}
            />
          )}
        {(!isShared || isDreamProperty) && (
          <Comparables
            googlePlaceId={googlePlaceId || _.isEmpty(property?.propertyData)}
            comparables={data?.avm?.comparables || dreamData?.avm?.comparables}
          />
        )}
        {!(googlePlaceId || _.isEmpty(property?.propertyData)) &&
          (dreamData.statistics || data.statistics) && <NPD />}
      </Section>
      <PlaceHolder />

      <Section flex={2}>
        {!isShared && (
          <DreamPrice propertyId={propertyId} dreamPrice={property?.dreamPrice} />
        )}
        {isDreamProperty && <DreamPriceOffer />}
      </Section>
    </Container>
  )
}

export default PropertyDetails
