import api from 'venus/api'
import React from 'react'
import { useState } from 'react'
import { Container } from 'venus/layout/SignUp/MobileValidation/EnterMobile.styles'
import { Text } from 'venus/components'
import { Input } from 'venus/components'
import { Button, AlertBox } from 'venus/components'
import { ENTER_MOBILE_NUMBER_MSG } from 'venus/layout/SignUp/Constants'
import { SignUpSteps } from 'venus/layout/SignUp/Constants'
import { MobileWrapper } from 'venus/layout/SignUp/MobileValidation/EnterMobile.styles'
import { formatMobileNumber } from 'venus/utils'
import { InputArea } from 'venus/layout/SignUp/MobileValidation/EnterMobile.styles'

const EnterMobile = ({
  setStep,
  setUser,
}: {
  setStep: Function
  setUser: Function
}) => {
  const [mobile, setMobile] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const num = mobile.replaceAll(' ', '')
  const isDisabled = num.length !== 9 || isNaN(Number(num))

  const handleOnClick = async () => {
    try {
      setLoading(true)
      const reformatMobile = `0${mobile.replaceAll(' ', '')}`
      const response = await api.post(`/auth/send-mobile-code`, {
        mobile: reformatMobile,
      })
      console.log('send mobile code response::', response)
      if (response?.status === 200) {
        setUser((user) => {
          return {
            ...user,
            mobile: reformatMobile,
          }
        })
        setStep(SignUpSteps.ENTER_VALIDATION_CODE)
        setLoading(false)
      }
    } catch (err) {
      // TODO: place global error handler later
      console.log('[ERROR][SEND_MOBILE_VALIDATION]', err)
      setError(err.data.errors[0].msg)
      setLoading(false)
      return err
    }
  }

  const handleOnChange = (value) => {
    const mobile = formatMobileNumber(value)
    setMobile(mobile)
  }

  const handlPressAlertOK = () => {
    setError('')
  }

  return (
    <Container>
      <InputArea>
        <Text format={'body'} value={ENTER_MOBILE_NUMBER_MSG} />
        <MobileWrapper>
          <Text
            format={'h1'}
            value='+61'
            fontSize={30}
            style={{
              borderBottom: `2px solid #F5F6FF`,
              padding: '12px 0 11px 0',
            }}
          />
          <Input
            value={mobile}
            onChange={handleOnChange}
            maxLength={11}
            placeholder={''}
            style={{
              width: '50%',
              margin: '18px 0 18px 18px',
            }}
            inputStyle={{
              border: 'none',
              borderBottom: '2px solid #F5F6FF',
              padding: '0 0 7px',
              fontSize: '30px',
              fontWeight: 600,
              fontFamily: 'Roboto Mono',
            }}
          />
        </MobileWrapper>
      </InputArea>
      <AlertBox
        cancel={handlPressAlertOK}
        title='Enter Mobile Error'
        message={error}
        btnGroup={[{ text: 'OK', onClick: handlPressAlertOK, type: 'error' }]}
        showAlert={!!error}
      />
      <Button
        type={'primary'}
        text={'Next'}
        isDisabled={isDisabled}
        isLoading={loading}
        onClick={handleOnClick}
      />
    </Container>
  )
}

export default EnterMobile
