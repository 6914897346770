export const LABELS = {
  COMPANY_NAME: 'Company Name',
  COMPANY_ABN: 'ABN',
  COMPANY_ADDRESS: 'Company Address',
  COMPANY_SUBURB: 'Suburb',
  COMPANY_STATE: 'State',
  COMPANY_PHONE: 'Company Phone',
  COMPANY_EMAIL: 'Company Email',
  COMPANY_WEBSITE: 'Company Website',
  LICENSE_NUMBER: 'License Number',
};