import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 10px;

  max-height: 680px;
  min-height: 230px;
  width: 480px;
  background-color: ${(props) => props.theme.colors.White};
`

export const Header = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: ${(props) => props.theme.colors.Primary};
`

export const MHVBadge = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;

  width: 40px;
  height: 40px;

  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.Secondary};

  transform: translate(50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px 34px;
`

export const ActionFooter = styled.div`
  padding: 0 34px 20px;

  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 32px;
  top: 24px;
`

export const CloseButton = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;
  padding: 0;
  height: 24px;
`
