import { useState } from 'react'
// import MHVPremium from 'mhv/scenes/Transfer/MHVPremium'
import { IDVerification } from 'venus/pages/DreamPrice/IDVerification/IDVerification'
import { useAppSelector } from 'venus/redux/hooks'
import {
  ToggleButton,
  MultiSpaceItemSelect,
  ActionLayout,
  AlertBox,
} from 'venus/components'
import { ToggleBtnArea, ToggleBtnWrapper } from './SelectPSIScope.styles'

interface ISelectPSIScope {
  isShareDocument?: boolean
  setIsShareDocument?: (b: boolean) => void
  isShareHistory?: boolean
  setIsShareHistory?: (b: boolean) => void
  isShareWholeProperty: boolean
  setIsShareWholeProperty: (b: boolean) => void
  type: string
  spaceList: any
  checkedItemIds: any
  setCheckedItemIds: Function
  isSetShareScope?: boolean
  handlePressNext: () => void
  displayPremiumOption?: boolean
  isShown?: boolean
  onViewPlan?: Function
  onCancel?: Function
  isRequiredIDVerification?: boolean
  onClose?: () => void
}

export const SelectPSIScope = ({
  isShareDocument,
  setIsShareDocument,
  isShareHistory,
  setIsShareHistory,
  isShareWholeProperty,
  setIsShareWholeProperty,
  type,
  spaceList,
  checkedItemIds,
  setCheckedItemIds,
  isSetShareScope,
  handlePressNext,
  displayPremiumOption = false,
  isShown = false,
  onViewPlan = () => null,
  onCancel = () => null,
  isRequiredIDVerification = false,
  onClose,
}: ISelectPSIScope) => {
  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          isDisabled:
            !isShareWholeProperty && checkedItemIds.length === 0 && !isSetShareScope,
          text: 'Next',
          onClick: handlePressNext,
        },
      ]}
    >
      <>
        <ToggleBtnArea style={{ height: isShareDocument !== undefined ? 110 : 36 }}>
          {isShareDocument !== undefined && (
            <ToggleBtnWrapper>
              <ToggleButton
                checked={isShareDocument}
                setChecked={setIsShareDocument}
                text='Share Documents'
              />
            </ToggleBtnWrapper>
          )}
          {isShareHistory !== undefined && (
            <ToggleBtnWrapper>
              <ToggleButton
                checked={isShareHistory}
                setChecked={setIsShareHistory}
                text='Share History'
              />
            </ToggleBtnWrapper>
          )}
          <ToggleButton
            checked={isShareWholeProperty}
            setChecked={setIsShareWholeProperty}
            text='Share Entire Property'
            isDisabled={type !== 'property'}
          />
        </ToggleBtnArea>
        {!isShareWholeProperty && (
          <>
            <div
              style={{
                padding: '0 36px 36px 36px',
                width: '100%',
                height: '275px',
                overflow: 'scroll',
              }}
            >
              {(spaceList || []).map(({ id, items, name }) => (
                <MultiSpaceItemSelect
                  key={`space_items_${id}`}
                  id={id}
                  spaceLabel={name}
                  items={items}
                  checkedItemIds={checkedItemIds}
                  setCheckedItemIds={setCheckedItemIds}
                  isDisabled={isSetShareScope}
                />
              ))}
            </div>
          </>
        )}
        {/* {displayPremiumOption && (
        <MHVPremium
          isOpen={isShown}
          onViewPlan={onViewPlan}
          onCancel={onCancel}
        />
      )} */}
      </>
    </ActionLayout>
  )
}
