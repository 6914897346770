import { useModelContext } from 'venus/context/ModelContext'
import { Button, Loader, Modal } from 'venus/components'
import Scanner from './Scanner'
import { useEffect, useState } from 'react'
import useUploadImage from 'venus/hooks/useUploadImage'
import { Content, Wrapper } from './ScanHome.styles'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'venus/redux/hooks'
import { getUploadConfig } from '../ImageUploader/ImageUploader'
import axios from 'axios'
import useNavigateSearch from 'venus/hooks/useNavigateSearch'

const ScanHome = ({
  individual,
  isOpen,
  goNext,
  close,
  space: defaultSpace,
  image: defaultImage,
}) => {
  const { isReady, init, isLoading } = useModelContext()
  const navigate = useNavigate()
  const navigateSearch = useNavigateSearch()

  const { propertyId } = useParams()

  const [url, setUrl] = useState('')

  const uploadImage = async (uploadConfig, image) => {
    const { uploadURL } = uploadConfig

    const fileType = image.type

    // setting up the reader
    const reader = new FileReader()
    reader.readAsArrayBuffer(image)
    // reader.readAsText(image, 'UTF-8')

    // here we tell the reader what to do when it's done reading...
    reader.onload = async () => {
      const content = reader.result // this is the content!
      // @ts-ignore
      // const buffer = Buffer.from(content, 'base64')

      try {
        await axios
          .put(uploadURL, content, {
            headers: {
              'Content-Type': String(fileType),
            },
          })
          .then((res) => {
            setUrl(uploadConfig.url)

            return res.status
          })
          .catch((error) => {
            console.log('error', error.response.data)
            return 403
          })

        // return uploadConfig
      } catch (error) {
        console.log('error in upload', error)
      }
    }
  }
  useEffect(() => {
    const processImage = async () => {
      const config = await getUploadConfig(defaultImage)
      await uploadImage(config, defaultImage)
    }

    if (defaultImage) {
      processImage()
    }
  }, [defaultImage])

  useEffect(() => {
    console.log('IS', isReady, isLoading)
    if (!isReady && !isLoading) {
      init()
    }
  }, [isReady, isLoading])
  //   if (!isReady) {
  //     return (
  //       <View style={{ flex: 1, margin: 20, justifyContent: 'center' }}>
  //         <Loader />
  //         <Typography
  //           styles={{ textAlign: 'center', marginTop: 40 }}
  //           text='Initializing MHV home scanner engine, this might take a while.'
  //         />
  //       </View>
  //     )
  //   }

  const next = (space) => {
    if (individual) {
      goNext()
    } else {
      if (space) {
        close()

        if (!space.isPropertyWideSpace) {
          navigateSearch(`/property/${propertyId}/space/${space?.id}`, {
            activeIndex: 'Photos',
          })
        }
      } else {
        close()
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={'ScanHome'}
      //   onBack={? handleGoBack : null}
      onClose={close}
      //   style={{ content: { width: '1024px', height: '800px' } }}
      width={1024}
      height={820}
    >
      {isReady ? (
        <Scanner
          image={defaultImage}
          defaultSpace={defaultSpace}
          url={url}
          next={next}
          back={null}
          reset={null}
        />
      ) : (
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      )}
    </Modal>
  )
}

export default ScanHome
