import styled from 'styled-components'
import { Text } from 'venus/components'
const Container = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const Item = styled.li`
  padding: 12px 20px;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.Grey};
  :last-child {
    border-bottom: 0;
  }
`

const PropertyList = ({ properties }) => {
  return (
    <Container>
      {properties.map((property) => (
        <Item key={property.id}>
          <Text format='h4' value={property.address.address} />
        </Item>
      ))}
    </Container>
  )
}

export default PropertyList
