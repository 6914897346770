import { useEffect, useMemo, useState } from 'react'
import { Loader, Button, TabView } from 'venus/components'
import { AddProperty } from 'venus/common/AddProperty/AddProperty'
import { ModalLayout } from 'venus/layout/ModalLayout'
import { getSharedProperties } from 'venus/redux/features/property/sharedProperties'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { Onboarding } from 'venus/pages/Onboarding'
import { Container, IconArea } from './SharedProperties.styles'
import PropertyList from '../PropertyList'

const SharedProperties = () => {
  const dispatch = useAppDispatch()
  const { loading, error, sharedProperties, sharedScope } = useAppSelector(
    (state) => state.sharedProperties,
  )

  useEffect(() => {
    dispatch(getSharedProperties())
  }, [])

  const properties = useMemo(() => {
    return sharedProperties?.map((property, index) => ({
      ...property,
      // @ts-ignore
      shareId: sharedScope[index]?.id,
      sharedBy: sharedScope[index]?.sharedBy,
    }))
  }, [sharedProperties, sharedScope])

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  return (
    <Container>
      <PropertyList
        type='share'
        properties={properties}
        emptyMessage='Shared properties will appear when another user shares their property link with you.'
      />
    </Container>
  )
}

export default SharedProperties
