import styled from 'styled-components'
import { theme } from 'venus/config'

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 110px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const AvatarArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  background-color: ${theme.colors.LightBlue};
`

export const EditIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
`
