import React from 'react'
import { theme } from 'venus/config'
import useDebounceAction from 'venus/hooks/useDebounceAction'
import { BtnWrapper } from './Button.styles'
import { Text, Loader } from 'venus/components'

interface ITouchButton {
  isSubmit?: boolean
  onClick: (event?: any) => void
  type?:
    | 'primary'
    | 'secondary'
    | 'disabled'
    | 'error'
    | 'error_reverse'
    | 'dropdown'
    | 'icon_button'
    | 'icon_button_error'
    | 'icon_button_tertiary'
    | 'round'
    | 'tertiary'
  textFormat?: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'drop_down_option'
  text?: string
  isDisabled?: boolean
  style?: any
  fullBlock?: boolean
  iconLeft?: React.ReactElement
  isLoading?: boolean
}

export const Button = ({
  isSubmit,
  type = 'primary',
  onClick,
  text,
  textFormat = 'h4',
  isDisabled = false,
  style,
  iconLeft,
  fullBlock = true,
  isLoading = false,
}: ITouchButton) => {
  const debounce = useDebounceAction()
  const handlePress = () => debounce(onClick)

  const buttonStyles = {
    primary: {
      bgColor: theme.colors.Primary,
      textColor: theme.colors.White,
      borderColor: '',
      bgColorOnHover: theme.colors.Secondary,
      textColorOnHover: theme.colors.White,
      borderColorOnHover: 'transparent',
    },
    secondary: {
      bgColor: theme.colors.LightBlue,
      textColor: theme.colors.Primary,
      borderColor: '',
      bgColorOnHover: theme.colors.White,
      textColorOnHover: theme.colors.Primary,
      borderColorOnHover: theme.colors.Grey,
    },
    tertiary: {
      bgColor: theme.colors.Secondary,
      textColor: theme.colors.White,
      borderColor: '',
      bgColorOnHover: theme.colors.White,
      textColorOnHover: theme.colors.Primary,
      borderColorOnHover: theme.colors.Grey,
    },
    disabled: {
      bgColor: theme.colors.LighterGrey,
      textColor: theme.colors.DarkGrey,
      borderColor: '',
      bgColorOnHover: null,
      textColorOnHover: theme.colors.DarkGrey,
      borderColorOnHover: 'transparent',
    },
    error: {
      bgColor: theme.colors.DangerRed,
      textColor: theme.colors.White,
      borderColor: '',
      bgColorOnHover: theme.colors.White,
      textColorOnHover: theme.colors.DangerRed,
      borderColorOnHover: theme.colors.DangerRed,
    },
    error_reverse: {
      bgColor: theme.colors.White,
      textColor: theme.colors.DangerRed,
      borderColor: theme.colors.DangerRed,
      bgColorOnHover: theme.colors.DangerRed,
      textColorOnHover: theme.colors.White,
      borderColorOnHover: theme.colors.DangerRed,
    },
    dropdown: {
      bgColor: theme.colors.White,
      textColor: theme.colors.Black,
      borderColor: '',
      bgColorOnHover: theme.colors.LightBlue,
      textColorOnHover: theme.colors.Black,
      borderColorOnHover: 'transparent',
    },
    icon_button: {
      bgColor: theme.colors.Primary,
      textColor: theme.colors.White,
      borderColor: '',
      bgColorOnHover: theme.colors.LightBlue,
      textColorOnHover: theme.colors.Primary,
      borderColorOnHover: 'transparent',
    },
    icon_button_tertiary: {
      bgColor: theme.colors.LightBlue,
      textColor: theme.colors.Primary,
      borderColor: '',
      bgColorOnHover: theme.colors.White,
      textColorOnHover: theme.colors.Primary,
      borderColorOnHover: theme.colors.Grey,
    },
    icon_button_error: {
      bgColor: theme.colors.LightRed,
      textColor: theme.colors.ErrorState,
      borderColor: '',
      bgColorOnHover: theme.colors.ErrorState,
      textColorOnHover: theme.colors.White,
      borderColorOnHover: 'transparent',
    },
    round: {
      bgColor: theme.colors.White,
      textColor: theme.colors.Black,
      borderColor: '',
      bgColorOnHover: theme.colors.LightBlue,
      textColorOnHover: theme.colors.Secondary,
      borderColorOnHover: 'transparent',
    },
  }
  const {
    bgColor,
    textColor,
    borderColor,
    bgColorOnHover,
    textColorOnHover,
    borderColorOnHover,
  } = buttonStyles[isDisabled ? 'disabled' : type]

  if (type === 'round')
    return (
      <BtnWrapper
        noPadding
        bgColor={bgColor}
        borderColor={borderColor}
        bgColorOnHover={bgColorOnHover || bgColor}
        textColorOnHover={isLoading ? textColor : textColorOnHover || textColor}
        borderColorOnHover={borderColorOnHover}
        isDisabled={isDisabled}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          ...style,
        }}
        disabled={isDisabled || isLoading}
        onClick={handlePress}
      >
        {iconLeft}
      </BtnWrapper>
    )

  return (
    <BtnWrapper
      type={isSubmit ? 'submit' : 'button'}
      bgColor={bgColor}
      borderColor={borderColor}
      bgColorOnHover={isLoading ? bgColor : bgColorOnHover || bgColor}
      textColorOnHover={isLoading ? textColor : textColorOnHover || textColor}
      borderColorOnHover={borderColorOnHover}
      borderRadius={type === 'dropdown' ? 0 : 8}
      isDisabled={isDisabled || type === 'disabled'}
      style={{
        width: fullBlock ? '100%' : 'auto',
        height:
          type === 'icon_button' || 'icon_button_error' || 'icon_button_tertiary'
            ? '38px'
            : '45px',
        borderRadius:
          type === 'icon_button' || 'icon_button_error' || 'icon_button_tertiary'
            ? '5px'
            : '8px',
        ...style,
      }}
      disabled={isDisabled || isLoading}
      onClick={handlePress}
    >
      {iconLeft}
      {isLoading && <Loader size={16} color={theme.colors.White} />}
      {!isLoading && typeof text === 'string' && (
        <Text value={String(text)} color={textColor} format={textFormat} />
      )}
      {!isLoading && typeof text !== 'string' && text}
    </BtnWrapper>
  )
}
