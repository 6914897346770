type TPropertyType = {
  [key: string]: string
}

export const propertyTypeMaps: TPropertyType = {
  CR: 'Acreage',
  ALP: 'Alpine',
  A: 'Apartment',
  BLK: 'Block of Units',
  COMM: 'Commercial',
  D: 'Duplex',
  F: 'Flat',
  H: 'House',
  O: 'Other',
  BUS: 'Professional Services',
  RELOC: 'Relocatable Home',
  LAND: 'Residential Land',
  RET: 'Retirement',
  RURAL: 'Rural',
  S: 'Semi-Detached',
  SA: 'Serviced Apartment',
  STU: 'Studio',
  T: 'Terrace',
  TWN: 'Townhouse',
  U: 'Unit',
  V: 'Villa',
}

export const propertyLabelMaps: TPropertyType = {
  Acreage: 'CR',
  Alpine: 'ALP',
  Apartment: 'A',
  'Block of Units': 'BLK',
  Commercial: 'COMM',
  Duplex: 'D',
  Flat: 'F',
  House: 'H',
  Other: 'O',
  'Professional Services': 'BUS',
  'Relocatable Home': 'RELOC',
  'Residential Land': 'LAND',
  Retirement: 'RET',
  Rural: 'RURAL',
  'Semi-Detached': 'S',
  'Serviced Apartmen': 'SA',
  Studio: 'STU',
  Terrace: 'T',
  TownhouseTWN: 'TWN',
  Unit: 'U',
  Villa: 'V',
}

export const propretyTypeOptions = [
  {
    label: 'Unit',
    value: 'U',
  },
  {
    label: 'House',
    value: 'H',
  },
  {
    label: 'Townhouse',
    value: 'TWN',
  },
  {
    label: 'Flat',
    value: 'F',
  },
  {
    label: 'Duplex',
    value: 'D',
  },
  {
    label: 'Villa',
    value: 'V',
  },
  {
    label: 'Acreage',
    value: 'CR',
  },
  {
    label: 'Alpine',
    value: 'ALP',
  },
  {
    label: 'Apartment',
    value: 'A',
  },
  {
    label: 'Block of Units',
    value: 'BLK',
  },
  {
    label: 'Commercial',
    value: 'COMM',
  },
  {
    label: 'Other',
    value: 'O',
  },
  {
    label: 'Professional Services',
    value: 'BUS',
  },
  {
    label: 'Relocatable Home',
    value: 'RELOC',
  },
  {
    label: 'Residential Land',
    value: 'LAND',
  },
  {
    label: 'Retirement',
    value: 'RET',
  },
  {
    label: 'Rural',
    value: 'RURAL',
  },
  {
    label: 'Semi-Detached',
    value: 'S',
  },
  {
    label: 'Serviced Apartment',
    value: 'SA',
  },
  {
    label: 'Studio',
    value: 'STU',
  },
  {
    label: 'Terrace',
    value: 'T',
  },
]

const spots = new Array(5)
  .fill(null)
  .map((_, index) => ({ value: index, label: `${index}` }))

export const carSpotsOptions = [
  ...spots,
  {
    label: '5+',
    value: 5,
  },
]
