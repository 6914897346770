import styled from 'styled-components'
import { theme } from 'venus/config'

export const Wrapper = styled.div`
  position: relative;
  height: 38px;
  width: 165px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    button {
      background-color: ${theme.colors.LightBlue};
    }
    svg {
      color: ${theme.colors.Primary} !important;
    }
    span {
      color: ${theme.colors.Primary} !important;
    }
  }
`

export const FileSelectDiv = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  input {
    height: 38px;
    width: 165px;
    cursor: pointer;
  }
`

export const FileSelectIconDiv = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  input {
    height: 64px;
    width: 74px;
    cursor: pointer;
  }
`

export const IconArea = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
`

export const UploadContainer = styled.button`
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
`
