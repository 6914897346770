import { format, parse } from 'date-fns'

const DATE_FORMAT = 'dd/MM/yyyy'

export const displayDate = (timestamp: string | number): string => {
  if (!timestamp) return format(new Date(), DATE_FORMAT)
  return format(new Date(timestamp), DATE_FORMAT)
}

export const formateDate = (
  str: string | null,
  dateFormat: string = DATE_FORMAT,
): string => {
  if (!str) return format(new Date(), dateFormat)
  if (/^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/g.test(str)) return str
  return format(new Date(str), DATE_FORMAT)
}

export const formateDateWithYYYYMMDD = (yyyy: number, mm: number, dd: number): string => {
  return format(new Date(yyyy, mm, dd), DATE_FORMAT)
}

export const parseDate = (str: string) => {
  return parse(str, DATE_FORMAT, new Date())
}
