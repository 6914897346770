import _ from 'lodash'
import { EmptyData, Text } from 'venus/components'
import { FREE_PREMIUM, PAID, theme } from 'venus/config'
import { IProperty } from 'venus/redux/features/property/properties'
import { getColorsFromUrl, isColorBackground } from 'venus/utils'
import ContextMenu from './ContextMenu'
import Property from './Property'
import { AddressShader, Item, List } from './PropertyList.styles'

interface IPropertyList {
  type: 'property' | 'share' | 'dream'
  properties?: IProperty[]
  emptyMessage?: string
  vaults?: any
}

const PropertyList = ({ type, properties, vaults, emptyMessage }: IPropertyList) => {
  if (!properties || !properties.length) {
    // TODO: Update empty data component
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyData message={emptyMessage} />
      </div>
    )
  }
  return (
    <List>
      {properties?.map((property) => {
        const subscriptionStatus = _.get(vaults, property.id)

        const isGradient = isColorBackground(property?.coverImage)

        return (
          <Item
            key={property.shareId || property.id}
            coverImage={property?.coverImage}
            isGradient={isGradient}
            colors={isGradient ? getColorsFromUrl(property?.coverImage) : []}
          >
            <AddressShader />
            <Property
              {...property}
              type={type}
              isPremium={subscriptionStatus === FREE_PREMIUM || subscriptionStatus == PAID}
              shareId={property?.shareId}
            />
          </Item>
        )
      })}
    </List>
  )
}

export default PropertyList
