import { useState } from 'react'
import { Image, Pills, Text } from 'venus/components'
import { Container, ImageContainer, ItemsContainer, Space } from './SpaceDisplay.styles'

const SpaceDisplay = ({
  id,
  items,
  isActive,
  isSelected,
  name,
  coverImage,
  onSelectSpace,
  onSelectItem,
}) => {
  const [pressed, setPressed] = useState(false)

  const handleSelectSpace = () => {
    onSelectSpace(id)
    setPressed(!pressed)
  }

  const handleSelectItem = (item) => {
    onSelectItem(item.id)
  }

  return (
    <Container isActive={isActive}>
      <div onClick={handleSelectSpace}>
        <Space isActive={isActive} pressed={isSelected}>
          <ImageContainer>
            <Image enablePreview={false} image={coverImage} />
          </ImageContainer>
          <Text value={name} format='h4' />
        </Space>
      </div>
      {isSelected && (
        <ItemsContainer>
          <Pills
            style={{ marginRight: 0 }}
            options={items}
            handlePress={handleSelectItem}
          />
        </ItemsContainer>
      )}
    </Container>
  )
}

export default SpaceDisplay
