import styled from 'styled-components'

export const List = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 24px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
`

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  padding: 0;
  border: 0;

  display: flex;
`
