import styled from "styled-components"

export const AppStoreWrapper = styled.img`
  height: 80px;
  cursor: pointer;
`

export const GooglePlayWrapper = styled(AppStoreWrapper)`
  margin: 24px;
`

export const ButtonsArea = styled.div`
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 36px;
`
