import { useState } from 'react'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import ScanHome from 'venus/common/ScanHome'
import Dropzone from './Dropzone'
import { useAppDispatch } from 'venus/redux/hooks'
import { setScanHomeDone } from 'venus/redux/features/onboarding'

const OnboardingScanHome = ({ setStep, setTitle }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [scanIndex, setScanIndex] = useState(0)
  const dispatch = useAppDispatch()

  const goNextScan = async (indexes) => {
    console.log('scanIndex', indexes, selectedFiles, scanIndex)

    if (scanIndex + 1 === selectedFiles.length) {
      if (indexes && indexes.length) {
        // upload(indexes)
      }

      //   reset()
      setScanIndex(0)
      setSelectedFiles([])

      dispatch(setScanHomeDone(true))
      setStep(7)
      setTitle('Upload Documents')
    } else {
      setScanIndex((index) => index + 1)
    }
  }

  return (
    <>
      <Text
        style={{ margin: 24, textAlign: 'center' }}
        color={theme.colors.Black}
        format='body'
        value={'Scan Home'}
      />
      <Dropzone upload={setSelectedFiles} />
      {!!selectedFiles.length && (
        <ScanHome
          goNext={goNextScan}
          individual
          space={null}
          isOpen
          close={goNextScan}
          image={selectedFiles[scanIndex]}
        />
      )}
    </>
  )
}

export default OnboardingScanHome
