import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Input, ActionLayout } from 'venus/components'
import {
  ChangePwdContainer,
  ChangePwdBodyWrapper,
} from 'venus/layout/ProfileMenu/ProfileSettings/ChangePassword/ChangePassword.styles'
import {
  NEW_PASSWORD_NOT_MATCH,
  CONFIRM_PASSWORD,
} from 'venus/layout/ProfileMenu/ProfileSettings/ChangePassword/Constants'
import { SettingsHeader } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader'

type ChangePasswordData = {
  newPassword: string
  confirmPassword: string
}

const ChangePasswordForm = ({
  onSubmit,
  handleCloseSettings,
  setConfirmDeleteAccount,
}: {
  onSubmit: (data: ChangePasswordData) => void
  handleCloseSettings: Function
  setConfirmDeleteAccount: Function
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ChangePasswordData>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    }
  })
  const inputs = watch()

  const handleOnClickDelete = async () => {
    handleCloseSettings()
    setConfirmDeleteAccount(true)
  }

  return (
    <ActionLayout
      margin={28}
      children={
        <ChangePwdContainer>
          <SettingsHeader title={'Change Password'}/>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ChangePwdBodyWrapper>
              <Controller
                name={'newPassword'}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    type="password"
                    label="New Password"
                    isError={!!errors.newPassword}
                    errorMsg={NEW_PASSWORD_NOT_MATCH}
                    {...field}
                    style={{
                      marginBottom: '18px'
                    }}
                  />
                )}
              />
              <Controller
                name={'confirmPassword'}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    type="password"
                    label="Confirm Password"
                    isError={inputs.newPassword !== inputs.confirmPassword}
                    errorMsg={CONFIRM_PASSWORD}
                    {...field}
                  />
                )}
              />
            </ChangePwdBodyWrapper>
          </form>
        </ChangePwdContainer>
      }
      leftActions={[
        {
          type: 'icon_button_error',
          text: 'Delete Account',
          onClick: handleOnClickDelete,
          color: 'ErrorState',
        }
      ]}
      rightActions={[
        {
          type: 'primary',
          isSubmit: true,
          onClick: handleSubmit(onSubmit),
          text: 'Update',
        }
      ]}
    />
  )
}

export default ChangePasswordForm