import { IAddress } from 'venus/types/Property'
import pluralize from 'pluralize'
import _ from 'lodash'

interface IPropertyData {
  area: number
  baths: number
  beds: number
  parking?: number
  propertyType?: string
  name?: string
}

export const getAddressPreviewStr = (propertyData: IPropertyData) => {
  if (!propertyData || _.isEmpty(propertyData)) {
    return ''
  }
  const { beds, baths, area, propertyType } = propertyData
  return `${pluralize('bedroom', beds, true)} • ${pluralize('bathroom', baths, true)} • ${
    area || 'n/a'
  } sqm • ${propertyType || 'House'}`
}

export const addressPreviewStr = ({ beds, baths, area }: IPropertyData) =>
  `${pluralize('bedroom', beds, true)} ${pluralize('bathroom', baths, true)} ${area}sqm`

export const formatFullAddress = (addressObject: IAddress) => {
  if (!addressObject) {
    return ''
  }

  const { address, suburb, state, postcode } = addressObject
  return `${address}, ${suburb} ${state} ${postcode}`
}
export const getPropertyName = (property: {
  propertyData: IPropertyData
  address: IAddress
}) => {
  return property ? property.propertyData?.name || property.address?.address || '' : ''
}

export const getFullPropertyName = (property: any) => {
  return property ? property.propertyData?.name || formatFullAddress(property.address) : ''
}
