import { useEffect, useMemo, useState } from 'react'
import { addDoc, editDocs, homeDocs } from 'venus/redux/features/onboarding'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { theme } from 'venus/config'
import { Text, Button, Loader } from 'venus/components'
import { EditDoc } from './EditDoc'
import { BtnArea, EditWrapper, EditContainer } from './UploadDocs.styles'
import Dropzone from './Dropzone'
import api from 'venus/api'
import { getExtension } from 'mime'
import axios from 'axios'
import DocList from './DocList'

interface IUploadDocs {
  setStep: (s: number) => void
  setTitle: (s: string) => void
}

const getUploadConfigs = async (files) => {
  if (!files) {
    return null
  }

  try {
    const config = files.map((file) => {
      const mimeType = file?.type || 'image/jpeg'

      const ext = getExtension(mimeType || 'jpg')
      const fileName = file?.name

      return { name: fileName, type: mimeType, ext }
    })
    // const getUrlResponse = await api.post('files', filesInfo)
    // return getUrlResponse.data
    const uploadConfig = await api.post('/files', config).then((res) => res.data)
    return uploadConfig
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export const UploadDocs = ({ setStep, setTitle }: IUploadDocs) => {
  const dispatch = useAppDispatch()
  const docs = useAppSelector(homeDocs)
  const [hasUploaded, setHasUploaded] = useState(false)
  const [contentTypeModal, setContentTypeModal] = useState(false)
  const [selected, setSelected] = useState(-1)
  // const [docContentType, setDocContentType] = useState([])
  // const [newName, setNewName] = useState([])
  // const [uri, setUri] = useState([])
  const [uploading, setUploading] = useState([])
  console.log('docs::', docs)

  const { loading } = useAppSelector((state) => state.docs)

  useEffect(() => {
    if (uploading.length) {
      const newImages = uploading.filter((u) => u.url)
      if (newImages.length === uploading.length) {
        setContentTypeModal(true)
      }

      console.log('newImages', newImages, uploading)
    }
  }, [uploading])

  const isSaveDisabled = useMemo(() => {
    if (uploading.length) {
      return uploading.some((upload) => !upload.name || !upload.contentType)
    }
    return true
  }, [uploading])

  if (loading) return <Loader isFull />

  const setNewName = (nameArr) => {
    setUploading(nameArr.map((name, index) => ({ ...uploading[index], name })))
  }
  const setDocContentType = (typeArr) => {
    setUploading(
      typeArr.map((contentType, index) => ({ ...uploading[index], contentType })),
    )
  }

  const uploadDocs = async (files) => {
    const uploadConfig = await getUploadConfigs(files)

    setUploading(
      uploadConfig.map((config) => ({
        progress: 0,
        url: '',
        doc: null,
      })),
    )

    files.forEach((file, index) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        setUploading((uploadingConfig) => {
          const uploadingFile = [...uploadingConfig]
          uploadingFile[index].doc = reader.result
          uploadingFile[index].name = file.name
          return uploadingFile
        })
      }
    })

    files.forEach((file, index) => {
      const reader = new FileReader()

      reader.readAsArrayBuffer(file)

      reader.onload = () => {
        const fileType = file.type
        const { uploadURL } = uploadConfig[index]
        const content = reader.result
        axios
          .put(uploadURL, content, {
            headers: {
              'Content-Type': String(fileType),
            },
            onUploadProgress: (progress: ProgressEvent) => {
              const progressPercentage = Math.round(
                (progress.loaded / progress.total) * 100,
              )
              setUploading((uploadingConfig) => {
                const uploadingFile = [...uploadingConfig]
                uploadingFile[index].progress = progressPercentage
                return uploadingFile
              })
            },
          })
          .then((res) => {
            // dispatch(setUrl(uploadConfig.url))
            console.log('upl', uploadConfig)

            setUploading((uploadingConfig) => {
              const uploadingFile = [...uploadingConfig]
              uploadingFile[index].url = uploadConfig[index].url
              return uploadingFile
            })
            return uploadConfig[index].url
          })
      }
    })
  }

  const handlePressPlus = () => {
    setContentTypeModal(true)
    setHasUploaded(true)
  }

  const handlePressNext = () => {
    setStep(8)
    setTitle('Assign Documents')
  }

  const handlePressCancel = () => {
    setContentTypeModal(false)
  }

  const handlePressSaveEdit = () => {
    dispatch(
      addDoc({
        uri: uploading.map((upload) => upload.url),
        filename: uploading.map((upload) => upload.name),
        contentType: uploading.map((upload) => upload.contentType),
      }),
    )
    setContentTypeModal(false)
    setHasUploaded(true)
  }

  const removeDoc = (index) => {
    const updated = docs.filter((doc) => doc.uri !== docs[index].uri)

    dispatch(editDocs(updated))
  }

  if (contentTypeModal)
    return (
      <EditContainer>
        <EditWrapper>
          {uploading.map((upload, index) => {
            return (
              <EditDoc
                index={index}
                uri={upload.url}
                newName={uploading.map((u) => u.name)}
                setNewName={setNewName}
                docContentType={uploading.map((u) => u.contentType)}
                setDocContentType={setDocContentType}
                key={upload.url}
              />
            )
          })}
        </EditWrapper>
        <BtnArea>
          <Button
            style={{ height: '36px', padding: '4px 28px', width: 'auto', marginRight: 15 }}
            onClick={handlePressCancel}
            text='Cancel'
            type='secondary'
          />
          <Button
            style={{ height: '36px', padding: '4px 28px', width: 'auto' }}
            onClick={handlePressSaveEdit}
            text='Save'
            isDisabled={isSaveDisabled}
          />
        </BtnArea>
      </EditContainer>
    )

  return (
    <>
      <Text
        style={{ margin: 24, textAlign: 'center' }}
        color={theme.colors.Black}
        format='body'
        value={'Upload documents to add to your vault. You can add up to three documents'}
      />
      {docs.length < 3 && <Dropzone uploadDocs={uploadDocs} />}
      {docs.length > 0 && <DocList docs={docs} removeDoc={removeDoc} />}

      <BtnArea
        style={{
          flex: 1,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          paddingBottom: 28,
          alignSelf: 'flex-end',
        }}
      >
        {hasUploaded && (
          <Button
            onClick={handlePressNext}
            text='Finish uploading'
            style={{ height: '36px', padding: '4px 28px', width: 'auto' }}
          />
        )}
      </BtnArea>
    </>
  )
}
