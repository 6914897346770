import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import api from 'venus/api'
import {
  IRemoveSpace,
  ICreateBulkItemsAsync,
  IUpdateItemAsync,
  IRemoveItemAsync,
} from './types'

export const createBulkItems = createAsyncThunk(
  'item/createBulk',
  async ({ propertyId, items }: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/property/${propertyId}/items`, items)
      console.log('createBulkItems response::', response)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const bulkCreateItemsInSpaces = createAsyncThunk(
  'item/bulkCreateItemsInSpaces',
  async (
    { propertyId, checkedItems }: ICreateBulkItemsAsync,
    { rejectWithValue }
  ) => {
    try {
      const requests = checkedItems.map(({ items, spaceId }) => {
        console.log('url::', `/property/${propertyId}/space/${spaceId}/items`)
        console.log('items::', items)
        return api.post(`/property/${propertyId}/space/${spaceId}/items`, items)
      })
      const response = await axios.all(requests)
      response.forEach((r) =>
        console.log('bulkCreateItemsInSpaces response::', r.data)
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const getItemTemplate = createAsyncThunk(
  'item/getItemTemplate',
  async (propertyId: string, { rejectWithValue }) => {
    try {
      const response = await api.get(`/property/${propertyId}/itemTemplate`)
      console.log('getItemTemplate response::', response.data)
      return response.data
    } catch (err) {
      console.log('getItemTemplate::', err)
      return rejectWithValue(err)
    }
  }
)

export const editItemAsync = createAsyncThunk(
  'item/update',
  async (
    { propertyId, spaceId, itemId, details }: IUpdateItemAsync,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put(
        `/property/${propertyId}/space/${spaceId}/item/${itemId}`,
        details
      )
      console.log('editItem response::', response)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const removeItem = createAsyncThunk(
  'item/remove',
  async (
    { propertyId, spaceId, itemId }: IRemoveItemAsync,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.delete(
        `/property/${propertyId}/space/${spaceId}/item/${itemId}`
      )
      console.log('removeItem response::', response)
      return response.data
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
