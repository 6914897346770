import styled from 'styled-components'

type EmptyDataContainerProps = {
  readonly fullBlock?: boolean
}

export const EmptyDataContainer = styled.div<EmptyDataContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  ${({ fullBlock }) => fullBlock && `height: 100%;`}
`
