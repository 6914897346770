import styled from 'styled-components'
import { theme } from 'venus/config'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const StarWrapper = styled.div`
  display: flex;
  max-width: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;
`

export const RatingArea = styled.div`
  display: flex;
  width: fit-content;
  height: 29px;
  padding: 2px 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${theme.colors.LightBlue};
`
