import { useState } from 'react'
import { Text, Row, Select as ReactSelect } from 'venus/components'
// import ReactSelect from 'venus/components/Select'
import Select from 'venus/components/Select/Select'

export const DropdownSample = ({ index, title }: { index: number; title: string }) => {
  const [newRole1, setNewRole1] = useState('OWNER')
  const [newRole2, setNewRole2] = useState('')

  const roles = [
    { label: 'Home Owner', value: 'OWNER' },
    { label: 'Tradesperson', value: 'TRADESPEOPLE' },
    { label: 'Real Estate Agent', value: 'AGENT' },
    { label: 'Property Manager', value: 'MANAGER' },
    { label: 'Builder', value: 'BUILDER' },
    { label: 'Developer', value: 'DEVELOPER' },
    { label: 'Other', value: 'OTHER' },
  ]

  return (
    <div style={{ width: 500 }}>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Row height={120}>
        <ReactSelect title='Role' options={roles} value={newRole1} onChange={setNewRole1} />
      </Row>
      <Row height={120}>
        <ReactSelect
          title='Role'
          options={roles}
          value={newRole2}
          onChange={setNewRole2}
          isError={!newRole2}
          errorMsg='This is error state.'
        />
      </Row>

      <Row height={120}>
        <ReactSelect
          title='Role'
          options={roles}
          value={newRole2}
          onChange={setNewRole2}
          // isError={!newRole2}
          // errorMsg='This is error state.'
        />
      </Row>
    </div>
  )
}
