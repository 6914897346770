import React from 'react'
import { Text } from 'venus/components'
import { PropertyCardView } from 'venus/components'
import { PropertyListCardView } from 'venus/components'

export const PropertyCardViewSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const testProperty = {
    id: '1',
    address: {
      state: 'NSW',
      suburb: 'Chatswood',
      address: '2839/100 Victoria Avenue',
      postcode: '2067'
    },
    propertyData: {
      area: 500,
      beds: 3,
      baths: 2,
      parking: 1,
    },
    spacesId: [],
    propertyWideSpaceId: '',
    images: [],
    spaces: [],
    coverImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      {/* Confirmation Card */}
      <PropertyCardView property={testProperty} isSelected={true} showPropertyData={false}/>
      <PropertyCardView property={testProperty} isSelected={false} showPropertyData={false}/>
      <PropertyCardView
        property={testProperty}
        imageHeight={240}
        isSelected={true}
        showPropertyData={true}
      />
      {/* Property Card as a list element */}
      <PropertyListCardView
        property={testProperty}
        isSelectable={true}
        isChecked={true}
      />
      <PropertyListCardView
        imageWidth={136}
        imageHeight={106}
        property={testProperty}
        isSelectable={false}
        showPropertyData={true}
      />
    </>
  )
}
