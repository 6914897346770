import { useState } from 'react'
import { Image } from 'venus/components'
import { theme } from 'venus/config'
import { CoverImageContainer } from './PropertyDetails.styles'
import { ReactComponent as Chevron } from 'venus/assets/img/icons/Chevron.svg'
import { Action } from './CoverImage.styles'

const CoverImage = ({ image, images }: { image?: string; images: any }) => {
  const hasImages = images && images.length

  const [index, setIndex] = useState(image ? -1 : 0)

  const prevImage = () => setIndex(index - 1)
  const nextImage = () => setIndex(index + 1)

  const hasPrev = image ? index > -1 : index > 0

  if (hasImages) {
    return (
      <CoverImageContainer>
        {index === -1 ? (
          <Image
            enablePreview={false}
            key={image}
            style={{ aspectRatio: '16 / 9' }}
            image={image}
          />
        ) : (
          <Image
            enablePreview={false}
            key={image}
            style={{ aspectRatio: '16 / 9' }}
            image={images[index].url}
          />
        )}
        {hasPrev && (
          <Action style={{ left: 10 }} onClick={prevImage}>
            <Chevron color={theme.colors.White} style={{ transform: 'rotate(90deg)' }} />
          </Action>
        )}
        {index < images.length - 1 && (
          <Action style={{ right: 10 }} onClick={nextImage}>
            <Chevron color={theme.colors.White} style={{ transform: 'rotate(-90deg)' }} />
          </Action>
        )}
      </CoverImageContainer>
    )
  }
  if (image) {
    return (
      <CoverImageContainer>
        <Image
          enablePreview={false}
          key={image}
          style={{ aspectRatio: '16 / 9' }}
          image={image}
        />
      </CoverImageContainer>
    )
  }
  return (
    <CoverImageContainer>
      <Image
        key='no-image'
        enablePreview={false}
        style={{ aspectRatio: '16 / 9' }}
        image='/'
      />
    </CoverImageContainer>
  )
}

export default CoverImage
