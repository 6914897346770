import styled from 'styled-components'
import { theme } from 'venus/config'

export const LeftArea = styled.div`
  width: 228px;
  height: 680px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 40px;
  align-self: flex-start;
  background-color: ${theme.colors.LighterGrey};
  border-radius: 9px 0 0;
`

export const RightArea = styled.div`
  width: 452px;
  height: 680px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`

export const HeaderArea = styled.div`
  width: calc(100% - 68px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.White};
  padding: 28px 0;
`

export const BodyArea = styled.div`
  background-color: ${theme.colors.White};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  min-height: 0;

  border-radius: 9px;
`

export const IconButton = styled.div`
  cursor: pointer;
`

export const BackButton = styled.div`
  cursor: pointer;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const HeaderPlaceHolder = styled.div`
  width: 28px;
`
