import { useEffect, useState } from 'react'
import api from 'venus/api'

const useFetchTemplate = ({
  propertyId,
  spaceId,
}: {
  propertyId: string
  spaceId: string
}) => {
  const [template, setTemplate] = useState([])

  useEffect(() => {
    const fetchTemplates = async () => {
      const response = await api.get(`/property/${propertyId}/itemTemplate`, {
        params: { spaceId },
      })

      console.log('fetch template', response.data)

      setTemplate(response.data.itemTemplate)
    }

    fetchTemplates()
  }, [propertyId, spaceId])

  return template
}

export default useFetchTemplate
