export const getTabsForDreamProperty = (
  spaces: any[] | undefined,
  items: any[] | undefined,
) => {
  const tabs = ['Details']
  if (!!spaces) tabs.push('Spaces')
  if (!!items) tabs.push('Items')
  tabs.push('Photos')
  tabs.push('Videos')
  return tabs
}

export const getTabsForProperty = (
  isShared: boolean,
  isDreamProperty: boolean,
  isShareDocument: boolean,
  isShareHistory: boolean,
  spaces: any[] | undefined,
  items: any[] | undefined,
) => {
  const tabs = ['Details']
  const isDisplaySpaces =
    !(isShared || isDreamProperty) ||
    ((isShared || isDreamProperty) && !!spaces && spaces.length)
  const isDisplayItems =
    !(isShared || isDreamProperty) ||
    ((isShared || isDreamProperty) && !!items && items.length)
  const isDisplayDocument = !isShared || (isShared && isShareDocument)
  const isDisplayHistory = !isShared || (isShared && isShareHistory)
  if (isDisplaySpaces) tabs.push('Spaces')
  if (isDisplayItems) tabs.push('Items')
  tabs.push('Photos')
  tabs.push('Videos')

  // console.log('isDreamProperty', isShared, isDreamProperty, items, isDisplayItems)

  if (!isDreamProperty) {
    if (isDisplayDocument) tabs.push('Documents')
    if (isDisplayHistory) tabs.push('History')
    if (!isShared) tabs.push('Tradespeople')
  }

  return { tabs, isDisplaySpaces, isDisplayItems, isDisplayDocument, isDisplayHistory }
}
