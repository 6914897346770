import { Text } from 'venus/components'
import ActionBar from 'venus/components/ActionBar'
import { Container, Content } from './EditSpaces.styles'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import SpaceItem from './SpaceItem'
import { useState } from 'react'
// import reorder from '../reorder'

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const EditSpaces = ({ spaces, onSubmit, onDelete }) => {
  const [list, setList] = useState(spaces)
  const [isLoading, setIsLoading] = useState(false)

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return
    }

    const newSpaces = reorder(list, result.source.index, result.destination.index)

    setList(newSpaces)
  }

  const handleSave = async () => {
    setIsLoading(true)
    await onSubmit(list)
    setIsLoading(false)
  }

  return (
    <Container>
      <Text format='body' style={{ textAlign: 'center' }}>
        Drag and drop to reorder your spaces.
      </Text>

      <DragDropContext onDragEnd={onDragEnd}>
        <Content>
          <Droppable droppableId='spaces'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {list.map((space, index) => (
                  <Draggable index={index} draggableId={space.id}>
                    {(draggableProvided, snapshot) => (
                      <SpaceItem
                        provided={draggableProvided}
                        {...space}
                        onDelete={onDelete}
                        snapshot={snapshot}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </Content>
      </DragDropContext>
      <ActionBar rightActions={[{ text: 'Save', type: 'primary', onClick: handleSave }]} />
    </Container>
  )
}

export default EditSpaces
