import styled from 'styled-components'
import { theme } from 'venus/config'

export const PropertyContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  /* aspect-ratio: 1.5; */
  position: relative;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 50px;
`

export const SwipeView = styled.div`
  background-color: ${theme.colors.ErrorState};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 24px;
  margin-top: 0;
  padding: 24px;
`

export const LabelArea = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
`
