export const isTempUrl = (url: string) => {
  const match = url.match(/^(?:https?:)?(?:\/\/)?([^\/\?]+)/i)
  const hostname = match && match[1]
  return hostname && hostname.includes('tmp')
}

export const extractFileFromUrl = (url: string): string => {
  const tempUrl = new URL(url)

  const segments = url.split('/')
  if (!segments.length) {
    return ''
  }
  const lastSegment = segments.pop()

  return lastSegment || ''
}

export const buildURLQuery = (obj) =>
  Object.entries(obj)
    .filter(([key, value]) => value)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&')
