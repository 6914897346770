import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useNavigate } from 'react-router-dom'
import { deleteUser } from 'venus/redux/features/user'
import { Text, ActionLayout, Modal, Input } from 'venus/components'
import { ChangePwdContainer } from 'venus/layout/ProfileMenu/ProfileSettings/ChangePassword/ChangePassword.styles'
import {
  CANCEL_BUTTON_TEXT,
  DELETE_BUTTON_TEXT,
  DELETE_ACCOUNT_TITLE,
  ONCLICK_DELETE_MESSAGE,
  CONFIRM_USER_INPUT_TITLE
} from 'venus/layout/ProfileMenu/ProfileSettings/DeleteAccount/Constants'
import {
  DeleteMessageWrapper,
  UserNameInputWrapper
} from 'venus/layout/ProfileMenu/ProfileSettings/DeleteAccount/DeleteAccount.styles'
import {
  INVALID_INPUT_MSG
} from 'venus/layout/ProfileMenu/ProfileSettings/TransferOwnership/Constants'
import { getFullName } from 'venus/utils/fullName'

const DeleteAccount = ({
  setConfirmDeleteAccount
}: {
  setConfirmDeleteAccount: Function
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showConfirmModal, setShowConfirmModal] = useState(true)
  const [confirmUserName, setConfirmUserName] = useState('')
  const { info } = useAppSelector((state) => state.user)

  const lowerCaseUserName = getFullName(info).toLowerCase()

  const handleOnclickCancel = () => {
    setConfirmDeleteAccount(false)
    setShowConfirmModal(false)
  }

  const handleOnClickDelete = async () => {
    const result = await dispatch(deleteUser())
    if (result?.payload === 'Success') {
      navigate('/logout')
    }
  }

  return (
    <Modal
      isOpen={showConfirmModal}
      height={380}
      showCloseButton={false}
      title={DELETE_ACCOUNT_TITLE}
    >
      <ActionLayout
        margin={28}
        children={
          <ChangePwdContainer>
            <DeleteMessageWrapper>
              <Text
                format={'h2'}
                fontSize={14}
                fontWeight={400}
                lineHeight={'21px'}
                value={ONCLICK_DELETE_MESSAGE}
                style={{ textAlign: 'center' }}
              />
            </DeleteMessageWrapper>
            <UserNameInputWrapper>
              <Input
                type={'text'}
                label={CONFIRM_USER_INPUT_TITLE}
                value={confirmUserName}
                onChange={setConfirmUserName}
                isError={!!confirmUserName.length && !confirmUserName.trim()}
                errorMsg={INVALID_INPUT_MSG}
              />
            </UserNameInputWrapper>
          </ChangePwdContainer>
        }
        leftActions={[
          {
            type: 'secondary',
            text: CANCEL_BUTTON_TEXT,
            onClick: handleOnclickCancel,
          },
        ]}
        rightActions={[
          {
            type: 'icon_button_error',
            text: DELETE_BUTTON_TEXT,
            onClick: handleOnClickDelete,
            isDisabled: lowerCaseUserName !== confirmUserName.toLowerCase()
          }
        ]}
      />
    </Modal>
  )
}

export default DeleteAccount