import axios from 'axios'
import _ from 'lodash'
import { getExtension } from 'mime'
import React, { useEffect, useRef, useState } from 'react'
import api from 'venus/api'
import {
  reset,
  setProgress,
  setUrl,
  setVideo,
  setVideos,
} from 'venus/redux/features/video/video'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'

const getRandom = () => Math.random().toString(36)

const getVideoDimensionsOf = (url) => {
  return new Promise((resolve) => {
    // create the video element
    const video = document.createElement('video')

    // place a listener on it
    video.addEventListener(
      'loadedmetadata',
      (event) => {
        // retrieve dimensions
        const height = video.videoHeight
        const width = video.videoWidth
        resolve({ duration: `${video.duration}`, height: `${height}`, width: `${width}` })
      },
      false,
    )

    // start download meta-datas
    video.src = url
  })
}

const getUploadConfig = async (file) => {
  // const mimeType = getType(video)
  const mimeType = file?.type || 'video/mp4'
  const fileName = file?.name
  const ext = getExtension(mimeType || 'mp4')

  const uploadConfig = await api
    .post('/images', [{ name: fileName, type: mimeType, ext }])
    .then((res) => res.data)
  return uploadConfig[0]
}

const VideoUploader = ({ children, propertyId }) => {
  const fileInput = useRef()
  const [selectedFile, setSelectedFile] = useState()
  const [randomKey, setRandomKey] = useState(getRandom())
  const dispatch = useAppDispatch()
  const [metaData, setMetaData] = useState({})

  const { url, progress, image } = useAppSelector((state) => state.video)

  const uploadImage = async (uploadConfig, video) => {
    const { uploadURL } = uploadConfig

    const fileType = video.type

    // setting up the reader
    const reader = new FileReader()
    reader.readAsArrayBuffer(video)
    // reader.readAsText(image, 'UTF-8')

    // here we tell the reader what to do when it's done reading...
    reader.onload = async () => {
      const content = reader.result // this is the content!

      try {
        await axios
          .put(uploadURL, content, {
            headers: {
              'Content-Type': String(fileType),
            },
            onUploadProgress: (progress: ProgressEvent) => {
              const progressPercentage = Math.round(
                (progress.loaded / progress.total) * 100,
              )
              dispatch(setProgress(progressPercentage))
            },
          })
          .then((res) => {
            dispatch(setUrl(uploadConfig.url))

            return res.status
          })
          .catch((error) => {
            console.log('error', error.response.data)
            return 403
          })

        // return uploadConfig
      } catch (error) {
        console.log('error in upload', error)
      }
    }
  }

  const handleClick = (event) => {
    if (fileInput.current) {
      // @ts-ignore
      fileInput.current?.click()
    }
  }

  const onChange = (event) => {
    setSelectedFile(_.get(event, 'target.files[0]'))
  }

  const processVideo = async () => {
    const reader = new FileReader()

    reader.readAsDataURL(selectedFile)
    reader.onload = async () => {
      // console.log('reader.result', reader)

      const dimension = await getVideoDimensionsOf(reader.result)
      setMetaData(dimension)
      console.log('dimension', dimension)
      dispatch(setVideo(reader.result))
    }

    const config = await getUploadConfig(selectedFile)

    await uploadImage(config, selectedFile)

    setRandomKey(getRandom())
    setSelectedFile(undefined)
    // await dispatch(reset())
  }

  useEffect(() => {
    ;(async () => {
      if (selectedFile) {
        await processVideo()
      }
    })()
  }, [selectedFile])

  useEffect(() => {
    const addVideo = async () => {
      try {
        const updated = await api
          .post(`/property/${propertyId}/video`, { url, metaData })
          .then((res) => res.data)
        dispatch(reset())
        dispatch(setVideos(updated))
      } catch (error) {
        dispatch(reset())
        console.log('ERROR in image upload', error.data)
      }
    }

    if (url) {
      addVideo()
    }

    return () => dispatch(reset())
  }, [url])

  return (
    <>
      <input
        key={randomKey}
        type='file'
        accept='video/*'
        style={{ display: 'none' }}
        ref={fileInput}
        onChange={onChange}
      />
      {React.cloneElement(children, { onClick: handleClick })}
    </>
  )
}

export default VideoUploader
