import React from 'react'
import { Text } from 'venus/components'
import { Container } from 'venus/components/PropertyListCardView/PropertyListCardView.styles'
import { IProperty } from 'venus/redux/features/property/properties'
import { Item } from 'venus/components/PropertyListCardView/PropertyListCardView.styles'
import { Radio } from 'venus/components/PropertyListCardView/PropertyListCardView.styles'
import { formatFullAddress } from 'venus/utils'
import { RadioWrapper } from 'venus/components/PropertyListCardView/PropertyListCardView.styles'
import { getAddressPreviewStr } from 'venus/utils'
import { PropertyDataWrapper } from 'venus/components/PropertyListCardView/PropertyListCardView.styles'
import { theme } from 'venus/config'
import { ReactComponent as Tick } from 'venus/assets/img/icons/Tick.svg'

const PropertyListCardView = ({
  imageWidth = 96,
  imageHeight = 64,
  property,
  isSelectable = false,
  isChecked = false,
  onClick,
  showPropertyData,
  titleStyle,
}: {
  imageWidth?: number,
  imageHeight?: number,
  property: IProperty,
  isSelectable: boolean,
  isChecked?: boolean,
  onClick?: Function,
  showPropertyData?: boolean,
  titleStyle?: {},
}) => {
  const handleOnClick = () => {
    onClick(property)
  }

  return (
    <Container onClick={handleOnClick}>
      <Item key={property?.id}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            coverImage={property?.coverImage}
      >
      </Item>
      <PropertyDataWrapper>
        <Text
          format={'h4'}
          fontWeight={400}
          lineHeight={'21px'}
          value={formatFullAddress(property?.address)}
          style={titleStyle}
        />
        {showPropertyData && (
          <Text
            format={'body'}
            fontWeight={400}
            lineHeight={'21px'}
            color={theme.colors.DarkGrey}
            value={getAddressPreviewStr(property?.propertyData)}
          />
        )}
      </PropertyDataWrapper>
      {isSelectable && (
        <RadioWrapper>
          <Radio type="radio" defaultChecked={isChecked}/>
          {isChecked && (
            <Tick style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: '14px',
              transform: 'translate(-50%, -50%)',
              stroke: theme.colors.Secondary,
              strokeWidth: 2,
            }}/>
          )}
        </RadioWrapper>
      )}
    </Container>
  )
}

export default PropertyListCardView