import { useEffect } from 'react'
import {
  editItem,
  setConfirmHomeLayoutDone,
  bulkCreateItemsInSpaces,
  getItemTemplate,
} from 'venus/redux/features/onboarding'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { Button, Text, MultiItemSelect, Loader } from 'venus/components'
import { BtnArea, ItemsArea, Wrapper } from './AddItems.styles'

interface IAddItems {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  isNonNdp: boolean
}

export const AddItems = ({ setStep, setTitle, isNonNdp }: IAddItems) => {
  const dispatch = useAppDispatch()
  const { homeItems, loading } = useAppSelector((state) => state.onboarding)
  const { spaces } = useAppSelector((state) => state.property)
  const { propertyId, property } = useAppSelector((state) => state.property)

  useEffect(() => {
    dispatch(getItemTemplate(propertyId))
  }, [propertyId, dispatch])

  const handlePressSave = async () => {
    const checkedItems: any = []
    homeItems.forEach(({ name: spaceLabel, itemTemplate }) => {
      const checked = itemTemplate.filter(({ checked }) => checked === true)
      if (checked.length > 0) {
        let spaceId
        if (spaceLabel === 'Property Wide') {
          spaceId = property.propertyWideSpaceId
        } else {
          spaceId = spaces.find(({ name }) => name === spaceLabel)?.id
        }
        checkedItems.push({
          spaceLabel,
          spaceId,
          items: checked.map(({ name, type }) => ({ name, typeId: type })),
        })
      }
    })
    if (checkedItems.length > 0)
      await dispatch(
        bulkCreateItemsInSpaces({
          propertyId,
          checkedItems,
        })
      )

    dispatch(
      setConfirmHomeLayoutDone({
        isConfirmHomeLayoutDone: true,
      })
    )
    setStep(isNonNdp ? 4 : 5)
    setTitle('Images')
  }

  const handleClickCheck = (spaceLabel: string, itemName: string) => {
    dispatch(
      editItem({
        spaceLabel,
        itemName,
      })
    )
  }

  return (
    <Wrapper>
      <Text
        style={{ marginBottom: 48 }}
        format='body'
        value={'Select items to add to your Vault'}
      />
      <ItemsArea>
        {homeItems.length > 0 &&
          homeItems.map(({ id, itemTemplate, name }) => (
            <MultiItemSelect
              key={`space_items_${id}`}
              spaceLabel={name}
              items={itemTemplate}
              handleCheck={handleClickCheck}
            />
          ))}
        {homeItems.length === 0 && <Loader isFull />}
      </ItemsArea>
      <BtnArea>
        <Button
          style={{ height: '36px', padding: '4px 28px', width: 'auto' }}
          onClick={handlePressSave}
          isLoading={loading}
          text='Save'
        />
      </BtnArea>
    </Wrapper>
  )
}
