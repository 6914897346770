import { useState } from 'react'
import { TSpace } from 'venus/redux/features/onboarding'
import { SpaceDropdown, OnboardingSpaceDropdown } from 'venus/config'
import { getErrorMsg } from 'venus/utils'
import { Wrapper, Content } from './AddSpace.styles'
import { Button, AlertBox, Select, Input } from 'venus/components'
import _ from 'lodash'

interface IAddSpaceParams {
  onSubmit: (s: TSpace) => Promise<void>
  isOnboarding?: boolean
}

const AddSpace = ({
  onSubmit,

  isOnboarding,
}: IAddSpaceParams) => {
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handlPressAlertOK = () => {
    setError('')
  }

  const handleSubmit = async () => {
    // if (!name || !_.trim(name)) {
    //   return setError('Space name can not be empty.')
    // }

    setIsLoading(true)
    const typeValue = type === 'FAMILY' ? 'LIVING' : type
    try {
      await onSubmit({ name, type: typeValue, checked: true })
      // await setSpaces([...spaces, { name, type: typeValue, checked: true }])
      setIsLoading(false)
      // setStep(1)
      // setTitle('Home Layout')
    } catch (error) {
      setIsLoading(false)
      const err = error as any
      const errors = getErrorMsg(err.data)
      if (Array.isArray(errors)) {
        let message = ''
        errors.forEach(({ msg }) => {
          message += `${msg} `
        })
        setError(message)
      } else {
        setError(errors)
      }
    }
  }

  return (
    <Content>
      <Wrapper>
        <Input style={{ marginBottom: 18 }} label='Name' value={name} onChange={setName} />
        <Select
          title='Space Type'
          options={isOnboarding ? OnboardingSpaceDropdown : SpaceDropdown}
          value={type}
          onChange={(type: any) => {
            const option = SpaceDropdown.find(({ value }) => value === type) as any
            if (!name) {
              setName(option.label)
            }
            setType(type)
          }}
        />
      </Wrapper>

      <Button
        isLoading={isLoading}
        onClick={handleSubmit}
        text='Save'
        isDisabled={!name || !_.trim(name) || !type}
        style={{
          height: '36px',
          padding: '4px 28px',
          width: 'auto',
          alignSelf: 'flex-end',
          marginRight: '24px',
        }}
      />
    </Content>
  )
}

export default AddSpace
