export const getErrorMsg = (err: any) => {
  if (typeof err === 'string') return err
  const data = err?.response?.data
  const status = err?.response?.status
  if (typeof data === 'string') return data
  if (typeof data === 'object')
    return {
      ...data,
      status,
    }

  const errors = data?.errors
  if (errors) return errors

  if (err?.errors) return err.errors

  return err?.data
}
