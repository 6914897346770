import { theme } from 'venus/config'

export type TFontWeight =
  | 'normal'
  | 'bold'
  | 'bolder'
  | 'lighter'
  | 300
  | 400
  | 500
  | 600
  | 700

interface IText {
  fontSize?: string | number
  fontWeight?: TFontWeight
  lineHeight?: string | number
  color?: string
  style?: any
  value?: string | number
  format: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'drop_down_option'
  children?: string | React.ReactNode
}

export const Text = ({
  fontSize,
  fontWeight,
  lineHeight,
  color = theme.colors.Black,
  style,
  value,
  format,
  children,
}: IText) => {
  const config = {
    h1: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '25px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '25px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '21px',
    },
    body: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    drop_down_option: {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '24px',
    },
  }

  return (
    <span
      style={{
        fontSize: fontSize || config[format].fontSize || 14,
        fontWeight: fontWeight || config[format].fontWeight || 400,
        lineHeight: lineHeight || config[format].lineHeight || 1.5,
        color,
        ...style,
      }}
    >
      {value || children}
    </span>
  )
}
