import { useState } from 'react'
import { theme, minimumMultiItemSelectDisplayNumber } from 'venus/config'
import {
  TitleArea,
  CheckBoxContent,
  CheckBoxArea,
  BtnWrapper,
} from './MultiItemSelect.styles'
import { Checkbox, Text, Chevron } from '..'

interface IMultiItemSelect {
  width?: number | string
  spaceLabel: string
  items: {
    name: string
    type: string
    checked?: boolean
  }[]
  handleCheck: (type: string, item: string) => void
}

export const MultiItemSelect = ({
  width = '100%',
  spaceLabel,
  items,
  handleCheck,
}: IMultiItemSelect) => {
  const [isFullDisplay, setIsFullDisplay] = useState(false)
  const { length } = items
  if (length === 0) return null
  const displaySwitch = length > minimumMultiItemSelectDisplayNumber
  const itemsToDisplay =
    displaySwitch && !isFullDisplay
      ? minimumMultiItemSelectDisplayNumber
      : length

  const handlePress = (spaceLabel: string, name: string) => {
    handleCheck(spaceLabel, name)
  }

  const handlePressSwitchFull = () => {
    setIsFullDisplay(!isFullDisplay)
  }

  return (
    <div style={{ width }}>
      <TitleArea>
        <Text value={spaceLabel} color={theme.colors.Primary} format='h3' />
      </TitleArea>
      <CheckBoxArea>
        {items
          .slice(0, itemsToDisplay)
          .map(({ name, type, checked = false }) => {
            return (
              <CheckBoxContent key={`multi_select_${name}`}>
                <Checkbox
                  checked={checked}
                  onChange={() => handlePress(spaceLabel, name)}
                />
                <Text
                  value={name}
                  color={theme.colors.Black}
                  format='body'
                  style={{
                    flex: 1,
                    alignSelf: 'center',
                    marginLeft: 12,
                  }}
                />
              </CheckBoxContent>
            )
          })}
      </CheckBoxArea>
      {displaySwitch && (
        <BtnWrapper
          style={{
            marginBottom: '12px',
            padding: 0,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            backgroundColor: theme.colors.White,
          }}
          onClick={handlePressSwitchFull}
        >
          <Text
            style={{ marginLeft: '6px' }}
            value={isFullDisplay ? 'Less' : 'More'}
            format={'body'}
          />
          <Chevron
            direction={isFullDisplay ? 'up' : 'down'}
            color={theme.colors.Black}
          />
        </BtnWrapper>
      )}
    </div>
  )
}
