import styled from 'styled-components'

type TermsAndConditionProps = {
  top?: number
  isModal?: boolean
}

export const Container = styled.div<TermsAndConditionProps>`
  background: white;
  padding: 24px 32px;
  overflow-y: auto;
  position: absolute;
  // set 0 as default to fill up in the mobile app view
  top: ${props => props.top || 0}px;
  height: inherit;

  h1 {
    font-size: ${props => props.isModal || window.innerWidth < 1000 ? '24px' : '32px'};
  }

  h2 {
    font-size: ${props => props.isModal || window.innerWidth < 1000 ? '18px' : '26px'};
  }

  p {
    font-size: ${props => props.isModal || window.innerWidth < 1000 ? '12px' : '16px'};
  }
`