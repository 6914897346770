import { TabView } from 'venus/components'
import { useLocation, useSearchParams } from 'react-router-dom'
import { List as DreamPropertyList } from 'venus/pages/DreamPrice/List'
import { Container } from './Properties.styles'
import MyProperties from './MyProperties'
import SharedProperties from './SharedProperties'
import { useState } from 'react'

const tabs = ['My Properties', 'Shared Properties', 'Dream Properties']

const Properties = () => {
  const location = useLocation()
  const { isOpenAddProperty } = (location?.state as any) || {}

  const [searchParams, setSearchParams] = useSearchParams()
  const activeIndex = searchParams.get('activeIndex')
  const [tabIndex, setTabIndex] = useState(0)
  const onChange = (index) => {
    setTabIndex(index)
    setSearchParams({ activeIndex: tabs[index] })
  }

  return (
    <Container>
      <TabView
        defaultActiveIndex={activeIndex ? tabs.indexOf(activeIndex) : 0}
        tabs={tabs}
        paddingBottom={0}
        style={{ overflow: 'auto' }}
        onChange={onChange}
      >
        <MyProperties
          setTabButtons={undefined}
          isOpenAddProperty={isOpenAddProperty === '1'}
        />
        <SharedProperties />
        <DreamPropertyList setTabButtons={undefined} />
      </TabView>
    </Container>
  )
}

export default Properties
