import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { IGrid } from './ImageGrids'
import { getColorsFromUrl, isColorBackground } from 'venus/utils'

const GridContainer = styled.ul`
  margin: 60px 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 36px;
  grid-row-gap: 14px;
`

const GridContent = styled.li`
  display: flex;
  flex-direction: column;
`

type ImageContainerProps = {
  readonly image?: string
  readonly isTemplate?: boolean
  readonly isGradient?: boolean
  readonly colors?: string[]
}
const ImageContainer = styled.div<ImageContainerProps>`
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) =>
    props.isTemplate ? props.theme.colors.LightBlue : props.theme.colors.Grey};
  background-size: cover;
  aspect-ratio: 16 / 9;
  border-radius: 4px;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  margin-bottom: 6px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isGradient &&
    `
    background: linear-gradient(0deg, ${props.colors[0]}, ${props.colors[1]});
  `}
`

const ImageGrid = ({ id, url, image, name, defaultType, onClick }: IGrid) => {
  const isGradient = isColorBackground(image)

  const colors = isGradient ? getColorsFromUrl(image) : []

  if (defaultType) {
    return (
      <GridContent onClick={onClick}>
        <ImageContainer
          image={image}
          isTemplate
          isGradient={isGradient}
          colors={colors}
        >
          <AddSign color={theme.colors.Secondary} />
          <Text format='h3' color={theme.colors.Black}>
            {name}
          </Text>
        </ImageContainer>
        <Text format='h3' color={theme.colors.Black}>
          {' '}
        </Text>
      </GridContent>
    )
  }

  return (
    <GridContent key={id}>
      <NavLink end style={{ textDecoration: 'none' }} to={url}>
        <ImageContainer
          image={image}
          isTemplate={false}
          isGradient={isGradient}
          colors={colors}
        ></ImageContainer>
        <Text format='h3' color={theme.colors.Black}>
          {name}
        </Text>
      </NavLink>
    </GridContent>
  )
}

export default ImageGrid
