import styled from 'styled-components'
import { theme } from 'venus/config'

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 10;
  border-radius: 12px;
`

export const HeaderArea = styled.div`
  width: calc(100% - 48px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.White};
  padding: 38px 24px;
`

export const BodyArea = styled.div`
  background-color: ${theme.colors.White};
  padding: 0 24px 24px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  border-radius: 12px;
`

export const IconButton = styled.div`
  cursor: pointer;
`

export const AddToHistoryWrapper = styled.div`
  background-color: ${theme.colors.White};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
`

export const HistoryContainer = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
`

export const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const HistoryTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 32px 36px;
`

export const HistoryBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  overflow-x: hidden;
`
