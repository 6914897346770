import { ReactChild } from "react"
import { ActionWrapper } from "./QuickActions.styles"

interface IQuickActions {
  children: ReactChild
  bottom?: number
  right?: number
}

export const QuickActions = ({
  children,
  bottom = 60,
  right = 18,
}: IQuickActions) => {
  return <ActionWrapper style={{ bottom, right }}>{children}</ActionWrapper>
}
