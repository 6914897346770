import { getType, getExtension } from 'mime'
import { useEffect, useState } from 'react'
// import * as ImagePicker from 'expo-image-picker'
import axios from 'axios'
// import * as FileSystem from 'expo-file-system'
import { Buffer } from 'buffer'
import api from 'venus/api'
import { extractFileFromUrl } from 'venus/utils/url'

interface IUploadConfig {
  url: string
  uploadURL: string
  key: string
  id: string
}

export const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri)
  return await resp.blob()
}

export const uploadImage = async (
  uploadConfig: IUploadConfig,
  image: any,
  updateProgress: (percentage: number) => void,
) => {
  const { uploadURL } = uploadConfig

  const fileType = getType(image)

  // setting up the reader
  const reader = new FileReader()
  reader.readAsText(image, 'UTF-8')

  // here we tell the reader what to do when it's done reading...
  reader.onload = async (readerEvent) => {
    const content = readerEvent.target.result // this is the content!
    // @ts-ignore
    const buffer = Buffer.from(content, 'base64')

    try {
      await axios
        .put(uploadURL, buffer, {
          headers: {
            'Content-Type': String(fileType),
          },
          onUploadProgress: (progress: ProgressEvent) => {
            const progressPercentage = Math.round((progress.loaded / progress.total) * 100)
            updateProgress(progressPercentage)
          },
        })
        .then((res) => {
          return res.status
        })
        .catch((error) => {
          console.log('error', error.response.data)
          return 403
        })

      return uploadConfig
    } catch (error) {
      console.log('error in upload', error)
    }
  }

  // const base64 = `await FileSystem.readAsStringAsync(imageUri, {
  //   encoding: FileSystem.EncodingType.Base64,
  // })`

  // const buffer = Buffer.from(base64, 'base64')
}

export const getUploadConfig = async (imageUrl: string) => {
  try {
    const mimeType = getType(imageUrl)

    const fileBody = await getBlob(imageUrl)
    const ext = getExtension(mimeType || 'jpg')

    const fileName = extractFileFromUrl(imageUrl)

    console.log('file config ', fileName, fileBody.type, mimeType, ext)

    const uploadConfig = await api
      .post('/images', [{ name: fileName, type: mimeType, ext }])
      .then((res) => res.data)
    return uploadConfig[0]
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

const useUploadImage = (imageUrl: string = '') => {
  const [image, setImage] = useState<string>(imageUrl)
  const [progress, setProgress] = useState<number>(0)
  const [url, setUrl] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const upload = async () => {
      try {
        const uploadConfig = await getUploadConfig(image)
        // await uploadImage(uploadConfig, image, setProgress)

        console.log('uploadConfig.url::', uploadConfig.url)
        setUrl(uploadConfig.url)
        setLoading(false)
      } catch (error) {
        console.log('upload error', error)
      }
    }

    if (image) {
      console.log('image::', image)
      upload()
    }
  }, [image])

  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  const pickImage = async () => {
    setLoading(true)

    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'

    input.onchange = (e) => {
      // getting a hold of the file reference
      // @ts-ignore
      const file = e.target.files[0]

      setImage(file)

      console.log('file', e.target)
    }

    input.click()
    // setLoading(true)
    // let result = await ImagePicker.launchImageLibraryAsync({
    //   mediaTypes: ImagePicker.MediaTypeOptions.Images,
    //   quality: 0.5,
    // })
    // if (!result.cancelled) {
    //   console.log('setImage::', result.uri)
    //   setImage(result.uri)
    // } else {
    //   setLoading(false)
    // }
  }

  const reset = () => {
    setImage('')
    setUrl('')
    setProgress(0)
  }

  return { image, pickImage, progress, url, reset, loading }
}
export default useUploadImage
