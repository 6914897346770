import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'venus/api'
import { errorHandling } from './asyncSignIn'

export const validateMobile = createAsyncThunk(
  'validate/mobile',
  async (
    { mobile, code }: { mobile: string; code: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(`/auth/validate-mobile`, {
        mobile,
        code,
      })
      console.log('validateMobile response::', response.data)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  },
)

export const resendMobileCode = createAsyncThunk(
  'validate/resendMobileCode',
  async ({ mobile }: { mobile: string }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/send-mobile-code`, { mobile })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  },
)

export const checkExistingEmail = createAsyncThunk(
  'auth/check-existing-email',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/check-email`, {
        email: email.toLowerCase(),
      })
      console.log('check-existing-email response::', response.data)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  },
)

export const resendEmailCode = createAsyncThunk(
  'validate/resendEmailCode',
  async (email: string) => {
    try {
      const response = await api.post(`/auth/resend-email-code/${email}`)
      console.log('resendEmailCode response::', response.data)
      return response.data
    } catch (err: any) {
      return errorHandling(err)
    }
  },
)

export const forgotPassword = createAsyncThunk(
  'validate/forgotPassword',
  async (body: { mobile?: string; email?: string }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/auth/forgot-password`, body)
      console.log('forgotPassword response::', response.data)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err.data)
    }
  },
)

export const deleteUser = createAsyncThunk('validate/deleteUser', async () => {
  try {
    const response = await api.delete(`/auth`)
    console.log('deleteUser response::', response.data)
    return response.data
  } catch (err: any) {
    return errorHandling(err)
  }
})
