import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useNavigateSearch from 'venus/hooks/useNavigateSearch'
import PSILayout from 'venus/layout/PSI'
import { getDreamPropertyDetails } from 'venus/redux/features/dreamprice'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getPropertyName } from 'venus/utils'
import PropertyDetails from '../PropertyDetails'
import { Container } from './DreamPropertyPreview.styles'

const DreamPropertyPreivew = () => {
  const dispatch = useAppDispatch()
  const { dreamId } = useParams()
  const navigateSearch = useNavigateSearch()
  const { currentDreamPropertyDetails } = useAppSelector((state) => state.dreamprice)

  useEffect(() => {
    dispatch(getDreamPropertyDetails(dreamId))
  }, [])

  const goBack = () => {
    navigateSearch('/', { activeIndex: 'Dream Properties' })
  }

  return (
    <PSILayout
      tag=''
      title={getPropertyName(currentDreamPropertyDetails)}
      headerBackground={currentDreamPropertyDetails?.coverImage}
      goBack={goBack}
      isDreamProperty
      isShared
      onUpdateSetting={undefined}
      setting={{}}
    >
      <Container>
        {/* @ts-ignore */}
        <PropertyDetails
          dreamId={dreamId}
          property={currentDreamPropertyDetails}
          isShared
          isDreamProperty
        />
      </Container>
    </PSILayout>
  )
}

export default DreamPropertyPreivew
