import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getProperty } from 'venus/redux/features/property/property'
import { IDreamPrice, ICurrentDreamProperty, ICurrentDreamPropertyDetails } from './types'
import {
  getDreamPropertyList,
  deleteDreamProperty,
  getNumberOfUserTrackingProperty,
  getDreamPropertyDetails,
  getPropertyWithDreamId,
  shareDreamPrice,
  unshareDreamPrice,
  acceptOffer,
  addDreamProperty,
  getDreamPropertyWideSpace,
  getDreamStatistics,
  getDreamAVM,
  getDreamRentalAVM,
} from './async'
import { getErrorMsg } from 'venus/utils'
import { getAVM, getRentalAVM, getStatistics } from '../npdData/npdData'

const initialState: IDreamPrice = {
  list: [],
  error: '',
  dreamPrice: '',
  numberOfUserTrackingProperty: 0,
  numberShowInterest: 0,
  dreamPriceStatus: 'NO_DREAM_PRICE',
  currentDreamProperty: {} as ICurrentDreamProperty,
  currentDreamPropertyDetails: {} as ICurrentDreamPropertyDetails,
  loading: false,
  propertyData: {},
}

export const dreamPriceSlice = createSlice({
  name: 'dreamprice',
  initialState,
  reducers: {
    resetDreamPriceStore: () => initialState,
    setDreamPrice: (state, action) => {
      const { payload } = action
      state.dreamPrice = payload
    },
    setCurrentDreamProperty: (state, action: PayloadAction<ICurrentDreamProperty>) => {
      const { payload } = action
      state.currentDreamProperty = payload
    },
    resetDreamProperty: (state) => {
      console.log('reset')
      state.currentDreamProperty = {} as ICurrentDreamProperty
      state.currentDreamPropertyDetails = {} as ICurrentDreamPropertyDetails
    },
    resetError: (state) => {
      state.error = ''
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addDreamProperty.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addDreamProperty.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(addDreamProperty.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(getProperty.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProperty.fulfilled, (state, action) => {
      state.loading = false
      state.dreamPrice = action.payload.dreamPrice
    })
    builder.addCase(getProperty.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(shareDreamPrice.pending, (state) => {
      state.loading = true
    })
    builder.addCase(shareDreamPrice.fulfilled, (state, action) => {
      state.dreamPrice = action.payload.dreamPrice
      state.dreamPriceStatus = 'SHARE_DREAM_PRICE'
      state.loading = false
    })
    builder.addCase(shareDreamPrice.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(getDreamPropertyList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDreamPropertyList.fulfilled, (state, action) => {
      state.list = action.payload
      state.loading = false
    })
    builder.addCase(getDreamPropertyList.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(deleteDreamProperty.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteDreamProperty.fulfilled, (state, action) => {
      // state.list = action.payload
      state.loading = false
    })
    builder.addCase(deleteDreamProperty.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(unshareDreamPrice.pending, (state) => {
      state.loading = true
    })
    builder.addCase(unshareDreamPrice.fulfilled, (state, action) => {
      state.dreamPrice = ''
      state.dreamPriceStatus = 'NO_DREAM_PRICE'
      state.loading = false
    })
    builder.addCase(unshareDreamPrice.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(getNumberOfUserTrackingProperty.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNumberOfUserTrackingProperty.fulfilled, (state, action) => {
      const { dreamPriceStatus, number, numberShowInterest } = action.payload
      state.numberOfUserTrackingProperty = number
      state.numberShowInterest = numberShowInterest
      state.dreamPriceStatus = dreamPriceStatus
      state.loading = false
    })
    builder.addCase(getNumberOfUserTrackingProperty.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(getDreamPropertyDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDreamPropertyDetails.fulfilled, (state, action) => {
      state.currentDreamPropertyDetails = action.payload
      state.loading = false
    })
    builder.addCase(getDreamPropertyDetails.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(getDreamPropertyWideSpace.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDreamPropertyWideSpace.fulfilled, (state, action) => {
      state.currentDreamPropertyDetails = {
        ...state.currentDreamPropertyDetails,
        items: action.payload.items,
      }
    })
    builder.addCase(getDreamPropertyWideSpace.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(getPropertyWithDreamId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPropertyWithDreamId.fulfilled, (state, action) => {
      state.currentDreamPropertyDetails = {
        ...state.currentDreamPropertyDetails,
        ...action.payload,
      }
      state.loading = false
    })
    builder.addCase(getPropertyWithDreamId.rejected, (state, action) => {
      state.error = getErrorMsg(action.payload)
      state.loading = false
    })
    builder.addCase(acceptOffer.pending, (state) => {
      state.loading = true
    })
    builder.addCase(acceptOffer.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(acceptOffer.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })

    builder.addCase(getStatistics.pending, (state, action) => {
      state.propertyData.statistics = undefined
    })
    builder.addCase(getDreamStatistics.pending, (state, action) => {
      // state.propertyData.statistics = undefined
    })
    builder.addCase(getDreamStatistics.fulfilled, (state, action) => {
      state.propertyData.statistics = action.payload
    })

    builder.addCase(getAVM.pending, (state, action) => {
      state.propertyData.avm = undefined
    })
    builder.addCase(getDreamAVM.pending, (state, action) => {
      state.propertyData.avm = undefined
    })
    builder.addCase(getDreamAVM.fulfilled, (state, action) => {
      state.propertyData.avm = action.payload
    })

    builder.addCase(getRentalAVM.pending, (state, action) => {
      state.propertyData.rentalAvm = undefined
    })
    builder.addCase(getDreamRentalAVM.pending, (state, action) => {
      state.propertyData.rentalAvm = undefined
    })
    builder.addCase(getDreamRentalAVM.fulfilled, (state, action) => {
      state.propertyData.rentalAvm = action.payload
    })
  },
})

export const {
  resetDreamPriceStore,
  setCurrentDreamProperty,
  setDreamPrice,
  resetDreamProperty,
  resetError,
  setError,
} = dreamPriceSlice.actions

export default dreamPriceSlice.reducer
