import {
  ImageBackground,
  LoadingCover,
  LoadingIndicator,
  LoadingIndicatorContainer,
  ImagePlaceholder,
} from './ImageUploading.styles'

interface IImageUploading {
  image: string
  url?: string
  progress: number
  style?: any
}

const ImageUploading = ({ image, url, progress, style }: IImageUploading) => {
  if (url) {
    return <ImageBackground style={style} image={url}></ImageBackground>
  }

  return (
    <ImageBackground image={image} style={style}>
      <LoadingCover />
      <LoadingIndicatorContainer>
        <LoadingIndicator progress={progress} />
      </LoadingIndicatorContainer>
    </ImageBackground>
  )
}

export default ImageUploading
