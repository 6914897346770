import {
  Container,
  ActionBody,
  ActionFooter,
  MessageArea,
} from './AlertBox.styles'
import { Text, Button, ActionLayout, ActionModal } from '..'
import { TColor, theme } from 'venus/config'

interface IBtnGroup {
  text: string
  onClick: (event?: any) => void
  color?: TColor
  textColor?: TColor
  loading?: boolean
  type: 'primary' | 'secondary' | 'disabled' | 'error' | 'dropdown'
}

interface IAlertBox {
  showAlert: boolean
  title: string
  message: string
  body?: any
  btnGroup: IBtnGroup[]
  cancel: () => void
}

export const AlertBox = ({
  title,
  message,
  body = null,
  btnGroup,
  showAlert,
  cancel,
}: IAlertBox) => (
  <ActionModal
    isOpen={showAlert}
    title={title}
    onClose={cancel}
    width={420}
    height={230}
    headerStyle={{
      borderBottom: `1px solid #E8EAEE`,
    }}
  >
    <Container>
      <ActionBody margin={34}>
        <MessageArea>
          <Text
            value={message}
            color={theme.colors.Black}
            format='body'
            lineHeight='21px'
            style={{
              padding: '24px 0',
              textAlign: 'center',
            }}
          />
          {body}
        </MessageArea>
      </ActionBody>
      <ActionFooter
        margin={34}
        style={{
          justifyContent: btnGroup.length === 1 ? 'center' : 'space-between',
        }}
      >
        {btnGroup.map((action) => (
          <Button
            key={action.text}
            {...action}
            style={{
              width:
                btnGroup.length === 1
                  ? '100%'
                  : action.text.length > 19
                  ? 'fit-content'
                  : `${95 / btnGroup.length}%`,
            }}
          />
        ))}
      </ActionFooter>
    </Container>
  </ActionModal>
)
