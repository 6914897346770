import {
  propretyTypeOptions,
  propertyTypeMaps,
  propertyLabelMaps,
  theme,
  Add_Dream_Property_Type,
} from 'venus/config'
import { Text, Select, Input, ActionLayout } from 'venus/components'
import { setErrorTitle } from 'venus/redux/features/error/reducer'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import {
  createNoneNpdProperty,
  createProperty,
  setPropertyId,
} from 'venus/redux/features/property/property'
import {
  addDreamProperty,
  getDreamPropertyList,
} from 'venus/redux/features/dreamprice'
import { createHomeImages } from 'venus/redux/features/onboarding'
import { IsPositiveIntegar } from 'venus/utils'
import { useForm, Controller } from 'react-hook-form'
import { BodyWrapper } from './ConfirmNonNpdHome.styles'

type PropertyFormData = {
  bedrooms: string
  bathrooms: string
  parkings: string
  landSize: string
  typeOfProperty: string
}

interface IConfirmNonNpdHome {
  onFinish: () => void
  handleGoBack: Function
  addType?: string
}

export const ConfirmNonNpdHome = ({
  onFinish,
  handleGoBack,
  addType,
}: IConfirmNonNpdHome) => {
  const dispatch = useAppDispatch()
  const isAddDreamProperty = addType === Add_Dream_Property_Type
  const { preview, addressId } = useAppSelector((state) => state.address)
  const {
    address,
    area,
    baths,
    beds,
    parking,
    postcode,
    suburb,
    state,
    nationalParcelId,
    googlePlaceId,
    propertyKey,
    propertyType,
    imageBaseUrl,
    imageCount,
    image,
  } = preview[0] as any
  const { loading } = useAppSelector((state) => state.property)

  console.log('preview non ndp::', preview)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PropertyFormData>({
    defaultValues: {
      bedrooms: beds ? String(beds) : '',
      bathrooms: baths ? String(baths) : '',
      parkings: parking ? String(parking) : '',
      landSize: area ? String(area) : '',
      typeOfProperty: propertyLabelMaps[propertyType] || '',
    },
  })

  const onSubmit = async (data: any) => {
    if (isAddDreamProperty) {
      dispatch(setErrorTitle('Dream Property Error'))
      const data = await dispatch(
        addDreamProperty({
          addressId: Number(addressId),
          nationalParcelId,
          propertyKey,
          googlePlaceId,
        })
      )
      console.log('addDreamProperty returned data::', data)
      if (data.meta.requestStatus === 'fulfilled') {
        await dispatch(getDreamPropertyList())
        onFinish()
      }
    } else if (!!googlePlaceId) {
      const body = {
        googlePlaceId,
        image,
        address,
        propertyData: {
          area: Number(data.landSize.trim()),
          baths: Number(data.bathrooms.trim()),
          beds: Number(data.bedrooms.trim()),
          parking: Number(data.parkings.trim()),
          propertyType: propertyTypeMaps[data.typeOfProperty],
          propertyTypeCode: data.typeOfProperty,
        },
      }
      console.log('createNoneNpdProperty body::', body)
      dispatch(setErrorTitle('Add Property Error'))
      const response = await dispatch(createNoneNpdProperty(body))
      console.log('createNoneNpdProperty payload::', response.payload)
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setPropertyId(response.payload.id))
        dispatch(createHomeImages(preview[0]))
        onFinish()
      } else {
        handleGoBack()
      }
    } else {
      console.log('create Property.')
      dispatch(setErrorTitle('Add Property Error'))
      const response = await dispatch(
        createProperty({
          address: {
            state,
            suburb,
            address,
            postcode,
          },
          addressId: Number(addressId),
          nationalParcelId,
          propertyKey,
          propertyData: {
            area: Number(data.landSize.trim()),
            beds: Number(data.bedrooms.trim()),
            baths: Number(data.bathrooms.trim()),
            parking: Number(data.parkings.trim()),
            propertyType: propertyTypeMaps[data.typeOfProperty],
            propertyTypeCode: data.typeOfProperty,
          },
          imageBaseUrl,
          imageCount,
        })
      )
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setPropertyId(response.payload.id))
        dispatch(createHomeImages(preview[0]))
        onFinish()
      }
    }
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          text: 'Next',
          onClick: handleSubmit(onSubmit),
          isDisabled: !address,
          isLoading: loading,
        },
      ]}
    >
      <BodyWrapper>
        <Text
          value='Select the number of bedrooms, bathrooms, parkings, land size and property type.'
          color={theme.colors.Black}
          format='body'
          style={{ textAlign: 'center', marginBottom: 24 }}
        />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                title='Type of Property'
                isDisabled={!!propertyType}
                value={value}
                onChange={onChange}
                options={propretyTypeOptions}
                isError={!!errors.typeOfProperty}
                errorMsg='This is required.'
                style={{ marginBottom: '18px' }}
              />
            )
          }}
          name='typeOfProperty'
        />
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => IsPositiveIntegar(v),
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                isDisabled={!!beds}
                label='Bedrooms'
                value={value}
                onChange={onChange}
                isError={!!errors.bedrooms}
                errorMsg={'Please input valid numbers.'}
                style={{
                  marginBottom: '18px',
                  width: 'calc(50% - 12px)',
                  marginRight: '24px',
                }}
              />
            )
          }}
          name='bedrooms'
        />
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => IsPositiveIntegar(v),
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                isDisabled={!!baths}
                label='Bathrooms'
                value={value}
                onChange={onChange}
                isError={!!errors.bathrooms}
                errorMsg={'Please input valid numbers.'}
                style={{
                  marginBottom: '18px',
                  width: 'calc(50% - 12px)',
                }}
              />
            )
          }}
          name='bathrooms'
        />
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => IsPositiveIntegar(v),
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                isDisabled={!!parking}
                label='Parking Lots'
                value={value}
                onChange={onChange}
                isError={!!errors.parkings}
                errorMsg={'Please input valid numbers.'}
                style={{
                  marginBottom: '18px',
                  width: 'calc(50% - 12px)',
                  marginRight: '24px',
                }}
              />
            )
          }}
          name='parkings'
        />
        <Controller
          control={control}
          rules={{
            required: true,
            validate: (v) => !isNaN(Number(v)) && Number(v) > 0,
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <Input
                isDisabled={!!area}
                label='Land Size (square meters)'
                value={value}
                onChange={onChange}
                isError={!!errors.landSize}
                errorMsg={'Please input valid numbers.'}
                style={{
                  marginBottom: '18px',
                  width: 'calc(50% - 12px)',
                }}
              />
            )
          }}
          name='landSize'
        />
      </BodyWrapper>
    </ActionLayout>
  )
}
