import api from 'venus/api'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Text, Input, Loader, Modal, ActionLayout, PropertyCardView } from 'venus/components'
import { setErrorTitle, setErrorMessage } from 'venus/redux/features/error/reducer'
import { useAppDispatch } from 'venus/redux/hooks'
import { getAcceptOwnership } from 'venus/redux/features/user'
import {
  AcceptOwnershipSteps,
  ERROR_MSG_TITLE,
  ACCEPT_CODE_DESCRIPTION,
  SECURE_CODE_PLACE_HOLDER,
  ACCEPT_OWNERSHIP_TITLE,
  ENTER_SECURE_CODE_TITLE
} from 'venus/layout/ProfileMenu/ProfileSettings/AcceptOwnership/Constants'
import { BodyWrapper } from 'venus/layout/ProfileMenu/ProfileSettings/AcceptOwnership/AcceptOwnership.styles'

const AcceptOwnership = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const {
  //   isInPromotion,
  //   productId,
  //   subscribed
  //   // unsubscribed,
  //   // credit = 0
  // } = useAppSelector((state) => state.subscription)

  const [loading, setLoading] = useState(false)
  const [property, setProperty] = useState(null)
  const [step, setStep] = useState(AcceptOwnershipSteps.CONFIRM_ACCEPT)
  const [transferCode, setTransferCode] = useState('') // code from the url link
  const [securityCode, setSecurityCode] = useState('')

  useEffect(() => {
    (async () => {
      setLoading(true)
      const queryParams = new URLSearchParams(window.location.search)
      const code = queryParams.get('code')
      setTransferCode(code)

      await dispatch(getAcceptOwnership(code))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setProperty(res.payload)
          } else {
            dispatch(setErrorTitle(ERROR_MSG_TITLE))
            dispatch(setErrorMessage(res?.payload))
            navigate('/', { replace: true })
          }
        })
        .finally(() => {
          setLoading(false)
        })
      // TODO: 1. get subscription
      // dispatch(getSubscription())
    })()
  }, [])

  const handleOnClickDecline = () => {
    navigate('/', { replace: true })
  }

  const handleOnClickAccept = () => {
    setStep(step + 1)
  }

  const handleOnConfirmSecurityCode = async () => {
    if (!securityCode) return

    // TODO: handle payment
    // if (!isInPromotion) {
    //   const number = getNumberFromProductSku(productId)
    //   if (subscribed.length + 1 > number) {
    //     setShowUpgrade(true)
    //   } else {
    //     navigation.navigate('SecurityCode', {
    //       transferCode: code,
    //       properties: [...subscribed, property]
    //     })
    //   }
    // } else {
    //   navigation.navigate('SecurityCode', {
    //     transferCode: code,
    //     property
    //   })
    // }
    setLoading(true)
    await api
      .post('/transfer/accept', { transferCode, code: securityCode })
      .then((res) => {
        navigate('/', { replace: true })
      })
      .catch((error) => {
        dispatch(setErrorTitle(ERROR_MSG_TITLE))
        return error
      })
      .finally(() => {
        setLoading(false)
        navigate('/', { replace: true })
      })
  }

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull/>
      </div>
    )
  }
  return (
    <>
      <Modal
        height={542}
        isOpen={true}
        showCloseButton={false}
        onBack={step !== AcceptOwnershipSteps.CONFIRM_ACCEPT ? () => setStep(step - 1) : null}
        title={step === AcceptOwnershipSteps.CONFIRM_ACCEPT ? ACCEPT_OWNERSHIP_TITLE : ENTER_SECURE_CODE_TITLE}
      >
        {step === AcceptOwnershipSteps.CONFIRM_ACCEPT ? (
          <ActionLayout
            margin={34}
            children={
              <>
                <PropertyCardView
                  property={property}
                  imageHeight={240}
                  isSelected={true}
                  showPropertyData={true}
                  addressFontSize={14}
                  propertyDataFontSize={14}
                />
              </>
            }
            rightActions={[
              {
                type: 'icon_button_error',
                text: 'Decline',
                onClick: handleOnClickDecline,
              },
              {
                type: 'primary',
                text: 'Accept',
                onClick: handleOnClickAccept,
              }
            ]}
          />
        ) : (
          <ActionLayout
            margin={24}
            children={(
              <>
                <BodyWrapper>
                  <Text format={'body'} value={ACCEPT_CODE_DESCRIPTION} style={{ margin: '32px 0' }}/>
                  <Input
                    label={''}
                    value={securityCode}
                    placeholder={SECURE_CODE_PLACE_HOLDER}
                    onChange={setSecurityCode}
                    maxLength={6}
                  />
                </BodyWrapper>
              </>

            )}
            rightActions={[
              {
                type: 'primary',
                text: 'Confirm',
                isDisabled: !securityCode.trim() || loading,
                onClick: handleOnConfirmSecurityCode,
              }
            ]}
          />
        )}

      </Modal>
    </>
  )
}

export default AcceptOwnership
