import styled from 'styled-components'
import { theme } from 'venus/config'
import { ReactComponent as Plus } from 'venus/assets/img/icons/AddSign.svg'
import { Text } from 'venus/components'

const Container = styled.div`
  border-top: 0.5px solid ${(props) => props.theme.colors.Grey};
`

const Action = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  margin: 0;
  width: 100%;

  border: 0;
  outline: none;
  background-color: transparent;
`

const AddProperty = ({ onClick }) => {
  return (
    <Container>
      <Action onClick={onClick}>
        <Plus color={theme.colors.Secondary} />
        <Text style={{ marginLeft: 10 }} value='Add Property' format='h4' />
      </Action>
    </Container>
  )
}

export default AddProperty
