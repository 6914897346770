import { useEffect, useMemo } from 'react'
// import useShowPremium from 'venus/hooks/useShowPremium'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { saveShareScope } from 'venus/redux/features/share'
import { Loader } from 'venus/components'
import { SelectPSIScope } from 'venus/common/SelectPSIScope'
import useSpaces from 'venus/hooks/useSpaces'

interface ISelect {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  propertyId: string
  spaceId: string
  itemId: string
  isShareDocument: boolean
  setIsShareDocument: (b: boolean) => void
  isShareHistory: boolean
  setIsShareHistory: (b: boolean) => void
  isShareWholeProperty: boolean
  setIsShareWholeProperty: (b: boolean) => void
  checkedItemIds: any[]
  setCheckedItemIds: (b: any[]) => void
  type: 'item' | 'space' | 'property'
}

const Select = ({
  setStep,
  setTitle,
  propertyId,
  spaceId,
  itemId,
  isShareDocument,
  setIsShareDocument,
  isShareHistory,
  setIsShareHistory,
  isShareWholeProperty,
  setIsShareWholeProperty,
  checkedItemIds,
  setCheckedItemIds,
  type,
}: ISelect) => {
  const dispatch = useAppDispatch()
  const { loading, property } = useAppSelector((state) => state.property)
  const { propertyWideSpaceId } = property || {}
  const spaces = property?.spaces
    ? [...property?.spaces, { id: propertyWideSpaceId, name: 'Property Wide' }]
    : []

  // const { vaults } = useAppSelector((state) => state.subscription)
  // const subscriptionStatus = vaults[propertyId]
  // const { isShown, onViewPlan, onCancel } = useShowPremium(
  //   subscriptionStatus,
  //   navigation,
  //   propertyId
  // )

  const { spaces: spacesList } = useSpaces(propertyId)

  const spaceList = useMemo(() => {
    if (type === 'property') return spacesList
    if (spaceId) {
      const filteredSpace = spacesList.find((space) => space.id === spaceId) || {}

      if (itemId) {
        return [
          {
            ...filteredSpace,
            items: filteredSpace.items?.filter((item) => item.id === itemId),
          },
        ]
      } else {
        return spacesList.filter((space) => space.id === spaceId)
      }
    }

    return spacesList
  }, [spaces, spaceId, itemId])

  useEffect(() => {
    if (type !== 'property' && spaceId && !itemId) {
      const filteredSpace = spacesList.find((space) => space.id === spaceId) || {}

      const updatedItemIds =
        filteredSpace.items?.map((item) => ({
          spaceId: filteredSpace.id,
          spaceName: filteredSpace.name,
          itemId: item.id,
          itemName: item.name,
        })) || []

      console.log('updatedItemIds', filteredSpace, updatedItemIds)

      setCheckedItemIds(updatedItemIds)
    }
  }, [spacesList, spaceId, itemId])

  const setShareScope = useMemo(() => {
    if (itemId) {
      const filteredSpace = spacesList.find((space) => space.id === spaceId)
      const filteredItem = filteredSpace
        ? filteredSpace.items?.find((item) => item.id === itemId)
        : property?.items?.find((item) => item.id === itemId)

      return {
        spaceId: filteredSpace?.id || propertyWideSpaceId,
        spaceName: filteredSpace?.name || 'Property Wide',
        itemId,
        itemName: filteredItem?.name,
      }
    }

    return null
  }, [spaces, spaceId, itemId])

  if (loading) {
    return <Loader isFull />
  }

  const isSetShareScope = !!itemId

  const allCheckedItemIds = isSetShareScope
    ? setShareScope
    : (spaceList || []).map(({ id, name, items }) => ({
        spaceId: id,
        spaceName: name,
        items: (items || []).map(({ id, name }) => ({
          itemId: id,
          itemName: name,
        })),
      }))

  const handlePressNext = () => {
    dispatch(
      saveShareScope({
        propertyId,
        isShareDocument,
        isShareHistory,
        isShareWholeProperty,
        shareScope: isSetShareScope
          ? [setShareScope]
          : isShareWholeProperty
          ? (allCheckedItemIds as any)
          : checkedItemIds,
      }),
    )
    setStep(2)
    setTitle('Share Details')
  }

  console.log('checkedItemIds', checkedItemIds)

  return (
    <SelectPSIScope
      isShareDocument={isShareDocument}
      setIsShareDocument={setIsShareDocument}
      isShareHistory={isShareHistory}
      setIsShareHistory={setIsShareHistory}
      isShareWholeProperty={isShareWholeProperty}
      setIsShareWholeProperty={setIsShareWholeProperty}
      type={type}
      spaceList={spaceList}
      checkedItemIds={checkedItemIds}
      setCheckedItemIds={setCheckedItemIds}
      isSetShareScope={isSetShareScope}
      handlePressNext={handlePressNext}
      displayPremiumOption
      isShown={false}
      onViewPlan={() => null}
      onCancel={() => null}
      // isShown={isShown}
      // onViewPlan={onViewPlan}
      // onCancel={onCancel}
    />
  )
}

export default Select
