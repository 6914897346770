import styled from 'styled-components'

type OptionWrapperProps = {
  readonly isOpen?: boolean
}
export const OptionWrapper = styled.div<OptionWrapperProps>`
  position: absolute;
  top: 31px;
  right: 0px;

  background: white;
  border-radius: 4px;
  cursor: pointer;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  overflow: hidden;
`

export const ContextButton = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 4px;
`

export const KebabWrapper = styled.div<OptionWrapperProps>`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background: ${(props) =>
    props.isOpen ? props.theme.colors.White : 'rgba(16, 13, 66, 0.5)'};
  border-radius: 4px;
  &:hover {
    background: ${(props) => props.theme.colors.White};
  }

  svg {
    color: ${(props) =>
      props.isOpen ? props.theme.colors.Black : props.theme.colors.White};
  }

  :hover svg {
    color: ${(props) => props.theme.colors.Black};
  }

  :hover ${OptionWrapper} {
    display: flex;
  }
`

export const OptionItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  padding: 8px 16px;

  &:hover {
    background-color: ${(props) => props.theme.colors.LightBlue};
  }
`
