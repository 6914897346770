import styled from 'styled-components'
import { AddressShader } from 'venus/pages/Properties/PropertyList/PropertyList.styles'

export const Container = styled.div`
`

type ImageContainerProps = {
  readonly isSelected: boolean
}

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 12px 0;

  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.2)};
`

type ItemProps = {
  imageHeight?: number
  readonly coverImage?: string
}
export const Item = styled.div<ItemProps>`
  width: 100%;
  height: ${({ imageHeight }) => imageHeight || 150}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Grey};

  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(66%);
  }

  :hover ${AddressShader} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 100%);
  }

  ${(props) =>
  !!props.coverImage &&
  `background-image: url(${props.coverImage});
    background-position: center center;
    background-repeat: none;
    background-size: cover;`}
`

export const PropertyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`