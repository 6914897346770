export enum TransferOwnershipStep {
  TRANSFER_SELECT = 'TRANSFER_SELECT',
  TRANSFER_CONFIRM = 'TRANSFER_CONFIRM',
  CANCEL_SELECT = 'CANCEL_SELECT',
}

export const TABS = [
  'New Transfer',
  'Pending Transfer'
]

export const TRANSFER_PAGE_TITLE = 'Transfer Vault'
export const TRANSFER_BTN_TEXT = 'Transfer'
export const CANCEL_BTN_TEXT = 'Cancel Transfer'
export const CONFIRM_TRANSFER = 'Confirm Transfer Vault'
export const OWNER_NAME_INPUT_TEXT = 'Full name of next homeowner'
export const OWNER_EMAIL_INPUT_TEXT = 'Email'
export const CONFIRM_TRANSFER_MSG = 'Are you sure you want to transfer ownership of this property vault to next homeowner?'
export const SELECT_TRANSFER_MSG = 'Please select the property you are transferring ownership for and enter the new owner\'s details'
export const INVALID_INPUT_MSG = 'This is required.'
export const INVALID_EMAIL_MSG = 'This email address is invalid.'