import styled from 'styled-components'
import { theme } from 'venus/config'

export const Container = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 24px 32px;
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`
type CopyButtonProps = {
  isCopied: boolean
}
export const CopyButtonHolder = styled.button<CopyButtonProps>`
  position: absolute;
  right: 0;
  bottom: 10px;
  margin-right: 22px;
  border: none;
  background-color: ${theme.colors.White};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.isCopied ? theme.colors.Secondary : theme.colors.SafeGreen};
`