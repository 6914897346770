import styled from 'styled-components'

export const ContentsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

export const BackBtnWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const CloseBtnWrapper = styled.div`
  width: 24px;
  height: 24px;
`
