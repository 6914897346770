import { MenuText, Text } from 'venus/components'

export const MenuTextSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <MenuText title='Title' text='content' />
      <MenuText title='Title' text='content' label='label' />
      <MenuText title='Title' text='content' hideBottomLine />
    </>
  )
}
