import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import _ from 'lodash'
import api from 'venus/api'
import { IImage } from 'venus/types/Image'
import { getErrorMsg } from 'venus/utils'
import { partialUpdateItem, updateItem } from '../item/item'
import { updateSpace } from '../space/space'
import { IProperty } from './properties'

export interface IRequestProperty {
  address: {
    state: string
    suburb: string
    address: string
    postcode: string
  }
  addressId: number
  nationalParcelId: string
  propertyKey: number
  propertyData: {
    area: number
    beds: number
    baths: number
    parking: number
    propertyType?: string
    propertyTypeCode?: string
  }
  imageBaseUrl: string
  imageCount: number
}

export interface ISpace {
  coverImage: string
  createdAt: string
  id: string
  images: IImage[]
  isArchived: boolean
  isPropertyWideSpace: boolean
  name: string
  propertyId: string
  type: string
  updatedAt: string
}

export interface IItem {
  id: string
  type: string
  name: string
  brandName?: string
  modelName?: string
  serialNo?: string
  value?: string
  note?: string
  spaceId?: string
  warrantyDate?: string
  installedAt?: string
  coverImage?: string
  isPersonal?: boolean
  createdBy?: number | string
  createdAt?: string
  updatedAt?: string
}

export interface ICreateSpaceParams {
  propertyId: string
  spaces: {
    name: string
    type: string
  }[]
}

export const errorHandling = (err: any) => {
  const error: AxiosError = err
  console.log('err', err)
  if (!error?.response) {
    throw err
  }
  return isRejectedWithValue(error.response.data)
}

export const getProperty = createAsyncThunk(
  'property/getProperty',
  async ({
    propertyId,
    shareId,
    dreamPropertyId,
  }: {
    propertyId: string
    shareId?: string
    dreamPropertyId?: string
  }) => {
    try {
      const response = await api.get(`/property/${propertyId}`, {
        params: { shareId, dreamPropertyId },
      })

      const property = response.data

      const res = await api.get(
        `/property/${propertyId}/spaces/${property.propertyWideSpaceId}`,
        {
          params: { shareId, dreamPropertyId },
        },
      )

      const propertyWideSpace = res.data

      return { ...property, ..._.omit(propertyWideSpace, ['id']) }
    } catch (err) {
      console.log('err in getProperty', err)
      return errorHandling(err)
    }
  },
)

export const getPropertyWideSpace = createAsyncThunk(
  'property/getPropertyWideSpace',
  async ({
    propertyId,
    spaceId,
    shareId,
    dreamPropertyId,
  }: {
    propertyId: string
    spaceId: string
    shareId?: string
    dreamPropertyId?: string
  }) => {
    try {
      const response = await api.get(`/property/${propertyId}/spaces/${spaceId}`, {
        params: { shareId, dreamPropertyId },
      })

      return response.data
    } catch (err) {
      console.log('err in getProperty space', err)
      return errorHandling(err)
    }
  },
)

export const getPropertyDocument = createAsyncThunk(
  'property/getPropertyDocument',
  async ({
    propertyId,
    type,
    spaceId,
    shareId,
  }: {
    propertyId: string
    type: string
    spaceId: string
    shareId?: string
  }) => {
    try {
      const response = await api.get(`/property/${propertyId}`, {
        params: { shareId },
      })
      console.log('getPropertyDocument::response.data::', response.data)
      if (type === 'property') {
        return response.data.documents
      }
      return response.data.spaces.filter(({ id }: { id: string }) => id === spaceId)[0]
        .documents
    } catch (err) {
      console.log('err in getProperty doc', err)
      return errorHandling(err)
    }
  },
)

export const createProperty = createAsyncThunk(
  'property/create',
  async (propertyObj: IRequestProperty, { rejectWithValue }) => {
    try {
      const response = await api.post('property', propertyObj)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  },
)

export const createNoneNpdProperty = createAsyncThunk(
  'property/createNoneNpdProperty',
  async (
    propertyObj: {
      googlePlaceId: string
      image: string
      address: {
        address: string
        suburb: string
        state: string
        postcode: string
      }
      propertyData: {
        area: number
        baths: number
        beds: number
        parking: number
        propertyType: string
        propertyTypeCode: string
      }
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post('property', propertyObj)
      console.log('createNoneNpdProperty data:', response.data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  },
)

export const getPropertyTemplate = createAsyncThunk(
  'property/getTemplate',
  async (propertyId: string) => {
    try {
      const response = await api.get(`property/${propertyId}/template`)
      console.log('getPropertyTemplate data:', response.data)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const createSpace = createAsyncThunk(
  'space/create',
  async ({ spaces, propertyId }: ICreateSpaceParams) => {
    try {
      const response = await api.post(`/property/${propertyId}/spaces`, spaces)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

export const getSpaceList = createAsyncThunk('space/getSpaceList', async (id: string) => {
  try {
    const response = await api.get(`/property/${id}/spaces`)
    return response.data
  } catch (err) {
    return errorHandling(err)
  }
})

export const getItemList = createAsyncThunk(
  'space/getItemList',
  async ({ propertyId, spaceId }: { propertyId: string; spaceId: string }) => {
    try {
      const response = await api.get(`property/${propertyId}/space/${spaceId}/items`)
      console.log('getItemList response::', response.data)
      return response.data
    } catch (err) {
      return errorHandling(err)
    }
  },
)

interface Property extends IProperty {
  items?: IItem[]
}

const initialState = {
  error: '',
  property: undefined,
  propertyId: '',
  spaces: [],
  items: [],
  loading: false,
} as {
  error: string
  property?: Property
  propertyId: string
  spaces: ISpace[]
  items: IItem[]
  loading: boolean
}

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    resetPropertyStore: () => initialState,
    resetError: (state: any) => {
      state.error = ''
    },
    setPropertyId: (state: any, action: PayloadAction<string>) => {
      const { payload } = action
      state.propertyId = payload
      console.log('state.propertyId22::', state.propertyId)
    },
    updateProperty: (state: any, action: any) => {
      state.property = { ...state.property, ...action.payload }
    },
    updatePropertySpaces: (state, action) => {
      const updatedSpace = action.payload

      const spaces = [...(state.property?.spaces || [])]
      const index = spaces?.findIndex((space) => space.id === updatedSpace.id)

      if (index === -1) {
        return
      }

      spaces.splice(index, 1, { ...spaces[index], ...updatedSpace })

      // @ts-ignore
      state.property = { ...state.property, spaces }
    },
    addNewItem: (state: any, action: any) => {
      state.property = {
        ...state.property,
        items: action.payload,
      }
    },
    updatePropertyItem: (state: any, action: any) => {
      state.property = {
        ...state.property,
        items: [
          action.payload,
          ...state.property.items.filter((item: any) => item.id !== action.payload.id),
        ],
      }
    },
    updateItemInProperty: (state, action) => {
      const index = state.property.items.findIndex((item) => item.id === action.payload.id)

      if (index > -1) {
        state.property.items[index] = {
          ...state.property.items[index],
          ...action.payload,
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSpace, (state, action: any) => {
      console.log('action on property', action.payload)
      const updatedSpace = action.payload
      if (state.property) {
        const spaces = state.property.spaces.map((space) =>
          space.id === updatedSpace.id || space.id === updatedSpace.spaceId
            ? {
                ...space,
                ...updatedSpace,
                coverImage: updatedSpace?.coverImage,
              }
            : space,
        )
        state.property = { ...state.property, spaces }
      }
    })

    builder.addCase(updateItem, (state, action) => {
      const updatedItem = action.payload as IItem

      if (state.property) {
        const filtered = state.property.items.filter(
          (item: IItem) => item.id !== updatedItem.id,
        )

        const oldItem = state.property.items.find(
          (item: IItem) => item.id === updatedItem.id,
        )
        if (oldItem) {
          state.property = {
            ...state.property,
            items: [updatedItem, ...filtered],
          }
        }
      }
    })
    builder.addCase(partialUpdateItem, (state, action) => {
      const updatedItem = action.payload as IItem
      if (state.property) {
        const filtered = state.property.items.filter((item) => item.id !== updatedItem.id)

        const oldItem = state.property.items.find((item) => item.id === updatedItem.id)
        if (oldItem) {
          state.property = {
            ...state.property,
            items: [{ ...oldItem, ...updatedItem }, ...filtered],
          }
        }
      }
    })

    builder.addCase(getProperty.pending, (state, action) => {
      state.loading = true
      state.property = undefined
      state.propertyId = ''
    })
    builder.addCase(getProperty.fulfilled, (state, action) => {
      state.loading = false
      state.property = action.payload
    })
    builder.addCase(getProperty.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as string
    })

    builder.addCase(getPropertyWideSpace.fulfilled, (state, action) => {
      state.property = { ...state.property, ..._.omit(action.payload, ['id']) }
    })
    builder.addCase(createProperty.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(createProperty.fulfilled, (state, action) => {
      state.loading = false
      state.property = action.payload
    })
    builder.addCase(createProperty.rejected, (state, action) => {
      state.loading = false
      const errorMsg = getErrorMsg(action.payload)
      if (Array.isArray(errorMsg)) {
        let message = ''
        errorMsg.forEach(({ msg }) => {
          message += `${msg} `
        })
        state.error = message
      } else if (typeof errorMsg === 'string') {
        state.error = errorMsg
      } else if (Array.isArray(errorMsg.errors)) {
        let message = ''
        errorMsg.errors.forEach(({ msg }) => {
          message += `${msg} `
        })
        state.error = message
      } else {
        state.error = JSON.stringify(errorMsg)
      }
    })
    builder.addCase(createNoneNpdProperty.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(createNoneNpdProperty.fulfilled, (state, action) => {
      state.loading = false
      state.property = action.payload
    })
    builder.addCase(createNoneNpdProperty.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(createSpace.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(createSpace.fulfilled, (state, action) => {
      state.loading = false
      state.spaces = action.payload
    })
    builder.addCase(createSpace.rejected, (state, action) => {
      state.loading = false
    })
    builder.addCase(getSpaceList.fulfilled, (state, action) => {
      state.loading = false
      state.spaces = action.payload
    })
    builder.addCase(getItemList.fulfilled, (state, action) => {
      state.loading = false
      console.log('get item list fulfilled payload::', action.payload)
      state.items = action.payload
    })
  },
})

export const {
  setPropertyId,
  updateProperty,
  addNewItem,
  updatePropertyItem,
  updateItemInProperty,
  updatePropertySpaces,
  resetError,
  resetPropertyStore,
} = propertySlice.actions

export default propertySlice.reducer
