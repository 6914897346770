import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 32px;
`

export const BackIconWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 24px 0;
`
