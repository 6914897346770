import styled from 'styled-components'
import { AddressShader } from 'venus/pages/Properties/PropertyList/PropertyList.styles'
import { theme } from 'venus/config'

type ImageContainerProps = {
  height?: number
}

export const Container = styled.div<ImageContainerProps>`
  height: ${props => props.height || 84}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  padding: 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.LightBlue};
  }
`

type ItemProps = {
  imageWidth?: number
  imageHeight?: number
  readonly coverImage?: string
}
export const Item = styled.div<ItemProps>`
  width: ${props => props.imageWidth}px;
  height: ${props => props.imageHeight}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Grey};

  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(66%);
  }

  :hover ${AddressShader} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 100%);
  }

  background-image: url(${(props) => props.coverImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`
export const RadioWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 20px;
`

export const PropertyDataWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 20px;
`

export const Radio = styled.input<{ checked?: boolean }>`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.colors.Secondary};
  border-radius: 50%;
  background-color: ${(props) => {
    return props.defaultChecked ? 'rgba(73, 195, 202, 0.4)' : theme.colors.White
  }};
}
`
