import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 24px;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  margin-top: 20px;
`

type SectionProps = {
  readonly flex: number
}
export const Section = styled.section<SectionProps>`
  flex: ${(props) => props.flex};
  margin: 8px 0;
`
