import styled from 'styled-components'

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Section = styled.div`
  flex: 1;
`
