import styled from 'styled-components'

export const List = styled.ul`
  margin: 0;
  margin-top: 24px;
  margin-bottom: 48px;
  padding: 0;
  list-style: none;
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 28px;
  grid-row-gap: 28px;
`
type ItemProps = {
  readonly coverImage?: string
  readonly isGradient: boolean
  readonly colors: string[]
}

export const AddressShader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.84) 0%, rgba(0, 0, 0, 0) 100%);
  transition: all 0.3s;
`

export const Item = styled.li<ItemProps>`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Grey};

  ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: calc(66%);
  }

  :hover ${AddressShader} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0) 100%);
  }

  ${(props) =>
    !!props.coverImage &&
    !props.isGradient &&
    `background-image: url(${props.coverImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;`}

  ${(props) =>
    props.isGradient &&
    `
      background: linear-gradient(0deg, ${props.colors[0]}, ${props.colors[1]});
    `}
`

export const PropertyCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: flex-end;
  padding: 12px 20px;

  cursor: pointer;
`
