import { Button, PillTag, Text } from 'venus/components'
import { theme } from 'venus/config'
import { Share } from 'venus/pages/Share'
import { Create } from 'venus/pages/Reminder'
import {
  ActionButton,
  Actions,
  BackButton,
  BackCover,
  Container,
  Content,
  TagSection,
  TitleSection,
  TagWrapper,
  TagSubTitleWrapper,
} from './PSIHeader.styles'
import { ReactComponent as Pencil } from 'venus/assets/img/icons/Pencil.svg'
import { useEffect, useMemo, useState } from 'react'
import { ReactComponent as Back } from 'venus/assets/img/icons/Back.svg'
import { ReactComponent as ShareIcon } from 'venus/assets/img/icons/Share.svg'
import { ReactComponent as Calendar } from 'venus/assets/img/icons/Calendar.svg'
import { ReactComponent as Radar } from 'venus/assets/img/icons/Radar.svg'
import { getColorsFromUrl, isColorBackground } from 'venus/utils'
import PSISetting from 'venus/common/PSISetting'
import { IImage } from 'venus/types/Image'
import usePremium from 'venus/hooks/usePremium'
import usePremiumContext from 'venus/context/MHVPremium'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import useUploadImage from 'venus/hooks/useUploadImage'
import ScanHome from 'venus/common/ScanHome'
import ImageUploader from 'venus/common/ImageUploader'
import {
  setImage,
  setName,
  setProgress,
  setUrl,
  reset,
} from 'venus/redux/features/image/image'
import axios from 'axios'
import api from 'venus/api'
interface IPSIHeader {
  title: string
  subTitle?: string
  invertSubTitle?: boolean
  hideBackButton?: boolean
  background?: string
  onClick?: () => void
  onDelete?: () => void
  isPendingTransfer?: boolean
  isPremium?: boolean
  dreamId?: string
  onBack: () => void
  setting: {
    name: string
    coverImage: string
    images: IImage[]
  }
  onUpdateSetting?: (data: any) => void
  isShared: boolean
  isPersonal?: boolean
  isDreamProperty?: boolean
  sharedBy?: string
  tag: string
}

const PSIHeader = ({
  background,
  title,
  subTitle,
  setting,
  onUpdateSetting,
  onDelete,
  isShared,
  isPersonal = false,
  isDreamProperty,
  sharedBy,
  tag,
  onClick,
  invertSubTitle,
  hideBackButton,
  isPendingTransfer,
  isPremium,
  dreamId,
  onBack,
}: IPSIHeader) => {
  const dispatch = useAppDispatch()
  const [isHover, setIsHover] = useState(false)
  const [isShareOpen, setIsShareOpen] = useState(false)
  const [isReminderOpen, setIsReminderOpen] = useState(false)
  const [shouldScanhome, setShouldScanhome] = useState(false)
  const [isPSISettingOpen, setIsPSISettingOpen] = useState(false)

  const { propertyId, spaceId, itemId } = useParams()
  const { propertyStatus } = usePremium(propertyId)
  const { openPremium } = usePremiumContext()
  const { image, pickImage, progress, url, reset } = useUploadImage()
  const { property } = useAppSelector((state) => state.property)
  const { space: defaultSpace } = useAppSelector((state) => state.space)

  const propertyWideSpace = property?.spaces?.find((s) => s.isPropertyWideSpace)

  const space = spaceId
    ? spaceId === defaultSpace?.id
      ? defaultSpace
      : propertyWideSpace
    : propertyWideSpace

  // const space = useMemo(() => {
  //   // use property wide space if current space does not match

  //   console.log('space', spaceId, defaultSpace, propertyWideSpace)

  //   if (!spaceId) {
  //     return propertyWideSpace
  //   }
  //   return
  // }, [property, defaultSpace, spaceId])

  const handleScanHome = (event) => {
    event.stopPropagation()
    pickImage()
  }

  const handleClickShare = (event) => {
    event.stopPropagation()
    setIsShareOpen(true)
  }

  const handleClickReminder = (event) => {
    event.stopPropagation()
    if (propertyStatus === 'free') {
      return openPremium()
    }
    setIsReminderOpen(true)
  }

  const handleCloseShare = () => {
    setIsShareOpen(false)
  }

  const handleCloseReminder = () => {
    setIsReminderOpen(false)
  }

  const openPSISetting = (event) => {
    console.log('event', event)
    if (event.target === event.currentTarget) {
      event.stopPropagation()
    }
    if (isShared) {
      return
    }

    setIsPSISettingOpen(true)
  }
  const handlePSISettingClose = () => {
    setIsPSISettingOpen(false)
    dispatch(reset())
  }
  const isGradient = isColorBackground(background)

  const updateSetting = async (data) => {
    await onUpdateSetting(data)
    handlePSISettingClose()
  }

  const deleteSetting = async () => {
    if (onDelete) await onDelete()
    handlePSISettingClose()
  }

  console.log('space!!!', space)

  return (
    <>
      <Share
        propertyId={propertyId}
        spaceId={spaceId}
        itemId={itemId}
        onClose={handleCloseShare}
        isOpen={isShareOpen}
      />
      <Create isOpen={isReminderOpen} onClose={handleCloseReminder} />
      <ScanHome
        individual={false}
        goNext={() => reset()}
        space={space}
        isOpen={!!image}
        close={() => reset()}
        image={image}
      />
      <PSISetting
        tag={tag}
        isOpen={isPSISettingOpen}
        onDelete={deleteSetting}
        hideDelete={!onDelete}
        onClose={handlePSISettingClose}
        {...setting}
        isPersonal={isPersonal}
        onSubmit={updateSetting}
      />
      <Container
        onClick={openPSISetting}
        image={background}
        isGradient={isGradient}
        colors={isGradient ? getColorsFromUrl(background) : []}
        isShared={isShared}
      >
        {!isGradient && <BackCover />}

        {/* <PillTag
        label={title}
        fontSize={32}
        fontWeight={600}
        lineHeight={1.5}
        paddingHorizontal={20}
      /> */}
        <Content>
          <TitleSection>
            <TagWrapper>
              <Text format='h4' fontSize={24} lineHeight={1.5} color={theme.colors.Primary}>
                {title}
              </Text>
            </TagWrapper>

            {subTitle && (
              <TagSubTitleWrapper>
                <Text format='h4' fontSize={16} lineHeight={1.5} color={theme.colors.White}>
                  {subTitle}
                </Text>
              </TagSubTitleWrapper>
            )}
          </TitleSection>

          {!isShared && (
            <div className='editIcon'>
              <Pencil
                style={{ marginLeft: 10 }}
                color={theme.colors.White}
                height={18}
                width={18}
              />
            </div>
          )}

          <BackButton>
            <Button type='round' onClick={onBack} iconLeft={<Back />} />
          </BackButton>
          {!isShared && !isPersonal && (
            <Actions>
              {/* @ts-ignore */}
              {!subTitle && (
                <ActionButton onClick={handleScanHome}>
                  <Radar />
                  <Text format='h4' color={theme.colors.White} style={{ marginLeft: 5 }}>
                    scan home
                  </Text>
                </ActionButton>
              )}

              <ActionButton onClick={handleClickShare}>
                <ShareIcon />
                <Text format='h4' color={theme.colors.White} style={{ marginLeft: 5 }}>
                  share
                </Text>
              </ActionButton>
              <ActionButton onClick={handleClickReminder}>
                <Calendar />
                <Text format='h4' color={theme.colors.White} style={{ marginLeft: 5 }}>
                  create reminder
                </Text>
              </ActionButton>
            </Actions>
          )}

          {isShared && (
            <TagSection>
              {isDreamProperty ? (
                <PillTag
                  label='Dream Property'
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={1.5}
                  paddingHorizontal={14}
                />
              ) : (
                <PillTag
                  background={theme.colors.Secondary}
                  label={sharedBy ? `Shared by ${sharedBy}` : 'Shared Property'}
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={1.5}
                  paddingHorizontal={14}
                />
              )}
            </TagSection>
          )}
        </Content>
      </Container>
    </>
  )
}

export default PSIHeader
