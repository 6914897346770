import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReminder, TStatus, TDetail } from './types'
import {
  createReminder,
  getReminderList,
  getReminderDetail,
  updateReminder,
  deleteReminder,
} from './async'
import { getErrorMsg } from 'venus/utils'

const initialState: IReminder = {
  list: [],
  detail: {} as TDetail,
  status: 'ONGOING',
  loading: false,
  error: '',
}

export const reminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      const { payload } = action
      state.error = payload
    },
    resetError: (state) => {
      state.error = ''
    },
    resetReminderStore: () => initialState,
    setStatus: (state, action: PayloadAction<TStatus>) => {
      const { payload } = action
      state.status = payload
    },
    resetReminder: (state) => {
      state.list = []
      state.detail = {} as TDetail
    },
    setDetail: (state, action: PayloadAction<TDetail>) => {
      const { payload } = action
      state.detail = payload
    },
    deleteLocalReminder: (state, action: PayloadAction<string>) => {
      const { payload } = action
      console.log('payload:', payload)
      state.list = [...state.list.filter(({ id }) => id !== payload)]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createReminder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createReminder.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(createReminder.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(getReminderList.pending, (state) => {
      state.list = []
      state.loading = true
    })
    builder.addCase(getReminderList.fulfilled, (state, action) => {
      state.list = action.payload
      state.loading = false
    })
    builder.addCase(getReminderList.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(getReminderDetail.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getReminderDetail.fulfilled, (state, action) => {
      state.detail = action.payload
      state.loading = false
    })
    builder.addCase(getReminderDetail.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(updateReminder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateReminder.fulfilled, (state, action) => {
      const { id } = state.detail
      const current = action.payload.filter(({ id: thisId }) => id === thisId)
      state.detail = current[0]
      state.list = action.payload
      state.loading = false
    })
    builder.addCase(updateReminder.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
    builder.addCase(deleteReminder.pending, (state) => {})
    builder.addCase(deleteReminder.fulfilled, (state, action) => {
      state.list = action.payload
      state.loading = false
    })
    builder.addCase(deleteReminder.rejected, (state, action) => {
      state.loading = false
      state.error = getErrorMsg(action.payload)
    })
  },
})

export const {
  setError,
  resetError,
  resetReminder,
  setStatus,
  setDetail,
  deleteLocalReminder,
  resetReminderStore,
} = reminderSlice.actions

export default reminderSlice.reducer
