import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`
export const InputSection = styled.div`
  width: 48%;
`

export const TnCSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormWrapper = styled.form`
  overflow-y: scroll;
  padding-bottom: 10px;
`

export const FixedButtonArea = styled.div`
  background-color: white;
`

export const GeneralInfoWrapper = styled.form`
  height: 500px;
  overflow-y: scroll;
`

export const CheckBoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 3px;
  height: 28px;
`
