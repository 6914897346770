import { useState } from 'react'
import QRCode from 'react-qr-code'
import { useAppSelector } from 'venus/redux/hooks'
import { QRCodeArea } from './CopyLink.styles'
import { Loader, InputMultipleLine, ActionLayout } from 'venus/components'

export const CopyLink = () => {
  const { shareLink, isGenerateQRCode, loading } = useAppSelector(
    (state) => state.share
  )
  const [btnText, setBtnText] = useState('Copy URL')
  if (loading) return <Loader isFull />

  const handlePressCopyLink = async () => {
    await navigator.clipboard.writeText(shareLink)
    setBtnText('URL copied.')
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'primary',
          text: btnText,
          onClick: handlePressCopyLink,
          isDisabled: btnText === 'URL copied.',
        },
      ]}
    >
      <>
        {isGenerateQRCode && (
          <QRCodeArea>
            <QRCode value={shareLink} size={220} />
          </QRCodeArea>
        )}
        <InputMultipleLine
          label='Your Link'
          value={shareLink}
          onChange={() => {}}
          isDisabled
        />
      </>
    </ActionLayout>
  )
}
