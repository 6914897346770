import React from 'react'
import { Container } from './PrivacyPolicy.styles'

export const PRIVACY_POLICY_LINK_TEXT = 'Privacy Policy'

export const PrivacyPolicy = ({
  top = 0,
  isModal = false,
}: {
  top?: number
  isModal?: boolean
}) => {
  return (
    <Container top={top} isModal={isModal}>
      <h1>Privacy Policy</h1>
      <p style={{ paddingTop: '1rem' }}>
        MyHomeVault Pty Ltd ACN 648 732 441, together with its related bodies corporate and
        related entities (<b>MyHomeVault</b>), values and respects the privacy of the people
        we deal with. MyHomeVault is committed to protecting your privacy and complying with
        the Privacy Act 1988 (Cth) (<b>Privacy Act</b>) and other applicable privacy laws
        and regulations.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        This Privacy Policy (<b>Policy</b>) describes how we collect, hold, use and disclose
        your personal information provided/uploaded by you or us to the MHV Platform, and
        how we maintain the quality and security of your personal information. The Policy is
        intended to be read in conjunction with the MHV Platform Terms and Conditions of
        Access and Use (<b>Terms</b>). The Terms are available at&nbsp;
        <a
          href='https://app.myhomevault.com.au/terms-and-conditions'
          target='_blank'
          rel='noreferrer'
          style={{ lineBreak: 'anywhere' }}
        >
          https://www.myhomevault.com.au
        </a>
      </p>
      <h2 style={{ paddingTop: '1rem' }}>The MHV Platform</h2>
      <p style={{ paddingTop: '1rem' }}>
        The MHV Platform is a property search and property/home ownership management,
        utility and documentation monitoring application, that allows users to:
      </p>
      <ul>
        <li>
          <p>
            monitor real estate market data relevant to their property(ies) and to third
            party owned properties, including properties the user would like to live in or
            add to their property portfolio (ie their &ldquo;dream properties&rdquo;);
          </p>
        </li>
        <li>
          <p>
            track and monitor in one place the status of items relevant to their
            property(ies)/home, such as, title deeds, contracts, plans, Council development
            applications, insurance policies, utilities, appliances, instruction manuals,
            historical information, and important contacts, eg, builder, architect, real
            estate agents or tradespeople; and
          </p>
        </li>
        <li>
          <p>
            engage with other homeowners, property investors and developers, real estate
            agents, tradespeople, and other persons providing goods or services, in relation
            to the real estate industry, and getting things done around the home and
            monitoring those items.
          </p>
        </li>
      </ul>
      <p>
        From time to time, certain functionalities of the MHV Platform are made available to
        users through MyHomeVault with or without the use of or accessing third party
        products or services (the MHV Associated Products/Services).
      </p>
      <h2 style={{ paddingTop: '1rem' }}>What is personal information?</h2>
      <p style={{ paddingTop: '1rem' }}>
        &ldquo;Personal information&rdquo; means any information or opinion, whether true or
        not, and whether recorded in a material form or not, about an identified individual
        or an individual who is reasonably identifiable. In general terms, this includes
        information or an opinion that personally identifies you either directly (e.g. your
        name) or indirectly.
      </p>
      <h2 style={{ paddingTop: '1rem' }}>What personal information do we collect?</h2>
      <p style={{ paddingTop: '1rem' }}>
        The personal information we collect about you depends on the nature of your dealings
        with us or what you choose to share with us.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        The personal information we collect about you is defined in clauses 2.2, 2.6, 2.10,
        and 2.11 and, in some cases, other clauses, of the Terms, and include, depending on
        the way in which you use the MHV Platform, your:
      </p>
      <ul style={{ paddingTop: '1rem' }}>
        <li>
          <p>email address;</p>
        </li>
        <li>
          <p>username (if different to your email address);</p>
        </li>
        <li>
          <p>telephone number;</p>
        </li>
        <li>
          <p>MHV Platform password; </p>
        </li>
        <li>
          <p>MHV Platform pin; </p>
        </li>
        <li>
          <p>billing/payment information;</p>
        </li>
        <li>
          <p>the fact that you have become a Registered User;</p>
        </li>
        <li>
          <p>
            Spaces, Items, Photos, Videos, Documents, History, Tradespeople, Contacts,
            Shared, and Reminders, related information that you record, or someone else
            records, about a property address or property, as those capitalised terms are
            defined in the Terms;
          </p>
        </li>
        <li>
          <p>
            the fact that another Registered User has elected to share with your login some
            or all of the above information;
          </p>
        </li>
        <li>
          <p>
            your profession/trade, details of your qualifications, including details of your
            license permitting you to practice in your profession/trade and any breadth or
            limitations on such license, the content of any opinion/quotation that you have
            given in relation to, or to do anything to or for, one or more property(ies);
          </p>
        </li>
        <li>
          <p>
            the fact of your interest in certain property(ies), and the capacity in which
            you hold that/ interest(s); and
          </p>
        </li>
        <li>
          <p>
            the fact that you or other Registered Users have elected to share with
            others&rsquo; the date are recorded against their logins.
          </p>
        </li>
      </ul>
      <p style={{ paddingTop: '1rem' }}>
        You do not have to provide us with your personal information. Where possible, we
        will give you the option to interact with us anonymously or by using a pseudonym.
        However, if you choose to deal with us in this way or choose not to provide us with
        your personal information, we may not be able to provide you with our services or
        otherwise interact with you.
      </p>
      <h2 style={{ paddingTop: '1rem' }}>How do we collect your personal information?</h2>
      <p style={{ paddingTop: '1rem' }}>
        We collect your personal information directly from you when you:
      </p>
      <ul>
        <li>
          <p>interact with us over the phone;</p>
        </li>
        <li>
          <p>interact with us in person;</p>
        </li>
        <li>
          <p>interact with us online;</p>
        </li>
        <li>
          <p>participate in surveys or questionnaires;</p>
        </li>
        <li>
          <p>attend a MyHomeVault event;</p>
        </li>
        <li>
          <p>subscribe to our mailing list; and/or</p>
        </li>
        <li>
          <p>
            provide/upload, or otherwise make available (including by other third-parties or
            Registered Users), any of the above identified personal information, through or
            against your login to the MHV Platform
          </p>
        </li>
      </ul>
      <h2 style={{ paddingTop: '1rem' }}>
        Collecting personal information from third parties
      </h2>
      <p style={{ paddingTop: '1rem' }}>
        We may also collect your personal information from third parties or through publicly
        available sources, for example, from parties through whom/which we provide the MHV
        Associated Products/Services in association with the MHV Platform.
      </p>
      <h2 style={{ paddingTop: '1rem' }}>How do we use your personal information?</h2>
      <p style={{ paddingTop: '1rem' }}>
        We use personal information for many purposes in connection with our functions and
        activities, including the following purposes:
      </p>
      <ul>
        <li>
          <p>provide you with information or services through the MHV Platform;</p>
        </li>
        <li>
          <p>deliver to you a more personalised experience and service offering;</p>
        </li>
        <li>
          <p>improve the quality of the services we offer;</p>
        </li>
        <li>
          <p>internal administrative purposes;</p>
        </li>
        <li>
          <p>marketing and research purposes; and</p>
        </li>
        <li>
          <p>for the purposes generally defined in clause 3 of the Terms.</p>
        </li>
      </ul>
      <h2 style={{ paddingTop: '1rem' }}>
        Disclosure of personal information to third parties
      </h2>
      <p style={{ paddingTop: '1rem' }}>
        We may disclose your personal information to third parties in accordance with this
        Policy in circumstances where you would reasonably expect us to disclose your
        information. For example, we may disclose your personal information to:
      </p>
      <ul>
        <li>
          <p>
            our third party service providers (for example, our IT providers, or the service
            providers through whom/which we provide the MHV Associated Products/Services in
            association with the MHV Platform);
          </p>
        </li>
        <li>
          <p>
            real estate agents in relation to Shared information, as that term is defined in
            clause 2.10.a.x of the Terms;
          </p>
        </li>
        <li>
          <p>
            Tradespeople, or other persons offering goods or services in the real estate
            industry;
          </p>
        </li>
        <li>
          <p>our marketing providers; and/or</p>
        </li>
        <li>
          <p>our professional services advisors;</p>
        </li>
      </ul>
      <h2 style={{ paddingTop: '1rem' }}>Transfer of personal information overseas</h2>
      <p style={{ paddingTop: '1rem' }}>
        Some of the third-party service providers we disclose personal information to may be
        based in or have servers located outside of Australia, including in overseas
        countries where third parties have servers.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        Where we disclose your personal information to third parties overseas, we will take
        reasonable steps to ensure that data security and appropriate privacy practices are
        maintained. We will only disclose to overseas third parties if:
      </p>
      <ul style={{ paddingTop: '1rem' }}>
        <li>
          <p>we reasonably believe that:</p>
        </li>
        <ul>
          <li>
            <p>
              the overseas recipient is subject to a law or binding scheme that is, overall,
              substantially similar to the Australian Privacy Principles; and
            </p>
          </li>
          <li>
            <p>the law or binding scheme can be enforced; or</p>
          </li>
        </ul>
      </ul>
      <ul>
        <li>
          <p>
            the disclosure is required or authorised by an Australian law or court /
            tribunal order.
          </p>
        </li>
      </ul>
      <h2 style={{ paddingTop: '1rem' }}>How do we protect your personal information?</h2>
      <p style={{ paddingTop: '1rem' }}>
        MyHomeVault will take reasonable steps to ensure that the personal information that
        we hold about you is kept confidential and secure, including by:
      </p>
      <ul>
        <li>
          <p>
            taking measures to restrict access to only personnel who need that personal
            information to effectively provide services to you;
          </p>
        </li>
        <li>
          <p>
            having technological measures in place (for example, adopting Secure Sockets
            Layer (SSL) and Amazon Web Services cloud infrastructure).
          </p>
        </li>
      </ul>
      <h2 style={{ paddingTop: '1rem' }}>Online activity</h2>
      <p style={{ paddingTop: '1rem' }}>
        <i>Cookies</i>
      </p>
      <p style={{ paddingTop: '1rem' }}>
        The MyHomeVault website uses cookies. A cookie is a small file of letters and
        numbers the website puts on your device if you allow it. These cookies recognise
        when your device has visited our website(s) before, so we can distinguish you from
        other users of the website. This improves your experience and the MyHomeVault
        website(s).
      </p>
      <p style={{ paddingTop: '1rem' }}>
        We do not use cookies to identify you, just to improve your experience on our
        website(s). If you do not wish to use the cookies, you can amend the settings on
        your internet browser so it will not automatically download cookies. However, if you
        remove or block cookies on your computer, please be aware that your browsing
        experience and our website&rsquo;s functionality may be affected.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        <i>Website analytics</i>
      </p>
      <p style={{ paddingTop: '1rem' }}>
        Our website uses relevant analytics services to help us better understand visitor
        traffic, so we can improve our services. Although this data is mostly anonymous, it
        is possible that under certain circumstances, we may connect it to you.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        <i>Direct marketing</i>
      </p>
      <p style={{ paddingTop: '1rem' }}>
        We may send you direct marketing communications and information about our services,
        opportunities, or events that we consider may be of interest to you if you have
        requested or consented to receive such communications. These communications may be
        sent in various forms, including mail, SMS, fax and email, in accordance with
        applicable marketing laws, such as the <i>Australian Spam Act 2003 </i>
        (Cth). You consent to us sending you those direct marketing communications by any of
        those methods. If you indicate a preference for a method of communication, we will
        endeavour to use that method whenever practical to do so.
      </p>
      <p style={{ paddingTop: '1rem' }}>
        You may opt-out of receiving marketing communications from us at any time by
        [following the instructions to &ldquo;unsubscribe&#39;&#39; set out in the relevant
        communication] / [contacting us using the details set out in the &ldquo;How to
        contact us&rdquo; section below].
      </p>
      <p style={{ paddingTop: '1rem' }}>
        In addition, we may also use your personal information or disclose your personal
        information to third parties for the purposes of advertising, including online
        behavioural advertising, website personalisation, and to provide targeted or
        retargeted advertising content to you (including through third party websites).
      </p>
      <h2 style={{ paddingTop: '1rem' }}>Retention of personal information</h2>
      <p style={{ paddingTop: '1rem' }}>
        We will not keep those parts of your personal information that we say we will not
        keep in the Terms for longer than we need to. In most cases, this means that we will
        only retain those parts of your personal information for the duration of your
        relationship with us unless we are required to retain your personal information to
        comply with applicable laws, for example record-keeping obligations. With that said,
        and as set out in the Terms, we will retain Your Personal Information and Your MHV
        Platform Data in the manner outlined in clause 3 of the Terms.
      </p>
      <h2 style={{ paddingTop: '1rem' }}>
        How to access and correct your personal information
      </h2>
      <p style={{ paddingTop: '1rem' }}>
        MyHomeVault will endeavour to keep your personal information as provided/uploaded by
        you accurate, complete and up to date.
      </p>
      <p>
        If you wish to make a request to access and / or correct the personal information we
        hold about you, you should make a request by contacting us and we will usually
        respond within 10 days. We will deal with such a request by following the procedure
        outlined below:
      </p>
      <ul style={{ paddingTop: '1rem' }}>
        <li>
          <p>
            Upon receipt of your request, it will be escalated to an appropriate member of
            our staff to implement, and to our Chief Operating Officer to monitor;
          </p>
        </li>
        <li>
          <p>
            our Chief Operating Officer will check in periodically with the appropriate
            member of staff to ensure that your request is implemented, and in no event, in
            less than 10 business days from receipt of your request.
          </p>
        </li>
      </ul>
      <h2 style={{ paddingTop: '1rem' }}>Links to third party sites</h2>
      <p style={{ paddingTop: '1rem' }}>
        MyHomeVault website(s) may contain links to websites operated by third parties. If
        you access a third party website through our website(s), personal information may be
        collected by that third party website. We make no representations or warranties in
        relation to the privacy practices of any third party provider or website and we are
        not responsible for the privacy policies or the content of any third party provider
        or website. Third party providers / websites are responsible for informing you about
        their own privacy practices and we encourage you to read their privacy policies.
      </p>
      <h2 style={{ paddingTop: '1rem' }}>Inquiries and complaints</h2>
      <p style={{ paddingTop: '1rem' }}>
        For complaints about how MyHomeVault handles, processes or manages your personal
        information, please contact&nbsp;
        <a
          href='https://www.myhomevault.com.au'
          target='_blank'
          rel='noreferrer'
          style={{ lineBreak: 'anywhere' }}
        >
          https://www.myhomevault.com.au
        </a>
        &nbsp;(contact form) Note we may require proof of your identity and full details of
        your request before we can process your complaint.
      </p>
      <p>
        Please allow up to 10 business days for MyHomeVault to respond to your complaint. It
        will not always be possible to resolve a complaint to everyone&rsquo;s satisfaction.
        If you are not satisfied with MyHomeVault&rsquo;s response to a complaint, you have
        the right to contact the Office of Australian Information Commissioner (at&nbsp;
        <a
          href='https://www.oaic.gov.au/'
          target='_blank'
          rel='noreferrer'
          style={{ lineBreak: 'anywhere' }}
        >
          https://www.oaic.gov.au
        </a>
        ) to lodge a complaint.
      </p>
      <h2 style={{ paddingTop: '1rem' }}>How to contact us</h2>
      <p style={{ paddingTop: '1rem' }}>
        If you have a question or concern in relation to our handling of your personal
        information or this Policy, you can contact us for assistance as follows:
      </p>
      <p>
        <b>Contact Form</b>
      </p>
      <p>
        <a
          href='https://www.myhomevault.com.au'
          target='_blank'
          rel='noreferrer'
          style={{ lineBreak: 'anywhere' }}
        >
          https://www.myhomevault.com.au
        </a>
      </p>
      <p>
        <b>Post</b>
      </p>
      <p>Attention: MyHomeVault PTY LTD</p>
      <p>Address: </p>
      <p>Level 11, 80 Mount Street North Sydney NSW 2060, Australia</p>
    </Container>
  )
}
