import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px 20px;
`

export const PlanRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
