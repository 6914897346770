import { ActionModal, Text, Button } from 'venus/components'
import {
  Container,
  ActionBody,
  ActionFooter,
  MessageArea,
} from './Notification.styles'
import { theme } from 'venus/config'
import { ReactElement } from 'react'

interface INotificationBox {
  showAlert: boolean
  closeAlert: () => void
  message: string
  icon?: ReactElement
}

export const Notification = ({
  message,
  showAlert,
  closeAlert,
  icon = <></>,
}: INotificationBox) => (
  <ActionModal
    isOpen={showAlert}
    title='Notification'
    onClose={closeAlert}
    width={420}
    height={230}
    headerStyle={{
      borderBottom: `1px solid #E8EAEE`,
    }}
  >
    <Container>
      <ActionBody margin={34}>
        <MessageArea>
          {icon && (
            <>
              {icon}
            </>
          )}
          <Text
            value={message}
            color={theme.colors.Black}
            format='body'
            lineHeight='21px'
            style={{ textAlign: 'center', marginTop: '14px', marginBottom: '38px' }}
          />
        </MessageArea>
      </ActionBody>
      <ActionFooter margin={34}>
        <Button
          fullBlock
          text='OK'
          type='primary'
          onClick={closeAlert}
          style={{ width: '100%' }}
        />
      </ActionFooter>
    </Container>
  </ActionModal>
)
