import styled from 'styled-components'
import { theme } from 'venus/config'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ListViewContainer = styled.div`
`

export const SearchBoxContainer = styled.div`
  background-color: ${theme.colors.LightBlue};
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
`

export const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 32px;
`

export const HeaderButtonBox = styled.div`
  width: 24px;
  height: 24px;
`

export const SearchResultTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`
export const EmptyArea = styled.div`
  max-height: 80%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SearchEmptyArea = styled.div`
  height: 100%;
  margin-top: 50%;
`
