import { Modal } from 'venus/components'

interface IModalLayout {
  title?: string
  children: JSX.Element
  isOpen: boolean
  closeModal?: () => void
  onBack?: () => void
}

export const ModalLayout = ({
  children,
  isOpen,
  closeModal,
  onBack,
  title = '',
}: IModalLayout) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} onBack={onBack} title={title}>
      {children}
    </Modal>
  )
}
