import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useParams } from 'react-router-dom'
// import { useShareStatus } from 'venus/hooks/useShareStatus'
import {
  getContactList,
  IContactEntry,
  IPhoneContactFields,
} from 'venus/redux/features/contact'
import { TabView, Loader, Button, Text } from 'venus/components'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import { roles } from 'venus/config'
import { TradespersonList } from 'venus/pages/Contact/List/TradespersonList'
import { CreateEditContact } from 'venus/pages/Contact/CreateEditContact'
import { ContactDetails } from 'venus/pages/Contact/ContactDetails'
import { SubList } from 'venus/pages/Contact/List/SubList'
import { Wrapper, IconArea } from './List.styles'

const tabs = [
  'All',
  'Tradespeople',
  'Real Estate Agent',
  'Property Manager',
  'Builder',
  'Other',
]

interface IList {
  type?: string
  isShowAddBtn?: boolean
}

export const List = ({ type, isShowAddBtn = true }: IList) => {
  const dispatch = useAppDispatch()
  const { propertyId } = useParams()
  const { entries, loading, error } = useAppSelector((state) => state.contact)
  const [showManuallyInputModal, setShowManuallyInputModal] = useState(false)
  const [selected, setSelected] = useState<IContactEntry>()
  const [prefilled, setPrefilled] = useState<IPhoneContactFields>()

  console.log('entries::', entries)

  const handlePress = setSelected

  useEffect(() => {
    const getList = async () => {
      if (propertyId) await dispatch(getContactList(propertyId))
    }
    getList()
  }, [propertyId, dispatch])

  // const { isShared } = useShareStatus()
  // if (isShared) return null

  if (loading || !entries) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  const handlePressPlus = () => {
    handlePressManuallyInput()
  }

  const handlePressManuallyInput = () => {
    setPrefilled({} as IPhoneContactFields)
    setShowManuallyInputModal(true)
  }

  const listAll = entries
    ? entries.filter(({ id }) =>
        type === 'WithHomeOwner' ? true : id !== '-1'
      )
    : []
  const listTradespeople = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[1].value?.toLowerCase()
  )
  const listRealEstateAgent = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[2].value?.toLowerCase()
  )
  const listPropertyManager = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[3].value?.toLowerCase()
  )
  const listBuilder = listAll.filter(
    ({ role }) => role[0]?.toLowerCase() === roles[4].value?.toLowerCase()
  )
  const listOther = listAll.filter(
    ({ role }) =>
      role[0]?.toLowerCase() === roles[0].value?.toLowerCase() ||
      role[0]?.toLowerCase() === roles[5].value?.toLowerCase()
  )

  return (
    <>
      <Text
        value='Contacts'
        format='h1'
        style={{
          display: 'inline-block',
          margin: '66px 36px 0px',
        }}
      />
      <Wrapper>
        {isShowAddBtn && (
          <IconArea>
            <Button
              type='icon_button'
              text='Add'
              onClick={handlePressPlus}
              iconLeft={<AddSign style={{ marginLeft: -9, color: 'white' }} />}
              style={{ width: 'auto' }}
            />
          </IconArea>
        )}
        <TabView
          defaultActiveIndex={0}
          tabs={tabs}
          paddingBottom={100}
          headerStyle={{ padding: '24px 0 0px 36px' }}
          style={{ height: '100%' }}
        >
          <SubList handlePress={handlePress} persons={listAll} />
          <TradespersonList
            handlePress={handlePress}
            persons={listTradespeople}
          />
          <SubList handlePress={handlePress} persons={listRealEstateAgent} />
          <SubList handlePress={handlePress} persons={listPropertyManager} />
          <SubList handlePress={handlePress} persons={listBuilder} />
          <SubList handlePress={handlePress} persons={listOther} />
        </TabView>
        {showManuallyInputModal && (
          <CreateEditContact
            prefilled={prefilled}
            propertyId={propertyId}
            setShow={setShowManuallyInputModal}
            isEditMode={false}
            isCreateMode
          />
        )}
        {!!selected?.firstName && (
          <ContactDetails
            propertyId={propertyId}
            setShow={handlePress}
            selected={selected}
          />
        )}
      </Wrapper>
    </>
  )
}
