import { ReactNode, useState } from 'react'
import { Notification, AlertBox, Modal, Text } from 'venus/components'
import { modalStyles } from 'venus/components/Modal'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { ReactComponent as Back } from 'venus/assets/img/icons/Back.svg'
import { ReactComponent as AddSign } from 'venus/assets/img/icons/AddSign.svg'
import {
  BackBtnArea,
  BackBtnWrapper,
  TagArea,
  RightBtnArea,
} from './ContactLayout.styles'
import {
  createContact,
  IContactEntry,
  resetError,
} from 'venus/redux/features/contact'
import { theme } from 'venus/config'

interface IContactLayout {
  children: ReactNode
  handlePressGoBack: () => void
  title: string
  selected?: IContactEntry
  propertyId: string
  displayAddToContact?: boolean
}

export const ContactLayout = ({
  children,
  handlePressGoBack,
  title,
  selected,
  propertyId,
  displayAddToContact = false,
}: IContactLayout) => {
  const dispatch = useAppDispatch()
  const [isDisabled, setIsdisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { error, entries } = useAppSelector((state) => state.contact)
  const isContactAdded = entries.find(
    ({ email, mobile }) =>
      email === selected?.email && mobile === selected?.mobile
  )
  const handleCreateContact = async () => {
    if (isDisabled) return
    const body = {
      propertyId: propertyId || '',
      avatar: selected?.profile.avatar || '',
      firstName: selected?.firstName || '',
      lastName: selected?.lastName || '',
      mobile: selected?.mobile || '',
      email: selected?.email || '',
      role: selected?.role || [],
      tradespeopleRole: selected?.tradespeopleRole || [],
      companyName: selected?.companyName || '',
      companyPhone: selected?.companyPhone || '',
      companyAddress: selected?.companyAddress || '',
      companyURL: selected?.companyURL || '',
    }
    const result = await dispatch(createContact({ body, propertyId }))
    console.log('create contact result::', result)
    if (result.meta.requestStatus === 'fulfilled') {
      setIsdisabled(true)
      setShowAlert(true)
    }
  }

  const handlPressAlertOK = () => {
    setShowAlert(false)
  }

  const handlPressCloseAlert = () => {
    dispatch(resetError())
  }

  return (
    <Modal isOpen title={title} onClose={handlePressGoBack}>
      {displayAddToContact && (
        <BackBtnArea>
          (isContactAdded ? (
          <TagArea
            style={{
              backgroundColor: theme.colors.Grey,
            }}
          >
            <Text
              value='Added to Contacts'
              format='h4'
              color={theme.colors.White}
            />
          </TagArea>
          ) : (
          <RightBtnArea
            onClick={handleCreateContact}
            style={{ cursor: isDisabled ? 'default' : 'pointer' }}
          >
            <TagArea
              style={{
                backgroundColor: isDisabled
                  ? theme.colors.Grey
                  : theme.colors.Primary,
              }}
            >
              <AddSign />
              <Text
                value='Add to Contacts'
                format='h4'
                color={theme.colors.White}
              />
            </TagArea>
          </RightBtnArea>
          ))
        </BackBtnArea>
      )}
      <Notification
        message={`${selected?.firstName} ${selected?.lastName} has been added into Contact.`}
        closeAlert={handlPressAlertOK}
        showAlert={showAlert}
      />
      <AlertBox
        title='Add Contact Error'
        message={error}
        cancel={handlPressCloseAlert}
        btnGroup={[
          {
            text: 'OK',
            onClick: handlPressCloseAlert,
            color: 'Primary',
            type: 'error',
          },
        ]}
        showAlert={!!error}
      />
      {children}
    </Modal>
  )
}

export default ContactLayout
