import styled from 'styled-components'

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  list-style: none;
  padding: 0;
`

type ImageProps = {
  readonly url: string
}
const Image = styled.div<ImageProps>`
  cursor: pointer;
  aspect-ratio: 1;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const Gallery = ({ images, onChange }) => {
  const handlePickImage = (image) => (event) => {
    onChange(image.url)

    console.log('url', image.url)
  }

  return (
    <List>
      {(images || []).map((image) => (
        <Image key={image.url} url={image.url} onClick={handlePickImage(image)} />
      ))}
    </List>
  )
}

export default Gallery
