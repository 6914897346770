import styled from 'styled-components'
import { theme } from 'venus/config'

export const SecondRowLeftPartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  height: 20px;
  justify-content: flex-start;
  align-items: center;
`

export const RightLeftPartWrapper = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 50px;
  align-self: center;
`

export const SpaceLabelView = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.Primary};
  padding: 4px 12px;
  height: 22px;
  margin-left: 18px;
`

export const MHVLabelView = styled(SpaceLabelView)`
  background-color: ${theme.colors.Green};
`

export const ItemLabelView = styled(SpaceLabelView)`
  background-color: ${theme.colors.LightBlue};
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 34px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: ${theme.colors.LightBlue};
  }
`

export const EmptyWrapper = styled.div`
  height: 100%;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 24px 0;
  overflow: auto;
`
