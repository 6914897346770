import { ReactComponent as MHV } from 'venus/assets/img/icons/MHV.svg'
import { theme } from 'venus/config'
import { Text } from '../Text'
import { EmptyDataContainer } from './EmptyData.styles'

export const EmptyData = ({
  message,
  fullBlock,
  defaultMessage,
}: {
  message: string
  fullBlock?: boolean
  defaultMessage?: string
}) => {
  return (
    <EmptyDataContainer fullBlock={fullBlock}>
      <div style={{ width: 65 }}>
        <MHV color={theme.colors.LightBlue} />
      </div>

      <Text
        value={defaultMessage ? defaultMessage : "There's Nothing Here!"}
        format='h3'
        color={theme.colors.BlueGrey}
        style={{ margin: 15 }}
      />
      <Text
        style={{ textAlign: 'center', maxWidth: '75%' }}
        value={message}
        format='h4'
        color={theme.colors.BlueGrey}
      />
    </EmptyDataContainer>
  )
}
