import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0;
  margin-top: 46px;
`

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const SpaceItemContainer = styled.li`
  cursor: grab;
  list-style: none;

  padding: 0;
  margin: 0 34px;

  display: flex;
  align-items: center;

  margin-bottom: 16px;
`
