import { AlertBox, Text } from 'venus/components'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { formateDate } from 'venus/utils'
import AddProperty from './AddProperty'
import {
  Container,
  CreditContainer,
  LinkButton,
  PlanRow,
  Renew,
  Section,
} from './PlanDetail.styles'
import PremiumContainer from '../PremiumContainer'
import PropertyList from './PropertyList'
import { MHV_PLANS } from '../Subscriptions'
import { theme } from 'venus/config'
import MobilePayment from './MobilePayment'
import { useState } from 'react'

const PlanDetail = ({ onAddProperty, cancelSubscription, type }: any) => {
  const dispatch = useAppDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const handleCancel = () => {
    cancelSubscription()
    closeModal()
  }

  const {
    productId,
    subscribed,
    unsubscribed,
    credit = 0,
    expireDate,
  } = useAppSelector((state) => state.subscription)

  const plan = MHV_PLANS.find(
    (p) => p.productId.month === productId || p.productId.year === productId,
  )
  const period = plan?.productId?.month === productId ? 'month' : 'year'
  // const period = 'year'

  const { name, price, number } = plan || MHV_PLANS[0]

  return (
    <>
      {type !== 'STRIPE' && <MobilePayment type={type} />}
      <PremiumContainer style={{}}>
        <Container>
          <PlanRow>
            <Text format='h2' value={name} />
            <Text format='h2' value={`$${price[period]}/${period}`} />
          </PlanRow>
          <Renew>
            <Text value='Your plan will automatically renew on ' format='body' />
            <Text value={formateDate(expireDate)} format='h3' />
            <Text value=' unless cancelled' format='body' />
          </Renew>
        </Container>
        <PropertyList properties={subscribed} />
        {!!unsubscribed.length && subscribed.length < number && (
          <AddProperty onClick={onAddProperty} />
        )}
      </PremiumContainer>
      <CreditContainer>
        <Section flex={1}>
          <Text format='h3' value='Your Credit' />
          <Text format='h3' value={String(credit)} />
        </Section>
        <Section flex={2}>
          <Text
            format='body'
            color={theme.colors.DarkestGrey}
            value='Credit is obtained by referring new premium users. Credit can be used to extend your subscriptions.'
          />
        </Section>
      </CreditContainer>
      {type === 'STRIPE' && (
        <div style={{ flex: 1 }}>
          <LinkButton onClick={openModal}>
            <Text color={theme.colors.DangerRed} format='h4'>
              Cancel Subscription
            </Text>
          </LinkButton>
        </div>
      )}

      <AlertBox
        showAlert={isOpen}
        title='Confirm Cancel'
        message={`You can’t access premium features and data anymore.  Do you want to cancel subscription?`}
        cancel={closeModal}
        btnGroup={[
          {
            text: 'Cancel',
            onClick: handleCancel,
            color: 'ErrorState',
            type: 'error',
          },
          {
            text: 'Close',
            onClick: closeModal,
            color: 'LightBlue',
            textColor: 'Primary',
            type: 'secondary',
          },
        ]}
      />
    </>
  )
}

export default PlanDetail
