import React, { useEffect } from 'react'
import { useState } from 'react'
import { Text, ToggleButton } from 'venus/components'
import { ADVANCEDSECURITY_GUIDE_TEXT } from 'venus/layout/ProfileMenu/ProfileSettings/AdvancedSecurity/Constants'
import { TOGGLE_TEXT } from 'venus/layout/ProfileMenu/ProfileSettings/AdvancedSecurity/Constants'
import { InputWrapper, ToggleBtnArea, ToggleBtnWrapper } from 'venus/layout/ProfileMenu/ProfileSettings/AdvancedSecurity/AdvancedSecurity.styles'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { ActionLayout } from 'venus/components'
import { SettingsHeader } from 'venus/layout/ProfileMenu/ProfileSettings/SettingsHeader'
import { updateProfile } from 'venus/redux/features/user'

const AdvancedSecurity = () => {
  const dispatch = useAppDispatch()
  const [isMFAEnabled, setIsMFAEnabled] = useState(false)
  const { info } = useAppSelector((state) => state.user)
  const { mfa } = info

  // useEffect(() => {
  //   dispatch(getProfile())
  // }, [])

  useEffect(() => {
    setIsMFAEnabled(mfa?.isMFAEnabled)
  }, [mfa])

  const handleMFAToggle = async () => {
    // update user with isMFAEnabled
    try {
      const newFlag = isMFAEnabled ? false : true
      setIsMFAEnabled(newFlag)
      let updatedMFA = mfa ? { ...mfa, isMFAEnabled: newFlag } : { isMFAEnabled: newFlag }
      const data = await dispatch(updateProfile({ mfa: updatedMFA }))
      // if (data.meta.requestStatus === 'fulfilled') {
      //   setMsg('Two factor authentication is ' + (newFlag ? 'enabled.' : 'disabled.'))
      //   setShowAlert(true)
      // }
    } catch (err) {
      console.log('err', err.data)
      // setMsg(err.data)
      // setShowAlert(true)
    }
  }

  return (
    <ActionLayout
      margin={28}
      children={
        <>
          <SettingsHeader title={'Advanced Security'} />
          <Text format={'h4'} value={ADVANCEDSECURITY_GUIDE_TEXT} style={{ marginTop: '28px' }} />
          <ToggleBtnArea>
            <ToggleBtnWrapper>
              <ToggleButton
                checked={isMFAEnabled}
                setChecked={handleMFAToggle}
                text={TOGGLE_TEXT}
              />
          </ToggleBtnWrapper>
          </ToggleBtnArea>
        </>
      }
      // rightActions={[
      //   {
      //     type: !isCopied ? 'primary' : 'secondary',
      //     text: !isCopied ? REFERRAL_BTN_TEXT : REFERRAL_COPIED_TEXT,
      //     onClick: handleClickCopyCode,
      //     isDisabled: isCopied,
      //   },
      // ]}
    />
  )
}

export default AdvancedSecurity
