import { Text } from 'venus/components'
import { theme } from 'venus/config'
import PlanDisplay from './PlanDisplay'
import { MHV_PLANS } from './Subscriptions'

const ChoosePlan = ({ onSelect, upgrade = 0 }) => {
  return (
    <>
      <Text
        format='body'
        value='As a Premium Vault user, you have unlimited document storage, reminders and a personal vault.'
      />
      <br />
      <Text
        format='body'
        value='Thinking of selling? As a premium vault user, you can set a dream price on your home.'
      />

      <br />
      <Text
        format='body'
        style={{ marginBottom: 24 }}
        value='Choose the number of premium vault plans here.'
      />
      {MHV_PLANS.map((plan, index) => (
        <PlanDisplay
          key={plan.name}
          display={plan.number > upgrade}
          index={index}
          {...plan}
          onSelect={() => onSelect(index)}
        />
      ))}
    </>
  )
}

export default ChoosePlan
