import React from 'react'
import { useState } from 'react'
import { Input, AlertBox } from 'venus/components'
import { Text } from 'venus/components'
import { Button } from 'venus/components'
import { SignUpSteps } from 'venus/layout/SignUp/Constants'
import { Container } from 'venus/layout/SignUp/Referral/Referral.styles'
import { SKIP_REFERRAL_CODE_MSG } from 'venus/layout/SignUp/Constants'
import { InputArea } from 'venus/layout/SignUp/Referral/Referral.styles'
import { axiosClient } from 'venus/api'
import { useAppDispatch } from 'venus/redux/hooks'
import { resetError } from 'venus/redux/features/user'

const EnterReferralCode = ({
  setStep,
  setUser,
}: {
  setStep: Function
  setUser: Function
}) => {
  const dispatch = useAppDispatch()
  const [referralCode, setReferralCode] = useState('')
  const [tryTimes, setTryTimes] = useState(0)
  const [msg, setMsg] = useState('')

  const handleOnChange = (value: string) => {
    setReferralCode(value.toUpperCase())
  }

  const handlPressAlertOK = () => {
    dispatch(resetError())
    setMsg('')
    if (tryTimes === 3) {
      setStep(SignUpSteps.ENTER_MOBILE)
    }
  }

  const handleOnClick = async () => {
    try {
      await axiosClient.get(`/auth/validate-referral/${referralCode}`)
      setUser((user) => {
        return {
          ...user,
          referral: referralCode,
        }
      })
      setStep(SignUpSteps.ENTER_MOBILE)
    } catch (error) {
      setMsg(tryTimes === 2 ? 'No valid referral code.' : 'Wrong code.')
      setReferralCode('')
    }
    setTryTimes(tryTimes + 1)
  }

  return (
    <Container>
      <InputArea>
        <Text format={'body'} value={SKIP_REFERRAL_CODE_MSG} />
        <Input
          value={referralCode}
          onChange={handleOnChange}
          maxLength={6}
          style={{ margin: '18px 0' }}
        />
      </InputArea>
      <AlertBox
        cancel={handlPressAlertOK}
        title='Enter Referral Code'
        message={msg}
        btnGroup={[{ text: 'OK', onClick: handlPressAlertOK, type: 'error' }]}
        showAlert={!!msg}
      />
      <Button
        type={'primary'}
        text={'Next'}
        isDisabled={!referralCode}
        onClick={handleOnClick}
      />
    </Container>
  )
}

export default EnterReferralCode
