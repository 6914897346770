import { ReactNode } from 'react'
import ReactModal from 'react-modal'
import {
  Button,
  ButtonWrapper,
  ModalBodyWrapper,
  ModalContents,
  ModalHeader,
  ModalTitle,
} from './ActionModal.styles'
import { ReactComponent as CrossIcon } from 'venus/assets/img/icons/Cross.svg'
import { Text } from '../Text'
import { theme } from 'venus/config'

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '9px',
    border: 0,
    padding: 0,
    overflow: '',
  },
}

ReactModal.setAppElement('#root')

interface IModal {
  width?: number
  height?: number
  isOpen: boolean
  close?: () => void
  closeModal?: () => void
  style?: any
  headerStyle?: any
  children?: ReactNode
  title?: string
  onClose?: () => void
  titleColor?: string
}

export const ActionModal = ({
  width,
  height,
  isOpen,
  close,
  style = {},
  headerStyle = {},
  title,
  onClose,
  titleColor = theme.colors.Black,
  children,
}: IModal) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={close}
    style={{
      overlay: { ...modalStyles.overlay, ...style?.overlay },
      content: { ...modalStyles.content, ...style?.content },
    }}
  >
    <ModalBodyWrapper width={width} height={height}>
      <ModalContents>
        <ModalHeader style={{ ...headerStyle }}>
          <ModalTitle>
            <Text format='h1' value={title} color={titleColor} />
          </ModalTitle>
          <ButtonWrapper>
            <Button onClick={onClose}>
              <CrossIcon />
            </Button>
          </ButtonWrapper>
        </ModalHeader>
        {children}
      </ModalContents>
    </ModalBodyWrapper>
  </ReactModal>
)
