import _ from 'lodash'

export const SpaceTypes = {
  ENTRANCE_HALL: 'Entrance Hall',
  LIVING: 'Living Room',
  KITCHEN: 'Kitchen',
  BATH: 'Bathroom',
  BED: 'Bedroom',
  STUDY: 'Study Room',
  LAUNDRY: 'Laundry Room',
  GARAGE: 'Garage',
  DINING: 'Dining Room',
  MEDIA: 'Media Room',
  ENTERTAINMENT: 'Entertainment Room',
  GARDEN: 'Garden',
  POOL: 'Pool',
  BALCONY: 'Balcony',
  TERRACE: 'Terrace',
  SHED: 'Shed Room',
  WINE_CELLAR: 'Wine Cellar Room',
  BOAT_HOUSE: 'Boat House',
  OTHER: 'Other',
}

export const OnboardingSpaceTypes = {
  LIVING: 'Living Room',
  KITCHEN: 'Kitchen',
  LAUNDRY: 'Laundry Room',
  BED: 'Bedroom',
  BATH: 'Bathroom',
  STUDY: 'Study Room',
  BALCONY: 'Balcony',
  TERRACE: 'Terrace',
  GARDEN: 'Garden',
  POOL: 'Pool',
  GARAGE: 'Garage',
  OTHER: 'Other',
}

const generateDropdown = (types: any) =>
  _.sortBy(
    Object.entries(types).map(([key, value]) => ({
      label: value,
      value: key,
    })),
    (obj) => obj.label,
  )

export const SpaceDropdown = [
  { label: 'Balcony', value: 'BALCONY' },
  { label: 'Bathroom', value: 'BATH' },
  { label: 'Bedroom', value: 'BED' },
  { label: 'Boat House', value: 'BOAT_HOUSE' },
  { label: 'Dining Room', value: 'DINING' },
  { label: 'Entertainment Room', value: 'ENTERTAINMENT' },
  { label: 'Entrance Hall', value: 'ENTRANCE_HALL' },
  { label: 'Family Room', value: 'FAMILY' },
  { label: 'Garage', value: 'GARAGE' },
  { label: 'Garden', value: 'GARDEN' },
  { label: 'Kitchen', value: 'KITCHEN' },
  { label: 'Laundry Room', value: 'LAUNDRY' },
  { label: 'Living Room', value: 'LIVING' },
  { label: 'Media Room', value: 'MEDIA' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Pool', value: 'POOL' },
  { label: 'Shed Room', value: 'SHED' },
  { label: 'Study Room', value: 'STUDY' },
  { label: 'Terrace', value: 'TERRACE' },
  { label: 'Wine Cellar Room', value: 'WINE_CELLAR' },
]
export const OnboardingSpaceDropdown = [
  { label: 'Balcony', value: 'BALCONY' },
  { label: 'Bathroom', value: 'BATH' },
  { label: 'Bedroom', value: 'BED' },
  { label: 'Garage', value: 'GARAGE' },
  { label: 'Garden', value: 'GARDEN' },
  { label: 'Kitchen', value: 'KITCHEN' },
  { label: 'Laundry Room', value: 'LAUNDRY' },
  { label: 'Living Room', value: 'LIVING' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Pool', value: 'POOL' },
  { label: 'Study Room', value: 'STUDY' },
  { label: 'Terrace', value: 'TERRACE' },
]
