import React from 'react'

import styled from 'styled-components'
import { Text } from '../Text'
import RadioButton from './RadioButton'

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const RadioSelect = ({
  label,
  selected,
  onSelect,
  style = {},
}: {
  label: string
  selected: boolean
  onSelect: (label: string) => void
  style?: any
}) => {
  const handlePress = () => onSelect(label)

  return (
    <Container style={style} onClick={handlePress}>
      <RadioButton selected={selected} />
      <Text format='h4' style={{ marginLeft: 8 }} value={label} />
    </Container>
  )
}

export default RadioSelect
