import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PSIPhotos from 'venus/common/PSIPhotos'
import { Loader, TabView } from 'venus/components'
import PSILayout from 'venus/layout/PSI/PSILayout'
import {
  getProperty,
  getPropertyWideSpace,
  updateProperty,
} from 'venus/redux/features/property/property'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { DocumentList } from 'venus/pages/Documents/List'
import { getPropertyName, getTabsForProperty } from 'venus/utils'
import Items from './Items'
import { Container } from './Property.styles'
import PropertyDetails from './PropertyDetails'
import Spaces from './Spaces'
import HistoryList from 'venus/pages/History'
import TradespeopleList from 'venus/pages/Tradespeople'
import Videos from './Videos'
import api from 'venus/api'
import { isTempUrl } from 'venus/utils/url'
import { getSharedProperties } from 'venus/redux/features/property/sharedProperties'
import useNavigateSearch from 'venus/hooks/useNavigateSearch'

const formatData = (data, images) => {
  if (data?.coverImage && isTempUrl(data?.coverImage)) {
    return { ...data, images: [...images, { url: data?.coverImage }] }
  }
  return data
}

const paramsToObject = (entries) => {
  const result = {}
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value
  }
  return result
}

const Property = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')
  const activeIndex = searchParams.get('activeIndex')

  const navigateSearch = useNavigateSearch()

  const { propertyId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { property, loading } = useAppSelector((state) => state.property)
  const { sharedScope } = useAppSelector((state) => state.sharedProperties)

  const share = useMemo(
    () => (sharedScope || []).find((scope) => scope.id === shareId),
    [sharedScope, shareId],
  )

  useEffect(() => {
    if (propertyId) {
      console.log('fetch', propertyId)
      dispatch(getProperty({ propertyId, shareId, dreamPropertyId }))
    }

    if (shareId) {
      dispatch(getSharedProperties())
    }
  }, [propertyId, shareId, dreamPropertyId])

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  const goBack = () => {
    if (!!dreamPropertyId) {
      navigateSearch('/', { activeIndex: 'Dream Properties' })
    } else if (isShared) {
      navigateSearch('/', { activeIndex: 'Shared Properties' })
    } else {
      navigate('/')
    }
  }

  const onTabChange = (index) => {
    console.log('searchParams', index, tabs[index], searchParams)
    const existing = paramsToObject(searchParams.entries())
    setSearchParams({ ...existing, activeIndex: tabs[index] })
  }

  const onUpdatePropertySetting = async (data) => {
    const response = await api.put(
      `/property/${property.id}`,
      formatData(data, property.images),
    )
    const updatedProperty = response.data

    dispatch(updateProperty({ ...updatedProperty, id: property.id }))
  }

  const isShared = !!shareId || !!dreamPropertyId

  const { isShareDocument, isShareHistory, sharedBy } = share || {}
  const { tabs, isDisplaySpaces, isDisplayItems, isDisplayDocument, isDisplayHistory } =
    getTabsForProperty(
      !!shareId,
      !!dreamPropertyId,
      isShareDocument,
      isShareHistory,
      property?.spaces,
      property?.items,
    )

  return (
    <PSILayout
      tag='Property'
      title={getPropertyName(property)}
      headerBackground={property?.coverImage}
      goBack={goBack}
      setting={{
        name: getPropertyName(property),
        coverImage: property?.coverImage,
        images: property?.images,
      }}
      onUpdateSetting={onUpdatePropertySetting}
      isShared={isShared}
      isDreamProperty={!!dreamPropertyId}
      sharedBy={sharedBy}
    >
      <Container>
        <TabView
          defaultActiveIndex={tabs.indexOf(activeIndex || 'Details')}
          tabs={tabs}
          style={{
            minHeight: 0,
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingBottom: 50,
            paddingTop: 26,
          }}
          onChange={onTabChange}
        >
          <PropertyDetails
            property={property}
            isShared={isShared}
            isDreamProperty={!!dreamPropertyId}
          />
          {isDisplaySpaces && (
            <Spaces
              id={propertyId}
              spaces={property?.spaces}
              setTabButtons={undefined}
              shareId={shareId}
              dreamPropertyId={dreamPropertyId}
            />
          )}
          {isDisplayItems && (
            <Items
              propertyId={propertyId}
              spaceId={property?.propertyWideSpaceId}
              items={property?.items || []}
              setTabButtons={undefined}
              shareId={shareId}
              dreamPropertyId={dreamPropertyId}
            />
          )}
          <PSIPhotos
            enableScanHome
            images={property?.images}
            updateUrl={`/property/${propertyId}`}
            update={updateProperty}
            psiId={{
              propertyId,
              spaceId: property?.propertyWideSpaceId,
              shareId,
              dreamPropertyId,
            }}
            propertyWideSpaceId={property?.propertyWideSpaceId}
            setTabButtons={undefined}
            isShared={isShared}
            isPersonal={false}
          />
          {/* @ts-ignore */}
          <Videos
            updateUrl={`/property/${propertyId}`}
            update={updateProperty}
            psiId={{ propertyId, spaceId: property?.propertyWideSpaceId }}
            propertyId={propertyId}
            dreamPropertyId={dreamPropertyId}
            shareId={shareId}
          />
          {isDisplayDocument && (
            <DocumentList
              type='property'
              propertyId={propertyId}
              propertyWideSpaceId={property?.propertyWideSpaceId}
              spaceId={property?.propertyWideSpaceId}
              onUpdate={updateProperty}
              shareId={shareId}
              dreamPropertyId={dreamPropertyId}
            />
          )}
          {isDisplayHistory && (
            <HistoryList
              propertyId={propertyId}
              spaceId={property?.propertyWideSpaceId}
              spaceNameTag={'Property Wide'}
            />
          )}
          <TradespeopleList propertyId={propertyId} />
        </TabView>
      </Container>
    </PSILayout>
  )
}

export default Property
