import { PillTag } from 'venus/components'
import { Container, Input } from './SpaceTag.styles'
import { useMemo, useRef, useState } from 'react'
import { SpaceDropdown } from 'venus/config'
import useSpaces from 'venus/hooks/useSpaces'
import AutoCompleteList from './AutoCompleteList'

const newSpaces = SpaceDropdown.map((s) => ({ ...s, label: `New ${s.label}` }))

const getAvailableName = (spaces, type) => {
  let index = spaces.length + 1

  if (!spaces.length) {
    return type
  }

  const getNextNumber = (spaces, index) => {
    const space = spaces.find((s) => s.name === `${type} ${index}`)
    return space ? getNextNumber(spaces, index + 1) : `${type} ${index}`
  }

  return getNextNumber(spaces, index)
}

const isExistingName = (name, spaces) => {
  return !!spaces.find((space) => space.name === name)
}

const getFormattedSpaces = (spaces) =>
  spaces.map((s) => ({
    id: s.id,
    value: s.type,
    label: s.name,
  }))

const SpaceTag = ({
  style = {},
  space,
  // isInputing,
  propertyId,
  onChange,
  // setIsInputing,
  // isOpen,
  // setIsOpen,
}) => {
  const input = useRef()
  const [newSpaceName, setNewSpaceName] = useState('')
  const { spaces } = useSpaces(propertyId)
  const [isOpen, setIsOpen] = useState(false)
  const [isInputing, setIsInputing] = useState<any>(false)

  const toggleDropdown = (event) => {
    event.stopPropagation()
    console.log('OPEN')
    setIsOpen((open) => !open)
  }

  const finishInputing = () => {
    if (!isExistingName(newSpaceName, spaces)) {
      onChange({ ...isInputing, label: newSpaceName, id: 'new' })
      setNewSpaceName('')
      setIsInputing(false)
    } else {
      setNewSpaceName('')
      setIsInputing(false)
      //   Alert.alert('Can not create space with same name.')
    }
  }

  const spaceOptions = useMemo(() => {
    if (!space) {
      return []
    }

    const newSpace = newSpaces.find((s) => s.value === space.value)
    const sameSpaces = space.id
      ? spaces.filter((s) => s.type === space.value).filter((s) => s.id !== space.id)
      : spaces.filter((s) => s.type === space.value)

    const remainingSpaces = spaces.filter((s) => s.type !== space.value)
    const remainingNewSpaces = newSpaces.filter((s) => s.value !== space?.value)

    return [
      space,
      ...getFormattedSpaces(sameSpaces),
      newSpace,
      ...getFormattedSpaces(remainingSpaces),
      ...remainingNewSpaces,
    ]
  }, [space, spaces])

  const handlePress = (selected) => {
    if (!selected.id) {
      setIsInputing(selected)

      const existingSpaces = spaces.filter((s) => s.type === selected.value)
      const newSpace = SpaceDropdown.find((s) => s.value === selected.value)
      const availableName = getAvailableName(existingSpaces, newSpace?.label)
      setNewSpaceName(availableName)
      //@ts-ignore
      setTimeout(() => input.current?.focus(), 0)
    } else {
      onChange(selected)
    }

    setIsOpen(false)
  }

  return (
    <>
      <Container style={style}>
        {!!isInputing ? (
          <Input
            ref={input}
            value={newSpaceName}
            onChange={(e) => setNewSpaceName(e.target.value)}
            onBlur={finishInputing}
          />
        ) : (
          <PillTag
            style={{ cursor: 'pointer' }}
            format='h2'
            label={space?.label}
            onClick={toggleDropdown}
          />
        )}

        {isOpen && (
          <AutoCompleteList
            value={space}
            onChange={onChange}
            options={spaceOptions}
            close={() => setIsOpen(false)}
            handleOptionSelect={handlePress}
            // onSearch={onSearch}
            // options={options.map(({ address, addressId, googlePlaceId }) => ({
            //   label: address,
            //   value: JSON.stringify({
            //     addressId: addressId,
            //     googlePlaceId,
            //   }),
            // }))}
          />
        )}

        {/* {isOpen && (
          <AutoCompleteList
            style={{
              shadowOffset: { x: 0, y: 4 },
              // position: 'absolute',
              // top: 16,
              // left: 80,
              // right: 80,
              marginTop: 20,
              backgroundColor: 'rgba(255,255,255,0.8)',
            }}
          >
            <FlatList
              nestedScrollEnabled
              keyExtractor={(item) => item.label || item.id}
              data={spaceOptions}
              renderItem={renderItem}
            />
          </AutoCompleteList>
        )} */}
      </Container>
    </>
  )
}

export default SpaceTag
