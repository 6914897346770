import styled from 'styled-components'
import { theme } from 'venus/config'

export const CardViewContainer = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${theme.colors.LighterGrey};
  border-radius: 12px;
  background-color: ${theme.colors.White};
  margin-bottom: 10px;

  &:hover {
    background-color: ${theme.colors.LighterGrey};
    cursor: pointer;
  }
`

type CardBoxProps = {
  readonly flex: number
}

export const CardBox = styled.div<CardBoxProps>`
  flex: ${(props) => props.flex};
`

export const DateCard = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  margin: 37px 0 38px 41px;
`

export const NameCard = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
`

export const TagCard = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  flex-wrap: wrap;
`
