import styled, { keyframes } from "styled-components"

const animation = keyframes` 
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const LogoArea = styled.div`
  height: 150px;
  width: 150px;
  margin: 50px auto 0;
  animation-name: ${animation};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
`
