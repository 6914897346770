import styled from 'styled-components'
import { Avatar, Text } from 'venus/components'
import { theme } from 'venus/config'
import { formateDate } from 'venus/utils'
import {
  LeftSide,
  RightSide,
  SharedInfoArea,
  ShareScopeArea,
  TitleWrapper,
} from './Shared.styles'

const ListContainer = styled.div`
  min-height: 0;
  overflow: auto;
`

const getShareScope = (isShareWholeProperty, shareScope) => {
  if (isShareWholeProperty) {
    return [{ value: 'Entire Property', type: 'space' }]
  }

  const sharedSpaces =
    shareScope.map(({ spaceName }) => ({ value: spaceName, type: 'space' })) || []

  const sharedItems = shareScope
    .reduce(
      (acc, curr) => [...acc, ...(curr.items || []).map(({ itemName }) => itemName)],
      [],
    )
    .filter((itemName) => !!itemName)
    .map((value) => ({ value, type: 'item' }))

  const shares = [...sharedSpaces, ...sharedItems]
  if (shares.length > 2) {
    return [...shares.slice(0, 2), { value: '...', type: 'space' }]
  }
  return shares
}

const SharedList = ({ shareList, handlePress }) => {
  return (
    <ListContainer>
      {shareList.map(
        (
          {
            id,
            eventName,
            createdAt,
            contactId,
            isShareWholeProperty,
            shareScope,
            contact,
          },
          index,
        ) => {
          const avatar = contact?.profile?.avatar
          if (!shareScope.value) return null

          const shareScopeText = getShareScope(isShareWholeProperty, shareScope.value)
          return (
            <SharedInfoArea key={id} onClick={() => handlePress(index)}>
              <LeftSide>
                <Text value={eventName} color={theme.colors.Black} format='h4' />
                <ShareScopeArea>
                  {shareScopeText.map(
                    ({ value, type }: { value: string; type: string }, index: number) => (
                      <TitleWrapper
                        key={`Share_Scope_${index}_${id}`}
                        style={{
                          backgroundColor:
                            type === 'space'
                              ? theme.colors.Primary
                              : theme.colors.LightBlue,
                        }}
                      >
                        <Text
                          value={value}
                          fontSize={10}
                          format='h3'
                          color={
                            type === 'space' ? theme.colors.White : theme.colors.Primary
                          }
                          style={{
                            textTransform: 'capitalize',
                          }}
                        />
                      </TitleWrapper>
                    ),
                  )}
                </ShareScopeArea>
              </LeftSide>
              <RightSide>
                {contactId && (
                  <Avatar
                    avatar={avatar}
                    size={32}
                    style={{ marginRight: 12 }}
                    isBorder={false}
                  />
                )}
                <Text
                  value={formateDate(createdAt)}
                  color={theme.colors.Black}
                  format='h4'
                  style={{ marginTop: 2 }}
                />
              </RightSide>
            </SharedInfoArea>
          )
        },
      )}
    </ListContainer>
  )
}

export default SharedList
