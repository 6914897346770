import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { axiosClient } from 'venus/api'
import { setAuthToken } from 'venus/auth/auth'
import { AlertBox, Button, Input, LinkButton, Text } from 'venus/components'
import { BgWrapper } from 'venus/layout/MHV/MHV.styles'
// import Input from './Input'
import {
  Divider,
  DividerContent,
  ForgotPasswordBtn,
  LoginPanel,
  LogoWrapper,
  PasswordWrapper,
  SignUpSection,
  CustomPasswordInput,
} from './Login.styles'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'

import { ReactComponent as Logo } from 'venus/assets/img/icons/Logo.svg'
import { theme } from 'venus/config'
import _ from 'lodash'
import useNavigateSearch from 'venus/hooks/useNavigateSearch'

const Login = () => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const navigateSearch =useNavigateSearch()
  const [error, setError] = useState('')

  const handleGoogleCode = async (codeResponse: TokenResponse) => {
    const { access_token } = codeResponse || {}
    try {
      const res = await axiosClient.post('/auth/oauth', {
        accessToken: access_token,
        oAuthType: 'GOOGLE',
      })

      if (res.data?.accessToken) {
        setAuthToken(res.data?.accessToken)
        return navigate('/', { replace: true })
      }
      navigate('/sign-up', {
        replace: true,
        state: res.data,
      })
    } catch (error) {
      if (error?.response?.data) {
        setError(error.response.data)
      }
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleCode,
    scope: 'email profile',
  })

  const openForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleSignIn = async (event) => {
    event?.preventDefault()

    if (!password) {
      return false
    }

    const type = userName.indexOf('@') >= 0 ? 'email' : 'mobile'
    const body =
      type === 'email' ? { email: userName, password } : { mobile: userName, password }

    try {
      const response = await axiosClient.post('/auth/sign-in', body, {
        withCredentials: true,
      })
      const { accessToken } = response.data
      const { otpToken } = response.data

      if (accessToken) {
        setAuthToken(accessToken)

        // if redirected from another page, redirect to that page
        const query = window.location.search
        if (query.startsWith('?redirect=')) {
          const url = query.replace('?redirect=', '')
          window.location.replace(url)
        }
        navigate('/', { replace: true })
      } else if (otpToken) {
        // Two Factor Authentication
        navigateSearch('/otp-input', { otpToken })
      }
    } catch (error) {
      setError(_.get(error, 'response.data.errors[0].msg', error?.response?.data))
    }
  }

  return (
    <BgWrapper
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPanel>
        <LogoWrapper>
          <Logo height={68} />
        </LogoWrapper>
        <form onSubmit={handleSignIn}>
          <Input
            style={{ marginBottom: 18 }}
            placeholder='Mobile / Email'
            value={userName}
            onChange={setUserName}
          />
          <PasswordWrapper>
            <CustomPasswordInput
              type='password'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ForgotPasswordBtn type='button' onClick={openForgotPassword}>
              <Text format='h4'>Forgot password?</Text>
            </ForgotPasswordBtn>
          </PasswordWrapper>

          <Button
            isSubmit
            type='primary'
            text='Sign In'
            onClick={()=>{}}
            isDisabled={!userName || !password}
          />
        </form>

        <Divider>
          <DividerContent>
            <Text format='body' color={theme.colors.Grey}>
              or
            </Text>
          </DividerContent>
        </Divider>

        <Button type='secondary' text='Sign In With Google' onClick={googleLogin} />
      </LoginPanel>
      <SignUpSection>
        <Text fontSize={16} fontWeight={400} format='h4' color={theme.colors.White}>
          Don't have an account?
        </Text>
        <LinkButton
          to='/sign-up'
          color={theme.colors.Secondary}
          format='h4'
          style={{ marginLeft: 8 }}
          textStyle={{
            fontSize: 16,
            fontWeight: 500,
          }}
          label='Sign up'
        />
      </SignUpSection>
      <AlertBox
        title='Login Error'
        message={(error || '').toString()}
        cancel={() => setError('')}
        btnGroup={[
          { type: 'primary', text: 'OK', onClick: () => setError(''), color: 'Primary' },
        ]}
        showAlert={!!error}
      />
    </BgWrapper>
  )
}

export default Login
