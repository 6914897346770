import styled from 'styled-components'
import { Loader, Text } from 'venus/components'
import { theme } from 'venus/config'
import { formatPercentage, formatPriceWithDollarSign } from 'venus/utils'

const Panel = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.LightBlue};
`
const Container = styled.div`
  padding: 24px;
  border: 1px solid ${(props) => props.theme.colors.LighterGrey};
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
`
const Section = styled.div`
  padding: 12px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Message = styled.div`
  grid-column: 1 / span 3;
  margin-top: 10px;
`

const AVM = ({ isLoading, avm, rentalAvm }: any) => {
  const { confidence, priceEstimate } = avm
  const {
    confidence: rentalConfidence,
    price,
    yield: yieldValue,
    yieldConfidence,
  } = rentalAvm || {}

  return (
    <>
      <Container>
        <Message style={{ textAlign: 'center', paddingBottom: 20 }}>
          <Text format='h1'>AVM Estimates</Text>
        </Message>
        <Panel>
          <Section>
            <Text format='h4' color={theme.colors.Black} style={{ marginBottom: 8 }}>
              Sale Price
            </Text>
            {isLoading ? (
              <Loader size={16} />
            ) : (
              <>
                <Text format='h1' color={theme.colors.Primary} style={{ marginBottom: 4 }}>
                  {priceEstimate
                    ? formatPriceWithDollarSign(Math.round(priceEstimate))
                    : 'n/a'}
                </Text>
                <Text
                  format='body'
                  fontSize={14}
                  fontWeight={500}
                  color={theme.colors.DarkGrey}
                >
                  {confidence > 0.5 ? 'High Confidence' : 'Low Confidence'}
                </Text>
              </>
            )}
          </Section>
        </Panel>
        <Panel>
          <Section>
            <Text format='h4' color={theme.colors.Black} style={{ marginBottom: 8 }}>
              Rental Price
            </Text>
            <Text format='h1' color={theme.colors.Primary} style={{ marginBottom: 4 }}>
              {priceEstimate ? `${formatPriceWithDollarSign(price)} pw` : 'n/a'}
            </Text>
            <Text
              format='body'
              fontSize={14}
              fontWeight={500}
              color={theme.colors.DarkGrey}
            >
              {rentalConfidence === 'high' ? 'High Confidence' : 'Low Confidence'}
            </Text>
          </Section>
        </Panel>
        <Panel>
          <Section>
            <Text format='h4' color={theme.colors.Black} style={{ marginBottom: 8 }}>
              Yield
            </Text>
            {isLoading ? (
              <Loader size={16} />
            ) : (
              <>
                <Text format='h1' color={theme.colors.Primary} style={{ marginBottom: 4 }}>
                  {yieldValue ? formatPercentage(yieldValue / 100, 2) : 'n/a'}
                </Text>
                <Text
                  format='body'
                  fontSize={14}
                  fontWeight={500}
                  color={theme.colors.DarkGrey}
                >
                  {yieldConfidence === 'high' ? 'High Confidence' : 'Low Confidence'}
                </Text>
              </>
            )}
          </Section>
        </Panel>
        <Message>
          <Text
            format='body'
            fontSize={12}
            lineHeight={1.5}
            color={theme.colors.DarkestGrey}
            style={{ marginTop: 8 }}
          >
            This information is supplied by MyHomeValt on behalf of National Property Group.
          </Text>
        </Message>
      </Container>
    </>
  )
}

export default AVM
