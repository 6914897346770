import { useEffect } from 'react'
import { getSubscription } from 'venus/redux/features/subscription/subscription'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { getNumberFromProductSku } from 'venus/utils'

const usePremium = (propertyId?: string) => {
  const { subscribed, productId, vaults, status } = useAppSelector(
    (state) => state.subscription,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getSubscription())
  }, [])

  const number = getNumberFromProductSku(productId)

  let type = subscribed.length < number ? 'enoughQuota' : 'notEnoughQuota'

  const subscriptionStatus = vaults[propertyId]

  return { status, propertyStatus: subscriptionStatus }
}

export default usePremium
