export const TextInputViewMargin = 24
export const TextInputViewHeight = 48
export const DropdownViewHeight = 50

export const screenWidth = window.innerWidth

export const roles = [
  { label: 'Home Owner', value: 'OWNER' },
  { label: 'Tradesperson', value: 'TRADESPEOPLE' },
  { label: 'Real Estate Agent', value: 'AGENT' },
  { label: 'Property Manager', value: 'MANAGER' },
  { label: 'Builder', value: 'BUILDER' },
  { label: 'Developer', value: 'DEVELOPER' },
  { label: 'Other', value: 'OTHER' },
]

export const tradesPeopleSubRolesArr = [
  { label: 'Plumber', value: 'PLUMBER' },
  { label: 'Electrician', value: 'ELECTRICIAN' },
  { label: 'Landscaper', value: 'LANDSCAPER' },
  { label: 'Painter', value: 'PAINTER' },
  { label: 'Other', value: 'OTHER' },
]

export const minPasswordLength = 10
export const pinLength = 4
export const mobileNumberLengthWithoutCountryCodeSpace = 11
export const mobileVerificationCodeLength = 4
export const referralCodeLength = 6
export const minimumMultiItemSelectDisplayNumber = 6

export const States = [
  { label: 'ACT', value: 'ACT' },
  { label: 'NSW', value: 'NSW' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' },
  { label: 'NT', value: 'NT' },
]

export const Poppins_Font = {
  Thin: 'Poppins_100Thin',
  Thin_Italic: 'Poppins_100Thin_Italic',
  ExtraLight: 'Poppins_200ExtraLight',
  ExtraLight_Italic: 'Poppins_200ExtraLight_Italic',
  Light: 'Poppins_300Light',
  Light_Italic: 'Poppins_300Light_Italic',
  Regular: 'Poppins_400Regular',
  Regular_Italic: 'Poppins_400Regular_Italic',
  Medium: 'Poppins_500Medium',
  Medium_Italic: 'Poppins_500Medium_Italic',
  SemiBold: 'Poppins_600SemiBold',
  SemiBold_Italic: 'Poppins_600SemiBold_Italic',
  Bold: 'Poppins_700Bold',
  Bold_Italic: 'Poppins_700Bold_Italic',
  ExtraBold: 'Poppins_800ExtraBold',
  ExtraBold_Italic: 'Poppins_800ExtraBold_Italic',
  Black: 'Poppins_900Black',
  Black_Italic: 'Poppins_900Black_Italic',
}
