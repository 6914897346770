import styled from 'styled-components'

export const BodyArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 62px;
  width: calc(100% - 48px);
`

export const FirstRow = styled.div`
  width: 100%;
`

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const AutoCompleteWrapper = styled.div`
  height: 407px;
`
