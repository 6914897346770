import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  margin: 20px;
  justify-content: center;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const LengendSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

interface LegendProps {
  readonly color: string
}

export const Legend = styled.div<LegendProps>`
  width: 22px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`

export const DisplayWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`
