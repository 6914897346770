import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { AlertBox, Drawer } from 'venus/components'
import HistoryForm from 'venus/pages/History/HistoryForm'
import { Share } from 'venus/pages/Share'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { resetError } from 'venus/redux/features/reminder'
import { Detail, Edit } from 'venus/pages/Reminder'
import { createHistory } from 'venus/redux/features/history/history'
import { HistoryBody } from 'venus/pages/Reminder/Reminder.styles'
import { HistoryHeader } from 'venus/pages/Reminder/Reminder.styles'
import { HistoryTitle } from 'venus/pages/Reminder/Reminder.styles'
import { Text } from 'venus/components'
import { HistoryContainer } from 'venus/pages/Reminder/Reminder.styles'
import { resetHistoryStore } from 'venus/redux/features/history/history'
import { CREATE_HISTORY_TITLE } from 'venus/pages/History/HistoryForm/Constants'

interface IShare {
  onClose: () => void
  isOpen: boolean
  setIsOpen: Function
  step: number
  setStep: (n: number) => void
}

export const Reminder = ({ onClose, isOpen, setIsOpen, step, setStep }: IShare) => {
  const dispatch = useAppDispatch()
  const { error, detail } = useAppSelector((state) => state.reminder)
  const { property } = useAppSelector((state) => state.property)
  const { history } = useAppSelector((state) => state.history)
  const reminderTitle = 'Reminder'
  const [title, setTitle] = useState(reminderTitle)
  const [isShareOpen, setIsShareOpen] = useState(step === 5)
  const { space, item, propertyId, spaceId, itemId, id } = detail
  const spaceIdProp =
    space?.name === 'Property Wide' ? property?.propertyWideSpaceId : spaceId
  const itemIdProp = !!item ? itemId : undefined

  const [showHistoryForm, setShowHistoryForm] = useState(false)

  // show history form on mark as complete otherwise make sure close it
  useEffect(() => {
    setShowHistoryForm(step === 4)
  }, [step])

  const handlPressAlertOK = () => {
    dispatch(resetError())
  }

  const handleCloseShare = () => {
    setIsShareOpen(false)
  }

  const handleOnSubmitHistory = async (data) => {
    if (!data) return

    const body: any = {
      propertyId,
      spaceId,
      eventName: data.eventName.trim(),
      eventDate: data.eventDate,
      itemCost: parseFloat(data.itemCost),
      laborCost: parseFloat(data.laborCost),
      notes: data.notes?.trim() || '',
      contactIds: data.contacts.map((contact) => contact.id),
      rating: {
        timeManagement: data.timeManagementRating,
        communication: data.communicationRating,
        pricing: data.pricingRating,
        quality: data.qualityRating,
      },
    }
    if (itemId) body.itemId = itemId
    await dispatch(createHistory(body))
    dispatch(resetHistoryStore())
    onClose()
  }

  const handleCloseHistory = () => {
    dispatch(resetHistoryStore())
    onClose()
  }

  return (
    <>
      {step === 2 && (
        <Detail
          setStep={setStep}
          setTitle={setTitle}
          onClose={onClose}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      {step === 3 && (
        <Edit
          setStep={setStep}
          setTitle={setTitle}
          onClose={onClose}
          isOpen={isOpen}
        />
      )}
      {step === 4 ? (
        <Drawer onClose={handleCloseHistory} isOpen={showHistoryForm}>
          <HistoryContainer>
            <HistoryHeader>
              <HistoryTitle>
                <Text format={'h1'} value={CREATE_HISTORY_TITLE}/>
              </HistoryTitle>
            </HistoryHeader>
            <HistoryBody>
              <HistoryForm history={history} onSubmit={handleOnSubmitHistory} />
            </HistoryBody>
          </HistoryContainer>
        </Drawer>
      ) : <></>}
      {step === 5 && (
        <Share
          onClose={handleCloseShare}
          isOpen={isShareOpen}
          propertyId={propertyId}
          spaceId={spaceIdProp}
          itemId={itemIdProp}
        />
      )}
      <AlertBox
        cancel={handlPressAlertOK}
        title={`${title} Error`}
        message={error}
        btnGroup={[
          {
            text: 'OK',
            onClick: handlPressAlertOK,
            color: 'Primary',
            type: 'error',
          },
        ]}
        showAlert={!!error}
      />
    </>
  )
}
