import styled from 'styled-components'

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  margin-top: 24px;
  width: calc(100% - 68px);
  position: absolute;
  bottom: 24px;
`

export const ContentWrapper = styled.div`
  width: 100%;
`

export const FieldWrapper = styled.div`
  width: 100%;
  margin-bottom: 18px;
`
