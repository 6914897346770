import React from 'react'
import { Text } from 'venus/components'
import { Button } from 'venus/components'
import { Modal } from 'venus/components'
import { ActionLayout } from 'venus/components'
import { ReactComponent as EditIcon } from 'venus/assets/img/icons/Edit.svg'
import { theme } from 'venus/config'

export const ScrollableModalSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenMin, setIsOpenMin] = React.useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const toggleOpenMin = () => {
    setIsOpenMin(!isOpenMin)
  }

  const sampleText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet mauris commodo quis. Non pulvinar neque laoreet suspendisse. Urna et pharetra pharetra massa massa. Viverra adipiscing at in tellus integer feugiat scelerisque varius. Sit amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Amet porttitor eget dolor morbi non. Mattis molestie a iaculis at erat. Consectetur purus ut faucibus pulvinar elementum. Bibendum ut tristique et egestas quis ipsum suspendisse. Morbi tristique senectus et netus et malesuada fames ac turpis. At imperdiet dui accumsan sit amet nulla. Tempus egestas sed sed risus. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum posuere. Malesuada fames ac turpis egestas sed tempus urna et pharetra. Lacus vel facilisis volutpat est velit egestas dui. Sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus. At urna condimentum mattis pellentesque.
    Ornare massa eget egestas purus viverra accumsan. A scelerisque purus semper eget duis at. Mi sit amet mauris commodo quis. Netus et malesuada fames ac turpis egestas integer eget. Dictum varius duis at consectetur lorem. Pretium vulputate sapien nec sagittis aliquam malesuada. Dui sapien eget mi proin sed libero enim. Ultrices vitae auctor eu augue. Et sollicitudin ac orci phasellus egestas tellus. Vitae congue mauris rhoncus aenean vel elit. Sed enim ut sem viverra. Elementum facilisis leo vel fringilla est ullamcorper eget nulla. Dui accumsan sit amet nulla facilisi morbi tempus iaculis urna. Dui faucibus in ornare quam viverra. Fusce ut placerat orci nulla.
    Facilisi etiam dignissim diam quis. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Et malesuada fames ac turpis. Quis vel eros donec ac odio tempor orci. Sagittis orci a scelerisque purus semper eget duis. Mattis aliquam faucibus purus in. Sociis natoque penatibus et magnis dis. Purus sit amet volutpat consequat mauris nunc congue. Semper viverra nam libero justo laoreet. Imperdiet proin fermentum leo vel orci porta non pulvinar neque. Aenean et tortor at risus viverra adipiscing at. Porta lorem mollis aliquam ut porttitor. Viverra suspendisse potenti nullam ac. Convallis posuere morbi leo urna molestie at elementum eu. Facilisis sed odio morbi quis commodo odio. Tellus id interdum velit laoreet id donec. Donec ac odio tempor orci dapibus ultrices. Nunc scelerisque viverra mauris in aliquam sem. Sit amet dictum sit amet justo donec.
    Sed euismod nisi porta lorem mollis aliquam ut. Elementum curabitur vitae nunc sed. Nunc vel risus commodo viverra. Blandit cursus risus at ultrices mi tempus imperdiet. Habitant morbi tristique senectus et netus et. Facilisis magna etiam tempor orci eu. Auctor eu augue ut lectus arcu bibendum at varius vel. Etiam tempor orci eu lobortis elementum nibh. Nisi scelerisque eu ultrices vitae auctor eu augue ut. Ut placerat orci nulla pellentesque dignissim enim. Egestas purus viverra accumsan in.
    Diam sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Arcu bibendum at varius vel pharetra vel turpis nunc. Risus nullam eget felis eget nunc lobortis. Pretium fusce id velit ut tortor pretium. Donec ac odio tempor orci dapibus ultrices in. Suspendisse sed nisi lacus sed viverra tellus in hac. Non arcu risus quis varius quam quisque id diam vel. Auctor neque vitae tempus quam pellentesque nec. Rhoncus dolor purus non enim praesent elementum facilisis leo vel. Ut ornare lectus sit amet. Sagittis purus sit amet volutpat consequat. Nibh ipsum consequat nisl vel pretium lectus quam. Consequat id porta nibh venenatis cras sed felis eget velit. Erat imperdiet sed euismod nisi. Ornare quam viverra orci sagittis eu. Commodo nulla facilisi nullam vehicula ipsum a arcu. Non blandit massa enim nec. Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique.
    Suspendisse ultrices gravida dictum fusce ut placerat orci nulla pellentesque. Arcu dictum varius duis at consectetur. Ut eu sem integer vitae justo eget magna fermentum. Tempor orci eu lobortis elementum nibh tellus molestie. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Sed id semper risus in. Rhoncus aenean vel elit scelerisque mauris pellentesque. Donec ac odio tempor orci. Amet volutpat consequat mauris nunc congue nisi vitae suscipit tellus. Erat imperdiet sed euismod nisi porta lorem mollis aliquam. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. Euismod lacinia at quis risus. Scelerisque fermentum dui faucibus in ornare quam. Tincidunt lobortis feugiat vivamus at augue eget arcu. Integer enim neque volutpat ac tincidunt. Odio aenean sed adipiscing diam donec adipiscing tristique risus. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum. Tristique nulla aliquet enim tortor at auctor urna nunc. Et netus et malesuada fames ac turpis egestas sed tempus. Suscipit adipiscing bibendum est ultricies integer.
    Pharetra pharetra massa massa ultricies mi quis hendrerit dolor magna. Vitae auctor eu augue ut lectus. Pharetra diam sit amet nisl suscipit adipiscing. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et. Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim. Netus et malesuada fames ac turpis. In est ante in nibh mauris cursus mattis. Faucibus nisl tincidunt eget nullam non. Posuere urna nec tincidunt praesent semper feugiat. Ut etiam sit amet nisl purus in mollis nunc sed. Fermentum leo vel orci porta non pulvinar neque laoreet suspendisse. Congue nisi vitae suscipit tellus.
    Lorem donec massa sapien faucibus. Volutpat commodo sed egestas egestas. Diam in arcu cursus euismod. Lacus viverra vitae congue eu consequat ac felis donec et. At urna condimentum mattis pellentesque id nibh tortor id aliquet. Leo a diam sollicitudin tempor id eu. Ac turpis egestas maecenas pharetra convallis posuere morbi leo urna. Amet porttitor eget dolor morbi non arcu risus quis varius. Augue eget arcu dictum varius. Purus semper eget duis at tellus at urna condimentum mattis. Purus in massa tempor nec feugiat nisl. Etiam tempor orci eu lobortis elementum.`

  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Button onClick={toggleOpen} text="Open Modal"/>
      <p/>
      <Button onClick={toggleOpenMin} text="Open Minimum Modal"/>

      {/* Maximum modal */}
      <Modal isOpen={isOpen} onClose={toggleOpen}>
        <ActionLayout
          children={<Text format={'body'} value={sampleText}/>}
          rightActions={[
            {
              type: 'icon_button_tertiary',
              text: 'Edit',
              onClick: () => {console.log('edit')},
              iconLeft: <EditIcon style={{ marginRight: '9px', color: theme.colors.Primary }}/>
            }
          ]}/>
      </Modal>
    </>
  )
}
