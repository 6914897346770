import styled from 'styled-components'

export const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ModalController = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 0;
  background: none;
  padding: 0;
`

type ModalBodyWrapperProps = {
  readonly width?: number
  readonly height?: number
}
export const ModalBodyWrapper = styled.div<ModalBodyWrapperProps>`
  position: relative;
  width: ${(props) => props.width || 480}px;
  min-height: ${(props) => props.height || 480}px;
  max-height: ${(props) => props.height || 680}px;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
`

export const ModalContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 32px;
  width: 24px;
  z-index: 2;
`

export const ModalTitle = styled(ButtonWrapper)`
  flex: 1;
  margin: 24px 0;
`

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 34px;
`

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  background: none;
  padding: 0;
  height: 24px;
`

// export const CloseIconWrapper = styled.button`
//   border: 0;
//   outline: none;
//   background: none;
//   width: 24px;
//   height: 24px;
//   margin: 24px 32px;
// `

export const ButtonPlaceHolder = styled.div`
  height: 24px;
`
