import styled from 'styled-components'

export const ListContainer = styled.div``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const IconArea = styled.div`
  position: absolute;
  top: 22px;
  right: 44px;
  &:hover {
    button {
      svg {
        color: white;
      }
    }
  }
`
