import { Text } from 'venus/components'
import { AssignSpaceImagesSample } from 'venus/pages/UI/AssignSpaceImagesSample'
import { AlertBoxSample } from './AlertBoxSample'
import { AutoCompleteSample } from './AutoCompleteSample'
import { ButtonSample } from './ButtonSample'
import { DropdownSample } from './DropdownSample'
import { FixedHeightRowSample } from './FixedHeightRowSample'
import { ImageSample } from './ImageSample'
import { InputSample } from './InputSample'
import { LinkButtonSample } from './LinkButtonSample'
import { NotificationSample } from './NotificationSample'
import { PillSample } from './PillSample'
import { PriceInputSample } from './PriceInputSample'
import { TabViewSample } from './TabViewSample'
import { DatePickerSample } from './DatePickerSample'
import { TextSample } from './TextSample'
import { IconButtonSample } from './IconButtonSample'
import { MultiItemSelectSample } from './MultiItemSelectSample'
import { StepButtonSample } from './StepButtonSample'
import { PageWrapper, DemoArea } from './UI.styles'
import { AvatarSample } from 'venus/pages/UI/AvatarSample'
import { MenuTextSample } from 'venus/pages/UI/MenuTextSample'
import { PlaceholderSample } from 'venus/pages/UI/PlaceholderSample'
import { EmptyDataSample } from 'venus/pages/UI/EmptyDataSample'
import { HistoryListViewSample } from 'venus/pages/UI/HistoryListViewSample'
import { FileUploaderSample } from 'venus/pages/UI/FileUploaderSample'
import { TextAreaSample } from 'venus/pages/UI/TextAreaSample'
import { ScrollableModalSample } from 'venus/pages/UI/ScrollableModalSample'
import { MultiSpaceItemSelectSample } from 'venus/pages/UI/MultiSpaceItemSelectSample'
import { InputMultipleLineSample } from 'venus/pages/UI/InputMultipleLineSample'
import { DrawerSample } from './DrawerSample'
import { ContactDrawViewSample } from './ContactDrawViewSample'
import { PropertyCardViewSample } from './PropertyCardViewSample'
import { RatingSample } from 'venus/pages/UI/RatingSample'

export const UI = () => {
  const ui = [
    {
      component: TextSample,
      label: 'Text',
    },
    {
      component: ButtonSample,
      label: 'Button',
    },
    {
      component: LinkButtonSample,
      label: 'Link Button',
    },
    {
      component: InputSample,
      label: 'Input',
    },
    {
      component: InputMultipleLineSample,
      label: 'Input Multiple Line',
    },
    {
      component: PriceInputSample,
      label: 'Price Input',
    },
    {
      component: FixedHeightRowSample,
      label: 'Fixed Height Row',
    },
    {
      component: AlertBoxSample,
      label: 'Alert Box',
    },
    {
      component: NotificationSample,
      label: 'Notification',
    },
    {
      component: TabViewSample,
      label: 'Tab View',
    },
    {
      component: PillSample,
      label: 'Pill',
    },
    {
      component: DropdownSample,
      label: 'Dropdown',
    },
    {
      component: RatingSample,
      label: 'Ratings',
    },
    {
      component: ImageSample,
      label: 'Image',
    },
    {
      component: AutoCompleteSample,
      label: 'AutoComplete',
    },
    {
      component: DatePickerSample,
      label: 'Date Picker',
    },
    {
      component: IconButtonSample,
      label: 'Icon Button',
    },
    {
      component: MultiItemSelectSample,
      label: 'Multi-Item Select',
    },
    {
      component: StepButtonSample,
      label: 'Step Button',
    },
    { component: AvatarSample, label: 'Avatar' },
    { component: MenuTextSample, label: 'Menu Text' },
    { component: PlaceholderSample, label: 'Placeholder' },
    { component: EmptyDataSample, label: 'EmptyData View' },
    {
      component: HistoryListViewSample,
      label: 'History Card View',
    },
    {
      component: FileUploaderSample,
      label: 'File Uploader',
    },
    {
      component: TextAreaSample,
      label: 'Text Area',
    },
    {
      component: ScrollableModalSample,
      label: 'Scrollable Modal',
    },
    {
      component: MultiSpaceItemSelectSample,
      label: 'Multi-Space/Item Select',
    },
    { component: DrawerSample, label: 'Drawer' },
    { component: ContactDrawViewSample, label: 'Contact Draw View' },
    { component: PropertyCardViewSample, label: 'Property Card View' },
    { component: AssignSpaceImagesSample, label: 'Assign Space Images'},
  ]

  return (
    <PageWrapper>
      <Text
        value='UI Playground'
        format='h1'
        style={{ margin: 18, alignSelf: 'center', fontSize: 32 }}
      />
      <DemoArea>
        {ui.map(({ component, label }, index) => (
          <div
            key={`ui_demo_${index}`}
            style={{
              marginBottom: 24,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {component({ index: index + 1, title: label })}
          </div>
        ))}
      </DemoArea>
    </PageWrapper>
  )
}
