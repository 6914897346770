import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { saveSettings, createShareLink, getShareList } from 'venus/redux/features/share'
// import MHVPremium from 'mhv/scenes/Transfer/MHVPremium'
// import useShowPremium from 'mhv/hooks/useShowPremium'
// import { FREE } from 'venus/config'
import { ToggleButton, Input, InputMultipleLine, ActionLayout } from 'venus/components'
import { ToggleBtnArea } from './Create.styles'

interface ICreate {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  eventName: string
  setEventName: (s: string) => void
  notes: string
  setNotes: (s: string) => void
  isSharedAnonymously: boolean
  setIsSharedAnonymously: (b: boolean) => void
  isGenerateQRCode: boolean
  setIsGenerateQRCode: (b: boolean) => void
}

const Create = ({
  setStep,
  setTitle,
  eventName,
  setEventName,
  notes,
  setNotes,
  isSharedAnonymously,
  setIsSharedAnonymously,
  isGenerateQRCode,
  setIsGenerateQRCode,
}: ICreate) => {
  const dispatch = useAppDispatch()
  // const [showAlert, setShowAlert] = useState(false)
  const {
    propertyId,
    shareScope,
    isShareWholeProperty,
    isShareDocument,
    isShareHistory,
    loading,
  } = useAppSelector((state) => state.share)
  // const { vaults, subscribed, unsubscribed } = useAppSelector(
  //   (state) => state.subscription
  // )
  // const subscriptionStatus = vaults[propertyId]
  // const { onViewPlan, onCancel } = useShowPremium(subscriptionStatus, navigation)
  // console.log('subscriptionStatus::', subscriptionStatus)

  // const handleViewPlan = () => {
  //   setShowAlert(false)
  //   onViewPlan()
  // }

  // const handleOnCancel = () => {
  //   setShowAlert(false)
  //   onCancel()
  // }

  const saveToContext = (shareLink: string) => {
    dispatch(
      saveSettings({
        eventName,
        notes,
        isSharedAnonymously,
        isGenerateQRCode,
        shareLink,
      }),
    )
  }

  const handlePressShareViaLink = async () => {
    // if (!subscriptionStatus || subscriptionStatus === FREE) {
    //   setShowAlert(true)
    // } else {
    const link = await dispatch(
      createShareLink({
        propertyId,
        eventName,
        shareScope,
        isShareWholeProperty,
        isShareDocument,
        isShareHistory,
        notes,
        isSharedAnonymously,
      }),
    )
    await dispatch(getShareList(propertyId))
    saveToContext(link.payload)
    setStep(3)
    setTitle('Share via URL')
    // }
  }

  const handlePressShareToContacts = () => {
    saveToContext('')
    setStep(4)
    setTitle('Share To')
  }

  return (
    <ActionLayout
      rightActions={[
        {
          type: 'secondary',
          isDisabled: eventName.trim() === '' || loading,
          text: 'Share via Link',
          onClick: handlePressShareViaLink,
          isLoading: loading,
        },
        {
          type: 'secondary',
          isDisabled: eventName.trim() === '' || loading,
          text: 'Share to Contacts',
          onClick: handlePressShareToContacts,
        },
      ]}
    >
      <>
        <Input
          label='Event Name'
          style={{ marginTop: 0 }}
          value={eventName}
          onChange={setEventName}
        />
        <InputMultipleLine
          label='Notes'
          style={{ marginTop: 12 }}
          value={notes}
          onChange={setNotes}
        />
        <ToggleBtnArea>
          <ToggleButton
            checked={isSharedAnonymously}
            setChecked={setIsSharedAnonymously}
            text='Share Anonymously'
          />
          <ToggleButton
            checked={isGenerateQRCode}
            setChecked={setIsGenerateQRCode}
            text='Generate QR Code'
          />
        </ToggleBtnArea>
        {/* <MHVPremium
        isOpen={showAlert}
        onViewPlan={handleViewPlan}
        onCancel={handleOnCancel}
      /> */}
      </>
    </ActionLayout>
  )
}

export default Create
