import React, { useState } from 'react'
import { Text, Row, Rating } from 'venus/components'

export const RatingSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [rating, setRating] = useState(0)

  const handleRating = (rate: number) => {
    setRating(rate)
  }

  return (
    <div style={{ width: 500 }}>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: 'left' }}
      />
      <Row>
        <Rating rating={1} readonly/>
      </Row>
      <Row>
        <Rating rating={2} readonly/>
      </Row>
      <Row>
        <Rating rating={3} readonly/>
      </Row>
      <Row>
        <Rating rating={4} readonly/>
      </Row>
      <Row>
        <Rating rating={5} readonly/>
      </Row>
      <Row style={{ marginTop: '18px' }}>
        <Rating
          label={'Time Management'}
          rating={rating}
          onClick={handleRating}
        />
      </Row>
      <Row style={{ marginTop: '18px' }}>
        <Rating
          label={'Time Management (with error)'}
          rating={rating}
          onClick={handleRating}
          isError
        />
      </Row>
      <Row style={{ marginTop: '18px' }}>
        <Rating
          label={'Pricing (No hover sample)'}
          rating={rating}
          onClick={handleRating}
          allowHover={false}
        />
      </Row>
    </div>
  )
}
