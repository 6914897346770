import React from 'react'
import { Container } from 'venus/components/ActionLayout/ActionLayout.styles'
import { ReactElement, ReactNode } from 'react'
import { ActionFooter } from 'venus/components/ActionLayout/ActionLayout.styles'
import { ActionBody } from 'venus/components/ActionLayout/ActionLayout.styles'
import { Button } from 'venus/components'
import { Groups } from 'venus/components/ActionLayout/ActionLayout.styles'

const ActionLayout = ({
  children,
  leftActions = [],
  rightActions = [],
  margin = 34,
  overFlowY = 'auto',
  style = {},
}: {
  children: ReactElement | ReactNode
  leftActions?: any[]
  rightActions?: any[]
  margin?: number
  overFlowY?: string
  style?: any
}) => {
  return (
    <Container>
      <ActionBody style={style} margin={margin} overFlowY={overFlowY}>
        {children}
      </ActionBody>
      {leftActions.length + rightActions.length > 0 && (
        <ActionFooter margin={margin}>
          <Groups>
            {leftActions?.map((action) => (
              <Button
                key={action.text}
                {...action}
                style={{ marginRight: '10px', width: 'fit-content' }}
              />
            ))}
          </Groups>
          <Groups>
            {rightActions?.map((action) => (
              <Button
                key={action.text}
                {...action}
                style={{ marginLeft: '10px', width: 'fit-content' }}
              />
            ))}
          </Groups>
        </ActionFooter>
      )}
    </Container>
  )
}

export default ActionLayout
