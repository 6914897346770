import styled from 'styled-components'
import { theme } from 'venus/config'

export const BtnArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 74px;
  padding: 0px 24px;
  border-top: 1px solid ${(props) => props.theme.colors.LighterGrey};
`

export const ScrollableList = styled.div`
  width: 100%;
`

export const FileListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  max-height: 410px;
  overflow-y: auto;
`

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.LightBlue};
  margin: 6px;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
`

export const WebViewWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: flex-start;
  align-items: center;
  border-width: 1px;
  border-radius: 6px;
  border-color: ${theme.colors.LightBlue};
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
`

export const WebViewCloseIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
`

export const PreviewArea = styled.iframe`
  width: 100%;
  height: 220px;
  border: 1px solid;
  overflow-y: scroll;
`

export const PreviewPlaceHolder = styled.div`
  width: 100%;
  height: 220px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FileSelectDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
  input {
    height: 38px;
    width: 165px;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 220px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const EditLeftPart = styled.div`
  width: 160px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EditRightPart = styled.div`
  padding-left: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-self: flex-start;
`

export const EditContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 0;
`
export const EditWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 12px 24px;
  overflow-y: auto;
`
