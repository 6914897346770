import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { theme, Max_Price } from 'venus/config'
import { TextArea } from 'venus/components/Textarea'
import PillButton from 'venus/components/Pill/PillButton'
import { SelectContact } from 'venus/pages/Contact/SelectContact'
import { getDate } from 'venus/utils'
import { getFullName } from 'venus/utils/fullName'
import { IHistoryFormData } from 'venus/pages/History/HistoryForm/types'
import { ReactComponent as DeleteIcon } from 'venus/assets/img/icons/Trash.svg'
import { ActionLayout, DatePicker, PillTag, PriceInput, Rating, Text, AlertBox, Input } from 'venus/components'
import { IHistory } from 'venus/redux/features/history/types'
import { TagsWrapper, BodyPanel, RowWrapper, CostWrapper } from 'venus/pages/History/HistoryForm/HistoryForm.styles'
import {
  REQUIRED_MSG,
  DELETE_HISTORY_CONFIRM_MSG,
  MAX_LENGTH_NOTE_MSG,
  MAX_LENGTH_EVENT_NAME_MSG,
  RATING_TITLE
} from 'venus/pages/History/HistoryForm/Constants'

const HistoryForm = ({
  history,
  onDelete,
  onSubmit,
  onCloseDrawer,
}: {
  history: IHistory
  onDelete?: () => void
  onSubmit: (data: any) => void
  onCloseDrawer?: any
}) => {
  const defaultValues = {
    contacts: history?.contacts || [],
    eventName: history?.eventName || '',
    eventDate: history?.eventDate || getDate(new Date().toISOString()),
    itemCost: history?.itemCost || 0,
    laborCost: history?.laborCost || 0,
    notes: history?.notes || '',
    timeManagementRating: _.get(history, ['rating', 'timeManagement'], null),
    communicationRating: _.get(history, ['rating', 'communication'], null),
    pricingRating: _.get(history, ['rating', 'pricing'], null),
    qualityRating: _.get(history, ['rating', 'quality'], null),
    tags: history?.tags || [],
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    setError,
  } = useForm<IHistoryFormData>({
    defaultValues,
  })

  const [selectedContact, setSelectedContact] = useState(
    _.get(defaultValues, ['contacts', '0'], '')
  )
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)

  const resetForm = () => {
    setSelectedContact(null)
    reset()
  }

  useEffect(() => {
    if (onCloseDrawer) onCloseDrawer.current = resetForm
  }, [])

  const handleCloseAlertBox = () => {
    setShowAlertBox(false)
  }

  const openContactSelectModal = () => {
    setIsContactModalOpen(true)
  }

  const closeContactSelectModal = () => {
    setIsContactModalOpen(false)
  }

  const onHandleContactSelect = (contact) => {
    setValue('contacts', [contact])
    setSelectedContact(contact)
    closeContactSelectModal()
  }

  const leftActions = !history.id
    ? []
    : [
      {
        type: 'icon_button_error',
        onClick: () => {
          setShowAlertBox(true)
        },
        text: 'Delete',
        iconLeft: (
          <DeleteIcon
            style={{ marginRight: '9px', color: theme.colors.ErrorState }}
          />
        ),
      },
    ]

  return (
    <>
      <AlertBox
        cancel={handleCloseAlertBox}
        title="Delete contact"
        message={DELETE_HISTORY_CONFIRM_MSG}
        btnGroup={[
          {
            text: 'Cancel',
            color: 'LightBlue',
            textColor: 'Primary',
            onClick: handleCloseAlertBox,
            type: 'secondary',
          },
          {
            text: 'Delete Event',
            onClick: onDelete,
            color: 'ErrorState',
            type: 'error',
          },
        ]}
        showAlert={showAlertBox}
      />
      <SelectContact
        isOpen={isContactModalOpen}
        onClose={closeContactSelectModal}
        handleSelect={onHandleContactSelect}
        title={selectedContact ? getFullName(selectedContact) : `Select Person`}
        onBack={closeContactSelectModal}
        selectedId={selectedContact?.id}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <ActionLayout
          margin={36}
          children={
            <BodyPanel>
              <>
                <RowWrapper>
                  <Text
                    format={'h2'}
                    value={'Location'}
                    style={{ textAlign: 'left', marginTop: '18px' }}
                  />
                </RowWrapper>
                <TagsWrapper>
                  {defaultValues.tags.map((tag, index) => {
                    if (index === 0) {
                      return (
                        <PillTag
                          key={index}
                          format="h4"
                          label={tag}
                          style={{ margin: '6px 0px' }}
                        />
                      )
                    }
                    return (
                      <PillTag
                        key={index}
                        format="h4"
                        label={tag}
                        background={theme.colors.LightBlue}
                        color={theme.colors.Black}
                        style={{ margin: '6px 6px' }}
                      />
                    )
                  })}
                </TagsWrapper>
                <Text
                  format={'h2'}
                  value={'Tradesperson'}
                  style={{ textAlign: 'left', marginBottom: '18px' }}
                />
                <TagsWrapper>
                  <Controller
                    name="contacts"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={() => {
                      return (
                        <RowWrapper>
                          <PillButton
                            label={!selectedContact?.id ? '+ New' : _.truncate(getFullName(selectedContact), { length: 80 })}
                            onClick={openContactSelectModal}
                          />
                          {!!errors?.contacts && (
                            <Text
                              format={'body'}
                              color={theme.colors.DangerRed}
                              value={REQUIRED_MSG}
                              style={{ marginTop: '6px' }}
                            />
                          )}
                        </RowWrapper>
                      )
                    }}
                  />
                </TagsWrapper>
                <Controller
                  name="eventName"
                  control={control}
                  rules={{
                    maxLength: 30,
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label="Title"
                        value={value}
                        onChange={(str) => {
                          if (!!str.length && !str.trim()) {
                            return setError('eventName', { types: { required: REQUIRED_MSG } }, { shouldFocus: true })
                          }
                          onChange(str)
                        }}
                        isError={!!errors?.eventName}
                        errorMsg={errors?.eventName?.type === 'maxLength' ? MAX_LENGTH_EVENT_NAME_MSG : REQUIRED_MSG}
                        style={{ marginBottom: '18px' }}
                      />
                    )
                  }}
                />
                <Controller
                  name="notes"
                  control={control}
                  rules={{
                    maxLength: 1024,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextArea
                        rows={5}
                        cols={95}
                        label="Notes"
                        value={value}
                        onChange={onChange}
                        style={{ marginBottom: '18px' }}
                        isError={!!errors?.notes}
                        errorMsg={errors?.notes?.type === 'maxLength' && MAX_LENGTH_NOTE_MSG}
                      />
                    )
                  }}
                />
                <Controller
                  name="eventDate"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        label={'Date'}
                        onChange={onChange}
                        value={value}
                        disableFutureDate
                        isError={!!errors?.eventDate}
                        errorMsg={REQUIRED_MSG}
                        style={{ marginBottom: '18px' }}
                      />
                    )
                  }}
                />
                <CostWrapper>
                  <Controller
                    name="itemCost"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <PriceInput
                          label="Item Cost"
                          value={value}
                          onChange={onChange}
                          style={{ marginRight: '24px' }}
                          isError={value > Max_Price}
                          errorMsg={'Invalid value.'}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="laborCost"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <PriceInput
                          label="Labour Cost"
                          value={value}
                          onChange={onChange}
                          isError={value > Max_Price}
                          errorMsg={'Invalid value.'}
                        />
                      )
                    }}
                  />
                </CostWrapper>
                <RowWrapper>
                  <Text
                    format={'h3'}
                    value={RATING_TITLE}
                    fontWeight={600}
                    lineHeight={'21px'}
                    style={{ marginBottom: '18px' }}
                  />
                  <Controller
                    name="timeManagementRating"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Rating
                          label="Time Management"
                          rating={value}
                          onClick={(value) => {
                            onChange(value)
                          }}
                          isError={!!errors?.timeManagementRating}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="communicationRating"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Rating
                          label="Communication"
                          rating={value}
                          onClick={(value) => {
                            onChange(value)
                          }}
                          isError={!!errors?.communicationRating}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="pricingRating"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Rating
                          label="Pricing"
                          rating={value}
                          onClick={(value) => {
                            onChange(value)
                          }}
                          isError={!!errors?.pricingRating}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="qualityRating"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Rating
                          label="Quality"
                          rating={value}
                          onClick={onChange}
                          isError={!!errors?.qualityRating}
                        />
                      )
                    }}
                  />
                </RowWrapper>
              </>
            </BodyPanel>
          }
          rightActions={[
            {
              type: 'icon_button',
              isSubmit: true,
              onClick: handleSubmit,
              text: !history.id ? 'Create' : 'Update',
            },
          ]}
          leftActions={leftActions}
        />
      </form>
    </>
  )
}

export default HistoryForm
