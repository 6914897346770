import styled from 'styled-components'
import { theme } from 'venus/config'

type BtnWrapperProps = {
  readonly bgColor: string
  readonly bgColorOnHover: string
  readonly textColorOnHover: string
  readonly borderColor: string
  readonly borderColorOnHover: string
  readonly isDisabled: boolean
  readonly borderRadius?: number
  readonly noPadding?: boolean
}

export const BtnWrapper = styled.button<BtnWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.borderColor
      ? `1px solid ${props.borderColor}`
      : '1px solid transparent'};
  padding: ${(props) => (props.noPadding ? '0' : '0 16px')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => props.bgColor};
  border-radius: ${(props) => props.borderRadius}px;
  transition-duration: 0.3s;

  &:hover {
    background-color: ${(props) => props.bgColorOnHover};
    span,
    svg {
      color: ${(props) => props.textColorOnHover} !important;
    }
    border: 1px solid
      ${({ isDisabled, borderColorOnHover }) =>
        isDisabled ? 'none' : borderColorOnHover};
  }
`
