import styled from 'styled-components'

export const BtnArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  width: 100%;
  margin-top: 24px;
`

export const Wrapper = styled.div`
  width: calc(100% - 48px);
  height: 100%;
  padding: 24px;
`

export const BodyWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  height: 600px;
  padding-bottom: 200px;
`
