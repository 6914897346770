import { useState } from 'react'
import { Loader, PillTabs, TabView, Text } from 'venus/components'
import { theme } from 'venus/config'
import { Panel } from '../PropertyDetails.styles'
import NPD from './NPD'
import { Container, PillButton, PillContainer } from './PropertyStatistics.styles'

import { IStatistics, IStatisticsConfig } from './Statistics.t'
import StatisticsChart from './StatisticsChart'
import StatisticsLegend from './StatisticsLegend'
// import StatisticsLegend from './StatisticsLegend'
// import NPD from './NPD'
// import { View } from 'react-native'

const StatisticsConfig: IStatisticsConfig[] = [
  {
    title: 'Median Price',
    type: 'recentMedianSales',
    config: {
      bar: {
        x: 'monthLabel',
        y: 'suburbPrice',
      },
      line: {
        x: 'monthLabel',
        y: 'lgaPrice',
      },
    },
  },
  {
    title: 'Capital Growth',
    type: 'capitalGrowth',
    config: {
      bar: {
        x: 'yearLabel',
        y: 'suburbMedianPrice',
      },
      line: {
        x: 'yearLabel',
        y: 'lgaMedianPrice',
      },
    },
  },
]

const tabs = ['All', 'Houses', 'Units']
const types = ['A', 'H', 'U']

const PropertyStatistics = ({
  isDataLoading,
  statistics,

  onTypeChange,
}: {
  isDataLoading: boolean
  statistics: any

  onTypeChange: (type: string) => void
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const onChange = (index) => {
    onTypeChange(types[index])
  }
  if (statistics)
    return (
      <Panel style={{ marginTop: 20, marginBottom: 20 }}>
        <Container>
          <Text
            style={{ textAlign: 'center' }}
            format='h1'
            value='Suburb and Neighborhood Statistics'
          />
          {/* <PillTabs
            tabs={[
              { label: 'Median Price', key: 'price' },
              { label: 'Capital Growth', key: 'capital' },
            ]}
          ></PillTabs> */}
          <TabView center defaultActiveIndex={0} onChange={onChange} tabs={tabs}>
            <></>
            <></>
            <></>
          </TabView>
          <PillContainer>
            {StatisticsConfig.map((pill, index) => (
              <PillButton
                key={pill.title}
                isActive={activeIndex === index}
                onClick={() => setActiveIndex(index)}
              >
                <Text
                  format='h4'
                  color={activeIndex === index ? theme.colors.White : theme.colors.DarkGrey}
                >
                  {pill.title}
                </Text>
              </PillButton>
            ))}
          </PillContainer>
          <StatisticsChart
            data={statistics[StatisticsConfig[activeIndex].type]}
            config={StatisticsConfig[activeIndex]}
          />
          <StatisticsLegend data={statistics} />
        </Container>
      </Panel>
    )

  return (
    <Panel style={{ marginTop: 20 }}>
      <Container>
        <Text format='h1' value='Suburb and Neighborhood Statistics' />
        <div
          style={{
            display: 'flex',
            height: 150,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      </Container>
    </Panel>
  )
}

export default PropertyStatistics
