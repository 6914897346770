import styled from 'styled-components'
import { theme } from 'venus/config'

export const ShareScopeArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  flex-wrap: wrap;
  margin-bottom: 18px;
  margin-top: 6px;
`

export const TitleWrapper = styled.div`
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  border-radius: 50px;
  margin-top: 3px;
  margin-right: 6px;
`

export const BtnArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-end;
  padding-bottom: 36px;
`
