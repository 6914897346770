import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { BtnWrapper, LeftTextArea } from './StepList.styles'
import { ReactComponent as Tick } from 'venus/assets/img/icons/Tick.svg'

interface IStepList {
  height?: number
  text: string
  borderRadius?: number
  style?: any
  isDisabled?: boolean
  isCompleted: boolean
}

export const StepList = ({
  height = 45,
  borderRadius = 5,
  text,
  style = {},
  isDisabled = false,
  isCompleted,
}: IStepList) => {
  const color = isDisabled ? theme.colors.DarkestGrey : theme.colors.Primary
  const bgColor =
    !isDisabled && !isCompleted ? theme.colors.LightBlue : 'transparent'

  return (
    <BtnWrapper
      style={{
        backgroundColor: bgColor,
        height,
        borderRadius,
        ...style,
      }}
    >
      <LeftTextArea>
        <Text value={text} format='body' color={color} />
      </LeftTextArea>
      {isCompleted && (
        <Tick color={theme.colors.Black} style={{ marginRight: '12px' }} />
      )}
    </BtnWrapper>
  )
}
