import _ from 'lodash'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'venus/redux/hooks'
import { Text, TabView, Rating } from 'venus/components'
import HistoryForm from 'venus/pages/History/HistoryForm'
import HistoryFormReadonly from 'venus/pages/History/HistoryForm/HistoryFormReadonly'
import { IHistory } from 'venus/redux/features/history/types'
import { CREATE_HISTORY_TITLE } from 'venus/pages/History/HistoryForm/Constants'
import {
  ContentsHeader,
  ContentsBody,
  ContentTitle,
  RatingWrapper,
  RatingsContainer,
  Container
} from 'venus/pages/History/HistoryDetailsTabView/HistoryDetailsTabView.styles'

const HistoryDetailsTabView = ({
  onUpdate,
  onDelete,
  isEditMode,
  toggleEditMode,
  onCloseDrawer,
}: {
  onUpdate?: (data: IHistory) => void
  onDelete?: () => void
  isEditMode?: boolean
  toggleEditMode?: () => void
  onCloseDrawer?: any
}) => {
  const { history } = useAppSelector((state) => state.history)
  const [searchParams] = useSearchParams()
  const shareId = searchParams.get('shareId')
  const dreamPropertyId = searchParams.get('dreamPropertyId')
  const isShared = !!shareId || !!dreamPropertyId

  const handleOnSubmit = (formData) => {
    toggleEditMode()
    onUpdate(formData)
  }

  const rating = _.get(history, ['rating'], {
    timeManagement: '0',
    communication: '0',
    pricing: '0',
    quality: '0',
  })

  return (
    <Container>
      <ContentsHeader>
        <ContentTitle>
          {!history?.id ? (
            <Text format={'h1'} value={CREATE_HISTORY_TITLE}/>
          ) : (
            <Text format={'h1'} value={!isEditMode ? history?.eventName : 'Edit Event'}/>
          )}
        </ContentTitle>
      </ContentsHeader>
      <ContentsBody>
        {!isShared && !history?.id ? (
          // Create History
          <HistoryForm
            history={history}
            onSubmit={onUpdate}
            onCloseDrawer={onCloseDrawer}
          />
        ) : (
          // Edit History
          <TabView
            tabs={['Details', 'Ratings']}
            defaultActiveIndex={0}
            paddingBottom={0}
            headerStyle={isEditMode ? { display: 'none' } : { marginLeft: 36, marginBottom: 10 }}
            style={{ overflowY: 'auto' }}
          >
            {/* First Tab */}
            {!isEditMode ? (
              <HistoryFormReadonly
                toggleEditMode={toggleEditMode}
                isShared={isShared}
              />
            ) : (
              <HistoryForm
                history={history}
                onDelete={onDelete}
                onSubmit={handleOnSubmit}
              />
            )}
            {/* Second Tab */}
            <RatingsContainer>
              <RatingWrapper>
                <Text format={'h3'} value={'Time Management'}/>
                <Rating rating={rating?.timeManagement} readonly/>
              </RatingWrapper>
              <RatingWrapper>
                <Text format={'h3'} value={'Communication'}/>
                <Rating rating={rating?.communication} readonly/>
              </RatingWrapper>
              <RatingWrapper>
                <Text format={'h3'} value={'Pricing'}/>
                <Rating rating={rating?.pricing} readonly/>
              </RatingWrapper>
              <RatingWrapper>
                <Text format={'h3'} value={'Quality'}/>
                <Rating rating={rating?.quality} readonly/>
              </RatingWrapper>
            </RatingsContainer>
          </TabView>
        )}
      </ContentsBody>
    </Container>
  )
}

export default HistoryDetailsTabView
