import api from 'venus/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { IHistory } from 'venus/redux/features/history/types'

export const createHistory = createAsyncThunk(
  'history/create',
  async (fields: any, { rejectWithValue }) => {
    try {
      const response = await api.post('/history', fields)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  })

export const updateHistory = createAsyncThunk(
  'history/update',
  async (fields: any, { rejectWithValue }) => {
    try {
      const response = await api.put('/history', fields)
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  })

export const deleteHistory = createAsyncThunk(
  'history/delete',
  async ({
    historyId,
    propertyId,
    spaceId,
    itemId,
  }: {
    historyId: string
    propertyId: string;
    spaceId?: string;
    itemId?: string;
  }, { rejectWithValue }) => {
    try {
      const response = await api.delete('/history', {
        params: {
          propertyId,
          spaceId,
          itemId,
          historyId,
        }
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.data)
    }
  })

const initialState = {
  error: null,
  history: {},
  loading: false,
} as {
  error: object | null | undefined
  history: IHistory
  loading: boolean
}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    resetHistoryStore: () => initialState,
    setHistory: (state, action) => {
      state.history = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createHistory.fulfilled, (state, action) => {
      state.loading = false
      state.history = action.payload
    })
    builder.addCase(createHistory.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(updateHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateHistory.fulfilled, (state, action) => {
      state.loading = false
      state.history = action.payload
    })
    builder.addCase(updateHistory.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
    builder.addCase(deleteHistory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteHistory.fulfilled, (state, action) => {
      state.loading = false
      state.history = {} as IHistory
    })
    builder.addCase(deleteHistory.rejected, (state, action) => {
      state.loading = false
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.error = action.payload as object
      } else {
        state.error = { message: action.error.message }
      }
    })
  }
})

export const { resetHistoryStore, setHistory } = historySlice.actions

export default historySlice.reducer
