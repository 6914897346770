import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Loader } from 'venus/components'
import { getProfile } from 'venus/redux/features/user'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'

let user: {} | null = null
// { firstName: 'test' }

const Authenticated = ({ children }: { children: JSX.Element }) => {
  //   let auth = useAuth()
  let location = useLocation()
  const dispatch = useAppDispatch()

  const { loadingProfile, info } = useAppSelector((state) => state.user)
  useEffect(() => {
    dispatch(getProfile())
  }, [])

  if (!loadingProfile && !info) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  if (loadingProfile) {
    return (
      <div style={{ height: '100vh' }}>
        <Loader isFull />
      </div>
    )
  }

  return children
}

export default Authenticated
