import { Text, EmptyData } from 'venus/components'
import { TDetail } from 'venus/redux/features/reminder'
import { theme } from 'venus/config'
import {
  RowWrapper,
  RightLeftPartWrapper,
  MHVLabelView,
  SpaceLabelView,
  ItemLabelView,
  SecondRowLeftPartWrapper,
  EmptyWrapper,
  ListWrapper,
} from './SubList.styles'

interface ISubList {
  list: TDetail[]
  handlePress: Function
}

export const SubList = ({ list, handlePress }: ISubList) => {
  const handlePressList = (list: TDetail) => {
    console.log('list::', list)
    handlePress(list)
  }

  return (
    <EmptyWrapper>
      {list.length === 0 && (
        <EmptyData
          message={`To set a reminder go to one of your properties and click on the reminder icon in the top right corner.`}
        />
      )}
      {list.length > 0 && (
        <ListWrapper>
          {list.map((reminder, index) => (
            <RowWrapper
              key={`reminder_sublist_${index}`}
              onClick={() => handlePress(reminder)}
            >
              <SecondRowLeftPartWrapper>
                <Text
                  format='h3'
                  value={reminder.eventName}
                  color={theme.colors.Black}
                  style={{ alignSelf: 'flex-start' }}
                />
                {reminder.isMHVReminder ? (
                  <MHVLabelView>
                    <Text
                      format='h4'
                      value={'MHV Recommend'}
                      color={theme.colors.White}
                    />
                  </MHVLabelView>
                ) : (
                  <SpaceLabelView>
                    <Text
                      format='h4'
                      value={reminder.space.name}
                      color={theme.colors.White}
                    />
                  </SpaceLabelView>
                )}
                {!!reminder.item && (
                  <ItemLabelView>
                    <Text
                      format='body'
                      value={reminder.item.name}
                      color={theme.colors.Primary}
                    />
                  </ItemLabelView>
                )}
              </SecondRowLeftPartWrapper>
              <RightLeftPartWrapper>
                <Text
                  format='h4'
                  value={reminder.dueDateTime}
                  color={theme.colors.DarkestGrey}
                />
              </RightLeftPartWrapper>
            </RowWrapper>
          ))}
        </ListWrapper>
      )}
    </EmptyWrapper>
  )
}
