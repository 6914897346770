import styled from 'styled-components'
import { theme } from 'venus/config'

export const TitleWrapper = styled.div`
  height: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  background: ${theme.colors.Primary};
  border-radius: 50px;
  margin-top: 3px;
  margin-right: 6px;
  margin-bottom: 4px;
`

export const ShareScopeArea = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-start;
  overflow: hidden;
`

export const BtnArea = styled.div`
  width: 100%;
  padding: 0 48px 24px 24px;
  position: absolute;
  bottom: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TitleTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 18px 0 0 0;
`

export const ValueTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 8px 0;
`

export const ContactWrapper = styled.div`
  align-self: flex-start;
  margin: 9px 9px 9px 24px;
`

export const ShareScopeAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 9px;
`

export const ShareScopeBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
