import styled from 'styled-components'
import { theme } from 'venus/config'

export const Container = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 24px 32px;
`

export const ToggleBtnArea = styled.div`
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
  margin-top: 12px;
`

export const ToggleBtnWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`
