import { Modal, QuickActions, ButtonGroup, IconButton } from 'venus/components'
import { modalStyles } from 'venus/components/Modal'
import { IButtonGroup } from 'venus/components/ButtonGroup'
import { ReactComponent as Close } from 'venus/assets/img/icons/Close.svg'
import { ReactNode, useState } from 'react'
import { getColorsFromUrl, isColorBackground } from 'venus/utils'
import { theme } from 'venus/config'

export const Image = ({
  image,
  alt,
  style,
  imageStyle,
  children,
  actions,
  enablePreview = true,
}: {
  image: string
  alt?: string
  style?: any
  imageStyle?: any
  children?: ReactNode
  actions?: IButtonGroup[]
  enablePreview?: boolean
}) => {
  const [isValidImage, setIsValidImage] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const handleOnError = (event) => {
    console.log('ev', event)
    setIsValidImage(false)
  }

  const src =
    !isValidImage || image?.endsWith('null.jpg')
      ? '/png/No_Img.png'
      : image || '/png/No_Img.png'

  const handleOpen = () => {
    if (isValidImage && enablePreview) {
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  if (isColorBackground(image)) {
    const colors = getColorsFromUrl(image)
    return <div style={{ backgroundColor: colors, ...style }}>{children}</div>
  }

  if (!isOpen)
    return (
      <div
        style={{
          ...style,
          cursor: isValidImage && enablePreview ? 'pointer' : 'default',
          display: 'flex',
          height: '100%',
          ...style,
        }}
        onClick={handleOpen}
      >
        <img
          src={src}
          style={{
            ...imageStyle,
            width: '100%',
            objectFit: 'cover',
            minHeight: '100%',
          }}
          alt={alt || 'content'}
          onError={handleOnError}
        />
        {children}
      </div>
    )

  return (
    <Modal
      showCloseButton={false}
      isOpen={isOpen}
      style={{
        overlay: {
          ...modalStyles.overlay,
          backgroundColor: 'black',
        },
        content: {
          ...modalStyles.content,
          width: '100%',
          backgroundImage: `url(${src})`,
          height: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          borderRadius: 0,
          backgroundColor: theme.colors.Black,
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        icon={<Close />}
        style={{
          position: 'absolute',
          top: '32px',
          left: '32px',
          backgroundColor: 'white',
        }}
      />
      {!!actions && (
        <QuickActions bottom={32}>
          <ButtonGroup buttons={actions} />
        </QuickActions>
      )}
    </Modal>
  )
}
