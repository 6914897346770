import { Text } from 'venus/components'
import { formatFullAddress, getAddressPreviewStr } from 'venus/utils'
import { theme } from 'venus/config'
import { AddressSection, Line, Panel } from './PropertyDetails.styles'
import AVM from './AVM'
import { useSearchParams } from 'react-router-dom'

const AddressDisplay = ({
  isShared,
  isDreamProperty,
  address,
  propertyData,
  avm,
  rentalAvm,
  avmLoading,
  googlePlaceId,
  dreamPrice,
}) => {
  const [searchParams] = useSearchParams()
  const dreamPropertyId = searchParams.get('dreamPropertyId')

  const shouldDisplayAddressPreview =
    (isDreamProperty && (dreamPropertyId || !googlePlaceId)) || !isDreamProperty

  const shouldDisplayAVM = !isShared || (isDreamProperty && !dreamPrice)

  return (
    <>
      <AddressSection>
        <Line>
          <Text
            format='h1'
            fontSize={20}
            lineHeight='25px'
            color={theme.colors.Primary}
            style={{ marginBottom: 16 }}
          >
            {formatFullAddress(address)}
          </Text>
        </Line>
        {shouldDisplayAddressPreview && (
          <Line>
            <Text format='h4' color={theme.colors.DarkestGrey}>
              {getAddressPreviewStr(propertyData)}
            </Text>
          </Line>
        )}
      </AddressSection>
      {(avm || avmLoading) && !googlePlaceId && shouldDisplayAVM && (
        <AVM avm={avm} rentalAvm={rentalAvm} />
      )}
    </>
  )
}
// remove AVM
// {(avm || avmLoading) && !isShared && <AVM isLoading={avmLoading} {...avm} />}

export default AddressDisplay
