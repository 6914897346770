import styled from 'styled-components';
import { theme } from 'venus/config';

type TextAreaProps = {
  readonly isError?: boolean
}

export const StyledTextArea = styled.textarea<TextAreaProps>`
  resize: none;
  padding: 12px 20px;
  text-align: left;
  border: ${(props) =>
          `0.5px solid ${
                  props.isError ? theme.colors.DangerRed : theme.colors.Grey
          }`};
  color: ${theme.colors.Black};
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;

  &::placeholder {
    color: ${theme.colors.DarkestGrey};
  }

  &:hover {
    border-color: ${(props) =>
            props.isError ? theme.colors.DangerRed : theme.colors.Secondary};
  }
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;