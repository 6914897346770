import { useDropzone } from 'react-dropzone'
import { Text } from 'venus/components'
import { theme } from 'venus/config'
import { DropContainer } from './Dropzone.styles'
import { ReactComponent as UploadIcon } from 'venus/assets/img/icons/Upload.svg'
import { useEffect } from 'react'

const Dropzone = ({ upload }) => {
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept } =
    useDropzone({
      accept: {
        'image/*': [],
      },
    })

  useEffect(() => {
    if (acceptedFiles.length) {
      upload(acceptedFiles)
    }
  }, [acceptedFiles])

  return (
    <DropContainer {...getRootProps({ isFocused, isDragAccept })}>
      <UploadIcon width={47} height={47} color={theme.colors.BlueGrey} />
      <Text
        style={{ textAlign: 'center', marginTop: 37, marginLeft: 20, marginRight: 20 }}
        format='body'
        color={theme.colors.DarkestGrey}
      >
        Drag and drop or browse your image to add items to space
      </Text>
    </DropContainer>
  )
}

export default Dropzone
