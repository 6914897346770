import { theme } from 'venus/config'
import { Wrapper, LoadingDiv } from './Loader.styles'

interface ILoader {
  size?: number
  color?: string
  isFull?: boolean
}

export const Loader = ({
  size = 32,
  isFull = false,
  color = theme.colors.Secondary,
}: ILoader) => {
  const style = {
    width: size,
    background: color,
  }
  if (!isFull) return <LoadingDiv style={{ ...style }} />
  return (
    <Wrapper>
      <LoadingDiv style={{ ...style }} />
    </Wrapper>
  )
}
