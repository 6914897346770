import { Text } from 'venus/components'
import { DetailGroup, DetailSection, DetailSections } from './ItemDetail.styles'

interface IItemDetail {
  label: string
  key: string
  formatter?: (value: string) => string
}

const getValue = (formatter?: (v: string) => string, value?: string) =>
  formatter ? formatter(value) : value || ' '

const ItemDetail = ({
  item,
  detail,
}: {
  item: { [key: string]: string }
  detail: IItemDetail | IItemDetail[]
}) => {
  if (Array.isArray(detail)) {
    return (
      <DetailSections>
        {detail.map((d) => {
          return (
            <DetailGroup key={d.key}>
              <Text format='h3' value={d.label} style={{ marginBottom: 8 }} />
              <Text
                format='body'
                lineHeight={1.5}
                value={getValue(d.formatter, item[d.key])}
                style={{ minHeight: 21 }}
              />
            </DetailGroup>
          )
        })}
      </DetailSections>
    )
  }

  return (
    <DetailSection>
      <Text format='h3' value={detail.label} style={{ marginBottom: 8 }} />
      <Text
        format='body'
        lineHeight={1.5}
        value={getValue(detail.formatter, item[detail.key])}
        style={{ minHeight: 21 }}
      />
    </DetailSection>
  )
}

export default ItemDetail
