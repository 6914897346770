import { Text, LinkButton } from "venus/components"

export const LinkButtonSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format="h2"
        style={{ margin: 18, textAlign: "left" }}
      />
      <LinkButton
        handlePress={() => null}
        label="Terms and Conditions"
        style={{ margin: 18 }}
        format="body"
      />
    </>
  )
}
