import { useState } from 'react'
import { MultiSpaceItemSelect, Text } from 'venus/components'

export const MultiSpaceItemSelectSample = ({
  index,
  title,
}: {
  index: number
  title: string
}) => {
  const [checkedItemIds, setCheckedItemIds] = useState([
    {
      spaceId: 'MultiSpaceItemSelect_kitchen',
      itemId: 'item_2',
      spaceName: 'Kitchen',
      itemName: 'K2',
    },
  ])
  return (
    <>
      <Text
        value={`${index}. ${title}`}
        format='h2'
        style={{ margin: 18, textAlign: 'left' }}
      />
      <MultiSpaceItemSelect
        spaceLabel='Kitchen'
        id='MultiSpaceItemSelect_kitchen'
        items={[
          {
            name: 'K1',
            id: 'item_1',
            spaceId: 'MultiSpaceItemSelect_kitchen',
          },
          {
            name: 'K2',
            id: 'item_2',
            spaceId: 'MultiSpaceItemSelect_kitchen',
          },
          {
            name: 'K3',
            id: 'item_3',
            spaceId: 'MultiSpaceItemSelect_kitchen',
          },
          {
            name: 'K4',
            id: 'item_4',
            spaceId: 'MultiSpaceItemSelect_kitchen',
          },
        ]}
        checkedItemIds={checkedItemIds}
        setCheckedItemIds={setCheckedItemIds}
        isDisabled={false}
      />
    </>
  )
}
