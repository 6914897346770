import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'venus/redux/hooks'
import { useForm, Controller } from 'react-hook-form'
import { theme } from 'venus/config'
import { formateDate } from 'venus/utils'
import {
  updateReminder,
  deleteReminder,
  deleteLocalReminder,
} from 'venus/redux/features/reminder'
import TextArea from '../../../components/Textarea/TextArea'
import { BtnArea, DateWrapper } from './Edit.styles'
import {
  Button,
  Input,
  Text,
  DatePicker,
  Notification,
  InputMultipleLine,
  AlertBox,
  Drawer,
  ActionLayout,
} from 'venus/components'

type PropertyFormData = {
  notes: string
  dueDateTime: string
  eventName: string
}

interface IEdit {
  setStep: (s: number) => void
  setTitle: (s: string) => void
  onClose: () => void
  isOpen: boolean
}

export const Edit = ({ setStep, setTitle, onClose, isOpen }: IEdit) => {
  const [showNotification, setShowNotification] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showEventNameError, setShowEventNameError] = useState(false)
  const dispatch = useAppDispatch()
  const { detail } = useAppSelector((state) => state.reminder)
  const { propertyId, spaceId, itemId, notes, eventName, dueDateTime, id, isMHVReminder } =
    detail

  const getDefaultValue = () => {
    return {
      defaultValues: {
        notes,
        dueDateTime,
        eventName,
      },
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<PropertyFormData>(getDefaultValue())

  const onSubmit = async (data: PropertyFormData) => {
    setShowEventNameError(false)
    if (data.eventName.trim() === '') {
      setShowEventNameError(true)
      return
    }
    const today = formateDate(null)
    const body: any = {
      dueDateTime: data?.dueDateTime.trim(),
      eventName: data?.eventName.trim(),
      notes: data?.notes?.trim() || '',
      spaceId,
      status: data?.dueDateTime.trim() === today ? 'DUE' : 'UPCOMING',
    }
    if (itemId) body.itemId = itemId
    console.log('body::', body)
    const result = await dispatch(
      updateReminder({
        propertyId,
        reminderId: id,
        body,
      })
    )
    console.log('updateReminder result::', result)
    setShowNotification(true)
    setStep(2)
  }

  const handlPressAlertOK = async () => {
    setShowNotification(false)
    onClose()
  }

  const handlPressDelete = async () => {
    const data = await dispatch(deleteReminder({ propertyId, reminderId: id }))
    dispatch(deleteLocalReminder(id))
    console.log('delete reminder result::', data)
    setShowConfirmDelete(false)
    onClose()
  }

  const handlePressDelete = () => {
    setShowConfirmDelete(true)
  }

  const handlPressAlertCancel = () => {
    setShowConfirmDelete(false)
  }

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <ActionLayout
        leftActions={[
          {
            type: 'error_reverse',
            text: 'Remove Reminder',
            onClick: handlePressDelete,
          },
        ]}
        rightActions={[
          {
            type: 'primary',
            text: 'Save Changes',
            onClick: handleSubmit(onSubmit),
          },
        ]}
      >
        <>
          <Text
            value='Edit Reminder'
            format='h1'
            style={{
              alignSelf: 'flex-start',
              marginTop: '34px',
              display: 'inline-block',
            }}
            fontSize='26px'
          />
          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 150,
            }}
            render={({ field: { onChange, value } }) => (
              <div style={{ marginBottom: '18px' }}>
                <Input
                  isDisabled={isMHVReminder}
                  label='Reminder Name'
                  value={value}
                  onChange={onChange}
                  style={{
                    marginTop: '34px',
                  }}
                  isError={!!errors.eventName || showEventNameError}
                  errorMsg={
                    errors?.eventName?.type === 'maxLength'
                      ? 'Maximum 150 characters.'
                      : 'This is required.'
                  }
                />
              </div>
            )}
            name='eventName'
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <DateWrapper>
                <DatePicker onChange={onChange} value={value} disablePastDate />
              </DateWrapper>
            )}
            name='dueDateTime'
          />
          {(errors.dueDateTime ||
            getValues('dueDateTime') === 'dd/mm/yyyy') && (
            <Text
              format='h3'
              value='This is required.'
              color={theme.colors.ErrorState}
            />
          )}
          <Controller
            name='notes'
            control={control}
            rules={{
              maxLength: 1024,
            }}
            render={({ field: { onChange, value } }) => (
              <TextArea
                disabled={isMHVReminder}
                rows={8}
                cols={50}
                label={'Notes (optional)'}
                value={value || ''}
                onChange={onChange}
                isError={!!errors.notes}
                errorMsg={
                  errors?.notes?.type === 'maxLength'
                    ? 'Maximum 1024 characters.'
                    : ''
                }
              />
            )}
          />
          <Notification
            message={`New reminder ${getValues('eventName')} has been updated.`}
            closeAlert={handlPressAlertOK}
            showAlert={showNotification}
          />
          <AlertBox
            cancel={handlPressAlertCancel}
            title='Remove Reminder'
            message={`Are you sure you want to delete this reminder?`}
            btnGroup={[
              {
                text: 'Delete',
                onClick: handlPressDelete,
                color: 'ErrorState',
                type: 'error',
              },
              {
                text: 'Cancel',
                onClick: handlPressAlertCancel,
                color: 'LightBlue',
                textColor: 'Primary',
                type: 'secondary',
              },
            ]}
            showAlert={showConfirmDelete}
          />
        </>
      </ActionLayout>
    </Drawer>
  )
}
