import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const PhoneContainer = styled.div`
  width: 106px;
  height: 106px;
  border-radius: 50%;
  background-color: rgba(73, 195, 202, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DownloadIcon = styled.img`
  height: 45px;
  cursor: pointer;
`

export const DownloadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin: 30px 0;
`
